import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Program} from './program';

@Injectable({
  providedIn: 'root'
})
export class LoginStatusService {

  currentLoggedInStatus: Observable<boolean>;
  private loggedInStatusSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentLoggedInStatus = this.loggedInStatusSubject.asObservable();
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedInStatusSubject.next(loggedIn);
  }
}
