import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {SportsCreditStudentResult} from '../../_model/sports-credit-student-result';
import {Course} from '../../_model/course';
import {Venue} from '../../_model/venue';
import {Address} from '../../_model/address';
import {Subject, Subscription} from 'rxjs';
import {TitleGenerator} from '../../title-generator';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AlertService} from '../../alert';
import {DashboardService} from '../../dashboard/dashboard.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {ApplicationSettingsService} from '../../application-settings.service';
import {environment} from '../../../environments/environment';
import {numberToWords} from 'number-to-words';
import {NewEnrolmentRequest} from '../../_model/new-enrolment-request';
import {NewEnrolmentRequestStudent} from '../../_model/new-enrolment-request-student';
import {SportsCreditResult} from '../../_model/sports-credit-result';
import {NewEnrolmentResponse} from '../../_model/new-enrolment-response';
import {NewEnrolmentStudentResponse} from '../../_model/new-enrolment-student-response';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-dashboard-booking-intensive-credit-use',
  templateUrl: './dashboard-booking-intensive-credit-use.component.html',
  styleUrls: ['./dashboard-booking-intensive-credit-use.component.css']
})
export class DashboardBookingIntensiveCreditUseComponent implements OnInit, OnDestroy {

  creditId: string;
  credit: SportsCreditStudentResult;
  creditResult: SportsCreditResult;
  course: Course;
  venue: Venue;
  address: Address;
  listPosition: number;

  previewEnrolments: NewEnrolmentResponse;
  previewStudentEnrolment: NewEnrolmentStudentResponse;

  startDate: Date;

  enrolling = false;
  previewLoaded = false;

  locale: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  applicationSettingsSubscription: Subscription;

  googleUrl: SafeResourceUrl;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private dashboardService: DashboardService,
              private googleAnalytics: GoogleAnalyticsService,
              private changeDetectorRef: ChangeDetectorRef,
              private applicationSettings: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Credit Booking Confirmation');

    this.locale = this.applicationSettings.locale;

    this.route.params.subscribe(params => {
      this.creditId = params.id;
      this.apiService.getCredit(this.creditId, this.ngUnsubscribe).subscribe(result => {
        this.credit = result;
        this.creditResult = result.creditResults[0];

        const creditResult = this.credit.creditResults[0];
        if (creditResult.status !== 'ACTIVE') {
          this.alertService.error('Credit is no longer active');
          return;
        }

        if (creditResult.expired) {
          this.alertService.error('Credit has expired');
          return;
        }

        this.apiService.getCourse(params.courseId, this.ngUnsubscribe).subscribe(course => {
          this.course = course;
          this.apiService.getVenue(course.venueId, this.ngUnsubscribe).subscribe(venue => {
            this.venue = venue;
            this.setGoogleUrl();
          });
          this.apiService.getAddress(course.venueId, this.ngUnsubscribe).subscribe(venue => this.address = venue);

          this.googleAnalytics.viewCourse(this.course, this.course.programLevel, this.listPosition, 'Intensive Credit Course');

          this.previewEnrolment();
        });
      });
    });

    this.route.queryParams.subscribe(params => {
      if (params.listPosition != null) {
        this.listPosition = parseInt(params.listPosition, 10);
      }
    });
    this.renderer.addClass(document.body, 'dashboard-booking-credits-use-confirm');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-booking-credits-use-confirm');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return environment.apiUrl + 'images/instructors/' + instructorId + '/image';
  }

  getNumberOfStudentsText(): string {
    return numberToWords.toWords(this.course.numberOfStudentsPerInstructor);
  }

  setGoogleUrl() {
    if (this.venue.latitude != null && this.venue.longitude != null) {
      this.googleUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?' +
        'key=AIzaSyBn9W7K04WtxdwqzxP1zu8NRnjvCQ4Rtd4&' +
        'q=' + this.venue.latitude + ',' + this.venue.longitude);
    }
  }

  confirmSession(): void {
    if (this.enrolling) {
      return;
    }

    this.enrolling = true;

    this.apiService.enrol(this.generateEnrolmentObject()).subscribe(response => {
      if (response.result === 'success') {
        this.router.navigate(['/dashboard_credits']);

        const studentResult = response.studentEnrolments[0];

        this.dashboardService.setNewConfirmationMessage('Intensive course booking complete for '
          + this.course.venueName + ' from '
          + formatDate(studentResult.startDate, 'd MMM yyyy', this.locale)
          + ' @ ' + formatDate(studentResult.startDate, 'h:mma', this.locale, studentResult.startDateOffset)
          + ' for ' + studentResult.numberOfClasses + ' lessons');
      } else {
        this.alertService.error(response.error);
      }
    }, error => {
      this.alertService.error('Failed to enrol intensive course: ' + error);
    }, () => {
      this.enrolling = false;
    });
  }

  previewEnrolment(): void {
    this.previewEnrolments = null;
    this.apiService.previewEnrolment(this.generateEnrolmentObject()).subscribe(response => {
      if (response.result === 'error') {
        this.alertService.error(response.error);
      }

      this.previewEnrolments = response;
      this.previewStudentEnrolment = response.studentEnrolments[0];

      if (this.previewEnrolments.totalAmount > 0) {
        this.alertService.error('Credit could not be applied');
        this.previewEnrolments.result = 'error';
      }

      this.changeDetectorRef.detectChanges();
    }, () => {}, () => {
      this.previewLoaded = true;
    });
  }

  generateEnrolmentObject(): NewEnrolmentRequest {
    const request = new NewEnrolmentRequest();

    request.accountId = this.creditResult.account.id;
    request.licenseeId = this.creditResult.course.licenseeId;

    const studentList = [];
    const studentRequest = new NewEnrolmentRequestStudent();

    studentRequest.courseId = this.course.id;
    studentRequest.enrolmentType = 'SINGLE_TERM';
    studentRequest.programLevelId = this.creditResult.studentLevelId;
    studentRequest.studentId = this.credit.student.id;
    studentRequest.enrolInNextTerm = false;
    studentRequest.sportsCreditId = this.creditResult.credit.sportsCreditId;

    studentList.push(studentRequest);
    request.studentEnrolments = studentList;

    request.acceptedTermsAndConditions = false;

    return request;
  }

}
