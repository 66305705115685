import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {FindCourseMultipleStepComponent} from '../find-course-multiple-step/find-course-multiple-step.component';
import {FindCourseMultipleStudentComponent} from '../find-course-multiple-student/find-course-multiple-student.component';
import {FindCourseMultipleServiceStep} from '../find-course-multiple-step/find-course-multiple-service-step.service';
import {FindCourseMultipleService} from './find-course-multiple.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SelectDateOfBirthService} from '../select-date-of-birth/select-date-of-birth.service';
import {SelectProgramService} from '../select-program/select-program.service';
import {SelectProgramLevelService} from '../select-program-level/select-program-level.service';
import {SelectLocationService} from '../select-location/select-location.service';
import {ActivatedRoute, Router} from '@angular/router';
import {VenueOptions} from '../select-location/select-location.component';
import {CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {Program} from '../../_model/program';
import {TitleGenerator} from '../../title-generator';
import {Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {ApiService} from '../../api.service';
import moment from 'moment';

@Component({
  selector: 'app-find-course-multiple',
  templateUrl: './find-course-multiple.component.html',
  styleUrls: ['./find-course-multiple.component.css']
})
export class FindCourseMultipleComponent implements OnInit, OnDestroy, AfterContentInit {

  @ViewChild('step_container', { static: true, read: ViewContainerRef }) stepEntry: ViewContainerRef;
  @ViewChild('student_container', { static: true, read: ViewContainerRef }) studentEntry: ViewContainerRef;

  studentCount: number;
  selectedStudent = 1;

  selectDobForm: UntypedFormGroup;

  submitted = false;

  stepOne = true;
  stepTwo = false;
  stepThree = false;
  stepFour = false;

  dateOfBirth: Date;
  program: Program;
  studentName: string;
  programShowLevelChecker: boolean;

  studentNameSubscription: Subscription;
  programSubscription: Subscription;
  programLevelIdSubscription: Subscription;
  venueOptionsSubscription: Subscription;

  instructorId: string;

  loading = true;

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private renderer: Renderer2,
              private resolver: ComponentFactoryResolver,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private findCourseMultipleService: FindCourseMultipleService,
              private findCourseMultipleServiceStep: FindCourseMultipleServiceStep,
              private selectDateOfBirthService: SelectDateOfBirthService,
              private selectProgramService: SelectProgramService,
              private selectProgramLevelService: SelectProgramLevelService,
              private selectLocationService: SelectLocationService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private apiService: ApiService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Find Course');

    this.bookingHeaderTitleServiceService.setTitle('find the perfect course');
    this.studentNameSubscription = this.findCourseMultipleService.currentStudentName
      .subscribe(message => {
        if (message != null && !this.loading) {
          this.findCourseMultipleServiceStep.setStudentName(message);
        }
      });
    this.programSubscription = this.selectProgramService.currentProgram
      .subscribe(selectedProgramId => {
        if (selectedProgramId != null && !this.loading) {
          this.onProgramSelected(selectedProgramId);
        }
      });
    this.programLevelIdSubscription = this.selectProgramLevelService.currentProgramLevelId
      .subscribe(programLevelId => {
        if (programLevelId != null && !this.loading) {
          this.onProgramLevelSelected(programLevelId);
        }
      });
    this.venueOptionsSubscription = this.selectLocationService.currentVenueOptions
      .subscribe(venueOptions => {
        if (venueOptions != null && !this.loading) {
          this.onVenueSelected(venueOptions);
        }
      });

    this.sessionService.removeCourseSearchParameters();
    this.sessionService.removeBasket();

    this.studentCount = 0;
    this.submitted = false;

    this.selectDobForm = this.formBuilder.group({});

    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'type-multi');

    this.stepEntry.clear();

    this.addMultipleStudent();
    this.addMultipleStudent();

    this.route.queryParams.subscribe(params => {
      if (params.venueId != null) {
        this.sessionService.courseSearchParameters.venueOptions = new VenueOptions();
        this.sessionService.courseSearchParameters.venueOptions.venueId = params.venueId;
      }
      this.instructorId = params.instructorId;
    });
  }

  ngAfterContentInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.studentNameSubscription != null) {
      this.studentNameSubscription.unsubscribe();
    }
    if (this.programSubscription != null) {
      this.programSubscription.unsubscribe();
    }
    if (this.programLevelIdSubscription != null) {
      this.programLevelIdSubscription.unsubscribe();
    }
    if (this.venueOptionsSubscription != null) {
      this.venueOptionsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'find-course');
    this.renderer.removeClass(document.body, 'type-multi');
  }

  addMultipleStudent() {
    this.studentCount++;

    this.addMultipleStudentStep();

    const formControlFields = [];
    formControlFields.push({ name: 'first_name_' + this.studentCount, control: new UntypedFormControl('', Validators.required) });
    formControlFields.push({ name: 'dob_day_' + this.studentCount, control: new UntypedFormControl('', Validators.required) });
    formControlFields.push({ name: 'dob_month_' + this.studentCount, control: new UntypedFormControl('', Validators.required) });
    formControlFields.push({ name: 'dob_year_' + this.studentCount, control: new UntypedFormControl('', Validators.required) });

    formControlFields.forEach(f => this.selectDobForm.addControl(f.name, f.control));

    const factory = this.resolver.resolveComponentFactory(FindCourseMultipleStudentComponent);
    const componentRef = this.studentEntry.createComponent(factory);
    componentRef.instance.selectStudentsForm = this.selectDobForm;
    componentRef.instance.studentNumber = this.studentCount;

    this.sessionService.courseSearchParameters.students = [];
  }

  addMultipleStudentStep() {
    const factory = this.resolver.resolveComponentFactory(FindCourseMultipleStepComponent);
    const componentRef = this.stepEntry.createComponent(factory);
    componentRef.instance.stepNumber = this.studentCount;
  }

  updateStepNumber(stepNumber) {
    this.stepOne = stepNumber === 1;
    this.stepTwo = stepNumber === 2;
    this.stepThree = stepNumber === 3;
    this.stepFour = stepNumber === 4;
  }

  onDobSubmit() {
    this.selectDateOfBirthService.setSubmitted(true);
    this.submitted = true;

    // stop here if form is invalid
    if (this.selectDobForm.invalid) {
      return;
    }

    this.dateOfBirth = this.getStudentDateOfBirth();
    this.studentName = this.getStudentName();

    this.updateStepNumber(2);
    this.selectProgramService.setTitle('Now select a programme for ' + this.getStudentName() + '…');

    this.findCourseMultipleServiceStep.setSelectedStudent(this.selectedStudent);
  }

  onProgramSelected(selectedProgram: Program) {
    if (selectedProgram == null) {
      return;
    }

    this.program = selectedProgram;
    this.programShowLevelChecker = selectedProgram.hasLevelFinder;

    this.updateStepNumber(3);

    this.selectProgramLevelService.setTitle('What is ' + this.getStudentName() + '\'s skill level?');
  }

  getStudentName() {
    return this.selectDobForm.get('first_name_' + this.selectedStudent).value;
  }

  getStudentDateOfBirth() {
    const date = moment().set(
      {year: this.selectDobForm.get('dob_year_' + this.selectedStudent).value,
        month: this.selectDobForm.get('dob_month_' + this.selectedStudent).value - 1,
        date: this.selectDobForm.get('dob_day_' + this.selectedStudent).value
      });

    return date.toDate();
  }

  onProgramLevelSelected(selectedProgramLevelId: string) {
    if (selectedProgramLevelId === '' || selectedProgramLevelId == null) {
      return;
    }

    const studentParameters = new CourseSearchStudent();

    studentParameters.id = this.selectedStudent;
    studentParameters.dateOfBirth = this.dateOfBirth;
    studentParameters.programId = this.program.id;
    studentParameters.programLevelId = selectedProgramLevelId;
    studentParameters.studentName = this.studentName;

    this.apiService.getProgramLevel(selectedProgramLevelId)
      .subscribe(programLevel => studentParameters.programLevelName = programLevel.programLevelName);

    this.sessionService.courseSearchParameters.students.push(studentParameters);

    this.selectedStudent++;

    // Update so that the last one can be marked as done
    this.findCourseMultipleServiceStep.setSelectedStudent(this.selectedStudent);
    if (this.selectedStudent > this.studentCount) {
      this.showLocations();
    } else {
      this.dateOfBirth = this.getStudentDateOfBirth();
      this.studentName = this.getStudentName();

      this.selectProgramService.setTitle('Now select a programme for ' + this.getStudentName() + '…');
      this.updateStepNumber(2);
    }
  }

  showLocations() {
    if (this.sessionService.courseSearchParameters.venueOptions.venueId != null) {
      this.navigateToResults();
    } else {
      this.updateStepNumber(4);
      this.selectLocationService.setTitle('Lastly, what’s your preferred location?');
    }
  }

  onVenueSelected(venueOptions: VenueOptions) {
    if (venueOptions == null) {
      return;
    }

    this.sessionService.courseSearchParameters.venueOptions = venueOptions;
    this.sessionService.saveCourseSearchParameters();

    this.navigateToResults();
  }

  navigateToResults() {
    if (this.sessionService.courseSearchTermType != null) {
      this.sessionService.courseSearchParameters.termType = this.sessionService.courseSearchTermType;
      this.sessionService.courseSearchTermType = null;
      this.sessionService.saveBasket();
    }

    const runSearch = true;
    const instructorId = this.instructorId;

    this.router.navigate(['courses_list_single'], {queryParams: {runSearch, instructorId}});
  }
}
