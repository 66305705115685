<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_account_settings']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              add card					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_account_settings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-checkout">
      <div class="container">
        <form method="post" id="booking_add_card" novalidate="novalidate" class="form-dashboard form-account parsley-rows" [formGroup]="cardForm" (ngSubmit)="onCardSubmit()" >

          <div class="card">
            <div class="row row-payment-title">
              <h3 class="span-heading title-label">
                Payment method
              </h3>

              <div class="span-cards">
                <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>							<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>						</div>
            </div>
            <div class="row row-card-info">
              <div class="span-input span-text required">
                <div id="card_number" class="stripeCardNumber" #cardElement>

                </div>
                <i class="asterisk" *ngIf="cardPaymentMethod.cardHasErrors">*</i>
                <i class="checkmark"></i>
                <ul *ngIf="submitted && cardPaymentMethod.cardHasErrors && cardForm.errors != null" class="parsley-errors-list filled">
                  <li *ngIf="cardForm.errors.cardError" class="parsley-required">{{cardPaymentMethod.cardErrors}}</li>
                </ul>
              </div>


              <div class="row row-border">
                <h3 class="span-heading title-label">
                  Card Holder's Name
                </h3>

                <div class="span-input span-text required">
                  <input
                    type="text"
                    id="creditCardName"
                    name="creditCardName"
                    required="required"
                    class="input-text"
                    formControlName="creditCardNameInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Cardholder Name'"
                  />
                  <label for="creditCardName" class="floating-label">
                    card holder name
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul class="parsley-errors-list filled"></ul>
                </div>
              </div>
            </div>

            <div class="row row-border">
              <h3 class="span-heading title-label">
                Billing Address
              </h3>
              <div class="span-input span-checkbox" *ngFor="let address of paymentAddresses">
                <label
                  for="address_{{address.addressId}}"
                  class="input-check checkbox">
                  <input
                    id="address_{{address.addressId}}"
                    name="paymentAddress"
                    type="radio"
                    class="input-checkbox"
                    [value]="address.addressId"
                    (change)="paymentAddressChange()"
                    formControlName="paymentAddress"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Payment Address'"
                  />
                  <span class="preview-address">
			{{getSingleLineAddress(address)}}
		</span>
                </label>
              </div>

              <div class="span-input span-checkbox">
                <label
                  for="cha_other"
                  class="input-check checkbox">
                  <input
                    id="cha_other"
                    name="paymentAddress"
                    type="radio"
                    class="input-checkbox"
                    value="other"
                    (change)="paymentAddressChange()"
                    formControlName="paymentAddress"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Payment Address'"
                  />
                  <span>
			Enter a different billing address
		</span>
                </label>
              </div>
              <ul class="parsley-errors-list filled"></ul>
            </div>
            <span [style.display]="(paymentAddressId !== 'other') ? 'none' : '' ">
                <h3 class="title-label">
                  Address
                </h3>

                  <app-address *ngIf="cardPaymentMethod != null && cardPaymentMethod.address != null"
                               [submitted]="submitted"
                               [addressForm]="cardForm"
                               [addressLinesActive]="false"
                               [address]="cardPaymentMethod.address"></app-address>

                </span>
            <button type="submit" formaction="dashboard-account-settings.html" class="btn btn-block btn-red" [ngClass]="addingCard || cardForm.invalid ? 'disabled' : ''">add card</button>
          </div>
        </form>
      </div>
    </section>


  </main>
</div>

<app-footer></app-footer>
