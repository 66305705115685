<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="[]" queryParamsHandling="preserve" (click)="goBack()">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              cancel subscription					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="[]" queryParamsHandling="preserve" (click)="goBack()">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container">

        <form method="post" id="booking_confirm" novalidate="novalidate" class="form-dashboard" [formGroup]="withdrawalForm" (ngSubmit)="onWithdrawalFormSubmit()">
          <div class="panel panel-dashboard">

            <div class="span-title">
              <h2 class="entry-title">
                Are you sure?					</h2>
              <div class="entry-content">
                <p>Cancelling your subscription will mean your space is completely lost, and you will have to start the enrolment process to reserve your place again.</p>
              </div>
            </div>


            <div class="row row-cards">

              <div class="span-card" *ngIf="enrolment != null">
                <div class="card style-checked">
                  <div class="span-choice">
                    <label
                      class="input-check radio">

                      <ul class="label-description">
                        <li class="title">{{enrolment.student.givenName}} {{enrolment.student.familyName}}</li>
                        <li class="details">
                          <ul class="list-inline">
                            <li id="student_term">{{enrolment.course.termDescription}}</li>
                            <li> with </li>
                            <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                          </ul>
                        </li>
                        <li class="details">
                          <ul class="list-inline">
                            <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
                            <li> on </li>
                            <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                            <li> at </li>
                            <li id="term_venue">{{enrolment.course.venueName}}</li>
                          </ul>
                        </li>
                        <li class="details withdrawalLessonPreview" *ngIf="withdrawalPreview.lastClass != null">
                          <ul class="list-inline">
                            <li>last lesson </li>
                            <li id="class_start_date">{{withdrawalPreview.lastClass.startDate | worldDate: 'EEEE d MMM yyyy h:mma'}}</li>
                          </ul>
                        </li>
                        <li class="details">
                          <ul class="list-inline">
                            <li>last payment </li>
                            <li id="last_payment_date">{{withdrawalPreview.lastPaymentDate | worldDate: 'EEEE d MMM yyyy'}}</li>
                          </ul>
                        </li>
                      </ul>
                    </label>
                    <label
                      class="radio">

                      <ul>
                        <li class="details">
                          <div class="row row-cards" [ngClass]="selectedWithdrawalText != null && selectedWithdrawalText.toLowerCase() === 'other' ? '' : 'span-select-padding'">
                            <div class="span-select span-input required">
                              <select
                                type="select"
                                required="required"
                                class="input-select"
                                formControlName="withdrawalReasonSelect"
                                #withdrawalReason
                                (change)="updateWithdrawalReasonText(withdrawalReason.options[withdrawalReason.options.selectedIndex].text)"
                                appParsleyValidation
                                [submitted]="submitted"
                                [elementName]="'Withdrawal Reason'"
                              >
                                <option value="" disabled selected hidden>Reason</option>
                                <option *ngFor="let reason of withdrawalReasons" [value]="reason.withdrawalReasonId">{{reason.name}}</option>
                                <option  [value]="'other'">Other&hellip;</option>
                              </select>
                              <ul class="parsley-errors-list filled"></ul>
                            </div>
                          </div>
                        </li>
                        <li class="details" *ngIf="selectedWithdrawalText != null && selectedWithdrawalText.toLowerCase() === 'other'">
                          <div class="row row-cards">
                            <div class="span-input span-text required">
                              <input id="reasonOther"
                                     type="text"
                                     class="input-text"
                                     formControlName="withdrawalReasonOtherInput"
                                     placeholder="Enter a reason here"
                                     maxlength="1000"
                                     appParsleyValidation
                                     [submitted]="submitted"
                                     [elementName]="'Other Reason'"
                              />
                              <ul class="parsley-errors-list filled"></ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" class="btn btn-block btn-red " [ngClass]="this.withdrawalForm.invalid || running ? 'disabled' : ''">
              Yes, cancel this subscription				</button>

          </div>
        </form>

      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
