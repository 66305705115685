import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PostcodeAddress} from './postcode-address';

@Injectable({
  providedIn: 'root'
})
export class PostcodeLookupService {

  apiKey = 'PCW5L-5XMZ7-8CFTS-8SMBS';
  testApiKey = 'PCW45-12345-12345-1234X';

  includeOrganisation = false;

  constructor(private httpClient: HttpClient) { }

  public lookupPostcode(postCode: string, country: string): Observable<Array<PostcodeAddress>> {
    let apiKeyToUse = '';

    if (isDevMode()) {
      apiKeyToUse = this.testApiKey;
    } else {
      apiKeyToUse = this.apiKey;
    }

    let addressUrl = `https://ws.postcoder.com/pcw/${apiKeyToUse}/` +
      `address/${country}/${encodeURIComponent(postCode)}?format=json&postcodeonly=true&lines=3&identifier=enrol-web`;

    if (!this.includeOrganisation) {
      addressUrl += '&exclude=organisation';
    }

    return this.httpClient.get<any>(addressUrl).pipe(
      map(response => {
        const array = response as any[];
        let idCount = 0;
        return array.map(data => new PostcodeAddress(++idCount, country, data));
      })
    );
  }
}
