import {UntypedFormGroup, ValidationErrors} from '@angular/forms';

export function getFormValidationErrors(debugForm: UntypedFormGroup) {
  console.log('********** ERRRORS ***********');

  const formErrors: ValidationErrors = debugForm.errors;
  if (formErrors != null) {
    Object.keys(formErrors).forEach(keyError => {
      console.log('Form error, keyError: ' + keyError + ', err value: ', formErrors[keyError]);
    });
  }

  Object.keys(debugForm.controls).forEach(key => {
    const controlErrors: ValidationErrors = debugForm.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });
}
