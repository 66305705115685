import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {EnrolmentResponse} from '../_model/enrolment-response';
import {ConfirmationMessage} from '../_model/confirmation-message';
import {Enrolment} from '../_model/enrolment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private currentProvisionalEnrolmentsSubject = new BehaviorSubject<EnrolmentResponse[]>(null);
  currentProvisionalEnrolments: Observable<EnrolmentResponse[]>;

  private confirmationMessageSubject = new BehaviorSubject<ConfirmationMessage>(null);
  currentConfirmationMessage: Observable<ConfirmationMessage>;

  private reloadAbandonedCartsSubject = new BehaviorSubject<boolean>(null);
  currentReloadAbandonedCarts: Observable<boolean>;

  constructor() {
    this.currentProvisionalEnrolments = this.currentProvisionalEnrolmentsSubject.asObservable();
    this.currentConfirmationMessage = this.confirmationMessageSubject.asObservable();
    this.currentReloadAbandonedCarts = this.reloadAbandonedCartsSubject.asObservable();
  }

  setProvisionalEnrolments(enrolments: EnrolmentResponse[]) {
    this.currentProvisionalEnrolmentsSubject.next(enrolments);
  }

  setConfirmationMessage(message: ConfirmationMessage) {
    this.confirmationMessageSubject.next(message);
  }

  setNewConfirmationMessage(message: string, enrolment?: Enrolment, paymentReference?: string) {
    const confirmationMessage = new ConfirmationMessage();
    confirmationMessage.message = message;
    confirmationMessage.enrolment = enrolment;
    confirmationMessage.paymentReference = paymentReference;
    this.setConfirmationMessage(confirmationMessage);
  }

  setReloadAbandonedCarts() {
    this.reloadAbandonedCartsSubject.next(true);
  }
}
