import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseListBasketService {

  currentScrollToBasketId: Observable<number>;
  private scrollToBasketIdSubject = new BehaviorSubject<number>(null);

  constructor() {
    this.currentScrollToBasketId = this.scrollToBasketIdSubject.asObservable();
  }

  scrollToBasketId(basketId: number) {
    this.scrollToBasketIdSubject.next(basketId);
  }
}
