import {Address} from './address';

export class PaymentMethod {
  cardId: string;
  cardholderName: string;
  trailingDigits: string;
  expiryMonth: number;
  expiryYear: number;
  address: Address;
  accountId: string;
  cardLength: number;
  licenseeId: string;
  cardType: string;
  stripeToken: string;
  paymentIntentId: string;
  cardErrors: string; // holding parameter
  cardHasErrors: boolean; // holding parameter
  paymentAddressId = 'other'; // holding parameter
  updateInvoicing: boolean;
}
