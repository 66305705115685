import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {BookingStartComponent} from './booking-start/booking-start.component';
import {FindCourseSingleComponent} from './course-search/find-course-single/find-course-single.component';
import {FindCourseMultipleComponent} from './course-search/find-course-multiple/find-course-multiple.component';
import {CoursesListSingleComponent} from './course-search/courses-list-single/courses-list-single.component';
import {CourseSingleComponent} from './course-search/course-single/course-single.component';
import {CartComponent} from './cart/cart.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {DirectDebitMandateRedirectComponent} from './checkout/direct-debit-mandate-redirect/direct-debit-mandate-redirect.component';
import {BookingCompleteComponent} from './booking-complete/booking-complete.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {DashboardBookingConfirmComponent} from './dashboard/dashboard-booking-confirm/dashboard-booking-confirm.component';
import {DashboardBookingCancelComponent} from './dashboard/dashboard-booking-cancel/dashboard-booking-cancel.component';
import {DashboardBookingsComponent} from './dashboard/dashboard-bookings/dashboard-bookings.component';
import {DashboardWaitingListComponent} from './dashboard/dashboard-waiting-list/dashboard-waiting-list.component';
import {DashboardWaitingListRequestComponent} from './dashboard/dashboard-waiting-list-request/dashboard-waiting-list-request.component';
import {DashboardCreditsComponent} from './dashboard/dashboard-credits/dashboard-credits.component';
import {DashboardStudentsComponent} from './dashboard/dashboard-students/dashboard-students.component';
import {DashboardUpgradesOffersComponent} from './dashboard/dashboard-upgrades-offers/dashboard-upgrades-offers.component';
import {DashboardAccountSettingsComponent} from './dashboard/dashboard-account-settings/dashboard-account-settings.component';
import {DashboardEditDirectDebitComponent} from './dashboard/dashboard-account-settings/dashboard-edit-direct-debit/dashboard-edit-direct-debit.component';
import {DashboardEditCardComponent} from './dashboard/dashboard-account-settings/dashboard-edit-card/dashboard-edit-card.component';
import {DashboardAddCardComponent} from './dashboard/dashboard-account-settings/dashboard-add-card/dashboard-add-card.component';
import {DashboardEditPersonalDetailsComponent} from './dashboard/dashboard-account-settings/dashboard-edit-personal-details/dashboard-edit-personal-details.component';
import {DashboardBookingStartComponent} from './course-search/dashboard-booking-start/dashboard-booking-start.component';
import {DashboardBookingManageComponent} from './dashboard/dashboard-booking-manage/dashboard-booking-manage.component';
import {DashboardBookingAttendingComponent} from './dashboard/dashboard-booking-attending/dashboard-booking-attending.component';
import {DashboardWaitingListAmendComponent} from './dashboard/dashboard-waiting-list-amend/dashboard-waiting-list-amend.component';
import {DashboardBookingCreditsUseComponent} from './course-search/dashboard-booking-credits-use/dashboard-booking-credits-use.component';
import {DashboardBookingCreditsUseConfirmComponent} from './course-search/dashboard-booking-credits-use-confirm/dashboard-booking-credits-use-confirm.component';
import {DashboardBookingConfirmCheckoutComponent} from './dashboard/dashboard-booking-confirm-checkout/dashboard-booking-confirm-checkout.component';
import {DashboardBookingTransferComponent} from './course-search/dashboard-booking-transfer/dashboard-booking-transfer.component';
import {DashboardBookingTransferConfirmComponent} from './course-search/dashboard-booking-transfer-confirm/dashboard-booking-transfer-confirm.component';
import {DashboardBookingTransferPaymentComponent} from './checkout/dashboard-booking-transfer-payment/dashboard-booking-transfer-payment.component';
import {DashboardBookingCreditsBuyComponent} from './checkout/dashboard-booking-credits-buy/dashboard-booking-credits-buy.component';
import {LostPasswordComponent} from './login/lost-password/lost-password.component';
import {RequestAccessComponent} from './login/request-access/request-access.component';
import {ActivateAccountComponent} from './login/activate-account/activate-account.component';
import {DashboardBookingUpgradeComponent} from './checkout/dashboard-booking-upgrade/dashboard-booking-upgrade.component';
import {DashboardPreSaleUpgradeComponent} from './checkout/dashboard-pre-sale-upgrade/dashboard-pre-sale-upgrade.component';
import {CourseListChangeLocationComponent} from './course-search/course-list-change-location/course-list-change-location.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {DashboardInvoicePaymentComponent} from './dashboard/dashboard-invoice-payment/dashboard-invoice-payment.component';
import {DashboardWaitingListCancelComponent} from './dashboard/dashboard-waiting-list-cancel/dashboard-waiting-list-cancel.component';
import {DashboardWaitingListExpiryComponent} from './dashboard/dashboard-waiting-list-expiry/dashboard-waiting-list-expiry.component';
import {CourseListReferralComponent} from './course-search/course-list-referral/course-list-referral.component';
import {AuthGuard} from './sso/AuthGuard';
import {DashboardBillingHistoryComponent} from './dashboard/dashboard-billing-history/dashboard-billing-history.component';
import {DashboardBookingIntensiveCreditUseComponent} from './course-search/dashboard-booking-intensive-credit-use/dashboard-booking-intensive-credit-use.component';
import {CertificateComponent} from './certificate/certificate.component';
import {SubscriptionComponent} from './subscription/subscription.component';
import {SurveyComponent} from './survey/survey.component';
import {ResumeCartComponent} from './cart/resume-cart/resume-cart.component';
import {RenewalSubscriptionComponent} from './subscription/renewal-subscription/renewal-subscription.component';
import {InvoicingConfirmationComponent} from './invoicing/invoicing-confirmation/invoicing-confirmation.component';
import {InvoiceConfirmationCompleteComponent} from './invoicing/invoice-confirmation-complete/invoice-confirmation-complete.component';
import {DashboardInvoiceCancelComponent} from './dashboard/dashboard-invoice-cancel/dashboard-invoice-cancel.component';
import {DashboardSubscriptionCancelComponent} from './dashboard/dashboard-subscription-cancel/dashboard-subscription-cancel.component';
import {DashboardSubscriptionsComponent} from './dashboard/dashboard-subscriptions/dashboard-subscriptions.component';

const roles = ['ROLE_ACCOUNT_CONTACT'];

const routes: Routes = [
  { path: '', redirectTo: '/booking_start', pathMatch: 'full' },
  { path: 'booking_start', component: BookingStartComponent },
  { path: 'find_course_single', component: FindCourseSingleComponent },
  { path: 'find_course_multiple', component: FindCourseMultipleComponent},
  { path: 'courses_list_single', component: CoursesListSingleComponent},
  { path: 'course_single/:id', component: CourseSingleComponent},
  { path: 'cart', component: CartComponent},
  { path: 'terms_and_conditions', component: TermsAndConditionsComponent},
  { path: 'privacy_policy', component: PrivacyPolicyComponent},
  { path: 'checkout', component: CheckoutComponent},
  { path: 'direct_debit_mandate_redirect', component: DirectDebitMandateRedirectComponent},
  { path: 'direct_debit_mandate_resume', component: DirectDebitMandateRedirectComponent},
  { path: 'booking_complete', component: BookingCompleteComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_confirm/:id', component: DashboardBookingConfirmComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_cancel/:id', component: DashboardBookingCancelComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_bookings', component: DashboardBookingsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_waiting_list', component: DashboardWaitingListComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_waiting_list_request', component: DashboardWaitingListRequestComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_credits', component: DashboardCreditsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_students', component: DashboardStudentsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_upgrades_offers', component: DashboardUpgradesOffersComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_account_settings', component: DashboardAccountSettingsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_edit_direct_debit/:accountId/:type/:paymentId', component: DashboardEditDirectDebitComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_edit_card/:id', component: DashboardEditCardComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_add_card/:id', component: DashboardAddCardComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_edit_personal_details', component: DashboardEditPersonalDetailsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_start', component: DashboardBookingStartComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_manage/:id', component: DashboardBookingManageComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_attending/:id', component: DashboardBookingAttendingComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_waiting_list_amend/:id', component: DashboardWaitingListAmendComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_credits_use/:id', component: DashboardBookingCreditsUseComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_credits_use_confirm/:id/:classId', component: DashboardBookingCreditsUseConfirmComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_intensive_credits_use_confirm/:id/:courseId', component: DashboardBookingIntensiveCreditUseComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_confirm_checkout/:id', component: DashboardBookingConfirmCheckoutComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_transfer/:id', component: DashboardBookingTransferComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_transfer_confirm/:enrolment_id/:course_id', component: DashboardBookingTransferConfirmComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_transfer_payment/:enrolment_id/:course_id', component: DashboardBookingTransferPaymentComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_booking_credits_buy', component: DashboardBookingCreditsBuyComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_billing_history', component: DashboardBillingHistoryComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'lost_password', component: LostPasswordComponent},
  { path: 'request_access', component: RequestAccessComponent},
  { path: 'activate_account/:type/:id', component: ActivateAccountComponent},
  { path: 'activate_account/:type', component: ActivateAccountComponent},
  { path: 'dashboard_booking_upgrade/:enrolmentId', component: DashboardBookingUpgradeComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_pre_sale_upgrade/:enrolmentId', component: DashboardPreSaleUpgradeComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'course_list_change_location', component: CourseListChangeLocationComponent},
  { path: 'dashboard_invoice_payment/:pendingPaymentId', component: DashboardInvoicePaymentComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_waiting_list_cancel/:waitingListId', component: DashboardWaitingListCancelComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_waiting_list_expiry/:waitingListPlacementId/:action', component: DashboardWaitingListExpiryComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'course_list_referral/:id', component: CourseListReferralComponent},
  { path: 'certificate/:id', component: CertificateComponent},
  { path: 'subscription/:id', component: SubscriptionComponent},
  { path: 'renewal/:id/subscription', component: RenewalSubscriptionComponent},
  { path: 'booking_survey/:bookingSurveyId', component: SurveyComponent},
  { path: 'survey/:id', component: SurveyComponent},
  { path: 'resume_cart/:abandonedCartId', component: ResumeCartComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'invoice/:pendingPaymentId/confirm', component: InvoicingConfirmationComponent},
  { path: 'invoice/:pendingPaymentId/cancel', component: DashboardInvoiceCancelComponent},
  { path: 'invoice_confirmation_complete', component: InvoiceConfirmationCompleteComponent},
  { path: 'dashboard_subscription_cancel/:id', component: DashboardSubscriptionCancelComponent, canActivate: [AuthGuard], data: {roles}},
  { path: 'dashboard_subscriptions', component: DashboardSubscriptionsComponent, canActivate: [AuthGuard], data: {roles}},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
