import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentMethod} from '../../_model/payment-method';
import {DirectDebitDetails} from '../../_model/direct-debit-details';
import {PolicyResult} from '../../_model/policy-result';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
// eslint-disable-next-line max-len
import {CheckoutPaymentMethodCardService} from '../../checkout/checkout-payment/checkout-payment-method-card/checkout-payment-method-card.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../../title-generator';
import {AlertService} from '../../alert';
import {BasketService} from '../../checkout/basket.service';
import {DashboardService} from '../dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Address} from '../../_model/address';
import {TermsAndConditionsValidator} from '../../_helpers/terms_and_conditions_validator';
import {getPriceTypeTitle} from '../../_helpers/enrolment_helper';
import {PendingPayment} from '../../_model/pending-payment';
import {PendingPaymentSettlementRequest} from '../../_model/pending-payment-settlement-request';
import {Account} from '../../_model/account';
import {PendingPaymentSettlementResponse} from '../../_model/pending-payment-settlement-response';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dashboard-invoice-payment',
  templateUrl: './dashboard-invoice-payment.component.html',
  styleUrls: ['./dashboard-invoice-payment.component.css']
})
export class DashboardInvoicePaymentComponent implements OnInit, OnDestroy, AfterViewInit {

  paymentForm: UntypedFormGroup;

  submitted = false;

  pendingPaymentId: string;
  pendingPayment: PendingPayment;
  sportsAccount: Account;
  previewPendingPaymentSettlement: PendingPaymentSettlementResponse;

  cardPayment = false;
  requiresMandate = false;
  addressLinesActive = false;

  cardPaymentMethod: PaymentMethod;
  directDebitDetails: DirectDebitDetails;

  termsAndConditionsPolicyId: string;
  termsAndConditions: PolicyResult;

  processingPayment = false;
  settingUpMandate = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  cardFormUpdateSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  voucherCodeSubscription: Subscription;

  stripe: Stripe;

  loading = false;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private basketService: BasketService,
              private dashboardService: DashboardService,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Dashboard Booking Confirm');

    this.paymentForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      creditCardSelect: [''],
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      enrolmentTermsCheckbox: ['']
    });

    this.cardPaymentMethod = new PaymentMethod();
    this.cardPaymentMethod.address = new Address();

    this.route.params.subscribe(params => {
      this.pendingPaymentId = params.pendingPaymentId;
      this.apiService.getPendingPayment(this.pendingPaymentId, this.ngUnsubscribe).subscribe(pendingPayment => {
        this.pendingPayment = pendingPayment;

        this.apiService.getAccount(pendingPayment.sportsAccountId, this.ngUnsubscribe).subscribe(sportsAccount => {
          this.sportsAccount = sportsAccount;

          const paymentTypes = [];
          paymentTypes.push('CARD');

          this.apiService.getPaymentGateways(paymentTypes,
            this.sportsAccount.licenseeId, this.sportsAccount.id, this.ngUnsubscribe).subscribe(paymentGateways => {
            paymentGateways.forEach(paymentGateway => {
              if (paymentGateway.paymentGatewayType === 'CARD') {
                this.cardPayment = true;

                const options = {};
                const apiVersion = environment.stripeApiVersion;

                if (apiVersion !== 'default') {
                  options['apiVersion'] = apiVersion;
                }

                loadStripe(paymentGateway.key, options).then(stripeResult => {
                  this.stripe = stripeResult;
                });
              }
            });
          });

          this.apiService.previewSettlePendingPayment(this.generatePendingPaymentSettlementObject(),
            this.ngUnsubscribe).subscribe(settlementResponse => {
            this.previewPendingPaymentSettlement = settlementResponse;
          });
        });
      });
    });

    this.cardFormUpdateSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.cardPaymentMethod = paymentMethod;
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && this.pendingPayment != null && !this.loading) {
        if (hasErrors) {
          this.processingPayment = false;
          this.spinner.hide();
        } else {
          this.payPendingPayment();
        }
      }
    });

    this.renderer.addClass(document.body, 'checkout-multiple');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'type-multi');

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;

      if (this.termsAndConditions != null) {
        this.termsAndConditionsPolicyId = this.termsAndConditions.id;
      }
    });

    this.setPaymentValidators();
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  setPaymentValidators() {
    let paymentValidator = null;
    let addressValidator = null;
    let creditCardAddressValidator = null;

    if (this.previewPendingPaymentSettlement != null && this.previewPendingPaymentSettlement.totalAmountWithBalance > 0) {
      paymentValidator = [Validators.required];

      if (this.cardPaymentMethod.cardId === 'new') {
        creditCardAddressValidator = [Validators.required];

        if (this.cardPaymentMethod.paymentAddressId === 'other') {
          addressValidator = [Validators.required];
        }
      } else {
        this.removeFormControlError('cardError');
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.termsAndConditions != null) {
      this.paymentForm.get('enrolmentTermsCheckbox').setValidators([TermsAndConditionsValidator()]);
    }

    this.paymentForm.get('creditCardSelect').setValidators(paymentValidator);
    this.paymentForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.paymentForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.paymentForm.get('addressLine1Input').setValidators(addressValidator);
    this.paymentForm.get('addressCityInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountyInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountryInput').setValidators(addressValidator);
    this.paymentForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.paymentForm.get('creditCardSelect').updateValueAndValidity();
    this.paymentForm.get('creditCardNameInput').updateValueAndValidity();
    this.paymentForm.get('paymentAddress').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressCityInput').updateValueAndValidity();
    this.paymentForm.get('addressCountyInput').updateValueAndValidity();
    this.paymentForm.get('addressCountryInput').updateValueAndValidity();
    this.paymentForm.get('addressPostcodeInput').updateValueAndValidity();
    this.paymentForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.cardFormUpdateSubscription != null) {
      this.cardFormUpdateSubscription.unsubscribe();
    }
    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }
    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }
    if (this.voucherCodeSubscription != null) {
      this.voucherCodeSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'checkout-multiple');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'type-multi');
  }

  generatePendingPaymentSettlementObject(): PendingPaymentSettlementRequest {
    const request = new PendingPaymentSettlementRequest();

    request.pendingPaymentId = this.pendingPaymentId;
    request.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    if (this.termsAndConditions != null) {
      request.termsAndConditionsPolicyId = this.termsAndConditions.id;
    }

    if (this.previewPendingPaymentSettlement != null && this.previewPendingPaymentSettlement.totalAmountWithBalance > 0) {
      request.cardId = this.cardPaymentMethod.cardId;
      request.paymentIntentId = this.cardPaymentMethod.paymentIntentId;
    }

    if (this.previewPendingPaymentSettlement != null) {
      request.quotedAmount = this.previewPendingPaymentSettlement.totalAmountWithBalance;
    }
    return request;
  }

  formatPriceTypeTitle(enrolmentType: string): string {
    return getPriceTypeTitle(enrolmentType);
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  onPaymentSubmit(): void {
    if (this.processingPayment) {
      return;
    }

    this.submitted = true;

    if (this.cardPaymentMethod.cardId === 'new' && this.cardPaymentMethod.cardHasErrors) {
      this.paymentForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
    }

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      if (!this.addressLinesActive) {
        this.addressLinesActive = !!(this.submitted
          && (this.paymentForm.controls.addressLine1Input.errors
            || this.paymentForm.controls.addressCityInput.errors
            || this.paymentForm.controls.addressCountyInput.errors
            || this.paymentForm.controls.addressCountryInput.errors
            || this.paymentForm.controls.addressPostcodeInput.errors));
      }

      return;
    }

    this.processingPayment = true;
    this.spinner.show();

    if (this.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else {
      this.payPendingPayment();
    }
  }

  payPendingPayment() {
    this.alertService.clear();

    if (this.pendingPayment == null) {
      return;
    }

    this.apiService.settlePendingPayment(this.generatePendingPaymentSettlementObject()).subscribe(response => {
      let receiptId = null;
      this.spinner.hide();

      if (response.result === 'success') {
        if (response.sportsPayment != null
          && response.sportsPayment.payment != null
          && response.sportsPayment.payment.code !== '') {
          receiptId = response.sportsPayment.payment.code;
          this.alertService.success('Enrolment complete, receipt number: ' + response.sportsPayment.payment.code);
        } else {
          this.alertService.success('Enrolment complete');
        }
        this.navigateToReceipt(receiptId);
      } else if (response.result === 'requires_action' && this.pendingPayment.type === 'INVOICE') {
        this.spinner.show();

        this.stripe.confirmCardPayment(
          response.paymentIntentClientSecret
        ).then(result => {
          if (result.error) {
            // Show error in payment form
            this.alertService.error(result.error.message);
            this.spinner.hide();
            this.processingPayment = false;
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server

            this.router.navigate(['/invoice_confirmation_complete'], {queryParams: {}});
          }
        });
      } else if (response.result === 'requires_action') {
        this.spinner.show();

        if (response.requiresPaymentAction) {
          console.log('requires payment action');
          this.checkoutPaymentMethodCardService.setHandleStripeAction(response.paymentIntentClientSecret);
        } else {
          console.error('Requires action, though action parameter not true');
          this.spinner.hide();
          this.processingPayment = false;
        }
      } else if (response.result === 'payment_error') {
        this.processingPayment = false;

        this.alertService.error(response.error);
      } else if (response.result === 'error') {
        this.processingPayment = false;

        if (response.error != null) {
          this.alertService.error(response.error);
        } else {
          this.alertService.error('An unknown error has occurred');
        }
      } else {
        this.processingPayment = false;

        console.error('Unknown response: ' + response.result);
      }
    });
  }

  navigateToReceipt(receiptId) {
    this.router.navigate(['/booking_complete'], {queryParams: {receiptId}});
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  getInvoiceDescription(): string {
    let description = '';
    for (const invoice of this.pendingPayment.pendingPaymentInvoices) {
      if (description !== '') {
        description += ', ';
      }
      description += invoice.invoice.description;
    }
    return description;
  }
}
