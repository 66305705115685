import {AfterContentInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {ApiService} from '../../api.service';
import {CourseListSingleServiceService} from './course-list-single-service.service';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseSearchResult} from '../../_model/course-search-result';
import {CourseRatio} from '../course-list-student-filter/course-ratio';
import {numberToWords} from 'number-to-words';
// eslint-disable-next-line max-len
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {Instructor} from '../course-list-student-filter/instructor';
import {StartTime} from '../course-list-student-filter/start-time';
import {CourseSingleService} from '../course-single/course-single.service';
import {CourseSearchUpdateRequest} from '../../_model/course-search-update-request';
import {TitleGenerator} from '../../title-generator';
import {isIterable} from '../../_helpers/general_helpers';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth-service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {AlertService} from '../../alert';

@Component({
  selector: 'app-courses-list-single',
  templateUrl: './courses-list-single.component.html',
  styleUrls: ['./courses-list-single.component.css']
})
export class CoursesListSingleComponent implements OnInit, OnDestroy, AfterContentInit {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  courseResults: CourseSearchResult[];
  students: CourseSearchStudent[];

  courseRatios: CourseRatio[];
  mondayExists = false;
  tuesdayExists = false;
  wednesdayExists = false;
  thursdayExists = false;
  fridayExists = false;
  saturdayExists = false;
  sundayExists = false;
  instructors: Instructor[];
  startTimes: StartTime[];

  selectedInstructorId: string;
  showInstructors = false;

  isSearchDisabled = false;
  multipleMode = false;

  runningCourseSearch = false;

  courseSearchParamsSubscription: Subscription;
  basketSubscription: Subscription;
  courseSearchResultsSubscription: Subscription;
  runSearchSubscription: Subscription;

  runSearch: boolean;
  initSearchRun = false;
  loading = true;

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private courseSingleService: CourseSingleService,
              private apiService: ApiService,
              private renderer: Renderer2,
              private sessionService: SessionService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private googleAnalytics: GoogleAnalyticsService,
              private alertService: AlertService) {
    this.students = sessionService.courseSearchParameters.students;
    this.loading = true;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Courses List');
    this.courseListStudentDetailsFilterService.studentDetailsFilterChangeUpdate.subscribe(hasValidUpdate => {
      if (hasValidUpdate != null && !this.loading) {
        this.courseResults = [];
        this.bookingHeaderTitleServiceService.setTitle('');
        if (hasValidUpdate) {
          this.setCourseSearch('full');
        }
      }
    });
    this.courseListStudentDetailsFilterService.filterChangeUpdate.subscribe(refreshRequested => {
      if (refreshRequested != null) {
        this.refreshCourses();
      }
    });
    this.courseSearchResultsSubscription = this.courseListSingleServiceService.currentCourseSearchResults.subscribe(courseResults => {
      if (courseResults != null) {
        this.courseResults = courseResults;
      }
    });
    this.runSearchSubscription = this.courseListSingleServiceService.currentRunSearch.subscribe(runType => {
      if (runType != null) {
        this.setCourseSearch(runType);
      }
    });

    this.bookingHeaderTitleServiceService.setTitle('');
    this.bookingHeaderTitleServiceService.setShowFilter(false);

    this.route.queryParams.subscribe(params => {
      this.selectedInstructorId = params.instructorId;
      const runSearchStr = params.runSearch;

      if (runSearchStr == null) {
        this.runSearch = true;
      } else {
        this.runSearch = runSearchStr === 'true';
      }

      if (this.runSearch && !this.initSearchRun) {
        this.setCourseSearch('full');
        this.initSearchRun = true;
      }
    });

    // If all variables are not set, then abort
    if (this.sessionService.courseSearchParameters == null
      || this.sessionService.courseSearchParameters.venueOptions == null
      || this.sessionService.courseSearchParameters.students == null
      || this.sessionService.courseSearchParameters.students.length === 0) {
      this.authService.isLoggedIn().subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.router.navigate(['/dashboard_booking_start']);
        } else {
          this.router.navigate(['/booking_start']);
        }
      });
    }
  }

  ngAfterContentInit() {
    if (!this.runSearch && this.courseResults == null) {
      console.log('No course results received and run search is disabled, running full search');
      this.setCourseSearch('full');
    }

    this.loading = false;
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.courseSearchParamsSubscription != null) {
      this.courseSearchParamsSubscription.unsubscribe();
    }
    if (this.basketSubscription != null) {
      this.basketSubscription.unsubscribe();
    }
    if (this.courseSearchResultsSubscription != null) {
      this.courseSearchResultsSubscription.unsubscribe();
    }
    if (this.runSearchSubscription != null) {
      this.runSearchSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard');
    if (this.multipleMode) {
      this.renderer.removeClass(document.body, 'courses-list-multiple');
      this.renderer.removeClass(document.body, 'type-multi');
    } else {
      this.renderer.removeClass(document.body, 'courses-list-single');
      this.renderer.removeClass(document.body, 'type-single');
    }
  }

  setCourseSearch(runType: string) {
    if (this.sessionService.courseSearchParameters == null) {
      console.error('Course search parameters not set for run');
      return;
    }

    console.log('Running search for ' + runType);

    this.renderer.addClass(document.body, 'index-courses');

    this.multipleMode = this.getCourseSearchStudents().length > 1;

    if (this.multipleMode) {
      this.renderer.addClass(document.body, 'courses-list-multiple');
      this.renderer.addClass(document.body, 'type-multi');
    } else {
      this.renderer.addClass(document.body, 'courses-list-single');
      this.renderer.addClass(document.body, 'type-single');
    }

    this.bookingHeaderTitleServiceService.setTitle('');

    this.isSearchDisabled = true;

    if (runType === 'full') {
      // cancel an running requests
      this.ngUnsubscribe.next();

      this.runningCourseSearch = true;

      const courseSearchParameters = this.getCourseSearchParameters();
      if (courseSearchParameters == null) {
        return;
      }
      this.apiService.searchForCourses(courseSearchParameters, this.ngUnsubscribe).subscribe((data) => {
        this.courseResults = data;
        this.runningCourseSearch = false;

        this.filterCourseSearchResults();
        this.refreshCourses();
        this.isSearchDisabled = false;

        this.getCourseSearchStudents().forEach(courseStudent => {
          this.googleAnalytics.search(courseStudent.programLevelName, 'New Enrolment');
        });
      });
    } else if (runType === 'refresh') {
      this.filterCourseSearchResults();
      this.refreshCourses();
      this.isSearchDisabled = false;
    }
  }

  getCourseSearchParameters(): CourseSearchParameters {
    if (this.sessionService.courseSearchParameters.days != null) {
      // If the days were not available (i.e. no manually unchecked), then re-add them.
      if (!this.mondayExists && !this.sessionService.courseSearchParameters.days.includes(2)) {
        this.sessionService.courseSearchParameters.days.push(2);
      }
      if (!this.tuesdayExists && !this.sessionService.courseSearchParameters.days.includes(3)) {
        this.sessionService.courseSearchParameters.days.push(3);
      }
      if (!this.wednesdayExists && !this.sessionService.courseSearchParameters.days.includes(4)) {
        this.sessionService.courseSearchParameters.days.push(4);
      }
      if (!this.thursdayExists && !this.sessionService.courseSearchParameters.days.includes(5)) {
        this.sessionService.courseSearchParameters.days.push(5);
      }
      if (!this.fridayExists && !this.sessionService.courseSearchParameters.days.includes(6)) {
        this.sessionService.courseSearchParameters.days.push(6);
      }
      if (!this.saturdayExists && !this.sessionService.courseSearchParameters.days.includes(7)) {
        this.sessionService.courseSearchParameters.days.push(7);
      }
      if (!this.sundayExists && !this.sessionService.courseSearchParameters.days.includes(1)) {
        this.sessionService.courseSearchParameters.days.push(1);
      }
    }

    if (this.sessionService.courseSearchParameters.venueOptions.venueId == null
      && (this.sessionService.courseSearchParameters.venueOptions.latitude == null
        || this.sessionService.courseSearchParameters.venueOptions.longitude == null
        || this.sessionService.courseSearchParameters.venueOptions.distance == null)) {
      this.alertService.error('Location is required');
      return null;
    }
    return this.sessionService.courseSearchParameters;
  }

  filterCourseSearchResults(): void {
    // Get a list of basket items to check against
    const courseStudentList = [];
    if (this.sessionService.basket != null && this.sessionService.basket.students != null) {
      this.sessionService.basket.students.forEach(student => {
        courseStudentList.push(student.course.id + student.student.id);
      });
    }

    this.courseRatios = [];
    this.instructors = [];
    this.startTimes = [];

    this.mondayExists = false;
    this.tuesdayExists = false;
    this.wednesdayExists = false;
    this.thursdayExists = false;
    this.fridayExists = false;
    this.saturdayExists = false;
    this.sundayExists = false;

    const courseSearchDays = [];

    let counter = 0;
    if (isIterable(this.courseResults)) {
      this.courseResults.forEach(course => {

        let requiresUpdate = false;
        const updateRequest = new CourseSearchUpdateRequest();
        updateRequest.students = [];

        course.courseSearchStudents.forEach(courseSearchStudent => {
          let inBasket = false;

          if (courseStudentList.includes(course.course.id + courseSearchStudent.id)) {
            inBasket = true;
            requiresUpdate = true;
          }

          this.getCourseSearchStudents().forEach(result => {
            if (result.id === courseSearchStudent.id) {
              result.toEnrol = inBasket;

              // Need to clone the object otherwise it will override with the next iteration
              const newResult = new CourseSearchStudent();
              newResult.id = result.id;
              newResult.studentId = result.studentId;
              newResult.toEnrol = inBasket;
              newResult.programId = result.programId;
              newResult.programLevelId = result.programLevelId;
              newResult.dateOfBirth = result.dateOfBirth;

              updateRequest.students.push(newResult);
            }
          });
        });

        if (requiresUpdate) {
          updateRequest.courseId = course.course.id;

          this.apiService.updateCourseSearchStudentEnrolment(updateRequest).subscribe(result => {
            result.courseSearchStudents.forEach(resultStudent => {
              course.courseSearchStudents.forEach(courseSearchStudentItem => {
                if (courseSearchStudentItem.id === resultStudent.id) {
                  courseSearchStudentItem.canEnrol = resultStudent.canEnrol;
                }
              });
            });
          });
        }

        let numberOfStudentsString;

        if (course.course.numberOfStudentsPerInstructor === 1) {
          numberOfStudentsString = 'Personal Session';
        } else {
          numberOfStudentsString = 'Group of ' + numberToWords.toWords(course.course.numberOfStudentsPerInstructor);
        }

        let courseRatio = new CourseRatio();
        courseRatio.numberOfCourses = 0;

        let ratioIndex = -1;
        for (let i = 0; i < this.courseRatios.length; i++) {
          if (this.courseRatios[i].numberOfStudentsPerInstructor === course.course.numberOfStudentsPerInstructor
            && this.courseRatios[i].classDuration === course.course.classDurationInMinutes) {
            courseRatio = this.courseRatios[i];
            ratioIndex = i;
            break;
          }
        }

        courseRatio.classDuration = course.course.classDurationInMinutes;
        courseRatio.numberOfStudentsPerInstructor = course.course.numberOfStudentsPerInstructor;
        courseRatio.numberOfStudentsPerInstructorText = numberOfStudentsString;
        courseRatio.numberOfCourses = courseRatio.numberOfCourses + 1;
        courseRatio.id = course.course.numberOfStudentsPerInstructor + '_' + course.course.classDurationInMinutes;

        if (ratioIndex > -1) {
          this.courseRatios.splice(ratioIndex, 1, courseRatio);
        } else {
          this.courseRatios.push(courseRatio);
        }

        for (const dayNumber of course.course.dayOfTheWeekNumbers) {
          if (!courseSearchDays.includes(dayNumber)) {
            courseSearchDays.push(dayNumber);
          }
          switch (dayNumber) {
            case 2: {
              this.mondayExists = true;
              break;
            }
            case 3: {
              this.tuesdayExists = true;
              break;
            }
            case 4: {
              this.wednesdayExists = true;
              break;
            }
            case 5: {
              this.thursdayExists = true;
              break;
            }
            case 6: {
              this.fridayExists = true;
              break;
            }
            case 7: {
              this.saturdayExists = true;
              break;
            }
            case 1: {
              this.sundayExists = true;
              break;
            }

          }
        }

        let foundInstructor = false;
        this.instructors.forEach(instructor => {
          if (course.course.instructorId === instructor.id) {
            foundInstructor = true;
          }
        });

        if (!foundInstructor) {
          const courseInstructor = new Instructor();
          courseInstructor.id = course.course.instructorId;
          courseInstructor.displayName = course.instructorDisplayName;

          let isChecked = true;
          if (this.selectedInstructorId != null && this.selectedInstructorId !== '') {
            isChecked = this.selectedInstructorId === courseInstructor.id;
          }

          courseInstructor.checked = isChecked;

          this.instructors.push(courseInstructor);
        }

        let foundTime = false;
        const startTimeHour = course.course.startTimeHour;

        this.startTimes.forEach(startTime => {
          if (startTimeHour === startTime.hour) {
            foundTime = true;
          }
        });

        if (!foundTime) {
          const startTime = new StartTime();
          startTime.hour = startTimeHour;
          startTime.checked = true;

          const startDate = new Date();
          startDate.setHours(startTime.hour, 0, 0);
          startTime.fromTime = startDate;

          let toValue = startTime.hour + 1;
          if (toValue === 24) {
            toValue = 0;
          }

          const endDate = new Date();
          endDate.setHours(toValue, 0, 0);

          startTime.toTime = endDate;

          this.startTimes.push(startTime);
        }

        counter++;
      });

      this.showInstructors = this.selectedInstructorId != null && this.selectedInstructorId !== '';

      if (this.showInstructors) {
        // If the instructor doesn't exist, show them all
        let foundSet = false;
        this.instructors.forEach(instructor => {
          if (instructor.checked) {
            foundSet = true;
          }
        });

        if (!foundSet) {
          console.log('No instructor found for selected instructor, resetting');
          this.instructors.forEach(instructor => {
            instructor.checked = true;
          });
        }

      }


      this.selectedInstructorId = null;
    }

    this.startTimes.sort((t1, t2) => {
      if (t1.hour > t2.hour) {
        return 1;
      }
      if (t1.hour < t2.hour) {
        return -1;
      }
      return 0;
    });

    this.instructors.sort((t1, t2) => {
      if (t1.displayName > t2.displayName) {
        return 1;
      }
      if (t1.displayName < t2.displayName) {
        return -1;
      }
      return 0;
    });

    this.courseRatios.sort((t1, t2) => {
      if (t1.numberOfCourses > t2.numberOfCourses) {
        return 1;
      }
      if (t1.numberOfCourses < t2.numberOfCourses) {
        return -1;
      }
      return 0;
    });

    this.sessionService.courseSearchParameters.days = courseSearchDays;
    this.setCoursesFound(counter);
  }

  refreshCourses() {
    if (this.courseResults != null) {
      let totalCourses = this.courseResults.length;
      if (isIterable(this.courseResults)) {
        for (const courseResult of this.courseResults) {
          courseResult.show = this.canShowCourse(courseResult);
          if (!courseResult.show) {
            totalCourses--;
          }
        }
      }
      this.setCoursesFound(totalCourses);
    }
  }

  setCoursesFound(totalCourses: number): void {
    let termType = 'weekly';
    if (this.sessionService.courseSearchParameters.termType === 'FAST_TRACK') {
      termType = 'intensive';
    }

    this.bookingHeaderTitleServiceService.setTitle(totalCourses + ' ' + termType + ' courses found');
  }

  canShowCourse(courseSearchResult: CourseSearchResult): boolean {
    let foundDay = false;

    let canShow = false;
    for (const student of this.getCourseSearchStudents()) {
      for (const courseStudent of courseSearchResult.courseSearchStudents) {
        if (student.id === courseStudent.id && student.checked) {
          canShow = true;
        }
      }
    }

    if (!canShow) {
      return false;
    }

    // If there is more than one student and you are switching between licensees, then lock it in
    if (this.sessionService.basket != null
      && this.sessionService.basket.licenseeId != null
      && this.getCourseSearchStudents().length > 1) {
      if (courseSearchResult.course.licenseeId !== this.sessionService.basket.licenseeId) {
        return false;
      }
    }

    if (this.sessionService.courseSearchParameters.days == null) {
      return false;
    }

    for (const dayNumber of courseSearchResult.course.dayOfTheWeekNumbers) {
      if (this.sessionService.courseSearchParameters.days.includes(dayNumber)) {
        foundDay = true;
        break;
      }
    }

    if (!foundDay) {
      return false;
    }

    for (const ratio of this.courseRatios) {
      if (!ratio.checked
        && ratio.numberOfStudentsPerInstructor === courseSearchResult.course.numberOfStudentsPerInstructor
        && ratio.classDuration === courseSearchResult.course.classDurationInMinutes) {
        return false;
      }
    }

    for (const instructor of this.instructors) {
      if (!instructor.checked
          && instructor.id === courseSearchResult.course.instructorId) {
        return false;
      }
    }

    for (const startTime of this.startTimes) {
      if (!startTime.checked
        && startTime.hour === courseSearchResult.course.startTimeHour) {
        return false;
      }
    }

    return !(this.sessionService.courseSearchParameters.courseType != null
      && courseSearchResult.course.courseType !== this.sessionService.courseSearchParameters.courseType);
  }

  getCourseSearchStudents(): CourseSearchStudent[] {
    if (this.sessionService.courseSearchParameters == null || this.sessionService.courseSearchParameters.students == null) {
      return [];
    } else {
      return this.sessionService.courseSearchParameters.students;
    }
  }
}
