import {convertObjectToDate} from '../_helpers/date_helper';

export class Person {
  dob: Date;
  email: string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  name: string;
  optInMarketingEmails: boolean;
  optInMarketingPartners: boolean;
  optInMarketingPhone: boolean;
  optInMarketingSMS: boolean;
  optInOperationsInfoEmail: boolean;
  optInOperationsInfoPhone: boolean;
  optInOperationsInfoSMS: boolean;
  personId: string;
  telephoneHome: string;
  telephoneMobile: string;
  title: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.dob = convertObjectToDate(this.dob);
  }
}
