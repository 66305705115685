import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Account} from '../../_model/account';
import {TitleGenerator} from '../../title-generator';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-dashboard-subscriptions',
  templateUrl: './dashboard-subscriptions.component.html',
  styleUrls: ['./dashboard-subscriptions.component.css']
})
export class DashboardSubscriptionsComponent implements OnInit, OnDestroy {

  accounts: Account[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Subscriptions');

    this.accounts = [];
    this.apiService.getAccounts(null, this.ngUnsubscribe).subscribe(accounts => {
      this.accounts = accounts;
    });

    this.renderer.addClass(document.body, 'dashboard-subscriptions');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-subscriptions');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }
}
