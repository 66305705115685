<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_waiting_list']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              cancel waiting list request					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_waiting_list']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container" *ngIf="waitingListEntry != null">

          <div class="panel panel-dashboard">

            <div class="span-title">
              <h2 class="entry-title">
                Are you sure?					</h2>
              <div class="entry-content">
                <p>Your waiting list entry for {{waitingListEntry.waitingListEntry.student.givenName}} will be removed and we will stop searching for a place.</p>
              </div>
            </div>


            <button type="button" class="btn btn-block btn-red wl-button" (click)="removeWaitingList($event)" [ngClass]="running ? 'disabled' : ''">
              Yes, cancel this waiting list request				</button>

          </div>

      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
