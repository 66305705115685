import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FindCourseSingleServiceService} from './find-course-single-service.service';
import {SelectProgramService} from '../select-program/select-program.service';
import {SelectProgramLevelService} from '../select-program-level/select-program-level.service';
import {SelectLocationService} from '../select-location/select-location.service';
import {SelectDateOfBirthService} from '../select-date-of-birth/select-date-of-birth.service';
import {VenueOptions} from '../select-location/select-location.component';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {Program} from '../../_model/program';
import {TitleGenerator} from '../../title-generator';
import {Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-find-course-single',
  templateUrl: './find-course-single.component.html',
  styleUrls: ['./find-course-single.component.css']
})
export class FindCourseSingleComponent implements OnInit, OnDestroy, AfterViewInit {
  dateOfBirth: Date;
  program: Program;
  programLevelId: string;
  programLevelName: string;
  programShowLevelChecker: boolean;
  venueOptions: VenueOptions;

  stepOne = true;
  stepTwo = false;
  stepThree = false;
  stepFour = false;

  instructorId: string;

  loading = true;

  stepUpdateSubscription: Subscription;
  programSubscription: Subscription;
  dateOfBirthSubscription: Subscription;
  programLevelSubscription: Subscription;
  venueOptionsSubscription: Subscription;

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private selectProgramService: SelectProgramService,
              private selectProgramLevelService: SelectProgramLevelService,
              private selectLocationService: SelectLocationService,
              private selectDateOfBirthService: SelectDateOfBirthService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private renderer: Renderer2,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private findCourseSingleServiceService: FindCourseSingleServiceService,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private apiService: ApiService) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.stepUpdateSubscription = this.findCourseSingleServiceService.currentStepUpdate.subscribe(message => this.setPageStep(message));
    this.programSubscription = this.selectProgramService.currentProgram.subscribe(programId => this.onProgramSelected(programId));
    this.dateOfBirthSubscription = this.selectDateOfBirthService.currentDateOfBirth.subscribe(dateOfBirth => {
      this.onDobSubmit(dateOfBirth);
    });
    this.programLevelSubscription = this.selectProgramLevelService.currentProgramLevelId
      .subscribe(programLevelId => this.onProgramLevelSelected(programLevelId));
    this.venueOptionsSubscription = this.selectLocationService.currentVenueOptions
      .subscribe(venueOptions => this.onVenueSelected(venueOptions));

    this.sessionService.removeBasket();
    this.sessionService.removeCourseSearchParameters();

    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'type-single');
    this.titleService.setTitle('Find Course');
    this.bookingHeaderTitleServiceService.setTitle('find the perfect course');

    this.route.queryParams.subscribe(params => {
      if (params.venueId != null) {
        this.venueOptions = new VenueOptions();
        this.venueOptions.venueId = params.venueId;
      }
      this.instructorId = params.instructorId;
    });

    this.doStep1();
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.stepUpdateSubscription != null) {
      this.stepUpdateSubscription.unsubscribe();
    }
    if (this.programSubscription != null) {
      this.programSubscription.unsubscribe();
    }
    if (this.dateOfBirthSubscription != null) {
      this.dateOfBirthSubscription.unsubscribe();
    }
    if (this.programLevelSubscription != null) {
      this.programLevelSubscription.unsubscribe();
    }
    if (this.venueOptionsSubscription != null) {
      this.venueOptionsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'find-course');
    this.renderer.removeClass(document.body, 'type-single');
  }

  doStep1() {
    this.updateStepNumber(1);
    this.selectDateOfBirthService.setTitle('Firstly, what’s the student’s date of birth?');
  }

  setPageStep(stepNumber) {
    if (stepNumber == null) {
      return;
    }

    switch (stepNumber) {
      case 1:
        this.doStep1();
        break;
      case 2:
        this.onDobSubmit(this.dateOfBirth);
        break;
      case 3:
        this.doOnProgramSelected(this.program, this.programShowLevelChecker);
        break;
      case 4:
        this.onProgramLevelSelected(this.programLevelId);
        break;
      default:
        console.error('Invalid step number: ' + stepNumber);
    }
  }

  updateStepNumber(stepNumber) {
    this.stepOne = stepNumber === 1;
    this.stepTwo = stepNumber === 2;
    this.stepThree = stepNumber === 3;
    this.stepFour = stepNumber === 4;
  }

  onDobSubmit(dateOfBirth: Date) {
    if (dateOfBirth == null || !this.stepOne || this.loading) {
      return;
    }

    this.dateOfBirth = dateOfBirth;

    this.updateStepNumber(2);
    this.selectProgramService.setTitle('Now select a programme…');
  }

  onProgramSelected(selectedProgram: Program) {
    if (selectedProgram == null || this.loading) {
      return;
    }
    this.doOnProgramSelected(selectedProgram, selectedProgram.hasLevelFinder);
  }

  doOnProgramSelected(program: Program, programShowLevelChecker: boolean) {
    this.updateStepNumber(3);
    this.program = program;
    this.programShowLevelChecker = programShowLevelChecker;

    this.selectProgramLevelService.setTitle('And what’s their skill level?');

    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    studentParameters.id = 1;
    studentParameters.dateOfBirth = this.dateOfBirth;
    studentParameters.programId = this.program.id;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    parameters.students = students;
  }

  onProgramLevelSelected(selectedProgramLevelId: string) {
    if (selectedProgramLevelId === '' || selectedProgramLevelId == null || this.loading) {
      return;
    }

    this.programLevelId = selectedProgramLevelId;

    this.apiService.getProgramLevel(selectedProgramLevelId)
      .subscribe(programLevel => this.programLevelName = programLevel.programLevelName);

    if (this.venueOptions != null && this.venueOptions.venueId != null) {
      this.navigateToResults();
      return;
    }

    this.updateStepNumber(4);
    this.selectLocationService.setTitle('Lastly, what’s your preferred location?');
  }

  onVenueSelected(venueOptions: VenueOptions) {
    if (venueOptions == null || this.loading) {
      return;
    }

    this.venueOptions = venueOptions;
    this.navigateToResults();
  }

  navigateToResults() {
    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    if (this.sessionService.courseSearchTermType != null) {
      parameters.termType = this.sessionService.courseSearchTermType;
      this.sessionService.courseSearchTermType = null;
    }

    studentParameters.id = 1;
    studentParameters.dateOfBirth = this.dateOfBirth;
    studentParameters.programId = this.program.id;
    studentParameters.programLevelId = this.programLevelId;
    studentParameters.programLevelName = this.programLevelName;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    parameters.students = students;
    parameters.venueOptions = this.venueOptions;

    this.sessionService.courseSearchParameters = parameters;
    this.sessionService.saveCourseSearchParameters();

    const runSearch = true;
    const instructorId = this.instructorId;

    this.router.navigate(['courses_list_single'], {queryParams: {runSearch, instructorId}});
  }
}

