export class Address {
  postCode: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  organisation: string;
  latitude: number;
  longitude: number;
  state: string;
  country: string;
  locality: string;
  addressId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
