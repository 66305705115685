import {Course} from './course';
import {Student} from './student';
import {Class} from './class';
import {Booking} from './booking';
import {Account} from './account';
import {convertObjectToDate} from '../_helpers/date_helper';
import {Term} from './term';

export class EnrolmentResponse {
  enrolmentId: string;
  provisionalExpiry: Date;
  account: Account;
  course: Course;
  nextClass: Class;
  enrolmentStatus: string;
  enrolmentType: string;
  bookings: Booking[];
  student: Student;
  startDate: Date;
  endDate: Date;
  enrolmentLastTermEndDate: Date;
  terms: Term[];
  canUpgradeToMultipleTerms: boolean;
  autoEnrolmentTransactionDate: Date;

  selected = false;
  viewBookings = false;

  discount: number; // These are here to hold from separate query
  currencyCode: string; // These are here to hold from separate query

  hasPendingPayment: boolean;
  pendingPaymentIds: string[];

  previousEnrolmentType: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.provisionalExpiry = convertObjectToDate(this.provisionalExpiry);
      this.startDate = convertObjectToDate(this.startDate);
      this.endDate = convertObjectToDate(this.endDate);
      this.enrolmentLastTermEndDate = convertObjectToDate(this.enrolmentLastTermEndDate);
      this.autoEnrolmentTransactionDate = convertObjectToDate(this.autoEnrolmentTransactionDate);

      if (this.nextClass != null) {
        this.nextClass.startDate = convertObjectToDate(this.nextClass.startDate);
        this.nextClass.endDate = convertObjectToDate(this.nextClass.endDate);
      }
      if (this.bookings != null) {
        this.bookings.forEach(booking => {
          booking.expiryDate = convertObjectToDate(booking.expiryDate);
        });
      }
      if (this.course != null) {
        this.course.startTimeDate = convertObjectToDate(this.course.startTimeDate);
        this.course.endTimeDate = convertObjectToDate(this.course.endTimeDate);
      }
    }
  }
}
