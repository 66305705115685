import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {DashboardService} from '../dashboard.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {SportsCreditStudentResult} from '../../_model/sports-credit-student-result';
import {SportsCreditResult} from '../../_model/sports-credit-result';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {SessionService} from '../../session.service';
import {VenueOptions} from '../../course-search/select-location/select-location.component';

@Component({
  selector: 'app-dashboard-upgrades-offers',
  templateUrl: './dashboard-upgrades-offers.component.html',
  styleUrls: ['./dashboard-upgrades-offers.component.scss']
})
export class DashboardUpgradesOffersComponent implements OnInit, OnDestroy {

  provisionalUpgrades: EnrolmentResponse[];
  confirmedUpgrades: EnrolmentResponse[];
  provisionalEnrolments: EnrolmentResponse[];
  earlyBirdEnrolments: EnrolmentResponse[];
  credits: SportsCreditStudentResult[];
  noOffers = true;
  loadingOffers = true;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private sessionService: SessionService,
              private router: Router) {}

  generateEnrolmentResponse(enrolment: EnrolmentResponse, discount: number, currencyCode: string): EnrolmentResponse {
    const newEnrolment = new EnrolmentResponse();
    newEnrolment.student = enrolment.student;
    newEnrolment.enrolmentId = enrolment.enrolmentId;
    newEnrolment.discount = discount;
    newEnrolment.currencyCode = currencyCode;
    newEnrolment.provisionalExpiry = enrolment.provisionalExpiry;
    newEnrolment.course = enrolment.course;

    return newEnrolment;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Upgrades and Offers');

    this.provisionalUpgrades = [];
    this.confirmedUpgrades = [];
    this.provisionalEnrolments = [];
    this.earlyBirdEnrolments = [];

    const now = new Date();

    this.apiService.getEnrolments(null, null, null, null, this.ngUnsubscribe).subscribe(enrolments => {
      let enrolmentsLoaded = 0;
      for (const enrolment of enrolments) {
        if (enrolment.endDate > now
          && (enrolment.enrolmentStatus === 'PROVISIONAL'
          || enrolment.enrolmentType === 'SINGLE_TERM'
          || enrolment.enrolmentType === 'MULTIPLE_TERMS')) {
          this.apiService.getEnrolmentPrices(enrolment.enrolmentId, this.ngUnsubscribe).subscribe(priceList => {
            let hasBeenAdded = false;
            if (priceList.prices != null) {
              priceList.prices.forEach(price => {
                if (enrolment.enrolmentStatus === 'PROVISIONAL' && price.priceType === 'MULTIPLE_TERMS') {
                  this.provisionalUpgrades.push(this.generateEnrolmentResponse(enrolment, price.discount, priceList.currencyCode));
                  hasBeenAdded = true;
                } else if (enrolment.enrolmentStatus === 'CONFIRMED'
                  && price.priceType === 'MULTIPLE_TERMS'
                  && enrolment.canUpgradeToMultipleTerms) {
                  this.confirmedUpgrades.push(this.generateEnrolmentResponse(enrolment, price.discount, priceList.currencyCode));
                  hasBeenAdded = true;
                } else if (enrolment.enrolmentStatus === 'CONFIRMED' && price.priceType === 'PRE_SALE') {
                  this.earlyBirdEnrolments.push(this.generateEnrolmentResponse(enrolment, price.discount, priceList.currencyCode));
                  hasBeenAdded = true;
                }
              });
            }

            if (hasBeenAdded) {
              this.noOffers = false;
            }

            if (!hasBeenAdded && enrolment.enrolmentStatus === 'PROVISIONAL') {
              this.provisionalEnrolments.push(enrolment);
            }

            this.checkIfOfferLoadingComplete(++enrolmentsLoaded, enrolments.length);
          }, () => {
            this.checkIfOfferLoadingComplete(++enrolmentsLoaded, enrolments.length);
          });
        } else {
          this.checkIfOfferLoadingComplete(++enrolmentsLoaded, enrolments.length);
        }
      }
    });

    const creditTypes = [];
    creditTypes.push('EnrolmentDiscount');
    this.apiService.getStudentCredits(null, null, creditTypes, this.ngUnsubscribe).subscribe(credits => {
      this.credits = credits;
    });

    this.renderer.addClass(document.body, 'dashboard-upgrades-offers');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  checkIfOfferLoadingComplete(enrolmentsLoaded: number, enrolmentLength: number): void {
    if (enrolmentsLoaded === enrolmentLength) {
      this.loadingOffers = false;
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-upgrades-offers');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'instructors/' + instructorId + '/image\')';
  }

  confirmBooking(provisional: EnrolmentResponse) {
    this.router.navigate(['/dashboard_booking_confirm', provisional.enrolmentId]).then(() => {
      this.dashboardService.setProvisionalEnrolments(this.provisionalEnrolments);
    });
  }

  getDiscountPercentage(credit: SportsCreditResult): string {
    if ((credit.discount.amount % 1) === 0) {
      return Math.round(credit.discount.amount) + '%';
    } else {
      return credit.discount.amount.toFixed(1) + '%';
    }
  }

  getDiscountConditions(credit: SportsCreditResult): string {
    let returnText = credit.minNumberOfStudentsPerInstructor.toString();

    if (credit.minNumberOfStudentsPerInstructor !== credit.maxNumberOfStudentsPerInstructor) {
      returnText += ' - ' + credit.maxNumberOfStudentsPerInstructor;
    }

    returnText += ' students per class, ' + credit.minClassDurationInMinutes;

    if (credit.minClassDurationInMinutes !== credit.maxClassDurationInMinutes) {
      returnText += ' - ' + credit.maxClassDurationInMinutes;
    }

    returnText += ' minutes';

    return returnText;
  }

  discountSearch(creditStudent: SportsCreditStudentResult, credit: SportsCreditResult) {
    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    studentParameters.id = 1;
    studentParameters.studentId = creditStudent.student.id;
    studentParameters.programId = credit.programId;
    studentParameters.programLevelId = credit.studentLevelId;
    studentParameters.programLevelName = credit.studentLevelName;
    studentParameters.studentName = creditStudent.student.givenName + ' ' + creditStudent.student.familyName;
    studentParameters.dateOfBirth = creditStudent.student.dateOfBirth;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    parameters.students = students;
    parameters.venueOptions = new VenueOptions();
    parameters.venueOptions.venueId = credit.venueId;
    parameters.termType = credit.termType;

    this.sessionService.courseSearchParameters = parameters;
    this.sessionService.saveCourseSearchParameters();

    const runSearch = true;
    this.router.navigate(['courses_list_single'], {queryParams: {runSearch}});
  }
}
