<div class="lead">
  <h1 class="title">Account Subscriptions - {{account.accountNumber}}</h1>
</div>

<div class="wgt wgt-dashboard wgt-account-payments-pending" *ngIf="recurringBillingItems != null">
  <div class="card">

    <div class="item-single" *ngFor="let recurringBillingItem of recurringBillingItems">
      <div class="container">
        <div class="row">

          <div class="span-graphic">
            <div class="badge-wrap green">
              <div class="badge-text">direct debit<span class="direct-debit-cancelled-ribbon" *ngIf="recurringBillingItem.endDate != null">CANCELLED</span></div>
            </div>
          </div>

          <div class="span-content">
            <ul class="list-content">
              <li>
                <ul class="list-inline" *ngIf="recurringBillingItem.lastEnrolment != null">
                  <li id="student_full_name_0">{{recurringBillingItem.lastEnrolment.bookableEntity.displayName}}</li>
                  <li> on </li>
                  <li id="term_days_0">{{recurringBillingItem.lastEnrolment.course.fullDayOfWeekNames}}</li>
                  <li> at </li>
                  <li id="term_location_0">{{recurringBillingItem.lastEnrolment.course.venueName}}</li>
                </ul>
              </li>
              <li>
                <ul class="list-inline">
                  <li id="term_cost_zero" *ngIf="recurringBillingItem.plan.price == null">{{0 | currency:account.currency:'symbol-narrow'}}</li>
                  <li id="term_cost_0" *ngIf="recurringBillingItem.plan.price != null">{{recurringBillingItem.plan.price.amount | currency:account.currency:'symbol-narrow'}}</li>
                  <li> / month. Next payment date is </li>
                  <li id="next_payment_date_invoice" *ngIf="recurringBillingItem.subscriptionNextPayment == null && recurringBillingItem.latestTerm != null">{{recurringBillingItem.latestTerm.startDateTime | worldDate: 'd MMM yyy'}}</li>
                  <li id="next_payment_date_subscription" *ngIf="recurringBillingItem.subscriptionNextPayment != null">{{recurringBillingItem.subscriptionNextPayment | worldDate: 'd MMM yyy'}}<span *ngIf="recurringBillingItem.endDate != null">. Ends {{recurringBillingItem.endDate | worldDate: 'd MMM yyy'}})</span></li>
                </ul>
              </li>
              <li *ngIf="recurringBillingItem.paymentType === 'direct_debit'">Mandate</li>
              <li *ngIf="recurringBillingItem.paymentType === 'direct_debit'" id="biling_postcode">{{getMandateDescription(recurringBillingItem)}}</li>
              <li *ngIf="recurringBillingItem.paymentType === 'credit_card'">Card</li>
              <li *ngIf="recurringBillingItem.paymentType === 'credit_card'" id="credit_card">{{getCreditCardName(recurringBillingItem)}}</li>
            </ul>
          </div>

          <div class="span-buttons icon-button">
            <a [routerLink]="[]" queryParamsHandling="preserve" (click)="editDirectDebitDetails(recurringBillingItem)" class="btn btn-pencil">
              <span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-pencil"></use></svg></span>						</a>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
