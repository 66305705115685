import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {checkStrength, PasswordStrengthValidator, PasswordValidator} from '../../_helpers/password_validator';
import {EmailValidator} from '../../_helpers/email_validator';
import {BasketService} from '../basket.service';
import {PolicyResult} from '../../_model/policy-result';
import {ApiService} from '../../api.service';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';

@Component({
  selector: 'app-checkout-personal',
  templateUrl: './checkout-personal.component.html',
  styleUrls: ['./checkout-personal.component.css']
})
export class CheckoutPersonalComponent implements OnInit, OnDestroy {

  personalDetailsForm: UntypedFormGroup;

  showConfirmationEmail = true;
  submitted = false;
  confirmEmail: string;
  fieldTextType = false;

  passwordStrength = 0;
  checkingIfUserExists = false;

  privacyPolicy: PolicyResult;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  confirmationEmailSubscription: Subscription;

  constructor(private basketService: BasketService,
              private apiService: ApiService,
              private formBuilder: UntypedFormBuilder,
              private sessionService: SessionService) {}

  ngOnInit(): void {
    this.personalDetailsForm = this.formBuilder.group({
      titleSelect: ['', Validators.required],
      firstNameInput: ['', Validators.required],
      familyNameInput: ['', Validators.required],
      emailInput: ['', [Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]],
      confirmEmailInput: ['', [
        Validators.required,
        EmailValidator('emailInput'),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]],
      passwordInput: ['', [Validators.minLength(6),
        PasswordStrengthValidator('passwordInput', 30),
        Validators.required]],
      confirmPasswordInput: ['', [
        Validators.required,
        Validators.minLength(6),
        PasswordValidator('passwordInput')
      ]],
      optInMarketingEmailCheckbox: [''],
      optInMarketingSmsCheckbox: ['']
    });

    this.apiService.getPrivacyPolicy(this.ngUnsubscribe).subscribe((privacyPolicy) => {
      this.privacyPolicy = privacyPolicy;

      if (this.privacyPolicy.valid) {
        this.personalDetailsForm.addControl('termsCheckbox', new UntypedFormControl('', Validators.required));
      }
    });

    // Needs to be here otherwise it will generate an NPE as the basket isn't set
    this.confirmationEmailSubscription = this.basketService.currentShowConfirmationEmail.subscribe(show => {
      if (show != null) {
        this.showConfirmationEmail = show;

        if (this.showConfirmationEmail === false) {
          this.confirmEmail = this.sessionService.basket.createAccountRequest.email;
        }
      }
    });

    this.personalDetailsForm.patchValue({
      titleSelect: this.sessionService.basket.createAccountRequest.title,
      firstNameInput: this.sessionService.basket.createAccountRequest.firstName,
      familyNameInput: this.sessionService.basket.createAccountRequest.familyName,
      emailInput: this.sessionService.basket.createAccountRequest.email,
      confirmEmailInput: this.confirmEmail,
      passwordInput: this.sessionService.basket.createAccountRequest.password,
      optInMarketingEmailCheckbox: this.sessionService.basket.createAccountRequest.marketingOptInEmail,
      optInMarketingSmsCheckbox: this.sessionService.basket.createAccountRequest.marketingOptInSMS,
      termsCheckbox: this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.confirmationEmailSubscription != null) {
      this.confirmationEmailSubscription.unsubscribe();
    }
  }

  getPersonalDetailsControl(componentName: string) {
    return this.personalDetailsForm.get(componentName);
  }

  checkConfirmEmailDisplay() {
    if (!this.showConfirmationEmail) {
      this.showConfirmationEmail = true;
      this.confirmEmail = '';
    }
  }

  passwordEntered(passwordEntered: string) {
    this.passwordStrength = checkStrength(passwordEntered) / 10;
  }

  onPersonalDetailsSubmit() {
    this.submitted = true;
    this.personalDetailsForm.updateValueAndValidity();
    // stop here if form is invalid
    if (this.personalDetailsForm.invalid) {
      return;
    }

    this.sessionService.basket.createAccountRequest.title = this.personalDetailsForm.get('titleSelect').value;
    this.sessionService.basket.createAccountRequest.firstName = this.personalDetailsForm.get('firstNameInput').value;
    this.sessionService.basket.createAccountRequest.familyName = this.personalDetailsForm.get('familyNameInput').value;
    this.sessionService.basket.createAccountRequest.email = this.personalDetailsForm.get('emailInput').value;
    this.sessionService.basket.createAccountRequest.password = this.personalDetailsForm.get('passwordInput').value;
    this.sessionService.basket.createAccountRequest.marketingOptInEmail = this.personalDetailsForm.get('optInMarketingEmailCheckbox').value;
    this.sessionService.basket.createAccountRequest.marketingOptInSMS = this.personalDetailsForm.get('optInMarketingSmsCheckbox').value;

    if (this.privacyPolicy.valid) {
      this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy = this.personalDetailsForm.get('termsCheckbox').value;
      this.sessionService.basket.createAccountRequest.policyId = this.privacyPolicy.id;
    } else {
      this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy = false;
    }

    this.sessionService.saveBasket();

    this.basketService.setStepNumber(2);
  }
}
