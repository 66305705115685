import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleGenerator} from '../title-generator';
import {BookingHeaderTitleServiceService} from '../booking-header/booking-header-title-service.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../api.service';
import {Subject} from 'rxjs';
import {AchievedAwardCertificateDescription} from '../_model/achieved-award-certificate-description';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit, OnDestroy {

  imageUrl: string;
  printUrl: string;
  achievedAwardCertificateDescription: AchievedAwardCertificateDescription;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private titleService: TitleGenerator,
              private apiService: ApiService,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Certificate');

    this.route.params.subscribe(params => {
      this.imageUrl = environment.apiUrl + 'images/achieved_award/' + params.id + '/image?date=' + new Date().getTime();
      this.printUrl = environment.apiUrl + 'images/achieved_award/' + params.id + '/certificate.pdf';

      this.apiService.getAchievedAwardCertificateDescription(params.id, this.ngUnsubscribe).subscribe(response => {
        this.achievedAwardCertificateDescription = response;

        this.bookingHeaderTitleServiceService.setTitle(response.heading);
      });
    });
  }

  getCertificateSrc(): string {
    return this.imageUrl;
  }

  printCertificate(): void {
    const newWin = window.open(this.printUrl, '_blank');
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      window.location.href = this.printUrl;
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }
}
