import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../dashboard.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {WaitingListResult} from '../../_model/waiting-list-result';
import {Venue} from '../../_model/venue';
import {Term} from '../../_model/term';
import {WaitingListRequestSchedule} from '../../_model/waiting-list-request-schedule';
import {WaitingListSchedule} from '../../_model/waiting-list-schedule';
import {TitleGenerator} from '../../title-generator';
import {convertDateToServerTimezone, dateRangeOverlaps} from '../../_helpers/date_helper';
import {AlertService} from '../../alert';
import {Subject} from 'rxjs';
import {ApplicationSettingsService} from '../../application-settings.service';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-waiting-list-amend',
  templateUrl: './dashboard-waiting-list-amend.component.html',
  styleUrls: ['./dashboard-waiting-list-amend.component.css']
})
export class DashboardWaitingListAmendComponent implements OnInit, OnDestroy {

  waitingListResult: WaitingListResult;
  schedulesForm: UntypedFormGroup;

  venues: Venue[];
  terms: Term[];
  timesFrom: string[];
  timesTo: string[];
  venueName: string;
  termName: string;
  dayName: string;

  submitting = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private dashboardService: DashboardService,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private applicationSettings: ApplicationSettingsService) {}

  addMinutes(date, minutes): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  loadWaitingList(waitingListId: string) {
    this.apiService.getWaitingList(waitingListId, this.ngUnsubscribe).subscribe(entry => {
      this.waitingListResult = entry;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Waiting List Availability');

    this.route.params.subscribe(params => {
      this.loadWaitingList(params.id);
    });

    this.apiService.getVenues(this.ngUnsubscribe).subscribe(venues => {
      this.venues = venues;
    });

    this.schedulesForm = this.formBuilder.group({
      venueSelect: ['', Validators.required],
      termSelect: ['', Validators.required],
      daySelect: ['', Validators.required],
      fromTimeSelect: ['', Validators.required],
      toTimeSelect: ['', Validators.required]
    });

    const epochTime = moment().set({
      hour: 6,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    this.timesFrom = [];
    this.timesTo = [];
    while (this.timesFrom.length < 68) {
      this.timesFrom.push(epochTime.toISOString(true));
      epochTime.add(15, 'minute');
      this.timesTo.push(epochTime.toISOString(true));
    }

    this.renderer.addClass(document.body, 'dashboard-action');
    this.renderer.addClass(document.body, 'dashboard-booking-request-transfer');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-action');
    this.renderer.removeClass(document.body, 'dashboard-booking-request-transfer');
  }

  venueChange(venueId: string, venueName: string) {
    this.terms = [];
    this.venueName = venueName;

    this.apiService.getVenueTerms(venueId, this.ngUnsubscribe).subscribe(terms => {
      this.terms = terms;
    });
  }

  termChange(termName: string) {
    this.termName = termName;
  }

  dayChange(dayName: string) {
    this.dayName = dayName;
  }

  onSchedulesSubmit() {
    if (this.schedulesForm.invalid) {
      return;
    }

    this.alertService.clear();

    let fromTime = new Date(this.schedulesForm.get('fromTimeSelect').value);
    let toTime = new Date(this.schedulesForm.get('toTimeSelect').value);

    if (toTime <= fromTime) {
      return;
    }

    fromTime = convertDateToServerTimezone(fromTime, this.applicationSettings.timezone);
    toTime = convertDateToServerTimezone(toTime, this.applicationSettings.timezone);

    const termId = this.schedulesForm.get('termSelect').value;
    const venueId = this.schedulesForm.get('venueSelect').value;
    const dayNumber = parseInt(this.schedulesForm.get('daySelect').value, 10);

    // Need to check whether it is overlapping with another schedule
    // Note that we check in the local timezone
    for (const wlSchedule of this.waitingListResult.schedules) {
      if (wlSchedule.termId === termId
        && wlSchedule.venueId === venueId
        && wlSchedule.dayNumber === dayNumber) {
        if (dateRangeOverlaps(fromTime, toTime, wlSchedule.start, wlSchedule.end)) {
          this.alertService.error('Availability overlaps with an existing schedule');
          return;
        }
      }
    }

    this.submitting = true;

    const schedule = new WaitingListRequestSchedule();
    schedule.venueId = venueId;
    schedule.venueName = this.venueName;
    schedule.termId = termId;
    schedule.termName = this.termName;
    schedule.fromTime = fromTime;
    schedule.toTime = toTime;
    schedule.dayNumber = dayNumber;
    schedule.dayName = this.dayName;

    this.apiService.addWaitingListSchedule(this.waitingListResult.waitingListEntry.id, schedule).subscribe(() => {
      this.schedulesForm.get('termSelect').setValue(null);
      this.schedulesForm.get('venueSelect').setValue(null);
      this.schedulesForm.get('daySelect').setValue(null);
      this.schedulesForm.get('fromTimeSelect').setValue(null);
      this.schedulesForm.get('toTimeSelect').setValue(null);

      this.venueName = null;
      this.termName = null;
      this.dayName = null;
      this.submitting = false;
      this.loadWaitingList(this.waitingListResult.waitingListEntry.id);
    }, () => {this.submitting = false; });
  }

  removeWaitingListAvailability(schedule: WaitingListSchedule, event: any) {
    event.target.disabled = true;
    this.apiService.removeWaitingListAvailability(this.waitingListResult.waitingListEntry.id,
      schedule.id, schedule.termId).subscribe(() => {
      this.waitingListResult.schedules.splice(this.waitingListResult.schedules.indexOf(schedule), 1);
      event.target.disabled = false;
    }, () => {event.target.disabled = false; });
  }
}
