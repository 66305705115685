
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_account_settings']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              edit Direct Debit Details					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_account_settings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->

<div class="wrap">
  <main class="main">
<div class="wgt wgt-dashboard wgt-account-payments-pending direct-debit-update-element" *ngIf="recurringBillingItems != null">
  <div class="card">

    <div class="item-single" *ngFor="let recurringBillingItem of recurringBillingItems">
      <div class="container">
        <div class="row">

          <div class="span-graphic">
            <div class="badge-wrap green">
              <div class="badge-text">direct debit</div>
            </div>
          </div>

          <div class="span-content">
            <ul class="list-content">
              <li>
                <ul class="list-inline" *ngIf="recurringBillingItem.lastEnrolment != null">
                  <li id="student_full_name_0">{{recurringBillingItem.lastEnrolment.bookableEntity.displayName}}</li>
                  <li> on </li>
                  <li id="term_days_0">{{recurringBillingItem.lastEnrolment.course.fullDayOfWeekNames}}</li>
                  <li> at </li>
                  <li id="term_location_0">{{recurringBillingItem.lastEnrolment.course.venueName}}</li>
                </ul>
              </li>
              <li>
                <ul class="list-inline">
                  <li id="term_cost_zero" *ngIf="recurringBillingItem.plan.price == null">{{0 | currency:recurringBillingItem.currency:'symbol-narrow'}}</li>
                  <li id="term_cost_0" *ngIf="recurringBillingItem.plan.price != null">{{recurringBillingItem.plan.price.amount | currency:recurringBillingItem.currency:'symbol-narrow'}}</li>
                  <li> / month. Next payment date is </li>
                  <li id="next_payment_date_invoice" *ngIf="recurringBillingItem.subscriptionNextPayment == null && recurringBillingItem.latestTerm != null">{{recurringBillingItem.latestTerm.startDateTime | worldDate: 'd MMM yyy'}}</li>
                  <li id="next_payment_date_subscription" *ngIf="recurringBillingItem.subscriptionNextPayment != null">{{recurringBillingItem.subscriptionNextPayment | worldDate: 'd MMM yyy'}}<span *ngIf="recurringBillingItem.endDate != null">. Ends {{recurringBillingItem.endDate | worldDate: 'd MMM yyy'}})</span></li>
                </ul>
              </li>
              <li *ngIf="recurringBillingItem.paymentType === 'direct_debit'">Mandate</li>
              <li *ngIf="recurringBillingItem.paymentType === 'direct_debit'" id="biling_postcode">{{getMandateDescription(recurringBillingItem)}}</li>
              <li *ngIf="recurringBillingItem.paymentType === 'credit_card'">Card</li>
              <li *ngIf="recurringBillingItem.paymentType === 'credit_card'" id="credit_card">{{getCreditCardName(recurringBillingItem)}}</li>
            </ul>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>


    <!--          <div class="span-buttons icon-button">
           <a [routerLink]="[]" queryParamsHandling="preserve" (click)="editDirectDebitDetails(recurringBillingItem)" class="btn btn-pencil">
             <span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-pencil"></use></svg></span>						</a>
         </div>-->

    <div class="wrap">
      <main class="main"><div class="wgt wgt-checkout">
        <div class="container multi-step-form">

          <div class="card">
            <div class="multi-step-track">
              <div class="slide-track">
                <form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="editDirectDebitForm" (ngSubmit)="onDirectDebitFormSubmit()">
                  <div class="form-section card-checkout current">
                    <div class="card-inner">

                      <div class="row row-payment-title">

                        <h3 class="span-heading title-label">
                          Payment method
                        </h3>
                        <div class="span-cards" *ngIf="paymentType === 'credit_card'">
                          <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>		<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>	</div>
                      <div class="span-cards" *ngIf="paymentType === 'direct_debit'">
                        <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-direct-debit"></use></svg></span></div>
                    </div>

                      <app-checkout-payment-method-card *ngIf="sportsAccount != null && paymentType === 'credit_card'"
                                                        [submitted]="submitted"
                                                        [paymentForm]="editDirectDebitForm"
                                                        [accountId]="sportsAccount.id"
                                                        [licenseeId]="sportsAccount.licenseeId"
                                                        [addressLinesActive]="addressLinesActive"
                                                        [selectedCardId]="paymentId"
                                                        [updateInvoices]="false"></app-checkout-payment-method-card>


                      <app-checkout-payment-method-mandate *ngIf="sportsAccount != null && paymentType === 'direct_debit'"
                                                        [submitted]="submitted"
                                                        [paymentForm]="editDirectDebitForm"
                                                        [accountId]="sportsAccount.id"
                                                        [action]="'edit-mandate'"
                                                        [extraParams]="'&accountId=' + accountId + '&paymentId=' + paymentId"
                                                        [selectedMandateId]="mandateId != null ? mandateId : paymentId"></app-checkout-payment-method-mandate>

                      <button type="submit" *ngIf="paymentType === 'credit_card' || (paymentType === 'direct_debit' && mandateId !== 'new')" [ngClass]="(paymentType === 'credit_card' && cardPaymentMethod == null) || (paymentType === 'direct_debit' && mandateId == null) || updating || editDirectDebitForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
                        Update Payment Method
                      </button>

                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
    </div>

  </main>
</div>

<app-footer></app-footer>
