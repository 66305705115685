import {StudentAchievement} from './student-achievement';
import {convertObjectToDate} from '../_helpers/date_helper';

export class AwardAchievement extends StudentAchievement {
  awardName: string;
  key: string;
  description: string;

  constructor(data?: any) {
    super(data);
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
