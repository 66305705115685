import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ApiService} from '../../../api.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../auth-service';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../dashboard.service';
import {TitleGenerator} from '../../../title-generator';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Subject, Subscription} from 'rxjs';
import {DirectDebitResponse} from '../../../_model/direct-debit-response';
import {Mandate} from '../../../_model/mandate';
import {Card} from '../../../_model/card';
import moment from 'moment/moment';
import {Account} from '../../../_model/account';
import {
  CheckoutPaymentMethodCardService
} from '../../../checkout/checkout-payment/checkout-payment-method-card/checkout-payment-method-card.service';
import {PaymentMethod} from '../../../_model/payment-method';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService} from '../../../alert';

@Component({
  selector: 'app-dashboard-edit-direct-debit',
  templateUrl: './dashboard-edit-direct-debit.component.html',
  styleUrls: ['./dashboard-edit-direct-debit.component.css']
})
export class DashboardEditDirectDebitComponent implements OnInit, OnDestroy, AfterViewInit {

  addressLinesActive: boolean = false;
  sportsAccount: Account;
  recurringBillingItems: DirectDebitResponse[];
  mandates: Mandate[];
  cards: Card[];
  editDirectDebitForm: UntypedFormGroup;
  cardPaymentMethod: PaymentMethod;
  submitted = false;
  paymentId: string;
  paymentType: string;
  accountId: string;
  updating = false;
  mandateId: string = null;

  countryCode: string;

  cardFormUpdateSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  applicationSettingsSubscription: Subscription;

  loading = false;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private titleService: TitleGenerator,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private renderer: Renderer2,
              private applicationSettings: ApplicationSettingsService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService) {}

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Edit Direct Debit');

    this.countryCode = this.applicationSettings.countryCode;

    this.route.params.subscribe(params => {
      this.paymentType = params.type;
      this.paymentId = params.paymentId;
      this.accountId = params.accountId;
    });

    this.route.queryParams.subscribe(params => {
      const mandateId = params.mandateId;
      if (mandateId != null && mandateId !== '') {
        this.mandateId = mandateId;
      }
    });

    if (this.paymentType === 'credit_card') {
      this.editDirectDebitForm = this.formBuilder.group({
        // There is no postcode validator, though if it was implemented it would be
        // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
        creditCardSelect: [''],
        creditCardNameInput: [''],
        paymentAddress: [''],
        postcodeLookupInput: [''],
        postcodeLookupHouseNumberInput: [''],
        addressLine1Input: [''],
        addressLine2Input: [''],
        addressLine3Input: [''],
        addressCityInput: [''],
        addressCountyInput: [''],
        addressCountryInput: [''],
        addressPostcodeInput: [''],
        postcodeResultsSelect: [''],
      });
    } else {
      this.editDirectDebitForm = this.formBuilder.group({
        mandateSelect: ['', Validators.required]
      });
    }

    this.renderer.addClass(document.body, 'dashboard-edit-dd-details');
    this.renderer.addClass(document.body, 'dashboard-account');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.apiService.getRecurringBilling(this.accountId, this.paymentId, this.ngUnsubscribe).subscribe(recurringBillingItems => {
      this.recurringBillingItems = recurringBillingItems;
    });

    this.apiService.getAccount(this.accountId, this.ngUnsubscribe).subscribe(account => {
      this.sportsAccount = account;
    })

    this.cardFormUpdateSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.cardPaymentMethod = paymentMethod.cardId !== this.paymentId ? paymentMethod : null;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethodMandate.subscribe(mandateId => {
      if (mandateId != null && !this.loading) {
        this.mandateId = mandateId !== this.paymentId ? mandateId : null;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && !this.loading) {
        if (hasErrors) {
          this.updating = false;
          this.spinner.hide();
        } else {
          this.updateDirectDebitCardPaymentMethod();
        }
      }
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }

    if (this.cardFormUpdateSubscription != null) {
      this.cardFormUpdateSubscription.unsubscribe();
    }

    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }

    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-edit-dd-details');
    this.renderer.removeClass(document.body, 'dashboard-account');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  setPaymentValidators() {
    let paymentValidator = null;
    let addressValidator = null;
    let creditCardAddressValidator = null;

    paymentValidator = [Validators.required];

    if (this.cardPaymentMethod?.cardId === 'new') {
      creditCardAddressValidator = [Validators.required];

      if (this.cardPaymentMethod?.paymentAddressId === 'other') {
        addressValidator = [Validators.required];
      }
    } else {
      this.removeFormControlError('cardError');
      this.changeDetectorRef.detectChanges();
    }

    this.editDirectDebitForm.get('creditCardSelect').setValidators(paymentValidator);
    this.editDirectDebitForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.editDirectDebitForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.editDirectDebitForm.get('addressLine1Input').setValidators(addressValidator);
    this.editDirectDebitForm.get('addressCityInput').setValidators(addressValidator);
    this.editDirectDebitForm.get('addressCountyInput').setValidators(addressValidator);
    this.editDirectDebitForm.get('addressCountryInput').setValidators(addressValidator);
    this.editDirectDebitForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.editDirectDebitForm.get('creditCardSelect').updateValueAndValidity();
    this.editDirectDebitForm.get('creditCardNameInput').updateValueAndValidity();
    this.editDirectDebitForm.get('paymentAddress').updateValueAndValidity();
    this.editDirectDebitForm.get('addressLine1Input').updateValueAndValidity();
    this.editDirectDebitForm.get('addressLine1Input').updateValueAndValidity();
    this.editDirectDebitForm.get('addressCityInput').updateValueAndValidity();
    this.editDirectDebitForm.get('addressCountyInput').updateValueAndValidity();
    this.editDirectDebitForm.get('addressCountryInput').updateValueAndValidity();
    this.editDirectDebitForm.get('addressPostcodeInput').updateValueAndValidity();
    this.editDirectDebitForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  removeFormControlError(errorName: string) {
    if (this.editDirectDebitForm?.errors && this.editDirectDebitForm?.errors[errorName]) {
      delete this.editDirectDebitForm.errors[errorName];
      if (Object.keys(this.editDirectDebitForm.errors).length === 0) {
        this.editDirectDebitForm.setErrors(null);
      }
    }
  }

  onDirectDebitFormSubmit(): void {
    if (this.updating) {
      return;
    }

    this.submitted = true;

    if (this.paymentType === 'credit_card') {
      if (this.cardPaymentMethod.cardId === 'new' && this.cardPaymentMethod.cardHasErrors) {
        this.editDirectDebitForm.setErrors({cardError: true});
        this.changeDetectorRef.detectChanges();
      }
    }

    // stop here if form is invalid
    if (this.editDirectDebitForm.invalid) {
      if (!this.addressLinesActive && this.paymentType === 'credit_card') {
        this.addressLinesActive = !!(this.submitted
          && (this.editDirectDebitForm.controls.addressLine1Input.errors
            || this.editDirectDebitForm.controls.addressCityInput.errors
            || this.editDirectDebitForm.controls.addressCountyInput.errors
            || this.editDirectDebitForm.controls.addressCountryInput.errors
            || this.editDirectDebitForm.controls.addressPostcodeInput.errors));
      }

      return;
    }

    this.updating = true;
    this.spinner.show();

    if (this.paymentType === 'credit_card' && this.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else {
      this.updateDirectDebitCardPaymentMethod();
    }
  }

  updateDirectDebitCardPaymentMethod(): void {
    this.alertService.clear();

    this.recurringBillingItems.forEach(recurringItem => {
      const paymentId = recurringItem.paymentType === 'credit_card' ? this.cardPaymentMethod.cardId : this.mandateId;

          this.apiService.updateRecurringBillingPaymentMethod(recurringItem.recurringBillingId, recurringItem.paymentType === 'credit_card' ? 'card' : 'direct_debit', paymentId).subscribe((response) => {
            if (response.paymentStatus != null && response.paymentStatus === 'requires_action') {
              this.router.navigate(['/invoice/' + response.pendingPaymentId + '/confirm']);
            } else if (response.paymentStatus != null && response.paymentStatus === 'requires_payment_method') {
              this.alertService.error('Failed to process payment: ' + response.paymentError + " (" + response.paymentErrorCode + ")");
              this.paymentId = paymentId;
            } else {
              this.router.navigate(['/dashboard_account_settings']);
              this.dashboardService.setNewConfirmationMessage('Direct debit updated');
            }
            this.updating = false;
          }, error => {
            this.updating = false;
          });
    });
  }

  getDirectDebitControl(componentName: string) {
    if (this.editDirectDebitForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.editDirectDebitForm.get(componentName);
  }

  getMandateDescription(item: DirectDebitResponse): string {
    const accountDetails = [];
    if (item.mandate.bankName != null && item.mandate.bankName !== '') {
      accountDetails.push(item.mandate.bankName);
    }

    if (item.mandate.sortCode != null && item.mandate.sortCode !== '') {

      let sortCode;
      if (item.mandate.sortCode.length === 6) {
        sortCode = item.mandate.sortCode.substring(0, 2) + "-" + item.mandate.sortCode.substring(2, 4) + "-" + item.mandate.sortCode.substring(4, 6);
      } else {
        sortCode = item.mandate.sortCode;
      }

      accountDetails.push(sortCode);
    }

    if (item.mandate.trailingFourAccountDigits != null && item.mandate.trailingFourAccountDigits !== '') {
      accountDetails.push("****" + item.mandate.trailingFourAccountDigits);
    }

    if (item.mandate.customerName != null && item.mandate.customerName !== '') {
      accountDetails.push("(" + item.mandate.customerName + ")");
    }

    return accountDetails.join(" ");
  }

  getCreditCardName(item: DirectDebitResponse): string {
    let cardString = item.card.cardType + ': ';

    for (let i = 0; i < item.card.cardLength - item.card.trailingDigits.length; i++) {
      cardString += '*';
    }

    cardString += item.card.trailingDigits;

    cardString += ' exp: ' + moment(item.card.expiry).format('MM/YYYY');

    return cardString;
  }
}
