import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../api.service';
import {BasketService} from '../../basket.service';
import {SessionService} from '../../../session.service';

@Component({
  selector: 'app-checkout-payment-voucher',
  templateUrl: './checkout-payment-voucher.component.html',
  styleUrls: ['./checkout-payment-voucher.component.css']
})
export class CheckoutPaymentVoucherComponent implements OnInit {

  @Input() voucherCode: string;
  @Input() voucherUsed: boolean;

  voucherForm: UntypedFormGroup;
  addingVoucherCode = false;

  submitted = false;
  verifyingVoucher = false;
  error: string;

  constructor(private apiService: ApiService,
              private basketService: BasketService,
              private formBuilder: UntypedFormBuilder,
              private sessionService: SessionService) {}

  ngOnInit(): void {
    this.voucherForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      voucherCodeInput: ['', Validators.required]
    });
  }

  onVoucherSubmit(): void {
    if (this.verifyingVoucher) {
      return;
    }

    this.error = null;
    this.verifyingVoucher = true;
    this.submitted = true;

    if (this.voucherForm.invalid) {
      return;
    }

    const voucherCodeToAdd = this.voucherForm.get('voucherCodeInput').value;

    this.apiService.validateVoucherCode(this.sessionService.basket.licenseeId, voucherCodeToAdd).subscribe(response => {
      this.verifyingVoucher = false;
      if (response.valid) {
        this.addingVoucherCode = false;
        this.basketService.setVoucherCode(response.code);
      } else {
        this.error = response.error;
      }
    }, () => {
      this.verifyingVoucher = false;
    });
  }

  showAddVoucher() {
    this.addingVoucherCode = true;
  }

  cancelAddVoucher(): void {
    this.addingVoucherCode = false;
  }

  clearVoucher(): void {
    this.basketService.setVoucherCode('NULL');
  }
}
