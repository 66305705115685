import { UntypedFormControl } from '@angular/forms';

export function PasswordValidator(confirmPasswordInput: string) {
  let confirmPasswordControl: UntypedFormControl;
  let passwordControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!confirmPasswordControl) {
      confirmPasswordControl = control;
      passwordControl = control.parent.get(confirmPasswordInput) as UntypedFormControl;
      passwordControl.valueChanges.subscribe(() => {
        confirmPasswordControl.updateValueAndValidity();
      });
    }

    if (passwordControl.value != null && confirmPasswordControl.value != null &&
      passwordControl.value !==
      confirmPasswordControl.value
    ) {
      return {
        passwordNotMatch: true
      };
    }

    return null;
  };
}

export function PasswordStrengthValidator(passwordInput: string, threshold: number) {

  return (passwordControl: UntypedFormControl) => {

    if (passwordControl.errors && !passwordControl.errors.weak) {
      return;
    }

    if (passwordControl.value != null && checkStrength(passwordControl.value) < threshold) {
      return {
        weak: true
      };
    }

    return null;
  };
}

export function checkStrength(p): number {
  // 1
  let force = 0;

  // 2
  const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
  const lowerLetters = /[a-z]+/.test(p);
  const upperLetters = /[A-Z]+/.test(p);
  const numbers = /[0-9]+/.test(p);
  const symbols = regex.test(p);

  // 3
  const flags = [lowerLetters, upperLetters, numbers, symbols];

  // 4
  let passedMatches = 0;
  for (const flag of flags) {
    passedMatches += flag === true ? 1 : 0;
  }

  // 5
  force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
  force += passedMatches * 10;

  // 6
  force = (p.length <= 6) ? Math.min(force, 10) : force;

  // 7
  force = (passedMatches === 1) ? Math.min(force, 10) : force;
  force = (passedMatches === 2) ? Math.min(force, 20) : force;
  force = (passedMatches === 3) ? Math.min(force, 30) : force;
  force = (passedMatches === 4) ? Math.min(force, 40) : force;

  return force;
}
