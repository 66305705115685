import {VenueOptions} from '../course-search/select-location/select-location.component';
import {UntypedFormGroup} from '@angular/forms';

export function VenueOptionsValidator(venueOptions: VenueOptions, addressControlName: string, distanceControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const addressControl = formGroup.controls[addressControlName];
    const distanceControl = formGroup.controls[distanceControlName];

    if ((venueOptions.longitude == null || venueOptions.latitude == null)
      || (venueOptions.longitude === 0 && venueOptions.latitude === 0)) {
      addressControl.setErrors({ notSet: true });
    } else {
      addressControl.setErrors(null);
    }

    if (venueOptions.distance == null || venueOptions.distance === 0) {
      distanceControl.setErrors({ notSet: true });
    } else {
      distanceControl.setErrors(null);
    }
  };
}
