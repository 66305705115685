import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectDateOfBirthService {

  currentTitle: Observable<string>;
  private titleSubject = new BehaviorSubject<string>(null);

  currentDateOfBirth: Observable<Date>;
  private dateOfBirthSubject = new BehaviorSubject<Date>(null);

  currentSubmitted: Observable<boolean>;
  private submittedSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentTitle = this.titleSubject.asObservable();
    this.currentDateOfBirth = this.dateOfBirthSubject.asObservable();
    this.currentSubmitted = this.submittedSubject.asObservable();
  }

  setTitle(message: string) {
    this.titleSubject.next(message);
  }

  setSelectedDateOfBirth(dateOfBirth: Date) {
    this.dateOfBirthSubject.next(dateOfBirth);
  }

  setSubmitted(submitted: boolean) {
    this.submittedSubject.next(submitted);
  }
}
