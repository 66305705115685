<app-header></app-header>
<app-booking-header></app-booking-header>

<div class="wrap">
  <main class="main">
    <div class="wgt wgt-courses">
      <div class="container">
        <div class="row row-courses">

          <div class="span-index-courses">
            <div class="wgt wgt-index-courses">
              <div class="row" *ngIf="courseReferral != null">
                <app-course-list-course class="span-card" *ngFor="let course of courses" [courseResult]="course" [multipleMode]="false" [mode]="mode"></app-course-list-course>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>		</main>
</div>
<app-footer></app-footer>
