<div class="wrap">
  <main class="main"><section class="auth">

    <div class="container">
      <div class="row">

        <div class="span-bg" style="background-image: url('./assets/images/login-bg.jpg')"></div>

        <div class="span-form">
          <div class="login-wrap">

            <div class="logo">
              <a appRedirectHome>
                <img appLogo/>
              </a>
            </div><div class="login-header">
            <h3 class="login-title">
              Forgot password?
            </h3>
            <p class="login-subtitle">
              Don't worry, simply enter the email address you registered with and we will send you a link to reset your password.
            </p>
          </div>

            <div class="form">
              <form method="post" id="lost_password" action="" novalidate="novalidate" class="auth-form" [formGroup]="restPasswordForm" (ngSubmit)="onResetPasswordSubmit()">

                <div *ngIf="!emailSent" class="span-input span-text required">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    class="input-text"
                    formControlName="emailInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Email'"
                    (input)="restPasswordForm.controls.emailInput.setValue(restPasswordForm.controls.emailInput.value.toLowerCase())"
                  />
                  <label for="email" class="floating-label">
                    email
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul class="parsley-errors-list filled"></ul>
                </div>

                <button *ngIf="!emailSent" type="submit" class="btn btn-block btn-red" [ngClass]="restPasswordForm.invalid || runningLostPassword ? 'disabled' : ''">
                  Send password reset link
                </button>

                <div *ngIf="error != null" class="invalid-feedback">
                  {{error}}
                  <span *ngIf="error == 'Account is not registered'">
                    <br>Please click <a [routerLink]="['/request_access']">here</a> to register your account
                  </span>
                </div>

                <div *ngIf="emailSent" class="alternative">
                  <p>An email has been sent to {{email}}. Please click on the link within the email to continue the registration process.</p>
                </div>

                <div class="alternative" *ngIf="!emailSent">
                  <p>Remembered you password? <a [routerLink]="[]" (click)="doLogin()">Log in</a></p>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>		</main>
</div>
