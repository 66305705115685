import {CoursePrice} from './course-price';
import {Course} from './course';

export class CoursePriceList {

  currencyCode: string;

  numberOfWeeksToEnrol: number;
  startDate: Date;

  flexibleWeeksAvailable: number;
  flexibleWeeks: Date[];

  prices: CoursePrice[];

  canEnrolInNextTerm: boolean;
  nextTermDescription: string;

  numberOfPlacesAvailable: number;

  full: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.startDate != null && typeof this.startDate === 'string') {
        if (this.startDate !== '') {
          this.startDate = new Date(this.startDate);
        }
      }
    }
  }

  getTotalActivePrices(): number {
    if (this.prices == null) {
      return 0;
    }

    const isActive = (coursePrice: CoursePrice) => !coursePrice.isHidden;
    const prices = this.prices.filter(isActive);

    return prices.length;
  }

  getActivePricesSortedBySequence(): CoursePrice[] {
    if (this.prices == null) {
      return null;
    }

    const isActive = (coursePrice: CoursePrice) => !coursePrice.isHidden;
    const prices = this.prices.filter(isActive);

    return prices.sort((t1, t2) => {
      if (t1.sequence > t2.sequence) {
        return 1;
      }
      if (t1.sequence < t2.sequence) {
        return -1;
      }
      return 0;
    });
  }

  getPricesSortedBySequence(): CoursePrice[] {
    if (this.prices == null) {
      return null;
    }

    return this.prices.sort((t1, t2) => {
      if (t1.sequence > t2.sequence) {
        return 1;
      }
      if (t1.sequence < t2.sequence) {
        return -1;
      }
      return 0;
    });
  }

  getFirstPrice(): CoursePrice {
    const priceList = this.getPricesSortedBySequence();

    if (priceList == null || priceList.length === 0) {
      return null;
    }

    return priceList[0];
  }
}
