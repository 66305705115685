import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {LocationStrategy} from '@angular/common';
import {ApplicationSettingsService} from '../application-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly locationStrategy: LocationStrategy,
    private applicationSettings: ApplicationSettingsService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      let redirectUri = window.location.origin;

      const baseHref = this.locationStrategy.getBaseHref();
      if (baseHref != null && baseHref !== '') {
        redirectUri += baseHref;
      }

      if (route.url.length === 1) {
        redirectUri += route.url[0];
      } else {
        if (redirectUri.endsWith('/') && state.url.startsWith('/')) {
          redirectUri = redirectUri.substr(0, redirectUri.length - 1);
        }

        redirectUri += state.url;
      }

      if (redirectUri.indexOf('?') === -1) {
        redirectUri += '?';
      } else {
        redirectUri += '&';
      }

      redirectUri += 'master_licensee_id=' + this.applicationSettings.masterLicenseeId;

      await this.keycloak.login({
        redirectUri,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
