import {HttpErrorResponse} from '@angular/common/http';
import {ApiException} from '../api-exception';

export function logError(error: HttpErrorResponse): void {
  console.error(getErrorMessage(error));
}

export function getErrorMessage(error: HttpErrorResponse): string {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // client-side error
    errorMessage = `Error: ${error.error.message}`;
  } else if (error.error == null) {
    errorMessage = `Error Status: ${error.status}\nMessage: ${error.statusText}`;
  } else {
    if (hasJsonStructure(error.error)) {
      const exception = new ApiException(error.error);

      if (exception.error == null) {
        if (error.status != null && error.status > 0) {
          errorMessage = error.status + ': ' + error.statusText;
        } else {
          errorMessage = error.statusText;
        }
      } else if (error.status != null && error.status > 0) {
        if (exception.message != null) {
          errorMessage = exception.error + ' (' + exception.status + '): ' + exception.message;
        } else {
          errorMessage = exception.error + ' (' + exception.status + ')';
        }
      } else {
        if (exception.message != null) {
          errorMessage = exception.error + ': ' + exception.message;
        } else {
          errorMessage = exception.error;
        }
      }
    } else if (error.status != null && error.status > 0) {
      errorMessage = error.status + ': ' + error.error;
    } else {
      errorMessage = error.error;
    }
  }

  return errorMessage;
}

export function hasJsonStructure(str): boolean {
  if (typeof str === 'object') { return true; }
  if (typeof str !== 'string') { return false; }
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]'
      || type === '[object Array]';
  } catch (err) {
    return false;
  }
}
