<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="9"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main"><app-dashboard-confirmation></app-dashboard-confirmation>

              <span *ngIf="!accountsLoaded">
              <div class="ph-item accounts-loading">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-12 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                  </div>
                </div>
              </div>

                <div class="ph-item accounts-loading">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-12 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-12 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-12 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                  </div>
                </div>
              </div>

              </span>

              <app-dashboard-account-settings-account *ngFor="let account of accounts" [account]="account"></app-dashboard-account-settings-account>

              <div class="wgt wgt-dashboard wgt-account-personal-details" *ngIf="person != null">
                <div class="card">

                  <div class="item-single">
                    <div class="container">
                      <div class="row">

                        <div class="span-graphic">
                          <div class="badge-wrap red">
                            <div class="badge-text">personal</div>
                          </div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>Name</li>
                            <li>
                              <ul class="list-inline">
                                <li id="user_gender">{{person.title}} </li>
                                <li id="user_full_name">{{person.givenName}} {{person.familyName}}</li>
                              </ul>
                            </li>
                            <li>Contact Details</li>
                            <li>
                              <ul class="list-inline">
                                <li id="user_phone">{{getTelephoneString()}}</li>,
                                <li id="user_email" class="word-break">{{person.email}}</li>
                              </ul>
                            </li>
                            <li>Address</li>
                            <li id="user_address" *ngIf="address != null">
                              <ul class="list-inline">
                                <li id="user_street_number" *ngIf="address.addressLine1 != null && address.addressLine1 !== ''">{{address.addressLine1}}, </li>
                                <li id="user_street" *ngIf="address.addressLine2 != null && address.addressLine2 !== ''">{{address.addressLine2}}, </li>
                                <li id="user_street_2" *ngIf="address.addressLine3 != null && address.addressLine3 !== ''">{{address.addressLine3}}, </li>
                                <li id="user_city" *ngIf="address.locality != null && address.locality !== ''">{{address.locality}}, </li>
                                <li id="user_state" *ngIf="address.state != null && address.state !== ''">{{address.state}}, </li>
                                <li id="user_postcode" *ngIf="address.postCode != null && address.postCode !== ''">{{address.postCode}}</li>
                              </ul>
                            </li>
                            <li>Password</li>
                            <li class="semi">******************</li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button">
                          <a [routerLink]="['/dashboard_edit_personal_details']" class="btn btn-pencil">
                            <span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-pencil"></use></svg></span>						</a>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="wgt wgt-account-marketing" *ngIf="person != null">

                <p class="title">marketing Communications:</p>

                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <td>
                          <label
                            for="marketing_email"
                            class="input-check checkbox">
                            <input
                              id="marketing_email"
                              name="marketing_email"
                              type="checkbox"
                              style="display: none"
                              [(ngModel)]="person.optInMarketingEmails"
                              #optInMarketingEmail
                              (click)="updateOptInMarketingEmails(optInMarketingEmail.checked)"
                            />
                            <span>
									<span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span>									<span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>									Keep in touch via email
								</span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label
                            for="marketing_sms"
                            class="input-check checkbox">
                            <input
                              style="display: none"
                              id="marketing_sms"
                              name="marketing_sms"
                              type="checkbox"
                              [(ngModel)]="person.optInMarketingSMS"
                              #optInMarketingSms
                              (click)="updateOptInMarketingSms(optInMarketingSms.checked)"
                            />
                            <span>
									<span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span>									<span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>									Keep in touch via sms
								</span>
                          </label>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>

<app-footer></app-footer>
