import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingHeaderTitleServiceService {

  currentTitle: Observable<string>;
  private titleSubject = new BehaviorSubject<string>(null);

  currentShowFilter: Observable<boolean>;
  private showFilterSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentTitle = this.titleSubject.asObservable();
    this.currentShowFilter = this.showFilterSubject.asObservable();
  }

  setTitle(message: string) {
    this.titleSubject.next(message);
  }

  setShowFilter(showFilter: boolean) {
    this.showFilterSubject.next(showFilter);
  }
}
