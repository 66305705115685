import {StudentAchievement} from './student-achievement';

export class ModuleAchievement extends StudentAchievement {
  moduleLevel: string;
  moduleName: string;
  moduleDescription: string;
  sequence: number;

  constructor(data?: any) {
    super(data);
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
