import {CourseSearchStudentResult} from './course-search-student-result';

export class CourseSearchUpdateResult {
  courseId: string;
  courseSearchStudents: CourseSearchStudentResult[];

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
