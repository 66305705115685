<div class="wgt wgt-dashboard wgt-confirmation" *ngIf="confirmationMessage != null">
  <div class="card">

    <div class="item-single">
      <div class="container">
        <div class="row">

          <div class="span-graphic">
            <div class="graphic-image"><span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span></div>
          </div>

          <div class="span-content">
            <ul class="list-content">
              <li>{{confirmationMessage.message}}</li>
              <li *ngIf="confirmationMessage.enrolment != null">
                <ul class="list-inline">
                  <li id="student_full_name">{{confirmationMessage.enrolment.bookableEntity.displayName}}</li>
                  <li> on </li>
                  <li id="term_days">{{confirmationMessage.enrolment.course.fullDayOfWeekNames}}</li>
                  <li> between </li>
                  <li id="term_time_range">{{confirmationMessage.enrolment.course.startTimeDate | worldDate: 'h:mma'}} & {{confirmationMessage.enrolment.course.endTimeDate | worldDate: 'h:mma'}}</li>
                  <li> at </li>
                  <li id="term_location">{{confirmationMessage.enrolment.course.venueName}}</li>
                </ul>
              </li>
              <li *ngIf="confirmationMessage.paymentReference != null">
                <ul class="list-inline">
                  <li>Payment reference number: </li>
                  <li id="reference_number">{{confirmationMessage.paymentReference}}</li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="span-buttons icon-button">
            <button type="button" (click)="confirmationMessage = null" class="btn btn-close" name="dismiss">
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>						</button>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
