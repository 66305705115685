
export function getPriceTypeTitle(priceType: string): string {
  switch (priceType) {
    case 'SINGLE_TERM':
      return 'Term Package';
    case 'MULTIPLE_TERMS':
      return 'Annual Package';
    case 'MONTHLY_DIRECT_DEBIT':
    case 'MONTHLY_CREDIT_CARD':
      return 'Monthly Subscription';
    case 'CREDIT_PACK':
      return 'Credit Packs';
    default:
      return priceType;
  }
}

export function getPriceTypeShort(priceType: string): string {
  switch (priceType) {
    case 'SINGLE_TERM':
      return 'term';
    case 'MULTIPLE_TERMS':
      return 'year';
    case 'MONTHLY_DIRECT_DEBIT':
    case 'MONTHLY_CREDIT_CARD':
      return 'month';
    case 'CREDIT_PACK':
      return 'lesson';
    default:
      return priceType;
  }
}
