import {UntypedFormControl} from '@angular/forms';

export function TelephoneValidator(mobilePhoneInput: string) {
  let mobileControlControl: UntypedFormControl;
  let telephoneControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!mobileControlControl) {
      mobileControlControl = control;
      telephoneControl = control.parent.get(mobilePhoneInput) as UntypedFormControl;
      telephoneControl.valueChanges.subscribe(() => {
        mobileControlControl.updateValueAndValidity();
      });
    }

    if ((telephoneControl.value == null || telephoneControl.value === '') &&
        (mobileControlControl.value == null || mobileControlControl.value === '')
    ) {
      return {
        requiresAtLeastOne: true
      };
    }
    return null;
  };
}
