import {FormGroup} from '@angular/forms';
import moment from 'moment-timezone';

export function convertObjectToDate(inputDate: Date): Date {
  let returnDate = inputDate;

  if (inputDate != null
    && (typeof inputDate === 'string'
      || typeof inputDate === 'number')) {
    if (inputDate !== '') {
      returnDate = new Date(inputDate);
    }
  }
  return returnDate;
}

export function dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
  aEnd = new Date(aEnd - 1);
  bEnd = new Date(bEnd - 1);
  if (aStart <= bStart && bStart <= aEnd) { return true; } // b starts in a
  if (aStart <= bEnd   && bEnd   <= aEnd) { return true; } // b ends in a
  if (bStart <  aStart && aEnd   <  bEnd) { return true; } // a in b
  return false;
}

export function isValidDate(d: Date) {
  if (d == null) {
    return false;
  }
  return d instanceof Date && !isNaN(d.getTime());
}

export function convertDateToServerTimezone(date: Date, timezone: string): Date {
  // We are now in the server timezone
  return moment()
    .tz(timezone).set({
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    millisecond: date.getMilliseconds()
  }).toDate();
}

export function monthsBetweenDates(fromDate: Date, toDate: Date): number {
  const dateStart = moment(fromDate);
  const dateEnd = moment(toDate);

  return dateStart.diff(dateEnd, 'months', true);
}
