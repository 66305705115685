import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {VenueOptions} from './select-location.component';

@Injectable({
  providedIn: 'root'
})
export class SelectLocationService {

  currentTitle: Observable<string>;
  private titleSubject = new BehaviorSubject<string>(null);

  currentVenueOptions: Observable<VenueOptions>;
  private venueOptionsSubject = new BehaviorSubject<VenueOptions>(null);

  currentVenueId: Observable<string>;
  private venueIdSubject = new BehaviorSubject<string>(null);

  currentUpdatedVenueOptions: Observable<boolean>;
  private updatedVenueOptionsSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentTitle = this.titleSubject.asObservable();
    this.currentVenueOptions = this.venueOptionsSubject.asObservable();
    this.currentVenueId = this.venueIdSubject.asObservable();
    this.currentUpdatedVenueOptions = this.updatedVenueOptionsSubject.asObservable();
  }

  setTitle(message: string) {
    this.titleSubject.next(message);
  }

  setVenueOptions(options: VenueOptions) {
    this.venueOptionsSubject.next(options);
  }

  setVenueId(venueId: string) {
    this.venueIdSubject.next(venueId);
  }

  setVenueOptionsUpdated() {
    this.updatedVenueOptionsSubject.next(true);
  }
}
