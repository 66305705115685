import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {FindCourseMultipleService} from '../find-course-multiple/find-course-multiple.service';
import {StudentName} from './student-name';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {SelectDateOfBirthService} from '../select-date-of-birth/select-date-of-birth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-find-course-multiple-student',
  templateUrl: './find-course-multiple-student.component.html',
  styleUrls: ['./find-course-multiple-student.component.css']
})
export class FindCourseMultipleStudentComponent implements OnInit, OnDestroy {

  @Input() studentNumber: number;
  @HostBinding('class') class = 'row row-cards';

  selectStudentsForm: UntypedFormGroup;
  submitted = false;

  dobYears: number[];

  submittedSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private findCourseMultipleService: FindCourseMultipleService,
              private selectDateOfBirthService: SelectDateOfBirthService) {}

  ngOnInit(): void {
    this.submittedSubscription = this.selectDateOfBirthService.currentSubmitted.subscribe(submitted => this.submitted = submitted);

    this.dobYears = new Array<number>();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= (currentYear - 100); i--) {
      this.dobYears.push(i);
    }
  }

  get f() { return this.selectStudentsForm.controls; }

  getControl(componentName: string) {
    return this.selectStudentsForm.get(componentName + '_' + this.studentNumber);
  }

  studentNameChange(studentNameValue: string) {
    const studentName = new StudentName();
    studentName.studentId = this.studentNumber;
    studentName.studentName = studentNameValue;

    this.findCourseMultipleService.setStudentName(studentName);
  }

  ngOnDestroy() {
    if (this.submittedSubscription != null) {
      this.submittedSubscription.unsubscribe();
    }
  }
}
