import {WaitingListEntry} from './waiting-list-entry';
import {WaitingListSchedule} from './waiting-list-schedule';
import {convertObjectToDate} from '../_helpers/date_helper';

export class WaitingListResult {
  schedules: WaitingListSchedule[];
  waitingListEntry: WaitingListEntry;
  collapsed = true;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.waitingListEntry.dateFromRequired = convertObjectToDate(this.waitingListEntry.dateFromRequired);
    this.waitingListEntry.datePlaced = convertObjectToDate(this.waitingListEntry.datePlaced);
    this.waitingListEntry.dateRequested = convertObjectToDate(this.waitingListEntry.dateRequested);
    if (this.waitingListEntry.waitingListExpiries != null) {
      this.waitingListEntry.waitingListExpiries.forEach(expiry => {
        expiry.dateSent = convertObjectToDate(expiry.dateSent);
        expiry.expiry = convertObjectToDate(expiry.expiry);
      });
    }
    this.schedules.forEach(schedule => {
      schedule.termStartDate = convertObjectToDate(schedule.termStartDate);
      schedule.termEndDate = convertObjectToDate(schedule.termEndDate);

      // It is important here to use the current date, as otherwise it will not take into account DST (being 1970)
      schedule.start = convertObjectToDate(schedule.start);
      schedule.end = convertObjectToDate(schedule.end);
    });
  }
}
