import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {FindCourseSingleServiceService} from '../find-course-single/find-course-single-service.service';
import {InteractiveSkillCheckService} from './interactive-skill-check.service';
import {ApiService} from '../../api.service';
import {ProgramLevelQuestion} from '../../_model/program-level-question';
import {ProgramLevel} from '../../_model/program-level';
import {TitleGenerator} from '../../title-generator';
import {Subscription} from 'rxjs';
import {ApplicationSettingsService} from '../../application-settings.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-interactive-skill-check',
  templateUrl: './interactive-skill-check.component.html',
  styleUrls: ['./interactive-skill-check.component.css']
})
export class InteractiveSkillCheckComponent implements OnInit, OnDestroy {
  selectProgramLevel: UntypedFormGroup;
  selectProgramLevelResultForm: UntypedFormGroup;
  currentQuestion: ProgramLevelQuestion;
  programId: string;
  currentAnswer: ProgramLevel;

  skillCheckStepOne = true;
  skillCheckStepTwo = false;

  programIdSubscription: Subscription;

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private interactiveSkillCheckService: InteractiveSkillCheckService,
              private renderer: Renderer2,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private findCourseSingleServiceService: FindCourseSingleServiceService,
              private apiService: ApiService,
              private applicationService: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Interactive Skill Check');
    this.programIdSubscription = this.interactiveSkillCheckService.currentProgramId.subscribe(programId => this.programId = programId);

    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'type-single');

    this.selectProgramLevel = this.formBuilder.group({
      check_no: [''],
      check_yes: ['']
    });

    this.selectProgramLevelResultForm = this.formBuilder.group({
      level_result: ['']
    });

    this.bookingHeaderTitleServiceService.setTitle('find the perfect course');
    this.bookingHeaderTitleServiceService.setShowFilter(false);

    this.loadNextQuestion(null);
  }

  ngOnDestroy(): void {
    if (this.programIdSubscription != null) {
      this.programIdSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'find-course');
    this.renderer.removeClass(document.body, 'type-single');
  }

  onProgramLevelResultSelected() {
      this.interactiveSkillCheckService.setProgramLevel(this.currentAnswer);

      // If I don't set the below, then when this reloads it will set as the previous level
      this.interactiveSkillCheckService.setProgramLevel(null);
  }

  loadNextQuestion(questionId: string) {
    this.apiService.programLevelFinder(this.programId,
      questionId).subscribe((data) => {
      this.currentQuestion = data;
    });
  }

  answerYes() {
    this.processAnswer('yes');
  }

  answerNo() {
    this.processAnswer('no');
  }

  processAnswer(answerName: string) {
    for (const answer of this.currentQuestion.answers) {
      if (answer.answer === answerName) {
        if (answer.answerProgramLevel != null) {
          this.currentAnswer = answer.answerProgramLevel;
          this.skillCheckStepOne = false;
          this.skillCheckStepTwo = true;
          this.selectProgramLevelResultForm.get('level_result').setValue(answer.answerProgramLevel.id);
        } else {
          this.loadNextQuestion(answer.nextQuestionId);
        }

        break;
      }
    }
  }

  getProgramLevelText(programLevel: ProgramLevel): string {
    if (programLevel.levelDescription != null && programLevel.levelDescription.values != null) {
      for (const value of programLevel.levelDescription.values) {
        if (this.applicationService.localeLanguage === value.resourceLocale.localeName) {
          return value.value;
        }
      }
    }
    return '';
  }

  getLevelImageUrlSrc(levelId: string): string {
    return environment.apiUrl + 'images/program_levels/' + levelId + '/image';
  }
}
