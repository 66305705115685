import {convertObjectToDate} from '../_helpers/date_helper';

export class StudentAchievement {
  dateAchieved: Date;
  id: string;
  achieved: boolean;
  type: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.dateAchieved = convertObjectToDate(this.dateAchieved);
  }
}
