import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleGenerator} from '../../title-generator';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../../api.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-renewal-subscription',
  templateUrl: './renewal-subscription.component.html',
  styleUrls: ['./renewal-subscription.component.css']
})
export class RenewalSubscriptionComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  requestError = false;
  isLoading = true;
  isSubscribe = false;

  constructor(private titleService: TitleGenerator,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Update Enrolment Subscription Preferences');
    this.spinner.show();

    this.route.params.subscribe(params => {
      const enrolmentRenewalId = params.id;
      this.route.queryParams.subscribe(queryParam => {
        const subscribed = queryParam.subscribed;

        if (subscribed != null && (subscribed.toLowerCase() === 'true' || subscribed.toLowerCase() === 'false')) {
          this.isSubscribe = (subscribed.toLowerCase() === 'true');
          this.apiService.updateEnrolmentSubscriptionPreferences(enrolmentRenewalId, this.isSubscribe)
            .subscribe(() => {}, err => {
            this.spinner.hide();
          }, () => {
            this.spinner.hide();
            this.isLoading = false;
          });
        } else {
          this.requestError = true;
          this.spinner.hide();
        }
      });
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }
}
