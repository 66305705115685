import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TitleGenerator} from '../../title-generator';
import {ApiService} from '../../api.service';
import {ApplicationSettingsService} from '../../application-settings.service';
import {AuthService} from '../../auth-service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountBillingItem} from '../../_model/account-billing-item';
import {PendingPayment} from '../../_model/pending-payment';

@Component({
  selector: 'app-dashboard-billing-history',
  templateUrl: './dashboard-billing-history.component.html',
  styleUrls: ['./dashboard-billing-history.component.scss']
})
export class DashboardBillingHistoryComponent implements OnInit, OnDestroy {

  accountsLoaded: boolean;
  billingHistory: AccountBillingItem[];
  pendingPayments: PendingPayment[];
  pendingPaymentId: string;
  cancellingPendingPayment = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private apiService: ApiService,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private changDetectorRef: ChangeDetectorRef,
              private applicationSettings: ApplicationSettingsService,
              private router: Router,
              private authService: AuthService) {
    this.accountsLoaded = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Billing History');

    this.apiService.getAccounts(null, this.ngUnsubscribe).subscribe(accounts => {
      this.accountsLoaded = true;

      if (this.applicationSettings.autoGeneratePendingPayments) {
        accounts.forEach(account => {
          if (account.balance < 0) {
            this.apiService.autoGeneratePendingPayments(account.id, this.ngUnsubscribe).subscribe( created => {
              if (created) {
                this.loadPendingPayments();
              }
            });
          }
        });
      }
    });

    this.apiService.getBillingHistory(null, null, null, this.ngUnsubscribe).subscribe(billingHistory => {
      this.billingHistory = billingHistory;
    });

    this.route.queryParams.subscribe(params => {
      this.pendingPaymentId = params.pendingPaymentId;
    });

    this.loadPendingPayments();
  }

  loadPendingPayments(): void {
    this.apiService.getPendingPaymentsForLoggedInUser(this.ngUnsubscribe).subscribe(pendingPayments => {
      this.pendingPayments = pendingPayments;
    }, () => {}, () => {
      if (this.pendingPaymentId != null) {
        this.navigateToElement('pendingPayment_' + this.pendingPaymentId);
      }
    });
  }

  navigateToElement(elementId: string) {
    this.changDetectorRef.detectChanges();
    const element = document.querySelector('#' + elementId);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 500 );
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-account-settings');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getPendingPaymentDescription(pendingPayment: PendingPayment) {
    let description = '';
    for (const invoice of pendingPayment.pendingPaymentInvoices) {
      if (description !== '') {
        description += ', ';
      }
      description += invoice.invoice.description;
    }
    return description;
  }

  getAmount(billingItem: AccountBillingItem): number {
    if (billingItem.invoiceFee < 0) {
      return billingItem.invoiceFee * -1;
    }

    return billingItem.invoiceFee;
  }

  getAmountText(billingItem: AccountBillingItem): string {
    if (billingItem.invoiceFee < 0) {
      return ' Credit';
    }

    return '';
  }

  invoicePdfClick(billingItem: AccountBillingItem) {
    const newWindow = window.open();
    if (billingItem.itemType === 'Invoice') {
      this.apiService.getInvoicePdf(billingItem.accountId, billingItem.id, this.ngUnsubscribe).subscribe(response => {
        this.openPdf(response, 'invoice_' + billingItem.documentNumber + '.pdf');
      });
    } else {
      this.apiService.getReceiptPdf(billingItem.accountId, billingItem.id, this.ngUnsubscribe).subscribe(response => {
        this.openPdf(response, 'receipt_' + billingItem.documentNumber + '.pdf');
      });
    }
  }

  openPdf(response: Blob, fileName: string): void {
    // Other browsers
    const newWindow = window.open();
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      // Popup blocked
      const a = document.createElement('a');
      a.style.cssText = 'display:none';
      a.href = URL.createObjectURL(response);
      a.download = fileName;
      a.click();
    } else {
      const fileURL = window.URL.createObjectURL(response);
      newWindow.location.assign(fileURL);
    }
  }

  getItemType(billingItem: AccountBillingItem): string {
    if (billingItem.itemType === 'Receipt' && billingItem.paymentMethod != null && billingItem.paymentMethod !== '') {
      return billingItem.paymentMethodSummary;
    }

    return billingItem.itemType;
  }

  cancelPendingPayment(pendingPaymentId: string): void {
    const referrer = this.router.url;
    this.router.navigate(['/invoice/' + pendingPaymentId + '/cancel'], {queryParams: {referrer}}).then(() => { });
  }

  editDirectDebitDetails(pendingPayment: PendingPayment): void {
    this.router.navigate(['/dashboard_edit_direct_debit', pendingPayment.sportsAccountId, pendingPayment.recurringBillingType, pendingPayment.recurringBillingPaymentId]);
  }
}
