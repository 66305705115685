import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from 'rxjs';
import {TitleGenerator} from '../../title-generator';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService} from '../../alert';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {PendingPaymentActionResponse} from '../../_model/pending-payment-action-response';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-invoicing-confirmation',
  templateUrl: './invoicing-confirmation.component.html',
  styleUrls: ['./invoicing-confirmation.component.css']
})
export class InvoicingConfirmationComponent implements OnInit, OnDestroy, AfterViewInit {

  loading = false;

  paymentForm: UntypedFormGroup;
  hasError = false;

  stripe: Stripe;

  pendingPaymentId: string;
  pendingPaymentSettlementResponse: PendingPaymentActionResponse;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  processingPayment = false;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private titleService: TitleGenerator,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private renderer: Renderer2,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Dashboard Booking Confirm');

    this.paymentForm = this.formBuilder.group({});

    this.spinner.show();

    this.route.params.subscribe(params => {
      this.pendingPaymentId = params.pendingPaymentId;




      this.apiService.getPendingPaymentRequiredAction(this.pendingPaymentId).subscribe(response => {
        this.pendingPaymentSettlementResponse = response;

        if (response.result === 'payment_error') {
          this.hasError = true;
          this.alertService.error(response.error);
        } else if (response.result === 'error') {
          this.hasError = true;
          if (response.error != null) {
            this.alertService.error(response.error);
          } else {
            this.alertService.error('An unknown error has occurred');
          }
        } else if (response.result !== 'requires_action') {
          console.error('Unknown response: ' + response.result);
          this.hasError = true;
        }

        const options = {};
        const apiVersion = environment.stripeApiVersion;

        if (apiVersion !== 'default') {
          options['apiVersion'] = apiVersion;
        }

        loadStripe(this.pendingPaymentSettlementResponse.apiKey, options).then(stripeResult => {
          this.spinner.hide();
          this.stripe = stripeResult;
        });
      });
    });

    this.renderer.addClass(document.body, 'checkout-multiple');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'type-multi');
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'checkout-multiple');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'type-multi');
  }

  getInvoiceDescription(): string {
    let description = '';
    for (const invoice of this.pendingPaymentSettlementResponse.pendingPaymentInvoices) {
      if (description !== '') {
        description += ', ';
      }
      description += invoice.invoice.description;
    }
    return description;
  }

  onPaymentSubmit(): void {
    if (this.processingPayment) {
      return;
    }

    if (this.paymentForm.invalid) {
      return;
    }

    this.processingPayment = true;

    this.stripe.confirmCardPayment(
      this.pendingPaymentSettlementResponse.paymentIntentClientSecret
    ).then(result => {
      if (result.error) {
        // Show error in payment form
        this.alertService.error(result.error.message);
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server

        this.router.navigate(['/invoice_confirmation_complete'], {queryParams: {}});
      }
    });
  }
}
