<form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="addressForm" (ngSubmit)="onAddressSubmit()">
  <div class="form-section current">
    <h3 class="title-label">
      Address
    </h3>

  <app-address
    [submitted]="submitted"
    [addressForm]="addressForm"
    [addressLinesActive]="addressLinesActive"
    [address]="address"></app-address>

    <div class="row row-phone">

      <div class="span-input span-text required">
        <input
          type="text"
          id="telephoneHome"
          name="telephoneHome"
          class="input-text"
          formControlName="telephoneHomeInput"
          (focusout)="formatTelephone()"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Telephone Home'"
        />
        <label
          for="telephoneHome"
          class="floating-label">
          phone
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div><div class="span-input span-text">
      <input
        type="text"
        id="telephoneMobile"
        name="telephoneMobile"
        class="input-text"
        formControlName="telephoneMobileInput"
        (focusout)="formatMobile()"
        appParsleyValidation
        [submitted]="submitted"
        [elementName]="'Telephone Mobile'"
      />
      <label
        for="telephoneMobile"
        class="floating-label">
        mobile
      </label>
      <i class="asterisk">*</i>
      <i class="checkmark"></i>
      <ul class="parsley-errors-list filled">
      </ul>
    </div>
    </div>
    <div class="row row-referral">

      <div class="span-input span-select">
        <select
          id="howDidYouHear"
          name="howDidYouHear"
          class="input-select"
          formControlName="howDidYouHearSelect"
          (change)="howDidYouHearChange()"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'How Did You Hear'"
        >
          <option value="" disabled selected hidden>how did you hear about us?</option>

          <option *ngFor="let type of howDidYouHearTypes" value="{{type.howDidYouHearTypeId}}">{{type.getName()}}</option>
          <option value="other">Other...</option>
        </select>
        <label
          for="howDidYouHear"
          class="floating-label">
          How did you hear about us?
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>
    </div>

    <div class="row row-referral" *ngIf="showHowDidYouHearOther">

      <div class="span-input span-text">
        <input
          type="text"
          id="howDidYouHearOther"
          name="howDidYouHearOther"
          class="input-text"
          formControlName="howDidYouHearOtherInput"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'How Did You Hear Other'"
        />
      </div>
      <label
        for="howDidYouHearOther"
        class="floating-label">
        Other description
      </label>
      <i class="asterisk">*</i>
      <i class="checkmark"></i>
      <ul class="parsley-errors-list filled">
      </ul>

    </div>
    <button type="submit" [ngClass]="this.addressForm.invalid || creatingAccount ? 'disabled' : ''" class="btn btn-block btn-red" >
      {{getSubmitText()}}
    </button>
  </div>
</form>
