export class CreateStudentResponse {
  id: number;
  studentId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
