<app-header></app-header>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>

<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info" *ngIf="course != null">
        <h2 class="title">
          {{course.venueName}}				</h2>
        <div class="info">

          {{course.fullDayOfWeekNames}}<span *ngIf="course.termType === 'WEEKLY'">s</span> @ {{course.startTimeDate | worldDate:'HH:mm'}}					</div>
      </div>


      <div class="span-back">
        <button type="button" class="btn btn-back" (click)="navigateBack()">
          <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back to results        </span>
        </button>
      </div>

    </div>
  </div>
</div>


<div class="wrap">
  <main class="main"><div class="wgt wgt-course-single">


    <div class="wgt wgt-course-pricing" *ngIf="course != null" [ngStyle]="{'background-image': getVenueImageUrl()}">
      <div class="container">

        <div class="row row-pricing" *ngIf="coursePriceList != null">

          <div *ngFor="let priceList of coursePriceList.getActivePricesSortedBySequence()" class="span-4 row-element-centred" [ngClass]="priceList.sequence === 1 ? 'span-popular' : ''">
            <div class="card">
              <h3 class="title">
                {{formatPriceTypeTitle(priceList.priceType)}}
                <span class="tip" *ngIf="priceList.sequence === 1 && coursePriceList.getTotalActivePrices() > 1">
							(Most popular option)
						</span>
              </h3>
              <div class="price">
                <span *ngIf="priceList.priceType === 'CREDIT_PACK'">from </span>
                {{getPrice(priceList) | currency:coursePriceList.currencyCode:'symbol-narrow'}}<br />
                per {{formatPriceTypeShort(priceList.priceType)}}
              </div>
              <ul class="benefits" *ngIf="priceList.descriptionLines != null">
                <li *ngFor="let description of priceList.descriptionLines" [innerHTML]="description.content"></li>
              </ul>

              <div class="row row-credit-pack" *ngIf="priceList.priceType === 'CREDIT_PACK' && classesAvailable != null">
                <div class="span-input select credit-span-input">
                  <select id="numberOfLessons"
                          [(ngModel)]="numberOfLessons"
                          (change)="updateSelectedClasses()"
                          class="input-select">
                    <option *ngFor="let i of getClassList()" value="{{i}}">{{i}}</option>
                  </select>

                  <label
                    for="numberOfLessons"
                    class="floating-label">
                    number of lessons
                  </label>
                </div>
              </div>


              <a (click)="addToBasket(priceList.priceType)" class="btn btn-block btn-red basket-link"  [ngClass]="isContinueButtonDisabled(priceList) ? 'disabled' : ''">
                book now					</a>
            </div>
          </div>


        </div>

        <div class="row course-pricing-warning" *ngIf="course != null && course.activeRestrictions.length > 0">
          <div class="span-warning">
            <div class="card">
              <div class="mark exclamation-point"></div>
              <span *ngFor="let restriction of course.activeRestrictions; let i = index">
              &nbsp;{{restriction.description}}<span *ngIf="i > 0">, </span>
              </span>
            </div>
          </div>
        </div>

        <div class="row course-pricing-warning" *ngIf="coursePriceList != null && course != null && course.nextTermCourseId != null && !coursePriceList.canEnrolInNextTerm">
          <div class="span-warning">
            <div class="card">
              <div class="mark exclamation-point"></div>
              &nbsp;Please note that enrolment in this course for {{coursePriceList.nextTermDescription}} is not available.
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="wgt wgt-course-info">
    <div class="container">

      <div class="row row-info">

        <div class="span-3 span-programme" *ngIf="course != null && program != null && programLevel != null">
          <span class="svg-icon badge-check"><svg class="svg-badge-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-badge-check"></use></svg></span>				<ul>
          <li class="title">programme</li>
          <li class="lead">{{program.programName}}</li>
          <li>{{programLevel.programLevelName}} (Level {{programLevel.sequence}})</li>
          <li>{{getNumberOfStudentsText()}} Students per Class</li>
          <li>{{course.classDurationInMinutes}} Minutes</li>
        </ul>
        </div>

        <div class="span-3 span-date" *ngIf="course != null && coursePriceList != null">
          <span class="svg-icon calendar"><svg class="svg-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-calendar"></use></svg></span>				<ul>
          <li class="title">Date & Time</li>
          <li class="lead">{{course.termDescription}}</li>
          <li>{{course.fullDayOfWeekNames}}s @ {{course.startTimeDate | worldDate:'HH:mm'}}</li>
          <li *ngIf="course.courseType === 'FIXED'">{{coursePriceList.numberOfWeeksToEnrol}} Lessons</li>
          <li *ngIf="course.courseType === 'FLEXIBLE'">{{numberOfLessons}} Lesson<span *ngIf="numberOfLessons > 1">s</span></li>
          <li *ngIf="course.courseType === 'FIXED'">Start Date {{coursePriceList.startDate | worldDate: 'dd.MM.yy'}}</li>
          <span *ngIf="course.courseType === 'FLEXIBLE'">
            <li *ngFor="let selectedClass of selectedClasses">{{selectedClass.startDate | worldDate: 'dd.MM.yy h:mma'}}</li>
          </span>
        </ul>
        </div>

        <div class="span-3 span-location" *ngIf="venue != null && address != null">
          <span class="svg-icon map-marker"><svg class="svg-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-map-marker"></use></svg></span>				<ul>
          <li class="title">location</li>
          <li class="lead">{{venue.venueName}}</li>
          <li *ngIf="address.addressLine1 != null">{{address.addressLine1}}</li>
          <li *ngIf="address.addressLine2 != null">{{address.addressLine2}}</li>
          <li *ngIf="address.addressLine3 != null">{{address.addressLine3}}</li>
          <li>{{address.locality}} {{address.postCode}}</li>
        </ul>
        </div>

        <div class="span-3 span-instructor" *ngIf="course != null">
          <span class="svg-icon child"><svg class="svg-child"><use xlink:href="./assets/sprite/spritemap.svg#sprite-child"></use></svg></span>				<ul>
          <li class="title">instructor</li>
          <li class="lead">{{course.instructorGivenName}}</li>
          <li class="image" *ngIf="course.instructorHasPhoto">
            <img src="{{getInstructorImageUrl(course.instructorId)}}" alt="{{course.instructorGivenName}}">
          </li>
        </ul>
        </div>

      </div>

    </div>
  </div>
    <!--<div class="wgt wgt-gallery">
    <div class="container">

      <div class="row row-gallery">

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-1.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-2.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-3.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-4.jpg')"></span>
          </div>
        </div>

      </div>
    </div>
  </div>-->

    <div class="locationMap" *ngIf="venue != null && ((venue.latitude != null && venue.longitude != null) || venue.placeId != null)" id="map"></div>

  </div>

  </main>
</div>

<app-footer></app-footer>
