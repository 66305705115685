import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {getErrorMessage} from '../../_helpers/http_log_helper';
import {TitleGenerator} from '../../title-generator';
import {AuthService} from '../../auth-service';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit, OnDestroy {

  restPasswordForm: UntypedFormGroup;
  submitted = false;
  runningLostPassword = false;
  error: string;
  emailSent = false;
  email: string;

  constructor( private formBuilder: UntypedFormBuilder,
               private titleService: TitleGenerator,
               private apiService: ApiService,
               private authService: AuthService,
               private renderer: Renderer2) {}

  ngOnInit(): void {
    this.titleService.setTitle('Lost Password');

    this.restPasswordForm = this.formBuilder.group({
      emailInput: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]]
    });

    this.renderer.addClass(document.body, 'lost-password');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'lost-password');
  }

  getResetControl(componentName: string) {
    return this.restPasswordForm.get(componentName);
  }

  onResetPasswordSubmit() {
    this.submitted = true;
    if (this.restPasswordForm.invalid || this.runningLostPassword) {
      return;
    }

    this.runningLostPassword = true;
    this.error = null;

    this.email = this.restPasswordForm.get('emailInput').value;

    this.apiService.resetPassword(this.email).subscribe(requestAccessResponse => {
        if (requestAccessResponse.status === 'success') {
          this.emailSent = true;
          this.restPasswordForm.get('emailInput').setValue(null);
        } else if (requestAccessResponse.status === 'error') {
          this.error = requestAccessResponse.error;
          this.runningLostPassword = false;
        } else {
          console.error('Unknown request access status: ' + requestAccessResponse.status);
        }
      },
      error => {
        this.runningLostPassword = false;
        this.error = getErrorMessage(error);
      });
  }

  doLogin() {
    this.authService.loginAndRedirectToDashboard();
  }
}
