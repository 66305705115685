<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="6"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main"><div class="lead">
              <h1 class="title">Your Upgrades & Offers</h1>

              <div class="row row-lead">
                <div class="span-subtitle">
                  <p>Take a look at our latest upgrades and offers to find a personalised discount deal just for you!</p>
                </div>
              </div>

              <div class="row row-lead" *ngIf="noOffers && !loadingOffers && credits != null && credits.length === 0">
                <div class="span-subtitle">
                  <p>There are currently no offers available.</p>
                </div>
              </div>
            </div>

              <span *ngIf="loadingOffers === true">
              <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
              </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
                </div>
                </span>

              <!--
              ---	Upgrades and Offers - Early Bird
              -->
              <div class="wgt wgt-dashboard wgt-upgrades-offers" *ngIf="earlyBirdEnrolments != null && earlyBirdEnrolments.length > 0">
                <div class="card" *ngFor="let enrolment of earlyBirdEnrolments">

                  <div class="row row-user">
                    <div class="span-user">
                      <div class="username">
                        <span id="eb_user_full_name_{{enrolment.enrolmentId}}">{{enrolment.student.givenName}} {{enrolment.student.familyName}}</span>
                      </div>
                      <ul class="user-details list-inline">
                        <li>Upgrade to our Early Bird Annual Tuition and save </li>
                        <li id="eb_sas_{{enrolment.enrolmentId}}">{{enrolment.discount | currency:enrolment.currencyCode:'symbol-narrow'}}</li>
                      </ul>
                    </div>

                    <div class="span-buttons">
                      <a [routerLink]="['/dashboard_pre_sale_upgrade',enrolment.enrolmentId]" class="btn btn-block btn-blue">view upgrade</a>
                    </div>
                  </div>

                </div>
              </div>

              <!--
              ---	Upgrades and Offers - Version 1
              -->
              <div class="wgt wgt-dashboard wgt-upgrades-offers" *ngIf="provisionalUpgrades != null && provisionalUpgrades.length > 0">
                <div class="card" *ngFor="let provisional of provisionalUpgrades">

                  <div class="row row-user">
                    <div class="span-user">
                      <div class="username">
                        <span id="user_full_name_{{provisional.enrolmentId}}">{{provisional.student.givenName}} {{provisional.student.familyName}}</span>
                      </div>
                      <ul class="user-details list-inline">
                        <li>Enrol in an Annual Membership and save </li>
                        <li id="sas_{{provisional.enrolmentId}}">{{provisional.discount | currency:provisional.currencyCode:'symbol-narrow'}}</li>
                      </ul>
                    </div>

                  </div>

                  <app-dashboard-provisional *ngFor="let provisional of provisionalUpgrades" [provisional]="provisional" [provisionalEnrolments]="provisionalUpgrades"></app-dashboard-provisional>

                </div>
              </div>


              <!--
              ---	Upgrades and Offers - Version 2
              -->
              <div class="wgt wgt-dashboard wgt-upgrades-offers" *ngIf="confirmedUpgrades != null && confirmedUpgrades.length > 0">
                <div class="card" *ngFor="let confirmedUpgrade of confirmedUpgrades">

                  <div class="row row-user">
                    <div class="span-user">
                      <div class="username">
                        <span id="user_full_name_{{confirmedUpgrade.enrolmentId}}">{{confirmedUpgrade.student.givenName}} {{confirmedUpgrade.student.familyName}}</span>
                      </div>
                      <ul class="user-details list-inline">
                        <li>Upgrade to Annual Membership and save </li>
                        <li id="sas_{{confirmedUpgrade.enrolmentId}}">{{confirmedUpgrade.discount | currency:confirmedUpgrade.currencyCode:'symbol-narrow'}}</li>
                      </ul>
                    </div>

                    <div class="span-buttons">
                      <a [routerLink]="['/dashboard_booking_upgrade',confirmedUpgrade.enrolmentId]" class="btn btn-block btn-blue">view upgrade</a>
                    </div>
                  </div>

                </div>
              </div>


              <!--
              ---	Upgrades and Offers - Version 3
              -->
              <div class="wgt wgt-dashboard wgt-upgrades-offers" *ngIf="provisionalEnrolments != null && provisionalEnrolments.length > 0">
                <div class="card" *ngFor="let provisional of provisionalEnrolments">

                  <div class="row row-user">
                    <div class="span-user">
                      <div class="username">
                        <span id="prov_user_full_name_{{provisional.enrolmentId}}">{{provisional.student.givenName}} {{provisional.student.familyName}}</span>
                      </div>
                    </div>
                  </div>

                  <app-dashboard-provisional *ngFor="let provisional of provisionalEnrolments" [provisional]="provisional" [provisionalEnrolments]="provisionalEnrolments"></app-dashboard-provisional>

                </div>
              </div>


              <!--
              ---	Upgrades and Offers - Discount Credits
              -->
              <span *ngIf="credits != null && credits.length > 0">
              <div class="wgt wgt-dashboard wgt-upgrades-offers" *ngFor="let creditStudent of credits">
                <div class="card" *ngFor="let credit of creditStudent.creditResults">

                  <div class="row row-user">
                    <div class="span-user">
                      <div class="username">
                        <span id="eb_user_full_name_{{credit.credit.sportsCreditId}}">{{creditStudent.student.givenName}} {{creditStudent.student.familyName}}</span>
                      </div>
                      <ul class="user-details list-inline">
                        <li>Save </li>
                        <li id="eb_sas_per_{{credit.credit.sportsCreditId}}" *ngIf="credit.discount.type === 'PERCENTAGE'">{{getDiscountPercentage(credit)}}</li>
                        <li id="eb_sas_val_{{credit.credit.sportsCreditId}}" *ngIf="credit.discount.type === 'VALUE'">{{credit.discount.amount | currency:credit.account.currency:'symbol-narrow'}}</li>
                        <li> off {{credit.programName}} </li>
                        <li *ngIf="credit.termType === 'WEEKLY'">weekly</li>
                        <li *ngIf="credit.termType === 'FAST_TRACK'">intensive</li>
                        <li> courses</li>
                      </ul>
                      <ul class="user-details list-inline list-conditions">
                        <li>{{getDiscountConditions(credit)}}</li>
                      </ul>
                      <ul class="user-details list-inline list-conditions">
                        <li>Discount applied at checkout</li>
                      </ul>
                    </div>

                    <div class="span-buttons">
                      <a [routerLink]="[]" (click)="discountSearch(creditStudent, credit)" class="btn btn-block btn-blue">find courses</a>
                    </div>
                  </div>

                </div>
              </div>
                </span>
            </main>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
