import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SportsCreditStudentResult} from '../../_model/sports-credit-student-result';
import {AlertService} from '../../alert';
import {Course} from '../../_model/course';
import {Class} from '../../_model/class';
import {Venue} from '../../_model/venue';
import {Address} from '../../_model/address';
import {numberToWords} from 'number-to-words';
import {formatDate} from '@angular/common';
import {DashboardService} from '../../dashboard/dashboard.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {ApplicationSettingsService} from '../../application-settings.service';
import {Subject, Subscription} from 'rxjs';
import {GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-dashboard-booking-credits-use-confirm',
  templateUrl: './dashboard-booking-credits-use-confirm.component.html',
  styleUrls: ['./dashboard-booking-credits-use-confirm.component.css']
})
export class DashboardBookingCreditsUseConfirmComponent implements OnInit, OnDestroy {

  creditId: string;
  credit: SportsCreditStudentResult;
  course: Course;
  classItem: Class;
  venue: Venue;
  address: Address;
  listPosition: number;
  hasError = false;

  enrolling = false;

  locale: string;
  googleUrl: SafeResourceUrl;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  applicationSettingsSubscription: Subscription;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private dashboardService: DashboardService,
              private googleAnalytics: GoogleAnalyticsService,
              private applicationSettings: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Credit Booking Confirmation');

    this.locale = this.applicationSettings.locale;

    this.route.params.subscribe(params => {
      this.creditId = params.id;
      this.apiService.getCredit(this.creditId, this.ngUnsubscribe).subscribe(result => {
        this.credit = result;

        const creditResult = this.credit.creditResults[0];
        if (creditResult.status !== 'ACTIVE') {
          this.alertService.error('Credit is no longer active');
          this.hasError = true;
          return;
        }

        if (creditResult.expired) {
          this.alertService.error('Credit has expired');
          this.hasError = true;
          return;
        }
      });

      this.apiService.getClass(params.classId, this.ngUnsubscribe).subscribe(classItem => {
        this.classItem = classItem;
        this.apiService.getCourse(classItem.courseId, this.ngUnsubscribe).subscribe(course => {
          this.course = course;
          this.apiService.getVenue(course.venueId, this.ngUnsubscribe).subscribe(venue => {
            this.venue = venue;
            this.setGoogleUrl();
          });
          this.apiService.getAddress(course.venueId, this.ngUnsubscribe).subscribe(venue => this.address = venue);

          this.googleAnalytics.viewCourse(this.course, this.course.programLevel, this.listPosition, 'Make-up');
        });
      });
    });

    this.route.queryParams.subscribe(params => {
      if (params.listPosition != null) {
        this.listPosition = parseInt(params.listPosition, 10);
      }
    });
    this.renderer.addClass(document.body, 'dashboard-booking-credits-use-confirm');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-booking-credits-use-confirm');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return environment.apiUrl + 'images/instructors/' + instructorId + '/image';
  }

  getNumberOfStudentsText(): string {
    return numberToWords.toWords(this.course.numberOfStudentsPerInstructor);
  }

  setGoogleUrl() {
    if (this.venue.latitude != null && this.venue.longitude != null) {
      this.googleUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?' +
        'key=AIzaSyBn9W7K04WtxdwqzxP1zu8NRnjvCQ4Rtd4&' +
        'q=' + this.venue.latitude + ',' + this.venue.longitude);
    }
  }

  confirmSession(): void {
    if (this.enrolling || this.hasError) {
      return;
    }

    this.enrolling = true;

    this.apiService.enrolInMakeup(this.classItem.id, this.credit.creditResults[0].credit.sportsCreditId).subscribe(booking => {
      this.googleAnalytics.paymentForCourse(this.course,
        booking.bookedClass.programLevel,
        booking.bookingId,
        0,
        0,
        this.applicationSettings.currency,
        'Make-up',
        this.listPosition);

      this.router.navigate(['/dashboard_credits']);

      this.dashboardService.setNewConfirmationMessage('Makeup booking complete for '
        + this.course.venueName + ' on '
        + formatDate(booking.bookedClass.startDate, 'd MMM yyyy', this.locale)
        + ' @ ' + formatDate(booking.bookedClass.startDate, 'h:mma', this.locale, booking.bookedClass.startDateOffset));
    }, () => this.enrolling = false);
  }
}
