import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleGenerator} from '../../title-generator';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject} from 'rxjs';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {VenueOptions} from '../../course-search/select-location/select-location.component';
import {SessionService} from '../../session.service';
import {AlertService} from '../../alert';

@Component({
  selector: 'app-resume-cart',
  templateUrl: './resume-cart.component.html',
  styleUrls: ['./resume-cart.component.css']
})
export class ResumeCartComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private sessionService: SessionService,
              private alertService: AlertService,
              private router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle('Resume Cart');
    this.spinner.show();

    this.route.params.subscribe(params => {
      this.apiService.getAbandonedCart(params.abandonedCartId, true, this.ngUnsubscribe).subscribe(abandonedCartResponse => {
        const abandonedCart = abandonedCartResponse.abandonedCart;

        // Generate basket item
        const parameters = new CourseSearchParameters();
        const studentParameters = new CourseSearchStudent();

        studentParameters.id = 1;
        studentParameters.studentId = abandonedCart.sportsStudent.id;
        studentParameters.programId = abandonedCart.programLevel.programId;
        studentParameters.programLevelId = abandonedCart.programLevel.id;
        studentParameters.programLevelName = abandonedCart.programLevel.programLevelName;
        studentParameters.studentName = abandonedCart.sportsStudent.givenName + ' ' + abandonedCart.sportsStudent.familyName;
        studentParameters.dateOfBirth = abandonedCart.sportsStudent.dateOfBirth;

        const students = new Array<CourseSearchStudent>();
        students.push(studentParameters);

        parameters.students = students;

        const venueOptions = new VenueOptions();
        venueOptions.venueId = abandonedCart.course.venueId;

        parameters.venueOptions = venueOptions;

        this.sessionService.courseSearchParameters = parameters;
        this.sessionService.saveCourseSearchParameters();

        if (abandonedCartResponse.availableToBook) {
          const selectedStudentId = studentParameters.id;
          const programId = studentParameters.programId;
          const programLevelId = studentParameters.programLevelId;
          const studentId = studentParameters.studentId;
          const referrer = '/courses_list_single';
          const listPosition = 1;

          this.router.navigate(['/course_single', abandonedCart.course.id],
            {queryParams: {selectedStudentId, studentId, programId, programLevelId, referrer, listPosition}});
        } else {
          const runSearch = true;
          this.router.navigate(['courses_list_single'], {queryParams: {runSearch}});
        }
      }, error => {
        console.error('Failed to load abandoned cart', error);
        this.router.navigate(['dashboard']);
      });
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }
}
