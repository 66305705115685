import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {StudentCreditPack} from '../../_model/student-credit-pack';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {SportsCreditStudentResult} from '../../_model/sports-credit-student-result';
import {Booking} from '../../_model/booking';
import {TitleGenerator} from '../../title-generator';
import {CreditPackResponse} from '../../_model/credit-pack-response';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {VenueOptions} from '../../course-search/select-location/select-location.component';
import {CourseListSingleServiceService} from '../../course-search/courses-list-single/course-list-single-service.service';
import {CourseSingleService} from '../../course-search/course-single/course-single.service';
import {Subject} from 'rxjs';
import {SessionService} from '../../session.service';
import {environment} from '../../../environments/environment';
import {SportsCreditResult} from '../../_model/sports-credit-result';

@Component({
  selector: 'app-dashboard-credits',
  templateUrl: './dashboard-credits.component.html',
  styleUrls: ['./dashboard-credits.component.scss']
})
export class DashboardCreditsComponent implements OnInit, OnDestroy {

  creditPacks: CreditPackResponse[];
  expiredCreditPacks: StudentCreditPack[];
  credits: SportsCreditStudentResult[];
  intensiveCredits: SportsCreditStudentResult[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  creditsLoaded: boolean;
  creditPacksLoaded: boolean;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private courseSingleService: CourseSingleService,
              private sessionService: SessionService) {
    this.creditsLoaded = false;
    this.creditPacksLoaded = false;
  }

  getCreditPackBookings(creditPack: StudentCreditPack) {
    const now = new Date();

    this.apiService.getStudentCreditBookings(creditPack.sportsStudentCreditPackId, this.ngUnsubscribe).subscribe(
      bookings => {
        creditPack.bookings = bookings;
        creditPack.bookings.forEach(booking => {
          if (booking.bookedClass.startDate >= now) {
            if (creditPack.nextBooking == null) {
              creditPack.nextBooking = booking;
            } else if (creditPack.nextBooking.bookedClass.startDate > booking.bookedClass.startDate) {
              creditPack.nextBooking = booking;
            }
          }

          if (booking.bookedClass.startDate < now) {
            if (creditPack.pastBooking == null) {
              creditPack.pastBooking = booking;
            } else if (creditPack.pastBooking.bookedClass.startDate < booking.bookedClass.startDate) {
              creditPack.pastBooking = booking;
            }
          }
        });
      });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Waiting List');

    this.creditPacks = [];
    this.expiredCreditPacks = [];
    this.apiService.getStudentCreditPacks(null, null, null, null, this.ngUnsubscribe).subscribe(studentCreditPacks => {
      for (const studentCreditPackResponse of studentCreditPacks) {
        const studentCreditPack = studentCreditPackResponse.studentCreditPack;
        if (studentCreditPack.hasExpired) {
          let hasActive = false;

          for (const credit of studentCreditPack.credits) {
            if (credit.status === 'ACTIVE') {
              hasActive = true;
            }
          }

          if (hasActive) {
            this.expiredCreditPacks.push(studentCreditPack);
          } else {
            this.creditPacks.push(studentCreditPackResponse);
          }
        } else {
          studentCreditPack.creditsRemaining = 0;

          for (const credit of studentCreditPack.credits) {
            if (credit.status === 'ACTIVE') {
              studentCreditPack.creditsRemaining = studentCreditPack.creditsRemaining + 1;
            }
          }

          this.creditPacks.push(studentCreditPackResponse);
        }
      }

      this.creditPacks.forEach(creditPack => {
        this.getCreditPackBookings(creditPack.studentCreditPack);
      });
      this.expiredCreditPacks.forEach(creditPack => {
        this.getCreditPackBookings(creditPack);
      });

      this.creditPacksLoaded = true;
    });

    let creditTypes = [];
    creditTypes.push('MakeUpClass');
    creditTypes.push('ClassCredit');
    this.apiService.getStudentCredits(null, null, creditTypes, this.ngUnsubscribe).subscribe(credits => {
      this.credits = credits;
      this.creditsLoaded = true;
    });

    creditTypes = [];
    creditTypes.push('IntensiveCourse');
    this.apiService.getStudentCredits(null, null, creditTypes, this.ngUnsubscribe).subscribe(credits => {
      this.intensiveCredits = credits;
      this.creditsLoaded = true;
    });

    this.renderer.addClass(document.body, 'dashboard-credits');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-credits');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getNumberOfMoreFutureBookings(studentCredit: StudentCreditPack): number {
    let numberOfBookings = 0;
    const now = new Date();
    studentCredit.bookings.forEach(booking => {
      if (booking.bookedClass.startDate >= now) {
        numberOfBookings++;
      }
    });

    if (studentCredit.nextBooking != null) {
      numberOfBookings--;
    }

    return numberOfBookings;
  }

  getMoreFutureBookings(studentCredit: StudentCreditPack): Booking[] {
    const bookings = [];
    const now = new Date();
    studentCredit.bookings.forEach(booking => {
      if (booking.bookedClass.startDate >= now) {
        if (studentCredit.nextBooking == null || studentCredit.nextBooking.bookingId !== booking.bookingId) {
          bookings.push(booking);
        }
      }
    });

    return bookings;
  }

  getNumberOfMorePastBookings(studentCredit: StudentCreditPack): number {
    let numberOfBookings = 0;
    const now = new Date();
    studentCredit.bookings.forEach(booking => {
      if (booking.bookedClass.startDate < now) {
        numberOfBookings++;
      }
    });

    if (studentCredit.pastBooking != null) {
      numberOfBookings--;
    }

    return numberOfBookings;
  }

  getMorePastBookings(studentCredit: StudentCreditPack): Booking[] {
    const bookings = [];
    const now = new Date();
    studentCredit.bookings.forEach(booking => {
      if (booking.bookedClass.startDate < now) {
        if (studentCredit.pastBooking == null || studentCredit.pastBooking.bookingId !== booking.bookingId) {
          bookings.push(booking);
        }
      }
    });

    return bookings;
  }

  getPrograms(studentCredit: StudentCreditPack): string {
    const programList = [];
    studentCredit.creditPack.programmes.forEach(program => {
      programList.push(program.programName);
    });

    return programList.join(', ');
  }

  useCredit(studentCredit: SportsCreditStudentResult) {
    let found = false;
    studentCredit.creditResults.forEach(credit => {
      if (!found && credit.status === 'ACTIVE' && !credit.expired) {
        this.router.navigate(['/dashboard_booking_credits_use', credit.credit.sportsCreditId]);
        found = true;
      }
    });
  }

  getRenewText(creditResponse: CreditPackResponse): string {
    if (creditResponse.lastEnrolledCourse == null) {
      return 'search for courses';
    } else {
      return 'renew';
    }
  }

  renewCredit(creditResponse: CreditPackResponse): void {
    if (creditResponse.lastEnrolledCourse == null
      || (creditResponse.lastEnrolledClass != null && creditResponse.lastEnrolledClass.programLevel == null)) {
      // Need to start search from scratch
      const referrer = 'dashboard_credits';
      this.router.navigate(['dashboard_booking_start'], {queryParams: {referrer}});
    } else if (creditResponse.lastEnrolledClass != null && creditResponse.futureClassAvailable) {
      // Need to navigate to the overview page
      const selectedStudentId = 1;
      const programId = creditResponse.lastEnrolledClass.programLevel.programId;
      const programLevelId = creditResponse.lastEnrolledClass.programLevel.id;
      const studentId = creditResponse.studentCreditPack.student.id;
      const referrer = '/dashboard_credits';

      this.sessionService.courseSearchParameters = this.generateCourseSearchParameters(creditResponse);
      this.sessionService.saveCourseSearchParameters();

      this.router.navigate(['/course_single',
        creditResponse.lastEnrolledCourse.id], {queryParams: {selectedStudentId, studentId, programId, programLevelId, referrer}});
    } else {
      // Need to go to the search page, with search parameters set
      this.sessionService.courseSearchParameters = this.generateCourseSearchParameters(creditResponse);
      this.sessionService.saveCourseSearchParameters();

      const runSearch = true;
      this.router.navigate(['courses_list_single'], {queryParams: {runSearch}});
    }
  }

  generateCourseSearchParameters(creditResponse: CreditPackResponse): CourseSearchParameters {
    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    studentParameters.id = 1;
    studentParameters.studentId = creditResponse.studentCreditPack.student.id;
    if (creditResponse.lastEnrolledClass.programLevel != null) {
      studentParameters.programId = creditResponse.lastEnrolledClass.programLevel.programId;
      studentParameters.programLevelId = creditResponse.lastEnrolledClass.programLevel.id;
      studentParameters.programLevelName = creditResponse.lastEnrolledClass.programLevel.programLevelName;
    }

    studentParameters.studentName = creditResponse.studentCreditPack.student.givenName
      + ' ' + creditResponse.studentCreditPack.student.familyName;
    studentParameters.dateOfBirth = creditResponse.studentCreditPack.student.dateOfBirth;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    parameters.students = students;

    const venueOptions = new VenueOptions();
    venueOptions.distance = 5;
    venueOptions.address = '';
    venueOptions.venueId = creditResponse.lastEnrolledCourse.venueId;

    parameters.venueOptions = venueOptions;
    parameters.courseType = 'FLEXIBLE';

    return parameters;
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  getClassDuration(studentCredit: SportsCreditResult): string {
    if (studentCredit.minClassDurationInMinutes === studentCredit.maxClassDurationInMinutes) {
      return String(studentCredit.minClassDurationInMinutes);
    } else {
      return studentCredit.minClassDurationInMinutes + ' - ' + studentCredit.maxClassDurationInMinutes;
    }
  }

  getStudentsPerInstructor(studentCredit: SportsCreditResult): string {
    if (studentCredit.minNumberOfStudentsPerInstructor === studentCredit.maxNumberOfStudentsPerInstructor) {
      return String(studentCredit.minNumberOfStudentsPerInstructor);
    } else {
      return studentCredit.minNumberOfStudentsPerInstructor + ' - ' + studentCredit.maxNumberOfStudentsPerInstructor;
    }
  }
}
