import {DirectDebitDetails} from './direct-debit-details';

export class PaymentGatewayResponse {
  key: string;
  paymentGatewayType: string;
  hasMandate: boolean;
  directDebitDetails: DirectDebitDetails;
  monthlyWithCardProRata: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
