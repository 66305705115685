import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {checkStrength, PasswordStrengthValidator, PasswordValidator} from '../../_helpers/password_validator';
import {ApiService} from '../../api.service';
import {AuthService} from '../../auth-service';
import {getErrorMessage} from '../../_helpers/http_log_helper';
import {TitleGenerator} from '../../title-generator';
import {ApplicationSettingsService} from '../../application-settings.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  activateAccountForm: UntypedFormGroup;
  activationCode: string;
  showActivationCode = true;
  activationType: string;
  submitted = false;
  runningActivateAccount = false;
  fieldTextType = false;
  passwordStrength = 0;
  error: string;
  contactUsUrl: string;

  constructor( private formBuilder: UntypedFormBuilder,
               private route: ActivatedRoute,
               private router: Router,
               private titleService: TitleGenerator,
               private renderer: Renderer2,
               private apiService: ApiService,
               private authService: AuthService,
               private applicationSettingsService: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Activate Account');

    this.route.params.subscribe(params => {
      this.activationCode = params.id;
      this.activationType = params.type;

      this.activateAccountForm = this.formBuilder.group({
        passwordInput: ['', [Validators.minLength(6),
          PasswordStrengthValidator('passwordInput', 30),
          Validators.minLength(6),
          Validators.required]],
        confirmPasswordInput: ['', [
          Validators.required,
          PasswordValidator('passwordInput')
        ]]
      });

      const formControlFields = [];

      if (this.activationCode != null) {
        this.showActivationCode = false;
      } else {
        formControlFields.push({ name: 'activationCodeInput', control: new UntypedFormControl('', Validators.required) });
      }

      if (this.activationType === 'sms') {
        formControlFields.push({ name: 'emailInput',
          control: new UntypedFormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]) });
      }

      formControlFields.forEach(f => this.activateAccountForm.addControl(f.name, f.control));
    });

    this.renderer.addClass(document.body, 'activate-account');

    this.contactUsUrl = this.applicationSettingsService.contactUrl;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'activate-account');
  }

  onActivateAccountSubmit(): void {
    this.submitted = true;
    if (this.activateAccountForm.invalid || this.runningActivateAccount) {
      return;
    }

    this.runningActivateAccount = true;
    this.error = null;

    let requestEmail = null;

    if (this.showActivationCode) {
      this.activationCode = this.activateAccountForm.get('activationCodeInput').value;
    }

    if (this.activationType === 'sms') {
      requestEmail = this.activateAccountForm.get('emailInput').value;
    }

    const password = this.activateAccountForm.get('passwordInput').value;

    this.apiService.activateAccount(this.activationCode, requestEmail, password).subscribe(requestAccessResponse => {
        if (requestAccessResponse.status === 'success') {
          this.authService.loginAndRedirectToDashboard();
        } else if (requestAccessResponse.status === 'error' || requestAccessResponse.status === 'already-registered') {
          this.error = requestAccessResponse.error;
          this.runningActivateAccount = false;
        } else {
          console.error('Unknown activate account status: ' + requestAccessResponse.status);
        }
      },
      error => {
        this.runningActivateAccount = false;
        this.error = getErrorMessage(error);
      });
  }

  getActivateAccountComponent(componentName: string) {
    return this.activateAccountForm.get(componentName);
  }

  passwordEntered(passwordEntered: string) {
    this.passwordStrength = checkStrength(passwordEntered) / 10;
  }
}
