<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
          </div>
          <div class="span-info">
            <h2 class="title">
              waiting list preferences					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_waiting_list']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>


<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container" *ngIf="action != null && !running">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Thanks for letting us know.
              </h2>
              <div class="entry-content">
                <p *ngIf="action === 'keep'">Your waiting list entry is still active and we will continue to search for a place.</p>
                <p *ngIf="action === 'remove'">Your waiting list entry has been closed.</p>
              </div>
            </div>

            <button type="button" class="btn btn-block btn-red wl-button" [routerLink]="['/dashboard']">
              view your dashboard
            </button>

          </div>
      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
