import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Program} from '../../_model/program';

@Injectable({
  providedIn: 'root'
})
export class SelectProgramService {

  currentTitle: Observable<string>;
  private titleSubject = new BehaviorSubject<string>(null);

  currentProgram: Observable<Program>;
  private programSubject = new BehaviorSubject<Program>(null);

  constructor() {
    this.currentTitle = this.titleSubject.asObservable();
    this.currentProgram = this.programSubject.asObservable();
  }

  setTitle(message: string) {
    this.titleSubject.next(message);
  }

  setSetProgramId(programId: Program) {
    this.programSubject.next(programId);
  }
}
