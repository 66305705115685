    <div *ngIf="currentQuestion != null" class="container multi-step-form">

      <form *ngIf="skillCheckStepOne" method="post" id="form_skill_check" class="form-skill-check" [formGroup]="selectProgramLevel">

        <div class="multi-step-track">
          <div class="slide-track">

            <div class="form-section current">
              <div id="panel_find_skill_check" class="panel panel-find-skill-check">

                <div class="span-title">
                  <h2 class="entry-title">
                    {{currentQuestion.heading}}
                  </h2>
                  <div class="entry-content">
                    <p>{{currentQuestion.question}}</p>
                  </div>
                </div>

                <div class="row row-cards">

                  <div class="span-card">
                    <div class="card style-red">

                      <div class="span-choice">
                        <label for="check_no" class="input-check radio">
                          <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>						<input
                          id="check_no"
                          type="radio"
                          name="check_no"
                          value="check_no"
                          data-next="2"
                          formControlName="check_no"
                          (click)="answerNo()"
                        />
                          <span class="radio-content">
                          <ul class="label-description">
                            <li class="title">no</li>
                          </ul>
                        </span>
                        </label>
                      </div>

                    </div>
                  </div>

                  <div class="span-card">
                    <div class="card style-green">

                      <div class="span-choice">
                        <label for="check_yes" class="input-check radio">
                          <span class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span>						<input
                          id="check_yes"
                          type="radio"
                          name="check_yes"
                          value="check_yes"
                          data-next="2"
                          formControlName="check_yes"
                          (click)="answerYes()"
                        />
                          <span class="radio-content">
              <ul class="label-description">
                <li class="title">yes</li>
              </ul>
            </span>
                        </label>
                      </div>

                    </div>
                  </div>

                </div>

              </div>					</div>

          </div>
        </div>

      </form>

      <form *ngIf="skillCheckStepTwo && currentAnswer != null" method="post" id="form_program_level_result" [formGroup]="selectProgramLevelResultForm">
        <div id="panel_find_result" class="panel panel-find-result">

          <div class="span-title">
            <h2 class="entry-title">
              Drumroll please! They are a…		</h2>
          </div>

          <div class="row row-cards">

            <div class="span-card">
              <div class="card style-checked">

                <div class="span-choice">
                  <label
                    for="level_result"
                    class="input-check radio">
                    <img src="{{getLevelImageUrlSrc(currentAnswer.id)}}" alt="{{currentAnswer.programLevelName}}" />
                    <input
                      id="level_result"
                      [value]="currentAnswer.id"
                      type="radio"
                      name="level_result"
                      formControlName="level_result"
                    />
                    <span class="radio-content">
              <ul class="label-description">
                <li class="title">{{currentAnswer.programLevelName}}</li>
                <li class="lead">
                  (Level
                  {{currentAnswer.sequence}})
                </li>
                <li class="description" [innerHTML]="getProgramLevelText(currentAnswer)"></li>
              </ul>
            </span>
                  </label>
                </div>

              </div>
            </div>

            <button type="submit" class="btn btn-block btn-red" data-next="5" (click)="onProgramLevelResultSelected()">
              continue
            </button>

          </div>

        </div>
      </form>

    </div>
