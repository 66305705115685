import {SportsFeedbackParameter} from './sports-feedback-parameter';
import {convertObjectToDate} from '../_helpers/date_helper';
import {Class} from './class';

export class SubscriptionCancellationResponse {
  cancellationPeriod: string;
  cancellationUnit: number;
  cancellationEndDate: Date;
  lastPaymentDate: Date;
  lastClass: Class;
  minimumTermPeriod: string;
  minimumTermUnit: number;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.cancellationEndDate = convertObjectToDate(this.cancellationEndDate);
    this.lastPaymentDate = convertObjectToDate(this.lastPaymentDate);
    if (this.lastClass != null) {
      this.lastClass.startDate = convertObjectToDate(this.lastClass.startDate);
      this.lastClass.endDate = convertObjectToDate(this.lastClass.endDate);
    }
  }
}
