import {Injectable, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ApplicationSettingsService} from './application-settings.service';
import {Subscription} from 'rxjs';

@Injectable()
export class TitleGenerator implements OnDestroy {

  pageName: string;
  appSettingsService: Subscription;

  constructor(private titleService: Title,
              private applicationSettings: ApplicationSettingsService) {
  }

  setTitle(title: string) {
    this.pageName = title;
    this.updateTitle();
  }

  updateTitle() {
    let pageTitle = this.pageName;

    if (this.applicationSettings.companyName != null) {
      pageTitle = this.applicationSettings.companyName + ' | ' + this.pageName;
    }

    this.titleService.setTitle(pageTitle);
  }

  ngOnDestroy() {
    if (this.appSettingsService != null) {
      this.appSettingsService.unsubscribe();
    }
  }
}
