<div class="face">
  <div class="band">
    <div class="red"></div>
    <div class="white"></div>
    <div class="blue"></div>
  </div>
  <div class="eyes"></div>
  <div class="dimples"></div>
  <div class="mouth"></div>
</div>

<h1 *ngIf="!cannotConnectToServer">Oops! Something went wrong!</h1>
<span *ngIf="cannotConnectToServer"><h1>Oops! Something went wrong!</h1><h2 class="cannotConnectText">We are unable to connect to the server, please try again later.</h2></span>

<div *ngIf="!cannotConnectToServer" class="btn" (click)="returnHome()">Return to Home</div>
<div *ngIf="cannotConnectToServer" class="btn" (click)="returnHome()">Retry</div>
