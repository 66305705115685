import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-dashboard-booking-manage',
  templateUrl: './dashboard-booking-manage.component.html',
  styleUrls: ['./dashboard-booking-manage.component.css']
})
export class DashboardBookingManageComponent implements OnInit, OnDestroy {

  enrolment: EnrolmentResponse;
  canUpgrade = false;
  canMultipleUpgrade = false;
  priceType: string;
  referrer: string;
  canTransfer = false;
  canCancel = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.titleService.setTitle('Manage Booking');

    this.route.params.subscribe(params => {
      this.apiService.getEnrolment(params.id, this.ngUnsubscribe).subscribe(enrolment => {
        this.enrolment = enrolment;
        if (enrolment.enrolmentStatus === 'PROVISIONAL' || enrolment.enrolmentType === 'SINGLE_TERM') {
          this.apiService.getEnrolmentPrices(enrolment.enrolmentId, this.ngUnsubscribe).subscribe(priceList => {
            if (priceList.prices != null) {
              priceList.prices.forEach(price => {
                if ((enrolment.enrolmentStatus === 'CONFIRMED'
                  && enrolment.enrolmentType === 'SINGLE_TERM'
                  && price.priceType === 'MULTIPLE_TERMS')
                  || (enrolment.enrolmentStatus === 'CONFIRMED' && price.priceType === 'PRE_SALE')) {
                  if (this.canUpgrade) {
                    this.canMultipleUpgrade = true;
                  }
                  this.canUpgrade = true;
                  this.priceType = price.priceType;
                }
              });
            }
          });
        }
        this.apiService.canTransferEnrolment(enrolment.enrolmentId).subscribe(transferResult => this.canTransfer = transferResult);
        this.apiService.canCancelEnrolment(enrolment.enrolmentId).subscribe(cancelResult => this.canCancel = cancelResult);
      });
    });

    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });

    this.renderer.addClass(document.body, 'dashboard-booking-manage');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-manage');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  upgradeBooking(): void {
    if (this.canMultipleUpgrade) {
      this.router.navigate(['/dashboard_upgrades_offers']);
    } else if (this.priceType === 'MULTIPLE_TERMS') {
      this.router.navigate(['/dashboard_booking_upgrade', this.enrolment.enrolmentId]);
    } else if (this.priceType === 'PRE_SALE') {
      this.router.navigate(['/dashboard_pre_sale_upgrade', this.enrolment.enrolmentId]);
    }
  }

  goBack(): void {
    if (this.referrer == null) {
      this.referrer = 'dashboard_bookings';
    }

    if (this.referrer === 'dashboard_bookings') {
      this.router.navigate(['/dashboard_bookings']);
    } else {
      this.router.navigate(['/' + this.referrer, this.enrolment.enrolmentId]);
    }
  }

  cancelSubscription(): void {
    this.router.navigate(['/dashboard_subscription_cancel', this.enrolment.enrolmentId]);
  }
}
