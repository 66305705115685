<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white; text-align: center" > Please wait&#x2026;<br><br> We are processing your transaction, do not navigate away from this page. </p></ngx-spinner>
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_booking_confirm', enrolmentId]">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              confirm booking					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_bookings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container multi-step-form">

      <div class="card">
        <div class="multi-step-track">
          <div class="slide-track">
        <form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
          <div class="form-section card-checkout current">
            <div class="card-inner">
              <div class="table-summary table-checkout">

                <div class="table-responsive" *ngIf="previewEnrolment != null">
                  <table class="table table-multiple">
                    <tbody *ngFor="let enrolment of previewEnrolment.studentEnrolments">
                    <tr *ngIf="enrolment.invoices.length === 0">
                      <th>{{enrolment.studentName}}</th>
                      <th>
                        <span>{{0 | currency:previewEnrolment.currency:'symbol-narrow'}}</span>
                      </th>
                    </tr>
                    <tr *ngFor="let invoice of enrolment.invoices">
                      <th>{{invoice.description}}</th>
                      <th>
                        <span class="discount" *ngIf="invoice.totalDiscounts < 0">{{(invoice.totalAmount - invoice.totalDiscounts) | currency:previewEnrolment.currency:'symbol-narrow'}}</span>&nbsp;
                        <span>{{invoice.totalAmount | currency:invoice.currency:'symbol-narrow'}}</span>
                      </th>
                    </tr>
                    <tr *ngIf="enrolment.restrictions.length > 0" class="restriction">
                      <td>
                        <div class="restriction-alert">
                          <svg class="exclamation-mark">
                            <title>Course Restriction</title>
                            <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                          </svg>
                        </div>
                        <div class="restriction-name">
                          <span *ngFor="let restriction of enrolment.restrictions; let i = index">{{restriction.description}}<span *ngIf="i > 0">, </span></span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{{enrolment.levelName}} - {{enrolment.programName}}</td>
                      <td *ngIf="enrolment.enrolmentType !== 'MONTHLY'">{{formatPriceTypeTitle(enrolment)}}</td>
                      <td *ngIf="enrolment.enrolmentType === 'MONTHLY'">{{getMonthlyAmount(enrolment) | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}} per month</td>
                    </tr>
                    <tr *ngIf="enrolment.enrolmentType === 'MONTHLY'">
                      <td *ngIf="enrolment.monthlyDiscount == null"><div class="monthly-pricing-description">{{enrolment.monthlyPrice.amount | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}} per month from {{enrolment.monthlyContractStartDate | worldDate: 'EEEE d MMM yyyy'}}</div></td>
                      <td *ngIf="enrolment.monthlyDiscount != null && enrolment.monthlyDiscount.subscriptionPeriod === 'all'"><div class="monthly-pricing-description">{{getMonthlyAmount(enrolment) | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}} per month from {{enrolment.monthlyContractStartDate | worldDate: 'EEEE d MMM yyyy'}} (discounted from {{enrolment.monthlyPrice.amount | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}})</div></td>
                      <td *ngIf="enrolment.monthlyDiscount != null && enrolment.monthlyDiscount.subscriptionPeriod === 'partial'"><div class="monthly-pricing-description">{{getMonthlyAmount(enrolment) | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}} per month from {{enrolment.monthlyContractStartDate | worldDate: 'EEEE d MMM yyyy'}} to {{getMonthlyEndDate(enrolment) | worldDate: 'EEEE d MMM yyyy'}} then {{enrolment.monthlyPrice.amount | currency:enrolment.monthlyPrice.currency:'symbol-narrow'}} per month</div></td>
                      <td></td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr *ngIf="previewEnrolment.accountBalance > 0">
                      <th colspan="2">{{previewEnrolment.accountBalance | currency:previewEnrolment.currency:'symbol-narrow'}}</th>
                    </tr>
                    <tr *ngIf="previewEnrolment.accountBalance > 0">
                      <td colspan="2">Account Balance</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{previewEnrolment.totalAmountWithBalance | currency:previewEnrolment.currency:'symbol-narrow'}}</th>
                    </tr>
                    <tr>
                      <td colspan="2">total</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-checkout-payment-voucher [voucherCode]="voucherCode" [voucherUsed]="previewEnrolment.voucherUsed"></app-checkout-payment-voucher>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div></div>

            <span *ngIf="venuePolicies != null">
              <div class="card-inner" *ngFor="let policy of venuePolicies">

                <div class="row row-policy-title">

                  <h3 class="span-heading title-label">
                    {{policy.title}}
                  </h3>

                  <div class="row row-policy-text" [innerHTML]="policy.policy">

                  </div>

                  <div class="span-input span-checkbox required">
                    <label
                      for="policy_{{policy.id}}"
                      class="input-check checkbox">
                      <input
                        id="policy_{{policy.id}}"
                        name="policy_{{policy.id}}"
                        type="checkbox"
                        required="required"
                        class="input-checkbox"
                        formControlName="policyCheckbox_{{policy.id}}"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="policy.title"
                        [requireChecked]="true"
                      />
                      <span>
                    I accept the terms above.
                  </span>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                    </label>
                    <ul class="parsley-errors-list filled"></ul>
                  </div>

                </div>
              </div>
            </span>

            <span *ngIf="autoEnrolmentResponse != null && autoEnrolmentResponse.enabled">
            <div class="card-inner">

              <div class="row row-policy-title">

                <div class="row row-policy-text" [innerHTML]="autoEnrolmentResponse.enrolmentText">

                </div>

              </div>
            </div>
            </span>

            <div class="card-inner">

              <div class="row row-payment-title" *ngIf="previewEnrolment != null && previewEnrolment.totalAmountWithBalance > 0">

                <h3 class="span-heading title-label">
                  Payment method
                </h3>
                <div class="span-cards" *ngIf="!requiresPaymentSelection && (currentTransactionType === 'CARD' || (currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD' && !this.requiresMandate))">
                  <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>		<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>	</div>

              </div>

              <div class="row row-card-info">
                <div class="payment-type-radio-toolbar">
                  <input
                    id="paymentMethodBACS"
                    type="radio"
                    name="paymentMethodInput"
                    [value]="'MONTHLY_DIRECT_DEBIT'"
                    formControlName="paymentMethodInput"
                    (change)="paymentTypeSelected('MONTHLY_DIRECT_DEBIT')"
                  />
                  <label for="paymentMethodBACS">Bank Account <span class="svg-icon student-2 payment-type-icon"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-direct-debit"></use></svg></span></label>

                  <input
                    id="paymentMethodCard"
                    type="radio"
                    name="paymentMethodInput"
                    [value]="'MONTHLY_CREDIT_CARD'"
                    formControlName="paymentMethodInput"
                    (change)="paymentTypeSelected('MONTHLY_CREDIT_CARD')"
                  />
                  <label for="paymentMethodCard">Credit Card <span class="svg-icon student-2 payment-type-icon"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg> <svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span></label>

                </div>
              </div>

                <app-checkout-payment-method-card *ngIf="!requiresPaymentSelection && (currentTransactionType === 'CARD' || (currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD' && !this.requiresMandate)) && previewEnrolment != null && previewEnrolment.totalAmountWithBalance > 0"
                  [submitted]="submitted"
                  [paymentForm]="paymentForm"
                  [accountId]="enrolment.account.id"
                  [licenseeId]="enrolment.course.licenseeId"
                  [addressLinesActive]="addressLinesActive"
                  [updateInvoices]="currentTransactionType !== 'MONTHLY_DIRECT_DEBIT_WITH_CARD'"></app-checkout-payment-method-card>

              <span *ngIf="!requiresPaymentSelection && directDebitDetails != null && (currentTransactionType === 'MONTHLY_DIRECT_DEBIT' || currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && !requiresMandate">
                <div class="row row-card-info provisional-bank-account">
                We will debit funds from your {{directDebitDetails.bankName}} bank account ending {{directDebitDetails.trailingFourAccountDigits}}.
                </div>
              </span>

              <span *ngIf="!requiresPaymentSelection && termsAndConditions != null && termsAndConditions.valid">
              <div class="span-input span-checkbox required" [style.display]="(currentTransactionType === 'CARD' || !requiresMandate) ? '' : 'none'">
                <label
                  for="enrolmentTerms"
                  class="input-check checkbox">
                  <input
                    id="enrolmentTerms"
                    name="enrolmentTerms"
                    type="checkbox"
                    required="required"
                    class="input-checkbox"
                    formControlName="enrolmentTermsCheckbox"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Terms and Conditions'"
                    [requireChecked]="true"
                  />
                  <span>
			I accept the <a target="_blank" [routerLink]="['/terms_and_conditions']">terms & conditions</a>.
		</span>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                </label>
                <ul class="parsley-errors-list filled"></ul>
              </div>
              </span>

              <button type="submit" *ngIf="currentTransactionType === 'CARD' || ((currentTransactionType === 'MONTHLY_DIRECT_DEBIT' || currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && !this.requiresMandate)" [ngClass]="enrolling || paymentForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
                Complete booking
              </button>

              <button type="button" (click)="setupDirectDebit()" *ngIf="(currentTransactionType === 'MONTHLY_DIRECT_DEBIT' || currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && this.requiresMandate" [ngClass]="settingUpMandate ? 'disabled' : ''" class="btn btn-block btn-red">
                Setup Direct Debit
              </button>

            </div>
          </div>
        </form>

      </div>
    </div>
    </div>
  </div>
  </div>
  </main>
</div>

<app-footer></app-footer>
