import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationMessage} from '../../_model/confirmation-message';
import {DashboardService} from '../dashboard.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard-confirmation',
  templateUrl: './dashboard-confirmation.component.html',
  styleUrls: ['./dashboard-confirmation.component.css']
})
export class DashboardConfirmationComponent implements OnInit, OnDestroy {

  confirmationMessage: ConfirmationMessage;

  confirmationMessageSubscription: Subscription;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.confirmationMessage = null;
    this.confirmationMessageSubscription = this.dashboardService.currentConfirmationMessage
      .subscribe(message => {
        if (message != null) {
          this.confirmationMessage = message;
        }
      });
  }

  ngOnDestroy() {
    if (this.confirmationMessageSubscription != null) {
      // If I do not put this is will continue to reload the message between pages
      this.dashboardService.setConfirmationMessage(null);
      this.confirmationMessageSubscription.unsubscribe();
    }
  }
}
