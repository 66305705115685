import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CourseSearchResult} from '../../_model/course-search-result';
import {CoursePriceList} from '../../_model/course-price-list';
import {ActivatedRoute, Router} from '@angular/router';
import {CourseSingleService} from './course-single.service';
import {numberToWords} from 'number-to-words';
import {ProgramLevel} from '../../_model/program-level';
import {Program} from '../../_model/program';
import {ApiService} from '../../api.service';
import {Address} from '../../_model/address';
import {Venue} from '../../_model/venue';
import {DomSanitizer} from '@angular/platform-browser';
import {getPriceTypeShort, getPriceTypeTitle} from '../../_helpers/enrolment_helper';
import {Basket} from '../../_model/basket';
import {BasketStudent} from '../../_model/basket-student';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseSearchUpdateRequest} from '../../_model/course-search-update-request';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {CoursePrice} from '../../_model/course-price';
import {BasketService} from '../../checkout/basket.service';
import {CreateAccountRequest} from '../../_model/create-account-request';
import {PaymentMethod} from '../../_model/payment-method';
import {Course} from '../../_model/course';
import {Class} from '../../_model/class';
import {CreditPackResult} from '../../_model/credit-pack-result';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {ApplicationSettingsService} from '../../application-settings.service';
import {AlertService} from '../../alert';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {VenueOptions} from '../select-location/select-location.component';
import {GeocodingService} from '../../geocoding.service';

@Component({
  selector: 'app-course-single',
  templateUrl: './course-single.component.html',
  styleUrls: ['./course-single.component.css']
})
export class CourseSingleComponent implements OnInit, OnDestroy {

  courseResults: CourseSearchResult[];
  courseResult: CourseSearchResult;
  coursePriceList: CoursePriceList;
  studentId: string;
  selectedStudentId: number;
  courseId: string;
  programLevelId: string;
  programId: string;

  program: Program;
  programLevel: ProgramLevel;
  address: Address;
  venue: Venue;
  course: Course;

  classesAvailable: Class[];
  numberOfLessons: number;
  maxNumberOfLessons: number;
  creditPacks: CreditPackResult[];
  selectedClasses: Class[];

  countryCode: string;
  referrer: string;
  disableContinueButton = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  applicationSettingsSubscription: Subscription;
  searchResultSubscription: Subscription;
  priceListSubscription: Subscription;
  courseSearchParamsSubscription: Subscription;
  courseSearchResultsSubscription: Subscription;

  listPosition: number;

  map: google.maps.Map;
  service: google.maps.places.PlacesService;
  infowindow: google.maps.InfoWindow;

  mapInit = false;

  constructor(private route: ActivatedRoute,
              private courseSingleService: CourseSingleService,
              private courseListSingleService: CourseListSingleServiceService,
              private checkoutBasketService: BasketService,
              private apiService: ApiService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private applicationSettings: ApplicationSettingsService,
              private alertService: AlertService,
              private basketService: BasketService,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService,
              private spinner: NgxSpinnerService,
              private changeDetectorRef: ChangeDetectorRef,
              private geocodingService: GeocodingService) {}

  updateNumberOfLessons(): void {
    if (this.maxNumberOfLessons == null || this.classesAvailable == null) {
      return;
    }

    let numberOfClasses: number;
    if (this.classesAvailable.length > this.maxNumberOfLessons) {
      numberOfClasses = this.maxNumberOfLessons;
    } else {
      numberOfClasses = this.classesAvailable.length;
    }

    this.numberOfLessons = numberOfClasses;
    this.updateSelectedClasses();
  }

  updateSelectedClasses() {
    let counter = 0;
    this.selectedClasses = [];
    this.classesAvailable.forEach(classItem => {
      if (counter < this.numberOfLessons) {
        this.selectedClasses.push(classItem);
      }
      counter++;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Course');
    this.spinner.show();

    this.countryCode = this.applicationSettings.countryCode;

    this.searchResultSubscription = this.courseSingleService.currentCourseSearchResult.subscribe(element => {
      if (element != null) {
        this.courseResult = element;
      }
    });
    this.priceListSubscription = this.courseSingleService.currentCoursePriceList.subscribe(element => {
      if (element != null) {
        this.coursePriceList = element;
        this.loadCoursePriceDescriptions();
      }
    });
    this.courseSearchResultsSubscription = this.courseSingleService.currentCourseSearchResults
      .subscribe(element => this.courseResults = element);

    this.route.params.subscribe(params => {
      this.courseId = params.id;
      this.apiService.getCourse(params.id, this.ngUnsubscribe).subscribe(course => {
        this.course = course;
        this.apiService.getVenue(this.course.venueId, this.ngUnsubscribe).subscribe(venue => {
          this.venue = venue;
          this.changeDetectorRef.detectChanges();
          if ((venue.latitude != null && venue.longitude != null) || venue.placeId != null) {
            this.initMap();
          }
        });
        this.apiService.getAddress(this.course.venueId, this.ngUnsubscribe).subscribe(address => {
          this.address = address;
          this.initMap();
        });

        this.route.queryParams
            .subscribe(queryParams => {
              this.referrer = queryParams.referrer;
              this.selectedStudentId = parseInt(queryParams.selectedStudentId, 10);
              this.studentId = queryParams.studentId;
              this.programLevelId = queryParams.programLevelId;
              this.programId = queryParams.programId;

              if (queryParams.listPosition != null) {
                this.listPosition = parseInt(queryParams.listPosition, 10);
              }

              this.apiService.getProgram(this.programId, this.ngUnsubscribe).subscribe(data => this.program = data);
              this.apiService.getProgramLevel(queryParams.programLevelId, this.ngUnsubscribe).subscribe(data => {
                this.programLevel = data;

                if (this.coursePriceList == null) {
                  this.apiService.getCoursePrices(this.courseId,
                      queryParams.programLevelId, this.studentId, null, this.ngUnsubscribe)
                      .subscribe(priceList => {
                        this.coursePriceList = priceList;
                        if (this.coursePriceList == null) {
                          this.alertService.error('Failed to load prices');
                        }
                        this.googleAnalytics.viewCourse(this.course, this.programLevel, this.listPosition, 'New Enrolment');
                        this.loadCoursePriceDescriptions();
                        this.spinner.hide();
                      }, () => {
                        this.spinner.hide();
                      });
                } else {
                  this.spinner.hide();
                  this.googleAnalytics.viewCourse(this.course, this.programLevel, this.listPosition, 'New Enrolment');
                }
              });
              this.apiService.getAvailableCourseClasses(this.courseId, this.studentId, this.ngUnsubscribe).subscribe(classesAvailable => {
                this.classesAvailable = classesAvailable;
                this.updateNumberOfLessons();
              });

              if (this.course.courseType === 'FLEXIBLE') {
                this.apiService.getCourseCreditPacks(this.courseId, this.programLevelId, null, this.ngUnsubscribe).subscribe(creditPacks => {
                  this.creditPacks = creditPacks;
                  this.creditPacks.forEach(creditPack => {
                    if (this.maxNumberOfLessons == null) {
                      this.maxNumberOfLessons = creditPack.creditPack.numberOfLessons;
                    } else if (this.maxNumberOfLessons < creditPack.creditPack.numberOfLessons) {
                      this.maxNumberOfLessons = creditPack.creditPack.numberOfLessons;
                    }
                  });

                  this.updateNumberOfLessons();
                });
              }
            });
      });
    });

    this.renderer.addClass(document.body, 'course-single');
    this.renderer.addClass(document.body, 'type-single');

    // TODO: Should check if logged in and navigate to the dashboard or booking start
    if (this.sessionService.courseSearchParameters == null) {
      this.router.navigate(['/courses_list_single']);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }
    if (this.searchResultSubscription != null) {
      this.searchResultSubscription.unsubscribe();
    }
    if (this.priceListSubscription != null) {
      this.priceListSubscription.unsubscribe();
    }
    if (this.courseSearchParamsSubscription != null) {
      this.courseSearchParamsSubscription.unsubscribe();
    }
    if (this.courseSearchResultsSubscription != null) {
      this.courseSearchResultsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'course-single');
    this.renderer.removeClass(document.body, 'type-single');
  }

  loadCoursePriceDescriptions() {
    if (this.coursePriceList != null && this.coursePriceList.prices != null) {
      const priceListTypes = [];
      this.coursePriceList.prices.forEach(price => {
        if ((price.priceType === 'MONTHLY_DIRECT_DEBIT' && priceListTypes.includes('MONTHLY_CREDIT_CARD')) || (price.priceType === 'MONTHLY_CREDIT_CARD' && priceListTypes.includes('MONTHLY_DIRECT_DEBIT'))) {
          price.isHidden = true;
        } else {
          this.apiService.getEnrolmentTypeDescription(price.priceType, this.ngUnsubscribe).subscribe(points => {
            price.descriptionLines = points;

            price.descriptionLines.forEach(description => {
              if (description.content.indexOf('{makeup_lessons}') > -1) {
                description.content = description.content.replace('{makeup_lessons}', String(price.numberOfClassCredits));
              }
            });
          });
        }
        priceListTypes.push(price.priceType);
      });

      if (priceListTypes.includes('MONTHLY_DIRECT_DEBIT') && priceListTypes.includes('MONTHLY_CREDIT_CARD')) {
        this.coursePriceList.prices.forEach(price => {
            price.requiresPaymentSelection = ((price.priceType === 'MONTHLY_DIRECT_DEBIT' || price.priceType === 'MONTHLY_CREDIT_CARD') && !price.isHidden);
        });
      } else {
        this.coursePriceList.prices.forEach(price => {
          price.requiresPaymentSelection = false;
        });
      }
    }
  }

  getNumberOfStudentsText(): string {
    if (this.course == null) {
      return '';
    }

    return numberToWords.toWords(this.course.numberOfStudentsPerInstructor);
  }

  formatPriceTypeTitle(enrolmentType: string): string {
    return getPriceTypeTitle(enrolmentType);
  }

  formatPriceTypeShort(enrolmentType: string): string {
    return getPriceTypeShort(enrolmentType);
  }

  addToBasket(enrolmentType: string) {
    this.processBasket(enrolmentType);
  }

  processBasket(enrolmentType: string) {
    if (this.disableContinueButton) {
      return;
    }

    if (this.coursePriceList.prices == null) {
      this.alertService.error('Failed to find price for course');
      return;
    }

    this.disableContinueButton = true;

    // Need to do this in case another tab has added something to the basket
    if (this.sessionService.basket == null) {
      this.sessionService.basket = new Basket();
      this.sessionService.basket.students = [];
      this.sessionService.basket.createAccountRequest = new CreateAccountRequest();
      this.sessionService.basket.createAccountRequest.address = new Address();
      this.sessionService.basket.createAccountRequest.address.country = this.countryCode;
      this.sessionService.basket.createAccountRequest.marketingOptInSMS = false;
      this.sessionService.basket.createAccountRequest.marketingOptInEmail = false;
      this.sessionService.basket.cardPaymentMethod = new PaymentMethod();
      this.sessionService.basket.cardPaymentMethod.address = new Address();
    }

    if (this.sessionService.basket.licenseeId == null) {
      this.sessionService.basket.licenseeId = this.course.licenseeId;
    }

    this.sessionService.basket.currencyCode = this.coursePriceList.currencyCode;

    // Need to see if it already exists in the basket and if so, replace it
    let foundStudent: BasketStudent = null;
    if (this.course.courseType === 'FLEXIBLE') {
      // Only one student allowed in basket
      this.sessionService.basket.students = [];
    } else {
      let indexToRemove = null;
      let counter = 0;
      this.sessionService.basket.students.forEach(student => {
        if (student.student.id === this.selectedStudentId) {
          foundStudent = student;
          indexToRemove = counter;
        }
        counter++;
      });

      if (indexToRemove != null) {
        this.sessionService.basket.students.splice(indexToRemove, 1);
      }
    }

    // Need to find the student
    let courseSearchStudent: CourseSearchStudent;
    courseSearchStudent = null;
    this.sessionService.courseSearchParameters.students.forEach(student => {
      if (student.id === this.selectedStudentId) {
        courseSearchStudent = student;
      }
    });

    if (courseSearchStudent == null) {
      console.log('Student course search not found, assuming it has been removed so recreating');
      const parameters = new CourseSearchParameters();
      const studentParameters = new CourseSearchStudent();

      studentParameters.id = 1;
      studentParameters.programId = this.programId;
      studentParameters.programLevelId = this.programLevelId;
      studentParameters.programLevelName = this.programLevel.programLevelName;

      const students = new Array<CourseSearchStudent>();
      students.push(studentParameters);

      parameters.students = students;
      parameters.venueOptions = new VenueOptions();

      this.sessionService.courseSearchParameters = parameters;
      this.sessionService.saveCourseSearchParameters();

      courseSearchStudent = studentParameters;
    }

    const basketStudent = new BasketStudent();
    basketStudent.id = this.sessionService.basket.nextBasketStudentId();
    basketStudent.program = this.program;
    basketStudent.venueAddress = this.address;
    basketStudent.listPosition = this.listPosition;

    this.coursePriceList.prices.forEach(price => {
      if (price.priceType === enrolmentType) {
        basketStudent.coursePrice = price;
      }
    });

    basketStudent.student = courseSearchStudent;
    basketStudent.course = this.course;

    if (foundStudent != null) {
      basketStudent.student = foundStudent.student;
    }

    if (this.classesAvailable == null || this.classesAvailable.length === 0) {
      this.alertService.error('No classes available to book');
      return;
    }
    basketStudent.startDate = this.classesAvailable[0].startDate;

    if (this.course.courseType === 'FLEXIBLE') {
      basketStudent.numberOfWeeksToEnrol = this.numberOfLessons;
    } else {
      basketStudent.numberOfWeeksToEnrol = this.coursePriceList.numberOfWeeksToEnrol;
    }

    // If they are existing, then set this in the basket for checkout
    if (courseSearchStudent.studentId != null && courseSearchStudent.studentId !== '') {
      basketStudent.whoIsSwimming = courseSearchStudent.studentId;

      let allStudentsCreated = true;
      this.sessionService.basket.students.forEach(student => {
        if (student.student.studentId == null || student.student.studentId === '') {
          allStudentsCreated = false;
        }
      });

      if (allStudentsCreated) {
        this.sessionService.basket.studentsCreated = true;
      }
    }

    this.sessionService.basket.students.push(basketStudent);

    // Update the basket in the session
    this.sessionService.saveBasket();

    this.googleAnalytics.addToCart(basketStudent, this.coursePriceList.currencyCode, 'New Enrolment');

    let requiresRefresh = false;
    const updateRequest = new CourseSearchUpdateRequest();
    updateRequest.students = [];

    if (this.courseResult != null) {
      this.courseResult.courseSearchStudents.forEach(student => {
        let inBasket = false;

        this.sessionService.basket.students.forEach(studentInBasket => {
          if (student.id === studentInBasket.student.id && this.course.id === studentInBasket.course.id) {
            inBasket = true;
          }
        });

        if (!inBasket) {
          requiresRefresh = true;
        }

        this.sessionService.courseSearchParameters.students.forEach(result => {
          if (result.id === student.id) {
            result.toEnrol = inBasket;

            // Need to clone the object otherwise it will override with the next iteration
            const newResult = new CourseSearchStudent();
            newResult.id = result.id;
            newResult.studentId = result.studentId;
            newResult.toEnrol = inBasket;
            newResult.programId = result.programId;
            newResult.programLevelId = result.programLevelId;
            newResult.dateOfBirth = result.dateOfBirth;

            updateRequest.students.push(newResult);
          }
        });
      });
    }

    if (requiresRefresh) {
      updateRequest.courseId = this.course.id;

      this.apiService.updateCourseSearchStudentEnrolment(updateRequest).subscribe(result => {
        result.courseSearchStudents.forEach(resultStudent => {
          this.courseResult.courseSearchStudents.forEach(courseSearchStudentItem => {
            if (courseSearchStudentItem.id === resultStudent.id) {
              courseSearchStudentItem.canEnrol = resultStudent.canEnrol;
            }
          });
        });

        this.processRedirect();
      });
    } else {
      this.processRedirect();
    }
  }

  processRedirect() {
    // Sent them back to the results if there is another student to process
    // Sending the updated basket
    if (this.course.courseType !== 'FLEXIBLE'
      && this.sessionService.courseSearchParameters.students.length > this.sessionService.basket.students.length) {
      this.navigateToCourseSearch();
      return;
    }

    let redirectToBuy = false;
    if (this.sessionService.courseSearchParameters.students.length > 1) {
      // If multiple, send them to the summary page
      const referrer = this.getUrl();
      this.router.navigate(['/cart'], {queryParams: {referrer}});
    } else {
      if (this.course.courseType === 'FLEXIBLE') {
        // Check to see that the basket has the student in it
        // We assume there is only one student in it for flexible
        for (const basketStudent of this.sessionService.basket.students) {
          if (basketStudent.student.studentId != null) {
            // If single, send them to the payment details page
            redirectToBuy = true;
          }
        }
      }
      // If single, send them to the payment details page
      if (redirectToBuy) {
        const referrer = this.getUrl();
        this.router.navigate(['/dashboard_booking_credits_buy'], {queryParams: {referrer}});
      } else {
        const referrer = this.getUrl();
        this.router.navigate(['/checkout'], {queryParams: {referrer}});
      }
    }
  }

  getPrice(coursePrice: CoursePrice): number {
    if (coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT'
      || coursePrice.priceType === 'MONTHLY_CREDIT_CARD'
      || coursePrice.priceType === 'CREDIT_PACK') {
      return coursePrice.price;
    } else {
      return coursePrice.totalPrice;
    }
  }

  getInstructorImageUrl(instructorId: string): string {
    return environment.apiUrl + 'images/instructors/' + instructorId + '/image';
  }

  getClassList(): number[] {
    if (this.maxNumberOfLessons == null || this.classesAvailable == null) {
      return [];
    }

    const classesList = [];
    let numberOfClasses = this.classesAvailable.length;
    if (this.maxNumberOfLessons < numberOfClasses) {
      numberOfClasses = this.maxNumberOfLessons;
    }

    for (let i = 1; i <= numberOfClasses; i++) {
      classesList.push(i);
    }

    return classesList;
  }

  getVenueImageUrl(): string {
    if (this.course.venueImageExists) {
      return 'url(\'' + environment.apiUrl + 'images/venues/' + this.course.venueId + '/image\')';
    } else {
      return 'url(\'./assets/images/blue_image.png\')';
    }
  }

  isContinueButtonDisabled(priceList: CoursePrice): boolean {
    if (this.disableContinueButton) {
      return true;
    }

    return priceList.priceType === 'CREDIT_PACK' && this.numberOfLessons == null || this.numberOfLessons === 0;
  }

  navigateBack(): void {
    if (this.referrer == null || this.referrer.startsWith('/courses_list_single')) {
      this.navigateToCourseSearch();
    } else {
      this.router.navigateByUrl(this.referrer);
    }
  }

  navigateToCourseSearch(): void {
    const runSearch = this.courseResults == null;

    if (this.courseResults != null) {
      this.courseListSingleService.setCourseSearchResults(this.courseResults);
      this.courseListSingleService.runSearch('refresh');
    }
    this.router.navigate(['/courses_list_single'], {queryParams: {runSearch}});
  }

  getUrl() {
    // The purpose of this is to strip out the referrer
    let returnUrl = 'course_single/' + this.courseId + '?';

    returnUrl = this.appendParameter(returnUrl, this.selectedStudentId, 'selectedStudentId');
    returnUrl = this.appendParameter(returnUrl, this.studentId, 'studentId');
    returnUrl = this.appendParameter(returnUrl, this.programLevelId, 'programLevelId');
    returnUrl = this.appendParameter(returnUrl, this.programId, 'programId');
    returnUrl = this.appendParameter(returnUrl, this.listPosition, 'listPosition');

    return returnUrl;
  }

  appendParameter(url: string, parameter: any, parameterName: string): string {
    if (parameter != null) {
      url = url += '&' + parameterName + '=' + parameter;
    }
    return url;
  }

  initMap(): void {
    this.geocodingService.loader.load().then(() => {
      this.geocodingService.init();
      this.generateMap();
    }).catch(err => {
      console.error('Failed to load google maps: ' + err);
      this.alertService.error('Failed to load google maps: ' + err);
    });
  }

  generateMap(): void {
    if (this.mapInit || this.address == null || this.venue == null) {
      return;
    }

    this.mapInit = true;

    const london = new google.maps.LatLng(51.509865, -0.118092);

    this.infowindow = new google.maps.InfoWindow();

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: london,
      zoom: 15,
    });

    this.service = new google.maps.places.PlacesService(this.map);

    if (this.venue.placeId != null) {
      this.service.getDetails({ placeId: this.venue.placeId }, place => {
        if (place != null) {
          if (!place.geometry || !place.geometry.location) {
            console.log('Failed to find place from place id [' + this.venue.placeId + ']');
          } else {
            this.createMarker(place.geometry.location);
            this.map.setCenter(place.geometry.location);
          }
        } else if (this.venue.longitude != null && this.venue.latitude != null) {
          // Could not obtain from place, try lat lng
          this.loadLocationFromLatLng();
        } else {
          console.log('Failed to load map, place id is null');
        }
      });
    } else if (this.venue.longitude != null && this.venue.latitude != null) {
      this.loadLocationFromLatLng();
    } else {
      console.log('Failed to load map, parameters not set');
    }
  }

  loadLocationFromLatLng(): void {
    const location = new google.maps.LatLng(this.venue.latitude, this.venue.longitude);
    this.createMarker(location);
    this.map.setCenter(location);
  }

  createMarker(location: google.maps.LatLng): void {
    const marker = new google.maps.Marker({
      map: this.map,
      position: location,
    });

    this.infowindow.setContent(this.getAddress());
    this.infowindow.open(this.map, marker);
  }

  getAddress(): string {
    let addressLines = this.venue.venueName;

    if (this.address.addressLine1 != null && this.address.addressLine1 !== '') {
      addressLines += '<br>' + this.address.addressLine1;
    }

    if (this.address.addressLine2 != null && this.address.addressLine2 !== '') {
      addressLines += '<br>' + this.address.addressLine2;
    }

    if (this.address.addressLine3 != null && this.address.addressLine3 !== '') {
      addressLines += '<br>' + this.address.addressLine3;
    }

    if (this.address.locality != null && this.address.locality !== '') {
      addressLines += '<br>' + this.address.locality;

      if (this.address.postCode != null && this.address.postCode !== '') {
        addressLines += ', ' + this.address.postCode;
      }
    } else if (this.address.postCode != null && this.address.postCode !== '') {
      addressLines += '<br>' + this.address.postCode;
    }

    return addressLines;
  }
}
