import {MembershipType} from './membership-type';
import {Student} from './student';
import {convertObjectToDate} from '../_helpers/date_helper';

export class Membership {
  membershipType: MembershipType;
  sportsStudent: Student;
  code: string;
  status: string;
  membershipId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.sportsStudent != null) {
        this.sportsStudent.dateOfBirth = convertObjectToDate(this.sportsStudent.dateOfBirth);
        if (this.sportsStudent.levels != null) {
          this.sportsStudent.levels.forEach(level => {
            level.verifiedDate = convertObjectToDate(level.verifiedDate);
          });
        }
      }
    }
  }
}
