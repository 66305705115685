<app-header></app-header>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
<app-booking-header></app-booking-header>

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container multi-step-form">
        <div id="panel_find_location" class="panel panel-find-location">
          <div class="subscription-description subscription-request-error" *ngIf="requestError">
            There was an error updating your subscription preferences. Please check the link and try again.
          </div>
          <div class="subscription-description" *ngIf="isSubscribe && !isLoading">
            You have been re-subscribed to enrolment notifications.
          </div>
          <div class="subscription-description" *ngIf="!isSubscribe && !isLoading">
            You have been unsubscribed to enrolment notifications.
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
