<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_account_settings']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              edit personal details					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_account_settings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-checkout">
      <div class="container" *ngIf="person != null">
        <form method="post" id="booking_change_password" novalidate="novalidate" class="form-dashboard form-account" [formGroup]="passwordForm" (ngSubmit)="onPasswordFormSubmit()">

          <div class="card">
            <div class="row">
              <h3 class="span-heading title-label">
                Update Password
              </h3>

              <div class="span-input span-text required">
                <input
                  [type]="currentPasswordFieldTextType ? 'text' : 'password'"
                  id="currentPassword"
                  name="currentPassword"
                  class="input-text"
                  formControlName="currentPasswordInput"
                  appParsleyValidation
                  [submitted]="passwordFormSubmitted"
                  [elementName]="'Current Password'"
                />
                <label
                  for="currentPassword"
                  class="floating-label">
                  current password
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>

                <button *ngIf="!currentPasswordFieldTextType" type="button" id="showCurrentPassword" class="show-password" (click)="currentPasswordFieldTextType=true">Show</button>
                <button *ngIf="currentPasswordFieldTextType" type="button" id="hideCurrentPassword" class="show-password" (click)="currentPasswordFieldTextType=false">Hide</button>
              </div>
              <div class="span-input span-text required">
                <input
                  pwnedPasswordValidator
                  pwnedPasswordMinimumOccurrenceForError="1"
                  pwnedPasswordApi="https://api.pwnedpasswords.com/range/"
                  pwnedPasswordApiCallDebounceTime="400"
                  [type]="updatedPasswordFieldTextType ? 'text' : 'password'"
                  id="updatedPassword"
                  name="updatedPassword"
                  class="input-text"
                  minlength="6"
                  #passwordInput
                  (keyup)=passwordEntered(passwordInput.value)
                  formControlName="updatedPasswordInput"
                  appParsleyValidation
                  [submitted]="passwordFormSubmitted"
                  [elementName]="'Updated Password'"
                />
                <label
                  for="updatedPassword"
                  class="floating-label">
                  new password
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <button *ngIf="!updatedPasswordFieldTextType" type="button" id="showNewPassword" class="show-password" (click)="updatedPasswordFieldTextType=true">Show</button>
                <button *ngIf="updatedPasswordFieldTextType" type="button" id="hideNewPassword" class="show-password" (click)="updatedPasswordFieldTextType=false">Hide</button>

                <meter max="4" id="password-strength-meter" value="{{passwordStrength}}"></meter>

                <ul class="parsley-errors-list filled"></ul>
                <ul *ngIf="passwordForm.get('updatedPasswordInput').errors != null && passwordForm.get('updatedPasswordInput').errors.pwnedPasswordOccurrence" class="parsley-errors-list filled">
                  This password has previously appeared in a data breach and should never be used.
                </ul>
              </div>
              <div class="span-input span-text required">
                <input
                  type="password"
                  id="updatedPasswordConfirmation"
                  name="updatedPasswordConfirmation"
                  class="input-text"
                  formControlName="updatedPasswordConfirmationInput"
                  appParsleyValidation
                  [submitted]="passwordFormSubmitted"
                  [elementName]="'Password Confirmation'"
                />
                <label
                  for="updatedPasswordConfirmation"
                  class="floating-label">
                  confirm new password
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>					</div>

            <button type="submit" class="btn btn-block btn-red" [ngClass]="this.passwordForm.invalid || runningUpdate ? 'disabled' : ''" >save new password</button>				</div>

        </form>

        <form method="post" id="booking_edit-details" novalidate="novalidate" class="form-dashboard form-account" [formGroup]="editPersonForm" (ngSubmit)="onPersonFormSubmit()">

          <div class="card">
            <div class="row row-border">
              <h3 class="span-heading title-label">
                Contact Details
              </h3>

              <div class="span-input span-select  required">
                <select
                  id="title"
                  name="title"
                  type="select"
                  class="input-select"
                  formControlName="titleSelect"
                  appParsleyValidation
                  [submitted]="personFormSubmitted"
                  [elementName]="'Title'"
                >
                  <option value="" disabled selected hidden>title</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Lady">Lady</option>
                  <option value="Lord">Lord</option>
                  <option value="Master">Master</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Rev.">Rev.</option>
                  <option value="Sir">Sir</option>
                </select>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div><div class="span-input span-text required">
              <input
                type="text"
                id="givenName"
                name="givenName"
                class="input-text"
                formControlName="givenNameInput"
                appParsleyValidation
                [submitted]="personFormSubmitted"
                [elementName]="'First Name'"
              />
              <label for="givenName" class="floating-label">
                first name
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div>

              <div class="span-input span-text required">
                <input
                  type="text"
                  id="familyName"
                  name="familyName"
                  class="input-text"
                  formControlName="familyNameInput"
                  appParsleyValidation
                  [submitted]="personFormSubmitted"
                  [elementName]="'Surname'"
                />
                <label for="familyName" class="floating-label">
                  surname
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div><div class="span-input span-text required">
              <input
                type="email"
                id="email"
                name="email"
                class="input-text"
                formControlName="emailInput"
                appParsleyValidation
                [submitted]="personFormSubmitted"
                [elementName]="'Email'"
                (input)="editPersonForm.controls.emailInput.setValue(editPersonForm.controls.emailInput.value.toLowerCase())"
              />
              <label for="email" class="floating-label">
                email
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div><div class="span-input span-text required">
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                class="input-text"
                formControlName="telephoneHomeInput"
                (focusout)="formatTelephone()"
                appParsleyValidation
                [submitted]="personFormSubmitted"
                [elementName]="'Telephone'"
              />
              <label
                for="phoneNumber"
                class="floating-label">
                phone
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div><div class="span-input span-text required">
              <input
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                class="input-text"
                formControlName="telephoneMobileInput"
                (focusout)="formatMobile()"
                appParsleyValidation
                [submitted]="personFormSubmitted"
                [elementName]="'Mobile'"
              />
              <label
                for="mobileNumber"
                class="floating-label">
                mobile
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div>					</div>


            <div class="row row-border" *ngIf="address != null">
              <h3 class="span-heading title-label">
                Billing Address
              </h3>

              <app-address
                [submitted]="personFormSubmitted"
                [addressForm]="editPersonForm"
                [addressLinesActive]="true"
                [address]="address"></app-address>

  					</div>

            <button type="submit" class="btn btn-block btn-red" [ngClass]="this.editPersonForm.invalid || runningUpdate ? 'disabled' : ''">save changes</button>				</div>

        </form>
      </div>
    </section>


  </main>
</div>

<app-footer></app-footer>
