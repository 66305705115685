<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>



      <div class="span-info">
        <h2 class="title">{{title}}</h2>
        <div class="info">

        </div>
      </div>


      <div id="span-courses-sort" class="span-courses-sort sort-by" [style.visibility]="(!showCourseSort) ? 'hidden' : 'visible' ">

        <label for="courses_sort" class="label" >
          sort by
        </label>

        <div class="span-input span-select">
          <select
            id="courses_sort"
            name="courses_sort"
            type="select"
            class="input-select btn btn-blue"
          >
            <option value="0">Nearest</option>
            <option value="1">Cheapest</option>
            <option value="2">Top rated</option>
          </select>
        </div>

        <div class="span-input span-filter">
          <a href="#wgt_filter" data-toggle="collapsable" class="btn btn-blue active">
            filter
          </a>
        </div>

    </div>
  </div>
  </div>
</div>
