export class ConfirmationBlock {
  sequence: number;
  label: string;
  url: string;
  content: string;
  icon: string;
  action: string;
  class: string; // for local use

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
