import {Component, Input, OnInit} from '@angular/core';
import {CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {SessionService} from '../../session.service';

@Component({
  selector: 'app-course-list-student-filter',
  templateUrl: './course-list-student-filter.component.html',
  styleUrls: ['./course-list-student-filter.component.css']
})
export class CourseListStudentFilterComponent implements OnInit {

  @Input() isSearchDisabled: boolean;
  students: CourseSearchStudent[];
  showStudentFilter = true;

  constructor(private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private sessionService: SessionService) {
    this.students = sessionService.courseSearchParameters.students;
  }

  ngOnInit(): void {
  }

  toggleStudentFilter() {
    this.showStudentFilter = !this.showStudentFilter;
  }

  refreshFilter() {
    this.courseListStudentDetailsFilterService.setRefreshRequested(true);
  }
}
