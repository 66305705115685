import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {AlertService} from '../../alert';
import {WaitingListResult} from '../../_model/waiting-list-result';
import {AuthService} from '../../auth-service';

@Component({
  selector: 'app-dashboard-waiting-list-cancel',
  templateUrl: './dashboard-waiting-list-cancel.component.html',
  styleUrls: ['./dashboard-waiting-list-cancel.component.css']
})
export class DashboardWaitingListCancelComponent implements OnInit, OnDestroy {

  waitingListEntry: WaitingListResult;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  running = false;

  constructor(private route: ActivatedRoute,
              private titleService: TitleGenerator,
              private alertService: AlertService,
              private authService: AuthService,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Cancel Waiting List Entry');

    this.route.params.subscribe(params => {
      if (params.waitingListId == null) {
        this.alertService.error('Waiting list entry not found');
      } else {
        this.apiService.getWaitingList(params.waitingListId, this.ngUnsubscribe).subscribe(waitingListEntry => {
          this.waitingListEntry = waitingListEntry;
        });
      }
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  removeWaitingList(event: any) {
    event.target.disabled = true;
    this.running = true;
    this.apiService.removeWaitingList(this.waitingListEntry.waitingListEntry.id).subscribe(() => {
      this.router.navigate(['/dashboard_waiting_list']);
    }, () => {
      event.target.disabled = false;
      this.running = false;
    });
  }
}
