import {WaitingListRequestSchedule} from './waiting-list-request-schedule';

export class WaitingListRequest {
  studentId: string;
  programId: string;
  existingEnrolmentId: string;
  schedules: WaitingListRequestSchedule[];
  classDurationInMinutes: number|string; // selects store them as strings
  numberOfStudentsPerInstructor: number|string;
  levelId: string;
  dateRequired: Date;
  notes: string;
  instructorGender: string;
  waitingListId: string;
}
