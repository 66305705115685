export class Venue {

  venueCustomerServiceTelephone: string;
  venueTelephone: string;
  latitude: number;
  venueEmail: string;
  venueLocality: string;
  venueUrl: string;
  longitude: number;
  code: string;
  includeOnWebsite: boolean;
  venueName: string;
  id: string;
  placeId: string;
  autoEnrolmentEnabled: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
