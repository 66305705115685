<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="5"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container" *ngIf="!addStudent">


          <div class="wrap">
            <main class="main"><div class="lead">
              <h1 class="title">Your Students</h1>

              <div class="row row-lead">
                <div class="span-subtitle">
                  <p>You can find a list of students who are currently with us here.</p>
                </div>
                <div class="span-button">
                  <a [routerLink]="[]" queryParamsHandling="preserve" (click)="showAddStudent()" class="btn btn-block btn-blue">add a new student</a>
                </div>
              </div>
            </div>

              <span *ngIf="studentsLoaded === false">
              <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
              </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
                </div>
                </span>

              <div class="wgt wgt-student" *ngFor="let student of students; let i = index">
                <div class="card">

                  <app-dashboard-student-progression [student]="student"></app-dashboard-student-progression>

                </div>
              </div>
            </main>
          </div>
        </div>
        <app-dashboard-add-student *ngIf="addStudent"></app-dashboard-add-student>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
