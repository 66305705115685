import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.css']
})
export class CheckoutHeaderComponent implements OnInit {

  referrer: string;

  constructor(private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });
  }

  goBack(): void {
    if (this.referrer == null) {
      this.referrer = '/courses_list_single';
    }
    this.router.navigateByUrl(this.referrer + '?runSearch=true');
  }
}
