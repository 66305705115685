export class Program {
  id: string;
  code: string;
  programName: string;
  hasLevelFinder: boolean;
  maximumAgeInMonths: number;
  minimumAgeInMonths: number;

  constructor(data?: any) {
    if (data != null) {
    Object.assign(this, data);
    }
  }
}
