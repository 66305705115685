<header>

  <header class="header-wrap" *ngIf="enrolment != null">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back" *ngIf="enrolment != null">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_booking_manage', enrolment.enrolmentId]">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              Manage {{enrolment.student.givenName}}’s Booking Attendance					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_bookings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap" *ngIf="enrolment != null">
  <main class="main"><section class="wgt wgt-find-courses">
    <div class="container">
      <form method="post" id="booking_manage_attendance" novalidate="novalidate" class="form-dashboard" [formGroup]="bookingForm" (ngSubmit)="onBookingSelect()">

        <div class="panel panel-dashboard">

          <div class="span-title">
            <h2 class="entry-title">
              Which session cannot be attended?
            </h2>
          </div>

          <div class="row row-cards">

            <div class="span-card" *ngFor="let booking of bookings">
              <div class="card style-default">
                <div class="span-choice" *ngIf="booking.attendance !== 'NOTATTENDING'">
                  <label
                    for="booking_{{booking.bookingId}}"
                    class="input-check radio">

                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <div class="badge-text">
                          <span id="term_expire_day_3">{{booking.bookedClass.startDate | worldDate: 'd'}}</span>
                        </div>
                      </div>
                    </div>

                    <input
                      id="booking_{{booking.bookingId}}"
                      type="radio"
                      name="notAttendingBooking"
                           class="disabled"
                      [value]="booking.bookingId"
                      formControlName="notAttendingBooking"
                    />
                    <span class="radio-content">
										<ul class="label-description">
                      <li class="title" [ngStyle]="{'text-decoration': (booking.attendance === 'NOTATTENDING') ? 'line-through' : ''}">{{booking.bookedClass.startDate | worldDate:'EEE'}} {{booking.bookedClass.startDate | worldDate:'d'}}<sup>{{booking.bookedClass.startDate | worldDate:'d' | ordinal:false}}</sup> {{booking.bookedClass.startDate | worldDate:'MMM yyyy'}}</li>
										</ul>
									</span>
                  </label>
                </div>
                <div class="span-choice" *ngIf="booking.attendance === 'NOTATTENDING'">
                    <div class="input-check">
                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <div class="badge-text">
                          <span id="term_expire_day_3">{{booking.bookedClass.startDate | worldDate: 'd'}}</span>
                        </div>
                      </div>
                    </div>
                    <span class="radio-content">
										<ul class="label-description">
                      <li class="title" [ngStyle]="{'text-decoration': (booking.attendance === 'NOTATTENDING') ? 'line-through' : ''}">{{booking.bookedClass.startDate | worldDate:'EEE'}} {{booking.bookedClass.startDate | worldDate:'d'}}<sup>{{booking.bookedClass.startDate | worldDate:'d' | ordinal:false}}</sup> {{booking.bookedClass.startDate | worldDate:'MMM yyyy'}}</li>
										</ul>
                      </span>
                    </div>
                </div>
              </div>
            </div>

          </div>

          <button type="submit" class="btn btn-block btn-red" [ngClass]="this.bookingForm.invalid || submitting ? 'disabled' : ''">
            confirm
          </button>

        </div>

      </form>
    </div>
  </section>
  </main>
</div>

<app-footer></app-footer>
