import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CourseSearchParameters} from '../../_model/course-search-parameters';
import {CourseSearchResult} from '../../_model/course-search-result';
import {Basket} from '../../_model/basket';

@Injectable({
  providedIn: 'root'
})
export class CourseListSingleServiceService {

  currentCourseSearchResults: Observable<CourseSearchResult[]>;
  private courseSearchResultsSubject = new BehaviorSubject<CourseSearchResult[]>(null);

  currentRunSearch: Observable<string>;
  private runSearchSubject = new BehaviorSubject<string>(null);

  constructor() {
    this.currentCourseSearchResults = this.courseSearchResultsSubject.asObservable();
    this.currentRunSearch = this.runSearchSubject.asObservable();
  }

  setCourseSearchResults(courseSearchResults: CourseSearchResult[]) {
    this.courseSearchResultsSubject.next(courseSearchResults);
  }

  runSearch(searchType: string) {
    this.runSearchSubject.next(searchType);
  }
}
