export class VenueSearchDistance {
  distance: number;
  isDefault: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
