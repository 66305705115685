export class HowDidYouHearType {
  howDidYouHearTypeId: string;
  name: string;
  description: string;
  showOnline: boolean;
  onlineDescription: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }

  getName(): string {
    if (this.onlineDescription == null || this.onlineDescription === '') {
      return this.name;
    }

    return this.onlineDescription;
  }
}
