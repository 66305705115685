export class WaitingListRequestSchedule {
  venueId: string;
  venueName: string;
  termId: string;
  termName: string;
  fromTime: Date;
  toTime: Date;
  dayNumber: number;
  dayName: string;
}
