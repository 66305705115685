import {StudentLevel} from './student-level';
import {convertObjectToDate} from '../_helpers/date_helper';
import {StudentAchievement} from './student-achievement';
import {ModuleAchievement} from './module-achievement';

export class Student {
  title: string;
  givenName: string;
  familyName: string;
  age: number;
  dateOfBirth: Date;
  id: string;
  programLevelId: string; // Here as a holder for a separate query
  levels: StudentLevel[];
  progression: StudentAchievement[]; // Here as a holder for a separate query
  futureProgression: ModuleAchievement[]; // Here as a holder for a separate query
  gender: string;
  medicalHistoryNotes: string;
  studentEntityId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.dateOfBirth = convertObjectToDate(this.dateOfBirth);
      if (this.levels != null) {
        this.levels.forEach(level => {
          level.verifiedDate = convertObjectToDate(level.verifiedDate);
        });
      }
    }
  }
}

