import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SelectProgramLevelService} from './select-program-level.service';
import {Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {InteractiveSkillCheckService} from '../interactive-skill-check/interactive-skill-check.service';
import {ProgramLevel} from '../../_model/program-level';
import {Subject, Subscription} from 'rxjs';
import {ApplicationSettingsService} from '../../application-settings.service';
import {environment} from '../../../environments/environment';
import {Program} from '../../_model/program';

@Component({
  selector: 'app-select-program-level',
  templateUrl: './select-program-level.component.html',
  styleUrls: ['./select-program-level.component.css']
})
export class SelectProgramLevelComponent implements OnInit, OnDestroy {

  @Input() selectProgramLevelForm: UntypedFormGroup;
  @Input() title: string;
  @Input() program: Program;
  @Input() showLevelCheckerLink: boolean;
  programLevels: ProgramLevel[];
  showLevelChecker = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  titleSubscription: Subscription;
  programLevelSubscription: Subscription;

  constructor(private selectProgramLevelService: SelectProgramLevelService,
              private interactiveSkillCheckService: InteractiveSkillCheckService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private apiService: ApiService,
              private applicationService: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleSubscription = this.selectProgramLevelService.currentTitle.subscribe(message => this.title = message);
    this.programLevelSubscription = this.interactiveSkillCheckService.currentProgramLevel
      .subscribe(programLevel => {
        if (programLevel != null) {
          console.log('got level ' + programLevel);
          this.onProgramLevelSet(programLevel);
        }
      });

    this.apiService.getProgramLevels(this.program.id, this.ngUnsubscribe).subscribe((data) => {
      this.programLevels = data;
    });

    this.selectProgramLevelForm = this.formBuilder.group({
      programLevelSelect: ['']
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.titleSubscription != null) {
      this.titleSubscription.unsubscribe();
    }
    if (this.programLevelSubscription != null) {
      this.programLevelSubscription.unsubscribe();
    }
  }

  onRunLevelChecker() {
    this.showLevelChecker = true;
    this.interactiveSkillCheckService.setProgramId(this.program.id);
  }

  onProgramLevelSet(programLevel: ProgramLevel) {
    if (programLevel == null) {
      return;
    }

    this.onProgramLevelSelected(programLevel.id);
  }

  onProgramLevelSelected(programLevelId: string) {
    this.selectProgramLevelService.setSetProgramLevelId(programLevelId);
  }

  getProgramLevelText(programLevel: ProgramLevel): string {
    if (programLevel.levelDescription != null && programLevel.levelDescription.values != null) {
      for (const value of programLevel.levelDescription.values) {
        if (this.applicationService.localeLanguage === value.resourceLocale.localeName) {
          return value.value;
        }
      }
    }
    return '';
  }

  getLevelImageUrlSrc(levelId: string): string {
    return environment.apiUrl + 'images/program_levels/' + levelId + '/image';
  }
}
