import {Address} from './address';
import {convertObjectToDate} from '../_helpers/date_helper';

export class Mandate {
  sportsAccountPaymentGatewayMandateId: string;
  sortCode: string;
  trailingFourAccountDigits: string;
  isDefault: boolean;
  address: Address;
  mandateId: string;
  mandateStatus: string;
  paymentReference: string;
  status: string;
  mandateStatusUpdatedDate: Date;
  email: string;
  telephone: string;
  bankName: string;
  customerName: string;
  mandateCreationDate: Date;
  mandateUrl: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.mandateStatusUpdatedDate = convertObjectToDate(this.mandateStatusUpdatedDate);
    this.mandateCreationDate = convertObjectToDate(this.mandateCreationDate);
  }
}
