import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CourseSearchParameters} from '../../_model/course-search-parameters';
import {CourseSearchResult} from '../../_model/course-search-result';
import {CoursePriceList} from '../../_model/course-price-list';
import {Basket} from '../../_model/basket';

@Injectable({
  providedIn: 'root'
})
export class CourseSingleService {

  currentCourseSearchResult: Observable<CourseSearchResult>;
  private courseSearchResultSubject = new BehaviorSubject<CourseSearchResult>(null);

  currentCourseSearchResults: Observable<CourseSearchResult[]>;
  private courseSearchResultsSubject = new BehaviorSubject<CourseSearchResult[]>(null);

  currentCoursePriceList: Observable<CoursePriceList>;
  private coursePriceListSubject = new BehaviorSubject<CoursePriceList>(null);

  constructor() {
    this.currentCourseSearchResult = this.courseSearchResultSubject.asObservable();
    this.currentCoursePriceList = this.coursePriceListSubject.asObservable();
    this.currentCourseSearchResults = this.courseSearchResultsSubject.asObservable();
  }

  setCourseSearchResult(courseSearchResult: CourseSearchResult) {
    this.courseSearchResultSubject.next(courseSearchResult);
  }

  setCoursePriceList(coursePriceList: CoursePriceList) {
    this.coursePriceListSubject.next(coursePriceList);
  }

  setCourseSearchResults(courseSearchResults: CourseSearchResult[]) {
    this.courseSearchResultsSubject.next(courseSearchResults);
  }
}
