<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container multi-step-form">


          <div class="span-intro">
            <div class="span-back">
              <button type="button" class="btn btn-back" id="btn-prev" [routerLink]="['/booking_start']">
                <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use
                  xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span> <span class="label">
        	back
        </span>
              </button>
            </div>

            <div class="wgt wgt-wizard-chain">
              <div class="container">

                <ul class="step-list-text">
                  <li class="step step-1">
                    student <span class="current-index">1</span> of {{studentCount}}
                  </li>
                </ul>

                <ul class="step-list-icons">
                  <ng-template #step_container>
                  </ng-template>
                </ul>

              </div>
            </div>
          </div>


          <div class="multi-step-track">
            <div class="slide-track">

              <form [style.display]="!stepOne?'none':'inherit'" method="post" id="form_course_finder_dob" novalidate="novalidate" class="form-course-finder" [formGroup]="selectDobForm" (ngSubmit)="onDobSubmit()">
              <div class="form-section current">


                <div id="panel_find_student_info" class="panel panel-find-student-info">

                  <div class="span-title">
                    <h2 class="entry-title">
                      Firstly, what are the students’ name & age? </h2>
                  </div>

                  <div class="row row-cards-wrapper">
                    <ng-template #student_container>
                    </ng-template>
                  </div>

                  <div class="row">
                    <div class="span-button">
                      <button type="button" class="btn btn-add-student" (click)="addMultipleStudent()">
                        <span class="plus-circle"></span>
                        Add another student
                      </button>
                    </div>
                    <button [ngClass]="this.selectDobForm.invalid ? 'disabled' : ''" type="submit" class="btn btn-block btn-red" data-next="2">
                      continue
                    </button>
                  </div>

                </div>
              </div>
              </form>

              <app-select-program *ngIf="stepTwo" [dateOfBirth]="dateOfBirth"></app-select-program>
              <app-select-program-level *ngIf="stepThree" [program]="program" [showLevelCheckerLink]="programShowLevelChecker"></app-select-program-level>
              <app-select-location *ngIf="stepFour"></app-select-location>

            </div>
          </div>


      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
