<app-header></app-header>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
<app-booking-header></app-booking-header>

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container multi-step-form">
        <div id="panel_find_location" class="panel panel-find-location">
          <div class="subscription-description subscription-request-error" *ngIf="requestError">
            There was an error updating your subscription preferences. Please login to update your preferences.
          </div>
          <div class="subscription-description" *ngIf="subscriptionList.length > 0 && !isLoading">
            You
            <span *ngIf="!requiresOperationalConfirm">have been</span>
            <span *ngIf="requiresOperationalConfirm">will be</span>
            subscribed to {{subscriptionList.join(', ')}}.
          </div>
          <div class="subscription-description" *ngIf="unsubscribeList.length > 0 && !isLoading">
            You
            <span *ngIf="!requiresOperationalConfirm">have been</span>
            <span *ngIf="requiresOperationalConfirm">will be</span>
            unsubscribed from  {{unsubscribeList.join(', ')}}.
          </div>
          <div class="subscription-description" *ngIf="requiresOperationalConfirm">
            You have requested to be unsubscribed from operational communication. Please note that you will no longer receive any operational communication about your lessons, such as lesson cancellations. Please confirm via the button below.
          </div>
          <button type="submit" *ngIf="requiresOperationalConfirm" class="btn btn-block btn-red subscribe-button" (click)="updateSubscriptionPreferences()">
            unsubscribe me
          </button>
        </div>
      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
