import {convertObjectToDate} from '../_helpers/date_helper';
import {SurveyResponse} from './survey-response';

export class BookingSurveyResponse {
  id: string;
  instructorGivenName: string;
  studentGivenName: string;
  day: string;
  time: Date;
  timezoneOffset: string;
  venue: string;
  surveyResponse: SurveyResponse;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.time = convertObjectToDate(this.time);
    if (this.surveyResponse.questions != null) {
      this.surveyResponse.questions.forEach(question => {
        question.validFrom = convertObjectToDate(question.validFrom);
        question.validTo = convertObjectToDate(question.validTo);
      });
    }
  }
}
