
<header class="header-wrap">
  <div class="banner type-dashboard">

    <div class="container">

      <div class="row row-action">

        <div class="span-back">
          <button type="button" class="btn btn-back" [routerLink]="['/dashboard_waiting_list']">
            <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
          </button>
        </div>
        <div class="span-info">
          <h2 class="title">
            make a new waiting list request					</h2>
        </div>

        <div class="span-cancel">
          <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_waiting_list']">
        <span class="label">
        	cancel
        </span>
            <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
        </div>
      </div>

    </div>
  </div>
</header>

<app-alert></app-alert>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <form class="container" *ngIf="waitingListResult != null">

        <form method="post" id="waitingListSchedule" novalidate="novalidate" class="form-dashboard" [formGroup]="schedulesForm" (ngSubmit)="onSchedulesSubmit()">
          <div class="form-section">
            <div class="panel panel-dashboard">

              <div class="span-title">
                <h2 class="entry-title">
                  What’s your availability?
                </h2>
              </div>

              <div class="row row-cards">

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="venue" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-map-marker"><svg class="svg-dashboard-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-map-marker"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">venue</li>
                      </ul>
                    </label>
                    <div class="span-select span-input">
                      <select
                        id="venue"
                        name="venue"
                        class="input-select"
                        #venueSelect
                        (change)="venueChange(venueSelect.value, venueSelect.options[venueSelect.options.selectedIndex].text)"
                        formControlName="venueSelect"
                        appParsleyValidation
                        [submitted]="true"
                        [elementName]="'Venue'"
                      >
                        <option value="" disabled selected hidden>please select</option>
                        <option *ngFor="let venue of venues" value="{{venue.id}}">
                          {{venue.venueName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="term" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-calendar-check"><svg class="svg-dashboard-calendar-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar-check"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">term</li>
                      </ul>
                    </label>
                    <div class="span-select span-input">
                      <select
                        id="term"
                        name="term"
                        type="select"
                        class="input-select"
                        #termSelect
                        (change)="termChange(termSelect.options[termSelect.options.selectedIndex].text)"
                        formControlName="termSelect"
                        appParsleyValidation
                        [submitted]="true"
                        [elementName]="'Term'"
                      >
                        <option value="" disabled selected hidden>please select</option>
                        <option *ngFor="let term of terms" value="{{term.termId}}">
                          {{term.description}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="day" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-calendar"><svg class="svg-dashboard-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">day</li>
                      </ul>
                    </label>
                    <div class="span-select span-input">

                      <select
                        id="day"
                        name="day"
                        type="select"
                        required="required"
                        class="input-select"
                        #daySelect
                        (change)="dayChange(daySelect.options[daySelect.options.selectedIndex].text)"
                        formControlName="daySelect"
                        appParsleyValidation
                        [submitted]="true"
                        [elementName]="'Day'"

                      >
                        <option value="" disabled selected hidden>please select</option>
                        <option value="2">
                          Monday							</option>
                        <option value="3">
                          Tuesday							</option>
                        <option value="4">
                          Wednesday							</option>
                        <option value="5">
                          Thursday							</option>
                        <option value="6">
                          Friday							</option>
                        <option value="7">
                          Saturday							</option>
                        <option value="1">
                          Sunday							</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="span-card span-time">
                  <div class="card style-checked">
                    <label for="time_from" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-clock"><svg class="svg-dashboard-clock"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clock"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">time</li>
                      </ul>
                    </label>

                    <div class="container">
                      <div class="row row-time">
                        <div class="span-select span-input">
                          <select
                            id="time_from"
                            name="time_from"
                            type="select"
                            required="required"
                            class="input-select"
                            formControlName="fromTimeSelect"
                            appParsleyValidation
                            [submitted]="true"
                            [elementName]="'From Time'"
                          >
                            <option value="" disabled selected hidden>please select</option>
                            <option [value]="time" *ngFor="let time of timesFrom">
                              {{time | date: 'h:mma'}}
                            </option>
                          </select>
                        </div>
                        <div class="span-select span-input">
                          <select
                            id="time_to"
                            name="time_to"
                            required="required"
                            class="input-select"
                            formControlName="toTimeSelect"
                            appParsleyValidation
                            [submitted]="true"
                            [elementName]="'To Time'"
                          >
                            <option value="" disabled selected hidden>please select</option>
                            <option [value]="time" *ngFor="let time of timesTo">
                              {{time | date: 'h:mma'}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

            <div class="span-button span-add">
              <button type="submit" [ngClass]="this.schedulesForm.invalid || submitting ? 'disabled' : ''" class="btn btn-add">
                <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>add availibility set	</button>
            </div>


            <div class="waiting-list-table">
              <table class="table-responsive-advanced">
                <thead>
                <tr>
                  <th scope="col">term</th>
                  <th scope="col">day</th>
                  <th scope="col">from</th>
                  <th scope="col">until</th>
                  <th scope="col">venue</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let schedule of waitingListResult.schedules">
                  <td data-label="term">{{schedule.termDescription}}</td>
                  <td data-label="day">{{schedule.dayName}}</td>
                  <td data-label="from">{{schedule.start | worldDate: 'h:mma'}}</td>
                  <td data-label="until">{{schedule.end | worldDate: 'h:mma'}}</td>
                  <td data-label="venue">{{schedule.venueName}}</td>
                  <td><button type="button" class="btn btn-close" (click)="removeWaitingListAvailability(schedule, $event)"><span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span></button></td>
                </tr>
                </tbody>
              </table>
            </div>


            <button type="button" [routerLink]="['/dashboard_waiting_list']" class="btn btn-block btn-red">
              done
            </button>
          </div>
        </form>

      </form>

    </section>
  </main>
</div>

<app-footer></app-footer>
