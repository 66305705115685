import {ResourceKey} from './resource-key';

export class ProgramLevel {
  id: string;
  programLevelName: string;
  sequence: number;
  programName: string;
  programId: string;
  hasLevelImage: boolean;
  levelDescription: ResourceKey;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
