<div class="container">

  <div class="row">
    <div class="span-user">
      <div class="username">
        <span id="user_full_name">{{student.givenName}} {{student.familyName}}</span>
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="showEditStudent(student)"><span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-pencil"></use></svg></span></a>
      </div>
      <ul class="user-details list-inline">
        <li id="student_skill_1" *ngFor="let level of levels">
          <span *ngIf="level.level != null">
          {{level.level.programLevelName}} (Level {{level.level.sequence}})
            </span>
          <span class="level-verified-tick-wrapper">
                            <svg *ngIf="level.verifiedDate != null" class="sprite-tick level-verified-tick">
                              <title>Your level has been verified by an instructor</title>
                              <use xlink:href="./assets/sprite/spritemap.svg#sprite-tick"></use>
                            </svg>
                            </span>
        </li>
        <span *ngIf="!levelsHidden">
        <li id="student_skill_2" *ngFor="let level of hiddenLevels">
          <span *ngIf="level.level != null">
          {{level.level.programLevelName}} (Level {{level.level.sequence}})
            </span>
          <span class="level-verified-tick-wrapper">
                            <svg *ngIf="level.verifiedDate != null" class="sprite-tick level-verified-tick">
                              <title>Your level has been verified by an instructor</title>
                              <use xlink:href="./assets/sprite/spritemap.svg#sprite-tick"></use>
                            </svg>
                            </span>
        </li>
        </span>
        <li *ngIf="levelsHidden && hiddenLevels.length > 0">
          <button type="button" id="btn_show_hidden_levels" (click)="showHiddenLevels()">
            <span class="view-more-circle"><svg class="svg-view-more"><use xlink:href="./assets/sprite/spritemap.svg#sprite-view-more"></use></svg></span>					</button>
        </li>
        <li> Gender: </li>
        <li id="student_gender_1">{{getStudentGenderName(student.gender)}}</li>
        <li> Date of Birth: </li>
        <li id="student_dob_1">{{student.dateOfBirth | worldDate: 'd MMM yyyy'}}</li>
      </ul>
    </div>

    <div class="span-swiper-buttons" *ngIf="student.progression != null && student.futureProgression != null && (student.futureProgression.length > 0 || student.progression.length > 0)">
      <div class="swiper-button swiper-button-next swiper-button-next-{{student.id}}"></div>
      <div class="swiper-button swiper-button-prev swiper-button-prev-{{student.id}}"></div>
    </div>

  </div>


  <div class="swiper" *ngIf="student.progression != null && student.futureProgression != null && (student.futureProgression.length > 0 || student.progression.length > 0)">

    <div class="swiper-container swiper-container-{{student.id}}">
      <div class="swiper-wrapper">

        <div class="swiper-slide yellow" *ngFor="let progression of student.progression; let i = index">
          <div class="swiper-content">
            <ul>
              <li class="icon"><span class="svg-icon dashboard-award"><svg class="svg-dashboard-award"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-award"></use></svg></span></li>
              <li *ngIf="progression.type === 'AwardAchievement'" id="award_title_{{i}}" class="title">{{getAwardAchievement(progression).awardName}}</li>
              <li *ngIf="progression.type === 'ModuleAchievement'" id="module_title_{{i}}" class="title">{{getModuleAchievement(progression).sequence}}. {{getModuleAchievement(progression).moduleName}}</li>
              <li *ngIf="progression.type === 'LevelAchievement'" id="level_title_{{i}}" class="title">You are now a {{getLevelAchievement(progression).levelName}}!</li>
              <li *ngIf="progression.type === 'ChallengeAchievement'" id="challenge_title_{{i}}" class="title">{{getChallengeAchievement(progression).challengeName}} in {{msToTime(getChallengeAchievement(progression).achievementTime)}}</li>
              <li id="badge_aquired_date_{{i}}" class="white">{{progression.dateAchieved | worldDate: 'dd MMM yyyy'}}</li>
            </ul>
          </div>
          <div class="swiper-excerpt">
            <p *ngIf="progression.type === 'AwardAchievement'">{{getAwardAchievement(progression).description}}</p>
            <p *ngIf="progression.type === 'ModuleAchievement'">{{getModuleAchievement(progression).moduleDescription}}</p>
            <p *ngIf="progression.type === 'LevelAchievement'">Congratulations on becoming a {{getLevelAchievement(progression).levelName}}</p>
            <p *ngIf="progression.type === 'ChallengeAchievement'">Great work on this challenge!</p>
          </div>
        </div>
        <div class="swiper-slide blue" *ngFor="let progression of student.futureProgression; let i = index">
          <div class="swiper-content">
            <ul>
              <li class="icon"><span class="svg-icon child"><svg class="svg-child"><use xlink:href="./assets/sprite/spritemap.svg#sprite-child"></use></svg></span></li>
              <li id="badge_aquired_title_3" class="title"><span class="module-achievement-text">{{getModuleAchievement(progression).sequence}}. {{getModuleAchievement(progression).moduleName}}</span></li>
              <li id="badge_aquired_date_3" class="white">Working Towards</li>
            </ul>
          </div>
          <div class="swiper-excerpt">
            <p>{{getModuleAchievement(progression).moduleDescription}}</p>
          </div>
        </div>



      </div>
    </div>
  </div>

</div>
