import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseSearchStudent} from '../../_model/course-search-parameters';
import {Program} from '../../_model/program';
import {ProgramLevel} from '../../_model/program-level';
import {ApiService} from '../../api.service';
import {CourseListStudentDetailsFilterService} from './course-list-student-details-filter-service.service';
import {Student} from '../../_model/student';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import moment from 'moment';
import {isValidDate} from '../../_helpers/date_helper';

@Component({
  selector: 'app-course-list-student-details-filter',
  templateUrl: './course-list-student-details-filter.component.html',
  styleUrls: ['./course-list-student-details-filter.component.css']
})
export class CourseListStudentDetailsFilterComponent implements OnInit, OnDestroy {

  @Input() student: CourseSearchStudent;
  @Input() isSearchDisabled: boolean;

  studentDetailsFilterForm: UntypedFormGroup;

  programs: Program[];
  programLevels: ProgramLevel[];
  hasVerifiedLevelForProgram = false;
  existingStudent: Student;

  previousProgramId: string;
  previousProgramLevelId: string;

  @Input() showStudent = true;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
              private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.previousProgramLevelId = this.student.programLevelId;
    this.previousProgramId = this.student.programId;

    this.studentDetailsFilterForm = this.formBuilder.group({
      studentDobInput: [''],
      studentProgramInput: [''],
      studentLevelInput: ['']
    });

    this.studentDetailsFilterForm.patchValue({
      studentDobInput: moment(this.student.dateOfBirth).format('YYYY-MM-DD'),
      studentProgramInput: this.student.programId,
      studentLevelInput: this.student.programLevelId
    });

    if (this.student.studentId != null) {
      this.apiService.getStudent(this.student.studentId, this.ngUnsubscribe).subscribe(student => {
        this.existingStudent = student;
        this.loadPrograms(true);
      });
    } else {
      this.loadPrograms(true);
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  loadPrograms(isLoad: boolean) {
    this.apiService.getPrograms(this.student.dateOfBirth, this.student.studentId, this.ngUnsubscribe).subscribe((data) => {
      this.programs = data;
      let containsLevel = false;
      for (const program of data) {
        if (program.id === this.previousProgramId) {
          containsLevel = true;
          break;
        }
      }
      if (containsLevel) {
        this.studentDetailsFilterForm.patchValue({
          studentProgramInput: this.previousProgramId
        });
      } else {
        this.studentDetailsFilterForm.patchValue({
          studentProgramInput: null
        });
      }
      this.changeDetectorRef.detectChanges();
      if (this.student.programId != null) {
        this.checkDisableLevel();
      }
      this.onProgramChange(this.studentDetailsFilterForm.get('studentProgramInput').value, isLoad);
    });
  }

  loadProgramLevels(programId: string, isLoad: boolean) {
    this.apiService.getProgramLevels(programId, this.ngUnsubscribe).subscribe((data) => {
      this.programLevels = data;

      let containsLevel = false;
      for (const programLevel of data) {
        if (programLevel.id === this.previousProgramLevelId) {
          containsLevel = true;
          break;
        }
      }
      if (containsLevel) {
        this.studentDetailsFilterForm.patchValue({
          studentLevelInput: this.previousProgramLevelId
        });
      } else {
        this.studentDetailsFilterForm.patchValue({
          studentLevelInput: null
        });
      }

      this.changeDetectorRef.detectChanges();
      this.onProgramLevelChange(this.studentDetailsFilterForm.get('studentLevelInput').value, isLoad);
    });
  }

  onDateOfBirthChange(dateOfBirth, isLoad) {
    this.courseListStudentDetailsFilterService.setHasValidUpdate(false);

    if (this.student.programId != null) {
      this.previousProgramId = this.student.programId;
    }
    if (this.student.programLevelId != null) {
      this.previousProgramLevelId = this.student.programLevelId;
    }

    this.programs = [];
    this.programLevels = [];

    this.changeDetectorRef.detectChanges();

    this.student.dateOfBirth = null;
    this.student.programId = null;
    this.student.programLevelId = null;

    this.studentDetailsFilterForm.patchValue( {
      studentProgramInput: null,
      studentLevelInput: null
    });

    if (dateOfBirth !== '' && isValidDate(new Date(dateOfBirth))) {
      this.student.dateOfBirth = new Date(dateOfBirth);
      this.loadPrograms(isLoad);
    }
  }

  onProgramChange(programId, isLoad: boolean) {
    if (!isLoad) {
      // Clear the display
      this.courseListStudentDetailsFilterService.setHasValidUpdate(false);
    }

    this.programLevels = [];

    this.changeDetectorRef.detectChanges();

    if (programId === '') {
      programId = null;
    }

    this.studentDetailsFilterForm.patchValue( {
      studentLevelInput: null
    });

    this.student.programId = programId;
    if (programId != null) {
      this.previousProgramId = this.student.programId;

      this.loadProgramLevels(programId, isLoad);
      this.checkDisableLevel();
    }
  }

  checkDisableLevel() {
    this.hasVerifiedLevelForProgram = false;
    if (this.existingStudent != null && this.student.programId != null) {
      this.existingStudent.levels.forEach(level => {
        if (level.program.id === this.student.programId && level.verifiedDate != null) {
          this.hasVerifiedLevelForProgram = true;
        }
      });
    }
  }

  onProgramLevelChange(programLevelId, isLoad: boolean) {
    if (!isLoad) {
      // Clear the display
      this.courseListStudentDetailsFilterService.setHasValidUpdate(false);
    }

    if (programLevelId === '') {
      programLevelId = null;
    }

    this.student.programLevelId = programLevelId;

    if (programLevelId != null) {
      this.previousProgramLevelId = this.student.programLevelId;
    }

    if (!isLoad) {
      this.giveUpdate();
    }
  }

  giveUpdate() {
    if (this.student.programId != null && this.student.programLevelId != null) {
      this.courseListStudentDetailsFilterService.setHasValidUpdate(true);
    }
  }

  toggleStudent() {
    this.showStudent = !this.showStudent;
  }

  getMinDate(): string {
    return moment('1900-01-01').format('YYYY-MM-DD');
  }

  getMaxDate(): string {
    return moment().format('YYYY-MM-DD');
  }
}
