import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Basket} from '../../../_model/basket';
import {PaymentMethod} from '../../../_model/payment-method';

@Injectable({
  providedIn: 'root'
})
export class CheckoutPaymentMethodCardService {

  currentCardFormUpdated: Observable<boolean>;
  private cardFormUpdatedSubject = new BehaviorSubject<boolean>(null);

  currentContinueEnrolment: Observable<boolean>;
  private continueEnrolmentSubject = new BehaviorSubject<boolean>(null);

  currentHandleStripeAction: Observable<string>;
  private handleStripeActionSubject = new BehaviorSubject<string>(null);

  currentAddCardToAccount: Observable<boolean>;
  private addCardToAccountSubject = new BehaviorSubject<boolean>(null);

  currentPaymentMethod: Observable<PaymentMethod>;
  private paymentMethodSubject = new BehaviorSubject<PaymentMethod>(null);

  currentMandateFormUpdated: Observable<boolean>;
  private mandateFormUpdatedSubject = new BehaviorSubject<boolean>(null);

  currentPaymentMethodMandate: Observable<string>;
  private paymentMethodMandateSubject = new BehaviorSubject<string>(null);

  constructor() {
    this.currentCardFormUpdated = this.cardFormUpdatedSubject.asObservable();
    this.currentContinueEnrolment = this.continueEnrolmentSubject.asObservable();
    this.currentHandleStripeAction = this.handleStripeActionSubject.asObservable();
    this.currentAddCardToAccount = this.addCardToAccountSubject.asObservable();
    this.currentPaymentMethod = this.paymentMethodSubject.asObservable();
    this.currentMandateFormUpdated = this.mandateFormUpdatedSubject.asObservable();
    this.currentPaymentMethodMandate = this.paymentMethodMandateSubject.asObservable();
  }

  setCardFormUpdated() {
    this.cardFormUpdatedSubject.next(true);
  }

  setContinueEnrolment(hasErrors: boolean) {
    this.continueEnrolmentSubject.next(hasErrors);
  }

  setHandleStripeAction(clientSecret: string) {
    this.handleStripeActionSubject.next(clientSecret);
  }

  setAddCardToAccount() {
    this.addCardToAccountSubject.next(true);
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethodSubject.next(paymentMethod);
  }

  setPaymentMethodMandate(mandateId: string) {
    this.paymentMethodMandateSubject.next(mandateId);
  }

  setMandateFormUpdated() {
    this.mandateFormUpdatedSubject.next(true);
  }
}
