<div class="item-single">
  <div class="container">
    <div class="row">

      <div class="span-graphic type-double">
        <div class="graphic-image" *ngIf="provisional.course.instructorHasPhoto" [ngStyle]="{'background-image': getInstructorImageUrl(provisional.course.instructorId)}"></div>
        <div class="badge-wrap blue">
          <div class="badge-text">
            offer expires <span id="term_expire_date_{{provisional.enrolmentId}}">{{provisional.provisionalExpiry | worldDate: 'd MMM'}}</span>
          </div>
        </div>
      </div>

      <div class="span-content">
        <ul class="list-content">
          <li>
            <ul class="list-inline" *ngIf="provisional.autoEnrolmentTransactionDate == null">
              <li>Don’t let </li>
              <li id="student_first_name_{{provisional.enrolmentId}}">{{provisional.student.givenName}}</li>
              <li> miss Out!</li>
            </ul>
            <ul class="list-inline" *ngIf="provisional.autoEnrolmentTransactionDate != null">
              <li>Next payment for </li>
              <li id="student_first_name_{{provisional.enrolmentId}}">{{provisional.student.givenName}}</li>
            </ul>
          </li>
          <li>
            <ul>
              <li id="term_date_{{provisional.enrolmentId}}">{{provisional.course.termDescription}}</li>
              <li id="student_full_name_{{provisional.enrolmentId}}">{{provisional.course.instructorGivenName}}</li>
            </ul>
          </li>
          <li>
            <ul class="list-inline provisional-row">
              <li id="term_days_{{provisional.enrolmentId}}">{{provisional.course.fullDayOfWeekNames}}</li>
              <li> between </li>
              <li id="term_time_{{provisional.enrolmentId}}">{{provisional.course.startTimeDate | worldDate: 'h:mma'}} & {{provisional.course.endTimeDate | worldDate: 'h:mma'}}</li>
              <li> at </li>
              <li id="term_location_{{provisional.enrolmentId}}">{{provisional.course.venueName}}</li>
            </ul>
          </li>
          <li *ngIf="provisional.autoEnrolmentTransactionDate != null">
            <ul class="list-inline provisional-row auto-payment-row">
              <li>Payment will be automatically taken on </li>
              <li id="payment_taken_{{provisional.enrolmentId}}">{{provisional.autoEnrolmentTransactionDate | worldDate: 'd MMM'}}</li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="span-buttons">
        <a *ngIf="provisional.autoEnrolmentTransactionDate == null" [routerLink]="[]" queryParamsHandling="preserve" (click)="confirmBooking(provisional)" class="btn btn-block btn-yellow">yes, confirm my booking</a>
        <a *ngIf="provisional.autoEnrolmentTransactionDate != null" [routerLink]="[]" queryParamsHandling="preserve" (click)="confirmBooking(provisional)" class="btn btn-block btn-yellow">confirm now</a>
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="cancelBooking(provisional)" class="btn btn-block btn-underlined">cancel reservation</a>
      </div>

    </div>
  </div>
</div>
