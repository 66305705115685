<div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleStudentFilter()" class="accordion-header" [ngClass]="showStudentFilter ? 'active' : ''">
      Show results for
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-0-wgt-f1" class="toggler"  *ngIf="showStudentFilter"  [ngClass]="showStudentFilter ? 'active' : ''">
    <div class="row row-results">

      <div class="span-input span-checkbox" *ngFor="let student of students">
        <label
          for="filter_{{student.id}}"
          class="input-check checkbox">
          <input
            id="filter_{{student.id}}"
            name="results_for"
            type="checkbox"
            class="input-checkbox"
            [(ngModel)]="student.checked"
            (change)="refreshFilter()"
            value="{{student.id}}"
            checked="checked"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>{{student.studentName}}</span>
          <i class="checkmark"></i>
        </label>
      </div>

    </div>
  </div>
</div>
