<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container">

        <div class="span-title">
          <div class="entry-content" *ngIf="achievedAwardCertificateDescription != null" style="padding-bottom: 20px">
          {{achievedAwardCertificateDescription.introductionText}}
          </div>
          <img class="imageCertificate" *ngIf="imageUrl != null" src="{{getCertificateSrc()}}">
        </div>
        <button *ngIf="printUrl != null && achievedAwardCertificateDescription != null && achievedAwardCertificateDescription.hasPrintable" type="submit" class="btn btn-block btn-blue" (click)="printCertificate()">
          print
        </button>
      </div>
    </section>		</main>
</div>

<app-footer></app-footer>
