export class VoucherCodeValidationResponse {
  error: string;
  valid: boolean;
  code: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
