import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../booking-header/booking-header-title-service.service';
import {PolicyResult} from '../_model/policy-result';
import {ApiService} from '../api.service';
import {TitleGenerator} from '../title-generator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  termsAndConditions: PolicyResult;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private apiService: ApiService,
              private renderer: Renderer2) {}

  ngOnInit(): void {
    this.titleService.setTitle('Terms and Conditions');

    this.renderer.addClass(document.body, 'terms-and-conditions');
    this.bookingHeaderTitleServiceService.setTitle('terms and conditions');

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'terms-and-conditions');
  }
}
