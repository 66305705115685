import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectProgramLevelService {

  currentTitle: Observable<string>;
  private titleSubject = new BehaviorSubject<string>(null);

  currentProgramLevelId: Observable<string>;
  private currentProgramLevelIdSubject = new BehaviorSubject<string>(null);

  constructor() {
    this.currentTitle = this.titleSubject.asObservable();
    this.currentProgramLevelId = this.currentProgramLevelIdSubject.asObservable();
  }

  setTitle(message: string) {
    this.titleSubject.next(message);
  }

  setSetProgramLevelId(programLevelId: string) {
    this.currentProgramLevelIdSubject.next(programLevelId);
  }

}
