export class LoggedInUser {
  personId: string;
  givenName: string;
  familyName: string;
  isStudent: boolean;
  username: string;
  email: string;
  telephone: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
