import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../auth-service';
import {LoggedInUser} from '../_model/logged-in-user';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {EnrolmentResponse} from '../_model/enrolment-response';
import {AccountSummary} from '../_model/account-summary';
import {environment} from '../../environments/environment';
import {TitleGenerator} from '../title-generator';
import {Subject} from 'rxjs';
import {AbandonedCartResponse} from '../_model/abandoned-cart-response';
import {DashboardService} from './dashboard.service';
import {PendingPayment} from '../_model/pending-payment';
import {ApplicationSettingsService} from '../application-settings.service';
import {DirectDebitResponse} from '../_model/direct-debit-response';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  loggedInUser: LoggedInUser;
  futureEnrolments: EnrolmentResponse[];
  provisionalEnrolments: EnrolmentResponse[];
  accountSummary: AccountSummary;
  abandonedCarts: AbandonedCartResponse[];
  enrolmentsLoaded: boolean;
  pendingPayments: PendingPayment[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private titleService: TitleGenerator,
              private dashboardService: DashboardService,
              private applicationSettings: ApplicationSettingsService,
              private renderer: Renderer2) {
    this.enrolmentsLoaded = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard');

    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.loggedInUser = loggedInUser;
    });

    this.futureEnrolments = [];
    this.provisionalEnrolments = [];
    this.apiService.getEnrolments(new Date(), null, null, null, this.ngUnsubscribe).subscribe(enrolments => {
      enrolments.forEach(enrolment => {
        if (enrolment.nextClass != null && enrolment.enrolmentStatus === 'CONFIRMED') {
          this.futureEnrolments.push(enrolment);
        }
        if (enrolment.enrolmentStatus === 'PROVISIONAL') {
          this.provisionalEnrolments.push(enrolment);
        }
      });
      this.enrolmentsLoaded = true;
    });

    this.apiService.getAccountSummary(null, this.ngUnsubscribe).subscribe(accountSummary => {
      this.accountSummary = accountSummary;
    });

    this.loadAbandonedCarts();

    this.dashboardService.currentReloadAbandonedCarts.subscribe(value => {
      if (value != null) {
        this.loadAbandonedCarts();
      }
    });
    this.renderer.addClass(document.body, 'dashboard');

    this.apiService.getAccounts(null, this.ngUnsubscribe).subscribe(accounts => {
      if (this.applicationSettings.autoGeneratePendingPayments) {
        accounts.forEach(account => {
          if (account.balance < 0) {
            this.apiService.autoGeneratePendingPayments(account.id, this.ngUnsubscribe).subscribe( created => {
              if (created) {
                this.loadPendingPayments();
              }
            });
          }
        });
      }
    });

    this.loadPendingPayments();
  }

  loadAbandonedCarts(): void {
    const statuses = ['waiting', 'scheduled'];
    this.abandonedCarts = [];
    this.apiService.getAbandonedCartsForLoggedInUser(statuses, this.ngUnsubscribe)
      .subscribe(abandonedCarts => this.abandonedCarts = abandonedCarts);
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  numberOfCreditPacks(): number {
    let count = 0;
    this.accountSummary.studentSummaries.forEach(studentSummary => {
      count = count + studentSummary.numberOfCreditPackCredits;
    });

    return count;
  }

  numberOfClassCredits(): number {
    let count = 0;
    this.accountSummary.studentSummaries.forEach(studentSummary => {
      count = count + studentSummary.numberOfClassCredits + studentSummary.numberOfIntensiveCourseCredits;
    });

    return count;
  }

  getAccountBalancePositive(balance: number): number {
    if (balance < 0) {
      balance = balance * -1;
    }

    return balance;
  }

  navigateToAccountTransactions(): void {
    this.router.navigate(['/dashboard_billing_history']);
  }

  navigateToCredits(): void {
    this.router.navigate(['/dashboard_credits']);
  }

  navigateToWaitingLists(): void {
    this.router.navigate(['/dashboard_waiting_list']);
  }

  loadPendingPayments(): void {
    this.apiService.getPendingPaymentsForLoggedInUser(this.ngUnsubscribe).subscribe(pendingPayments => {
      this.pendingPayments = pendingPayments;
    });
  }

  getPendingPaymentDescription(pendingPayment: PendingPayment) {
    let description = '';
    for (const invoice of pendingPayment.pendingPaymentInvoices) {
      if (description !== '') {
        description += ', ';
      }
      description += invoice.invoice.description;
    }
    return description;
  }

  cancelPendingPayment(pendingPaymentId: string): void {
    const referrer = this.router.url;
    this.router.navigate(['/invoice/' + pendingPaymentId + '/cancel'], {queryParams: {referrer}}).then(() => { });
  }

  editDirectDebitDetails(pendingPayment: PendingPayment): void {
    this.router.navigate(['/dashboard_edit_direct_debit', pendingPayment.sportsAccountId, pendingPayment.recurringBillingType, pendingPayment.recurringBillingPaymentId]);
  }
}
