import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleGenerator} from '../title-generator';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {SubscriptionRequest} from '../_model/subscription-request';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements  OnInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  subscriptionRequest: SubscriptionRequest;

  requestError = false;
  requiresOperationalConfirm = false;
  subscriptionList: string[];
  unsubscribeList: string[];
  personId: string;
  isLoading = true;

  constructor(private titleService: TitleGenerator,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private spinner: NgxSpinnerService) {
    this.subscriptionList = [];
    this.unsubscribeList = [];
  }

  ngOnInit(): void {
    this.titleService.setTitle('Update Subscription Preferences');
    this.spinner.show();

    this.subscriptionRequest = new SubscriptionRequest();
    this.route.params.subscribe(params => {
      this.personId = params.id;
      this.route.queryParams.subscribe(queryParam => {
        const request = queryParam.query;

        if (request != null) {
          const requestSplit = request.split(',');
          requestSplit.forEach(requestItem => {
            const isSubscribed = !requestItem.startsWith('!');
            if (requestItem.startsWith('!')) {
              requestItem = requestItem.substr(1);
            }

            requestItem = requestItem.toLowerCase();

            switch (requestItem) {
              case 'marketing':
                this.subscriptionRequest.marketingPartners = isSubscribed;
                this.subscriptionRequest.marketingEmails = isSubscribed;
                this.subscriptionRequest.marketingPhone = isSubscribed;
                this.subscriptionRequest.marketingSMS = isSubscribed;
                this.subscriptionRequest.abandonedCart = isSubscribed;
                this.setSubscriptionListItem('marketing communication', isSubscribed);
                break;
              case 'operational':
                this.subscriptionRequest.operationsInfoEmail = isSubscribed;
                this.subscriptionRequest.operationsInfoPhone = isSubscribed;
                this.subscriptionRequest.operationsInfoSMS = isSubscribed;
                this.setSubscriptionListItem('operational communication', isSubscribed);
                if (!isSubscribed) {
                  this.requiresOperationalConfirm = true;
                }
                break;
              case 'marketing_emails':
                this.subscriptionRequest.marketingEmails = isSubscribed;
                this.setSubscriptionListItem('marketing emails', isSubscribed);
                break;
              case 'marketing_partners':
                this.subscriptionRequest.marketingPartners = isSubscribed;
                this.setSubscriptionListItem('marketing partners', isSubscribed);
                break;
              case 'marketing_phone':
                this.subscriptionRequest.marketingPhone = isSubscribed;
                this.setSubscriptionListItem('marketing via telephone', isSubscribed);
                break;
              case 'marketing_sms':
                this.subscriptionRequest.marketingSMS = isSubscribed;
                this.setSubscriptionListItem('marketing via text message', isSubscribed);
                break;
              case 'operational_emails':
                this.subscriptionRequest.operationsInfoEmail = isSubscribed;
                this.setSubscriptionListItem('operational emails', isSubscribed);
                if (!isSubscribed) {
                  this.requiresOperationalConfirm = true;
                }
                break;
              case 'operational_phone':
                this.subscriptionRequest.operationsInfoPhone = isSubscribed;
                this.setSubscriptionListItem('operational telephone calls', isSubscribed);
                if (!isSubscribed) {
                  this.requiresOperationalConfirm = true;
                }
                break;
              case 'operational_sms':
                this.subscriptionRequest.operationsInfoSMS = isSubscribed;
                this.setSubscriptionListItem('operational text messages', isSubscribed);
                if (!isSubscribed) {
                  this.requiresOperationalConfirm = true;
                }
                break;
              case 'abandoned_cart':
                this.subscriptionRequest.abandonedCart = isSubscribed;
                this.setSubscriptionListItem('shopping basket emails', isSubscribed);
                break;
              case 'daily_financial_snapshot':
                this.subscriptionRequest.dailyFinancialSnapshot = isSubscribed;
                this.setSubscriptionListItem('daily financial snapshot', isSubscribed);
                break;
              case 'weekly_marketing_report':
                this.subscriptionRequest.weeklyMarketingReport = isSubscribed;
                this.setSubscriptionListItem('weekly marketing report', isSubscribed);
                break;
              case 'weekly_master_licensee_performance':
                this.subscriptionRequest.weeklyMasterLicenseePerformance = isSubscribed;
                this.setSubscriptionListItem('weekly master licensee report', isSubscribed);
                break;
              case 'weekly_licensee_performance':
                this.subscriptionRequest.weeklyLicenseePerformance = isSubscribed;
                this.setSubscriptionListItem('weekly licensee report', isSubscribed);
                break;
              case 'weekly_venue_performance':
                this.subscriptionRequest.weeklyVenuePerformance = isSubscribed;
                this.setSubscriptionListItem('weekly venue Performance Report', isSubscribed);
                break;
              case 'weekly_operator_performance':
                this.subscriptionRequest.weeklyOperatorPerformance = isSubscribed;
                this.setSubscriptionListItem('weekly operator report', isSubscribed);
                break;
              case 'weekly_instructor_performance':
                this.subscriptionRequest.weeklyInstructorPerformance = isSubscribed;
                this.setSubscriptionListItem('weekly instructor report', isSubscribed);
                break;
            }
          });
        }
        this.requestError = (this.subscriptionRequest.marketingEmails == null &&
          this.subscriptionRequest.marketingPartners == null &&
          this.subscriptionRequest.marketingPhone == null &&
          this.subscriptionRequest.marketingSMS == null &&
          this.subscriptionRequest.operationsInfoPhone == null &&
          this.subscriptionRequest.operationsInfoEmail == null &&
          this.subscriptionRequest.operationsInfoSMS == null &&
          this.subscriptionRequest.abandonedCart == null &&
          this.subscriptionRequest.dailyFinancialSnapshot == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null &&
          this.subscriptionRequest.weeklyInstructorPerformance == null);

        if (!this.requestError && !this.requiresOperationalConfirm) {
          this.updateSubscriptionPreferences();
        } else {
          this.spinner.hide();
          this.isLoading = false;
        }
      });
    });
  }

  setSubscriptionListItem(name: string, isSubscribed: boolean): void {
    if (isSubscribed) {
      this.subscriptionList.push(name);
    } else {
      this.unsubscribeList.push(name);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  updateSubscriptionPreferences(): void {
    this.requiresOperationalConfirm = false;
    this.spinner.show();
    this.isLoading = true;
    this.apiService.updateSubscriptionPreferences(this.personId, this.subscriptionRequest).subscribe(() => {}, err => {
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
      this.isLoading = false;
    });
  }
}
