import {BasketStudent} from './basket-student';
import {CreateAccountRequest} from './create-account-request';
import {CreateAccountCourse} from './create-account-course';
import {PaymentMethod} from './payment-method';

export class Basket {
  students: BasketStudent[];
  currencyCode: string;

  accountLinked = false;
  isProspect = false;
  accountCreated = false;
  studentsCreated = false;
  abandonedCartCreated = false;
  createAccountRequest: CreateAccountRequest;
  licenseeId: string;
  accountId: string;
  cardPaymentMethod: PaymentMethod;
  acceptedTermsAndConditions = false;
  termsAndConditionsPolicyId: string;
  voucherCode: string;

  constructor(data?: any) {
    this.students = [];
    if (data != null) {
      Object.assign(this, data);

      this.students.forEach(student => {
        if (student.student != null && student.student.dateOfBirth != null && typeof student.student.dateOfBirth === 'string') {
          if (student.student.dateOfBirth !== '') {
            student.student.dateOfBirth = new Date(student.student.dateOfBirth);
          }
        }
      });
    }
  }

  containsBasketItem(courseId: string, studentId: number): boolean {
    if (this.students != null) {
      for (const student of this.students) {
        if (student.student.id === studentId && student.course.id === courseId) {
          return true;
        }
      }
    }

    return false;
  }

  getBasketId(courseId: string, studentId: number): number {
    if (this.students != null) {
      for (const student of this.students) {
        if (student.student.id === studentId && student.course.id === courseId) {
          return student.id;
        }
      }
    }

    return null;
  }

  nextBasketStudentId(): number {
    if (this.students == null || this.students.length === 0) {
      return 1;
    }

    let maxNumber = 0;
    for (const student of this.students) {
      if (student.id > maxNumber) {
        maxNumber = student.id;
      }
    }

    return maxNumber + 1;
  }

  getCreateAccountRequest(): CreateAccountRequest {
    if (this.createAccountRequest == null) {
      return null;
    }

    const courseIds = [];

    this.students.forEach(basketStudent => {
      const accountCourse = new CreateAccountCourse();
      accountCourse.courseId = basketStudent.course.id;
      accountCourse.levelId = basketStudent.student.programLevelId;

      if (!courseIds.includes(accountCourse)) {
        courseIds.push(accountCourse);
      }
    });

    this.createAccountRequest.createAccountCourses = courseIds;

    return this.createAccountRequest;
  }
}
