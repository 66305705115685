import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CourseSearchStudent} from '../../_model/course-search-parameters';

@Injectable({
  providedIn: 'root'
})
export class CourseListStudentDetailsFilterService {

  private studentDetailsFilterChangeSubject = new BehaviorSubject<boolean>(null);
  studentDetailsFilterChangeUpdate: Observable<boolean>;

  private filterChangeSubject = new BehaviorSubject<boolean>(null);
  filterChangeUpdate: Observable<boolean>;

  constructor() {
    this.studentDetailsFilterChangeUpdate = this.studentDetailsFilterChangeSubject.asObservable();
    this.filterChangeUpdate = this.filterChangeSubject.asObservable();
  }

  setHasValidUpdate(hasValidUpdate: boolean) {
    this.studentDetailsFilterChangeSubject.next(hasValidUpdate);
  }

  setRefreshRequested(refreshRequested: boolean) {
    this.filterChangeSubject.next(refreshRequested);
  }
}
