import {UntypedFormControl} from '@angular/forms';

export function HowDidYouHearValidator(howDidYouHearInput: string) {
  let howDidYouHearOtherControl: UntypedFormControl;
  let howDidYouHearControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!howDidYouHearOtherControl) {
      howDidYouHearOtherControl = control;
      howDidYouHearControl = control.parent.get(howDidYouHearInput) as UntypedFormControl;
      howDidYouHearControl.valueChanges.subscribe(() => {
        howDidYouHearOtherControl.updateValueAndValidity();
      });
    }

    if (howDidYouHearControl.value != null &&
      (howDidYouHearControl.value === 'other' &&
        (howDidYouHearOtherControl.value === '' || howDidYouHearOtherControl.value == null))
    ) {
      return {
        required: true
      };
    }
    return null;
  };
}
