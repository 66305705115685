
<header class="header-wrap">
  <div class="banner type-dashboard">

    <div class="container">

      <div class="row row-action">

        <div class="span-back">
          <button type="button" class="btn btn-back" [routerLink]="['/dashboard_waiting_list']">
            <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
          </button>
        </div>
        <div class="span-info">
          <h2 class="title">
            make a new waiting list request					</h2>
        </div>

        <div class="span-cancel">
          <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_waiting_list']">
        <span class="label">
        	cancel
        </span>
            <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
        </div>
      </div>

    </div>
  </div>
</header>

<app-alert></app-alert>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <form class="container">
        <form *ngIf="stepNumber === 1" method="post" id="booking_transfer" novalidate="novalidate" class="form-dashboard" [formGroup]="studentsForm" (ngSubmit)="onStudentsSubmit()">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Who is the new request for?
              </h2>
            </div>

            <div class="row row-cards" *ngIf="accountContacts != null">

              <div class="span-card row-element-centred" *ngFor="let student of accountContacts">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="student_{{student.id}}"
                      class="input-check radio">
                      <img *ngIf="student.programLevelId != null" src="{{getLevelImageUrl(student.programLevelId)}}" alt="{{student.givenName}} {{student.familyName}}" />
                      <span *ngIf="student.programLevelId == null" class="svg-icon student-2"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-2"></use></svg></span>
                      <input
                        id="student_{{student.id}}"
                        type="radio"
                        [value]="student.id"
                        formControlName="waitingListStudent"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{student.givenName}} {{student.familyName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="span-card">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="student_new"
                      class="input-check radio">
                      <span class="svg-icon student-2"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-2"></use></svg></span>									<input
                      id="student_new"
                      type="radio"
                      [value]="'new'"
                      formControlName="waitingListStudent"
                    />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">add a new student</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" [ngClass]="this.studentsForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
              continue
            </button>

          </div>

        </form>

        <form *ngIf="stepNumber === 2" method="post" id="booking_program" novalidate="novalidate" class="form-dashboard" [formGroup]="programsForm" (ngSubmit)="onProgramsSubmit()">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Which program is this for?
              </h2>
            </div>

            <div class="row row-cards" *ngIf="programs != null">

              <div class="span-card row-element-centred" *ngFor="let program of programs">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="program_{{program.id}}"
                      class="input-check radio">
                      <input
                        id="program_{{program.id}}"
                        type="radio"
                        [value]="program.id"
                        formControlName="waitingListProgram"
                        (change)="programSelected(program)"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{program.programName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" [ngClass]="this.programsForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
              continue
            </button>

          </div>

        </form>

        <form *ngIf="stepNumber === 3" method="post" id="booking_program_level" novalidate="novalidate" class="form-dashboard" [formGroup]="programLevelsForm" (ngSubmit)="onProgramLevelsSubmit()">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Which level is this for?
              </h2>
              <div class="entry-content" *ngIf="showLevelCheckerLink">
                <a (click)="onRunLevelChecker()" [routerLink]="[]" queryParamsHandling="preserve" class="btn-check">Not sure? Use our interactive checker</a>
              </div>
            </div>

            <div class="row row-cards" *ngIf="programLevels != null">

              <div class="span-card row-element-centred" *ngFor="let programLevel of programLevels">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="program_{{programLevel.id}}"
                      class="input-check radio">
                      <div class="span-graphic">
                        <div class="graphic-image" [ngStyle]="{'background-image': getLevelImageUrl(programLevel.id)}"></div>
                      </div>
                      <input
                        id="program_{{programLevel.id}}"
                        type="radio"
                        [value]="programLevel.id"
                        formControlName="waitingListProgramLevel"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{programLevel.programLevelName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" [ngClass]="this.programsForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
              continue
            </button>

          </div>

        </form>

        <form *ngIf="stepNumber === 4" method="post" id="enrolmentForm" novalidate="novalidate" class="form-dashboard" [formGroup]="enrolmentForm" (ngSubmit)="transferEnrolmentSubmit()">
          <div class="multi-step-track">
            <div class="slide-track">

              <div class="form-section current">                <div class="panel panel-dashboard">

                  <div class="span-title">
                    <h2 class="entry-title">
                      Do you want to transfer a course?
                    </h2>
                  </div>

                  <div class="row row-cards">

                    <div class="span-card" *ngFor="let enrolment of enrolments">
                      <div class="card style-default">
                        <div class="span-choice">

                          <label
                            for="current_course"
                            class="input-check radio"
                          >
                            <div class="span-graphic">
                              <div class="graphic-image" *ngIf="enrolment.course.instructorHasPhoto" [ngStyle]="{'background-image': getInstructorImageUrl(enrolment.course.instructorId)}"></div>
                            </div>

                            <input
                              id="current_course"
                              type="radio"
                              name="currentCourseInput"
                              value="current_course"
                              [value]="enrolment.enrolmentId"
                              (click)="transferEnrolmentSubmit()"
                              formControlName="currentCourseInput"
                            />
                            <span class="radio-content">
							<ul class="label-description">
								<li class="title">current course</li>
								<li class="details">
									<ul class="list-inline">
										<li id="student_term_1">{{enrolment.course.termDescription}}</li>
										<li> with </li>
										<li id="student_teacher_1">{{enrolment.course.instructorGivenName}}</li>
									</ul>
								</li>
								<li class="details">
									<ul class="list-inline">
										<li id="term_time_1">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
										<li> on </li>
										<li id="term_day_1">{{enrolment.course.fullDayOfWeekNames}}</li>
										<li> at </li>
										<li id="term_venue_1">{{enrolment.course.venueName}}</li>
									</ul>
								</li>
							</ul>
						</span>
                          </label>

                        </div>
                      </div>
                    </div>

                    <div class="span-card">
                      <div class="card style-default">
                        <div class="span-choice">
                          <label
                            for="new_course"
                            class="input-check radio"
                          >
                            <div class="span-graphic">
                              <div class="badge-wrap red plus">

                              </div>
                            </div>

                            <input
                              id="new_course"
                              type="radio"
                              name="currentCourseInput"
                              value="new_course"
                              data-next="2"
                              data-parsley-group="slide-01"
                              [value]="null"
                              (click)="transferEnrolmentSubmit()"
                              formControlName="currentCourseInput"
                            />
                            <span class="radio-content">
							<ul class="label-description">
								<li class="title">make a brand new request</li>
							</ul>
						</span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>

        <form *ngIf="stepNumber === 5" method="post" id="waitingListPreferences" novalidate="novalidate" class="form-dashboard" [formGroup]="preferencesForm" (ngSubmit)="onPreferencesSubmit()">
          <div class="form-section">
            <div class="panel panel-dashboard">

              <div class="span-title">
                <h2 class="entry-title">
                  What are your preferences?
                </h2>
              </div>

              <div class="row row-cards">

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="venue" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-map-marker"><svg class="svg-dashboard-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-map-marker"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">date required</li>
                      </ul>
                    </label>
                    <div class="span-text span-input">
                      <input
                        id="dateRequired"
                        type="date"
                        name="dateRequired"
                        class="input-text wl-date-required"
                        formControlName="dateSelect"
                      />
                    </div>
                  </div>
                </div>

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="term" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-calendar-check"><svg class="svg-dashboard-calendar-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar-check"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">students per instructor</li>
                      </ul>
                    </label>
                    <div class="span-select span-input">
                      <select
                        id="numberOfStudentsPerInstructor"
                        name="numberOfStudentsPerInstructor"
                        class="input-select"
                        formControlName="numberOfStudentsPerInstructorSelect"
                      >
                        <option [value]="null" disabled selected hidden>please select</option>
                        <option [value]="i" *ngFor='let int of counter(20) ;let i = index'>
                          {{i}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="span-card">
                  <div class="card style-checked">
                    <label for="day" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-calendar"><svg class="svg-dashboard-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">class duration</li>
                      </ul>
                    </label>
                    <div class="span-select span-input">

                      <select
                        id="classDuration"
                        name="classDuration"
                        required="required"
                        class="input-select"
                        formControlName="classDurationInMinutesSelect"
                      >
                        <option [value]="null" disabled selected hidden>please select</option>
                        <option [value]="15">15 Minutes</option>
                        <option [value]="30">30 Minutes</option>
                        <option [value]="45">45 Minutes</option>
                        <option [value]="60">60 Minutes</option>
                        <option [value]="75">75 Minutes</option>
                        <option [value]="90">90 Minutes</option>
                        <option [value]="105">105 Minutes</option>
                        <option [value]="120">120 Minutes</option>
                        <option [value]="135">135 Minutes</option>
                        <option [value]="150">150 Minutes</option>
                        <option [value]="165">165 Minutes</option>
                        <option [value]="180">180 Minutes</option>
                        <option [value]="195">195 Minutes</option>
                        <option [value]="210">210 Minutes</option>
                        <option [value]="225">225 Minutes</option>
                        <option [value]="240">240 Minutes</option>
                        <option [value]="255">255 Minutes</option>
                        <option [value]="270">270 Minutes</option>
                        <option [value]="285">285 Minutes</option>
                        <option [value]="300">300 Minutes</option>
                        <option [value]="315">315 Minutes</option>
                        <option [value]="330">330 Minutes</option>
                        <option [value]="345">345 Minutes</option>
                        <option [value]="360">360 Minutes</option>
                        <option [value]="375">375 Minutes</option>
                        <option [value]="390">390 Minutes</option>
                        <option [value]="405">405 Minutes</option>
                        <option [value]="420">420 Minutes</option>
                        <option [value]="435">435 Minutes</option>
                        <option [value]="450">450 Minutes</option>
                        <option [value]="465">465 Minutes</option>
                        <option [value]="480">480 Minutes</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="span-card span-time">
                  <div class="card style-checked">
                    <label for="time_from" class="input-check">
                      <div class="span-graphics">
                        <div class="badge-wrap-small red">
                          <span class="svg-icon dashboard-clock"><svg class="svg-dashboard-clock"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clock"></use></svg></span>						</div>
                      </div>

                      <ul class="label-description">
                        <li class="title">instructor gender</li>
                      </ul>
                    </label>

                    <div class="container">
                      <div class="row row-time">
                        <div class="span-select span-input">
                          <select
                            id="instructorGender"
                            name="instructorGender"
                            required="required"
                            class="input-select"
                            formControlName="instructorGenderSelect"
                          >
                            <option value="" disabled selected hidden>please select</option>
                            <option [value]="null">No preference</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

            <button type="submit" [ngClass]="this.preferencesForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red" data-next>
              continue
            </button>
          </div>
        </form>

        <form *ngIf="stepNumber === 6" method="post" id="waitingListSchedule" novalidate="novalidate" class="form-dashboard" [formGroup]="schedulesForm" (ngSubmit)="onSchedulesSubmit()">
        <div class="form-section">
          <div class="panel panel-dashboard">

            <div class="span-title">
              <h2 class="entry-title">
                What’s your availability?
              </h2>
            </div>

            <div class="row row-cards">

              <div class="span-card">
                <div class="card style-checked">
                  <label for="venue" class="input-check">
                    <div class="span-graphics">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-map-marker"><svg class="svg-dashboard-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-map-marker"></use></svg></span>						</div>
                    </div>

                    <ul class="label-description">
                      <li class="title">venue</li>
                    </ul>
                  </label>
                  <div class="span-select span-input">
                    <select
                      id="venue"
                      name="venue"
                      class="input-select"
                      #venueSelect
                      (change)="venueChange(venueSelect.value, venueSelect.options[venueSelect.options.selectedIndex].text)"
                      formControlName="venueSelect"
                    >
                      <option value="" disabled selected hidden>please select</option>
                      <option *ngFor="let venue of venues" value="{{venue.id}}">
                        {{venue.venueName}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="span-card">
                <div class="card style-checked">
                  <label for="term" class="input-check">
                    <div class="span-graphics">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-calendar-check"><svg class="svg-dashboard-calendar-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar-check"></use></svg></span>						</div>
                    </div>

                    <ul class="label-description">
                      <li class="title">term</li>
                    </ul>
                  </label>
                  <div class="span-select span-input">
                    <select
                      id="term"
                      name="term"
                      type="select"
                      class="input-select"
                      #termSelect
                      (change)="termChange(termSelect.options[termSelect.options.selectedIndex].text)"
                      formControlName="termSelect"
                    >
                      <option value="" disabled selected hidden>please select</option>
                      <option *ngFor="let term of terms" value="{{term.termId}}">
                        {{term.description}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="span-card">
                <div class="card style-checked">
                  <label for="day" class="input-check">
                    <div class="span-graphics">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-calendar"><svg class="svg-dashboard-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar"></use></svg></span>						</div>
                    </div>

                    <ul class="label-description">
                      <li class="title">day</li>
                    </ul>
                  </label>
                  <div class="span-select span-input">

                    <select
                      id="day"
                      name="day"
                      type="select"
                      required="required"
                      class="input-select"
                      #daySelect
                      (change)="dayChange(daySelect.options[daySelect.options.selectedIndex].text)"
                      formControlName="daySelect"
                    >
                      <option value="" disabled selected hidden>please select</option>
                      <option value="2">
                        Monday							</option>
                      <option value="3">
                        Tuesday							</option>
                      <option value="4">
                        Wednesday							</option>
                      <option value="5">
                        Thursday							</option>
                      <option value="6">
                        Friday							</option>
                      <option value="7">
                        Saturday							</option>
                      <option value="1">
                        Sunday							</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="span-card span-time">
                <div class="card style-checked">
                  <label for="time_from" class="input-check">
                    <div class="span-graphics">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-clock"><svg class="svg-dashboard-clock"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clock"></use></svg></span>						</div>
                    </div>

                    <ul class="label-description">
                      <li class="title">time</li>
                    </ul>
                  </label>

                  <div class="container">
                    <div class="row row-time">
                      <div class="span-select span-input">
                        <select
                          id="time_from"
                          name="time_from"
                          type="select"
                          required="required"
                          class="input-select"
                          formControlName="fromTimeSelect"
                        >
                          <option value="" disabled selected hidden>from</option>
                          <option [value]="time" *ngFor="let time of timesFrom">
                            {{time | date: 'h:mma'}}
                          </option>
                        </select>
                      </div>
                      <div class="span-select span-input">
                        <select
                          id="time_to"
                          name="time_to"
                          required="required"
                          class="input-select"
                          formControlName="toTimeSelect"
                        >
                          <option value="" disabled selected hidden>to</option>
                          <option [value]="time" *ngFor="let time of timesTo">
                            {{time | date: 'h:mma'}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <div class="span-button span-add">
            <button type="submit" [ngClass]="this.schedulesForm.invalid ? 'disabled' : ''" class="btn btn-add">
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>add availibility set	</button>
          </div>


          <div class="waiting-list-table">
            <table class="table-responsive-advanced">
              <thead>
              <tr>
                <th scope="col">term</th>
                <th scope="col">day</th>
                <th scope="col">from</th>
                <th scope="col">until</th>
                <th scope="col">venue</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let schedule of waitingListRequest.schedules">
                <td data-label="term">{{schedule.termName}}</td>
                <td data-label="day">{{schedule.dayName}}</td>
                <td data-label="from">{{schedule.fromTime | date: 'h:mma'}}</td>
                <td data-label="until">{{schedule.toTime | date: 'h:mma'}}</td>
                <td data-label="venue">{{schedule.venueName}}</td>
                <td><button type="button" class="btn btn-close" (click)="removeWaitingListAvailability(schedule)"><span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span></button></td>
              </tr>
              </tbody>
            </table>
          </div>


          <button type="button" (click)="submitWaitingList()" [ngClass]="waitingListRequest.schedules.length === 0 || submitting ? 'disabled' : ''" class="btn btn-block btn-red">
            continue
          </button>
        </div>
        </form>

        <app-dashboard-add-student *ngIf="stepNumber === 7"></app-dashboard-add-student>

        <app-interactive-skill-check *ngIf="stepNumber === 8"></app-interactive-skill-check>

      </form>

    </section>
  </main>
</div>

<app-footer></app-footer>
