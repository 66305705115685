<app-header></app-header>

<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info">
        <h2 class="title">
          my basket				</h2>
        <div class="info" *ngIf="students != null && students.length > 0">

          <div class="cart-total">

            <a routerLink="" (click)="navigateToCheckout()" class="btn btn-block btn-red">
              checkout now
            </a>

          </div>					</div>
      </div>


      <div class="span-back">
        <button type="button" class="btn btn-back" (click)="goBack()">
          <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
        </button>
      </div>

    </div>
  </div>
</div>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <div class="wgt wgt-cart">
      <div class="container">

        <div class="wgt-cart-no-bookings" *ngIf="students.length === 0">
          Your basket is empty
        </div>

        <div class="cards" *ngIf="students.length > 0">


          <div class="card-cart" *ngFor="let student of students">

            <div class="row row-heading">

              <div class="span-name">
                {{student.student.studentName}}
              </div>

              <div class="span-price">
                <span *ngIf="student.coursePrice.priceType === 'CREDIT_PACK'">from </span> {{getPrice(student.coursePrice) | currency:currencyCode:'symbol-narrow'}}<br />
                per {{formatPriceTypeShort(student.coursePrice.priceType)}}
              </div>

            </div>

            <div class="row row-info">
              <ul class="span-programme-info">
                <li *ngIf="student.course.programLevel != null">{{student.course.programLevel.programName}}</li>
                <li>{{getNumberOfStudentsText(student) | titlecase}} Students per Class | {{student.course.classDurationInMinutes}} Minutes | With {{student.course.instructorGivenName}}</li>
                <li>{{student.course.termDescription}}</li>
                <li>{{student.course.fullDayOfWeekNames}}s @ {{student.course.startTimeDate | worldDate:'HH:mm'}} | {{student.numberOfWeeksToEnrol}} Lessons | Starting {{student.startDate | worldDate: 'dd.MM.yy'}}</li>
                <li>{{student.course.venueName}}</li>
                <li>{{getAddress(student)}}</li>
              </ul>
              <ul class="span-payment-info">
                <li>{{formatPriceTypeTitle(student.coursePrice.priceType)}}</li>
                <li *ngIf="student.coursePrice.priceType === 'MULTIPLE_TERMS'">Total lessons: {{student.coursePrice.numberOfMultipleTermWeeks}}</li>
                <li *ngIf="((student.coursePrice.priceType !== 'MONTHLY_DIRECT_DEBIT' && student.coursePrice.priceType !== 'MONTHLY_CREDIT_CARD') || ((student.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT' || student.coursePrice.priceType === 'MONTHLY_CREDIT_CARD') && student.student.studentId != null)) && student.coursePrice.priceType !== 'CREDIT_PACK'">Amount due today: {{getTotalDueToday(student) | currency:currencyCode:'symbol-narrow'}}</li>
                <li *ngIf="(student.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT' || student.coursePrice.priceType === 'MONTHLY_CREDIT_CARD') && student.student.studentId != null">Then {{student.coursePrice.price | currency:currencyCode:'symbol-narrow'}} due monthly starting {{student.coursePrice.contractStartDate | worldDate:'dd.MM.yy'}}</li>
                <li><a target="_blank" [routerLink]="['/terms_and_conditions']">Terms & Conditions</a> <span class="pipe">|</span> <a id="remove" [routerLink]="[]" queryParamsHandling="preserve" (click)="removeStudent(student)">Remove</a></li>
              </ul>
            </div>

          </div>

        </div>

        <div class="cart-total">

          <a *ngIf="students == null || students.length === 0" [routerLink]="[]" queryParamsHandling="preserve" (click)="navigateToSearch()" class="btn btn-block btn-red-white">
            search for courses
          </a>

          <a *ngIf="students != null && students.length > 0" [routerLink]="[]" queryParamsHandling="preserve" (click)="navigateToCheckout()" class="btn btn-block btn-red-white">
            checkout now
          </a>

        </div>
      </div>
    </div>

  </main>
</div>
