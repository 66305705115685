<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white; text-align: center" > Please wait&#x2026;<br><br> We are processing your transaction, do not navigate away from this page. </p></ngx-spinner>
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back" *ngIf="enrolment != null && course != null">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_booking_transfer_confirm', enrolment.enrolmentId, course.id]">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="/assets//sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info" *ngIf="enrolment != null">
            <h2 class="title">
              transfer booking					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>
          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_bookings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="/assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container multi-step-form">

      <div class="card">
        <div class="multi-step-track">
          <div class="slide-track">
            <form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
              <div class="form-section card-checkout current">
                <div class="card-inner">
                  <div class="table-summary table-checkout">

                    <div class="table-responsive" *ngIf="transferPreview != null && enrolment != null">
                      <table class="table table-multiple">
                        <tbody>
                        <tr>
                          <th>{{enrolment.student.givenName}} {{enrolment.student.familyName}}</th>
                          <th>
                            <span>{{transferPreview.totalAmount | currency:transferPreview.currencyCode:'symbol-narrow'}}</span>
                          </th>
                        </tr>
                        <tr>
                          <td><div *ngFor="let reason of transferPreview.reasons">{{reason}}</div></td>
                          <td>{{formatPriceTypeTitle(enrolment.enrolmentType)}}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr *ngIf="transferPreview.accountBalance > 0">
                          <th colspan="2">{{transferPreview.accountBalance | currency:transferPreview.currencyCode:'symbol-narrow'}}</th>
                        </tr>
                        <tr *ngIf="transferPreview.accountBalance > 0">
                          <td colspan="2">Account Balance</td>
                        </tr>
                        <tr>
                          <th colspan="2">{{transferPreview.totalAmountWithBalance | currency:transferPreview.currencyCode:'symbol-narrow'}}</th>
                        </tr>
                        <tr>
                          <td colspan="2">total</td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div></div>

                <span *ngIf="venuePolicies != null">
                  <div class="card-inner" *ngFor="let policy of venuePolicies">

                    <div class="row row-policy-title">

                      <h3 class="span-heading title-label">
                        {{policy.title}}
                      </h3>

                      <div class="row row-policy-text" [innerHTML]="policy.policy">

                      </div>

                      <div class="span-input span-checkbox required">
                        <label
                          for="policy_{{policy.id}}"
                          class="input-check checkbox">
                          <input
                            id="policy_{{policy.id}}"
                            name="policy_{{policy.id}}"
                            type="checkbox"
                            required="required"
                            class="input-checkbox"
                            formControlName="policyCheckbox_{{policy.id}}"
                            appParsleyValidation
                            [submitted]="submitted"
                            [elementName]="policy.title"
                            [requireChecked]="true"
                          />
                          <span>
                        I accept the terms above.
                      </span>
                          <i class="asterisk">*</i>
                          <i class="checkmark"></i>
                        </label>
                        <ul class="parsley-errors-list filled"></ul>
                      </div>

                    </div>
                  </div>
                  </span>

                <div class="card-inner">

                  <div class="row row-payment-title" *ngIf="transferPreview != null && transferPreview.totalAmountWithBalance > 0">

                    <h3 class="span-heading title-label">
                      Payment method
                    </h3>
                    <div class="span-cards" *ngIf="currentTransactionType === 'CARD'">
                      <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>		<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>	</div>

                  </div>

                  <app-checkout-payment-method-card *ngIf="currentTransactionType === 'CARD' && enrolment != null && transferPreview != null && transferPreview.totalAmountWithBalance > 0"
                                                    [submitted]="submitted"
                                                    [paymentForm]="paymentForm"
                                                    [accountId]="enrolment.account.id"
                                                    [licenseeId]="enrolment.account.licenseeId"
                                                    [addressLinesActive]="addressLinesActive"
                                                    [updateInvoices]="true"></app-checkout-payment-method-card>

                  <span *ngIf="directDebitDetails != null && currentTransactionType === 'MONTHLY_DIRECT_DEBIT' && !requiresMandate">
      <div class="row row-card-info">
      We will debit funds from your {{directDebitDetails.bankName}} bank account ending {{directDebitDetails.trailingFourAccountDigits}}.
      </div>
    </span>

                  <div class="span-input span-checkbox required" *ngIf="termsAndConditions != null && (currentTransactionType === 'CARD' || !requiresMandate)">
                    <label
                      for="enrolmentTerms"
                      class="input-check checkbox">
                      <input
                        id="enrolmentTerms"
                        name="enrolmentTerms"
                        type="checkbox"
                        required="required"
                        class="input-checkbox"
                        formControlName="enrolmentTermsCheckbox"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="'Terms and Conditions'"
                        [requireChecked]="true"
                      />
                      <span>
			I accept the <a target="_blank" [routerLink]="['/terms_and_conditions']">terms & conditions</a>.
		</span>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                    </label>
                    <ul class="parsley-errors-list filled"></ul>
                  </div>

                  <button type="submit" *ngIf="currentTransactionType === 'CARD' || (currentTransactionType === 'MONTHLY_DIRECT_DEBIT' && !this.requiresMandate)" [ngClass]="enrolling || paymentForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
                    Complete booking
                  </button>

                  <button type="button" (click)="setupDirectDebit()" *ngIf="currentTransactionType === 'MONTHLY_DIRECT_DEBIT' && this.requiresMandate" [ngClass]="settingUpMandate ? 'disabled' : ''" class="btn btn-block btn-red">
                    Setup Direct Debit
                  </button>

                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  </main>
</div>

<app-footer></app-footer>
