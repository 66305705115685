<div class="span-sidebar">
  <ul class="nav nav-dashboard">
    <li id="home">
      <a [routerLink]="['/dashboard']" [ngClass]="activeItem === 1 ? 'active' : ''">
        <span class="svg-icon dashboard-home"><svg class="svg-dashboard-home"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-home"></use></svg></span>			home
      </a>
    </li>
    <li id="bookings">
      <a [routerLink]="['/dashboard_bookings']" [ngClass]="activeItem === 2 ? 'active' : ''">
        <span class="svg-icon dashboard-clipboard"><svg class="svg-dashboard-clipboard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clipboard"></use></svg></span>			bookings
      </a>
    </li>
    <li id="waiting-list">
      <a [routerLink]="['/dashboard_waiting_list']" [ngClass]="activeItem === 3 ? 'active' : ''">
        <span class="svg-icon dashboard-clock"><svg class="svg-dashboard-clock"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clock"></use></svg></span>			waiting list
      </a>
    </li>
    <li id="credits">
      <a [routerLink]="['/dashboard_credits']" [ngClass]="activeItem === 4 ? 'active' : ''">
        <span class="svg-icon dashboard-coins"><svg class="svg-dashboard-coins"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-coins"></use></svg></span>			credits
      </a>
    </li>
    <li id="students">
      <a [routerLink]="['/dashboard_students']" [ngClass]="activeItem === 5 ? 'active' : ''">
        <span class="svg-icon dashboard-users"><svg class="svg-dashboard-users"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-users"></use></svg></span>			students
      </a>
    </li>
    <li id="upgrades-offers">
      <a [routerLink]="['/dashboard_upgrades_offers']" [ngClass]="activeItem === 6 ? 'active' : ''">
        <span class="svg-icon dashboard-tags"><svg class="svg-dashboard-tags"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-tags"></use></svg></span>			upgrades & offers
      </a>
    </li>
    <li id="subscriptions">
      <a [routerLink]="['/dashboard_subscriptions']" [ngClass]="activeItem === 7 ? 'active' : ''">
        <span class="svg-icon dashboard-tags"><svg class="svg-dashboard-tags"><use xlink:href="./assets/sprite/spritemap.svg#sprite-subscription"></use></svg></span>			subscriptions
      </a>
    </li>
    <li id="billing-history">
      <a [routerLink]="['/dashboard_billing_history']" [ngClass]="activeItem === 8 ? 'active' : ''">
        <span class="svg-icon dashboard-billing-history"><svg class="svg-dashboard-billing-history"><use xlink:href="./assets/sprite/spritemap.svg#sprite-invoice"></use></svg></span>			billing history
      </a>
    </li>
    <li id="account-settings">
      <a [routerLink]="['/dashboard_account_settings']" [ngClass]="activeItem === 9 ? 'active' : ''">
        <span class="svg-icon dashboard-user"><svg class="svg-dashboard-user"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-user"></use></svg></span>			account settings
      </a>
    </li>
  </ul>					</div>
