<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" (click)="goBack()">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              make a new booking					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" (click)="goBack()">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>


<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container">
        <form method="post" id="booking_start" novalidate="novalidate" class="form-dashboard" [formGroup]="studentsForm" (ngSubmit)="onStudentsSubmit()" *ngIf="stepNumber === 1">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Who is the new booking for?
              </h2>
            </div>

            <div class="row row-cards" *ngIf="accountContacts != null">

              <div class="span-card row-element-centred" *ngFor="let accountContact of accountContacts">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="student_{{accountContact.id}}"
                      class="input-check radio">
                      <img *ngIf="accountContact.programLevelId != null && accountContact.hasLevelImage" src="{{getLevelImageUrlSrc(accountContact.programLevelId)}}" alt="{{accountContact.givenName}} {{accountContact.familyName}}" />
                      <span *ngIf="accountContact.programLevelId == null || (accountContact.programLevelId != null && !accountContact.hasLevelImage)" class="svg-icon student-2"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-2"></use></svg></span>
                      <input
                        id="student_{{accountContact.id}}"
                        type="radio"
                        name="studentInput"
                        [value]="accountContact.id"
                        formControlName="studentInput"
                        (change)="studentSelected(accountContact.id)"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{accountContact.givenName}} {{accountContact.familyName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="span-card row-element-centred">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="student_new"
                      class="input-check radio">
                      <span class="svg-icon student-2"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-2"></use></svg></span>									<input
                      id="student_new"
                      type="radio"
                      name="studentInput"
                      [value]="'new'"
                      formControlName="studentInput"
                      (change)="studentSelected('new')"
                    />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">add a new student</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" class="btn btn-block btn-red" [ngClass]="this.studentsForm.invalid ? 'disabled' : ''">
              continue
            </button>

          </div>

        </form>

        <form *ngIf="stepNumber === 2" method="post" id="booking_program" novalidate="novalidate" class="form-dashboard" [formGroup]="programsForm" (ngSubmit)="onProgramsSubmit()">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Which program is this for?
              </h2>
            </div>

            <div class="row row-cards" *ngIf="programs != null">

              <div class="span-card row-element-centred" *ngFor="let program of programs">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="program_{{program.id}}"
                      class="input-check radio">
                      <input
                        id="program_{{program.id}}"
                        type="radio"
                        [value]="program.id"
                        formControlName="studentProgram"
                        (change)="setProgramId(program.id)"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{program.programName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" [ngClass]="this.programsForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
              continue
            </button>

          </div>

        </form>

        <form *ngIf="stepNumber === 3" method="post" id="booking_program_level" novalidate="novalidate" class="form-dashboard" [formGroup]="programLevelsForm" (ngSubmit)="onProgramLevelsSubmit()">

          <div class="panel panel-dashboard type-booking">

            <div class="span-title">
              <h2 class="entry-title">
                Which level is this for?
              </h2>
            </div>

            <div class="row row-cards" *ngIf="programLevels != null">

              <div class="span-card row-element-centred" *ngFor="let programLevel of programLevels">
                <div class="card style-default">
                  <div class="span-choice">
                    <label
                      for="program_{{programLevel.id}}"
                      class="input-check radio">
                      <div class="span-graphic">
                        <div class="graphic-image" [ngStyle]="{'background-image': getLevelImageUrl(programLevel.id)}"></div>
                      </div>
                      <input
                        id="program_{{programLevel.id}}"
                        type="radio"
                        [value]="programLevel.id"
                        formControlName="studentProgramLevel"
                        (change)="setLevelId(programLevel.id, programLevel.programLevelName)"
                      />
                      <span class="radio-content">
										<ul class="label-description">
											<li class="title">{{programLevel.programLevelName}}</li>
										</ul>
									</span>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <button type="submit" [ngClass]="this.programsForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
              continue
            </button>

          </div>

        </form>
        <app-select-location *ngIf="stepNumber === 4" [postCode]="selectedPostCode"></app-select-location>
        <app-dashboard-add-student *ngIf="stepNumber === 5"></app-dashboard-add-student>
      </div>
    </section>
  </main>
</div>

<app-footer></app-footer>
