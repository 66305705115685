export class ApiException {
  timestamp: Date;
  status: number;
  error: string;
  message: string;
  path: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.timestamp != null && typeof this.timestamp === 'string') {
        if (this.timestamp !== '') {
          this.timestamp = new Date(this.timestamp);
        }
      }
    }
  }
}
