export class AchievedAwardCertificateDescription {
  heading: string;
  introductionText: string;
  hasPrintable: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
