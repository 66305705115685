import {NewEnrolmentRequestStudent} from './new-enrolment-request-student';

export class NewEnrolmentRequest {
  accountId: string;
  licenseeId: string;
  cardId: string;
  acceptedTermsAndConditions: boolean;
  acceptedPolicyIds: string[];
  studentEnrolments: NewEnrolmentRequestStudent[];
  paymentIntentId: string;
  provisionalEnrolmentId: string;
  provisionalEnrolmentType: string;
  quotedAmount: number;
  voucherCode: string;
}
