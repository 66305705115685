<form method="post" id="form_checkout_students" novalidate="novalidate" class="form-checkout" [formGroup]="studentsForm" (ngSubmit)="onStudentsSubmit()">
  <div class="form-section current" *ngIf="loadedStudents">

    <div class="card-inner" *ngFor="let courseStudent of students">

      <h3 class="title-label">
        {{courseStudent.student.studentName}}		</h3>

      <div class="row row-details ">
        <h3 class="span-heading title-label">
          Who is Swimming?
        </h3>

        <div class="span-input span-select required">
          <select
            id="whoIsSwimming{{courseStudent.id}}"
            name="whoIsSwimming{{courseStudent.id}}"
            class="input-select"
            formControlName="whoIsSwimmingSelect{{courseStudent.id}}"
            (change)="setControlValidators(courseStudent)"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Who is this lesson for?'"
          >
            <option value="" disabled selected hidden></option>
            <option *ngFor="let accountContact of accountContacts" value="{{accountContact.id}}" [disabled]="isStudentDisabled(courseStudent, accountContact)">
              {{accountContact.givenName}} {{accountContact.familyName}}
              <span *ngIf="isStudentDisabled(courseStudent, accountContact)">
                (Invalid level)
              </span>
            </option>
            <option value="new">New Student...</option>
          </select>
          <i class="asterisk">*</i>
          <i class="checkmark"></i>
          <ul class="parsley-errors-list filled"></ul>
        </div>
      </div>

      <div class="row row-details" [style.display]="courseStudent.whoIsSwimming === 'new' ? '' : 'none'">
        <div class="span-input span-select span-short required">
          <select
            id="studentTitle{{courseStudent.id}}"
            name="studentTitle{{courseStudent.id}}"
            class="input-select"
            formControlName="studentTitleSelect{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Title'"
          >
            <option value="" disabled selected hidden>title</option>
            <option value="Dr.">Dr.</option>
            <option value="Lady">Lady</option>
            <option value="Lord">Lord</option>
            <option value="Master">Master</option>
            <option value="Miss">Miss</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Ms.">Ms.</option>
            <option value="Prof.">Prof.</option>
            <option value="Rev.">Rev.</option>
            <option value="Sir">Sir</option>
          </select>
          <i class="asterisk hide-on-error">* required</i>
          <i class="checkmark"></i>
          <ul class="parsley-errors-list filled"></ul>
        </div><div class="span-input span-text required">
        <input
          type="text"
          id="studentFirstName{{courseStudent.id}}"
          name="studentFirstName{{courseStudent.id}}"
          class="input-text"
          formControlName="studentFirstNameInput{{courseStudent.id}}"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'First Name'"
        />
        <label for="studentFirstName{{courseStudent.id}}" class="floating-label">
          first name
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled"></ul>
      </div>

        <div class="span-input span-text required">
          <input
            type="text"
            id="studentLastName{{courseStudent.id}}"
            name="studentLastName{{courseStudent.id}}"
            class="input-text"
            formControlName="studentLastNameInput{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Surname'"
          />
          <label for="studentLastName{{courseStudent.id}}" class="floating-label">
            surname
          </label>
          <i class="asterisk">*</i>
          <i class="checkmark"></i>
          <ul class="parsley-errors-list filled"></ul>
        </div>
        <div class="span-input span-select required">
          <select
            id="studentGender{{courseStudent.id}}"
            name="studentGender{{courseStudent.id}}"
            class="input-select"
            formControlName="studentGenderSelect{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Gender'"
          >
            <option value="" disabled selected hidden>gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
          <label for="studentGender{{courseStudent.id}}" class="floating-label">
            gender
          </label>
          <i class="asterisk">*</i>
          <i class="checkmark"></i>
          <ul class="parsley-errors-list filled"></ul>
        </div>
      </div>

      <div class="row row-cards row-dob" [style.display]="showCreateStudentInfo(courseStudent) ? '' : 'none'">
        <div class="span-select span-input span-4 checkout-dob required">
          <select
            id="studentDateOfBirthDay{{courseStudent.id}}"
            name="studentDateOfBirthDay{{courseStudent.id}}"
            class="input-select"
            formControlName="studentDateOfBirthDaySelect{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Day'"
            (change)="setStudentDateOfBirth(courseStudent)"
          >
            <option value="" disabled selected hidden>Day</option>
            <option value="1">
              1			</option>
            <option value="2">
              2			</option>
            <option value="3">
              3			</option>
            <option value="4">
              4			</option>
            <option value="5">
              5			</option>
            <option value="6">
              6			</option>
            <option value="7">
              7			</option>
            <option value="8">
              8			</option>
            <option value="9">
              9			</option>
            <option value="10">
              10			</option>
            <option value="11">
              11			</option>
            <option value="12">
              12			</option>
            <option value="13">
              13			</option>
            <option value="14">
              14			</option>
            <option value="15">
              15			</option>
            <option value="16">
              16			</option>
            <option value="17">
              17			</option>
            <option value="18">
              18			</option>
            <option value="19">
              19			</option>
            <option value="20">
              20			</option>
            <option value="21">
              21			</option>
            <option value="22">
              22			</option>
            <option value="23">
              23			</option>
            <option value="24">
              24			</option>
            <option value="25">
              25			</option>
            <option value="26">
              26			</option>
            <option value="27">
              27			</option>
            <option value="28">
              28			</option>
            <option value="29">
              29			</option>
            <option value="30">
              30			</option>
            <option value="31">
              31			</option>
          </select>
          <ul class="parsley-errors-list filled"></ul>
        </div>

        <div class="span-select span-input span-4 checkout-dob required">
          <select
            id="studentDateOfBirthMonth{{courseStudent.id}}"
            name="studentDateOfBirthMonth{{courseStudent.id}}"
            class="input-select"
            formControlName="studentDateOfBirthMonthSelect{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Month'"
            (change)="setStudentDateOfBirth(courseStudent)"
          >
            <option value="" disabled selected hidden>Month</option>
            <option value="1">
              1			</option>
            <option value="2">
              2			</option>
            <option value="3">
              3			</option>
            <option value="4">
              4			</option>
            <option value="5">
              5			</option>
            <option value="6">
              6			</option>
            <option value="7">
              7			</option>
            <option value="8">
              8			</option>
            <option value="9">
              9			</option>
            <option value="10">
              10			</option>
            <option value="11">
              11			</option>
            <option value="12">
              12			</option>
          </select>
          <ul class="parsley-errors-list filled"></ul>
        </div>

        <div class="span-select span-input span-4 row-checkmark-last checkout-dob required" >
          <select
            id="studentDateOfBirthYear{{courseStudent.id}}"
            name="studentDateOfBirthYear{{courseStudent.id}}"
            class="input-select"
            formControlName="studentDateOfBirthYearSelect{{courseStudent.id}}"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Date'"
            (change)="setStudentDateOfBirth(courseStudent)"
          >
            <option value="" disabled selected hidden>Year</option>
            <option *ngFor="let dobYear of dobYears" value="{{dobYear}}">{{dobYear}}</option>
          </select>
          <i class="asterisk">*</i>
          <i class="checkmark"></i>
          <ul class="parsley-errors-list filled"></ul>
        </div>

      </div>


      <div class="row row-conditions " [style.display]="showCreateStudentInfo(courseStudent) ? '' : 'none'">
        <h3 class="span-heading title-label">
          Any Medical conditions?
        </h3>

        <div class="span-input span-checkbox">
          <label
            for="studentMedicalNo{{courseStudent.id}}"
            class="input-check checkbox">
            <input
              id="studentMedicalNo{{courseStudent.id}}"
              name="studentMedicalRadio{{courseStudent.id}}"
              type="radio"
              class="input-checkbox"
              [value]="false"
              formControlName="studentMedicalRadio{{courseStudent.id}}"
              appParsleyValidation
              [submitted]="submitted"
              [elementName]="'Medical Conditions'"
              (change)="medicalInputClicked(courseStudent)"
            />
            <span>
				No
			</span>
          </label>
        </div>

        <div class="span-input span-checkbox">
          <label
            for="studentMedicalYes{{courseStudent.id}}"
            class="input-check checkbox">
            <input
              id="studentMedicalYes{{courseStudent.id}}"
              name="studentMedicalRadio{{courseStudent.id}}"
              type="radio"
              class="input-checkbox"
              [value]="true"
              formControlName="studentMedicalRadio{{courseStudent.id}}"
              appParsleyValidation
              [submitted]="submitted"
              [elementName]="'Medical Conditions'"
              (change)="medicalInputClicked(courseStudent)"
            />
            <span>
				Yes
			</span>
          </label>
          <ul class="parsley-errors-list filled"></ul>
        </div>

        <div [style.display]="courseStudent.student.hasMedicalConditions ? '' : 'none'" id="student-{{courseStudent.id}}-medical-condition-wrap" class="span-input span-textarea active">
		<textarea
      id="studentMedicalConditions{{courseStudent.id}}"
      name="studentMedicalConditions{{courseStudent.id}}"
      class="input-text"
      placeholder="medical condition"
      formControlName="studentMedicalConditionsTextArea{{courseStudent.id}}"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Medical Conditions'"
    ></textarea>
          <label
            for="studentMedicalConditions{{courseStudent.id}}"
            class="sr-only"
          >
            medical condition
          </label>
          <ul class="parsley-errors-list filled"></ul>
        </div>

      </div>
    </div>


    <button type="submit" [ngClass]="studentsForm.invalid || submitting ? 'disabled' : ''" class="btn btn-block btn-red">
      Next
    </button>
  </div>
</form>
