import {CreditPack} from './credit-pack';
import {Invoice} from './invoice';
import {Price} from './price';
import {convertObjectToDate} from '../_helpers/date_helper';

export class CreditPackResult {
  creditPack: CreditPack;
  price: Price;
  invoice: Invoice;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    if (this.invoice != null) {
      this.invoice.transactionDate = convertObjectToDate(this.invoice.transactionDate);
    }
  }
}
