import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TitleGenerator} from '../../title-generator';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {CheckoutPaymentMethodCardService} from '../checkout-payment/checkout-payment-method-card/checkout-payment-method-card.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../alert';
import {DashboardService} from '../../dashboard/dashboard.service';
import {PaymentMethod} from '../../_model/payment-method';
import {PolicyResult} from '../../_model/policy-result';
import {Address} from '../../_model/address';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {UpgradeToMultipleTermsRequest} from '../../_model/upgrade-to-multiple-terms-request';
import {UpgradeToMultipleTermsResponse} from '../../_model/upgrade-to-multiple-terms-response';
import {TermsAndConditionsValidator} from '../../_helpers/terms_and_conditions_validator';
import {BasketService} from '../basket.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs';
import {GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-dashboard-booking-upgrade',
  templateUrl: './dashboard-booking-upgrade.component.html',
  styleUrls: ['./dashboard-booking-upgrade.component.css']
})
export class DashboardBookingUpgradeComponent implements OnInit, OnDestroy, AfterViewInit {

  paymentForm: UntypedFormGroup;

  submitted = false;

  enrolment: EnrolmentResponse;
  upgradeToMultipleTermPreview: UpgradeToMultipleTermsResponse;

  cardPaymentMethod: PaymentMethod;
  addressLinesActive = false;

  termsAndConditionsPolicyId: string;
  termsAndConditions: PolicyResult;
  enrolling = false;
  receiptId: string;
  voucherCode: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  cardFormUpdatedSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  voucherCodeSubscription: Subscription;

  loading = false;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private dashboardService: DashboardService,
              private basketService: BasketService,
              private spinner: NgxSpinnerService,
              private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Upgrade Enrolment');

    this.cardPaymentMethod = new PaymentMethod();
    this.cardPaymentMethod.address = new Address();

    this.paymentForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      creditCardSelect: [''],
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      enrolmentTermsCheckbox: ['']
    });

    this.cardFormUpdatedSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.cardPaymentMethod = paymentMethod;
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && !this.loading) {
        if (hasErrors) {
          this.enrolling = false;
          this.spinner.hide();
        } else {
          this.upgradeEnrolment();
        }
      }
    });

    this.route.params.subscribe(params => {
      this.apiService.getEnrolment(params.enrolmentId, this.ngUnsubscribe).subscribe(result => {
        this.enrolment = result;
        this.previewUpgrade();
      });
    });

    this.voucherCodeSubscription = this.basketService.currentVoucherCode.subscribe(voucherCode => {
      if (voucherCode != null) {
        if (voucherCode === 'NULL') {
          this.voucherCode = null;
        } else {
          this.voucherCode = voucherCode;
        }

        this.previewUpgrade();
      }
    });

    this.renderer.addClass(document.body, 'checkout-multiple');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'type-multi');

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;

      if (this.termsAndConditions != null) {
        this.termsAndConditionsPolicyId = this.termsAndConditions.id;
        this.setPaymentValidators();
      }
    });

    this.setPaymentValidators();
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  previewUpgrade() {
    if (this.enrolment == null) {
      this.alertService.error('Enrolment not found');
      return;
    }

    this.alertService.clear();

    const upgradeArgs = new UpgradeToMultipleTermsRequest();
    upgradeArgs.enrolmentId = this.enrolment.enrolmentId;
    upgradeArgs.acceptedTermsAndConditions = false;
    upgradeArgs.voucherCode = this.voucherCode;

    this.upgradeToMultipleTermPreview = null;
    this.apiService.previewUpgradeToMultipleTerms(upgradeArgs).subscribe(response => {
      this.upgradeToMultipleTermPreview = response;
      if (response.result === 'error') {
        this.alertService.error(response.error);
      } else {
        let promotionName = 'Multiple Term Upgrade';
        if (response.futureTerms != null) {
          promotionName += ' ' + response.futureTerms.join(' ,');
        }
        this.googleAnalytics.promotionView('MULTI_TERM', promotionName);
      }
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'checkout-multiple');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'type-multi');

    if (this.cardFormUpdatedSubscription != null) {
      this.cardFormUpdatedSubscription.unsubscribe();
    }
    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }
    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }
    if (this.voucherCodeSubscription != null) {
      this.voucherCodeSubscription.unsubscribe();
    }
  }

  setPaymentValidators() {
    let addressValidator = null;
    let creditCardAddressValidator = null;
    let paymentValidator = null;

    if (this.upgradeToMultipleTermPreview != null && this.upgradeToMultipleTermPreview.totalAmountWithBalance > 0) {
      paymentValidator = [Validators.required];
      if (this.cardPaymentMethod.cardId === 'new') {
        creditCardAddressValidator = [Validators.required];

        if (this.cardPaymentMethod.paymentAddressId === 'other') {
          addressValidator = [Validators.required];
        }
      } else {
        this.removeFormControlError('cardError');
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.termsAndConditions != null) {
      this.paymentForm.get('enrolmentTermsCheckbox').setValidators([TermsAndConditionsValidator()]);
    }

    this.paymentForm.get('creditCardSelect').setValidators(paymentValidator);
    this.paymentForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.paymentForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.paymentForm.get('addressLine1Input').setValidators(addressValidator);
    this.paymentForm.get('addressCityInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountyInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountryInput').setValidators(addressValidator);
    this.paymentForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.paymentForm.get('creditCardSelect').updateValueAndValidity();
    this.paymentForm.get('creditCardNameInput').updateValueAndValidity();
    this.paymentForm.get('paymentAddress').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressCityInput').updateValueAndValidity();
    this.paymentForm.get('addressCountyInput').updateValueAndValidity();
    this.paymentForm.get('addressCountryInput').updateValueAndValidity();
    this.paymentForm.get('addressPostcodeInput').updateValueAndValidity();
    this.paymentForm.get('enrolmentTermsCheckbox').updateValueAndValidity();

    this.paymentForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  onPaymentSubmit(): void {
    if (this.upgradeToMultipleTermPreview == null || this.enrolling || this.enrolment == null) {
      return;
    }

    this.submitted = true;

    if (this.cardPaymentMethod.cardId === 'new' && this.cardPaymentMethod.cardHasErrors) {
      this.paymentForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
    }

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }

    this.enrolling = true;
    this.spinner.show();

    if (this.upgradeToMultipleTermPreview.totalAmountWithBalance > 0 && this.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else  {
      this.upgradeEnrolment();
    }
  }

  upgradeEnrolment(): void {
    this.alertService.clear();

    if (this.enrolment == null) {
      this.alertService.error('Enrolment not found');
      return;
    }

    const upgradeArgs = new UpgradeToMultipleTermsRequest();
    upgradeArgs.enrolmentId = this.enrolment.enrolmentId;
    upgradeArgs.acceptedTermsAndConditions = false;
    upgradeArgs.cardId = this.cardPaymentMethod.cardId;
    upgradeArgs.paymentIntentId = this.cardPaymentMethod.paymentIntentId;
    upgradeArgs.quotedAmount = this.upgradeToMultipleTermPreview.totalAmountWithBalance;
    upgradeArgs.voucherCode = this.voucherCode;
    upgradeArgs.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    upgradeArgs.termsAndConditionsPolicyId = this.termsAndConditionsPolicyId;

    this.apiService.upgradeToMultipleTerms(upgradeArgs).subscribe(response => {
      this.enrolling = false;
      this.spinner.hide();
      let receiptId = null;
      if (response.result === 'success') {
        if (response.sportsPayment != null
          && response.sportsPayment.payment != null
          && response.sportsPayment.payment.code !== '') {
          receiptId = response.sportsPayment.payment.code;
          this.alertService.success('Upgrade complete, receipt number: ' + response.sportsPayment.payment.code);
        } else {
          this.alertService.success('Upgrade complete');
        }

        this.googleAnalytics.paymentForCourse(this.enrolment.course,
          this.enrolment.course.programLevel,
          receiptId,
          response.totalAmountWithBalance,
          response.totalAmount,
          response.currencyCode,
          'Multiple Term Upgrade',
          null);

        this.navigateToReceipt(receiptId);
      } else if (response.result === 'requires_action') {
        if (response.requiresPaymentAction) {
          console.log('requires payment action');
          this.enrolling = true;
          this.spinner.show();
          this.checkoutPaymentMethodCardService.setHandleStripeAction(response.paymentIntentClientSecret);
        } else {
          console.error('Requires action, though action parameter not true');
        }
      } else if (response.result === 'payment_error') {
        this.alertService.error(response.error);
      } else if (response.result === 'error') {
        if (response.error != null) {
          this.alertService.error(response.error);
        } else {
          this.alertService.error('An unknown error has occurred');
        }
      } else {
        console.error('Unknown response: ' + response.result);
      }
    });
  }

  navigateToReceipt(receiptId) {
    this.router.navigate(['/booking_complete'], {queryParams: {receiptId}});
  }
}
