import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {Student} from './_model/student';
import {map} from 'rxjs/operators';
import {AccountContact} from './_model/account-contact';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private apiService: ApiService) {}

  public getLoggedInStudentAccountContacts(): Observable<Array<AccountContact>> {
    return this.apiService.getLoggedInUserStudents().pipe(
      map(response => {
        return response.map(student => {
          const accountContact = new AccountContact();
          accountContact.type = 'student';
          accountContact.id = student.id;
          accountContact.givenName = student.givenName ;
          accountContact.familyName = student.familyName;
          accountContact.entityId = student.studentEntityId;
          accountContact.title = student.title;
          accountContact.gender = student.gender;
          accountContact.dateOfBirth = student.dateOfBirth;

          return accountContact;
        });
      })
    );
  }
}
