import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Account} from '../../_model/account';
import {Person} from '../../_model/person';
import {Address} from '../../_model/address';
import {UpdatePersonRequest} from '../../_model/update-person-request';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-dashboard-account-settings',
  templateUrl: './dashboard-account-settings.component.html',
  styleUrls: ['./dashboard-account-settings.component.scss']
})
export class DashboardAccountSettingsComponent implements OnInit, OnDestroy {

  accounts: Account[];
  person: Person;
  address: Address;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  accountsLoaded: boolean;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService) {
    this.accountsLoaded = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Account Settings');

    this.accounts = [];
    this.apiService.getAccounts(null, this.ngUnsubscribe).subscribe(accounts => {
      this.accounts = accounts;
      this.accountsLoaded = true;
    });

    this.renderer.addClass(document.body, 'dashboard-account-settings');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      if (loggedInUser != null) {
        this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(person => {
          this.person = person;
        });
        this.apiService.findAddresses('billing', this.ngUnsubscribe).subscribe(results => {
          let isFirst = true;
          results.forEach(address => {
            if (isFirst) {
              isFirst = false;
              this.address = address;
            }
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-account-settings');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getTelephoneString(): string {
    let returnVal = '';

    if (this.person.telephoneHome != null && this.person.telephoneHome !== '') {
      returnVal += this.person.telephoneHome;
    }

    if (this.person.telephoneMobile != null
      && this.person.telephoneMobile !== ''
      && this.person.telephoneMobile !== this.person.telephoneHome) {
      if (returnVal !== '') {
        returnVal += ', ';
      }

      returnVal += this.person.telephoneMobile;
    }

    return returnVal;
  }

  updateOptInMarketingEmails(value: boolean) {
    this.person.optInMarketingEmails = value;
    this.updatePersonDetails();
  }

  updateOptInMarketingSms(value: boolean) {
    this.person.optInMarketingSMS = value;
    this.updatePersonDetails();
  }

  updatePersonDetails() {
    const updatePersonRequest = new UpdatePersonRequest();
    updatePersonRequest.person = this.person;

    this.apiService.updatePerson(this.person.personId, updatePersonRequest).subscribe(person => {
      this.person = person;
    });
  }
}
