import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../../auth-service';
import {ApiService} from '../../../api.service';
import {DashboardService} from '../../dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Person} from '../../../_model/person';
import {Address} from '../../../_model/address';
import {checkStrength, PasswordStrengthValidator, PasswordValidator} from '../../../_helpers/password_validator';
import {PhoneNumberValidator} from '../../../_helpers/phone_number_validator';
import {TelephoneValidator} from '../../../_helpers/telephone_validator';
import {formatNumber} from '../../../_helpers/telephone_helper';
import {UpdatePersonRequest} from '../../../_model/update-person-request';
import {TitleGenerator} from '../../../title-generator';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Subject, Subscription} from 'rxjs';
import {BasketService} from '../../../checkout/basket.service';
import {getFormValidationErrors} from '../../../_helpers/debug_helper';

@Component({
  selector: 'app-dashboard-edit-personal-details',
  templateUrl: './dashboard-edit-personal-details.component.html',
  styleUrls: ['./dashboard-edit-personal-details.component.css']
})
export class DashboardEditPersonalDetailsComponent implements OnInit, OnDestroy {

  passwordForm: UntypedFormGroup;
  editPersonForm: UntypedFormGroup;
  person: Person;
  address: Address;
  passwordFormSubmitted = false;
  runningUpdate = false;
  personFormSubmitted = false;

  currentPasswordFieldTextType = false;
  updatedPasswordFieldTextType = false;
  passwordStrength = 0;

  countryCode: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  applicationSettingsSubscription: Subscription;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private applicationSettings: ApplicationSettingsService,
              private basketService: BasketService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Edit Direct Debit');

    this.countryCode = this.applicationSettings.countryCode;

    this.passwordForm = this.formBuilder.group({
      currentPasswordInput: ['', [
        Validators.required]],
      updatedPasswordInput: ['', [Validators.minLength(6),
        PasswordStrengthValidator('updatedPasswordInput', 30),
        Validators.required]],
      updatedPasswordConfirmationInput: ['', [
        Validators.required,
        Validators.minLength(6),
        PasswordValidator('updatedPasswordInput')
      ]]
    });

    this.editPersonForm = this.formBuilder.group({
      titleSelect: ['', Validators.required],
      givenNameInput: ['', Validators.required],
      familyNameInput: ['', Validators.required],
      emailInput: ['', [Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: ['', Validators.required],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: ['', Validators.required],
      addressCountyInput: ['', Validators.required],
      addressCountryInput: ['', Validators.required],
      addressPostcodeInput: ['', Validators.required],
      postcodeResultsSelect: [''],
      telephoneHomeInput: [''],
      telephoneMobileInput: ['', [TelephoneValidator('telephoneHomeInput')]]
    });

    this.renderer.addClass(document.body, 'dashboard-edit-personal-details');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      if (loggedInUser != null) {
        this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(person => {
          this.person = person;

          this.editPersonForm.patchValue({
            titleSelect: person.title,
            givenNameInput: person.givenName,
            familyNameInput: person.familyName,
            emailInput: person.email,
            telephoneHomeInput: person.telephoneHome,
            telephoneMobileInput: person.telephoneMobile
          });

          this.changeDetectorRef.detectChanges();
        });
        this.apiService.findAddresses('billing', this.ngUnsubscribe).subscribe(results => {
          let isFirst = true;
          results.forEach(address => {
            if (isFirst) {
              isFirst = false;
              this.address = address;
              this.changeDetectorRef.detectChanges();
            }
          });
        });
      }
    });

    this.basketService.currentAddress.subscribe(address => {
      if (address != null) {
        this.address = address;
      }
    });

    this.setTelephoneValidators();
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-edit-personal-details');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  setTelephoneValidators() {
    let telephoneHomeValidator = null;
    let telephoneMobileValidator = null;

    if (this.countryCode != null) {
      telephoneHomeValidator = [PhoneNumberValidator(this.countryCode)];
      telephoneMobileValidator = [PhoneNumberValidator(this.countryCode), TelephoneValidator('telephoneHomeInput')];
    } else {
      telephoneMobileValidator = [TelephoneValidator('telephoneHomeInput')];
    }

    this.editPersonForm.get('telephoneHomeInput').setValidators(telephoneHomeValidator);
    this.editPersonForm.get('telephoneMobileInput').setValidators(telephoneMobileValidator);

    this.editPersonForm.get('telephoneHomeInput').updateValueAndValidity();
    this.editPersonForm.get('telephoneMobileInput').updateValueAndValidity();
    this.editPersonForm.updateValueAndValidity();
  }

  onPersonFormSubmit(): void {
    this.personFormSubmitted = true;

    if (this.runningUpdate || this.editPersonForm.invalid) {
      getFormValidationErrors(this.editPersonForm);
      return;
    }

    this.runningUpdate = true;

    this.person.title = this.editPersonForm.get('titleSelect').value;
    this.person.givenName = this.editPersonForm.get('givenNameInput').value;
    this.person.familyName = this.editPersonForm.get('familyNameInput').value;
    this.person.email = this.editPersonForm.get('emailInput').value;
    this.person.telephoneHome = this.editPersonForm.get('telephoneHomeInput').value;
    this.person.telephoneMobile = this.editPersonForm.get('telephoneMobileInput').value;

    const updatePersonRequest = new UpdatePersonRequest();
    updatePersonRequest.person = this.person;
    updatePersonRequest.billingAddress = this.address;

    this.apiService.updatePerson(this.person.personId, updatePersonRequest).subscribe(() => {
      this.router.navigate(['/dashboard_account_settings']);
      this.dashboardService.setNewConfirmationMessage('Personal details updated');
    }, () => {
      this.runningUpdate = false;
    });
  }

  onPasswordFormSubmit(): void {
    this.passwordFormSubmitted = true;

    if (this.runningUpdate || this.passwordForm.invalid) {
      return;
    }

    this.runningUpdate = true;

    const currentPassword = this.passwordForm.get('currentPasswordInput').value;
    const updatedPassword = this.passwordForm.get('updatedPasswordInput').value;

    this.apiService.updatePassword(currentPassword, updatedPassword).subscribe(() => {
      this.router.navigate(['/dashboard_account_settings']);
      this.dashboardService.setNewConfirmationMessage('Password updated');
    }, () => {
      this.runningUpdate = false;
    });
  }

  getPasswordControl(componentName: string) {
    return this.passwordForm.get(componentName);
  }

  getPersonControl(componentName: string) {
    return this.editPersonForm.get(componentName);
  }

  formatTelephone() {
    this.person.telephoneHome = formatNumber(this.editPersonForm.get('telephoneHomeInput').value, this.countryCode);
    this.editPersonForm.get('telephoneHomeInput').setValue(this.person.telephoneHome);
  }

  formatMobile() {
    this.person.telephoneMobile = formatNumber(this.editPersonForm.get('telephoneMobileInput').value, this.countryCode);
    this.editPersonForm.get('telephoneMobileInput').setValue(this.person.telephoneMobile);
  }

  passwordEntered(passwordEntered: string) {
    this.passwordStrength = checkStrength(passwordEntered) / 10;
  }
}
