import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FindCourseSingleServiceService {

  private currentStepNumberSubject = new BehaviorSubject<number>(null);
  currentStepUpdate: Observable<number>;

  constructor() {
    this.currentStepUpdate = this.currentStepNumberSubject.asObservable();
  }

  setStepNumber(stepNumber: number) {
    this.currentStepNumberSubject.next(stepNumber);
  }

}
