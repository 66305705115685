<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <app-course-list-basket></app-course-list-basket>
    <div class="wgt wgt-courses">
    <div class="container">
      <div class="row row-courses">

        <div class="span-filter" [ngClass]="isSearchDisabled ? 'searchRunning' : ''">

          <aside id="wgt_filter" class="wgt wgt-filter toggler active">
            <div class="accordion" *ngIf="students != null && students.length === 1">

              <app-course-list-student-details-filter *ngFor="let student of students" [student]="student" [isSearchDisabled]="isSearchDisabled"></app-course-list-student-details-filter>
              <app-course-list-filter
                [courseRatios]="courseRatios"
                [mondayExists]="mondayExists"
                [tuesdayExists]="tuesdayExists"
                [wednesdayExists]="wednesdayExists"
                [thursdayExists]="thursdayExists"
                [fridayExists]="fridayExists"
                [saturdayExists]="saturdayExists"
                [sundayExists]="sundayExists"
                [instructors]="instructors"
                [startTimes]="startTimes"
                [isSearchDisabled]="isSearchDisabled"
                [showInstructor]="showInstructors"></app-course-list-filter>

            </div>
            <div class="accordion" *ngIf="students != null && students.length > 1">

              <app-course-list-student-filter [isSearchDisabled]="isSearchDisabled"></app-course-list-student-filter>
              <app-course-list-filter
                [courseRatios]="courseRatios"
                [mondayExists]="mondayExists"
                [tuesdayExists]="tuesdayExists"
                [wednesdayExists]="wednesdayExists"
                [thursdayExists]="thursdayExists"
                [fridayExists]="fridayExists"
                [saturdayExists]="saturdayExists"
                [sundayExists]="sundayExists"
                [instructors]="instructors"
                [startTimes]="startTimes"
                [isSearchDisabled]="isSearchDisabled"></app-course-list-filter>
              <app-course-list-student-details-filter *ngFor="let student of students" [student]="student" [isSearchDisabled]="isSearchDisabled" [showStudent]="false"></app-course-list-student-details-filter>

            </div>
          </aside>			</div>


        <div class="span-index-courses">
          <div class="wgt wgt-index-courses">
            <div class="row">
              <app-course-list-course class="span-card" *ngFor="let course of courseResults" [hidden]="!(course.show && course.availableToBook)" [courseResult]="course" [multipleMode]="multipleMode" [mode]="'new'" [courseResults]="courseResults"></app-course-list-course>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>		</main>
</div>
<app-footer></app-footer>
