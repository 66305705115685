import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SelectLocationService} from './select-location.service';
import {VenueOptionsValidator} from '../../_helpers/venue_options_validator';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {GeocodingService} from '../../geocoding.service';
import {Subscription} from 'rxjs';
import {AlertService} from '../../alert';
import {NgxSpinnerService} from 'ngx-spinner';
import {Loader} from '@googlemaps/js-api-loader';
import {VenueSearchDistance} from '../../_model/venue-search-distance';
import {ApiService} from '../../api.service';
import {ApplicationSettingsService} from '../../application-settings.service';

export class VenueOptions {
  longitude: number;
  latitude: number;
  distance: number;
  address: string;
  venueId: string;
}

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent implements OnInit, OnDestroy {

  @Input() selectVenueForm: UntypedFormGroup;
  @Input() title: string;
  @Input() postCode: string;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  options = {
    componentRestrictions : {
      country: ['UK']
    }
  };

  venueOptions = new VenueOptions();
  submitted = false;
  googleLoaded = false;
  venueSearchDistances: VenueSearchDistance[] = [];
  distanceType: string = null;

  titleSubscription: Subscription;

  constructor(private el: ElementRef,
              private selectLocationService: SelectLocationService,
              private formBuilder: UntypedFormBuilder,
              private geocodingService: GeocodingService,
              private changeDetectorRef: ChangeDetectorRef,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private applicationSettings: ApplicationSettingsService,
              private apiService: ApiService) {
    this.googleLoaded = false;
  }

  ngOnInit(): void {
    this.titleSubscription = this.selectLocationService.currentTitle.subscribe(message => this.title = message);
    this.spinner.show();

    this.apiService.getVenueSearchDistances().subscribe(distances => this.venueSearchDistances = distances);

    if (this.applicationSettings.distanceMeasurement === 'metric') {
      this.distanceType = 'kilometers';
    } else {
      this.distanceType = 'miles';
    }

    this.geocodingService.loader.load().then(() => {
      this.spinner.hide();
      this.googleLoaded = true;
      this.geocodingService.init();
    }).catch(e => {
      this.spinner.hide();
      console.error('Failed to load google maps: ' + e);
      this.alertService.error('Failed to load google maps: ' + e);
    });

    this.selectVenueForm = this.formBuilder.group({
      location_within: ['', Validators.required],
      location_postcode: ['']
    }, {
      validator: VenueOptionsValidator(this.venueOptions, 'location_postcode', 'location_within')
    });

    this.venueOptions.distance = 5;

    this.selectVenueForm.get('location_within').setValue(this.venueOptions.distance);

    if (this.postCode != null && this.postCode !== '') {
      this.geocodingService.codeAddress(this.postCode).subscribe(results => {
        if (results.length === 1 && results[0].geometry != null) {
          this.venueOptions.latitude = results[0].geometry.location.lat();
          this.venueOptions.longitude = results[0].geometry.location.lng();
          this.venueOptions.address = this.postCode;

          this.selectVenueForm.get('location_postcode').setValue(this.postCode);

          this.selectVenueForm.updateValueAndValidity();
          this.changeDetectorRef.detectChanges();
        }
      }, error => {
        console.error('Failed to geocode, so ignoring [' + this.postCode + ']: ' + error);
      });
    }
  }

  ngOnDestroy() {
    if (this.titleSubscription != null) {
      this.titleSubscription.unsubscribe();
    }
  }

  public handleAddressChange(address: any) {
    if (address.geometry == null || address.geometry.location == null) {
      this.alertService.error('Failed to get location from address');
      return;
    }

    this.venueOptions.latitude = address.geometry.location.lat();
    this.venueOptions.longitude = address.geometry.location.lng();
    this.venueOptions.address = address.formatted_address;

    this.selectVenueForm.updateValueAndValidity();
  }

  getCurrentLocation() {
    this.alertService.clear();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.venueOptions.latitude = position.coords.latitude;
        this.venueOptions.longitude = position.coords.longitude;
        this.venueOptions.address = 'Current location';

        this.selectVenueForm.get('location_postcode').setValue(this.venueOptions.address);

        this.selectVenueForm.controls.location_postcode.setValue(this.venueOptions.address);
        this.selectVenueForm.controls.location_postcode.disable();

        this.selectVenueForm.updateValueAndValidity();
        if (!this.selectVenueForm.invalid) {
          this.onVenueSelected();
        }
      }, err => {
        console.log('Failed to get location [' + err.code + ']: ' + err.message);
        this.alertService.error('Failed to get current location');
      });
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  }

  clearAddress() {
    this.venueOptions.latitude = null;
    this.venueOptions.longitude = null;
    this.venueOptions.address = '';

    this.selectVenueForm.updateValueAndValidity();
  }

  onVenueSelected() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.selectVenueForm.invalid) {
      return;
    }

    this.venueOptions.distance = this.selectVenueForm.get('location_within').value;

    this.selectLocationService.setVenueOptions(this.venueOptions);
  }

  canSearch(): boolean {
    return this.selectVenueForm.invalid && (this.selectVenueForm.get('location_postcode').value.length > 0);
  }

  searchForLocation(): void {
    this.geocodingService.codeAddress(this.selectVenueForm.get('location_postcode').value).subscribe(results => {
      if (results.length > 0) {
        const result = results[0];

        this.venueOptions.latitude = result.geometry.location.lat();
        this.venueOptions.longitude = result.geometry.location.lng();
        this.venueOptions.address = result.formatted_address;

        this.selectVenueForm.updateValueAndValidity();
        this.onVenueSelected();
      } else {
        this.alertService.error('No location results found');
      }
    }, error => {
      if (error === 'ZERO_RESULTS') {
        this.alertService.error('No location results found');
      } else {
        this.alertService.error('Failed to find location [' + error + ']');
      }
    });
  }

  get f() { return this.selectVenueForm.controls; }
}
