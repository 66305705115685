<app-dashboard-header></app-dashboard-header>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="2"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main">

              <app-dashboard-confirmation></app-dashboard-confirmation>

              <div class="lead">
                <h1 class="title">Your Bookings</h1>

                <div class="row row-lead">
                  <div class="span-subtitle">
                    <p>Your upcoming lessons can be found below. Please carefully check the date and time to ensure that your sessions aren’t missed.</p>
                  </div>
                  <div class="span-button">
                    <a [routerLink]="['/dashboard_booking_start']" [queryParams]="{referrer: 'dashboard_bookings'}" class="btn btn-block btn-blue">make a new booking</a>
                  </div>
                </div>
              </div>

              <span *ngIf="enrolmentsLoaded === false">
              <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
              </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
                </div>
                </span>

              <div class="wgt wgt-dashboard wgt-offers" *ngIf="provisionalEnrolments != null">
                <div class="card" *ngFor="let provisional of provisionalEnrolments">

                  <app-dashboard-provisional [provisional]="provisional" [provisionalEnrolments]="provisionalEnrolments"></app-dashboard-provisional>

                </div>
              </div>
              <div class="wgt wgt-dashboard wgt-bookings-current" *ngIf="futureEnrolments != null">
                <div class="card">

                  <div class="item-single" *ngFor="let enrolment of futureEnrolments">
                    <div class="container">
                      <div class="row">

                        <div class="span-graphic" *ngIf="enrolment.course.instructorHasPhoto">
                          <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(enrolment.course.instructorId)}"></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_{{enrolment.enrolmentId}}">{{enrolment.student.givenName}} {{enrolment.student.familyName}}'s</li>
                                <li> Next Lesson</li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline" *ngIf="!enrolment.viewBookings">
                                <li id="term_date_{{enrolment.enrolmentId}}">{{enrolment.nextClass.startDate | worldDate: 'EEEE d MMM yyyy' : enrolment.nextClass.startDateOffset}}</li>
                                <li> at </li>
                                <li id="term_time_{{enrolment.enrolmentId}}">{{enrolment.nextClass.startDate | worldDate: 'h:mma' : enrolment.nextClass.startDateOffset}}</li>
                                <li class="btn-more-wrap" *ngIf="enrolment.bookings.length > 1">
                                  <button
                                    class="btn btn-more btn-green"
                                    (click)="enrolment.viewBookings = !enrolment.viewBookings"
                                    data-toggle="collapsable"
                                    type="button"
                                  >
                                    view <span id="current_bookings_number_{{enrolment.enrolmentId}}">{{enrolment.bookings.length - 1}}</span> more
                                  </button>
                                </li>
                              </ul>
                              <div id="other_bookings_{{enrolment.enrolmentId}}" class="hidden-info toggler" [ngClass]="enrolment.viewBookings ? 'active' : ''">
                                <ul class="list-inline" *ngFor="let booking of enrolment.bookings; let i = index" [ngStyle]="{'text-decoration': (booking.attendance === 'NOTATTENDING') ? 'line-through' : ''}">
                                  <li id="term_date_{{booking.bookingId}}">{{booking.bookedClass.startDate | worldDate: 'EEEE d MMM yyyy' : booking.bookedClass.startDateOffset}}</li>
                                  <li> at </li>
                                  <li id="term_time_{{booking.bookingId}}">{{booking.bookedClass.startDate | worldDate: 'h:mma' : booking.bookedClass.startDateOffset}}</li>
                                  <li class="btn-more-wrap" *ngIf="i===0">
                                    <button
                                      class="btn btn-more btn-green"
                                      (click)="enrolment.viewBookings = !enrolment.viewBookings"
                                      data-toggle="collapsable"
                                      type="button"
                                    >
                                      hide
                                    </button>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <ul class="list-inline">
                            <li id="student_sklil_{{enrolment.enrolmentId}}" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programLevelName}}</li>
                            <li> with </li>
                            <li id="student_teacher_{{enrolment.enrolmentId}}">{{enrolment.nextClass.actualInstructor.givenName}}</li>
                            <li> at </li>
                            <li id="term_location_{{enrolment.enrolmentId}}">{{enrolment.course.venueName}}
                            </li>
                                <li id="map-icon-{{enrolment.enrolmentId}}" class="view-map-icon" *ngIf="venueAddressMap.has(enrolment.course.venueId) && ((venueAddressMap.get(enrolment.course.venueId).latitude != null && venueAddressMap.get(enrolment.course.venueId).longitude != null) || (enrolment.course.venuePlaceId != null && enrolment.course.venuePlaceId !== ''))">
                                  <a class="mapLink" (click)="navigateToMap(enrolment.course.venueId, enrolment.course.venuePlaceId)">
                                  <svg class="map-icon">
                                  <title>View on map</title>
                                  <use xlink:href="./assets/sprite/spritemap.svg#sprite-map"></use>
                                  </svg></a></li>
                          </ul>
                              <ul class="list-inline" *ngIf="enrolment.enrolmentType === 'MULTIPLE_TERMS'">
                                <li>Finishes </li>
                                <li id="finises_text">{{enrolment.enrolmentLastTermEndDate | worldDate: 'MMMM, yyyy'}}
                                <span *ngIf="enrolment.terms.length > 0">(<span *ngFor="let term of enrolment.terms; let i=index;"><span *ngIf="i>0">, </span>{{term.description}}</span>)
                                </span>
                                </li>
                              </ul>
                          </li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button" *ngIf="enrolment.nextClass != null && enrolment.enrolmentStatus === 'CONFIRMED'">
                          <a *ngIf="enrolment.hasPendingPayment" [routerLink]="[]" queryParamsHandling="preserve" class="btn btn-list" (click)="navigateToAccountSettings(enrolment)">
                          <svg class="exclamation-mark">
                            <title>There is an overdue payment associated with this booking</title>
                            <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                          </svg>
                          </a>
                          &nbsp;
                          <a *ngIf="enrolment != null" [routerLink]="['/dashboard_booking_manage',enrolment.enrolmentId]" class="btn btn-list" [queryParams]="{referrer: 'dashboard_bookings'}">
                            <span class="svg-icon dashboard-list"><svg class="svg-dashboard-list"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-list"></use></svg></span>						</a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div><div class="wgt wgt-dashboard wgt-bookings-past" *ngIf="pastEnrolments != null">
                <div class="card">

                  <h4 class="card-title">
                    <span id="past_bookings_number"><span *ngIf="pastEnrolmentsLoaded">{{pastEnrolments.length}}</span></span>
                    Past Bookings
                    <button
                      class="btn btn-toggler"
                      (click)="loadPastEnrolments()"
                      data-toggle="collapsable"
                      data-target="past_bookings_wrap"
                      type="button"
                      [ngClass]="viewPastBookings ? 'active' : ''"
                    >
                      <span class="svg-icon caret"><svg class="svg-caret"><use xlink:href="./assets/sprite/spritemap.svg#sprite-caret"></use></svg></span>			</button>
                  </h4>

                  <div class="past-bookings-wrap toggler" id="past_bookings_wrap" [ngClass]="viewPastBookings ? 'active' : ''">
                    <span *ngIf="pastEnrolmentsLoaded === false">
                    <div class="ph-item">
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-4"></div>
                          <div class="ph-col-8 empty"></div>
                          <div class="ph-col-6 big"></div>
                          <div class="ph-col-6 empty"></div>
                          <div class="ph-col-8"></div>
                          <div class="ph-col-4 empty"></div>
                          <div class="ph-col-8"></div>
                          <div class="ph-col-4 empty"></div>
                        </div>
                      </div>
                    </div>
                      <div class="ph-item">
                      <div class="ph-col-12">
                        <div class="ph-row">
                          <div class="ph-col-4"></div>
                          <div class="ph-col-8 empty"></div>
                          <div class="ph-col-6 big"></div>
                          <div class="ph-col-6 empty"></div>
                          <div class="ph-col-8"></div>
                          <div class="ph-col-4 empty"></div>
                          <div class="ph-col-8"></div>
                          <div class="ph-col-4 empty"></div>
                        </div>
                      </div>
                      </div>
                      </span>
                    <div class="item-single" *ngFor="let enrolment of pastEnrolments">
                      <div class="container">
                        <div class="row">

                          <div class="span-graphic" *ngIf="enrolment.course.instructorHasPhoto">
                            <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(enrolment.course.instructorId)}"></div>
                          </div>

                          <div class="span-content">
                            <ul class="list-content">
                              <li id="past_student_full_name_{{enrolment.enrolmentId}}">{{enrolment.student.givenName}} {{enrolment.student.familyName}}</li>
                              <li>
                                <ul class="list-inline">
                                  <li id="past_term_past_start_date_{{enrolment.enrolmentId}}">{{enrolment.startDate | worldDate: 'd MMM yyyy'}}</li>
                                  <li>-</li>
                                  <li id="past_term_past_end_date_{{enrolment.enrolmentId}}">{{enrolment.endDate | worldDate: 'd MMM yyyy'}}</li>
                                </ul>
                              </li>
                              <li>
                                <ul class="list-inline">
                                  <li id="past_student_sklil_{{enrolment.enrolmentId}}" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programLevelName}}</li>
                                  <li> with </li>
                                  <li id="past_student_teacher_{{enrolment.enrolmentId}}">{{enrolment.course.instructorGivenName}}</li>
                                  <li> on </li>
                                  <li id="past_term_days_{{enrolment.enrolmentId}}">{{enrolment.course.fullDayOfWeekNames}}</li>
                                  <li> at </li>
                                  <li id="past_term_location_{{enrolment.enrolmentId}}">{{enrolment.course.venueName}}
                                  </li>
                                </ul>
                                <ul class="list-inline" *ngIf="enrolment.enrolmentType === 'MULTIPLE_TERMS'">
                                  <li>Finished </li>
                                  <li id="finished_text">{{enrolment.enrolmentLastTermEndDate | worldDate: 'MMMM, yyyy'}}
                                    <span *ngIf="enrolment.terms.length > 0">
                                      (<span *ngFor="let term of enrolment.terms; let i=index;"><span *ngIf="i>0">, </span>{{term.description}}</span>)
                                    </span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
