<form  method="post" id="form_course_finder_dob" novalidate="novalidate" class="form-course-finder" [formGroup]="selectDobForm" (ngSubmit)="onDobSubmit()">
<div class="form-section current">

  <div id="panel_find_student_info" class="panel panel-find-student-info">

    <div class="span-title">
      <h2 class="entry-title">
        {{title}}		</h2>
    </div>

    <div class="row row-cards">


      <div class="span-card"><div class="card">
        <div class="span-select span-input span-4 required">
          <select
            type="select"
            required="required"
            class="input-select"
            formControlName="dob_day_0"
            [ngClass]="{ 'is-invalid': submitted && f.dob_day_0.errors }"
          >
            <option value="" disabled selected hidden>Day</option>
            <option value="1">
              1			</option>
            <option value="2">
              2			</option>
            <option value="3">
              3			</option>
            <option value="4">
              4			</option>
            <option value="5">
              5			</option>
            <option value="6">
              6			</option>
            <option value="7">
              7			</option>
            <option value="8">
              8			</option>
            <option value="9">
              9			</option>
            <option value="10">
              10			</option>
            <option value="11">
              11			</option>
            <option value="12">
              12			</option>
            <option value="13">
              13			</option>
            <option value="14">
              14			</option>
            <option value="15">
              15			</option>
            <option value="16">
              16			</option>
            <option value="17">
              17			</option>
            <option value="18">
              18			</option>
            <option value="19">
              19			</option>
            <option value="20">
              20			</option>
            <option value="21">
              21			</option>
            <option value="22">
              22			</option>
            <option value="23">
              23			</option>
            <option value="24">
              24			</option>
            <option value="25">
              25			</option>
            <option value="26">
              26			</option>
            <option value="27">
              27			</option>
            <option value="28">
              28			</option>
            <option value="29">
              29			</option>
            <option value="30">
              30			</option>
            <option value="31">
              31			</option>
          </select>
        </div>

        <div *ngIf="submitted && f.dob_day_0.errors" class="invalid-feedback">
          <div *ngIf="f.dob_day_0.errors.required">Day is required</div>
          <div *ngIf="f.dob_day_0.errors.dateOfBirth">Invalid date of birth</div>
        </div>

      </div></div><div class="span-card"><div class="card">
      <div class="span-select span-input span-4 required">
        <select
          type="select"
          required="required"
          class="input-select"
          formControlName="dob_month_0"
          [ngClass]="{ 'is-invalid': submitted && f.dob_month_0.errors }"
        >
          <option value="" disabled selected hidden>Month</option>
          <option value="1">
            1			</option>
          <option value="2">
            2			</option>
          <option value="3">
            3			</option>
          <option value="4">
            4			</option>
          <option value="5">
            5			</option>
          <option value="6">
            6			</option>
          <option value="7">
            7			</option>
          <option value="8">
            8			</option>
          <option value="9">
            9			</option>
          <option value="10">
            10			</option>
          <option value="11">
            11			</option>
          <option value="12">
            12			</option>
        </select>
      </div>

      <div *ngIf="submitted && f.dob_month_0.errors" class="invalid-feedback">
        <div *ngIf="f.dob_month_0.errors.required">Month is required</div>
      </div>

    </div></div><div class="span-card"><div class="card">
      <div class="span-select span-input span-4 row-checkmark-last required">
        <select
          type="select"
          required="required"
          class="input-select"
          formControlName="dob_year_0"
          [ngClass]="{ 'is-invalid': submitted && f.dob_year_0.errors }"
        >
          <option value="" disabled selected hidden>Year</option>
          <option *ngFor="let dobYear of dobYears" value="{{dobYear}}">{{dobYear}}</option>
        </select>
      </div>

      <div *ngIf="submitted && f.dob_year_0.errors" class="invalid-feedback">
        <div *ngIf="f.dob_year_0.errors.required">Year is required</div>
      </div>

    </div></div>
    </div>
    <div class="row">
      <button type="submit" class="btn btn-block btn-red" [ngClass]="this.selectDobForm.invalid ? 'disabled' : ''" data-next="2">
        continue
      </button>
    </div>

  </div>
</div>
</form>
