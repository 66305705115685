import {StudentCreditPack} from './student-credit-pack';
import {Course} from './course';
import {convertObjectToDate} from '../_helpers/date_helper';
import {Program} from './program';
import {Class} from './class';

export class CreditPackResponse {
  studentCreditPack: StudentCreditPack;
  lastEnrolledClass: Class;
  futureClassAvailable: boolean;
  lastEnrolledCourse: Course;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.studentCreditPack.purchaseDate = convertObjectToDate(this.studentCreditPack.purchaseDate);
    this.studentCreditPack.expiryDate = convertObjectToDate(this.studentCreditPack.expiryDate);
    if (this.lastEnrolledClass != null) {
      this.lastEnrolledClass.startDate = convertObjectToDate(this.lastEnrolledClass.startDate);
      this.lastEnrolledClass.endDate = convertObjectToDate(this.lastEnrolledClass.endDate);
    }
    if (this.lastEnrolledCourse != null) {
      this.lastEnrolledCourse.startTimeDate = convertObjectToDate(this.lastEnrolledCourse.startTimeDate);
      this.lastEnrolledCourse.endTimeDate = convertObjectToDate(this.lastEnrolledCourse.endTimeDate);
    }
    this.studentCreditPack.student.dateOfBirth = convertObjectToDate(this.studentCreditPack.student.dateOfBirth);
  }
}
