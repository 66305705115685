<form method="post" id="form_checkout_personal_details" novalidate="novalidate" class="form-checkout parsley" [formGroup]="personalDetailsForm" (ngSubmit)="onPersonalDetailsSubmit()">
  <div class="form-section current">

    <h3 class="title-label">
      Personal Details
    </h3>

    <div class="row row-details">


      <div class="span-input span-select span-short required">
        <select
          id="title"
          type="select"
          name="title"
          formControlName="titleSelect"
          class="input-select"
          required="required"
          [ngClass]="getPersonalDetailsControl('titleSelect').errors ? '' : 'parsley-success'"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Title'"
        >
          <option value="" disabled selected hidden>title</option>
          <option value="Dr.">Dr.</option>
          <option value="Lady">Lady</option>
          <option value="Lord">Lord</option>
          <option value="Master">Master</option>
          <option value="Miss">Miss</option>
          <option value="Mr.">Mr.</option>
          <option value="Mrs.">Mrs.</option>
          <option value="Ms.">Ms.</option>
          <option value="Prof.">Prof.</option>
          <option value="Rev.">Rev.</option>
          <option value="Sir">Sir</option>
        </select>
        <i class="asterisk hide-on-error" *ngIf="getPersonalDetailsControl('titleSelect').errors">* required</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

      <div class="span-input span-text required">
        <input
          id="first_name"
          type="text"
          name="first_name"
          formControlName="firstNameInput"
          class="input-text"
          required="required"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'First Name'"
        />
        <label for="first_name" class="floating-label">
          first name
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>
      <div class="span-input span-text required" id="input_last_name">
        <input
          id="last_name"
          type="text"
          name="last_name"
          formControlName="familyNameInput"
          class="input-text"
          required="required"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Last Name'"
        />
        <label for="last_name" class="floating-label">
          surname
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

    </div><h3 class="title-label">
    Account Setup
  </h3>

    <div class="row row-account-setup">

      <div class="span-input span-text required">
        <input
          type="email"
          id="emailInput"
          name="emailInput"
          required="required"
          class="input-text"
          formControlName="emailInput"
          (keyup)="checkConfirmEmailDisplay()"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Email'"
          (input)="personalDetailsForm.controls.emailInput.setValue(personalDetailsForm.controls.emailInput.value.toLowerCase())"
        />
        <label for="emailInput" class="floating-label">
          email
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

      <div class="span-input span-text required" [hidden]="!showConfirmationEmail">
        <input
          type="email"
          id="confirmEmailInput"
          name="confirmEmailInput"
          required="required"
          class="input-text"
          formControlName="confirmEmailInput"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Confirmation Email'"
          (input)="personalDetailsForm.controls.confirmEmailInput.setValue(personalDetailsForm.controls.confirmEmailInput.value.toLowerCase())"
        />
        <label for="confirmEmailInput" class="floating-label">
          confirm email
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

      <div class="span-input span-text required">
        <input
          pwnedPasswordValidator
          pwnedPasswordMinimumOccurrenceForError="1"
          pwnedPasswordApi="https://api.pwnedpasswords.com/range/"
          pwnedPasswordApiCallDebounceTime="400"
          [type]="fieldTextType ? 'text' : 'password'"
          type="password"
          id="password"
          name="password"
          class="input-text"
          minlength="6"
          #passwordInput
          (keyup)=passwordEntered(passwordInput.value)
          formControlName="passwordInput"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Password'"
        />
        <label
          for="password"
          class="floating-label">
          password
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <button *ngIf="!fieldTextType" type="button" id="show_password" class="show-password" (click)="fieldTextType=true">Show</button>
        <button *ngIf="fieldTextType" type="button" id="hide_password" class="show-password" (click)="fieldTextType=false">Hide</button>

        <meter max="4" id="password-strength-meter" value="{{passwordStrength}}"></meter>

        <ul class="parsley-errors-list filled">
        </ul>
        <ul *ngIf="personalDetailsForm.get('passwordInput').errors != null && personalDetailsForm.get('passwordInput').errors.pwnedPasswordOccurrence" class="parsley-errors-list filled">
          This password has previously appeared in a data breach and should never be used.
        </ul>
      </div>

      <div class="span-input span-text required">
        <input
          type="password"
          id="confirm_password"
          name="confirm_password"
          required="required"
          class="input-text"
          formControlName="confirmPasswordInput"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Password Confirmation'"
          [requireChecked]="true"
        />
        <label
          for="password"
          class="floating-label">
          confirm password
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

      <div class="span-input span-checkbox">
        <label
          for="optInMarketingEmail"
          class="input-check checkbox">
          <input
            id="optInMarketingEmail"
            name="optInMarketingEmail"
            type="checkbox"
            class="input-checkbox"
            formControlName="optInMarketingEmailCheckbox"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Marketing Opt-in'"
          />
          <span>
			Opt in marketing email
		</span>
        </label>
      </div>

      <div class="span-input span-checkbox">
        <label
          for="optInMarketingSms"
          class="input-check checkbox">
          <input
            id="optInMarketingSms"
            name="optInMarketingSms"
            type="checkbox"
            class="input-checkbox"
            formControlName="optInMarketingSmsCheckbox"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Marketing SMS Opt-in'"
          />
          <span>
			Opt in marketing SMS
		</span>
        </label>
      </div>


      <div class="span-input span-checkbox required" *ngIf="privacyPolicy != null && privacyPolicy.valid">
        <label
          for="terms"
          class="input-check checkbox">
          <input
            id="terms"
            name="terms"
            type="checkbox"
            required="required"
            class="input-checkbox"
            value="terms"
            formControlName="termsCheckbox"
            appParsleyValidation
            [submitted]="submitted"
            [elementName]="'Terms & Conditions'"
            [requireChecked]="true"
          />
          <span>
			I accept the  <a target="_blank" [routerLink]="['/privacy_policy']">privacy policy</a>.
		</span>
          <i class="asterisk">*</i>
          <i class="checkmark"></i>
        </label>
        <ul class="parsley-errors-list filled">
        </ul>
      </div>

    </div>
    <button type="submit" [ngClass]="this.personalDetailsForm.invalid || checkingIfUserExists ? 'disabled' : ''" class="btn btn-block btn-red">
      Next
    </button>

  </div>
</form>
