import Rollbar from 'rollbar';

import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {environment} from '../environments/environment';
import {VERSION} from '../environments/version';

const rollbarConfig = {
  accessToken: '31b0389f1f8840888b5af6dedf982ba1',
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: ['Error: Uncaught (in promise): Event: {"isTrusted":true}', 'Failed to receive response from server', 'Cannot read property of undefined/null expression window.webkit.messageHandlers.selectedDebugHandler', 'Uncaught (in promise): Event: {"isTrusted":true}'],
  payload: {
    environment: environment.name,
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: VERSION.raw,
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
