import {StudentAchievement} from './student-achievement';

export class LevelAchievement extends StudentAchievement {
  levelName: string;

  constructor(data?: any) {
    super(data);
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
