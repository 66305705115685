import {convertObjectToDate} from '../_helpers/date_helper';

export class AccountBillingItem {
  id: string;
  itemType: string;
  documentNumber: string;
  description: string;
  transactionDate: Date;
  fee: number;
  discount: number;
  invoiceFee: number;
  credit: number;
  refund: number;
  amountPaid: number;
  balance: number;
  paymentMethod: string;
  operator: string;
  accountId: string;
  accountName: string;
  currencyCode: string;
  paymentStatus: string;
  paymentMethodSummary: string;
  pendingPaymentDate: Date;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.transactionDate = convertObjectToDate(this.transactionDate);
    this.pendingPaymentDate = convertObjectToDate(this.pendingPaymentDate);
  }
}
