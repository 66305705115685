import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PhoneNumberValidator} from '../../_helpers/phone_number_validator';
import {TelephoneValidator} from '../../_helpers/telephone_validator';
import {HowDidYouHearValidator} from '../../_helpers/how_did_you_hear_validator';
import {ApiService} from '../../api.service';
import {HowDidYouHearType} from '../../_model/how-did-you-hear-type';
import {BasketService} from '../basket.service';
import {LoggedInUser} from '../../_model/logged-in-user';
import {AuthService} from '../../auth-service';
import {formatNumber} from '../../_helpers/telephone_helper';
import {ApplicationSettingsService} from '../../application-settings.service';
import {getFormValidationErrors} from '../../_helpers/debug_helper';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {Address} from '../../_model/address';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.css']
})
export class CheckoutAddressComponent implements OnInit, OnDestroy {

  address: Address;

  addressForm: UntypedFormGroup;
  submitted = false;

  howDidYouHearTypes: HowDidYouHearType[];
  addressLinesActive = false;
  showHowDidYouHearOther = false;

  countryCode: string;

  creatingAccount = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  settingsSubscription: Subscription;
  addressSubscription: Subscription;

  constructor(private apiService: ApiService,
              private basketService: BasketService,
              private authService: AuthService,
              private formBuilder: UntypedFormBuilder,
              private applicationSettings: ApplicationSettingsService,
              private sessionService: SessionService) {
    this.address = sessionService.basket.createAccountRequest.address;
  }

  setTelephoneValidators() {
    let telephoneHomeValidator = null;
    let telephoneMobileValidator;

    if (this.countryCode != null) {
      telephoneHomeValidator = [PhoneNumberValidator(this.countryCode)];
      telephoneMobileValidator = [PhoneNumberValidator(this.countryCode), TelephoneValidator('telephoneHomeInput')];
    } else {
      telephoneMobileValidator = [TelephoneValidator('telephoneHomeInput')];
    }

    this.addressForm.get('telephoneHomeInput').setValidators(telephoneHomeValidator);
    this.addressForm.get('telephoneMobileInput').setValidators(telephoneMobileValidator);

    this.addressForm.get('telephoneHomeInput').updateValueAndValidity();
    this.addressForm.get('telephoneMobileInput').updateValueAndValidity();
    this.addressForm.updateValueAndValidity();
  }

  setHowDidYouHearValidator() {
    let howDidYouHearValidator = null;
    if (this.applicationSettings.enforceHowDidYouHear) {
      howDidYouHearValidator = Validators.required;
    }

    this.addressForm.get('howDidYouHearSelect').setValidators(howDidYouHearValidator);
    this.addressForm.get('telephoneHomeInput').updateValueAndValidity();
    this.addressForm.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.countryCode = this.applicationSettings.countryCode;

    this.addressForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')

      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: ['', Validators.required],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: ['', Validators.required],
      addressCountyInput: ['', Validators.required],
      addressCountryInput: ['', Validators.required],
      addressPostcodeInput: ['', Validators.required],
      telephoneHomeInput: [''],
      telephoneMobileInput: ['', [TelephoneValidator('telephoneHomeInput')]],
      howDidYouHearSelect: [''],
      postcodeResultsSelect: [''],
      howDidYouHearOtherInput: ['', HowDidYouHearValidator('howDidYouHearSelect')]
    });

    this.apiService.getHowDidYouHearAboutUsTypes(this.ngUnsubscribe).subscribe((types) => {
      this.howDidYouHearTypes = types;
    });

    this.addressSubscription = this.basketService.currentAddress.subscribe(address => {
      if (address != null && this.sessionService.basket != null && this.sessionService.basket.createAccountRequest != null) {
        this.sessionService.basket.createAccountRequest.address = address;
        this.address = address;
      }
    });

    this.addressForm.patchValue({
      telephoneHomeInput: this.sessionService.basket.createAccountRequest.telephoneHome,
      telephoneMobileInput: this.sessionService.basket.createAccountRequest.telephoneMobile,
      howDidYouHearSelect: this.sessionService.basket.createAccountRequest.howDidYouHearId,
      howDidYouHearOtherInput: this.sessionService.basket.createAccountRequest.howDidYouHearOther
    });

    this.setTelephoneValidators();
    this.setHowDidYouHearValidator();
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.settingsSubscription != null) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.addressSubscription != null) {
      this.addressSubscription.unsubscribe();
    }
  }

  getAddressControl(componentName: string) {
    if (this.addressForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.addressForm.get(componentName);
  }

  formatTelephone() {
    this.sessionService.basket.createAccountRequest.telephoneHome =
      formatNumber(this.addressForm.get('telephoneHomeInput').value, this.countryCode);
    this.addressForm.get('telephoneHomeInput').setValue(this.sessionService.basket.createAccountRequest.telephoneHome);
  }

  formatMobile() {
    this.sessionService.basket.createAccountRequest.telephoneMobile =
      formatNumber(this.addressForm.get('telephoneMobileInput').value, this.countryCode);
    this.addressForm.get('telephoneMobileInput').setValue(this.sessionService.basket.createAccountRequest.telephoneMobile);
  }

  howDidYouHearChange() {
    this.showHowDidYouHearOther = this.addressForm.get('howDidYouHearSelect').value === 'other';
  }

  onAddressSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addressForm.invalid || this.creatingAccount) {
      if (!this.addressLinesActive) {
        this.addressLinesActive = !!(this.submitted
          && (this.addressForm.controls.addressLine1Input.errors
            || this.addressForm.controls.addressCityInput.errors
            || this.addressForm.controls.addressCountyInput.errors
            || this.addressForm.controls.addressCountryInput.errors
            || this.addressForm.controls.addressPostcodeInput.errors));
      }
      return;
    }

    this.creatingAccount = true;

    this.sessionService.basket.createAccountRequest.telephoneHome = this.addressForm.get('telephoneHomeInput').value;
    this.sessionService.basket.createAccountRequest.telephoneMobile = this.addressForm.get('telephoneMobileInput').value;
    this.sessionService.basket.createAccountRequest.howDidYouHearId = this.addressForm.get('howDidYouHearSelect').value;
    this.sessionService.basket.createAccountRequest.howDidYouHearOther = null;

    if (this.addressForm.get('howDidYouHearSelect').value === 'other') {
      this.sessionService.basket.createAccountRequest.howDidYouHearOther = this.addressForm.get('howDidYouHearOtherInput').value;
    }

    this.sessionService.basket.createAccountRequest.address.addressLine1 = this.addressForm.get('addressLine1Input').value;
    this.sessionService.basket.createAccountRequest.address.addressLine2 = this.addressForm.get('addressLine2Input').value;
    this.sessionService.basket.createAccountRequest.address.addressLine3 = this.addressForm.get('addressLine3Input').value;
    this.sessionService.basket.createAccountRequest.address.locality = this.addressForm.get('addressCityInput').value;
    this.sessionService.basket.createAccountRequest.address.state = this.addressForm.get('addressCountyInput').value;
    this.sessionService.basket.createAccountRequest.address.country = this.addressForm.get('addressCountryInput').value;
    this.sessionService.basket.createAccountRequest.address.postCode = this.addressForm.get('addressPostcodeInput').value;

    this.sessionService.saveBasket();

    const createAccountRequest = this.sessionService.basket.getCreateAccountRequest();

    if (this.sessionService.basket.isProspect && !this.sessionService.basket.accountCreated) {
      this.apiService.convertProspectAccount(this.sessionService.basket.accountId, createAccountRequest).subscribe(() => {
        this.sessionService.basket.isProspect = false;
        this.sessionService.saveBasket();

        if (!this.sessionService.basket.studentsCreated) {
          this.basketService.setStepNumber(3);
        } else {
          this.basketService.setStepNumber(4);
        }
      }, () => {
        this.creatingAccount = false;
      });
    } else {
      this.apiService.createAccount(createAccountRequest).subscribe((createAccountResult) => {
        createAccountResult.accountResults.forEach(result => {
          this.sessionService.basket.accountId = result.accountId;
        });

        this.sessionService.basket.accountCreated = true;
        this.sessionService.saveBasket();

        const loggedInUser = new LoggedInUser();

        loggedInUser.personId = createAccountResult.personId;
        loggedInUser.givenName = createAccountResult.givenName;
        loggedInUser.familyName = createAccountResult.familyName;
        loggedInUser.isStudent = false;

        this.basketService.setLoggedInPerson(loggedInUser);

        this.authService.login();
      }, () => {
        this.creatingAccount = false;
      });
    }
  }

  getSubmitText(): string {
    if (this.sessionService.basket.isProspect && this.sessionService.basket.studentsCreated && !this.sessionService.basket.accountCreated) {
      return 'Continue to Payment';
    } else if (this.sessionService.basket.isProspect && !this.sessionService.basket.studentsCreated && !this.sessionService.basket.accountCreated) {
      return 'Continue';
    } else {
      return 'Create Account and Login';
    }
  }
}
