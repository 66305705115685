import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {WaitingListEntry} from '../../_model/waiting-list-entry';
import {WaitingListResult} from '../../_model/waiting-list-result';
import {WaitingListSchedule} from '../../_model/waiting-list-schedule';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {AlertService} from '../../alert';

@Component({
  selector: 'app-dashboard-waiting-list',
  templateUrl: './dashboard-waiting-list.component.html',
  styleUrls: ['./dashboard-waiting-list.component.scss']
})
export class DashboardWaitingListComponent implements OnInit, OnDestroy {

  waitingListResults: WaitingListResult[];
  termNames: string[];
  runningPlacementAction = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  waitingListsLoaded: boolean;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private alertService: AlertService,
              private authService: AuthService,
              private apiService: ApiService) {
    this.waitingListsLoaded = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Waiting List');

    this.loadWaitingListEntries();

    this.renderer.addClass(document.body, 'dashboard-waiting-list');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-waiting-list');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  loadWaitingListEntries() {
    this.termNames = [];
    this.apiService.getWaitingListEntries(this.ngUnsubscribe).subscribe(waitingListResults => {
      waitingListResults.forEach(result => {
        result.schedules.forEach(schedule => {
          if (this.termNames.indexOf(schedule.termDescription) === -1) {
            this.termNames.push(schedule.termDescription);
          }
        });
      });
      this.waitingListResults = waitingListResults;
      this.waitingListsLoaded = true;
    });
  }

  getWaitingListStatus(waitingListEntry: WaitingListEntry): string {
    let status = 'pending';
    for (const entry of waitingListEntry.waitingListPlacements) {
      if (entry.status === 'open') {
        status = 'offer sent';
      }
    }

    return status;
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  getTermNames(): string {
    return this.termNames.join(', ');
  }

  removeWaitingListAvailability(waitingList: WaitingListResult, schedule: WaitingListSchedule, event: any) {
    event.target.disabled = true;
    this.apiService.removeWaitingListAvailability(waitingList.waitingListEntry.id, schedule.id, schedule.termId).subscribe(() => {
      waitingList.schedules.splice(waitingList.schedules.indexOf(schedule), 1);
      event.target.disabled = false;
    });
  }

  hasPlacement(waitingListEntry: WaitingListResult): boolean {
    for (const entry of waitingListEntry.waitingListEntry.waitingListExpiries) {
      if (entry.status === 'open') {
        return true;
      }
    }
    return false;
  }

  placementExpiry(waitingListEntry: WaitingListResult): Date {
    for (const entry of waitingListEntry.waitingListEntry.waitingListExpiries) {
      if (entry.status === 'open') {
        return entry.expiry;
      }
    }
    return null;
  }

  keepWaitingListPlacement(waitingListEntry: WaitingListResult) {
    this.runningPlacementAction = true;
    this.alertService.clear();
    for (const entry of waitingListEntry.waitingListEntry.waitingListExpiries) {
      if (entry.status === 'open') {
        this.apiService.keepWaitingListExpiry(entry.waitingListExpiryId, this.ngUnsubscribe).subscribe(() => {
          this.waitingListResults = [];
          this.alertService.success('Waiting list entry kept');
          this.loadWaitingListEntries();
          this.runningPlacementAction = false;
        }, () => {
          this.runningPlacementAction = false;
        });
      }
    }
  }
}
