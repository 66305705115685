export function getCardType(cardType): string {
  if (cardType === 'amex') {
    return 'AMEX';
    // eslint-disable-next-line eqeqeq
  } else if (cardType == 'diners_club_catre_blanche' || cardType == 'diners_club_international') {
    return 'DINERSCLUB';
  } else if (cardType === 'mastercard') {
    return 'MASTERCARD';
  } else if (cardType === 'discover') {
    return 'DISCOVER';
  } else if (cardType === 'jcb') {
    return 'JCB';
  } else if (cardType === 'laster') {
    return 'LASER';
  } else if (cardType === 'maestro') {
    return 'MAESTRO';
  } else if (cardType === 'visa' || cardType === 'visa_electron') {
    return 'VISA';
  }

  return null;
}
