import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BasketStudent} from '../../_model/basket-student';
import {DateOfBirthControlValidator} from '../../_helpers/date_of_birth.validator';
import {ApiService} from '../../api.service';
import {LoggedInUser} from '../../_model/logged-in-user';
import {CreateStudentRequest} from '../../_model/create-student-request';
import {BasketService} from '../basket.service';
import {AccountContact} from '../../_model/account-contact';
import {UserService} from '../../user-service';
import {AuthService} from '../../auth-service';
import {PaymentMethod} from '../../_model/payment-method';
import {AbandonedCartRequest} from '../../_model/abandoned-cart-request';
import {Subject} from 'rxjs';
import {convertDateToServerTimezone} from '../../_helpers/date_helper';
import {ApplicationSettingsService} from '../../application-settings.service';
import {SessionService} from '../../session.service';
import {AlertService} from '../../alert';
import moment from 'moment';
import {GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-checkout-students',
  templateUrl: './checkout-students.component.html',
  styleUrls: ['./checkout-students.component.css']
})
export class CheckoutStudentsComponent implements OnInit, OnDestroy {

  @Input() loggedInPerson: LoggedInUser;

  students: BasketStudent[];

  studentsForm: UntypedFormGroup;
  submitted = false;
  submitting = false;

  accountContacts: AccountContact[];
  dobYears: number[];

  loadedStudents = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
              private basketService: BasketService,
              private userService: UserService,
              private authService: AuthService,
              private applicationSettings: ApplicationSettingsService,
              private alertService: AlertService,
              private formBuilder: UntypedFormBuilder,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.students = this.sessionService.basket.students;
    this.studentsForm = this.formBuilder.group({});

    this.dobYears = new Array<number>();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= (currentYear - 100); i--) {
      this.dobYears.push(i);
    }

    // load students
    this.loadStudents();
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  loadStudents(): void {
    this.accountContacts = [];
    this.userService.getLoggedInStudentAccountContacts().subscribe(students => {
      this.accountContacts = students;

      this.accountContacts.forEach(student => {
        this.apiService.getStudent(student.id, this.ngUnsubscribe).subscribe(levels => {
          student.levels = levels.levels;
        });
      });
    }, () => {}, () => {
      this.apiService.getLoggedInUserContacts(this.ngUnsubscribe).subscribe(contacts => {
        for (const contact of contacts) {
          this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
            contact.familyName, contact.gender, contact.dob, contact.personId, 'contact');
        }
      }, () => {}, () => {
        // Finally, add the logged in user
        this.authService.getLoggedInUser().subscribe(loggedInUser => {
          let type = 'self';
          if (loggedInUser.isStudent) {
            type = 'student';
          }

          if (!loggedInUser.isStudent) {
            this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(contact => {
              this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
                contact.familyName, contact.gender, contact.dob, contact.personId, type);
              this.sortContacts();
            }, () => {}, () => {
              this.loadStudentsIntoControls();
            });
          } else {
            this.sortContacts();
            this.loadStudentsIntoControls();
          }
        });
      });
    });
  }

  addContactIfNotFound(id: string, title: string, givenName: string,
                       familyName: string, gender: string, dateOfBirth: Date, entityId: string, type: string) {
    // Check to see if they are already a student
    let found = false;
    for (const accountContact of this.accountContacts) {
      if (entityId === accountContact.entityId) {
        found = true;
        break;
      }
    }

    if (!found) {
      const accountContact = new AccountContact();
      accountContact.type = type;
      accountContact.id = id;
      accountContact.givenName = givenName;
      accountContact.familyName = familyName;
      accountContact.entityId = entityId;
      accountContact.title = title;
      accountContact.gender = gender;
      accountContact.dateOfBirth = dateOfBirth;

      this.accountContacts.push(accountContact);
    }
  }

  sortContacts() {
    this.accountContacts = this.accountContacts.sort((t1, t2) => {
      const t1Name = t1.givenName + ' ' + t1.familyName;
      const t2Name = t2.givenName + ' ' + t2.familyName;
      if (t1Name > t2Name) {
        return 1;
      }
      if (t1Name < t2Name) {
        return -1;
      }
      return 0;
    });
  }

  loadStudentsIntoControls() {
    const totalStudents = this.sessionService.basket.students.length;
    this.sessionService.basket.students.forEach((courseStudent, index) => {
      const formControlFields = [];

      formControlFields.push({ name: 'whoIsSwimmingSelect' + courseStudent.id, control: new UntypedFormControl('', Validators.required) });
      formControlFields.push({name: 'studentTitleSelect' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentFirstNameInput' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentLastNameInput' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentGenderSelect' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentMedicalRadio' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentMedicalConditionsTextArea' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({name: 'studentDateOfBirthDaySelect' + courseStudent.id, control: new UntypedFormControl('')});
      formControlFields.push({
        name: 'studentDateOfBirthMonthSelect' + courseStudent.id, control:
          new UntypedFormControl('')
      });
      formControlFields.push({
        name: 'studentDateOfBirthYearSelect' + courseStudent.id, control:
          new UntypedFormControl('')
      });

      formControlFields.forEach(f => this.studentsForm.addControl(f.name, f.control));

      this.studentsForm.get('studentMedicalRadio' + courseStudent.id).valueChanges.subscribe(checked => {
        this.setControlMedicalValidators(courseStudent, checked);
      });

      this.studentsForm.get('whoIsSwimmingSelect' + courseStudent.id).setValue(courseStudent.whoIsSwimming);

      if ((courseStudent.student.givenName == null || courseStudent.student.givenName === '')
        && courseStudent.student.studentName != null) {
        if (courseStudent.student.studentName.includes(' ')) {
          const studentSplit = courseStudent.student.studentName.split(' ', 1);
          courseStudent.student.givenName = studentSplit[0];
          courseStudent.student.familyName = studentSplit[1];
        } else {
          courseStudent.student.givenName = courseStudent.student.studentName;
        }
      }

      this.studentsForm.get('studentTitleSelect' + courseStudent.id).setValue(courseStudent.student.title);
      this.studentsForm.get('studentFirstNameInput' + courseStudent.id).setValue(courseStudent.student.givenName);
      this.studentsForm.get('studentLastNameInput' + courseStudent.id).setValue(courseStudent.student.familyName);
      this.studentsForm.get('studentGenderSelect' + courseStudent.id).setValue(courseStudent.student.gender);
      this.studentsForm.get('studentDateOfBirthDaySelect' + courseStudent.id).setValue(this.getDateOfBirthDay(courseStudent));
      this.studentsForm.get('studentDateOfBirthMonthSelect' + courseStudent.id).setValue(this.getDateOfBirthMonth(courseStudent));
      this.studentsForm.get('studentDateOfBirthYearSelect' + courseStudent.id).setValue(this.getDateOfBirthYear(courseStudent));
      this.studentsForm.get('studentMedicalRadio' + courseStudent.id).setValue(courseStudent.student.hasMedicalConditions);
      this.studentsForm.get('studentMedicalConditionsTextArea' + courseStudent.id).setValue(courseStudent.student.medicalConditions);

      this.setControlValidators(courseStudent);

      if (totalStudents === (index + 1)) {
        this.loadedStudents = true;
      }
    });
  }

  setControlValidators(courseStudent: BasketStudent): void {
    let whoIsSwimming = this.studentsForm.get('whoIsSwimmingSelect' + courseStudent.id).value;
    courseStudent.whoIsSwimming = whoIsSwimming;

    if (courseStudent.whoIsSwimming !== 'new') {
      for (const contact of this.accountContacts) {
        if (contact.id === courseStudent.whoIsSwimming) {
          whoIsSwimming = contact.type;
        }
      }
    }

    let validatorsRequired = null;
    if (whoIsSwimming === 'new') {
      validatorsRequired = [Validators.required];
    }

    let medicalRequired = null;
    if (whoIsSwimming === 'new' || whoIsSwimming === 'self' || whoIsSwimming === 'contact') {
      medicalRequired = [Validators.required];

    }

    this.studentsForm.get('studentTitleSelect' + courseStudent.id).setValidators(validatorsRequired);
    this.studentsForm.get('studentFirstNameInput' + courseStudent.id).setValidators(validatorsRequired);
    this.studentsForm.get('studentLastNameInput' + courseStudent.id).setValidators(validatorsRequired);
    this.studentsForm.get('studentGenderSelect' + courseStudent.id).setValidators(validatorsRequired);
    this.studentsForm.get('studentMedicalRadio' + courseStudent.id).setValidators(medicalRequired);

    let dobRequired = null;
    let dobRequiredYear = null;
    if (whoIsSwimming === 'new' || whoIsSwimming === 'self' || whoIsSwimming === 'contact') {
      dobRequired = [Validators.required];
      dobRequiredYear = [Validators.required, DateOfBirthControlValidator('studentDateOfBirthDaySelect' + courseStudent.id,
        'studentDateOfBirthMonthSelect' + courseStudent.id)];
    }

    this.studentsForm.get('studentDateOfBirthDaySelect' + courseStudent.id).setValidators(dobRequired);
    this.studentsForm.get('studentDateOfBirthMonthSelect' + courseStudent.id).setValidators(dobRequired);
    this.studentsForm.get('studentDateOfBirthYearSelect' + courseStudent.id).setValidators(dobRequiredYear);

    this.studentsForm.get('studentTitleSelect' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentFirstNameInput' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentLastNameInput' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentGenderSelect' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentMedicalRadio' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentDateOfBirthDaySelect' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentDateOfBirthMonthSelect' + courseStudent.id).updateValueAndValidity();
    this.studentsForm.get('studentDateOfBirthYearSelect' + courseStudent.id).updateValueAndValidity();
  }

  setControlMedicalValidators(courseStudent: BasketStudent, required: boolean): void {
    let medicalRequired = null;
    if (required) {
      medicalRequired = [Validators.required];
    }

    this.studentsForm.get('studentMedicalConditionsTextArea' + courseStudent.id).setValidators(medicalRequired);
    this.studentsForm.get('studentMedicalConditionsTextArea' + courseStudent.id).updateValueAndValidity();
  }

  medicalInputClicked(courseStudent: BasketStudent) {
    const hasMedical = this.studentsForm.get('studentMedicalRadio' + courseStudent.id).value;
    courseStudent.student.hasMedicalConditions = hasMedical;
    this.setControlMedicalValidators(courseStudent, hasMedical);
  }

  getDateOfBirthDay(courseStudent: BasketStudent): number {
    if (courseStudent.student.dateOfBirth == null) {
      return null;
    }

    return courseStudent.student.dateOfBirth.getDate();
  }

  getDateOfBirthMonth(courseStudent: BasketStudent): number {
    if (courseStudent.student.dateOfBirth == null) {
      return null;
    }

    return courseStudent.student.dateOfBirth.getMonth() + 1;
  }

  getDateOfBirthYear(courseStudent: BasketStudent): number {
    if (courseStudent.student.dateOfBirth == null) {
      return null;
    }
    return courseStudent.student.dateOfBirth.getFullYear();
  }

  getStudentControl(componentName: string) {
    if (this.studentsForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.studentsForm.get(componentName);
  }

  setStudentDateOfBirth(courseStudent: BasketStudent) {
    const dayValue = this.studentsForm.get('studentDateOfBirthDaySelect' + courseStudent.id).value;
    const monthValue = this.studentsForm.get('studentDateOfBirthMonthSelect' + courseStudent.id).value;
    const yearValue = this.studentsForm.get('studentDateOfBirthYearSelect' + courseStudent.id).value;

    if (dayValue == null || dayValue === ''
      || monthValue == null || monthValue === ''
      || yearValue == null || yearValue === '') {
      courseStudent.student.dateOfBirth = null;
      return;
    }

    const day = parseInt(dayValue, 10);
    const month = parseInt(monthValue, 10) - 1;
    const year = parseInt(yearValue, 10);

    const date = moment().set(
      {year,
        month,
        date: day
      });

    courseStudent.student.dateOfBirth = date.toDate();
  }

  onStudentsSubmit() {
    this.submitted = true;

    if (this.studentsForm.invalid) {
      return;
    }

    this.submitting = true;

    let dateOfBirthErrors = false;
    let numberOfNewStudents = this.sessionService.basket.students.length;

    // First set the DOBs and get a list of expected student responses
    this.sessionService.basket.students.forEach(courseStudent => {
      // set error on matchingControl if validation fails
      courseStudent.whoIsSwimming = this.studentsForm.get('whoIsSwimmingSelect' + courseStudent.id).value;
      let whoIsSwimming = courseStudent.whoIsSwimming;

      if (courseStudent.whoIsSwimming !== 'new') {
        for (const contact of this.accountContacts) {
          if (contact.id === courseStudent.whoIsSwimming) {
            whoIsSwimming = contact.type;
          }
        }
      }

      if (whoIsSwimming === 'new') {
        courseStudent.student.title = this.studentsForm.get('studentTitleSelect' + courseStudent.id).value;
        courseStudent.student.givenName = this.studentsForm.get('studentFirstNameInput' + courseStudent.id).value;
        courseStudent.student.familyName = this.studentsForm.get('studentLastNameInput' + courseStudent.id).value;
        courseStudent.student.gender = this.studentsForm.get('studentGenderSelect' + courseStudent.id).value;
      }

      if (whoIsSwimming === 'new' || whoIsSwimming === 'self' || whoIsSwimming === 'contact') {
        this.setStudentDateOfBirth(courseStudent);

        courseStudent.student.hasMedicalConditions = this.studentsForm.get('studentMedicalRadio' + courseStudent.id).value;
        if (courseStudent.student.hasMedicalConditions) {
          courseStudent.student.medicalConditions = this.studentsForm.get('studentMedicalConditionsTextArea' + courseStudent.id).value;
        } else {
          courseStudent.student.medicalConditions = null;
        }
      } else {
        // Need to set the DOB to ensure that the function to check if course is available for DOB works
        for (const contact of this.accountContacts) {
          if (contact.id === courseStudent.whoIsSwimming) {
            courseStudent.student.dateOfBirth = contact.dateOfBirth;
          }
        }
      }
    });

    this.sessionService.basket.students.forEach(courseStudent => {
      this.apiService.isCourseAvailableForDateOfBirth(courseStudent.course.id, courseStudent.student.dateOfBirth)
        .subscribe(isAvailable => {
        if (!isAvailable) {
          this.alertService.error('Course is not available for ' + courseStudent.student.givenName + '\'s age');
          dateOfBirthErrors = true;
        }
        numberOfNewStudents--;

        if (numberOfNewStudents === 0 && !dateOfBirthErrors) {
          this.createStudents();
        } else if (numberOfNewStudents === 0 && dateOfBirthErrors) {
          this.submitting = false;
        }
      }, () => {
        this.submitting = false;
      });
    });
  }

  createStudents(): void {
    const studentRequests = [];
    this.sessionService.basket.students.forEach(courseStudent => {
      const createStudentRequest = new CreateStudentRequest();

      if (courseStudent.whoIsSwimming === 'new') {
        createStudentRequest.whoIsSwimming = 'new';
      } else {
        for (const contact of this.accountContacts) {
          if (contact.id === courseStudent.whoIsSwimming) {
            createStudentRequest.whoIsSwimming = contact.type;
            if (contact.type === 'student') {
              courseStudent.student.studentId = contact.id;
            } else {
              createStudentRequest.personId = contact.entityId;
            }
          }
        }
      }

      createStudentRequest.id = courseStudent.id;
      createStudentRequest.studentId = courseStudent.student.studentId;
      createStudentRequest.programId = courseStudent.student.programId;
      createStudentRequest.programLevelId = courseStudent.student.programLevelId;
      createStudentRequest.accountId = this.sessionService.basket.accountId;
      createStudentRequest.dateOfBirth = convertDateToServerTimezone(courseStudent.student.dateOfBirth, this.applicationSettings.timezone);
      createStudentRequest.title = courseStudent.student.title;
      createStudentRequest.givenName = courseStudent.student.givenName;
      createStudentRequest.familyName = courseStudent.student.familyName;
      createStudentRequest.gender = courseStudent.student.gender;
      createStudentRequest.hasMedicalConditions = courseStudent.student.hasMedicalConditions;
      createStudentRequest.medicalConditions = courseStudent.student.medicalConditions;
      createStudentRequest.courseId = courseStudent.course.id;

      studentRequests.push(createStudentRequest);
    });

    this.apiService.createStudent(studentRequests).subscribe(response => {
      this.sessionService.basket.studentsCreated = true;

      for (const createStudentResponse of response) {
        for (const courseStudent of this.sessionService.basket.students) {
          if (createStudentResponse.id === courseStudent.id) {
            courseStudent.student.studentId = createStudentResponse.studentId;
          }
        }
      }

      const abandonedCartRequests = [];
      for (const courseStudent of this.sessionService.basket.students) {
        const abandonedCartRequest = new AbandonedCartRequest();
        abandonedCartRequest.studentId = courseStudent.student.studentId;
        abandonedCartRequest.accountId = this.sessionService.basket.accountId;
        abandonedCartRequest.courseId = courseStudent.course.id;
        abandonedCartRequest.programLevelId = courseStudent.student.programLevelId;

        abandonedCartRequests.push(abandonedCartRequest);
      }

      this.apiService.createAbandonedCart(abandonedCartRequests).subscribe(() => {});
      this.sessionService.basket.abandonedCartCreated = true;
      if (this.sessionService.basket.cardPaymentMethod == null) {
        this.sessionService.basket.cardPaymentMethod = new PaymentMethod();
      }

      this.sessionService.saveBasket();
      this.basketService.setStepNumber(4);

      this.googleAnalytics.accountCreated();
    }, () => {
      this.submitting = false;
    });
  }

  showCreateStudentInfo(courseStudent: BasketStudent) {
    if (courseStudent.whoIsSwimming === 'new') {
      return true;
    } else {
      for (const contact of this.accountContacts) {
        if (contact.id === courseStudent.whoIsSwimming) {
          return contact.type !== 'student';
        }
      }
    }

    return false;
  }

  isStudentDisabled(basketStudent: BasketStudent, accountContact: AccountContact) {
    if (accountContact == null || accountContact.levels == null) {
      return false;
    }
    for (const level of accountContact.levels) {
      if (level.program.id === basketStudent.program.id && level.verifiedDate != null) {
        return true;
      }
    }
    return false;
  }
}
