import { Component, OnInit } from '@angular/core';
import {ApplicationSettingsService} from '../application-settings.service';
import {FooterLink} from '../_model/footer-link';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentDate: Date;

  facebookUrl: string;
  twitterUrl: string;
  linkedInUrl: string;
  instagramUrl: string;
  tagline: string;

  footerLinksLeft: FooterLink[];
  footerLinksRight: FooterLink[];

  constructor(private applicationSettingsService: ApplicationSettingsService) {
    this.currentDate = new Date();
  }

  ngOnInit(): void {
    this.applicationSettingsService.socialLinks.forEach(link => {
      switch (link.network) {
        case 'Facebook':
          this.facebookUrl = link.url;
          break;
        case 'Twitter':
          this.twitterUrl = link.url;
          break;
        case 'LinkedIn':
          this.linkedInUrl = link.url;
          break;
        case 'Instagram':
          this.instagramUrl = link.url;
      }
    });

    this.footerLinksLeft = [];
    this.footerLinksRight = [];

    this.applicationSettingsService.footerLinks.forEach(link => {
      if (link.column === 1) {
        this.footerLinksLeft.push(link);
      } else {
        this.footerLinksRight.push(link);
      }
    });

    this.tagline = this.applicationSettingsService.tagline;
  }

}
