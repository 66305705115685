import {convertObjectToDate} from '../_helpers/date_helper';

export class Term {
  termId: string;
  bookingPeriodStart: Date;
  bookingPeriodEnd: Date;
  termType: string;
  description: string;
  numberOfMondays: number;
  numberOfTuesdays: number;
  numberOfWednesdays: number;
  numberOfThursdays: number;
  numberOfFridays: number;
  numberOfSaturdays: number;
  numberOfSundays: number;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.bookingPeriodStart = convertObjectToDate(this.bookingPeriodStart);
    this.bookingPeriodEnd = convertObjectToDate(this.bookingPeriodEnd);
  }
}
