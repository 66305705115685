import {UntypedFormControl} from '@angular/forms';

export function TermsAndConditionsValidator() {
  return (control: UntypedFormControl) => {
    const checked = control.value;
    if (!checked) {
      return {
        termsNotAgreed: true
      };
    }
    return null;
  };
}
