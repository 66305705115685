<li class="step step-{{stepNumber}}" [ngClass]="{ 'current': currentStepNumber === stepNumber, 'done': stepNumber < currentStepNumber }">
  <button type="submit" formaction="">
                      <span class="svg-icon step-details"><svg class="svg-step-details"><use
                        xlink:href="./assets/sprite/spritemap.svg#sprite-step-details"></use></svg></span><span
    class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use
    xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span> <span
    class="step-text">
								{{studentName}}							</span>
  </button>
</li>
