<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white; text-align: center" > Please wait&#x2026;<br><br> We are processing your transaction, do not navigate away from this page. </p></ngx-spinner>
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" (click)="goBack()">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              make a new booking					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/cart']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><header>
    <div class="header">
      <div class="container">
        <div class="row">

          <div class="span-logo ">
          </div>

          <div class="span-info" *ngIf="course != null && coursePriceList != null">
            <h2 class="title">
              {{course.venueName}}				</h2>
            <div class="info">
              {{coursePriceList.startDate | worldDate:'EEEE d MMM yyyy'}} @ {{course.startTimeDate | worldDate:'HH:mm'}} for {{numberOfLessons}} lessons
            </div>
            <div class="info" *ngIf="availableCredits != null && availableCredits > 0">
              {{availableCredits}} Credits Available
            </div>
          </div>

        </div>
      </div>
    </div>

  </header>
    <div class="wgt wgt-checkout">
      <div class="container">

        <form method="post" id="booking_credits_buy" novalidate="novalidate" class="form-dashboard" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">

          <div class="card">

            <div class="table-summary table-credits-buy" *ngIf="creditPacks != null">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                  <tr>
                    <th class="title-label" colspan="2">Credit Pack Options</th>
                    <th class="title-label" id="cp_cost" *ngIf="selectedCreditPackId != null && creditPacks != null">{{getSelectedCreditPack().invoice.totalAmount | currency:getSelectedCreditPack().invoice.currency:'symbol-narrow' }}</th>
                  </tr>
                  <tr *ngFor="let creditPack of creditPacks">
                    <td>
                      <label for="cp_options_{{creditPack.creditPack.sportsCreditPackId}}" class="input-check checkbox">
                        <input
                          id="cp_options_{{creditPack.creditPack.sportsCreditPackId}}"
                          name="creditPackRadio"
                          type="radio"
                          class="input-checkbox"
                          [value]="creditPack.creditPack.sportsCreditPackId"
                          formControlName="creditPackRadio"
                          (change)="doPreviewPurchaseCreditPack()"
                          appParsleyValidation
                          [submitted]="submitted"
                          [elementName]="'Credit Pack'"
                        />
                        <span>{{creditPack.creditPack.numberOfLessons}} x Lessons</span>
                      </label>
                    </td>
                    <td colspan="2">({{creditPack.invoice.totalAmount | currency:creditPack.invoice.currency:'symbol-narrow'}})</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <span *ngIf="venuePolicies != null">
              <div class="card-inner" *ngFor="let policy of venuePolicies">

                <div class="row row-policy-title">

                  <h3 class="span-heading title-label">
                    {{policy.title}}
                  </h3>

                  <div class="row row-policy-text" [innerHTML]="policy.policy">

                  </div>

                  <div class="span-input span-checkbox required">
                    <label
                      for="policy_{{policy.id}}"
                      class="input-check checkbox">
                      <input
                        id="policy_{{policy.id}}"
                        name="policy_{{policy.id}}"
                        type="checkbox"
                        required="required"
                        class="input-checkbox"
                        formControlName="policyCheckbox_{{policy.id}}"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="policy.title"
                        [requireChecked]="true"
                      />
                      <span>
                    I accept the terms above.
                  </span>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                    </label>
                    <ul class="parsley-errors-list filled"></ul>
                  </div>

                </div>
              </div>
           </span>

            <div class="card-inner" *ngIf="previewPurchaseCreditPack != null">
              <div class="table-summary table-checkout">

                <div class="table-responsive" *ngIf="previewPurchaseCreditPack != null">
                  <table class="table table-multiple">
                    <tbody>
                    <tr>
                      <th>{{getSelectedCreditPack().creditPack.numberOfLessons}} x Lessons
                        <span *ngIf="previewPurchaseCreditPack.totalDiscount > 0"><br>Total discount</span>
                      <th>
                            <span>{{previewPurchaseCreditPack.totalFee | currency:previewPurchaseCreditPack.currencyCode:'symbol-narrow'}}
                              <span *ngIf="previewPurchaseCreditPack.totalDiscount > 0"><br>{{previewPurchaseCreditPack.totalDiscount * -1 | currency:previewPurchaseCreditPack.currencyCode:'symbol-narrow'}}</span></span>
                      </th>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr *ngIf="previewPurchaseCreditPack.accountBalance > 0">
                      <th colspan="2">{{previewPurchaseCreditPack.accountBalance | currency:previewPurchaseCreditPack.currencyCode:'symbol-narrow'}}</th>
                    </tr>
                    <tr *ngIf="previewPurchaseCreditPack.accountBalance > 0">
                      <td colspan="2">Account Balance</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{previewPurchaseCreditPack.totalAmountWithBalance | currency:previewPurchaseCreditPack.currencyCode:'symbol-narrow'}}</th>
                    </tr>
                    <tr>
                      <td colspan="2">total</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-checkout-payment-voucher [voucherCode]="voucherCode" [voucherUsed]="previewPurchaseCreditPack.voucherUsed"></app-checkout-payment-voucher>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div></div>

            <div class="row row-payment-method row-border" [style.display]="!paymentRequired ? 'none' : '' ">

              <h3 class="span-heading title-label">
                Payment Method
              </h3>

              <div class="span-cards">
                <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>			<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>			<span class="svg-icon direct-debit"><svg class="svg-direct-debit"><use xlink:href="./assets/sprite/spritemap.svg#sprite-direct-debit"></use></svg></span>		</div>

              <app-checkout-payment-method-card *ngIf="course != null && accountId != null"
                                                [submitted]="submitted"
                                                [paymentForm]="paymentForm"
                                                [addressLinesActive]="addressLinesActive"
                                                [listType]="'Credit Pack'"
                                                [totalAmount]="previewPurchaseCreditPack.totalAmountWithBalance"
                                                [updateInvoices]="true"></app-checkout-payment-method-card>
            </div>

            <div class="span-input span-checkbox required" *ngIf="termsAndConditions != null">
              <label
                for="enrolmentTerms"
                class="input-check checkbox">
                <input
                  id="enrolmentTerms"
                  name="enrolmentTerms"
                  type="checkbox"
                  required="required"
                  class="input-checkbox"
                  formControlName="enrolmentTermsCheckbox"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Terms and Conditions'"
                  [requireChecked]="true"
                />
                <span>
			I accept the <a target="_blank" [routerLink]="['/terms_and_conditions']">terms & conditions</a>.
		</span>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
              </label>
              <ul class="parsley-errors-list filled"></ul>
            </div>

            <button type="submit" class="btn btn-block btn-red" *ngIf="course != null && accountId != null && numberOfLessons != null && availableCredits != null" [ngClass]="enrolling || paymentForm.invalid ? 'disabled' : ''">
              Complete booking
            </button>


          </div>
        </form>

      </div>
    </div>


  </main>
</div>

<app-footer></app-footer>
