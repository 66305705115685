<app-header></app-header>

<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info">
        <h2 class="title">
          booking complete				</h2>
        <div class="info">

        </div>
      </div>

      <div class="span-btn">
        <a [routerLink]="['/dashboard']" class="btn btn-block btn-account">
          my account
          <span class="svg-icon user"><svg class="svg-user"><use xlink:href="./assets/sprite/spritemap.svg#sprite-user"></use></svg></span>	</a>
      </div>

    </div>
  </div>
</div>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <div class="wgt wgt-booking">
      <div class="container">

        <div class="span-title">
          <div class="entry-info">
            Your confirmation was successful and your payment is being processed. Look out for an email from us containing your receipt and your booking confirmation details. In the meantime, why not:
          </div>
        </div>

        <div class="row">

          <div class="span-card span-4 span-green">
            <div class="card">
              <div class="card-content">
                <span class="svg-icon student-1"><svg class="svg-student-1"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-1"></use></svg></span>							<div class="card-title">
                view my account
              </div>
                <div class="card-text">
                  Take a look at your current bookings, including important information such as your chosen day, time, teacher and venue.
                </div>
              </div>
              <a [routerLink]="['/dashboard']" class="btn btn-block btn-white">
                view now
              </a>
            </div>
          </div>

          <div class="span-card span-4 {{block.class}}" *ngFor="let block of confirmationBlocks">
            <div class="card">
              <div class="card-content">
                <span class="svg-icon {{block.icon}}"><svg class="svg-{{block.icon}}"><use [attr.xlink:href]="getIconLocation(block)"></use></svg></span>							<div class="card-title" [innerHTML]="block.label">
              </div>
                <div class="card-text" [innerHTML]="block.content">
                </div>
              </div>
              <a href="{{block.url}}" class="btn btn-block btn-white" [innerHTML]="block.action">
              </a>
            </div>
          </div>

        </div>

      </div>
    </div>

  </main>
</div>

<app-footer></app-footer>
