<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
<form  method="post" id="form_location" [formGroup]="selectVenueForm" (ngSubmit)="onVenueSelected()">
<div id="panel_find_location" class="panel panel-find-location">

  <div class="span-title">
    <h2 class="entry-title">
      {{title}}		</h2>
  </div>
  <div class="location-description">
    Start typing in your location and select from the list below, or click on the precision icon to get your current location.
  </div>

  <div class="row row-cards" *ngIf="googleLoaded">

    <div class="span-card">
      <div class="card">
        <div class="span-text required">
          <label
            for="location_postcode"
            class="sr-only">
            Location or postcode ...
          </label>
          <input
            type="text"
            id="location_postcode"
            name="location_postcode"
            placeholder="Location or postcode ..."
            class="input-text postcode"
            required="required"
            minlength=3
            formControlName="location_postcode"
            (keydown)="clearAddress()"
            ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />
          <button type="button" id="btn_locate" class="btn-locate" (click)="getCurrentLocation()">
            <span class="svg-icon location-circle"><svg class="svg-location-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-precision"></use></svg></span>					</button>
        </div>

        <ul class="parsley-errors-list filled"></ul>

      </div>
    </div>

    <div class="span-card">
      <div class="card">
        <div class="span-select required">
          <select
            id="location_within"
            name="location_within"
            type="select"
            required="required"
            class="input-select"
            formControlName="location_within">
            <option value="{{venueSearchDistance.distance}}" *ngFor="let venueSearchDistance of venueSearchDistances" [selected]="venueSearchDistance.isDefault">
              Within {{venueSearchDistance.distance}} {{distanceType}}
            </option>
          </select>
        </div>

        <ul class="parsley-errors-list filled"></ul>

      </div>
    </div>

    <button type="submit" *ngIf="!canSearch()" [ngClass]="this.selectVenueForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red" formaction="courses-list-single.html" data-next>
      continue
    </button>

    <button type="submit" (click)="searchForLocation()" *ngIf="canSearch()" class="btn btn-block btn-red" formaction="courses-list-single.html" data-next>
      search
    </button>

  </div>

</div>
</form>
