<header>

  <header class="header-wrap" *ngIf="enrolment != null">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="[]" queryParamsHandling="preserve" (click)="goBack()">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              Manage {{enrolment.student.givenName}}’s Booking					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="[]" queryParamsHandling="preserve" (click)="goBack()">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap" *ngIf="enrolment != null">
  <main class="main"><section class="wgt wgt-find-courses">
    <div class="container">
      <form method="post" id="booking_manage" novalidate="novalidate" class="form-dashboard">

        <div class="panel panel-dashboard type-manage">

          <div class="span-title">
            <h2 class="entry-title">
              What would you like to do?
            </h2>
          </div>

          <div class="row row-cards">

            <div class="span-card row-element-centred">
              <div class="card style-default">
                <div class="span-choice">
                  <a [routerLink]="['/dashboard_booking_attending', enrolment.enrolmentId]" class="input-check">
                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-calendar-exclamation"><svg class="svg-dashboard-calendar-exclamation"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-calendar-exclamation"></use></svg></span>										</div>
                    </div>
                    <ul class="label-description">
                      <li class="title">Cannot attend session</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div class="span-card row-element-centred" *ngIf="canTransfer">
              <div class="card style-default">
                <div class="span-choice">
                  <a [routerLink]="['/dashboard_booking_transfer', enrolment.enrolmentId]" class="input-check">
                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-exchange"><svg class="svg-dashboard-exchange"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-exchange"></use></svg></span>										</div>
                    </div>
                    <ul class="label-description">
                      <li class="title">Transfer Course</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div class="span-card row-element-centred" *ngIf="canUpgrade">
              <div class="card style-default">
                <div class="span-choice">
                  <a [routerLink]="[]" queryParamsHandling="preserve" (click)="upgradeBooking()" class="input-check">
                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-star"><svg class="svg-dashboard-star"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-star"></use></svg></span>										</div>
                    </div>
                    <ul class="label-description">
                      <li class="title">Upgrade my package</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div class="span-card row-element-centred" *ngIf="canCancel">
              <div class="card style-default">
                <div class="span-choice">
                  <a [routerLink]="[]" queryParamsHandling="preserve" (click)="cancelSubscription()" class="input-check">
                    <div class="span-graphic">
                      <div class="badge-wrap-small red">
                        <span class="svg-icon dashboard-star"><svg class="svg-dashboard-star"><use xlink:href="./assets/sprite/spritemap.svg#sprite-cancel" fill="#FFF"></use></svg></span>										</div>
                    </div>
                    <ul class="label-description">
                      <li class="title">Cancel my subscription</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

          </div>

        </div>

      </form>

    </div>
  </section>
  </main>
</div>

<app-footer></app-footer>
