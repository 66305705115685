<app-header></app-header>
<app-checkout-header></app-checkout-header>

<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container multi-step-form">

      <div class="wgt wgt-wizard-chain">
        <div class="container">

          <ul class="step-list-text">
            <li class="step step-1">
              step <span class="current-index">1</span> of 4				</li>
          </ul>

          <ul class="step-list-icons">


            <li class="step step-1" [ngClass]="{ 'current': currentStepNumber === 1, 'done': 1 < currentStepNumber }">
              <button type="submit" formaction="">
                <span class="svg-icon step-details"><svg class="svg-step-details"><use xlink:href="./assets/sprite/spritemap.svg#sprite-step-details"></use></svg></span><span class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span>							<span class="step-text">
								my details							</span>
              </button>
            </li>


            <li class="step step-2" [ngClass]="{ 'current': currentStepNumber === 2, 'done': 2 < currentStepNumber }">
              <button type="submit" formaction="">
                <span class="svg-icon step-contact"><svg class="svg-step-contact"><use xlink:href="./assets/sprite/spritemap.svg#sprite-step-contact"></use></svg></span><span class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span>							<span class="step-text">
								contact							</span>
              </button>
            </li>


            <li class="step step-3 " [ngClass]="{ 'current': currentStepNumber === 3, 'done': 3 < currentStepNumber }">
              <button type="submit" formaction="">
                <span class="svg-icon step-student"><svg class="svg-step-student"><use xlink:href="./assets/sprite/spritemap.svg#sprite-step-student"></use></svg></span><span class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span>							<span class="step-text">
								students							</span>
              </button>
            </li>


            <li *ngIf="!isFlexible" class="step step-4 " [ngClass]="{ 'current': currentStepNumber === 4, 'done': 4 < currentStepNumber }">
              <button type="submit" formaction="">
                <span class="svg-icon step-payment"><svg class="svg-step-payment"><use xlink:href="./assets/sprite/spritemap.svg#sprite-step-payment"></use></svg></span><span class="svg-icon check-circle-full"><svg class="svg-check-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle-full"></use></svg></span>							<span class="step-text">
								payment							</span>
              </button>
            </li>


          </ul>

        </div>
      </div>

      <div class="card">
        <div class="multi-step-track">
          <div class="slide-track">
            <div class="basket-empty" *ngIf="basketEmpty">
              Your basket is empty
            </div>
            <app-checkout-personal *ngIf="!basketEmpty && currentStepNumber === 1"></app-checkout-personal>

            <div class="form-section current" *ngIf="!basketEmpty && currentStepNumber === 5">
              <div class="account-exists">Your account already exists, please login to continue.</div>
              <button type="button" class="btn btn-block btn-red" (click)="doLogin()">
                Login
              </button>
              <div class="cancel-button-div">
              <button type="button" class="btn cancel-button" (click)="cancelLogin()">
                Cancel
              </button>
              </div>
            </div>

            <app-checkout-address *ngIf="!basketEmpty && currentStepNumber === 2">
            </app-checkout-address>
            <app-checkout-students *ngIf="!basketEmpty && currentStepNumber === 3" [loggedInPerson]="loggedInUser"></app-checkout-students>
            <app-checkout-payment *ngIf="!basketEmpty && currentStepNumber === 4"></app-checkout-payment>
          </div>
        </div>
      </div>

    </div>
  </div>
  </main>
</div>

<app-footer></app-footer>
