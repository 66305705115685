export const environment = {
  production: true,
  apiUrl: 'https://www.sportingtechnology.com/flow-api/',
  rollbarLogging: true,
  name: 'production',
  keycloakConfig: {
    url: 'https://www.sportingtechnology.com/auth',
    realm: 'flow-enrol',
    clientId: 'flow-enrol-web',
  },
  keycloakSilentCheckSsoRedirectUri:
    'https://book.swimmingnature.com/enrol-web/assets/sso/silent-check-sso.html',
  mapsApiKey: 'AIzaSyBn9W7K04WtxdwqzxP1zu8NRnjvCQ4Rtd4',
  stripeApiVersion: 'default',
  apiKey: 'xXXBUO2PUIrBy1gMpqQDdOSzp4eNsRhpbaschzsLo3CDi1srPyDIb-kYFhzUf3UWIKzv_Cg8VjdsoF5ZFr13HQ=='
};

/*  Swimming Nature UK
*  keycloakSilentCheckSsoRedirectUri:
    'https://book.swimmingnature.com/enrol-web/assets/sso/silent-check-sso.html',
*  apiKey: 'xXXBUO2PUIrBy1gMpqQDdOSzp4eNsRhpbaschzsLo3CDi1srPyDIb-kYFhzUf3UWIKzv_Cg8VjdsoF5ZFr13HQ=='
*
*  Angela Swim School
*  keycloakSilentCheckSsoRedirectUri:
    'https://angelaswim.com/enrol-web/assets/sso/silent-check-sso.html',
*  apiKey: '0ekEeLUGAQ0ZNtmaSH65XrXk6xLDQVxsABEUAZm4BhmezQiBHuhByzGsfS6ldge_5jZmxB2FkDoeXfm1CW9w6w=='
*
*  Swimming Nature Dubai
*
*  keycloakSilentCheckSsoRedirectUri:
    'https://swimmingnature.ae/enrol-web/assets/sso/silent-check-sso.html',
*  apiKey: 'LzwiHGdOiH3JmtKlBzySGCNlK3Fn_7X9kFEJuD8nqTK72EGUCaBKMf8G7xWuyOpzKtb76k5pxeR8BxmcwKkBQA=='
*
* */

