import {HostListener, Injectable} from '@angular/core';
import {Basket} from './_model/basket';
import {CourseSearchParameters} from './_model/course-search-parameters';
import {CreateAccountRequest} from './_model/create-account-request';
import {PaymentMethod} from './_model/payment-method';
import {VenueOptions} from './course-search/select-location/select-location.component';
import {BasketService} from './checkout/basket.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  basket: Basket = new Basket();
  courseSearchParameters: CourseSearchParameters = new CourseSearchParameters();
  courseSearchTermType: string;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    console.log('Processing beforeunload...', this.courseSearchParameters, this.basket);
    this.saveCourseSearchParameters();
    this.saveBasket();
  }
  constructor(private basketService: BasketService) {
    const basketString = localStorage.getItem('basket');
    const courseSearchParametersString = sessionStorage.getItem('courseSearchParameters');

    if (basketString != null) {
      this.basket = new Basket(JSON.parse(basketString));
    }

    this.initialiseBasket();

    if (courseSearchParametersString != null) {
      this.courseSearchParameters = new CourseSearchParameters(JSON.parse(courseSearchParametersString));
    }

    this.initialiseCourseSearchParameters();

    this.basketService.currentClearSessionBasket.subscribe(loggedIn => {
      if (loggedIn != null) {
        console.log('Clear session requested, removing basket');
        this.removeBasket();
      }
    });
  }

  private initialiseBasket() {
    if (this.basket.createAccountRequest == null) {
      this.basket.createAccountRequest = new CreateAccountRequest();
    }

    if (this.basket.cardPaymentMethod == null) {
      this.basket.cardPaymentMethod = new PaymentMethod();
    }
    if (this.basket.students == null) {
      this.basket.students = [];
    }
  }

  private initialiseCourseSearchParameters() {
    if (this.courseSearchParameters.venueOptions == null) {
      this.courseSearchParameters.venueOptions = new VenueOptions();
    }
    if (this.courseSearchParameters.students == null) {
      this.courseSearchParameters.students = [];
    }
  }

  saveBasket() {
    if (this.basket != null) {
      localStorage.setItem('basket', JSON.stringify(this.basket));
    } else {
      this.removeBasket();
    }
  }

  removeBasket() {
    this.basket = new Basket();
    this.initialiseBasket();
    localStorage.removeItem('basket');
  }

  saveCourseSearchParameters() {
    if (this.courseSearchParameters != null) {
      sessionStorage.setItem('courseSearchParameters', JSON.stringify(this.courseSearchParameters));
    } else {
      this.removeCourseSearchParameters();
    }
  }

  removeCourseSearchParameters() {
    this.courseSearchParameters = new CourseSearchParameters();
    this.initialiseCourseSearchParameters();
    sessionStorage.removeItem('courseSearchParameters');
  }
}
