

  <div class="multi-step-track">
    <div class="slide-track">

      <div class="form-section wgt-checkout current">

        <form method="post" id="booking_new_student" novalidate="novalidate" class="form-dashboard form-personal-details" [formGroup]="newStudentForm" (ngSubmit)="onNewStudentSubmit()">
        <div class="card">
          <div class="card-inner">


            <h3 class="title-label">
              Personal Details
            </h3>

            <div class="row row-details">


              <div class="span-input span-select  required">
                <select
                  id="studentTitle"
                  name="studentTitle"
                  type="select"
                  required="required"
                  class="input-select"
                  formControlName="studentTitleSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Title'"
                >
                  <option value="" disabled selected hidden>title</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Lady">Lady</option>
                  <option value="Lord">Lord</option>
                  <option value="Master">Master</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Rev.">Rev.</option>
                  <option value="Sir">Sir</option>
                </select>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div><div class="span-input span-text required">
              <input
                type="text"
                id="studentFirstName"
                name="studentFirstName"
                required="required"
                class="input-text"
                formControlName="studentFirstNameInput"
                appParsleyValidation
                [submitted]="submitted"
                [elementName]="'Given Name'"
              />
              <label for="studentFirstName" class="floating-label">
                first name
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div>

              <div class="span-input span-text required">
                <input
                  type="text"
                  id="studentLastName"
                  name="studentLastName"
                  required="required"
                  class="input-text"
                  formControlName="studentLastNameInput"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Surname'"
                />
                <label for="studentLastName" class="floating-label">
                  surname
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>
              <div class="span-input span-select required">
                <select
                  id="studentGender"
                  name="studentGender"
                  class="input-select"
                  formControlName="studentGenderSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Gender'"
                >
                  <option value="" disabled selected hidden>gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
                <label for="studentGender" class="floating-label">
                  gender
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>
            </div>

            <div class="row row-dob row-checkmark row-border">
              <h3 class="span-heading title-label">
                Date of Birth
              </h3>


              <div class="span-select span-input span-4 required">
                <select
                  id="studentDateOfBirthDay"
                  name="studentDateOfBirthDay"
                  required="required"
                  class="input-select"
                  formControlName="studentDateOfBirthDaySelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Day'"
                >
                  <option value="" disabled selected hidden>Day</option>
                  <option value="1">
                    1			</option>
                  <option value="2">
                    2			</option>
                  <option value="3">
                    3			</option>
                  <option value="4">
                    4			</option>
                  <option value="5">
                    5			</option>
                  <option value="6">
                    6			</option>
                  <option value="7">
                    7			</option>
                  <option value="8">
                    8			</option>
                  <option value="9">
                    9			</option>
                  <option value="10">
                    10			</option>
                  <option value="11">
                    11			</option>
                  <option value="12">
                    12			</option>
                  <option value="13">
                    13			</option>
                  <option value="14">
                    14			</option>
                  <option value="15">
                    15			</option>
                  <option value="16">
                    16			</option>
                  <option value="17">
                    17			</option>
                  <option value="18">
                    18			</option>
                  <option value="19">
                    19			</option>
                  <option value="20">
                    20			</option>
                  <option value="21">
                    21			</option>
                  <option value="22">
                    22			</option>
                  <option value="23">
                    23			</option>
                  <option value="24">
                    24			</option>
                  <option value="25">
                    25			</option>
                  <option value="26">
                    26			</option>
                  <option value="27">
                    27			</option>
                  <option value="28">
                    28			</option>
                  <option value="29">
                    29			</option>
                  <option value="30">
                    30			</option>
                  <option value="31">
                    31			</option>
                </select>
                <i class="asterisk">*</i><i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>


              <div class="span-select span-input span-4 required">
                <select
                  id="studentDateOfBirthMonth"
                  name="studentDateOfBirthMonth"
                  required="required"
                  class="input-select"
                  formControlName="studentDateOfBirthMonthSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Month'"
                >
                  <option value="" disabled selected hidden>Month</option>
                  <option value="1">
                    1			</option>
                  <option value="2">
                    2			</option>
                  <option value="3">
                    3			</option>
                  <option value="4">
                    4			</option>
                  <option value="5">
                    5			</option>
                  <option value="6">
                    6			</option>
                  <option value="7">
                    7			</option>
                  <option value="8">
                    8			</option>
                  <option value="9">
                    9			</option>
                  <option value="10">
                    10			</option>
                  <option value="11">
                    11			</option>
                  <option value="12">
                    12			</option>
                </select>
                <i class="asterisk">*</i><i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>


              <div class="span-select span-input span-4 row-checkmark-last required">
                <select
                  id="studentDateOfBirthYear"
                  name="studentDateOfBirthYear"
                  required="required"
                  class="input-select"
                  formControlName="studentDateOfBirthYearSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Year'"
                >
                  <option value="" disabled selected hidden>Year</option>
                  <option *ngFor="let dobYear of dobYears" value="{{dobYear}}">{{dobYear}}</option>
                </select>
                <i class="asterisk">*</i><i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>

            </div>

            <div class="row row-conditions row-border">
              <h3 class="span-heading title-label">
                Any Medical conditions?
              </h3>

              <div class="span-input span-checkbox">
                <label
                  for="studentMedicalNo"
                  class="input-check checkbox">
                  <input
                    id="studentMedicalNo"
                    name="studentMedicalRadio"
                    type="radio"
                    class="input-checkbox"
                    [value]="false"
                    formControlName="studentMedicalRadio"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Medical Conditions'"
                    (change)="handleMedicalChange()"
                  />
                  <span>
				No
			</span>
                </label>
              </div>

              <div class="span-input span-checkbox">
                <label
                  for="studentMedicalYes"
                  class="input-check checkbox">
                  <input
                    id="studentMedicalYes"
                    name="studentMedicalRadio"
                    type="radio"
                    class="input-checkbox"
                    [value]="true"
                    formControlName="studentMedicalRadio"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Medical Conditions'"
                    (change)="handleMedicalChange()"
                  />
                  <span>
				Yes
			</span>
                </label>
                <ul class="parsley-errors-list filled"></ul>
              </div>

              <div *ngIf="createStudentRequest.hasMedicalConditions" id="student-0-medical-condition-wrap" class="span-input span-textarea toggler active">
		<textarea
      id="studentMedicalConditions"
      name="studentMedicalConditions"
      class="input-text"
      placeholder="medical condition"
      formControlName="studentMedicalConditionsTextArea"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Medical Conditions'"
    ></textarea>
                <label
                  for="studentMedicalConditions"
                  class="sr-only"
                >
                  medical condition
                </label>
                <ul class="parsley-errors-list filled"></ul>
              </div>

            </div>
          </div>

          <button type="submit" [ngClass]="this.newStudentForm.invalid || this.submitting ? 'disabled' : ''" class="btn btn-block btn-red">
            {{buttonText}}
          </button>
        </div>
        </form>

        <div class="card" *ngIf="showMembershipForm && memberships != null && memberships.length > 0">
          <div class="card-inner">


            <h3 class="title-label">
              Memberships
            </h3>

            <div class="row row-details row-membership">
              <div class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr *ngFor="let membership of memberships">
                    <td>{{membership.membershipType.name}}</td>
                    <td>{{membership.code}}</td>
                    <td><a [routerLink]="[]" queryParamsHandling="preserve" (click)="cancelMembership(membership.membershipId)">cancel</a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>



        <form method="post" id="student_membership" novalidate="novalidate" class="form-dashboard form-personal-details" [formGroup]="membershipForm" (ngSubmit)="onMembershipSubmit()">
        <div class="card" *ngIf="showMembershipForm">
          <div class="card-inner">


            <h3 class="title-label">
              Add Membership
            </h3>

            <div class="row row-details">

              <div class="span-input span-select required">
                <select
                  id="membershipVenue"
                  name="membershipVenue"
                  class="input-select"
                  (change)="updateMemberships()"
                  formControlName="membershipVenueSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Venue'"
                >
                  <option value="" disabled selected hidden>venue</option>
                  <option value="{{venue.id}}" *ngFor="let venue of venues">{{venue.venueName}}</option>
                </select>
                <label for="membershipVenue" class="floating-label">
                  venue
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>

              <div class="span-input span-select required">
                <select
                  id="membershipType"
                  name="membershipType"
                  class="input-select"
                  formControlName="membershipTypeSelect"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Membership Type'"
                >
                  <option value="" disabled selected hidden>membership type</option>
                  <option value="{{membershipType.membershipTypeId}}" *ngFor="let membershipType of membershipTypes">{{membershipType.name}}</option>
                </select>
                <label for="membershipVenue" class="floating-label">
                  membership type
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>

              <div class="span-input span-text required">
                <input
                  type="text"
                  id="membershipCode"
                  name="membershipCode"
                  required="required"
                  class="input-text"
                  formControlName="membershipCodeInput"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Membership Code'"
                />
                <label for="studentLastName" class="floating-label">
                  membership code
                </label>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div>

            </div>

            <button type="submit" [ngClass]="this.membershipForm.invalid || this.submitting ? 'disabled' : ''" class="btn btn-block btn-red">
              Add Membership
            </button>
          </div>

        </div>
        </form>
      </div>

    </div>
  </div>


