<div class="banner type-default">
  <div class="container">
    <div class="row row-head">

      <div class="span-support span-banner">
        <span *ngIf="companyTelephone != null && companyTelephone !== ''">
          <span class="span-support-text">Any questions? We’re here to help on </span>
          <a href="tel:{{companyTelephone}}">
            <span class="svg-icon span-support-icon"><svg class="svg-phone-call"><use xlink:href="./assets/sprite/spritemap.svg#sprite-phone-call"></use></svg></span>
            {{companyTelephone}}
          </a>
        </span>
      </div>
      <div class="span-login span-banner" *ngIf="loggedInUser == null">
        <app-error-reporting [largeIcon]="false"></app-error-reporting>
        <span class="span-login-text">Already a member? </span>
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="doLogin()" class="login">
          <span class="label">Login Now</span>
          <span class="svg-icon user"><svg class="svg-user"><use xlink:href="./assets/sprite/spritemap.svg#sprite-user"></use></svg></span>					</a>
      </div>
      <div class="span-user" *ngIf="loggedInUser != null">
        <app-error-reporting [largeIcon]="false"></app-error-reporting>
        <button
          type="button"
          class="btn"
          [ngClass]="showMenu ? 'active' : ''"
          (click)="toggleShowMenu()"
          data-toggle="collapsable" data-target="user_options_list"
        >{{loggedInUser.givenName}} {{loggedInUser.familyName}}<span class="svg-icon caret"><svg class="svg-caret"><use xlink:href="./assets/sprite/spritemap.svg#sprite-caret"></use></svg></span></button>
        <ul class="user-options-list toggler" id="user_options_list" [ngClass]="showMenu ? 'active' : ''" style="max-height: 102px;">
          <li><a [routerLink]="['/dashboard']">My Account<span class="svg-icon dashboard-user"><svg class="svg-dashboard-user"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-user"></use></svg></span></a></li>
          <li><a [routerLink]="[]" queryParamsHandling="preserve" (click)="doLogoff()">Log Out<span class="svg-icon dashboard-sign-out"><svg class="svg-dashboard-sign-out"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-sign-out"></use></svg></span></a></li>
        </ul>
      </div>

    </div>
  </div>
</div>

<app-alert></app-alert>
