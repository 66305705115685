import {StudentAchievement} from './student-achievement';

export class ChallengeAchievement extends StudentAchievement {
  challengeId: string;
  challengeName: string;
  challengeTime: string;
  achievementTime: number;
  isFastest: boolean;

  constructor(data?: any) {
    super(data);
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
