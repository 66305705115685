<div class="span-card" [formGroup]="selectStudentsForm">
  <div class="card">
    <div class="span-text required">
      <label
        for="first_name_{{studentNumber}}"
        class="sr-only">
        first name
      </label>
      <input
        type="text"
        id="first_name_{{studentNumber}}"
        name="first_name_{{studentNumber}}"
        placeholder="First Name"
        class="input-text"
        required="required"
        minlength=2
        #studentNameInput
        formControlName="first_name_{{studentNumber}}"
        (change)="studentNameChange(studentNameInput.value)"
      />
    </div>
    <div *ngIf="submitted && getControl('first_name').errors" class="invalid-feedback">
      <div *ngIf="getControl('first_name').errors.required">Name is required</div>
    </div>
  </div>
</div>


<div class="span-card" [formGroup]="selectStudentsForm">
  <div class="card">
    <div class="span-select span-input span-4 required">
      <select
        id="dob_day_{{studentNumber}}"
        name="dob_day_{{studentNumber}}"
        required="required"
        class="input-select"
        formControlName="dob_day_{{studentNumber}}"
      >
        <option value="" disabled selected hidden>Day</option>
        <option value="1">
          1
        </option>
        <option value="2">
          2
        </option>
        <option value="3">
          3
        </option>
        <option value="4">
          4
        </option>
        <option value="5">
          5
        </option>
        <option value="6">
          6
        </option>
        <option value="7">
          7
        </option>
        <option value="8">
          8
        </option>
        <option value="9">
          9
        </option>
        <option value="10">
          10
        </option>
        <option value="11">
          11
        </option>
        <option value="12">
          12
        </option>
        <option value="13">
          13
        </option>
        <option value="14">
          14
        </option>
        <option value="15">
          15
        </option>
        <option value="16">
          16
        </option>
        <option value="17">
          17
        </option>
        <option value="18">
          18
        </option>
        <option value="19">
          19
        </option>
        <option value="20">
          20
        </option>
        <option value="21">
          21
        </option>
        <option value="22">
          22
        </option>
        <option value="23">
          23
        </option>
        <option value="24">
          24
        </option>
        <option value="25">
          25
        </option>
        <option value="26">
          26
        </option>
        <option value="27">
          27
        </option>
        <option value="28">
          28
        </option>
        <option value="29">
          29
        </option>
        <option value="30">
          30
        </option>
        <option value="31">
          31
        </option>
      </select>
    </div>

    <div *ngIf="submitted && getControl('dob_day').errors" class="invalid-feedback">
      <div *ngIf="getControl('dob_day').errors.required">Day is required</div>
      <div *ngIf="getControl('dob_day').errors.dateOfBirth">Invalid date of birth</div>
    </div>

  </div>
</div>
<div class="span-card" [formGroup]="selectStudentsForm">
  <div class="card">
    <div class="span-select span-input span-4 required">
      <select
        id="dob_month_{{studentNumber}}"
        name="dob_month_{{studentNumber}}"
        type="select"
        required="required"
        class="input-select"
        formControlName="dob_month_{{studentNumber}}"
      >
        <option value="" disabled selected hidden>Month</option>
        <option value="1">
          1
        </option>
        <option value="2">
          2
        </option>
        <option value="3">
          3
        </option>
        <option value="4">
          4
        </option>
        <option value="5">
          5
        </option>
        <option value="6">
          6
        </option>
        <option value="7">
          7
        </option>
        <option value="8">
          8
        </option>
        <option value="9">
          9
        </option>
        <option value="10">
          10
        </option>
        <option value="11">
          11
        </option>
        <option value="12">
          12
        </option>
      </select>
    </div>

    <div *ngIf="submitted && getControl('dob_month').errors" class="invalid-feedback">
      <div *ngIf="getControl('dob_month').errors.required">Month is required</div>
    </div>

  </div>
</div>
<div class="span-card" [formGroup]="selectStudentsForm">
  <div class="card">
    <div class="span-select span-input span-4 row-checkmark-last required">
      <select
        id="dob_year_{{studentNumber}}"
        name="dob_year_{{studentNumber}}"
        type="select"
        required="required"
        class="input-select"
        formControlName="dob_year_{{studentNumber}}"
      >
        <option value="" disabled selected hidden>Year</option>
        <option *ngFor="let dobYear of dobYears" value="{{dobYear}}">{{dobYear}}</option>
      </select>
    </div>

    <div *ngIf="submitted && getControl('dob_year').errors" class="invalid-feedback">
      <div *ngIf="getControl('dob_year').errors.required">Year is required</div>
    </div>

  </div>
</div>
