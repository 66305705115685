<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" *ngIf="enrolment != null" [routerLink]="['/dashboard_booking_manage',enrolment.enrolmentId]" [queryParams]="{referrer: 'dashboard_booking_transfer'}">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info" *ngIf="enrolment != null">
            <h2 class="title">
              transfer booking					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_bookings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>


<div class="wrap">
  <main class="main"><header>
    <div class="header">
      <div class="container">
        <div class="row">

          <div class="span-logo ">
          </div>

          <div class="span-info">
            <h2 class="title" *ngIf="courseResults == null || isSearchDisabled">
              let's find a better course				</h2>
            <h2 class="title" *ngIf="courseResults != null && !isSearchDisabled">
              {{courseResults.length}} courses found				</h2>
            <div class="info">

            </div>
          </div>

        </div>
      </div>
    </div>
  </header>
  </main>
</div>
<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <div class="wgt wgt-courses">
      <div class="container">
        <div class="row row-courses">

          <div class="span-filter" [ngClass]="isSearchDisabled ? 'searchRunning' : ''">

            <aside id="wgt_filter" class="wgt wgt-filter toggler active">
              <div class="accordion">

                <app-course-list-filter
                  [courseRatios]="null"
                  [mondayExists]="mondayExists"
                  [tuesdayExists]="tuesdayExists"
                  [wednesdayExists]="wednesdayExists"
                  [thursdayExists]="thursdayExists"
                  [fridayExists]="fridayExists"
                  [saturdayExists]="saturdayExists"
                  [sundayExists]="sundayExists"
                  [instructors]="instructors"
                  [startTimes]="startTimes"
                  [isSearchDisabled]="isSearchDisabled"
                  [excludeTermType]="true"></app-course-list-filter>

              </div>
            </aside>			</div>


          <div class="span-index-courses">
            <div class="wgt wgt-index-courses">
              <div class="row" *ngIf="!runningCourseSearch">
                <app-course-list-course class="span-card" *ngFor="let course of courseResults" [hidden]="!(course.show && course.availableToBook)" [courseResult]="course" [multipleMode]="false" [mode]="'transfer'" [courseResults]="courseResults" [enrolment]="enrolment"></app-course-list-course>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>		</main>
</div>
<app-footer></app-footer>
