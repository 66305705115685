import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseSearchResult} from '../../_model/course-search-result';
import {Instructor} from '../course-list-student-filter/instructor';
import {StartTime} from '../course-list-student-filter/start-time';
import {AlertService} from '../../alert';
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {TitleGenerator} from '../../title-generator';
import {isIterable} from '../../_helpers/general_helpers';
import {Subject} from 'rxjs';
import {SessionService} from '../../session.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {SelectLocationService} from '../select-location/select-location.service';
import {ProgramLevel} from '../../_model/program-level';

@Component({
  selector: 'app-dashboard-booking-transfer',
  templateUrl: './dashboard-booking-transfer.component.html',
  styleUrls: ['./dashboard-booking-transfer.component.css']
})
export class DashboardBookingTransferComponent implements OnInit, OnDestroy, AfterViewInit {
  enrolmentId: string;
  enrolment: EnrolmentResponse;
  courseResults: CourseSearchResult[];
  studentProgramLevel: ProgramLevel;

  isSearchDisabled = false;
  runningCourseSearch = false;
  mondayExists = false;
  tuesdayExists = false;
  wednesdayExists = false;
  thursdayExists = false;
  fridayExists = false;
  saturdayExists = false;
  sundayExists = false;
  instructors: Instructor[];
  startTimes: StartTime[];

  locationSet = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  loading: boolean;

  constructor(private alertService: AlertService,
              private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService,
              private selectLocationService: SelectLocationService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Transfer Booking Search');

    this.sessionService.removeCourseSearchParameters();

    this.courseListStudentDetailsFilterService.studentDetailsFilterChangeUpdate.subscribe(hasValidUpdate => {
      if (hasValidUpdate != null && !this.loading) {
        this.courseResults = [];
        this.bookingHeaderTitleServiceService.setTitle('');
        if (hasValidUpdate) {
          this.setCourseSearch('full');
        }
      }
    });
    this.courseListStudentDetailsFilterService.filterChangeUpdate.subscribe(refreshRequested => {
      if (refreshRequested != null) {
        this.refreshCourses();
      }
    });

    this.route.queryParams
      .subscribe(params => {
        if (params.location != null) {
          const decodedBase64 = atob(params.location);
          const addressDetails = JSON.parse(decodedBase64);

          this.sessionService.courseSearchParameters.venueOptions.address = addressDetails.address;
          this.sessionService.courseSearchParameters.venueOptions.distance = addressDetails.distance;
          this.sessionService.courseSearchParameters.venueOptions.longitude = addressDetails.longitude;
          this.sessionService.courseSearchParameters.venueOptions.latitude = addressDetails.latitude;
          this.sessionService.courseSearchParameters.venueOptions.venueId = null;

          this.sessionService.saveCourseSearchParameters();

          this.locationSet = true;
        } else {
          this.sessionService.courseSearchParameters.venueOptions.distance = 5;
          this.sessionService.courseSearchParameters.venueOptions.address = '';
        }
      });

    this.route.params.subscribe(params => {
      this.enrolmentId = params.id;
    });

    this.renderer.addClass(document.body, 'courses-list-single');
    this.renderer.addClass(document.body, 'type-single');
  }

  ngAfterViewInit() {
    this.apiService.getEnrolment(this.enrolmentId, this.ngUnsubscribe).subscribe(result => {
      this.enrolment = result;

      let hasFutureBooking = false;
      const now = new Date();
      this.enrolment.bookings.forEach(booking => {
        if (booking.bookedClass.startDate > now && booking.status === 'ACTIVE') {
          hasFutureBooking = true;
        }
      });
      if (!hasFutureBooking) {
        this.alertService.error('No future bookings found to transfer');
        return;
      }

      this.studentProgramLevel = result.course.programLevel;
      for (const studentLevel of result.student.levels) {
        if (result.course.programLevel != null && studentLevel.program.id === result.course.programLevel.programId) {
          this.studentProgramLevel = studentLevel.level;
        }
      }

      // Let's try and work out the student level
      if (this.studentProgramLevel == null && result.student.levels.length === 1) {
        this.studentProgramLevel = result.student.levels[0].level;
      }

      if (this.studentProgramLevel == null) {
        this.alertService.error('Failed to obtain student level from course');
        return;
      }

      if (!this.locationSet) {
        this.sessionService.courseSearchParameters.venueOptions.venueId = this.enrolment.course.venueId;
      } else {
        this.sessionService.courseSearchParameters.venueOptions.venueId = null;
      }

      this.sessionService.courseSearchParameters.termId = this.enrolment.course.termId;

      this.sessionService.courseSearchParameters.students = [];
      const courseSearchStudent = new CourseSearchStudent();
      courseSearchStudent.programLevelId = this.studentProgramLevel.id;
      courseSearchStudent.programLevelName = this.studentProgramLevel.programLevelName;
      courseSearchStudent.studentId = this.enrolment.student.id;
      courseSearchStudent.programId = this.studentProgramLevel.programId;

      this.sessionService.courseSearchParameters.students.push(courseSearchStudent);
      this.sessionService.courseSearchParameters.termType = this.enrolment.course.termType;

      this.sessionService.saveCourseSearchParameters();

      if (!this.locationSet) {
        this.selectLocationService.setVenueId(this.enrolment.course.venueId);
      } else {
        this.selectLocationService.setVenueOptionsUpdated();
      }

      // Run the search
      this.setCourseSearch('full');

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'courses-list-single');
    this.renderer.removeClass(document.body, 'type-single');
  }

  setCourseSearch(runType: string) {
    if (this.sessionService.courseSearchParameters == null) {
      console.error('Course search parameters not set for run');
      return;
    }

    console.log('Running search for ' + runType);

    this.renderer.addClass(document.body, 'index-courses');

    this.bookingHeaderTitleServiceService.setTitle('');

    this.isSearchDisabled = true;

    if (runType === 'full') {
      this.runningCourseSearch = true;


      this.apiService.searchForCourses(this.getCourseSearchParameters()).subscribe((data) => {
        this.courseResults = data;
        this.filterCourseSearchResults();
        this.refreshCourses();

        this.runningCourseSearch = false;
        this.isSearchDisabled = false;

        this.googleAnalytics.search(this.studentProgramLevel.programLevelName, 'Transfer');

        this.changeDetectorRef.detectChanges();
      });
    } else if (runType === 'refresh') {
      this.filterCourseSearchResults();
      this.isSearchDisabled = false;
    }
  }

  getCourseSearchParameters(): CourseSearchParameters {
    if (this.sessionService.courseSearchParameters.days != null) {
      // If the days were not available (i.e. no manually unchecked), then re-add them.
      if (!this.mondayExists && !this.sessionService.courseSearchParameters.days.includes(2)) {
        this.sessionService.courseSearchParameters.days.push(2);
      }
      if (!this.tuesdayExists && !this.sessionService.courseSearchParameters.days.includes(3)) {
        this.sessionService.courseSearchParameters.days.push(3);
      }
      if (!this.wednesdayExists && !this.sessionService.courseSearchParameters.days.includes(4)) {
        this.sessionService.courseSearchParameters.days.push(4);
      }
      if (!this.thursdayExists && !this.sessionService.courseSearchParameters.days.includes(5)) {
        this.sessionService.courseSearchParameters.days.push(5);
      }
      if (!this.fridayExists && !this.sessionService.courseSearchParameters.days.includes(6)) {
        this.sessionService.courseSearchParameters.days.push(6);
      }
      if (!this.saturdayExists && !this.sessionService.courseSearchParameters.days.includes(7)) {
        this.sessionService.courseSearchParameters.days.push(7);
      }
      if (!this.sundayExists && !this.sessionService.courseSearchParameters.days.includes(1)) {
        this.sessionService.courseSearchParameters.days.push(1);
      }
    }
    return this.sessionService.courseSearchParameters;
  }

  filterCourseSearchResults(): void {
    this.instructors = [];
    this.startTimes = [];

    this.mondayExists = false;
    this.tuesdayExists = false;
    this.wednesdayExists = false;
    this.thursdayExists = false;
    this.fridayExists = false;
    this.saturdayExists = false;
    this.sundayExists = false;

    const courseSearchDays = [];

    let counter = 0;
    if (isIterable(this.courseResults)) {
      this.courseResults.forEach(course => {

        switch (course.course.fullDayOfWeekNames) {
          case 'Monday': {
            if (!courseSearchDays.includes(2)) {
              courseSearchDays.push(2);
            }
            this.mondayExists = true;
            break;
          }
          case 'Tuesday': {
            if (!courseSearchDays.includes(3)) {
              courseSearchDays.push(3);
            }
            this.tuesdayExists = true;
            break;
          }
          case 'Wednesday': {
            if (!courseSearchDays.includes(4)) {
              courseSearchDays.push(4);
            }
            this.wednesdayExists = true;
            break;
          }
          case 'Thursday': {
            if (!courseSearchDays.includes(5)) {
              courseSearchDays.push(5);
            }
            this.thursdayExists = true;
            break;
          }
          case 'Friday': {
            if (!courseSearchDays.includes(6)) {
              courseSearchDays.push(6);
            }
            this.fridayExists = true;
            break;
          }
          case 'Saturday': {
            if (!courseSearchDays.includes(7)) {
              courseSearchDays.push(7);
            }
            this.saturdayExists = true;
            break;
          }
          case 'Sunday': {
            if (!courseSearchDays.includes(1)) {
              courseSearchDays.push(1);
            }
            this.sundayExists = true;
            break;
          }

        }

        let foundInstructor = false;
        this.instructors.forEach(instructor => {
          if (course.course.instructorId === instructor.id) {
            foundInstructor = true;
          }
        });

        if (!foundInstructor) {
          const courseInstructor = new Instructor();
          courseInstructor.id = course.course.instructorId;
          courseInstructor.displayName = course.instructorDisplayName;
          courseInstructor.checked = true;

          this.instructors.push(courseInstructor);
        }

        let foundTime = false;
        const startTimeHour = course.course.startTimeHour;

        this.startTimes.forEach(startTime => {
          if (startTimeHour === startTime.hour) {
            foundTime = true;
          }
        });

        if (!foundTime) {
          const startTime = new StartTime();
          startTime.hour = startTimeHour;
          startTime.checked = true;

          const startDate = new Date();
          startDate.setHours(startTime.hour, 0, 0);
          startTime.fromTime = startDate;

          let toValue = startTime.hour + 1;
          if (toValue === 24) {
            toValue = 0;
          }

          const endDate = new Date();
          endDate.setHours(toValue, 0, 0);

          startTime.toTime = endDate;

          this.startTimes.push(startTime);
        }

        counter++;
      });
    }

    this.startTimes.sort((t1, t2) => {
      if (t1.hour > t2.hour) {
        return 1;
      }
      if (t1.hour < t2.hour) {
        return -1;
      }
      return 0;
    });

    this.instructors.sort((t1, t2) => {
      if (t1.displayName > t2.displayName) {
        return 1;
      }
      if (t1.displayName < t2.displayName) {
        return -1;
      }
      return 0;
    });

    this.sessionService.courseSearchParameters.days = courseSearchDays;
    this.bookingHeaderTitleServiceService.setTitle(counter + ' courses found');
  }

  refreshCourses() {
    if (this.courseResults != null) {
      let totalCourses = this.courseResults.length;
      if (isIterable(this.courseResults)) {
        for (const courseResult of this.courseResults) {
          courseResult.show = this.canShowCourse(courseResult);
          if (!courseResult.show) {
            totalCourses--;
          }
        }
      }
      this.bookingHeaderTitleServiceService.setTitle(totalCourses + ' courses found');
    }
  }

  canShowCourse(courseSearchResult: CourseSearchResult): boolean {
    let foundDay = false;

    if (this.sessionService.courseSearchParameters.days == null) {
      return false;
    }

    for (const dayNumber of courseSearchResult.course.dayOfTheWeekNumbers) {
      if (this.sessionService.courseSearchParameters.days.includes(dayNumber)) {
        foundDay = true;
        break;
      }
    }

    if (!foundDay) {
      return false;
    }

    for (const instructor of this.instructors) {
      if (!instructor.checked
        && instructor.id === courseSearchResult.course.instructorId) {
        return false;
      }
    }

    for (const startTime of this.startTimes) {
      if (!startTime.checked
        && startTime.hour === courseSearchResult.course.startTimeHour) {
        return false;
      }
    }

    if ((this.enrolment.enrolmentType === 'FREE_TRIAL' || this.enrolment.enrolmentType === 'MAKEUP_CLASS')
      && this.enrolment.course.numberOfStudentsPerInstructor !== courseSearchResult.course.numberOfStudentsPerInstructor) {
      return false;
    }

    return true;
  }
}
