import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Course} from '../../_model/course';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../alert';
import {DashboardService} from '../../dashboard/dashboard.service';
import {BasketService} from '../basket.service';
import {Basket} from '../../_model/basket';
import {PaymentMethod} from '../../_model/payment-method';
import {Address} from '../../_model/address';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CreditPackResult} from '../../_model/credit-pack-result';
import {CheckoutPaymentMethodCardService} from '../checkout-payment/checkout-payment-method-card/checkout-payment-method-card.service';
import {PolicyResult} from '../../_model/policy-result';
import {Program} from '../../_model/program';
import {CreditPackPurchaseRequest} from '../../_model/credit-pack-purchase-request';
import {FlexibleCourseEnrolmentRequest} from '../../_model/flexible-course-enrolment-request';
import {CoursePriceList} from '../../_model/course-price-list';
import {TitleGenerator} from '../../title-generator';
import {TermsAndConditionsValidator} from '../../_helpers/terms_and_conditions_validator';
import {CreditPackPurchaseResponse} from '../../_model/credit-pack-purchase-response';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {ProgramLevel} from '../../_model/program-level';

@Component({
  selector: 'app-dashboard-booking-credits-buy',
  templateUrl: './dashboard-booking-credits-buy.component.html',
  styleUrls: ['./dashboard-booking-credits-buy.component.css']
})
export class DashboardBookingCreditsBuyComponent implements OnInit, OnDestroy, AfterViewInit {

  paymentForm: UntypedFormGroup;

  submitted = false;

  accountId: string;
  course: Course;
  numberOfLessons: number;
  programLevelId: string;
  programLevel: ProgramLevel;
  programId: string;
  studentId: string;
  creditPacks: CreditPackResult[];
  selectedCreditPackId: string;
  availableCredits: number;
  previewPurchaseCreditPack: CreditPackPurchaseResponse;
  startDate: Date;

  cardPaymentMethod: PaymentMethod;
  addressLinesActive = false;

  termsAndConditionsPolicyId: string;
  termsAndConditions: PolicyResult;
  venuePolicies: PolicyResult[];
  enrolling = false;
  paymentRequired = false;
  receiptId: string;
  coursePriceList: CoursePriceList;
  voucherCode: string;
  totalAmountWithBalance: number;

  referrer: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  basketSubscription: Subscription;
  cardFormUpdatedSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  voucherCodeSubscription: Subscription;

  loading = false;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private dashboardService: DashboardService,
              private basketService: BasketService,
              private spinner: NgxSpinnerService,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Credit Booking Confirmation');

    this.loadCourseFromBasket(this.sessionService.basket);

    this.cardPaymentMethod = new PaymentMethod();
    this.cardPaymentMethod.address = new Address();

    this.paymentForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      creditPackRadio: [''],
      creditCardSelect: [''],
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      enrolmentTermsCheckbox: ['']
    });

    this.cardFormUpdatedSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.cardPaymentMethod = paymentMethod;
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && !this.loading) {
        if (hasErrors) {
          this.enrolling = false;
          this.spinner.hide();
        } else {
          this.purchaseCredits();
        }
      }
    });

    this.voucherCodeSubscription = this.basketService.currentVoucherCode.subscribe(voucherCode => {
      if (voucherCode != null) {
        if (voucherCode === 'NULL') {
          this.voucherCode = null;
        } else {
          this.voucherCode = voucherCode;
        }

        this.doPreviewPurchaseCreditPack();
      }
    });

    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });

    this.renderer.addClass(document.body, 'dashboard-booking-credits-buy');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;

      if (this.termsAndConditions != null) {
        this.termsAndConditionsPolicyId = this.termsAndConditions.id;
        this.setPaymentValidators();
      }
    });

    this.setPaymentValidators();
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-credits-buy');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'dashboard-action');

    if (this.basketSubscription != null) {
      this.basketSubscription.unsubscribe();
    }
    if (this.cardFormUpdatedSubscription != null) {
      this.cardFormUpdatedSubscription.unsubscribe();
    }
    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }
    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }
    if (this.voucherCodeSubscription != null) {
      this.voucherCodeSubscription.unsubscribe();
    }
  }

  setPaymentValidators() {
    let addressValidator = null;
    let creditCardAddressValidator = null;
    let paymentValidator = null;

    if (this.availableCredits != null && this.numberOfLessons != null && this.availableCredits < this.numberOfLessons) {
      if (this.previewPurchaseCreditPack != null && this.previewPurchaseCreditPack.totalAmountWithBalance > 0) {
        paymentValidator = [Validators.required];

        if (this.cardPaymentMethod.cardId === 'new') {
          creditCardAddressValidator = [Validators.required];

          if (this.cardPaymentMethod.paymentAddressId === 'other') {
            addressValidator = [Validators.required];
          }
        } else {
          this.removeFormControlError('cardError');
          this.changeDetectorRef.detectChanges();
        }
      }
    }

    if (this.termsAndConditions != null) {
      this.paymentForm.get('enrolmentTermsCheckbox').setValidators([TermsAndConditionsValidator()]);
    }

    this.paymentForm.get('creditPackRadio').setValidators(paymentValidator);
    this.paymentForm.get('creditCardSelect').setValidators(paymentValidator);
    this.paymentForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.paymentForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.paymentForm.get('addressLine1Input').setValidators(addressValidator);
    this.paymentForm.get('addressCityInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountyInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountryInput').setValidators(addressValidator);
    this.paymentForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.paymentForm.get('creditPackRadio').updateValueAndValidity();
    this.paymentForm.get('creditCardSelect').updateValueAndValidity();
    this.paymentForm.get('creditCardNameInput').updateValueAndValidity();
    this.paymentForm.get('paymentAddress').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressCityInput').updateValueAndValidity();
    this.paymentForm.get('addressCountyInput').updateValueAndValidity();
    this.paymentForm.get('addressCountryInput').updateValueAndValidity();
    this.paymentForm.get('addressPostcodeInput').updateValueAndValidity();
    this.paymentForm.get('enrolmentTermsCheckbox').updateValueAndValidity();

    this.paymentForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  loadCourseFromBasket(basketItem: Basket): void {
    if (basketItem.students.length !== 1) {
      this.alertService.error('Invalid number of students in basket (' + basketItem.students.length + ')');
    } else {
      this.course = basketItem.students[0].course;
      this.numberOfLessons = basketItem.students[0].numberOfWeeksToEnrol;
      this.programLevelId = basketItem.students[0].student.programLevelId;
      this.studentId = basketItem.students[0].student.studentId;
      this.programId = basketItem.students[0].student.programId;
      this.startDate = basketItem.students[0].startDate;

      this.apiService.getAccounts(this.course.licenseeId, this.ngUnsubscribe).subscribe(accounts => {
        if (accounts.length === 0) {
          this.alertService.error('No accounts available to book in');
        } else {
          this.sessionService.basket.accountId = accounts[0].id;
          this.accountId = accounts[0].id;

          this.sessionService.saveBasket();

          this.loadStudentCredits();
        }
      });

      this.apiService.getProgramLevel(this.programLevelId, this.ngUnsubscribe).subscribe(data => {
        this.programLevel = data;
      });

      this.apiService.getCoursePrices(this.course.id,
        this.programLevelId, this.studentId, null, this.ngUnsubscribe).subscribe(priceList => this.coursePriceList = priceList);

      this.venuePolicies = [];
      this.apiService.getVenuePolicies(this.course.venueId, this.ngUnsubscribe).subscribe(policies => {
        const policyFormControlFields = [];
        policies.forEach(policy => {
          if (!this.venuePolicies.includes(policy)) {
            this.venuePolicies.push(policy);
            policyFormControlFields.push({ name: 'policyCheckbox_' + policy.id, control: new UntypedFormControl('', Validators.required) });
          }
        });
        policyFormControlFields.forEach(f => this.paymentForm.addControl(f.name, f.control));
      });
    }
  }

  loadStudentCredits() {
    this.apiService.getStudentCreditPacks(this.sessionService.basket.accountId,
      this.studentId, this.course.id,
      this.programLevelId, this.ngUnsubscribe).subscribe(creditPacks => {
      this.availableCredits = 0;
      for (const creditPackResponse of creditPacks) {
        const creditPack = creditPackResponse.studentCreditPack;
        if (creditPack.creditPack.classDurationInMinutes === this.course.classDurationInMinutes
          && creditPack.creditPack.numberOfStudentsPerInstructor === this.course.numberOfStudentsPerInstructor
          && this.hasProgram(this.programId, creditPack.creditPack.programmes)) {
          for (const credit of creditPack.credits) {
            if (credit.status === 'ACTIVE') {
              this.availableCredits++;
            }
          }
        }
      }

      this.changeDetectorRef.detectChanges();

      if (this.availableCredits < this.numberOfLessons) {
        this.loadCreditPacks();
        this.paymentRequired = true;
      }

      this.setPaymentValidators();
    });
  }

  hasProgram(programId: string, programs: Program[]): boolean {
    for (const program of programs) {
      if (program.id === programId) {
        return true;
      }
    }

    return false;
  }

  loadCreditPacks(): void {
    this.apiService.getCourseCreditPacks(this.course.id,
      this.programLevelId, this.studentId, this.ngUnsubscribe).subscribe(creditPacks => {
        this.creditPacks = creditPacks;
        if (this.creditPacks.length > 0) {
          for (const creditPack of this.creditPacks) {
            if (creditPack.creditPack.numberOfLessons >= this.numberOfLessons) {
              this.selectedCreditPackId = creditPack.creditPack.sportsCreditPackId;
            }
          }
          if (this.selectedCreditPackId == null) {
            this.selectedCreditPackId = creditPacks[creditPacks.length - 1].creditPack.sportsCreditPackId;
          }
          this.paymentForm.get('creditPackRadio').setValue(this.selectedCreditPackId);
          this.doPreviewPurchaseCreditPack();
        }
      });
  }

  doPreviewPurchaseCreditPack(): void {
    this.selectedCreditPackId = this.paymentForm.get('creditPackRadio').value;

    this.previewPurchaseCreditPack = null;
    this.apiService.previewPurchaseCreditPack(this.generatePurchaseObject()).subscribe(response => {
      this.previewPurchaseCreditPack = response;
      if (this.previewPurchaseCreditPack.totalAmountWithBalance === 0) {
        this.paymentRequired = false;
      }
      if (response.result === 'error') {
        this.alertService.error(response.error);
      } else {
        this.googleAnalytics.beginCheckoutForCourse(this.course,
          this.programLevel,
          null,
          response.totalAmountWithBalance,
          'Credit Pack',
          null);
      }
    });
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  onPaymentSubmit(): void {
    if (this.enrolling) {
      return;
    }

    this.submitted = true;

    if (this.availableCredits != null && this.numberOfLessons != null && this.availableCredits < this.numberOfLessons
      && this.cardPaymentMethod.cardId === 'new' && this.cardPaymentMethod.cardHasErrors) {
      this.paymentForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
    }

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }

    this.enrolling = true;
    this.spinner.show();

    if (this.paymentRequired && this.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else if (this.paymentRequired) {
      this.purchaseCredits();
    } else {
      this.loadStudentCredits();
      this.enrolStudentInCourse();
    }
  }

  purchaseCredits() {
    this.alertService.clear();

    this.apiService.purchaseCreditPack(this.generatePurchaseObject()).subscribe(response => {
      this.enrolling = false;
      this.spinner.hide();
      if (response.result === 'success') {
        this.paymentRequired = false;

        if (response.sportsPayment != null
          && response.sportsPayment.payment != null
          && response.sportsPayment.payment.code !== '') {
          this.receiptId = response.sportsPayment.payment.code;
          this.alertService.success('Enrolment complete, receipt number: ' + response.sportsPayment.payment.code);
        } else {
          this.alertService.success('Enrolment complete');
        }

        this.totalAmountWithBalance = response.totalAmountWithBalance;

        this.loadStudentCredits();
        this.enrolStudentInCourse();
      } else if (response.result === 'requires_action') {
        if (response.requiresPaymentAction) {
          console.log('requires payment action');
          this.enrolling = true;
          this.spinner.show();
          this.checkoutPaymentMethodCardService.setHandleStripeAction(response.paymentIntentClientSecret);
        } else {
          console.error('Requires action, though action parameter not true');
        }
      } else if (response.result === 'payment_error') {
        this.alertService.error(response.error);
      } else if (response.result === 'error') {
        if (response.error != null) {
          this.alertService.error(response.error);
        } else {
          this.alertService.error('An unknown error has occurred');
        }
      } else {
        console.error('Unknown response: ' + response.result);
      }
    });
  }

  generatePurchaseObject(): CreditPackPurchaseRequest {
    const request = new CreditPackPurchaseRequest();

    request.accountId = this.sessionService.basket.accountId;
    request.licenseeId = this.course.licenseeId;
    request.cardId = this.cardPaymentMethod.cardId;
    request.paymentIntentId = this.cardPaymentMethod.paymentIntentId;
    request.studentId = this.studentId;
    request.creditPackId = this.selectedCreditPackId;
    request.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    request.termsAndConditionsPolicyId = this.termsAndConditionsPolicyId;
    request.programId = this.programId;
    request.voucherCode = this.voucherCode;

    if (this.previewPurchaseCreditPack != null) {
      request.quotedAmount = this.previewPurchaseCreditPack.totalAmountWithBalance;
    }

    return request;
  }

  generateEnrolmentObject(): FlexibleCourseEnrolmentRequest {
    const request = new FlexibleCourseEnrolmentRequest();

    request.studentId = this.studentId;
    request.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    request.numberOfWeeks = this.numberOfLessons;
    request.courseId = this.course.id;
    request.programLevelId = this.programLevelId;
    request.startDate = this.startDate;

    request.acceptedPolicyIds = [];
    if (request.acceptedTermsAndConditions) {
      request.acceptedPolicyIds.push(this.termsAndConditionsPolicyId);
    }

    // You have to accept venue policies, so adding them all
    this.venuePolicies.forEach(policy => {
      request.acceptedPolicyIds.push(policy.id);
    });

    return request;
  }

  enrolStudentInCourse() {
    this.apiService.enrolStudentInFlexibleCourse(this.generateEnrolmentObject()).subscribe(() => {
      this.spinner.hide();
      this.sessionService.removeBasket();
      const receiptId = this.receiptId;

      this.googleAnalytics.beginCheckoutForCourse(this.course,
        this.programLevel,
        null,
        this.totalAmountWithBalance,
        'Credit Pack',
        null);

      this.router.navigate(['/booking_complete'], {queryParams: {receiptId}});
    }, () => {
      this.spinner.hide();
    });
  }

  getSelectedCreditPack(): CreditPackResult {
    for (const creditPack of this.creditPacks) {
      if (creditPack.creditPack.sportsCreditPackId === this.selectedCreditPackId) {
        return creditPack;
      }
    }

    return null;
  }

  goBack() {
    if (this.referrer == null) {
      this.referrer = 'cart';
    }

    this.router.navigate(['/' + this.referrer]);
  }
}
