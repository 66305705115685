<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <div class="wgt wgt-courses">
      <div class="container">
        <div class="row row-courses">

          <div class="span-filter" [ngClass]="isSearchDisabled ? 'searchRunning' : ''">

            <aside id="wgt_filter" class="wgt wgt-filter toggler active">
              <div class="accordion">

                <app-course-list-filter
                  [courseRatios]="null"
                  [mondayExists]="mondayExists"
                  [tuesdayExists]="tuesdayExists"
                  [wednesdayExists]="wednesdayExists"
                  [thursdayExists]="thursdayExists"
                  [fridayExists]="fridayExists"
                  [saturdayExists]="saturdayExists"
                  [sundayExists]="sundayExists"
                  [instructors]="instructors"
                  [startTimes]="startTimes"
                  [isSearchDisabled]="isSearchDisabled"
                  [showWeekly]="showWeekly"></app-course-list-filter>

              </div>
            </aside>			</div>


          <div class="span-index-courses">
            <div class="wgt wgt-index-courses">
              <div class="row">
                <app-course-list-course class="span-card" *ngFor="let course of courseResults" [hidden]="!(course.show && course.availableToBook)" [courseResult]="course" [multipleMode]="false" [mode]="mode" [courseResults]="courseResults" [credit]="credit.creditResults[0].credit"></app-course-list-course>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>		</main>
</div>
<app-footer></app-footer>
