<div class="wrap">
  <main class="main"><section class="auth">

    <div class="container">
      <div class="row">

        <div class="span-bg" style="background-image: url('./assets/images/login-bg.jpg')"></div>

        <div class="span-form">
          <div class="login-wrap">

            <div class="logo">
              <a appRedirectHome>
                <img appLogo/>
              </a>
            </div><div class="login-header">
            <h3 class="login-title">
              Request a log in
            </h3>
            <p class="login-subtitle">
              Please enter your details below
            </p>
          </div>

            <div class="form">
              <form method="post" id="request_access" novalidate="novalidate" class="auth-form" [formGroup]="requestAccessForm" (ngSubmit)="onRequestAccessSubmit()">

                <div *ngIf="emailSent === false" class="span-input span-checkbox required">

                  <div class="switch-field">
                    <input

                      id="requestAccessEmail"
                      name="requestAccessCheckbox"
                      type="radio"
                      class="input-checkbox"
                      [value]="'email'"
                      (change)="setRequestValidators()"
                      formControlName="requestAccessCheckbox"
                      appParsleyValidation
                      [submitted]="submitted"
                      [elementName]="'Request Type'"
                    />
                    <label for="requestAccessEmail">Email</label>
                    <input

                      id="requestAccessText"
                      name="requestAccessCheckbox"
                      type="radio"
                      class="input-checkbox"
                      [value]="'text'"
                      (change)="setRequestValidators()"
                      formControlName="requestAccessCheckbox"
                      appParsleyValidation
                      [submitted]="submitted"
                      [elementName]="'Request Type'"
                    />
                    <label for="requestAccessText">Mobile Number</label>
                  </div>

                </div>

                <div *ngIf="emailSent === false" class="span-input span-text required" [hidden]="requestType !== 'email'">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    class="input-text"
                    formControlName="emailInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Email'"
                    (input)="requestAccessForm.controls.emailInput.setValue(requestAccessForm.controls.emailInput.value.toLowerCase())"
                  />
                  <label for="email" class="floating-label">
                    email
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul *ngIf="emailSent === false && error != null" class="parsley-errors-list filled">
                    <li class="parsley-required">{{error}}</li>
                  </ul>
                </div>

                <div *ngIf="emailSent === false" class="span-input span-text required" [hidden]="requestType !== 'text'">
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    class="input-text"
                    formControlName="phoneNumberInput"
                    (focusout)="formatMobile()"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Telephone'"
                  />
                  <label
                    for="phone_number"
                    class="floating-label">
                    phone
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul *ngIf="emailSent === false && error != null" class="parsley-errors-list filled">
                    <li class="parsley-required">{{error}}</li>
                  </ul>
                </div>

                <button *ngIf="emailSent === false" type="submit" class="btn btn-block btn-red" [ngClass]="requestAccessForm.invalid || runningRequestAccess ? 'disabled' : ''">
                  Submit request
                </button>

                <div *ngIf="emailSent === false" class="alternative">
                  <p>Already have an account? <a [routerLink]="[]" (click)="doLogin()">Log in</a></p>
                </div>

                <div *ngIf="emailSent" class="alternative">
                  <p>An email has been sent to {{email}}. Please click on the link within the email to continue the registration process.</p>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>		</main>
</div>
