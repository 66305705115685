import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {ApplicationSettingsService} from '../application-settings.service';
import {environment} from '../../environments/environment';

@Directive({
  selector: '[appLogo]'
})
export class LogoDirective implements OnInit {

  @Input() inverse: boolean;

  constructor(private el: ElementRef,
              private applicationSettingsService: ApplicationSettingsService) { }

  ngOnInit() {
    if (this.inverse == null || !this.inverse) {
      if (this.applicationSettingsService.hasLogo) {
        this.el.nativeElement.setAttribute('src', this.getUrl() + '/logo');
      } else {
        this.el.nativeElement.style.display = 'none';
      }
    } else {
      if (this.applicationSettingsService.hasInverseLogo) {
        this.el.nativeElement.setAttribute('src', this.getUrl() + '/inverse_logo');
      } else {
        this.el.nativeElement.style.display = 'none';
      }
    }
  }

  private getUrl(): string {
    return environment.apiUrl + 'images/master_licensee/' + this.applicationSettingsService.masterLicenseeId;
  }
}
