import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseRatio} from '../course-list-student-filter/course-ratio';
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {Instructor} from '../course-list-student-filter/instructor';
import {StartTime} from '../course-list-student-filter/start-time';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {SelectLocationService} from '../select-location/select-location.service';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {CourseSearchParameters} from '../../_model/course-search-parameters';
import {VenueSearchDistance} from '../../_model/venue-search-distance';
import {ApplicationSettingsService} from '../../application-settings.service';

@Component({
  selector: 'app-course-list-filter',
  templateUrl: './course-list-filter.component.html',
  styleUrls: ['./course-list-filter.component.css']
})
export class CourseListFilterComponent implements OnInit, OnDestroy {

  @Input() courseRatios: CourseRatio[];
  @Input() instructors: Instructor[];
  @Input() startTimes: StartTime[];
  @Input() mondayExists: boolean;
  @Input() tuesdayExists: boolean;
  @Input() wednesdayExists: boolean;
  @Input() thursdayExists: boolean;
  @Input() fridayExists: boolean;
  @Input() saturdayExists: boolean;
  @Input() sundayExists: boolean;
  @Input() isSearchDisabled: boolean;
  @Input() excludeTermType = false;
  @Input() showWeekly = true;
  @Input() showIntensive = true;
  @Input() showInstructor = false;

  courseSearchParameters: CourseSearchParameters;

  isMondayChecked = true;
  isTuesdayChecked = true;
  isWednesdayChecked = true;
  isThursdayChecked = true;
  isFridayChecked = true;
  isSaturdayChecked = true;
  isSundayChecked = true;

  showLocation = true;
  showClassSize = true;
  showCourseType = true;
  showDays = false;
  showTimes = false;

  venueName: string;

  venueSearchDistances: VenueSearchDistance[] = [];
  distanceType: string = null;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  venueIdSubscription: Subscription;

  constructor(private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private selectLocationService: SelectLocationService,
              private apiService: ApiService,
              private router: Router,
              private applicationSettings: ApplicationSettingsService,
              private sessionService: SessionService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.courseSearchParameters = sessionService.courseSearchParameters;
  }

  ngOnInit(): void {
    this.apiService.getVenueSearchDistances().subscribe(distances => this.venueSearchDistances = distances);

    if (this.applicationSettings.distanceMeasurement === 'metric') {
      this.distanceType = 'kilometers';
    } else {
      this.distanceType = 'miles';
    }

    if (this.sessionService.courseSearchParameters.venueOptions.venueId != null) {
      this.setVenueName();
    }

    this.venueIdSubscription = this.selectLocationService.currentVenueId.subscribe(venueId => {
      if (venueId != null) {
        this.sessionService.courseSearchParameters.venueOptions.venueId = venueId;
        this.courseSearchParameters.venueOptions.venueId = venueId;
        this.setVenueName();
      }
    });

    this.venueIdSubscription = this.selectLocationService.currentUpdatedVenueOptions.subscribe(updated => {
      if (updated != null) {
        this.courseSearchParameters = this.sessionService.courseSearchParameters;
      }
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.venueIdSubscription != null) {
      this.venueIdSubscription.unsubscribe();
    }
  }

  setVenueName() {
    this.apiService.getVenue(this.sessionService.courseSearchParameters.venueOptions.venueId, this.ngUnsubscribe).subscribe(venue => {
      this.venueName = venue.venueName;
      this.changeDetectorRef.detectChanges();
    });
  }

  reRunSearch() {
    this.courseListStudentDetailsFilterService.setHasValidUpdate(true);
  }

  daySelected() {
    this.sessionService.courseSearchParameters.days = [];

    if (this.isMondayChecked) {  this.sessionService.courseSearchParameters.days.push(2); }
    if (this.isTuesdayChecked) {  this.sessionService.courseSearchParameters.days.push(3); }
    if (this.isWednesdayChecked) {  this.sessionService.courseSearchParameters.days.push(4); }
    if (this.isThursdayChecked) {  this.sessionService.courseSearchParameters.days.push(5); }
    if (this.isFridayChecked) {  this.sessionService.courseSearchParameters.days.push(6); }
    if (this.isSaturdayChecked) {  this.sessionService.courseSearchParameters.days.push(7); }
    if (this.isSundayChecked) {  this.sessionService.courseSearchParameters.days.push(1); }

    this.courseListStudentDetailsFilterService.setRefreshRequested(true);
  }

  toggleLocation() {
    this.showLocation = !this.showLocation;
  }

  toggleClassSize() {
    this.showClassSize = !this.showClassSize;
  }

   toggleCourseType() {
    this.showCourseType = !this.showCourseType;
   }

    toggleDays() {
      this.showDays = !this.showDays;
    }

    toggleTimes() {
      this.showTimes = !this.showTimes;
    }

     toggleInstructor() {
      this.showInstructor = !this.showInstructor;
     }

  refreshFilter() {
    this.courseListStudentDetailsFilterService.setRefreshRequested(true);
  }

  changeLocation(): void {
    const redirectPage = this.router.url;
    this.router.navigate(['/course_list_change_location'], {queryParams: {redirectPage}}).then(fulfilled => {
      if (fulfilled) {
        this.selectLocationService.setTitle('What’s your preferred location?');
      }
    });
  }
}
