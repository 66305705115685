<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_booking_credits_use',creditId]">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              credit enrolment					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_credits']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><header>
    <div class="header">
      <div class="container">
        <div class="row" *ngIf="course != null && credit != null">

          <div class="span-logo ">
          </div>

          <div class="span-info">
            <h2 class="title">
              {{course.venueName}}				</h2>
            <div class="info">
              {{classItem.startDate | worldDate:'EEEE d MMM yyyy':classItem.startDateOffset:locale}} @ {{classItem.startDate | worldDate:'HH:mm':classItem.startDateOffset:locale}}
            </div>
            <div class="restriction" *ngIf="course.activeRestrictions.length > 0">
              <div class="restriction-alert">
                <svg class="exclamation-mark">
                  <title>Course Restriction</title>
                  <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                </svg>
              </div>
              <div class="restriction-name">
                <span *ngFor="let restriction of course.activeRestrictions; let i = index">{{restriction.description}}<span *ngIf="i > 0">, </span></span>
              </div>
            </div>

            <a [routerLink]="[]" queryParamsHandling="preserve" (click)="confirmSession()" class="btn btn-block btn-red" [ngClass]="locale == null || enrolling ? 'disabled' : ''">confirm session</a>			</div>


        </div>
      </div>
    </div>

  </header>
    <div class="wgt wgt-course-single" *ngIf="course != null">

      <div class="wgt wgt-course-info">
        <div class="container">

          <div class="row row-info">

            <div class="span-3 span-programme" *ngIf="course.programLevel != null">
              <span class="svg-icon badge-check"><svg class="svg-badge-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-badge-check"></use></svg></span>				<ul>
              <li class="title">programme</li>
              <li class="lead">{{course.programLevel.programName}}</li>
              <li>{{course.programLevel.programLevelName}} (Level {{course.programLevel.sequence}})</li>
              <li>{{getNumberOfStudentsText()}} Students per Class</li>
              <li>{{course.classDurationInMinutes}} Minutes</li>
            </ul>
            </div>

            <div class="span-3 span-date">
              <span class="svg-icon calendar"><svg class="svg-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-calendar"></use></svg></span>				<ul>
              <li class="title">Date & Time</li>
              <li class="lead">{{course.termDescription}}</li>
              <li>{{course.fullDayOfWeekNames}} @ {{course.startTimeDate | worldDate:'HH:mm':classItem.startDateOffset:locale}}</li>
              <li>Booking Date {{classItem.startDate | worldDate: 'dd.MM.yy':classItem.startDateOffset:locale}}</li>
            </ul>
            </div>

            <div class="span-3 span-location" *ngIf="address != null">
              <span class="svg-icon map-marker"><svg class="svg-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-map-marker"></use></svg></span>				<ul>
              <li class="title">location</li>
              <li class="lead">{{course.venueName}}</li>
              <li *ngIf="address.addressLine1 != null">{{address.addressLine1}}</li>
              <li *ngIf="address.addressLine2 != null">{{address.addressLine2}}</li>
              <li *ngIf="address.addressLine3 != null">{{address.addressLine3}}</li>
              <li>{{address.locality}} {{address.postCode}}</li>
            </ul>
            </div>

            <div class="span-3 span-instructor">
              <span class="svg-icon child"><svg class="svg-child"><use xlink:href="./assets/sprite/spritemap.svg#sprite-child"></use></svg></span>				<ul>
              <li class="title">instructor</li>
              <li class="lead">{{course.instructorGivenName}}</li>
              <li class="image" *ngIf="course.instructorHasPhoto">
                <img src="{{getInstructorImageUrl(course.instructorId)}}" alt="{{course.instructorGivenName}}">
              </li>
            </ul>
            </div>

          </div>

        </div>
      </div>
      <!--<div class="wgt wgt-gallery">
      <div class="container">

        <div class="row row-gallery">

          <div class="span-4">
            <div class="thumb">
              <span style="background-image: url('./assets/images/gallery-hammersmit-1.jpg')"></span>
            </div>
          </div>

          <div class="span-4">
            <div class="thumb">
              <span style="background-image: url('./assets/images/gallery-hammersmit-2.jpg')"></span>
            </div>
          </div>

          <div class="span-4">
            <div class="thumb">
              <span style="background-image: url('./assets/images/gallery-hammersmit-3.jpg')"></span>
            </div>
          </div>

          <div class="span-4">
            <div class="thumb">
              <span style="background-image: url('./assets/images/gallery-hammersmit-4.jpg')"></span>
            </div>
          </div>

        </div>

      </div>
    </div>-->
      <div class="gmap" *ngIf="googleUrl != null">
        <iframe [src]="googleUrl" width="100%" height="374" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>

    </div>


  </main>
</div>

<app-footer></app-footer>
