import {ProgramLevel} from './program-level';
import {convertObjectToDate} from '../_helpers/date_helper';
import {CourseRestriction} from './course-restriction';

export class Course {
  id: string;
  type: string;
  classDurationInMinutes: number;
  numberOfStudentsPerInstructor: number;
  programLevel: ProgramLevel;
  bookingTypeEnum: string;
  startTimeDate: Date;
  startTimeHour: number;
  endTimeDate: Date;
  courseType: string;
  termDescription: string;
  venueName: string;
  venueId: string;
  venuePlaceId: string;
  fullDayOfWeekNames: string;
  instructorGivenName: string;
  instructorFamilyName: string;
  instructorId: string;
  instructorHasPhoto: boolean;
  dayOfTheWeekNumbers: number[];
  licenseeId: string;
  termType: string;
  venueImageExists: boolean;
  nextTermCourseId: string;
  termId: string;
  activeRestrictions: CourseRestriction[];

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.startTimeDate = convertObjectToDate(this.startTimeDate);
      this.endTimeDate = convertObjectToDate(this.endTimeDate);
    }
  }
}
