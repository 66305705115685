import {AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {Address} from '../../_model/address';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentMethod} from '../../_model/payment-method';
import {BasketService} from '../basket.service';
import {NewEnrolmentRequest} from '../../_model/new-enrolment-request';
import {NewEnrolmentRequestStudent} from '../../_model/new-enrolment-request-student';
import {NewEnrolmentResponse} from '../../_model/new-enrolment-response';
import {getPriceTypeShort, getPriceTypeTitle} from '../../_helpers/enrolment_helper';
import {PolicyResult} from '../../_model/policy-result';
import {AlertService} from '../../alert';
import {DirectDebitDetails} from '../../_model/direct-debit-details';
import {Router} from '@angular/router';
import {CheckoutPaymentMethodCardService} from './checkout-payment-method-card/checkout-payment-method-card.service';
import {TermsAndConditionsValidator} from '../../_helpers/terms_and_conditions_validator';
import {BasketStudent} from '../../_model/basket-student';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {AutoEnrolmentResponse} from '../../_model/auto-enrolment-response';
import {NewEnrolmentStudentResponse} from '../../_model/new-enrolment-student-response';
import moment from 'moment';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.css']
})
export class CheckoutPaymentComponent implements OnInit, OnDestroy, AfterContentInit {

  students: BasketStudent[];
  currencyCode: string;

  paymentForm: UntypedFormGroup;
  submitted = false;

  addressLinesActive = false;

  cardPayment = false;
  directDebitPayment = false;
  directDebitPaymentWithCardProRata = false;
  requiresMandate = false;

  enrolling = false;
  previewEnrolments: NewEnrolmentResponse;

  termsAndConditions: PolicyResult;
  venuePolicies: PolicyResult[];

  autoEnrolmentResponse: AutoEnrolmentResponse;
  autoEnrolmentLoaded = false;

  hasBacsTransaction = false;
  hasCardTransaction = false;
  currentTransactionType: string;
  settingUpMandate = false;
  directDebitDetails: DirectDebitDetails;

  receiptIds = [];
  hasLoaded = false;

  previewLoaded = false;
  termsLoaded = false;
  paymentGatewaysLoaded = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  cardSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  voucherSubscription: Subscription;
  addressSubscription: Subscription;

  hasDirectDebitSubscriptionPaymentOption = false;

  loading = true;
  requiresPaymentSelection = false;

  constructor(private apiService: ApiService,
              private basketService: BasketService,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private changeDetectorRef: ChangeDetectorRef,
              private alertService: AlertService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private spinner: NgxSpinnerService,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService) {
    this.students = sessionService.basket.students;
    this.currencyCode = sessionService.basket.currencyCode;
  }

  setPaymentValidators() {
    let paymentValidator = null;
    let addressValidator = null;
    let creditCardAddressValidator = null;

    if (this.previewEnrolments != null && this.previewEnrolments.totalAmountWithBalance > 0 && this.currentTransactionType === 'CARD') {
      paymentValidator = [Validators.required];

      if (this.sessionService.basket.cardPaymentMethod.cardId === 'new') {
        creditCardAddressValidator = [Validators.required];

        if (this.sessionService.basket.cardPaymentMethod.paymentAddressId === 'other') {
          addressValidator = [Validators.required];
        }
      } else {
        this.removeFormControlError('cardError');
        this.changeDetectorRef.detectChanges();
      }
    }

    this.paymentForm.get('creditCardSelect').setValidators(paymentValidator);
    this.paymentForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.paymentForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.paymentForm.get('addressLine1Input').setValidators(addressValidator);
    this.paymentForm.get('addressCityInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountyInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountryInput').setValidators(addressValidator);
    this.paymentForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.paymentForm.get('creditCardSelect').updateValueAndValidity();
    this.paymentForm.get('creditCardNameInput').updateValueAndValidity();
    this.paymentForm.get('paymentAddress').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressCityInput').updateValueAndValidity();
    this.paymentForm.get('addressCountyInput').updateValueAndValidity();
    this.paymentForm.get('addressCountryInput').updateValueAndValidity();
    this.paymentForm.get('addressPostcodeInput').updateValueAndValidity();
    this.paymentForm.updateValueAndValidity();

    if (this.hasLoaded) {
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.loading = true;

    this.paymentForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      creditCardSelect: [''],
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      paymentMethodInput: ['']
    });

    const formControlFields = [];
    this.sessionService.basket.students.forEach(basketStudent => {
      formControlFields.push({name: 'enrolInNextTermCheckbox_' + basketStudent.student.id, control: new UntypedFormControl('')});
    });

    formControlFields.forEach(f => this.paymentForm.addControl(f.name, f.control));

    this.cardSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.sessionService.basket.cardPaymentMethod = paymentMethod;
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && !this.loading) {
        if (hasErrors) {
          this.enrolling = false;
          this.spinner.hide();
        } else {
          this.enrolStudents();
        }
      }
    });

    this.voucherSubscription = this.basketService.currentVoucherCode.subscribe(voucherCode => {
      if (voucherCode != null && !this.loading) {
        if (voucherCode === 'NULL') {
          this.sessionService.basket.voucherCode = null;
        } else {
          this.sessionService.basket.voucherCode = voucherCode;
        }

        // Update the basket in the session
        this.sessionService.saveBasket();

        this.previewEnrolment();
      }
    });

    this.hasLoaded = true;

    this.addressSubscription = this.basketService.currentAddress.subscribe(address => {
      if (address != null && this.sessionService.basket != null && this.sessionService.basket.cardPaymentMethod != null && !this.loading) {
        this.sessionService.basket.cardPaymentMethod.address = address;
        this.changeDetectorRef.detectChanges();
      }
    });

    const paymentTypes = [];
    this.hasDirectDebitSubscriptionPaymentOption = false;
    this.sessionService.basket.students.forEach(courseStudent => {
      let paymentType: string;

      if (courseStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT' && courseStudent.coursePrice.requiresPaymentSelection) {
        this.requiresPaymentSelection = true;
        this.hasDirectDebitSubscriptionPaymentOption = true;

        if (paymentTypes.indexOf('MONTHLY_DIRECT_DEBIT') < 0) {
          paymentTypes.push('MONTHLY_DIRECT_DEBIT');
        }

        if (paymentTypes.indexOf('CARD') < 0) {
          paymentTypes.push('CARD');
        }
      } else {
        if (!this.requiresPaymentSelection && courseStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT') {
          paymentType = courseStudent.coursePrice.priceType;
          this.hasDirectDebitSubscriptionPaymentOption = true;
          this.hasBacsTransaction = true;
        } else if (!this.requiresPaymentSelection) {
          paymentType = 'CARD';
          this.hasCardTransaction = true;
        }

        if (paymentTypes.indexOf(paymentType) < 0) {
          paymentTypes.push(paymentType);
        }
      }

      if (courseStudent.canEnrolInNextTerm) {
        const control = this.paymentForm.controls['enrolInNextTermCheckbox_' + courseStudent.id];
        if (control != null) {
          control.setValue(courseStudent.enrolInNextTerm);
        }
      }
    });

    if (this.hasBacsTransaction && this.directDebitPaymentWithCardProRata) {
        this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';
     } else if (this.hasBacsTransaction) {
       this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT';
    } else {
      this.currentTransactionType = 'CARD';
    }

    this.setPaymentValidators();

    this.apiService.getPaymentGateways(paymentTypes, this.sessionService.basket.licenseeId,
      this.sessionService.basket.accountId, this.ngUnsubscribe)
      .subscribe(paymentGateways => {
      paymentGateways.forEach(paymentGateway => {

        if (paymentGateway.paymentGatewayType === 'CARD') {
          this.cardPayment = true;
        } else {
          this.directDebitPayment = !paymentGateway.monthlyWithCardProRata;
          this.directDebitPaymentWithCardProRata = paymentGateway.monthlyWithCardProRata;
          this.requiresMandate = !paymentGateway.hasMandate;

          if (!this.requiresMandate) {
            this.directDebitDetails = paymentGateway.directDebitDetails;
          }

          if (this.hasBacsTransaction && this.directDebitPaymentWithCardProRata) {
            this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';

            if (!this.requiresPaymentSelection) {
              this.paymentForm.patchValue({'paymentMethodInput': 'MONTHLY_DIRECT_DEBIT'})
            }
          }

          this.changeDetectorRef.detectChanges();
        }
      });
    }, () => {}, () => {
      this.paymentGatewaysLoaded = true;
    });

    const enrolmentTypes = [];
    this.sessionService.basket.students.forEach(student => {
      if (!enrolmentTypes.includes(student.coursePrice.priceType)) {
        enrolmentTypes.push(student.coursePrice.priceType);
      }
    });

    // Auto enrolment does not apply to monthly payments
    this.apiService.getAutoEnrolmentSettings(this.sessionService.basket.licenseeId, this.sessionService.basket.accountId, enrolmentTypes, this.ngUnsubscribe)
      .subscribe(autoEnrolmentResponse => {
        this.autoEnrolmentResponse = autoEnrolmentResponse;
        this.changeDetectorRef.detectChanges();
      }, () => {
      }, () => {
        this.autoEnrolmentLoaded = true;
        this.changeDetectorRef.detectChanges();
      });

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;
      if (this.termsAndConditions != null && this.termsAndConditions.valid) {
        this.sessionService.basket.termsAndConditionsPolicyId = this.termsAndConditions.id;
        this.paymentForm.addControl('enrolmentTermsCheckbox', new UntypedFormControl('', TermsAndConditionsValidator()));
      }
    }, () => {}, () => {
      this.termsLoaded = true;
    });

    this.venuePolicies = [];
    const venueIds = [];
    this.sessionService.basket.students.forEach(courseStudent => {
      const venueId = courseStudent.course.venueId;
      if (!venueIds.includes(venueId)) {
        venueIds.push(venueId);
        this.apiService.getVenuePolicies(venueId, this.ngUnsubscribe).subscribe(policies => {
          const policyFormControlFields = [];
          policies.forEach(policy => {
            if (!this.venuePolicies.includes(policy)) {
              this.venuePolicies.push(policy);
              policyFormControlFields.push({ name: 'policyCheckbox_' + policy.id, control: new UntypedFormControl('', Validators.required) });
            }
          });
          policyFormControlFields.forEach(f => this.paymentForm.addControl(f.name, f.control));
        });
      }
    });

    if (this.sessionService.basket.cardPaymentMethod == null) {
      this.sessionService.basket.cardPaymentMethod = new PaymentMethod();
      this.sessionService.basket.cardPaymentMethod.address = new Address();
    } else if (this.sessionService.basket.cardPaymentMethod.address == null) {
      this.sessionService.basket.cardPaymentMethod.address = new Address();
    }

    this.previewEnrolment();
    this.setPaymentValidators();
  }

  ngAfterContentInit() {
    this.loading = false;
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.cardSubscription != null) {
      this.cardSubscription.unsubscribe();
    }
    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }
    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }
    if (this.voucherSubscription != null) {
      this.voucherSubscription.unsubscribe();
    }
    if (this.addressSubscription != null) {
      this.addressSubscription.unsubscribe();
    }
  }

  previewEnrolment(): void {
    this.previewEnrolments = null;
    this.apiService.previewEnrolment(this.generateEnrolmentObject()).subscribe(response => {
      this.previewEnrolments = response;
      this.changeDetectorRef.detectChanges();

      this.previewEnrolments.studentEnrolments.forEach(studentResponse => {
        this.sessionService.basket.students.forEach(basketStudent => {
          if (basketStudent.student.studentId === studentResponse.studentId) {
            basketStudent.canEnrolInNextTerm = studentResponse.canEnrolInNextTerm;
            if (!studentResponse.canEnrolInNextTerm || basketStudent.enrolInNextTerm == null) {
              basketStudent.enrolInNextTerm = false;
            }
            basketStudent.nextTermDescription = studentResponse.nextTermDescription;
            const nextTermCheckbox = this.paymentForm.get('enrolInNextTermCheckbox_' + basketStudent.id);

            if (nextTermCheckbox != null) {
              nextTermCheckbox.setValue(basketStudent.enrolInNextTerm);
            } else {
              console.log('Checkbox for enrol in next term doesn\'t exist [' + 'enrolInNextTermCheckbox_' + basketStudent.id + ']');
            }
          }
        });
      });

      if (response.result === 'error') {
        this.alertService.error(response.error);
      }
    }, () => {}, () => {
      this.previewLoaded = true;
    });
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  onPaymentSubmit(): void {
    if (this.enrolling) {
      return;
    }

    this.submitted = true;

    if (this.termsAndConditions != null && this.termsAndConditions.valid) {
      this.sessionService.basket.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    } else {
      this.sessionService.basket.acceptedTermsAndConditions = false;
    }

    this.sessionService.basket.students.forEach(courseStudent => {
      if (courseStudent.canEnrolInNextTerm) {
        // Need to check whether it is showing on the display
        const element = this.paymentForm.get('enrolInNextTermCheckbox_' + courseStudent.id);
        if (element != null) {
          courseStudent.enrolInNextTerm = element.value;
        } else {
          courseStudent.enrolInNextTerm = false;
        }
      }
    });

    if (this.sessionService.basket.cardPaymentMethod.cardId === 'new' && this.sessionService.basket.cardPaymentMethod.cardHasErrors) {
      this.paymentForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
    }

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      if (!this.addressLinesActive) {
        this.addressLinesActive = !!(this.submitted
          && (this.paymentForm.controls.addressLine1Input.errors
            || this.paymentForm.controls.addressCityInput.errors
            || this.paymentForm.controls.addressCountyInput.errors
            || this.paymentForm.controls.addressCountryInput.errors
            || this.paymentForm.controls.addressPostcodeInput.errors));
      }

      return;
    }

    this.enrolling = true;
    this.spinner.show();

    if ((this.currentTransactionType === 'CARD' || this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && this.sessionService.basket.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else {
      this.enrolStudents();
    }
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  generateEnrolmentObject(): NewEnrolmentRequest {
    const request = new NewEnrolmentRequest();

    request.accountId = this.sessionService.basket.accountId;
    request.licenseeId = this.sessionService.basket.licenseeId;
    request.voucherCode = this.sessionService.basket.voucherCode;

    if ((this.currentTransactionType === 'CARD' || this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && this.previewEnrolments != null && this.previewEnrolments.totalAmountWithBalance > 0) {
      request.cardId = this.sessionService.basket.cardPaymentMethod.cardId;
      request.paymentIntentId = this.sessionService.basket.cardPaymentMethod.paymentIntentId;
    }

    const studentList = [];
    this.sessionService.basket.students.forEach(basketStudent => {

      if (this.currentTransactionType === 'CARD' ||
        ((this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT' || this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD')
          && basketStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT')
        || (this.requiresPaymentSelection)) {
        const studentRequest = new NewEnrolmentRequestStudent();

        studentRequest.courseId = basketStudent.course.id;

        if (basketStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT') {
          studentRequest.enrolmentType =this.hasBacsTransaction ? 'MONTHLY_DIRECT_DEBIT' : 'MONTHLY_CREDIT_CARD';
        } else {
          studentRequest.enrolmentType = basketStudent.coursePrice.priceType;
        }

        studentRequest.programLevelId = basketStudent.student.programLevelId;
        studentRequest.startDate = basketStudent.startDate;
        studentRequest.studentId = basketStudent.student.studentId;
        studentRequest.enrolInNextTerm = basketStudent.enrolInNextTerm;

        studentList.push(studentRequest);
      }
    });

    request.studentEnrolments = studentList;

    request.acceptedPolicyIds = [];

    if (this.termsAndConditions != null && this.termsAndConditions.valid) {
      request.acceptedTermsAndConditions = this.sessionService.basket.acceptedTermsAndConditions;
      if (request.acceptedTermsAndConditions) {
        request.acceptedPolicyIds.push(this.sessionService.basket.termsAndConditionsPolicyId);
      }
    } else {
      request.acceptedTermsAndConditions = false;
    }

    // You have to accept venue policies, so adding them all
    this.venuePolicies.forEach(policy => {
      request.acceptedPolicyIds.push(policy.id);
    });

    if (this.previewEnrolments != null) {
      request.quotedAmount = this.previewEnrolments.totalAmountWithBalance;
    }

    return request;
  }

  enrolStudents() {
    this.alertService.clear();

    this.apiService.enrol(this.generateEnrolmentObject()).subscribe(response => {
      let receiptId = null;
      this.enrolling = false;
      this.spinner.hide();
      if (response.result === 'success') {
        if (this.currentTransactionType === 'CARD') {
          if (response.sportsPayment != null
            && response.sportsPayment.payment != null
            && response.sportsPayment.payment.code !== '') {
            this.receiptIds.push(response.sportsPayment.payment.code);
            receiptId = response.sportsPayment.payment.code;
            this.alertService.success('Enrolment complete, receipt number: ' + response.sportsPayment.payment.code);
          } else {
            this.alertService.success('Enrolment complete');
          }

          // Must be up the top
          this.googleAnalytics.payment(this.sessionService.basket, receiptId, response.totalAmountWithBalance, 'New Enrolment');

          this.navigateToReceipt();
        } else {
          if (response.directDebitPendingPayment != null && response.directDebitPendingPayment.reference !== '') {
            this.receiptIds.push(response.directDebitPendingPayment.reference);
            receiptId = response.directDebitPendingPayment.reference;
            this.alertService.success('Enrolment complete, receipt number: ' + response.directDebitPendingPayment.reference);

            // Must be up the top
            this.googleAnalytics.payment(this.sessionService.basket, receiptId, response.totalAmountWithBalance, 'New Enrolment');
          } else {
            const invoiceIds = [];
            if (response.studentEnrolments != null) {
              response.studentEnrolments.forEach(studentEnrolment => {
                if (studentEnrolment.invoices != null) {
                  studentEnrolment.invoices.forEach(invoice => {
                    invoiceIds.push(invoice.code);
                  });
                }
              });
            }

            if (invoiceIds.length > 0) {
              this.googleAnalytics.payment(this.sessionService.basket, invoiceIds.join('-'), response.totalAmountWithBalance, 'New Enrolment');
              this.alertService.success('Enrolment complete, receipt number' + (invoiceIds.length > 1 ? 's' : '') + ': ' + invoiceIds.join(', '));
            } else {
              this.googleAnalytics.payment(this.sessionService.basket, String(new Date().getTime()), response.totalAmountWithBalance, 'New Enrolment');
              this.alertService.success('Enrolment complete');
            }
          }

          if (this.hasCardTransaction) {
            this.currentTransactionType = 'CARD';
            this.sessionService.basket.acceptedTermsAndConditions = false;
            this.setPaymentValidators();

            const indexesToRemove = [];

            let index = 0;
            this.sessionService.basket.students.forEach(basketStudent => {
              if (basketStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT') {
                indexesToRemove.push(index);
              }
              index++;
            });

            indexesToRemove.forEach(indexNumber => {
              this.sessionService.basket.students.splice(indexNumber, 1);
            });

            this.sessionService.saveBasket();

            this.previewEnrolment();
          } else {
            this.navigateToReceipt();
          }
        }
      } else if (response.result === 'requires_action') {
        if (response.requiresPaymentAction) {
          console.log('requires payment action');
          this.enrolling = true;
          this.spinner.show();
          this.checkoutPaymentMethodCardService.setHandleStripeAction(response.paymentIntentClientSecret);
        } else {
          console.error('Requires action, though action parameter not true');
        }
      } else if (response.result === 'payment_error') {
        this.alertService.error(response.error);
      } else if (response.result === 'error') {
        if (response.error != null) {
          this.alertService.error(response.error);
        } else {
          this.alertService.error('An unknown error has occurred');
        }
      } else {
        console.error('Unknown response: ' + response.result);
      }

    });
  }

  navigateToReceipt() {
    const receiptIds = this.receiptIds.join(',');

    this.sessionService.removeBasket();

    this.router.navigate(['/booking_complete'], {queryParams: {receiptIds}});
  }

  setupDirectDebit() {
    if (this.settingUpMandate) {
      return;
    }

    // We need to save the basket, as we no longer need to select the payment type
    this.sessionService.basket.students.forEach(student => {

      if (student.coursePrice.requiresPaymentSelection) {
        student.coursePrice.requiresPaymentSelection = false;
        student.coursePrice.priceType = 'MONTHLY_DIRECT_DEBIT';
      }

    });

    this.sessionService.saveBasket();

    this.settingUpMandate = true;
    this.apiService.setupMandate(this.sessionService.basket.licenseeId, this.sessionService.basket.accountId, 'checkout', null).subscribe(url => {
      window.location.href = url;
    });
  }

  formatPriceTypeTitle(studentResponse: NewEnrolmentStudentResponse): string {
    return getPriceTypeTitle(studentResponse.enrolmentType);
  }

  formatPriceTypeShort(enrolmentType: string): string {
    return getPriceTypeShort(enrolmentType);
  }

  setEnrolInNextTerm(student: BasketStudent, checked: boolean): void {
    student.enrolInNextTerm = checked;
    this.previewEnrolment();
  }

  paymentTypeSelected(paymentType: string): void {
    this.hasBacsTransaction = (paymentType === 'MONTHLY_DIRECT_DEBIT');

    if (this.hasBacsTransaction && this.directDebitPaymentWithCardProRata) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';
    } else if (this.hasBacsTransaction) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT';
    } else {
      this.currentTransactionType = 'CARD';
    }

    this.requiresPaymentSelection = false;

    this.changeDetectorRef.detectChanges();
  }

  getMonthlyAmount(studentResponse: NewEnrolmentStudentResponse) {
    const discount = studentResponse.monthlyDiscount;

    if (discount == null) {
      return studentResponse.monthlyPrice.amount;
    }

    if (discount.type === 'PERCENTAGE') {
      return studentResponse.monthlyPrice.amount - (studentResponse.monthlyPrice.amount * (discount.amount / 100));
    } else {
      let discountAmount = studentResponse.monthlyPrice.amount - discount.amount;
      if (discountAmount < 0) {
        discountAmount = 0;
      }
      return discountAmount;
    }
  }

  getMonthlyEndDate(studentResponse: NewEnrolmentStudentResponse): Date {
    const nowDate = moment(studentResponse.monthlyContractStartDate);
    return nowDate.add(studentResponse.monthlyDiscount.subscriptionPeriodValue, 'month').subtract(1, 'day').toDate();
  }
}
