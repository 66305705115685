import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PhoneNumberValidator} from '../../_helpers/phone_number_validator';
import {formatNumber} from '../../_helpers/telephone_helper';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {getErrorMessage} from '../../_helpers/http_log_helper';
import {TitleGenerator} from '../../title-generator';
import {ApplicationSettingsService} from '../../application-settings.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../auth-service';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.css']
})
export class RequestAccessComponent implements OnInit, OnDestroy {

  requestAccessForm: UntypedFormGroup;
  requestType: string;
  submitted = false;
  runningRequestAccess = false;
  email: string;
  mobileTelephone: string;
  error: string;
  emailSent = false;

  countryCode: string;

  applicationSettingsSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private apiService: ApiService,
              private router: Router,
              private authService: AuthService,
              private changeDetectorRef: ChangeDetectorRef,
              private applicationSettings: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Request Access');

    this.countryCode = this.applicationSettings.countryCode;

    this.requestAccessForm = this.formBuilder.group({
      requestAccessCheckbox: ['', Validators.required],
      phoneNumberInput: [''],
      emailInput: ['']
    });

    this.renderer.addClass(document.body, 'request-access');
    this.requestAccessForm.get('requestAccessCheckbox').setValue('email');
    this.requestType = 'email';
    this.setRequestValidators();
  }

  ngOnDestroy(): void {
    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'request-access');
  }

  setRequestValidators() {
    this.requestType = this.requestAccessForm.get('requestAccessCheckbox').value;

    let emailValidator = null;
    let textValidator = null;
    if (this.requestType === 'email') {
      emailValidator = [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required];
    } else {
      if (this.countryCode == null) {
        textValidator = [Validators.required];
      } else {
        textValidator = [PhoneNumberValidator(this.countryCode), Validators.required];
      }
    }

    this.requestAccessForm.get('emailInput').setValidators(emailValidator);
    this.requestAccessForm.get('phoneNumberInput').setValidators(textValidator);

    this.requestAccessForm.get('emailInput').updateValueAndValidity();
    this.requestAccessForm.get('phoneNumberInput').updateValueAndValidity();

    this.requestAccessForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  getRequestControl(componentName: string) {
    return this.requestAccessForm.get(componentName);
  }

  onRequestAccessSubmit() {
    this.submitted = true;
    if (this.requestAccessForm.invalid || this.runningRequestAccess) {
      return;
    }

    this.runningRequestAccess = true;
    this.error = null;

    let requestEmail = null;
    let requestMobile = null;

    if (this.requestType === 'email') {
      this.email = this.requestAccessForm.get('emailInput').value;
      requestEmail = this.email;
    } else {
      this.mobileTelephone = this.requestAccessForm.get('phoneNumberInput').value;
      requestMobile = this.mobileTelephone;
    }

    this.apiService.requestAccess(requestEmail, requestMobile).subscribe(requestAccessResponse => {
      if (requestAccessResponse.status === 'success') {
        if (this.requestType === 'email') {
          this.emailSent = true;
        } else {
          this.router.navigate(['/activate_account/sms']);
        }
      } else if (requestAccessResponse.status === 'error' || requestAccessResponse.status === 'already-registered') {
        this.error = requestAccessResponse.error;
        this.runningRequestAccess = false;
      } else {
        console.error('Unknown request access status: ' + requestAccessResponse.status);
      }
    },
      error => {
        this.runningRequestAccess = false;
        this.error = getErrorMessage(error);
      });
  }

  formatMobile() {
    this.mobileTelephone = formatNumber(this.requestAccessForm.get('phoneNumberInput').value, this.countryCode);
    this.requestAccessForm.get('phoneNumberInput').setValue(this.mobileTelephone);
  }

  doLogin() {
    this.authService.loginAndRedirectToDashboard();
  }
}
