import {Component, Input, OnInit} from '@angular/core';
import {Enrolment} from '../../_model/enrolment';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {DashboardService} from '../dashboard.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dashboard-provisional',
  templateUrl: './dashboard-provisional.component.html',
  styleUrls: ['./dashboard-provisional.component.css']
})
export class DashboardProvisionalComponent implements OnInit {

  @Input() provisional: EnrolmentResponse;
  @Input() provisionalEnrolments: EnrolmentResponse[];

  constructor(private dashboardService: DashboardService,
              private router: Router) {}

  ngOnInit(): void {
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  cancelBooking(provisional: EnrolmentResponse) {
    const referrer = this.router.url;
    this.router.navigate(['/dashboard_booking_cancel', provisional.enrolmentId], {queryParams: {referrer}}).then(() => {
      this.dashboardService.setProvisionalEnrolments(this.provisionalEnrolments);
    });
  }

  confirmBooking(provisional: EnrolmentResponse) {
    const referrer = this.router.url;
    this.router.navigate(['/dashboard_booking_confirm', provisional.enrolmentId], {queryParams: {referrer}}).then(() => {
      this.dashboardService.setProvisionalEnrolments(this.provisionalEnrolments);
    });
  }
}
