<span [formGroup]="addressForm" *ngIf="address != null">
<div class="row row-address row-checkmark address-box-student">

  <div class="span-input span-4 span-text required">
    <input
      type="text"
      id="postcodeLookup"
      name="postcodeLookup"
      class="input-text postcode"
      formControlName="postcodeLookupInput"
      (focusout)="postcodeFocusOut()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Postcode'"
    />
    <label
      for="postcodeLookup"
      class="floating-label">
      postcode
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled">
        </ul>
  </div><div class="span-input span-4 span-text required">
  <input
    type="text"
    id="postcodeLookupHouseNumber"
    name="postcodeLookupHouseNumber"
    class="input-text"
    formControlName="postcodeLookupHouseNumberInput"
    appParsleyValidation
    [submitted]="submitted"
    [elementName]="'House Number'"
  />
  <label
    for="postcodeLookupHouseNumber"
    class="floating-label">
    house number
  </label>
  <i class="asterisk">*</i>
  <i class="checkmark"></i>
</div>

  <div class="span-input span-4 span-button row-checkmark-last">
    <button type="button" class="address-manual" data-toggle="collapsable" data-target="address_lines" *ngIf="!addressLinesActive" (click)="addressLinesActive = true">
      Enter Address Manually
    </button>
    <button type="button" (click)="lookupAddress()" class="btn btn-red address-find" data-target="address-box-student">
      find address
    </button>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
  </div>
</div>

<div id="address_results" class="row address_results" *ngIf="postcodeAddresses != null">
  <div class="span-input span-text">
    <select
      type="text"
      id="postcodeResults"
      name="postcodeResults"
      class="input-select"
      formControlName="postcodeResultsSelect"
      (change)="addressListChange()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Address'"
    >
      <option value="" disabled selected hidden>Select address</option>
      <option *ngFor="let address of postcodeAddresses" value="{{address.id}}">{{address.summaryline}}</option>
    </select>
    <label
      for="postcodeLookup"
      class="floating-label">
      addresses found
    </label>
  </div>
</div>

<div id="address_lines" class="span-12 address-lines toggler" [ngClass]="{ 'active': addressLinesActive}">
  <div class="row">

    <div class="span-12 span-input span-text required">
      <input
        type="text"
        id="addressLine1"
        name="addressLine1"
        class="input-text address-1"
        formControlName="addressLine1Input"
        (change)="addressFieldUpdated()"
        appParsleyValidation
        [submitted]="submitted"
        [elementName]="'Address Line 1'"
      />
      <label
        for="addressLine1"
        class="floating-label">
        Address Line 1	</label>
      <i class="asterisk">*</i>
      <i class="checkmark"></i>
      <ul class="parsley-errors-list filled">
        </ul>
    </div><div class="span-12 span-input span-text required">
    <input
      type="text"
      id="addressLine2"
      name="addressLine2"
      class="input-text address-2"
      formControlName="addressLine2Input"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Address Line 2'"
    />
    <label
      for="addressLine2"
      class="floating-label">
      Address Line 2	</label>
    <i class="checkmark"></i>
  </div><div class="span-12 span-input span-text required">
    <input
      type="text"
      id="addressLine3"
      name="addressLine3"
      class="input-text address-3"
      formControlName="addressLine3Input"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Address Line 3'"
    />
    <label
      for="addressLine3"
      class="floating-label">
      Address Line 3	</label>
    <i class="checkmark"></i>
  </div><div class="span-12 span-input span-text required">
    <input
      type="text"
      id="addressCity"
      name="addressCity"
      class="input-text address-city"
      formControlName="addressCityInput"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'City'"
    />
    <label
      for="addressCity"
      class="floating-label">
      City
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled"></ul>
  </div><div class="span-12 span-input span-text required">
    <input
      type="text"
      id="addressCounty"
      name="addressCounty"
      class="input-text address-city"
      formControlName="addressCountyInput"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'County'"
    />
    <label
      for="addressCounty"
      class="floating-label">
      County
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled"></ul>
  </div><div class="span-12 span-input span-text required">
    <select
      id="addressCountry"
      name="addressCountry"
      class="input-text address-city"
      formControlName="addressCountryInput"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Country'"
    >
      <option *ngFor="let country of paymentCountries" value="{{country.countryCode}}">{{country.country}}</option>
    </select>
    <label
      for="addressCountry"
      class="floating-label">
      Country
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled"></ul>
  </div><div class="span-12 span-input span-text required">
    <input
      type="text"
      id="addressPostcode"
      name="addressPostcode"
      class="input-text address-city"
      formControlName="addressPostcodeInput"
      (change)="addressFieldUpdated()"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Postcode'"
    />
    <label
      for="addressPostcode"
      class="floating-label">
      Postcode
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled"></ul>
  </div>

  </div>
</div>
</span>
