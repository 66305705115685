import {convertObjectToDate} from '../_helpers/date_helper';
import {SportsPayment} from './sports-payment';
import {PendingPayment} from './pending-payment';

export class TransferResponse {
  currencyCode: string;
  totalAmount: number;
  startDate: Date;
  reasons: string[];
  newEnrolmentId: string;
  sportsPayment: SportsPayment;
  result: string;
  directDebitPendingPayment: PendingPayment;
  error: string;
  paymentIntentClientSecret: string;
  requiresPaymentAction: boolean;
  accountBalance: number;
  totalAmountWithBalance: number;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.startDate = convertObjectToDate(this.startDate);
    }
  }
}
