export class SubscriptionRequest {
  marketingEmails: boolean;
  marketingPartners: boolean;
  marketingPhone: boolean;
  marketingSMS: boolean;
  operationsInfoPhone: boolean;
  operationsInfoEmail: boolean;
  operationsInfoSMS: boolean;
  abandonedCart: boolean;
  dailyFinancialSnapshot: boolean;
  weeklyMarketingReport: boolean;
  weeklyMasterLicenseePerformance: boolean;
  weeklyLicenseePerformance: boolean;
  weeklyVenuePerformance: boolean;
  weeklyOperatorPerformance: boolean;
  weeklyInstructorPerformance: boolean;
}
