import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {StudentName} from '../find-course-multiple-student/student-name';

@Injectable({
  providedIn: 'root'
})
export class FindCourseMultipleService {

  currentStudentName: Observable<StudentName>;
  private studentNameSubject = new BehaviorSubject<StudentName>(null);

  constructor() {
    this.currentStudentName = this.studentNameSubject.asObservable();
  }

  setStudentName(studentName: StudentName) {
    this.studentNameSubject.next(studentName);
  }
}
