import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControlName} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appParsleyValidation]'
})
export class ParsleyValidationDirective implements OnInit, OnDestroy {

  formSubmitted: boolean;
  @Input() elementName: string;
  checkboxRequireChecked: boolean;

  valueSub: Subscription;

  constructor(private el: ElementRef,
              private formControlName: FormControlName, private renderer2: Renderer2) { }

  ngOnInit() {
    // Listen value changes
    this.valueSub = this.formControlName.valueChanges.subscribe(value => {
      this.processValidation(value);
    });
    this.processValidation(this.el.nativeElement.value);
  }

  processValidation(value: string) {
    if (!this.hasNoValue(value)) {
      this.renderer2.addClass(this.el.nativeElement, 'has-value');
    } else {
      this.renderer2.removeClass(this.el.nativeElement, 'has-value');
    }

    if (this.formControlName.errors || this.hasNoValue(value)) {
      this.renderer2.removeClass(this.el.nativeElement, 'parsley-success');
    } else {
      this.renderer2.addClass(this.el.nativeElement, 'parsley-success');
    }

    const asteriskDiv = this.el.nativeElement.parentElement.querySelector('.asterisk');
    if (asteriskDiv != null) {
      if (this.formControlName.errors && asteriskDiv.classList.contains('hide-on-error')) {
        if (this.formSubmitted) {
          asteriskDiv.style.display = 'none';
        } else {
          asteriskDiv.style.display = 'block';
        }
      } else if (this.formControlName.errors && !asteriskDiv.classList.contains('hide-on-error')) {
        asteriskDiv.style.display = 'block';
      } else {
        asteriskDiv.style.display = 'none';
      }
    }

    if (this.formSubmitted) {
      let errorDiv = this.el.nativeElement.parentElement.querySelector('.parsley-errors-list');

      if (errorDiv == null && this.el.nativeElement.parentElement.tagName.toLowerCase() === 'label') {
        errorDiv = this.el.nativeElement.parentElement.parentElement.querySelector('.parsley-errors-list');
      }

      if (errorDiv != null) {
        while (errorDiv.firstChild) {
          errorDiv.removeChild(errorDiv.lastChild);
        }

        let counter = 0;
        if (this.formControlName.errors) {
          if (this.formControlName.errors.required) {
            errorDiv.appendChild(this.generateNode(this.elementName + ' is required'));
            counter++;
          }
          if (this.formControlName.errors.patter) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode(this.elementName + ' does not look correct'));
            counter++;
          }
          if (this.formControlName.errors.invalidNumber) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode(this.elementName + ' is invalid'));
            counter++;
          }
          if (this.formControlName.errors.cardError) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('??????????????'));
            counter++;
          }
          if (this.formControlName.errors.pattern) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode(this.elementName + ' does not look to be valid'));
            counter++;
          }
          if (this.formControlName.errors.emailNotMatch) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('Emails do not match'));
            counter++;
          }
          if (this.formControlName.errors.passwordNotMatch) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('Passwords do not match'));
            counter++;
          }
          if (this.formControlName.errors.minlength) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('Password must be at least 6 characters'));
            counter++;
          }
          if (this.formControlName.errors.weak) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('Password is not strong enough, it must contain a ' +
              'combination of lowercase, uppercase, numbers and a special characters'));
            counter++;
          }
          if (this.formControlName.errors.dateOfBirthInvalid || this.formControlName.errors.dateOfBirth) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode(this.elementName + ' is invalid'));
            counter++;
          }
          if (this.formControlName.errors.notSet) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode(this.elementName + ' is not set'));
            counter++;
          }
          if (this.formControlName.errors.requiresAtLeastOne) {
            this.appendNewLine(errorDiv, counter);
            errorDiv.appendChild(this.generateNode('Telephone home or mobile must be completed'));
            counter++;
          }
        }
      }
    }
  }

  hasNoValue(value: string): boolean {
    if (this.el.nativeElement.type === 'checkbox' && this.checkboxRequireChecked != null && this.checkboxRequireChecked) {
      return !this.el.nativeElement.checked;
    }

    return value == null || value === '';
  }

  appendNewLine(errorDiv: any, elementCounter: number) {
    if (elementCounter > 0) {
      const node = document.createElement('div');
      node.classList.add('flex-break');
      errorDiv.appendChild(node);
    }
  }

  generateNode(errorValue: string, ): any {
    const node = document.createElement('li');                 // Create a <li> node
    node.classList.add('parsley-required');
    const textNode = document.createTextNode(errorValue);         // Create a text node
    node.appendChild(textNode);                              // Append the text to <li>

    return node;
  }

  ngOnDestroy() {
    // Un-listen value changes
    if (this.valueSub != null) {
      this.valueSub.unsubscribe();
    }
  }

  get submitted() {
    return this.formSubmitted;
  }

  @Input()
  set submitted(submitted) {
    this.formSubmitted = submitted;
    this.processValidation(this.el.nativeElement.value);
  }

  get requireChecked() {
    return this.checkboxRequireChecked;
  }

  @Input()
  set requireChecked(requireChecked) {
    this.checkboxRequireChecked = requireChecked;
    this.processValidation(this.el.nativeElement.value);
  }
}
