import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ApplicationSettingsService} from '../application-settings.service';

@Pipe({
  name: 'worldDate'
})
export class WorldDatePipe implements PipeTransform {

  timezone: string;
  private readonly localeValue: string;

  constructor(@Inject(LOCALE_ID) locale: string,
              private datePipe: DatePipe,
              private applicationSettings: ApplicationSettingsService) {
    this.timezone = this.applicationSettings.timezoneOffset;
    this.localeValue = this.applicationSettings.locale;
  }

  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null {
    if (this.timezone != null && timezone == null) {
      timezone = this.timezone;
      locale = this.localeValue;
    }
    return this.datePipe.transform(value, format, timezone, locale);
  }

}
