import {Account} from './account';
import {AccountSummaryStudent} from './account-summary-student';

export class AccountSummary {
  accounts: Account[];
  studentSummaries: AccountSummaryStudent[];

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
