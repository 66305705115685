import {Component, OnDestroy, OnInit} from '@angular/core';
import {BookingHeaderTitleServiceService} from './booking-header-title-service.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-booking-header',
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.css']
})
export class BookingHeaderComponent implements OnInit, OnDestroy {

  title = '';
  showCourseSort = false;

  titleService: Subscription;
  showFilterService: Subscription;

  constructor(private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService) {}

  ngOnInit(): void {
    this.titleService = this.bookingHeaderTitleServiceService.currentTitle.subscribe(message => this.title = message);
    this.showFilterService = this.bookingHeaderTitleServiceService.currentShowFilter
      .subscribe(showFilter => this.showCourseSort = showFilter);
  }

  ngOnDestroy() {
    if (this.titleService != null) {
      this.titleService.unsubscribe();
    }
    if (this.showFilterService != null) {
      this.showFilterService.unsubscribe();
    }
  }
}
