import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentMethod} from '../../../_model/payment-method';
import {Card} from '../../../_model/card';
import {UntypedFormGroup} from '@angular/forms';
import {Address} from '../../../_model/address';
import {ApiService} from '../../../api.service';
import {getCardType} from '../../../_helpers/card_helper';
import {CheckoutPaymentMethodCardService} from './checkout-payment-method-card.service';
import {AlertService} from '../../../alert';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Subject, Subscription} from 'rxjs';
import {BasketService} from '../../basket.service';
import {SessionService} from '../../../session.service';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import {Stripe} from '@stripe/stripe-js';
import {GoogleAnalyticsService} from '../../../google-analytics.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-checkout-payment-method-card',
  templateUrl: './checkout-payment-method-card.component.html',
  styleUrls: ['./checkout-payment-method-card.component.css']
})
export class CheckoutPaymentMethodCardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('cardElement') cardElement: ElementRef;
  @Input() submitted: boolean;
  @Input() paymentForm: UntypedFormGroup;
  @Input() addressLinesActive = false;

  cardPaymentMethod: PaymentMethod;
  @Input() accountId: string;
  @Input() licenseeId: string;
  @Input() selectedCardId: string;
  @Input() totalAmount: number;
  @Input() listType: string;
  @Input() updateInvoices: boolean;
  stripe: Stripe;
  card;
  stripeMounted = false;

  cards: Card[];
  paymentAddresses: Address[];
  paymentAddressId: string;

  countryCode: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  settingsSubscription: Subscription;
  stripeSubscription: Subscription;
  addCardSubscription: Subscription;

  loading = true;
  runningInit = true;

  constructor(private apiService: ApiService,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private alertService: AlertService,
              private changeDetectorRef: ChangeDetectorRef,
              private applicationSettings: ApplicationSettingsService,
              private sessionService: SessionService,
              private basketService: BasketService,
              private googleAnalytics: GoogleAnalyticsService) {
    this.cardPaymentMethod = sessionService.basket.cardPaymentMethod;
    this.cardPaymentMethod.paymentIntentId = null;
    if (this.sessionService.basket != null) {
      if (this.sessionService.basket.accountId != null) {
        this.accountId = this.sessionService.basket.accountId;
      }
      if (this.sessionService.basket.licenseeId != null) {
        this.licenseeId = this.sessionService.basket.licenseeId;
      }
    }
  }

  ngOnInit(): void {
    this.runningInit = true;
    this.countryCode = this.applicationSettings.countryCode;

    this.stripeSubscription = this.checkoutPaymentMethodCardService.currentHandleStripeAction.subscribe(clientSecret => {
      if (clientSecret != null && !this.runningInit) {
        this.handleStripeAction(clientSecret);
      }
    });

    this.addCardSubscription = this.checkoutPaymentMethodCardService.currentAddCardToAccount.subscribe(val => {
      if (val != null && !this.runningInit) {
        this.addCardToAccount();
      }
    });

    this.basketService.currentAddress.subscribe(address => {
      if (address != null && !this.runningInit) {
        this.cardPaymentMethod.address = address;
        this.sessionService.saveBasket();
      }
    });
  }

  ngAfterViewInit() {
    const paymentTypes = [];
    paymentTypes.push('CARD');

    this.apiService.getPaymentGateways(paymentTypes, this.licenseeId, this.accountId, this.ngUnsubscribe).subscribe(paymentGateways => {
      paymentGateways.forEach(paymentGateway => {
        if (paymentGateway.paymentGatewayType === 'CARD') {
          const options = {};
          const apiVersion = environment.stripeApiVersion;

          if (apiVersion !== 'default') {
            options['apiVersion'] = apiVersion;
          }

          loadStripe(paymentGateway.key, options).then(stripeResult => {
            this.stripe = stripeResult;

            this.loading = false;

            this.paymentAddresses = [];
            this.apiService.findAddresses('billing', this.ngUnsubscribe).subscribe(results => {
              // Minimum is first line and post code
              for (const result of results) {
                if (result.addressLine1 != null
                  && result.addressLine1 !== ''
                  && result.postCode != null
                  && result.postCode !== '') {
                  this.paymentAddresses.push(result);
                }
              }

              let isFirst = true;
              this.paymentAddresses.forEach(address => {
                if (isFirst) {
                  isFirst = false;
                  this.cardPaymentMethod.paymentAddressId = address.addressId;
                  this.paymentForm.get('paymentAddress').setValue(this.cardPaymentMethod.paymentAddressId);
                  this.paymentAddressChange();
                }
              });

              if (this.paymentForm.get('paymentAddress').value == null
                || this.paymentForm.get('paymentAddress').value === ''
                || this.paymentAddresses.length === 0) {
                this.paymentForm.get('paymentAddress').setValue('other');
                this.paymentAddressChange();
              }
            });

            this.apiService.findCardsForAccount(this.accountId, this.licenseeId, this.ngUnsubscribe).subscribe(result => {
              this.cards = result;

              if (this.cards.length === 0) {
                this.cardPaymentMethod.cardId = 'new';
              } else {
                this.cards.forEach(card => {
                  if ((this.selectedCardId != null && this.selectedCardId === card.sportsAccountPaymentGatewayCardId) || (this.selectedCardId == null && card.isPrimary)) {
                    this.cardPaymentMethod.cardId = card.sportsAccountPaymentGatewayCardId;
                  }
                });
              }

              this.paymentForm.get('creditCardSelect').setValue(this.cardPaymentMethod.cardId);

              this.setMountStripe();
            }, error => {
              console.error('Failed to load cards: ' + error);
              this.cardPaymentMethod.cardId = 'new';
              this.setMountStripe();
            });
          });
        }
      });
    });

    this.runningInit = false;
  }

  setMountStripe() {
    this.mountStripeCard();
    this.saveCardPaymentMethod();
    this.checkoutPaymentMethodCardService.setPaymentMethod(this.cardPaymentMethod);
    this.checkoutPaymentMethodCardService.setCardFormUpdated();
  }

  ngOnDestroy(): void {
    console.log('Payment method card destroyed, unsubscribing');
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.settingsSubscription != null) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.stripeSubscription != null) {
      this.stripeSubscription.unsubscribe();
    }
    if (this.addCardSubscription != null) {
      this.addCardSubscription.unsubscribe();
    }
  }

  cardSelectChange(cardId: string) {
    this.cardPaymentMethod.cardId = cardId;
    this.mountStripeCard();
    this.saveCardPaymentMethod();
    this.checkoutPaymentMethodCardService.setPaymentMethod(this.cardPaymentMethod);
    this.checkoutPaymentMethodCardService.setCardFormUpdated();
  }

  saveCardPaymentMethod(): void {
    this.sessionService.basket.cardPaymentMethod = this.cardPaymentMethod;
    this.sessionService.basket.cardPaymentMethod.paymentIntentId = null;
    this.sessionService.saveBasket();
  }

  getCreditCardName(card: Card): string {
    let cardString = card.cardType + ': ';

    for (let i = 0; i < card.cardLength - card.trailingDigits.length; i++) {
      cardString += '*';
    }

    cardString += card.trailingDigits;

    cardString += ' exp: ' + moment(card.expiry).format('MM/YYYY');

    return cardString;
  }

  mountStripeCard() {
    if (this.cardPaymentMethod == null
      || this.cardPaymentMethod.cardId !== 'new'
      || this.stripeMounted
      || this.cardElement == null) {
      return;
    }

    const elements = this.stripe.elements();

    this.card = elements.create('card', {hidePostalCode: true});
    this.card.mount(this.cardElement.nativeElement);

    // Nothing has been entered, so set it to true
    this.cardPaymentMethod.cardErrors = 'Please enter a credit card number';
    this.cardPaymentMethod.cardHasErrors = true;

    this.card.on('change', (event) => {
      if (event.complete) {
        this.removeFormControlError('cardError');
        this.cardPaymentMethod.cardHasErrors = false;
        this.changeDetectorRef.detectChanges();
        // enable payment button
      } else if (event.error) {
        this.cardPaymentMethod.cardErrors = event.error.message;
        this.cardPaymentMethod.cardHasErrors = true;
        this.paymentForm.setErrors({cardError: true});
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  paymentAddressChange(): void {
    let address = new Address();
    this.paymentAddressId = this.paymentForm.get('paymentAddress').value;
    if (this.paymentAddressId === 'other') {

      address.addressId = null;
      address.addressLine1 = '';
      address.addressLine2 = '';
      address.addressLine3 = '';
      address.locality = '';
      address.state = '';
      address.postCode = '';
      address.country = this.countryCode;
    } else {
      this.paymentAddresses.forEach(paymentAddress => {
        if (this.paymentAddressId === paymentAddress.addressId) {
          address = paymentAddress;
        }
      });
    }
    this.cardPaymentMethod.address = address;
    this.changeDetectorRef.detectChanges();

    this.checkoutPaymentMethodCardService.setCardFormUpdated();
  }

  getSingleLineAddress(address: Address): string {
    let addressString = '';

    addressString = this.appendAddressLine(addressString, address.addressLine1);
    addressString = this.appendAddressLine(addressString, address.addressLine2);
    addressString = this.appendAddressLine(addressString, address.addressLine3);
    addressString = this.appendAddressLine(addressString, address.locality);
    addressString = this.appendAddressLine(addressString, address.state);
    addressString = this.appendAddressLine(addressString, address.postCode);
    addressString = this.appendAddressLine(addressString, address.country);

    return addressString;
  }

  appendAddressLine(addressString: string, newValue: string): string {
    if (newValue == null || newValue === '') {
      return addressString;
    }

    if (addressString !== '') {
      addressString += ' ';
    }

    addressString += newValue;

    return addressString;
  }

  handleStripeAction(clientSecret: string) {
    if (this.stripe == null) {
      console.log('Stripe library no longer set, need to reinitialise...');
      const paymentTypes = [];
      paymentTypes.push('CARD');
      this.apiService.getPaymentGateways(paymentTypes, this.licenseeId, this.accountId, this.ngUnsubscribe).subscribe(paymentGateways => {
        paymentGateways.forEach(paymentGateway => {
          if (paymentGateway.paymentGatewayType === 'CARD') {
            const options = {};
            const apiVersion = environment.stripeApiVersion;

            if (apiVersion !== 'default') {
              options['apiVersion'] = apiVersion;
            }

            loadStripe(paymentGateway.key, options).then(stripeResult => {
              this.stripe = stripeResult;
              this.runHandleStripeAction(clientSecret);
            });
          }
        });
      }, error => {
        this.alertService.error('Failed to load payment gateway: ' + error);
        this.checkoutPaymentMethodCardService.setContinueEnrolment(true);
      });
    } else {
      this.runHandleStripeAction(clientSecret);
    }
  }

  runHandleStripeAction(clientSecret: string) {
    this.stripe.handleCardAction(
      clientSecret
    ).then(result => {
      if (result.error) {
        // Show error in payment form
        this.alertService.error(result.error.message);
        this.checkoutPaymentMethodCardService.setContinueEnrolment(true);
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        this.cardPaymentMethod.paymentIntentId = result.paymentIntent.id;
        this.checkoutPaymentMethodCardService.setContinueEnrolment(false);
      }
    });
  }

  addCardToAccount() {
    if (this.stripe == null) {
      console.log('Stripe not set, ignoring add card to account request');
      return;
    }

    this.cardPaymentMethod.cardholderName = this.paymentForm.get('creditCardNameInput').value;
    const paymentAddressId = this.paymentForm.get('paymentAddress').value;

    if (paymentAddressId === 'other') {
      this.cardPaymentMethod.address.addressId = null;
      this.cardPaymentMethod.address.addressLine1 = this.paymentForm.get('addressLine1Input').value;
      this.cardPaymentMethod.address.addressLine2 = this.paymentForm.get('addressLine2Input').value;
      this.cardPaymentMethod.address.addressLine3 = this.paymentForm.get('addressLine3Input').value;
      this.cardPaymentMethod.address.locality = this.paymentForm.get('addressCityInput').value;
      this.cardPaymentMethod.address.state = this.paymentForm.get('addressCountyInput').value;
      this.cardPaymentMethod.address.postCode = this.paymentForm.get('addressPostcodeInput').value;
      this.cardPaymentMethod.address.country = this.paymentForm.get('addressCountryInput').value;
    }

    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.cardPaymentMethod.cardholderName,
        address: {
          line1: this.cardPaymentMethod.address.addressLine1,
          line2: this.cardPaymentMethod.address.addressLine2,
          city: this.cardPaymentMethod.address.locality,
          state: this.cardPaymentMethod.address.state,
          postal_code: this.cardPaymentMethod.address.postCode,
          country: this.cardPaymentMethod.address.country
        }
      }
    }).then(result => {
      if (result.error) {
        this.cardPaymentMethod.cardErrors = result.error.message;
        this.cardPaymentMethod.cardHasErrors = true;
        this.paymentForm.setErrors({cardError: true});
        this.changeDetectorRef.detectChanges();

        this.checkoutPaymentMethodCardService.setContinueEnrolment(true);
      } else {
        this.cardPaymentMethod.stripeToken = result.paymentMethod.id;
        this.cardPaymentMethod.accountId = this.accountId;
        this.cardPaymentMethod.licenseeId = this.licenseeId;
        this.cardPaymentMethod.expiryMonth = result.paymentMethod.card.exp_month;
        this.cardPaymentMethod.expiryYear = result.paymentMethod.card.exp_year;
        this.cardPaymentMethod.trailingDigits = result.paymentMethod.card.last4;

        let numberOfDigits = 16;
        if (result.paymentMethod.card.brand === 'amex') {
          numberOfDigits = 15;
        }

        this.cardPaymentMethod.cardLength = numberOfDigits;
        this.cardPaymentMethod.cardType = getCardType(result.paymentMethod.card.brand);
        this.cardPaymentMethod.updateInvoicing = this.updateInvoices == null ? true : this.updateInvoices;

        this.apiService.addCardToAccount(this.cardPaymentMethod).subscribe(card => {
          // Strange error where cards is null, cannot work out why (getting cards returns status 0, which implies it was cancelled
          // Though we don't want to fail here, so init cards if null
          if (this.cards == null) {
            this.cards = [];
          }

          this.cards.push(card);
          this.cardPaymentMethod = new PaymentMethod();
          this.cardPaymentMethod.address = new Address();

          this.cardPaymentMethod.cardId = card.sportsAccountPaymentGatewayCardId;
          this.paymentForm.get('creditCardSelect').setValue(card.sportsAccountPaymentGatewayCardId);

          this.checkoutPaymentMethodCardService.setPaymentMethod(this.cardPaymentMethod);
          this.checkoutPaymentMethodCardService.setContinueEnrolment(false);

          if (this.listType != null) {
            this.googleAnalytics.addPaymentMethod(this.sessionService.basket, this.totalAmount, this.listType);
          }
        }, () => {
          this.checkoutPaymentMethodCardService.setContinueEnrolment(true);
        });
      }
    });
  }
}
