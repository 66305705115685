import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {ApiService} from '../../api.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {Address} from '../../_model/address';

@Component({
  selector: 'app-dashboard-bookings',
  templateUrl: './dashboard-bookings.component.html',
  styleUrls: ['./dashboard-bookings.component.scss']
})
export class DashboardBookingsComponent implements OnInit, OnDestroy {

  viewPastBookings = false;

  futureEnrolments: EnrolmentResponse[];
  provisionalEnrolments: EnrolmentResponse[];
  pastEnrolments: EnrolmentResponse[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  enrolmentsLoaded: boolean;
  pastEnrolmentsLoaded: boolean;

  addressVenueIds = [];
  venueAddressMap = new Map<string, Address>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router) {
    this.enrolmentsLoaded = false;
    this.pastEnrolmentsLoaded = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard Bookings');

    this.futureEnrolments = [];
    this.provisionalEnrolments = [];
    this.pastEnrolments = [];

    this.loadEnrolments(new Date(), null, true);

    this.renderer.addClass(document.body, 'dashboard-bookings');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  loadEnrolments(enrolmentAfterDate: Date, enrolmentCompletedBefore: Date, currentEnrolments: boolean) {
    this.apiService.getEnrolments(enrolmentAfterDate, enrolmentCompletedBefore, null, null, this.ngUnsubscribe).subscribe(enrolments => {
      enrolments.forEach(enrolment => {
        if (enrolment.nextClass != null && enrolment.enrolmentStatus === 'CONFIRMED') {
          this.futureEnrolments.push(enrolment);
          if (!this.addressVenueIds.includes(enrolment.course.venueId)) {
            this.addressVenueIds.push(enrolment.course.venueId);
          }
        } else if (enrolment.enrolmentStatus === 'PROVISIONAL') {
          this.provisionalEnrolments.push(enrolment);
        } else {
          this.pastEnrolments.push(enrolment);
        }
      });
      if (currentEnrolments) {
        this.enrolmentsLoaded = true;
      } else {
        this.pastEnrolmentsLoaded = true;
      }
    }, () => {}, () => {
      this.addressVenueIds.forEach(venueId => {
        this.apiService.getAddress(venueId, this.ngUnsubscribe).subscribe(address => {
          this.venueAddressMap.set(venueId, address);
        });
      });
    });
  }

  loadPastEnrolments(): void {
    this.viewPastBookings = !this.viewPastBookings;
    if (!this.pastEnrolmentsLoaded) {
      this.loadEnrolments(null, new Date(), false);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-bookings');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  navigateToAccountSettings(enrolment: EnrolmentResponse) {
    if (enrolment.pendingPaymentIds.length > 0) {
      const pendingPaymentId = enrolment.pendingPaymentIds[0];
      this.router.navigate(['/dashboard_billing_history'], {queryParams: {pendingPaymentId}});
    } else {
      this.router.navigate(['/dashboard_billing_history']);
    }
  }

  navigateToMap(venueId, placeId): void {
    const address = this.venueAddressMap.get(venueId);
    let query = '';
    let queryPlaceId = '';
    if (placeId != null && placeId !== '') {
      query = encodeURIComponent(this.getSingleLineAddress(address));
      queryPlaceId = placeId;
    } else if (address.longitude != null && address.longitude !== 0 && address.latitude != null && address.latitude !== 0) {
      query = address.latitude + ',' + address.longitude;
    } else {
      query = encodeURIComponent(this.getSingleLineAddress(address));
    }

    let url = 'https://www.google.com/maps/search/?api=1&query=' + query;

    if (queryPlaceId !== '') {
      url += '&query_place_id=' + queryPlaceId;
    }
    window.open(url, '_blank');
  }

  getSingleLineAddress(address: Address) {
    if (address == null) {
      return '';
    }

    let addressList = [];
    addressList = this.appendIfNotNull(address.addressLine1, addressList);
    addressList = this.appendIfNotNull(address.addressLine2, addressList);
    addressList = this.appendIfNotNull(address.addressLine3, addressList);
    addressList = this.appendIfNotNull(address.locality, addressList);
    addressList = this.appendIfNotNull(address.state, addressList);
    addressList = this.appendIfNotNull(address.postCode, addressList);
    addressList = this.appendIfNotNull(address.country, addressList);

    return addressList.join(', ');
  }

  appendIfNotNull(value: string, addressList: string[]): string[] {
    if (value != null && value !== '') {
      addressList.push(value);
    }

    return addressList;
  }
}
