import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DashboardService} from '../dashboard.service';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {WithdrawalReason} from '../../_model/withdrawal-reason';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard-booking-cancel',
  templateUrl: './dashboard-booking-cancel.component.html',
  styleUrls: ['./dashboard-booking-cancel.component.css']
})
export class DashboardBookingCancelComponent implements OnInit, OnDestroy {

  withdrawalForm: UntypedFormGroup;

  provisionalEnrolments: EnrolmentResponse[];
  provisionalId: string;
  provisional: EnrolmentResponse;
  withdrawalReasons: WithdrawalReason[];
  selectedWithdrawalText: string;
  running = false;
  submitted = false;
  referrer: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  provisionalEnrolmentsSubscription: Subscription;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private dashboardService: DashboardService,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2) {}

  setPaymentValidators() {
    let withdrawalReasonValidator = null;

    if (this.selectedWithdrawalText === 'Other') {
      withdrawalReasonValidator = [Validators.required];
    }

    this.withdrawalForm.get('withdrawalReasonOtherInput').setValidators(withdrawalReasonValidator);
    this.withdrawalForm.get('withdrawalReasonOtherInput').updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard Booking Cancel');

    this.route.params.subscribe(params => {
      this.provisionalId = params.id;

      this.apiService.getWithdrawalReasons(this.provisionalId, this.ngUnsubscribe).subscribe(reasons => this.withdrawalReasons = reasons);
    });

    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });

    this.provisionalEnrolmentsSubscription = this.dashboardService.currentProvisionalEnrolments.subscribe(provisionalEnrolments => {
      this.provisionalEnrolments = provisionalEnrolments;
    });

    this.withdrawalForm = this.formBuilder.group({
      withdrawalReasonSelect: ['', Validators.required],
      withdrawalReasonOtherInput: ['']
    });

    this.renderer.addClass(document.body, 'dashboard-booking-cancel');
    this.renderer.addClass(document.body, 'dashboard-action');

    if (this.provisionalEnrolments == null) {
      this.provisionalEnrolments = [];
      this.apiService.getEnrolment(this.provisionalId, this.ngUnsubscribe).subscribe(enrolment => {
        this.provisional = enrolment;
        this.checkValidProvisional();
      });
    } else {
      this.provisionalEnrolments.forEach(provisionalEnrolment => {
        if (provisionalEnrolment.enrolmentId === this.provisionalId) {
          this.provisional = provisionalEnrolment;
        }
      });

      this.checkValidProvisional();
    }
  }

  checkValidProvisional() {
    if (this.provisional.enrolmentStatus !== 'PROVISIONAL') {
      // We have reloaded the page under a different login, or the request no longer exists
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.provisionalEnrolmentsSubscription != null) {
      this.provisionalEnrolmentsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-booking-cancel');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  updateWithdrawalReasonText(value: string) {
    this.selectedWithdrawalText = value;
    this.setPaymentValidators();
  }

  getWithdrawalControl(componentName: string) {
    return this.withdrawalForm.get(componentName);
  }

  onWithdrawalFormSubmit(): void {
    this.submitted = true;

    if (this.running || this.withdrawalForm.invalid) {
      return;
    }

    this.running = true;

    const selectedWithdrawalId = this.withdrawalForm.get('withdrawalReasonSelect').value;
    const selectedWithdrawalReasonOther = this.withdrawalForm.get('withdrawalReasonOtherInput').value;

    this.apiService.withdrawProvisional(this.provisionalId, selectedWithdrawalId, selectedWithdrawalReasonOther).subscribe(() => {
      this.router.navigate(['/dashboard_bookings']);
      this.dashboardService.setNewConfirmationMessage('Enrolment withdrawn');
    }, () => {
      this.running = false;
    });
  }

  goBack(): void {
    if (this.referrer == null) {
      this.referrer = '/dashboard_bookings';
    }

    this.router.navigate([this.referrer]);
  }
}
