import {VenueOptions} from '../course-search/select-location/select-location.component';

export class CourseSearchParameters {
  students: CourseSearchStudent[];
  venueOptions: VenueOptions;
  termId: string;
  termType = 'WEEKLY';
  numberOfStudentsPerInstructor: number;
  classDurationInMinutes: number;
  days: number[];
  courseType: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.students.forEach(student => {
        if (typeof student.dateOfBirth === 'string') {
          student.dateOfBirth = new Date(student.dateOfBirth);
        }
      });
    }
  }
}

export class CourseSearchStudent {
  id: number;
  dateOfBirth: Date;
  studentId: string;
  programId: string;
  programLevelId: string;
  programLevelName: string;
  studentName: string;
  checked = true;
  toEnrol = false;
  canEnrol = false;
  givenName: string;
  familyName: string;
  title: string;
  hasMedicalConditions: boolean;
  medicalConditions: string;
  gender: string;

  getGivenName() {
    if (this.givenName != null && this.givenName !== '') {
      return this.givenName;
    }

    if (this.studentName == null) {
      return '';
    }

    let name = this.studentName;

    if (name.indexOf(' ') > 0) {
      name = name.substring(0, name.indexOf(' '));
    }

    return name;
  }

  getFamilyName() {
    if (this.familyName != null && this.familyName !== '') {
      return this.givenName;
    }

    if (this.studentName == null) {
      return '';
    }

    let name = this.studentName;

    if (name.indexOf(' ') > 0) {
      name = name.substring(name.indexOf(' ') + 1);
    }

    return name;
  }
}
