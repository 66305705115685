import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Student} from '../../_model/student';
import {DashboardAddStudentService} from '../../dashboard/dashboard-add-student/dashboard-add-student.service';
import {Program} from '../../_model/program';
import {ProgramLevel} from '../../_model/program-level';
import {SelectLocationService} from '../select-location/select-location.service';
import {VenueOptions} from '../select-location/select-location.component';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {AccountContact} from '../../_model/account-contact';
import {UserService} from '../../user-service';
import {Subject, Subscription} from 'rxjs';
import {ApplicationSettingsService} from '../../application-settings.service';
import {SessionService} from '../../session.service';

@Component({
  selector: 'app-dashboard-booking-start',
  templateUrl: './dashboard-booking-start.component.html',
  styleUrls: ['./dashboard-booking-start.component.css']
})
export class DashboardBookingStartComponent implements OnInit, OnDestroy {

  studentsForm: UntypedFormGroup;
  programsForm: UntypedFormGroup;
  programLevelsForm: UntypedFormGroup;

  accountContacts: AccountContact[];
  programs: Program[];
  programLevels: ProgramLevel[];
  stepNumber = 1;
  selectedStudentId: string;
  submitted = false;

  student: Student;
  programId: string;
  levelId: string;
  levelName: string;
  selectedPostCode: string;

  previousUrl: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  newStudentIdSubscription: Subscription;
  venueOptionsSubscription: Subscription;

  loaded = false;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private userService: UserService,
              private dashboardAddStudentService: DashboardAddStudentService,
              private selectLocationService: SelectLocationService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private titleService: TitleGenerator,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private renderer: Renderer2,
              private applicationSettings: ApplicationSettingsService,
              private sessionService: SessionService) {}

  addContactIfNotFound(id: string, title: string, givenName: string,
                       familyName: string, gender: string, dateOfBirth: Date, entityId: string, type: string) {
    // Check to see if they are already a student
    let found = false;
    for (const accountContact of this.accountContacts) {
      if (entityId === accountContact.entityId) {
        found = true;
        break;
      }
    }

    if (!found) {
      const accountContact = new AccountContact();
      accountContact.type = type;
      accountContact.id = id;
      accountContact.givenName = givenName;
      accountContact.familyName = familyName;
      accountContact.entityId = entityId;
      accountContact.title = title;
      accountContact.gender = gender;
      accountContact.dateOfBirth = dateOfBirth;

      this.accountContacts.push(accountContact);
    }
  }

  ngOnInit(): void {
    this.loaded = false;

    this.titleService.setTitle('New Booking');

    this.sessionService.removeBasket();
    this.sessionService.removeCourseSearchParameters();

    this.accountContacts = [];
    this.userService.getLoggedInStudentAccountContacts().subscribe(students => {
      this.accountContacts = students;

      this.accountContacts.forEach(student => {
        this.apiService.getLatestLevelForStudent(student.id, this.ngUnsubscribe).subscribe(level => {
          if (level != null) {
            student.programLevelId = level.id;
            student.hasLevelImage = level.hasLevelImage;
          }
        });
      });

      this.apiService.getLoggedInUserContacts(this.ngUnsubscribe).subscribe(contacts => {
        for (const contact of contacts) {
          this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
            contact.familyName, contact.gender, contact.dob, contact.personId, 'contact');
        }
      }, () => {}, () => {
        // Finally, add the logged in user
        this.authService.getLoggedInUser().subscribe(loggedInUser => {
          let type = 'self';

          if (loggedInUser.isStudent) {
            type = 'student';
          }

          if (!loggedInUser.isStudent) {
            this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(contact => {
              this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
                contact.familyName, contact.gender, contact.dob, contact.personId, type);
              this.sortContacts();
            });
          } else {
            this.sortContacts();
          }
        });
      });
    });

    this.studentsForm = this.formBuilder.group({
      studentInput: ['', Validators.required]
    });

    this.programsForm = this.formBuilder.group({
      studentProgram: ['', Validators.required]
    });

    this.programLevelsForm = this.formBuilder.group( {
      studentProgramLevel: ['', Validators.required]
    });

    this.newStudentIdSubscription = this.dashboardAddStudentService.currentNewStudentId.subscribe(studentId => {
      if (studentId != null && this.loaded) {
        this.selectedStudentId = studentId;
        this.runStep2();
      }
    });

    this.apiService.findAddresses('billing', this.ngUnsubscribe).subscribe(results => {
      let isFirst = true;
      results.forEach(address => {
        if (isFirst) {
          isFirst = false;
          this.selectedPostCode = address.postCode;
          this.changeDetectorRef.detectChanges();
        }
      });
    });

    this.venueOptionsSubscription = this.selectLocationService.currentVenueOptions
      .subscribe(venueOptions => this.onVenueSelected(venueOptions));

    this.route.queryParams.subscribe(params => {
      this.previousUrl = params.referrer;
    });

    this.renderer.addClass(document.body, 'dashboard-booking-start');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.loaded = true;
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.newStudentIdSubscription != null) {
      this.newStudentIdSubscription.unsubscribe();
    }
    if (this.venueOptionsSubscription != null) {
      this.venueOptionsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-booking-start');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  sortContacts() {
    this.accountContacts = this.accountContacts.sort((t1, t2) => {
      const t1Name = t1.givenName + ' ' + t1.familyName;
      const t2Name = t2.givenName + ' ' + t2.familyName;
      if (t1Name > t2Name) {
        return 1;
      }
      if (t1Name < t2Name) {
        return -1;
      }
      return 0;
    });
  }

  getLevelImageUrl(levelId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/program_levels/' + levelId + '/image\')';
  }

  getLevelImageUrlSrc(levelId: string): string {
    return environment.apiUrl + 'images/program_levels/' + levelId + '/image';
  }

  onStudentsSubmit(): void {
    if (this.studentsForm.invalid) {
      return;
    }

    if (this.selectedStudentId === 'new') {
      this.stepNumber = 5;
      return;
    }

    // Need to find the student to wrok out what to do next
    this.accountContacts.forEach(contact => {
      if (contact.id === this.selectedStudentId) {
        if (contact.type === 'student') {
          this.stepNumber = 99;
          this.apiService.getStudent(contact.id, this.ngUnsubscribe).subscribe(student => {
            this.student = student;
            this.runStep2();
          }, () => {
            this.stepNumber = 1;
          });
        } else {
          this.stepNumber = 5;
          this.dashboardAddStudentService.setAccountContact(contact);
          return;
        }
      }
    });
  }

  runStep2(): void {
    this.apiService.getStudent(this.selectedStudentId, this.ngUnsubscribe).subscribe(student => {
      this.student = student;
      this.stepNumber = 2;

      this.apiService.getPrograms(null, this.selectedStudentId, this.ngUnsubscribe).subscribe((data) => {
        this.programs = data;

        if (this.programs.length === 1) {
          this.programId = this.programs[0].id;
          this.programsForm.get('studentProgram').setValue(this.programId);
        }
      });
    });
  }

  onProgramsSubmit() {
    this.submitted = true;

    if (this.programsForm.invalid) {
      return;
    }

    this.submitted = false;

    for (const level of this.student.levels) {
      if (level.program.id === this.programId && level.level != null) {
        this.levelId = level.level.id;
        this.levelName = level.level.programLevelName;

        this.programLevelsForm.get('studentProgramLevel').setValue(this.levelId);

        if (level.verifiedDate != null) {
          this.runStep4();
          return;
        }
      }
    }

    this.stepNumber = 3;
    this.apiService.getProgramLevels(this.programId, this.ngUnsubscribe).subscribe(levels => this.programLevels = levels);
  }

  onProgramLevelsSubmit() {
    this.submitted = true;

    if (this.programLevelsForm.invalid) {
      return;
    }

    this.submitted = false;
    this.runStep4();
  }

  runStep4() {
    this.selectLocationService.setTitle('Lastly, what’s your preferred location?');
    this.stepNumber = 4;
  }

  onVenueSelected(venueOptions: VenueOptions) {
    if (venueOptions == null || this.student == null) {
      return;
    }

    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    studentParameters.id = 1;
    studentParameters.studentId = this.selectedStudentId;
    studentParameters.programId = this.programId;
    studentParameters.programLevelId = this.levelId;
    studentParameters.programLevelName = this.levelName;
    studentParameters.studentName = this.student.givenName + ' ' + this.student.familyName;
    // studentParameters.dateOfBirth = moment(this.student.dateOfBirth).tz(this.applicationSettings.timezone).toDate();
    studentParameters.dateOfBirth = this.student.dateOfBirth;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    parameters.students = students;
    parameters.venueOptions = venueOptions;
    parameters.venueOptions.venueId = null;

    if (this.sessionService.courseSearchTermType != null) {
      parameters.termType = this.sessionService.courseSearchTermType;
      this.sessionService.courseSearchTermType = null;
    }

    this.sessionService.courseSearchParameters = parameters;
    this.sessionService.saveCourseSearchParameters();

    const runSearch = true;
    this.router.navigate(['courses_list_single'], {queryParams: {runSearch}});
  }

  goBack() {
    let navigateTo = 'dashboard';
    if (this.previousUrl != null) {
      navigateTo = this.previousUrl;
    }
    this.router.navigate(['/' + navigateTo]);
  }

  studentSelected(accountContactId: string) {
    this.selectedStudentId = accountContactId;
  }

  setProgramId(programId: string) {
    this.programId = programId;
  }

  setLevelId(programLevelId: string, programLevelName: string) {
    this.levelId = programLevelId;
    this.levelName = programLevelName;
  }
}
