import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})
export class DashboardSidebarComponent implements OnInit {

  @Input() activeItem: number;

  constructor() { }

  ngOnInit(): void {
  }

}
