import {Venue} from './venue';

export class MembershipType {
  membershipTypeId: string;
  description: string;
  name: string;
  venues: Venue[];
  status: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
