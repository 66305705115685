<header class="header-wrap">
  <div class="banner type-dashboard">
    <div class="container">

      <div class="row row-dashboard">
        <div class="span-logo">
          <a appRedirectHome>
            <img appLogo [inverse]="true" />
          </a>
        </div>

        <div class="span-links">
          <a [routerLink]="['/dashboard_booking_start']" [queryParams]="{referrer: 'dashboard'}" class="btn">Make a new booking</a>
          <a [routerLink]="['/dashboard_upgrades_offers']" class="btn">Upgrades & offers</a>
        </div>

        <app-error-reporting [largeIcon]="true"></app-error-reporting>
        <div class="span-user" *ngIf="loggedInUser != null">
          <button
            type="button"
            class="btn"
            [ngClass]="showMenu ? 'active' : ''"
            (click)="showMenu = !showMenu"
            data-toggle="collapsable" data-target="user_options_list"
          >{{loggedInUser.givenName}} {{loggedInUser.familyName}}<span class="svg-icon caret"><svg class="svg-caret"><use xlink:href="./assets/sprite/spritemap.svg#sprite-caret"></use></svg></span></button>
          <ul class="user-options-list toggler" id="user_options_list" [ngClass]="showMenu ? 'active' : ''" style="max-height: 102px;">
            <li><a [routerLink]="['/dashboard']">My Account<span class="svg-icon dashboard-user"><svg class="svg-dashboard-user"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-user"></use></svg></span></a></li>
            <li><a [routerLink]="[]" queryParamsHandling="preserve" (click)="logOff()">Log Out<span class="svg-icon dashboard-sign-out"><svg class="svg-dashboard-sign-out"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-sign-out"></use></svg></span></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>

<app-alert></app-alert>
