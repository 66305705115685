import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthService} from '../../../auth-service';
import {ApiService} from '../../../api.service';
import {DashboardService} from '../../dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentMethod} from '../../../_model/payment-method';
import {Address} from '../../../_model/address';
import {Account} from '../../../_model/account';
import {getCardType} from '../../../_helpers/card_helper';
import {BasketService} from '../../../checkout/basket.service';
import {TitleGenerator} from '../../../title-generator';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Subject, Subscription} from 'rxjs';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-dashboard-add-card',
  templateUrl: './dashboard-add-card.component.html',
  styleUrls: ['./dashboard-add-card.component.css']
})
export class DashboardAddCardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('cardElement') cardElement: ElementRef;

  accountId: string;
  cardForm: UntypedFormGroup;
  account: Account;
  cardPaymentMethod: PaymentMethod;
  paymentAddresses: Address[];
  paymentAddressId: string;
  submitted = false;

  stripe: Stripe;
  card;
  cardErrors;
  cardHasErrors: boolean;
  stripeMounted = false;

  addingCard = false;

  countryCode: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  applicationSettingsSubscription: Subscription;
  addressSubscription: Subscription;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private basketService: BasketService,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private applicationSettings: ApplicationSettingsService) {}

  loadPaymentGateways() {
    const paymentTypes = ['STRIPE'];
    this.apiService.getPaymentGateways(paymentTypes, this.account.licenseeId, this.account.id, this.ngUnsubscribe)
      .subscribe(paymentGateways => {
      paymentGateways.forEach(paymentGateway => {
        if (paymentGateway.paymentGatewayType === 'STRIPE') {
          const options = {};
          const apiVersion = environment.stripeApiVersion;

          if (apiVersion !== 'default') {
            options['apiVersion'] = apiVersion;
          }

          loadStripe(paymentGateway.key, options).then(stripeResult => {
            this.stripe = stripeResult;

            this.mountStripeCard();
            this.setPaymentValidators();
          });
        }
      });
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Add Card');

    this.countryCode = this.applicationSettings.countryCode;

    this.cardForm = this.formBuilder.group({
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      enrolmentTermsCheckbox: ['']
    });

    this.cardPaymentMethod = new PaymentMethod();
    this.cardPaymentMethod.cardId = 'new';
    this.cardPaymentMethod.address = new Address();

    this.route.params.subscribe(params => {
      this.accountId = params.id;
    });

    this.renderer.addClass(document.body, 'dashboard-add-payment-card');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.addressSubscription = this.basketService.currentAddress.subscribe(address => {
      if (address != null && this.cardPaymentMethod != null) {
        this.cardPaymentMethod.address = address;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.paymentAddresses = [];
    this.apiService.findAddresses('billing', this.ngUnsubscribe).subscribe(results => {
      // Minimum is first line and post code
      for (const result of results) {
        if (result.addressLine1 != null
          && result.addressLine1 !== ''
          && result.postCode != null
          && result.postCode !== '') {
          this.paymentAddresses.push(result);
        }
      }

      let isFirst = true;
      this.paymentAddresses.forEach(address => {
        if (isFirst) {
          isFirst = false;
          this.paymentAddressId = address.addressId;
          this.cardForm.get('paymentAddress').setValue(this.paymentAddressId);
          this.paymentAddressChange();
        }
      });

      if (this.cardForm.get('paymentAddress').value == null || this.cardForm.get('paymentAddress').value === '') {
        this.cardForm.get('paymentAddress').setValue('other');
        this.paymentAddressChange();
      }
    });
  }

  ngAfterViewInit() {
    this.apiService.getAccounts(null, this.ngUnsubscribe).subscribe(accounts => {
      accounts.forEach(account => {
        if (account.id === this.accountId) {
          this.account = account;
          this.loadPaymentGateways();
        }
      });
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.applicationSettingsSubscription != null) {
      this.applicationSettingsSubscription.unsubscribe();
    }
    if (this.addressSubscription != null) {
      this.addressSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-add-payment-card');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  paymentAddressChange(): void {
    let address = new Address();
    this.paymentAddressId = this.cardForm.get('paymentAddress').value;
    if (this.paymentAddressId === 'other') {

      address.addressId = null;
      address.addressLine1 = '';
      address.addressLine2 = '';
      address.addressLine3 = '';
      address.locality = '';
      address.state = '';
      address.postCode = '';
      address.country = this.countryCode;
    } else {
      this.paymentAddresses.forEach(paymentAddress => {
        if (this.paymentAddressId === paymentAddress.addressId) {
          address = paymentAddress;
        }
      });
    }
    this.cardPaymentMethod.address = address;

    this.setPaymentValidators();
  }

  setPaymentValidators() {
    let addressValidator = null;
    let creditCardAddressValidator = null;

    if (this.cardPaymentMethod.cardId === 'new') {
      creditCardAddressValidator = [Validators.required];

      if (this.paymentAddressId === 'other') {
        addressValidator = [Validators.required];
      }
    } else {
      this.removeFormControlError('cardError');
      this.changeDetectorRef.detectChanges();
    }

    this.cardForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.cardForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.cardForm.get('addressLine1Input').setValidators(addressValidator);
    this.cardForm.get('addressCityInput').setValidators(addressValidator);
    this.cardForm.get('addressCountyInput').setValidators(addressValidator);
    this.cardForm.get('addressCountryInput').setValidators(addressValidator);
    this.cardForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.cardForm.get('creditCardNameInput').updateValueAndValidity();
    this.cardForm.get('paymentAddress').updateValueAndValidity();
    this.cardForm.get('addressLine1Input').updateValueAndValidity();
    this.cardForm.get('addressLine1Input').updateValueAndValidity();
    this.cardForm.get('addressCityInput').updateValueAndValidity();
    this.cardForm.get('addressCountyInput').updateValueAndValidity();
    this.cardForm.get('addressCountryInput').updateValueAndValidity();
    this.cardForm.get('addressPostcodeInput').updateValueAndValidity();
    this.cardForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  removeFormControlError(errorName: string) {
    if (this.cardForm?.errors && this.cardForm?.errors[errorName]) {
      delete this.cardForm.errors[errorName];
      if (Object.keys(this.cardForm.errors).length === 0) {
        this.cardForm.setErrors(null);
      }
    }
  }

  getSingleLineAddress(address: Address): string {
    let addressString = '';

    addressString = this.appendAddressLine(addressString, address.addressLine1);
    addressString = this.appendAddressLine(addressString, address.addressLine2);
    addressString = this.appendAddressLine(addressString, address.addressLine3);
    addressString = this.appendAddressLine(addressString, address.locality);
    addressString = this.appendAddressLine(addressString, address.state);
    addressString = this.appendAddressLine(addressString, address.postCode);
    addressString = this.appendAddressLine(addressString, address.country);

    return addressString;
  }

  appendAddressLine(addressString: string, newValue: string): string {
    if (newValue == null || newValue === '') {
      return addressString;
    }

    if (addressString !== '') {
      addressString += ' ';
    }

    addressString += newValue;

    return addressString;
  }

  onCardSubmit() {
    if (this.addingCard) {
      return;
    }

    this.submitted = true;

    if (this.cardHasErrors) {
      this.cardForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.addingCard = true;
    this.addCardToAccount();
  }

  mountStripeCard() {
    if (this.cardPaymentMethod == null
      || this.stripeMounted
      || this.cardElement == null) {
      return;
    }

    const elements = this.stripe.elements();

    this.card = elements.create('card', {hidePostalCode: true});
    this.card.mount(this.cardElement.nativeElement);

    // Nothing has been entered, so set it to true
    this.cardErrors = 'Please enter a credit card number';
    this.cardHasErrors = true;

    this.card.on('change', (event) => {
      if (event.complete) {
        this.removeFormControlError('cardError');
        this.cardHasErrors = false;
        this.changeDetectorRef.detectChanges();
        // enable payment button
      } else if (event.error) {
        this.cardErrors = event.error.message;
        this.cardHasErrors = true;
        this.cardForm.setErrors({cardError: true});
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  addCardToAccount() {
    this.cardPaymentMethod.cardholderName = this.cardForm.get('creditCardNameInput').value;
    const paymentAddressId = this.cardForm.get('paymentAddress').value;

    if (paymentAddressId === 'other') {
      this.cardPaymentMethod.address.addressId = null;
      this.cardPaymentMethod.address.addressLine1 = this.cardForm.get('addressLine1Input').value;
      this.cardPaymentMethod.address.addressLine2 = this.cardForm.get('addressLine2Input').value;
      this.cardPaymentMethod.address.addressLine3 = this.cardForm.get('addressLine3Input').value;
      this.cardPaymentMethod.address.locality = this.cardForm.get('addressCityInput').value;
      this.cardPaymentMethod.address.state = this.cardForm.get('addressCountyInput').value;
      this.cardPaymentMethod.address.postCode = this.cardForm.get('addressPostcodeInput').value;
      this.cardPaymentMethod.address.country = this.cardForm.get('addressCountryInput').value;
    }

    this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.cardPaymentMethod.cardholderName,
          address: {
            line1: this.cardPaymentMethod.address.addressLine1,
            line2: this.cardPaymentMethod.address.addressLine2,
            city: this.cardPaymentMethod.address.locality,
            state: this.cardPaymentMethod.address.state,
            postal_code: this.cardPaymentMethod.address.postCode,
            country: this.cardPaymentMethod.address.country
          }
        }
      }
    ).then(result => {
      if (result.error) {
        this.cardErrors = result.error.message;
        this.cardHasErrors = true;
        this.cardForm.setErrors({cardError: true});
        this.changeDetectorRef.detectChanges();

        this.addingCard = false;
      } else {
        this.cardPaymentMethod.stripeToken = result.paymentMethod.id;
        this.cardPaymentMethod.accountId = this.account.id;
        this.cardPaymentMethod.licenseeId = this.account.licenseeId;
        this.cardPaymentMethod.expiryMonth = result.paymentMethod.card.exp_month;
        this.cardPaymentMethod.expiryYear = result.paymentMethod.card.exp_year;
        this.cardPaymentMethod.trailingDigits = result.paymentMethod.card.last4;

        let numberOfDigits = 16;
        if (result.paymentMethod.card.brand === 'amex') {
          numberOfDigits = 15;
        }

        this.cardPaymentMethod.cardLength = numberOfDigits;
        this.cardPaymentMethod.cardType = getCardType(result.paymentMethod.card.brand);
        this.cardPaymentMethod.updateInvoicing = true;

        this.apiService.addCardToAccount(this.cardPaymentMethod).subscribe(() => {
          this.router.navigate(['/dashboard_account_settings']);
          this.dashboardService.setNewConfirmationMessage('Card added');
        }, () => {
          this.addingCard = false;
        });
      }
    });
  }
}
