import {Course} from './course';
import {CourseSearchStudentResult} from './course-search-student-result';
import {convertObjectToDate} from '../_helpers/date_helper';
import {CourseRestriction} from './course-restriction';

export class CourseSearchResult {
  course: Course;
  courseSearchStudents: CourseSearchStudentResult[];
  show = true;
  availableToBook = true;
  instructorDisplayName: string;
  distanceFromVenue: number;
  classDate: Date;
  classId: string;
  listPosition: number;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.classDate = convertObjectToDate(this.classDate);
    if (this.course != null) {
      this.course.startTimeDate = convertObjectToDate(this.course.startTimeDate);
      this.course.endTimeDate = convertObjectToDate(this.course.endTimeDate);
    }
  }
}
