import {SportsPayment} from './sports-payment';
import {PendingPaymentInvoice} from './pending-payment-invoice';

export class PendingPaymentSettlementResponse {
  totalAmount: number;
  sportsPayment: SportsPayment;
  currency: string;
  result: string;
  requiresPaymentAction: boolean;
  error: string;
  paymentIntentClientSecret: string;
  accountBalance: number;
  totalAmountWithBalance: number;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.sportsPayment != null
        && this.sportsPayment.payment != null
        && this.sportsPayment.payment.paymentDate != null
        && (typeof this.sportsPayment.payment.paymentDate === 'string'
          || typeof this.sportsPayment.payment.paymentDate === 'number')) {
        this.sportsPayment.payment.paymentDate = new Date(this.sportsPayment.payment.paymentDate);
      }
    }
  }
}
