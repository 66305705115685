import {RollbarErrorHandler} from './rollbar';
import { Injectable } from "@angular/core";

@Injectable()
export class AppExceptionHandler extends RollbarErrorHandler {
  handleError(error: any) {
    if (error != null) {
      console.error(error);
      super.handleError(error);
    } else {
      console.error('Received null error');
    }
  }
}
