import {convertObjectToDate} from '../_helpers/date_helper';
import {Enrolment} from './enrolment';
import {BillingServiceTerm} from './billing-service-term';
import {RecurringPricePlan} from './recurring-price-plan';
import {DirectDebitCustomerDetails} from './direct-debit-customer-details';
import {Card} from './card';
import {Mandate} from './mandate';

export class DirectDebitResponse {
  name: string;
  canCancel: boolean;
  paymentType: string;
  subscriptionStatus: string;
  subscriptionNextPayment: Date;
  status: string;
  endDate: Date;
  startDate: Date;
  lastEnrolment: Enrolment;
  latestTerm: BillingServiceTerm;
  firstTerm: BillingServiceTerm;
  recentTerm: BillingServiceTerm;
  plan: RecurringPricePlan;
  mandate: Mandate;
  card: Card;
  currency: string;
  recurringBillingId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.subscriptionNextPayment = convertObjectToDate(this.subscriptionNextPayment);
    this.endDate = convertObjectToDate(this.endDate);
    this.startDate = convertObjectToDate(this.startDate);
    if (this.lastEnrolment != null) {
      this.lastEnrolment.confirmDate = convertObjectToDate(this.lastEnrolment.confirmDate);
      this.lastEnrolment.dateFirstClassBooked = convertObjectToDate(this.lastEnrolment.dateFirstClassBooked);
      this.lastEnrolment.dateLastClassBooked = convertObjectToDate(this.lastEnrolment.dateLastClassBooked);
      this.lastEnrolment.provisionalExpiry = convertObjectToDate(this.lastEnrolment.provisionalExpiry);
    }
    this.initTermDates(this.latestTerm);
    this.initTermDates(this.firstTerm);
    this.initTermDates(this.recentTerm);
    if (this.plan != null) {
      this.plan.startDateTime = convertObjectToDate(this.plan.startDateTime);
      this.plan.endDateTime = convertObjectToDate(this.plan.endDateTime);
    }
    if (this.mandate != null) {
      this.mandate.mandateCreationDate = convertObjectToDate(this.mandate.mandateCreationDate);
      this.mandate.mandateStatusUpdatedDate = convertObjectToDate(this.mandate.mandateStatusUpdatedDate);
    }
  }

  initTermDates(term: BillingServiceTerm) {
    if (term != null) {
      term.startDateTime = convertObjectToDate(term.startDateTime);
      term.endDateTime = convertObjectToDate(term.endDateTime);
      if (term.invoice != null) {
        term.invoice.transactionDate = convertObjectToDate(term.invoice.transactionDate);
      }
    }
  }
}
