import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {PolicyResult} from '../_model/policy-result';
import {BookingHeaderTitleServiceService} from '../booking-header/booking-header-title-service.service';
import {ApiService} from '../api.service';
import {TitleGenerator} from '../title-generator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {

  privacyPolicy: PolicyResult;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private apiService: ApiService,
              private renderer: Renderer2) {}

  ngOnInit(): void {
    this.titleService.setTitle('Privacy Policy');

    this.renderer.addClass(document.body, 'terms-and-conditions');
    this.bookingHeaderTitleServiceService.setTitle('privacy policy');

    this.apiService.getPrivacyPolicy(this.ngUnsubscribe).subscribe((privacyPolicy) => {
      this.privacyPolicy = privacyPolicy;
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'terms-and-conditions');
  }
}
