import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AlertService} from '../../alert';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../../title-generator';
import {ApiService} from '../../api.service';
import {CourseReferral} from '../../_model/course-referral';
import {Subject} from 'rxjs';
import {CourseSearchResult} from '../../_model/course-search-result';
import {CourseSearchStudentResult} from '../../_model/course-search-student-result';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {SessionService} from '../../session.service';
import {VenueOptions} from '../select-location/select-location.component';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';

@Component({
  selector: 'app-course-list-referral',
  templateUrl: './course-list-referral.component.html',
  styleUrls: ['./course-list-referral.component.css']
})
export class CourseListReferralComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  courseReferral: CourseReferral;
  courses: CourseSearchResult[];
  mode: string;

  constructor(private alertService: AlertService,
              private googleAnalytics: GoogleAnalyticsService,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private apiService: ApiService,
              private sessionService: SessionService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Course Referral Search');

    this.renderer.addClass(document.body, 'courses-list-single');
    this.renderer.addClass(document.body, 'type-single');

    this.route.params.subscribe(params => {
      this.courses = [];
      const referralCode = params.id;

      this.apiService.getCourseReferral(referralCode).subscribe(courseReferral => {
        this.courseReferral = courseReferral;
        this.mode = 'referral_' + courseReferral.type.toLowerCase();

        this.bookingHeaderTitleServiceService.setTitle(courseReferral.name + ' (' + courseReferral.courses.length + ' courses)');

        // Set the search parameters
        const parameters = new CourseSearchParameters();
        const studentParameters = new CourseSearchStudent();

        studentParameters.id = 1;
        studentParameters.programId = this.courseReferral.programLevel.programId;
        studentParameters.programLevelId = this.courseReferral.programLevel.id;
        studentParameters.programLevelName = this.courseReferral.programLevel.programLevelName;
        studentParameters.studentName = '';

        const students = new Array<CourseSearchStudent>();
        students.push(studentParameters);

        const venueOptions = new VenueOptions();

        parameters.students = students;
        parameters.venueOptions = venueOptions;

        this.sessionService.courseSearchParameters = parameters;
        this.sessionService.saveCourseSearchParameters();

        let listPosition = 0;
        this.courseReferral.courses.forEach(course => {
          const courseResult = new CourseSearchResult();
          courseResult.course = course;
          courseResult.listPosition = ++listPosition;
          courseResult.courseSearchStudents = [];

          const courseResultStudent = new CourseSearchStudentResult();
          courseResultStudent.canEnrol = true;
          courseResultStudent.id = 1;

          courseResult.courseSearchStudents.push(courseResultStudent);

          this.courses.push(courseResult);
        });

        this.googleAnalytics.search(null, 'Course Referral');
      });
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'courses-list-single');
    this.renderer.removeClass(document.body, 'type-single');
  }
}
