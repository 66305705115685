export class CreateStudentRequest {
  id: number;
  accountId: string;
  whoIsSwimming: string;
  dateOfBirth: Date;
  studentId: string;
  programId: string;
  programLevelId: string;
  givenName: string;
  familyName: string;
  title: string;
  hasMedicalConditions: boolean;
  medicalConditions: string;
  gender: string;
  courseId: string;
  personId: string;
}
