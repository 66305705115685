import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SelectDateOfBirthService} from './select-date-of-birth.service';
import {DateOfBirthValidator} from '../../_helpers/date_of_birth.validator';
import {Subscription} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-select-date-of-birth',
  templateUrl: './select-date-of-birth.component.html',
  styleUrls: ['./select-date-of-birth.component.css']
})
export class SelectDateOfBirthComponent implements OnInit, OnDestroy {

  @Input() selectDobForm: UntypedFormGroup;
  @Input() title = '';
  @Input() submitted = false;

  dobYears: number[];

  titleSubscription: Subscription;

  constructor(private selectDateOfBirthService: SelectDateOfBirthService,
              private formBuilder: UntypedFormBuilder) {}

  setTitle(message) {
    this.title = message;
  }

  ngOnInit(): void {
    this.titleSubscription = this.selectDateOfBirthService.currentTitle.subscribe(title => this.setTitle(title));

    this.dobYears = new Array<number>();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= (currentYear - 100); i--) {
      this.dobYears.push(i);
    }

    this.selectDobForm = this.formBuilder.group({
        dob_day_0: ['', Validators.required],
        dob_month_0: ['', Validators.required],
        dob_year_0: ['', Validators.required]
      },
      {
        validator: DateOfBirthValidator('dob_day_0', 'dob_month_0', 'dob_year_0')
      });
  }

  ngOnDestroy() {
    if (this.titleSubscription != null) {
      this.titleSubscription.unsubscribe();
    }
  }

  get f() { return this.selectDobForm.controls; }

  onDobSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.selectDobForm.invalid) {
      return;
    }

    const dateOfBirth = moment().set(
      {year: parseInt(this.selectDobForm.controls.dob_year_0.value, 10),
        month: parseInt(this.selectDobForm.controls.dob_month_0.value, 10) - 1,
        date: parseInt(this.selectDobForm.controls.dob_day_0.value, 10)
      });

    this.selectDateOfBirthService.setSelectedDateOfBirth(dateOfBirth.toDate());
  }
}
