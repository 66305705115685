<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container">

        <div id="panel_find_start" class="panel panel-find-start">

          <div class="span-title">
            <h2 class="entry-title">
              Number of people?
            </h2>
          </div>

          <div class="row row-cards">

            <div class="span-card">
              <div class="card style-default">

                <div class="span-choice">
                  <a [routerLink]="['/find_course_single']" [queryParams]="{venueId: venueId, instructorId: instructorId}" class="input-check radio">
                    <span class="svg-icon student-2"><svg class="svg-student-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-student-2"></use></svg></span>								<span class="radio-content">
									<ul class="label-description">
										<li class="title">single student</li>
									</ul>
								</span>
                  </a>
                </div>

              </div>

            </div>

            <div class="span-card">
              <div class="card style-default">

                <div class="span-choice">
                  <a [routerLink]="['/find_course_multiple']" [queryParams]="{venueId: venueId, instructorId: instructorId}" class="input-check radio">
                    <span class="svg-icon students-1"><svg class="svg-students-1"><use xlink:href="./assets/sprite/spritemap.svg#sprite-students-1"></use></svg></span>								<span class="svg-icon students-2"><svg class="svg-students-2"><use xlink:href="./assets/sprite/spritemap.svg#sprite-students-2"></use></svg></span>

                    <span class="radio-content">
									<ul class="label-description">
										<li class="title">multiple students</li>
									</ul>
								</span>
                  </a>
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </section>		</main>
</div>

<app-footer></app-footer>
