import {Injectable} from '@angular/core';
import {ApplicationSettingsService} from './application-settings.service';
import * as CryptoJS from 'crypto-js';
import {Basket} from './_model/basket';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {BasketStudent} from './_model/basket-student';
import {ApiService} from './api.service';
import {AuthService} from './auth-service';
import {LoggedInUser} from './_model/logged-in-user';
import {Address} from './_model/address';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacebookConversionService {

  FACEBOOK_URL = 'https://graph.facebook.com/';
  API_VERSION = 'v16.0';
  ipAddress: string = null;

  constructor(private applicationSettings: ApplicationSettingsService,
              private cookieService: CookieService,
              private apiService: ApiService,
              private authService: AuthService,
              private httpClient: HttpClient) {
  }

  private getUrl(): string {
    return this.FACEBOOK_URL + this.API_VERSION + "/" + this.applicationSettings.facebookPixelId + "/events?access_token=" + this.applicationSettings.facebookToken;
  }

  conversionSetup(): boolean {
    return this.applicationSettings.facebookPixelId != null
      && this.applicationSettings.facebookPixelId !== ''
      && this.applicationSettings.facebookToken != null
      && this.applicationSettings.facebookToken !== '';
  }

  logPurchaseConversion(basket: Basket, receiptId: string, amount: number, listName: string) {
      this.sendFacebookEvent('Purchase', basket, null, receiptId, amount, listName);
  }

  logAddPaymentInfo(basket: Basket, amount: number, listName: string) {
      this.sendFacebookEvent('AddPaymentInfo', basket, null, null, amount, listName);
  }

  logViewContent(listName: string) {
      this.sendFacebookEvent('ViewContent', null, null, null, null, listName);
  }

  logStartCheckout(basket: Basket, listName: string) {
      let amount = 0;
      basket.students.forEach(student => {
        amount += student.coursePrice.totalPrice;
      });

      this.sendFacebookEvent('InitiateCheckout', basket, null, null, amount, listName);
  }

  logAddToCart(student: BasketStudent, listName: string) {
      let amount = student.coursePrice.totalPrice;

      this.sendFacebookEvent('AddToCart', null, student, null, amount, listName);
  }

  logFindLocation(listName: string) {
      this.sendFacebookEvent('FindLocation', null, null, null, null, listName);
  }

  logLead(listName: string) {
      this.sendFacebookEvent('Lead', null, null, null, null, listName);
  }

  private sendRequest(request): void {
   /* if (!environment.production) {
      console.log("Sending Facebook Conversion Event:", request)
    } else {*/
      this.httpClient.post<any>(this.getUrl(), request)
        .subscribe(response => {
          console.debug('Facebook conversion response', response);
        });
/*    }*/
  }

  private sendFacebookEvent(eventName: string, basket: Basket, student: BasketStudent, receiptId: string, amount: number, listName: string) {
    if (this.conversionSetup()) {
      this.authService.isLoggedIn().subscribe(loggedIn => {
        if (!loggedIn) {
          this.generateBodyWithIpAddress(eventName, null, null, basket, student, receiptId, amount, listName);
        } else {
          this.authService.getLoggedInUser().subscribe(loggedInUser => {
            this.apiService.findAddresses('postal').subscribe(results => {
              let address = null;
              if (results.length > 0) {
                address = results[0];
              }
              this.generateBodyWithIpAddress(eventName, loggedInUser, address, basket, student, receiptId, amount, listName);
            });
          });
        }
      });
    }
  }

  private generateBodyWithIpAddress(eventName: string, loggedInPerson: LoggedInUser, address: Address, basket: Basket, student: BasketStudent, receiptId: string, amount: number, listName: string) {
    this.getIPAddress().subscribe(ipAddress => {
      this.sendRequest(this.generateBody(eventName, loggedInPerson, address, basket, student, receiptId, amount, listName, ipAddress));
    }, (error) => {
      console.error('Failed to get IP Address', error);
      this.sendRequest(this.generateBody(eventName, loggedInPerson, address, basket, student, receiptId, amount, listName, null));
    });
  }

  private generateBody(eventName: string, loggedInPerson: LoggedInUser, address: Address, basket: Basket, student: BasketStudent, receiptId: string, amount: number, listName: string, ipAddress: string): any {
    const customData = {};

    if (basket != null || student != null) {
      customData['content_category'] = listName;
      customData['order_id'] = receiptId;
      customData['value'] = amount;
      customData['currency'] = this.applicationSettings.currency;
      customData['content_type'] = 'product';
      customData['content_ids'] = [];
      customData['contents'] = [];

      if (basket != null) {
        for (const basketStudent of basket.students) {
          customData['content_ids'].push(basketStudent.course.id);
          customData['contents'].push({id: basketStudent.course.id, quantity: 1, item_price: basketStudent.coursePrice.totalPrice});
        }
      }

      if (student != null) {
        customData['content_ids'].push(student.course.id);
        customData['contents'].push({id: student.course.id, quantity: 1, item_price: student.coursePrice.totalPrice});
      }

      customData['content_name'] = "Student Enrolment";
    }

    let fbp = null;
    if (this.cookieService.check('_fbp')) {
      fbp = this.cookieService.get('_fbp')
    }

    let fbc = null;
    if (this.cookieService.check('_fbc')) {
      fbp = this.cookieService.get('_fbc')
    }

    const userData = {};
    if (loggedInPerson != null) {
      userData['em'] = [ this.hash(loggedInPerson.email) ];
      userData['ph'] = [ this.hash(loggedInPerson.telephone) ];
      userData['external_id'] = [ this.hash(loggedInPerson.personId) ];
      userData['fn'] = [ this.hash(loggedInPerson.givenName) ];
      userData['ln'] = [ this.hash(loggedInPerson.familyName) ];
    }

    if (address != null) {
      userData['ct'] = [ this.hash(address.locality) ];
      userData['st'] = [ this.hash(address.state) ];
      userData['zp'] = [ this.hash(address.postCode) ];
      userData['country'] = [ this.hash(address.country) ];
    }

    userData["fbc"] = fbc;
    userData["fbp"] = fbp;
    userData["client_ip_address"] = ipAddress;

    return {
      "data": [
        {
          "event_name": eventName,
          "event_time": Math.floor(Date.now() / 1000),
          "action_source": "website",
          "user_data": userData,
          "custom_data": customData
        }
      ]
    };
  }

  private hash(string): string {
    if (string == null) {
      return null;
    }

    return CryptoJS.SHA256(string).toString();
  }

  public getIPAddress(): Observable<string> {
    if (this.ipAddress != null) {
      return new Observable<string>((observer) => {
        observer.next(this.ipAddress);
      });
    }

    const url = 'https://api.ipify.org/?format=json';

    return this.httpClient.get<any>(url).pipe(
      map(response => {
        this.ipAddress = response.ip;
        return this.ipAddress;
      })
    );
  }
}
