<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white; text-align: center" > Please wait&#x2026;<br><br> We are processing your transaction, do not navigate away from this page. </p></ngx-spinner>
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_billing_history']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              confirm booking					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_billing_history']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container multi-step-form">

      <div class="card">
        <div class="multi-step-track">
          <div class="slide-track">
            <form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
              <div class="form-section card-checkout current">
                <div class="card-inner">
                  <div class="table-summary table-checkout">

                    <div class="table-responsive" *ngIf="pendingPaymentSettlementResponse != null">
                      <table class="table table-multiple">
                        <tbody>
                        <tr>
                          <th>{{getInvoiceDescription()}}</th>
                          <th>
                            <span>{{pendingPaymentSettlementResponse.totalAmount | currency:pendingPaymentSettlementResponse.currency:'symbol-narrow'}}</span>
                          </th>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr *ngIf="pendingPaymentSettlementResponse.accountBalance > 0">
                          <th colspan="2">{{pendingPaymentSettlementResponse.accountBalance | currency:pendingPaymentSettlementResponse.currency:'symbol-narrow'}}</th>
                        </tr>
                        <tr *ngIf="pendingPaymentSettlementResponse.accountBalance > 0">
                          <td colspan="2">Account Balance</td>
                        </tr>
                        <tr>
                          <th colspan="2">{{pendingPaymentSettlementResponse.totalAmountWithBalance | currency:pendingPaymentSettlementResponse.currency:'symbol-narrow'}}</th>
                        </tr>
                        <tr>
                          <td colspan="2">total</td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div></div>

                <div class="card-inner">

                  <div class="row row-payment-title" *ngIf="pendingPaymentSettlementResponse != null && pendingPaymentSettlementResponse.totalAmountWithBalance > 0">

                    <h3 class="span-heading title-label">
                      Payment method
                    </h3>
                    <div class="span-cards">
                      <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>		<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>	</div>

                  </div>

                  <button type="submit" *ngIf="pendingPaymentSettlementResponse != null" [ngClass]="processingPayment || paymentForm.invalid || hasError ? 'disabled' : ''" class="btn btn-block btn-red">
                    Process Payment
                  </button>

                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  </main>
</div>

<app-footer></app-footer>
