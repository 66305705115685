export class OnlineBookingsPreferencesPaymentTypeDescription {
  onlineBookingsPreferencePaymentTypeDescriptionId: string;
  sequence: number;
  content: string;
  status: string;
  enrolmentType: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
