import {Component, Inject, Input, isDevMode, OnInit} from '@angular/core';
import Rollbar from 'rollbar';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RollbarService} from '../rollbar';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-error-reporting',
  templateUrl: './error-reporting.component.html',
  styleUrls: ['./error-reporting.component.css']
})
export class ErrorReportingComponent implements OnInit {

  @Input() largeIcon: boolean;

  errorForm: UntypedFormGroup;
  showModal = false;
  showLink = false;
  submitting = false;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private formBuilder: UntypedFormBuilder) {
    this.showLink = environment.rollbarLogging && isDevMode();
  }

  ngOnInit(): void {}

  submitError() {
    if (this.errorForm.errors || this.submitting) {
      return;
    }

    this.submitting = true;

    const errorDetail = this.errorForm.get('errorDetails').value;
    this.rollbar.error(errorDetail);
    this.showModal = false;
    this.submitting = false;
  }

  doShowModal() {
    if (this.errorForm == null) {
      this.errorForm = this.formBuilder.group({
        errorDetails: ['', [Validators.required]]
      });
    }

    this.errorForm.get('errorDetails').setValue('');
    this.showModal = true;
  }
}
