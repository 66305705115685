import {SportsCreditResult} from './sports-credit-result';
import {Student} from './student';
import {convertObjectToDate} from '../_helpers/date_helper';

export class SportsCreditStudentResult {
  student: Student;
  creditResults: SportsCreditResult[];

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.student.dateOfBirth = convertObjectToDate(this.student.dateOfBirth);
    this.creditResults.forEach(result => {
      result.creationDate = convertObjectToDate(result.creationDate);
      result.expiryDate = convertObjectToDate(result.expiryDate);
      if (result.course != null) {
        result.course.startTimeDate = convertObjectToDate(result.course.startTimeDate);
        result.course.endTimeDate = convertObjectToDate(result.course.endTimeDate);
      }
    });
  }
}
