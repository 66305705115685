import {Course} from './course';
import {Enrolment} from './enrolment';
import {convertObjectToDate} from '../_helpers/date_helper';
import {ProgramLevel} from './program-level';

export class CourseReferral {
  startDate: Date;
  endDate: Date;
  status: string;
  type: string;
  code: string;
  courses: Course[];
  enrolments: Enrolment[];
  name: string;
  programLevel: ProgramLevel;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.startDate = convertObjectToDate(this.startDate);
    this.endDate = convertObjectToDate(this.endDate);
    if (this.courses != null) {
      this.courses.forEach(course => {
        course.startTimeDate = convertObjectToDate(course.startTimeDate);
        course.endTimeDate = convertObjectToDate(course.endTimeDate);
      });
    }
    if (this.enrolments != null) {
      this.enrolments.forEach(enrolment => {
        enrolment.confirmDate = convertObjectToDate(enrolment.confirmDate);
        enrolment.dateFirstClassBooked = convertObjectToDate(enrolment.dateFirstClassBooked);
        enrolment.dateLastClassBooked = convertObjectToDate(enrolment.dateLastClassBooked);
        enrolment.provisionalExpiry = convertObjectToDate(enrolment.provisionalExpiry);
      });
    }
  }
}
