import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import moment from 'moment';

export function DateOfBirthValidator(dayName: string, monthName: string, yearName: string) {
  return (formGroup: UntypedFormGroup) => {
    const dayControl = formGroup.controls[dayName];
    const monthControl = formGroup.controls[monthName];
    const yearControl = formGroup.controls[yearName];

    if (dayControl.errors && !dayControl.errors.dateOfBirth) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    const day = parseInt(dayControl.value, 10);
    const month = parseInt(monthControl.value, 10) - 1;
    const year = parseInt(yearControl.value, 10);

    // set error on matchingControl if validation fails
    const date = moment().set(
      {year,
        month,
        date: day
      });

    if (!isValidDate(year, month, day) || date.toDate() > new Date()) {
      dayControl.setErrors({ dateOfBirth: true });
    } else {
      dayControl.setErrors(null);
    }
  };
}

export function DateOfBirthControlValidator(dayName: string, monthName: string) {
  let dayControl: UntypedFormControl;
  let monthControl: UntypedFormControl;
  let yearControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!dayControl) {
      dayControl = control.parent.get(dayName) as UntypedFormControl;
      monthControl = control.parent.get(monthName) as UntypedFormControl;
      yearControl = control;

      dayControl.valueChanges.subscribe(() => {
        yearControl.updateValueAndValidity();
      });

      monthControl.valueChanges.subscribe(() => {
        yearControl.updateValueAndValidity();
      });
    }

    if (yearControl.errors && !yearControl.errors.dateOfBirth) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    const day = parseInt(dayControl.value, 10);
    const month = parseInt(monthControl.value, 10) - 1;
    const year = parseInt(yearControl.value, 10);

    // set error on matchingControl if validation fails
    const date = moment().set(
      {year,
        month,
        date: day
      });

    if (!isValidDate(year, month, day) || date.toDate() > new Date()) {
      return {
        dateOfBirthInvalid: true
      };
    }

    return null;
  };
}

function isValidDate(year, month, day) {
  const d = new Date(year, month, day);

  if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
    if (d < new Date()) {
      return true;
    }
  }

  return false;
}
