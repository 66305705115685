<div class="span-button span-add" *ngIf="!addingVoucherCode && voucherCode == null">
  <button type="button" class="btn btn-add" (click)="showAddVoucher()">
    <span class="svg-icon dashboard-heart"><svg class="svg-dashboard-heart"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-heart"></use></svg></span>have a voucher code	</button>
</div>

<div class="span-button span-add row-edit-voucher" *ngIf="!addingVoucherCode && voucherCode != null">
  Voucher code {{voucherCode}}
  <span *ngIf="voucherUsed">has been applied</span>
  <span *ngIf="!voucherUsed">is not valid for this enrolment</span>
  <div><a [routerLink]="[]" queryParamsHandling="preserve" (click)="showAddVoucher()">edit</a></div>
  <div><a [routerLink]="[]" queryParamsHandling="preserve" (click)="clearVoucher()">clear</a></div>
</div>

<form method="post" id="form_checkout_address" novalidate="novalidate" class="form-voucher" [formGroup]="voucherForm" (ngSubmit)="onVoucherSubmit()">

<div class="row row-voucher row-checkmark" *ngIf="addingVoucherCode">

  <div class="span-input span-4 span-text">
    <input
      type="text"
      id="voucherCode"
      name="voucherCode"
      class="input-text voucherCode"
      formControlName="voucherCodeInput"
      appParsleyValidation
      [submitted]="submitted"
      [elementName]="'Voucher Code'"
    />
    <label
      for="voucherCode"
      class="floating-label">
      voucher code {{addingVoucherCode}}
    </label>
    <i class="asterisk">*</i>
    <i class="checkmark"></i>
    <ul class="parsley-errors-list filled"></ul>
  </div>
  <div *ngIf="error != null" class="voucher-error">{{error}}</div>
</div>

<div class="span-input span-4 span-button row-voucher-button" *ngIf="addingVoucherCode">
  <button type="submit" class="btn btn-red voucher-apply" data-target="row-voucher" [ngClass]="verifyingVoucher || voucherForm.invalid ? 'disabled' : ''">
    apply
  </button>
  <a [routerLink]="[]" queryParamsHandling="preserve" (click)="cancelAddVoucher()">cancel</a>
</div>
</form>
