import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SelectProgramService} from './select-program.service';
import {ApiService} from '../../api.service';
import {Program} from '../../_model/program';
import {Subject, Subscription} from 'rxjs';
import {ApplicationSettingsService} from '../../application-settings.service';

@Component({
  selector: 'app-select-program',
  templateUrl: './select-program.component.html',
  styleUrls: ['./select-program.component.css']
})
export class SelectProgramComponent implements OnInit, OnDestroy {

  programSelectForm: UntypedFormGroup;
  @Input() title: string;
  @Input() dateOfBirth: Date;
  programs: Program[];

  ngUnsubscribe: Subject<void> = new Subject<void>();
  titleSubscription: Subscription;

  constructor(private selectProgramService: SelectProgramService,
              private applicationSettingsService: ApplicationSettingsService,
              private formBuilder: UntypedFormBuilder,
              private apiService: ApiService) {}

  ngOnInit(): void {
    this.titleSubscription = this.selectProgramService.currentTitle.subscribe(message => this.title = message);

    this.programSelectForm = this.formBuilder.group({
      programSelect: ['']
    });

    this.apiService.getPrograms(this.dateOfBirth, null, this.ngUnsubscribe).subscribe(data => {
      if (data.length === 1 && !this.applicationSettingsService.forceProgramSelection) {
        this.selectProgramService.setSetProgramId(data[0]);
      } else {
        this.programs = data;
      }
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.titleSubscription != null) {
      this.titleSubscription.unsubscribe();
    }
  }
  onProgramSelected(programId: string) {
    this.programs.forEach(value => {
      if (value.id === programId) {
        this.selectProgramService.setSetProgramId(value);
      }
    });
  }

  getProgramDescription(program: Program): string {
    let fromDate: string;
    let toDate: string;

    if (program.minimumAgeInMonths != null && program.minimumAgeInMonths > 0) {
      fromDate = this.getAgeValue(program.minimumAgeInMonths);
    }

    if (program.maximumAgeInMonths != null && program.maximumAgeInMonths > 0 && program.maximumAgeInMonths < 99) {
      toDate = this.getAgeValue(program.maximumAgeInMonths);
    }

    if (fromDate != null && toDate != null) {
      return 'from ' + fromDate + ' to ' + toDate;
    } else if (fromDate != null) {
      return 'from ' + fromDate;
    } else if (toDate != null) {
      return 'up to ' + toDate;
    }

    return 'all abilities';
  }

  getAgeValue(ageInMonths: number): string {
    if (ageInMonths < 24) {
      return ageInMonths + ' months';
    }

    let returnValue = Math.floor(ageInMonths / 12) + ' years';
    const remainingMonths = ageInMonths % 12;

    if (remainingMonths > 0) {
      returnValue += ' ' + remainingMonths + ' months';
    }

    return returnValue;
  }
}
