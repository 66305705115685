import {Address} from './address';
import {CreateAccountCourse} from './create-account-course';

export class CreateAccountRequest {
  title: string;
  firstName: string;
  familyName: string;
  email: string;
  password: string;

  address = new Address();
  telephoneHome: string;
  telephoneMobile: string;

  acceptedPrivacyPolicy: boolean;
  policyId: string;

  howDidYouHearId: string;
  howDidYouHearOther: string;

  createAccountCourses: CreateAccountCourse[];

  marketingOptInEmail: boolean;
  marketingOptInSMS: boolean;
}
