import {convertObjectToDate} from '../_helpers/date_helper';

export class PolicyResult {
  name: string;
  type: string;
  id: string;
  validFrom: Date;
  validTo: Date;
  policy: string;
  title: string;
  valid: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.validFrom = convertObjectToDate(this.validFrom);
      this.validTo = convertObjectToDate(this.validTo);
    }
  }
}
