import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AlertService} from '../../alert';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {Course} from '../../_model/course';
import {TransferResponse} from '../../_model/transfer-response';
import {NewEnrolmentRequest} from '../../_model/new-enrolment-request';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PolicyResult} from '../../_model/policy-result';
import {DashboardService} from '../../dashboard/dashboard.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-booking-transfer-confirm',
  templateUrl: './dashboard-booking-transfer-confirm.component.html',
  styleUrls: ['./dashboard-booking-transfer-confirm.component.css']
})
export class DashboardBookingTransferConfirmComponent implements OnInit, OnDestroy {

  transferForm: UntypedFormGroup;

  enrolment: EnrolmentResponse;
  course: Course;
  transferPreview: TransferResponse;
  newEnrolmentRequest: NewEnrolmentRequest;
  termsAndConditions: PolicyResult;
  venuePolicies: PolicyResult[];
  listPosition: number;

  submitted = false;
  running = false;
  isError = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private alertService: AlertService,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Transfer Booking Confirm');

    this.newEnrolmentRequest = new NewEnrolmentRequest();
    this.newEnrolmentRequest.acceptedTermsAndConditions = false;

    this.transferForm = this.formBuilder.group({});

    this.route.queryParams.subscribe(params => {
      if (params.listPosition != null) {
        this.listPosition = parseInt(params.listPosition, 10);
      }
    });
    this.route.params.subscribe(params => {
      this.apiService.getEnrolment(params.enrolment_id, this.ngUnsubscribe).subscribe(result => {
        this.enrolment = result;

        this.newEnrolmentRequest.accountId = this.enrolment.account.id;
        this.newEnrolmentRequest.licenseeId = this.enrolment.course.licenseeId;

        this.isError = false;
        this.apiService.previewTransfer(params.enrolment_id, params.course_id).subscribe(response => {
          this.transferPreview = response;

          if (response.result === 'error') {
            this.alertService.error("Failed to preview transfer: " + response.error);
            this.isError = true;
          } else {
            this.googleAnalytics.viewCourse(this.course, this.enrolment.course.programLevel, this.listPosition, 'Transfer');
            if (this.transferPreview.totalAmount === 0) {
              this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
                this.termsAndConditions = termsAndConditions;
                if (this.termsAndConditions != null) {
                  const formControlFields = [];
                  formControlFields.push({name: 'enrolmentTermsCheckbox', control: new UntypedFormControl('', Validators.required)});

                  formControlFields.forEach(f => this.transferForm.addControl(f.name, f.control));
                }
              });
              this.venuePolicies = [];
              this.apiService.getVenuePolicies(this.course.venueId, this.ngUnsubscribe).subscribe(policies => {
                const policyFormControlFields = [];
                policies.forEach(policy => {
                  if (!this.venuePolicies.includes(policy)) {
                    this.venuePolicies.push(policy);
                    policyFormControlFields.push({
                      name: 'policyCheckbox_' + policy.id,
                      control: new UntypedFormControl('', Validators.required)
                    });
                  }
                });
                policyFormControlFields.forEach(f => this.transferForm.addControl(f.name, f.control));
              });
            }
          }
        });
      });
      this.apiService.getCourse(params.course_id, this.ngUnsubscribe).subscribe(result => {
        this.course = result;
      });
    });

    this.renderer.addClass(document.body, 'dashboard-booking-transfer');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-transfer');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  getTransferControl(componentName: string) {
    if (this.transferForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.transferForm.get(componentName);
  }

  onTransferSubmit(): void {
    if (this.running) {
      return;
    }

    this.submitted = true;
    // stop here if form is invalid
    if (this.transferForm.invalid || this.isError) {
      return;
    }

    this.running = true;

    this.googleAnalytics.addToCartForCourse(this.course,
      this.enrolment.course.programLevel,
      null,
      this.transferPreview.totalAmount,
      this.transferPreview.currencyCode,
      'Transfer',
      this.listPosition);

    if (this.transferPreview.totalAmount === 0) {
      this.newEnrolmentRequest.acceptedTermsAndConditions = this.transferForm.get('enrolmentTermsCheckbox').value;
      this.newEnrolmentRequest.acceptedPolicyIds = [];
      if (this.termsAndConditions != null) {
        this.newEnrolmentRequest.acceptedPolicyIds.push(this.termsAndConditions.id);
      }

      // You have to accept venue policies, so adding them all
      this.venuePolicies.forEach(policy => {
        this.newEnrolmentRequest.acceptedPolicyIds.push(policy.id);
      });

      this.apiService.transfer(this.enrolment.enrolmentId, this.course.id, this.newEnrolmentRequest).subscribe(response => {
        this.googleAnalytics.paymentForCourse(this.course,
          this.enrolment.course.programLevel,
          response.newEnrolmentId,
          response.totalAmountWithBalance,
          response.totalAmount,
          response.currencyCode,
          'Transfer',
          this.listPosition);

        this.router.navigate(['/dashboard_bookings']);
        this.dashboardService.setNewConfirmationMessage('Transfer has been completed');
      }, () => {
        this.running = false;
      });
    } else {
      const listPosition = this.listPosition;
      this.router.navigate(['/dashboard_booking_transfer_payment',
        this.enrolment.enrolmentId, this.course.id], {queryParams: {listPosition}});
    }
  }
}
