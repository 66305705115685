export class PostcodeAddress {
  id: string;
  county: string;
  number: string;
  postcode: string;
  posttown: string;
  premise: string;
  street: string;
  summaryline: string;
  subbuildingname: string;
  pobox: string;
  departmentname: string;
  buildingname: string;
  addressline1: string;
  addressline2: string;
  addressline3: string;
  country: string;

  constructor(id: number, country: string, data?: any) {
    this.id = id.toString(10);
    this.country = country;

    if (data != null) {
      Object.assign(this, data);
    }
  }
}
