import {PhoneNumberFormat as PNF, PhoneNumberUtil} from 'google-libphonenumber';

export function formatNumber(telephoneNumber: string, countryCode: string) {
  if (telephoneNumber == null || telephoneNumber === '') {
    return '';
  }

  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  let phoneNumber;
  try {
    phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
      telephoneNumber, countryCode
    );
  } catch (err) {
    console.error('Failed to parse number: ' + err);
    return telephoneNumber;
  }
  const validNumber = phoneNumberUtil.isValidNumber(phoneNumber);

  let numberFormat = PNF.NATIONAL;
  if (telephoneNumber.startsWith('+')) {
    numberFormat = PNF.INTERNATIONAL;
  }

  if (validNumber) {
    return phoneNumberUtil.format(phoneNumber, numberFormat);
  } else {
    return telephoneNumber;
  }
}
