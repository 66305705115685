import {Component, Inject, isDevMode, Renderer2} from '@angular/core';
import Rollbar from 'rollbar';
import {RollbarService} from './rollbar';
import {AuthService} from './auth-service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ApplicationSettingsService} from './application-settings.service';
import {environment} from '../environments/environment';
import '@stripe/stripe-js';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {SessionService} from './session.service';
import {ApiService} from './api.service';
import {DOCUMENT} from '@angular/common';

declare let gtag: any;
declare const fbq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'flow-enrol-web';

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              public applicationSettingsService: ApplicationSettingsService,
              public renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private readonly keycloakService: KeycloakService) {
    if (!environment.rollbarLogging) {
      rollbar.configure({enabled: false});
      console.log('development/sandbox mode, setting rollbar off');
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.logAnalytics(event.urlAfterRedirects);
        const request = location.href;
        this.authService.isLoggedIn().subscribe(loggedIn => {
          if (loggedIn) {
            this.apiService.registerPageView(request).subscribe();
          }
        }, (error => {
          console.error('Failed to log url', error);
        }));
      }
    });

    this.loadCookieYes();

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      console.log('User is now online...');
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      console.log('User is now offline...');
    }));

    keycloakService.keycloakEvents$.asObservable().subscribe(event => {
      console.log('Received event: ', KeycloakEventType[event.type]);

      if (event.type === KeycloakEventType.OnTokenExpired) {
        this.keycloakService.updateToken().then(refreshed => {
          if (!refreshed) {
            const url = [];
            this.route.snapshot.url.forEach(element => {
              url.push(element.path);
            });

            this.authService.logout(url.join('/') + window.location.search);
          }
        });
      }
    });

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        if (this.sessionService.basket != null) {
          if (this.sessionService.basket.accountId != null) {
            console.log('User is no longer logged in, removing basket');
            this.sessionService.removeBasket();
          }
        }
      } else {
        this.authService.getLoggedInUser().subscribe(loggedInUser => {
          if (environment.rollbarLogging) {
            rollbar.configure({
              payload: {
                // The usual
                person: {
                  id: loggedInUser.personId,
                  username: loggedInUser.username,
                  email: loggedInUser.email
                }
              }
            });
          }
        });
      }
    });
  }

  loadCookieYes() {
    if (this.applicationSettingsService.cookieYesIntegration) {
      let script = document.createElement('script');
      script.src = this.applicationSettingsService.cookieYesUrl;
      script.type = 'text/javascript';
      script.id = 'cookieyes';

      this.renderer2.listen(script, 'load', () => {
        this.initAnalytics();
      });

      this.renderer2.appendChild(this._document.head, script);
    } else {
      this.initAnalytics();
    }
  }

  initAnalytics() {
    if (this.applicationSettingsService.facebookPixelId != null && this.applicationSettingsService.facebookPixelId !== '') {
      if (!isDevMode()) {
        fbq('init', this.applicationSettingsService.facebookPixelId);
      } else {
        console.log('Facebook init: ' + this.applicationSettingsService.facebookPixelId);
      }
    }
  }

  logAnalytics(url: string) {
    if (this.applicationSettingsService.googleAnalyticsTrackingCode != null) {
      if (!isDevMode()) {
        gtag('config', this.applicationSettingsService.googleAnalyticsTrackingCode,
          {
            page_path: url
          }
        );
      } else {
        console.log('Google analytics page [' + this.applicationSettingsService.googleAnalyticsTrackingCode + ']: ' + url);
      }
    }
    if (this.applicationSettingsService.facebookPixelId != null && this.applicationSettingsService.facebookPixelId !== '' && !isDevMode()) {
      fbq('track', 'PageView');
    } else {
      console.log('Facebook page view: ' + url);
    }
  }
}

