import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FindCourseMultipleServiceStep} from './find-course-multiple-service-step.service';
import {StudentName} from '../find-course-multiple-student/student-name';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-find-course-multiple-step',
  templateUrl: './find-course-multiple-step.component.html',
  styleUrls: ['./find-course-multiple-step.component.css']
})
export class FindCourseMultipleStepComponent implements OnInit, OnDestroy {

  currentStepNumber = 1;
  @Input() stepNumber: number;
  @Input() studentName: string;

  studentNameSubscription: Subscription;
  selectedStudentSubscription: Subscription;

  constructor(private findCourseMultipleServiceService: FindCourseMultipleServiceStep) {}

  ngOnInit(): void {
    this.studentNameSubscription = this.findCourseMultipleServiceService.currentStudentName
      .subscribe(studentName => this.updateStudentName(studentName));
    this.selectedStudentSubscription = this.findCourseMultipleServiceService.currentSelectedStudent
      .subscribe(selectedStudent => this.currentStepNumber = selectedStudent);
  }

  ngOnDestroy() {
    if (this.studentNameSubscription != null) {
      this.studentNameSubscription.unsubscribe();
    }
    if (this.selectedStudentSubscription != null) {
      this.selectedStudentSubscription.unsubscribe();
    }
  }

  updateStudentName(studentName: StudentName) {
    if (studentName != null && this.stepNumber === studentName.studentId) {
      this.studentName = studentName.studentName;
    }
  }
}
