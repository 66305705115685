import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationSettingsService} from '../application-settings.service';
import {ApiService} from '../api.service';
import {ConfirmationBlock} from '../_model/confirmation-block';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-booking-complete',
  templateUrl: './booking-complete.component.html',
  styleUrls: ['./booking-complete.component.css']
})
export class BookingCompleteComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,
              private applicationSettingsService: ApplicationSettingsService,
              private apiService: ApiService) { }

  ngUnsubscribe: Subject<void> = new Subject<void>();

  receiptIds: string[];
  numberOfReceipts = 0;
  confirmationBlocks: ConfirmationBlock[];

  ngOnInit(): void {
    this.receiptIds = [];
    this.route.queryParams
      .subscribe(params => {
        if (params.receiptIds != null && params.receiptIds !== '') {
          this.receiptIds = params.receiptIds.split(',');
          this.numberOfReceipts += this.receiptIds.length;
        }
        if (params.receiptId != null && params.receiptId !== '') {
          this.receiptIds.push(params.receiptId);
          this.numberOfReceipts += 1;
        }
      });

    this.apiService.getConfirmationBlocks(this.ngUnsubscribe).subscribe(blocks => {
      this.confirmationBlocks = blocks;

      let counter = 1;
      this.confirmationBlocks.forEach(block => {
        if (counter === 3) {
          counter = 1;
        }

        switch (counter) {
          case 1:
            block.class = 'span-blue';
            break;
          case 2:
            block.class = 'span-red';
            break;
        }

        counter++;
      });
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  getReceiptText() {
    let receiptText = '';

    this.receiptIds.forEach(receiptId => {
      if (receiptText !== '') {
        receiptText += ', ';
      }

      receiptText += receiptId;
    });

    return receiptText;
  }

  getIconLocation(block: ConfirmationBlock): string {
    return './assets/sprite/spritemap.svg#' + block.icon;
  }
}
