import {Class} from './class';
import {convertObjectToDate} from '../_helpers/date_helper';

export class Booking {
  attendance: string;
  bookedClass: Class;
  bookingCustomerTrend: string;
  bookingCustomerType: string;
  bookingId: string;
  expiryDate: Date;
  status: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.expiryDate = convertObjectToDate(this.expiryDate);
      if (this.bookedClass != null) {
        this.bookedClass.startDate = convertObjectToDate(this.bookedClass.startDate);
        this.bookedClass.endDate = convertObjectToDate(this.bookedClass.endDate);
      }
    }
  }
}
