import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Program} from '../../_model/program';
import {ProgramLevel} from '../../_model/program-level';

@Injectable({
  providedIn: 'root'
})
export class InteractiveSkillCheckService {

  currentProgramId: Observable<string>;
  private programIdSubject = new BehaviorSubject<string>(null);

  currentProgramLevel: Observable<ProgramLevel>;
  private programLevelSubject = new BehaviorSubject<ProgramLevel>(null);

  constructor() {
    this.currentProgramId = this.programIdSubject.asObservable();
    this.currentProgramLevel = this.programLevelSubject.asObservable();
  }

  setProgramId(programId: string) {
    this.programIdSubject.next(programId);
  }

  setProgramLevel(programLevel: ProgramLevel) {
    this.programLevelSubject.next(programLevel);
  }
}
