import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from 'rxjs';
import {TitleGenerator} from '../title-generator';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BookingSurveyResponse} from '../_model/booking-survey-response';
import {SurveyRequest} from '../_model/survey-request';
import {SurveyRequestValue} from '../_model/survey-request-value';
import {BookingHeaderTitleServiceService} from '../booking-header/booking-header-title-service.service';
import {SportsFeedbackParameter} from '../_model/sports-feedback-parameter';
import {ApplicationSettingsService} from '../application-settings.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SurveyResponse} from '../_model/survey-response';
import {MultipleChoiceCheckboxRequiredValidator} from '../_helpers/multiple_choice_checkbox_required_validator';
import {SportsFeedbackParameterAnswer} from '../_model/sports-feedback-parameter-answer';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements  OnInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  feedbackForm: UntypedFormGroup;
  submitting = false;
  submitted = false;
  surveyComplete = false;
  surveyInvalid = false;
  loading = true;

  bookingSurvey: BookingSurveyResponse;
  survey: SurveyResponse;
  surveyQuestions: SportsFeedbackParameter[];

  constructor(private titleService: TitleGenerator,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private renderer: Renderer2,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private spinner: NgxSpinnerService,
              private formBuilder: UntypedFormBuilder,
              private applicationService: ApplicationSettingsService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Booking Survey');
    this.spinner.show();

    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'home');

    this.feedbackForm = this.formBuilder.group({});
    this.surveyQuestions = [];

    this.route.params.subscribe(params => {
      if (params.bookingSurveyId != null) {
        this.apiService.getBookingSurvey(params.bookingSurveyId, this.ngUnsubscribe).subscribe(survey => {
          this.bookingSurvey = survey;
          this.survey = survey.surveyResponse;

          this.processSurveyResponse();

          this.loading = false;
        });
      } else {
        this.apiService.getSurvey(params.id, this.ngUnsubscribe).subscribe(survey => {
          this.survey = survey;

          this.processSurveyResponse();

          this.loading = false;
        });
      }
    });
  }

  processSurveyResponse(): void {
    this.surveyInvalid = this.survey.expired || this.survey.verified;
    this.bookingHeaderTitleServiceService.setTitle(this.survey.heading);

    this.survey.questions.forEach(question => {
      if (!question.hidden) {
        this.surveyQuestions.push(question);
      }
    });

    const formControlFields = [];
    this.surveyQuestions.forEach(question => {
      if (question.type !== 'rating') {
        if (question.type !== 'multiple_choice') {
          formControlFields.push({
            name: 'question_' + question.feedbackParameterId,
            control: new UntypedFormControl('', question.required ? Validators.required : null)
          });
        } else {
          const formGroup = new UntypedFormGroup({});
          question.answers.forEach((answer) => {
            formGroup.addControl('question_' + question.feedbackParameterId + '_' + answer.feedbackParameterAnswerId, new UntypedFormControl(false));
          });

          if (question.required) {
            formGroup.setValidators(MultipleChoiceCheckboxRequiredValidator());
          }

          formControlFields.push({
            name: 'multipleChoiceGroup_' + question.feedbackParameterId,
            control: formGroup
          });
        }
      }
    });

    if (this.surveyQuestions.length === 0) {
      this.surveyComplete = true;
    }

    formControlFields.forEach(f => this.feedbackForm.addControl(f.name, f.control));

    this.route.queryParams.subscribe(queryParam => {
      const response = queryParam.response;

      if (response != null && response !== '') {
        const responseItems = response.split(',');

        const surveyRequest = new SurveyRequest();
        surveyRequest.id = this.survey.id;
        surveyRequest.values = [];

        responseItems.forEach(responseItem => {
          const responseSet = responseItem.split(':');

          if (responseSet.length === 2) {
            const paramId = responseSet[0];
            const value = responseSet[1];

            this.survey.questions.forEach(question => {
              if (question.feedbackParameterId === paramId) {
                const surveyRequestValue = new SurveyRequestValue();
                surveyRequestValue.parameterId = paramId;

                if (question.type === 'nps' || question.type === 'scale') {
                  surveyRequestValue.numericValue = value;
                } else if (question.type === 'true_false') {
                  surveyRequestValue.booleanValue = value;
                } else if (question.type === 'list' || question.type === 'multiple_choice') {
                  surveyRequestValue.answerIds.push(value.split(','));
                } else {
                  surveyRequestValue.textValue = value;
                }

                surveyRequest.values.push(surveyRequestValue);
              }
            });
          }
        });

        if (surveyRequest.values.length > 0) {
          this.apiService.updateSurvey(surveyRequest, this.ngUnsubscribe).subscribe(() => {
          });
        }
      }
      this.spinner.hide();
    });
  }

  getResourceText(parameter: SportsFeedbackParameter): string {
    let returnVal = '';
    parameter.resourceKey.values.forEach(value => {
      if (value.resourceLocale.localeName === this.applicationService.localeLanguage) {
        returnVal = this.mergeText(value.value);
      }
    });
    return returnVal;
  }

  mergeText(returnVal: string): string {
    returnVal = returnVal.replace('${master_licensee}', this.applicationService.companyName)
      .replace('${master_licensee_name}', this.applicationService.companyName);

    if (this.bookingSurvey != null) {
      returnVal = returnVal.replace('${instructor_given_name}',
        this.bookingSurvey.instructorGivenName);
    }

    return returnVal;
  }

  scaleList(parameter: SportsFeedbackParameter): number[] {
    return this.numberList(parameter.scaleFrom, parameter.scaleTo);
  }

  npsList(): number[] {
    return this.numberList(1, 10);
  }

  numberList(fromVal: number, toVal: number): number[] {
    const returnVal = [];
    for (let i = fromVal; i <= toVal; i++) {
      returnVal.push(i);
    }
    return returnVal;
  }

  getScaleWidth(parameter: SportsFeedbackParameter): string {
    return 100 / (parameter.scaleTo - (parameter.scaleFrom - 1)) + '%';
  }

  onFeedbackSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.feedbackForm.invalid) {
      window.scroll(0, 0);
      return;
    }

    this.submitting = true;

    const surveyRequest = new SurveyRequest();
    surveyRequest.id = this.survey.id;
    surveyRequest.values = [];

    this.surveyQuestions.forEach(question => {
      if (question.type === 'ranking') {
        const surveyRequestValue = new SurveyRequestValue();
        surveyRequestValue.parameterId = question.feedbackParameterId;
        surveyRequestValue.answerIds = [];

        question.answers.forEach((answer) => {
          surveyRequestValue.answerIds.push(answer.feedbackParameterAnswerId);
        });

        surveyRequest.values.push(surveyRequestValue);
      } else {
        if (question.type !== 'multiple_choice') {
          const value = this.feedbackForm.controls['question_' + question.feedbackParameterId].value;
          if (value != null) {
            const surveyRequestValue = new SurveyRequestValue();
            surveyRequestValue.parameterId = question.feedbackParameterId;

            if (question.type === 'nps' || question.type === 'scale') {
              surveyRequestValue.numericValue = value;
            } else if (question.type === 'true_false') {
              surveyRequestValue.booleanValue = value;
            } else if (question.type === 'list') {
              surveyRequestValue.answerIds = [];
              surveyRequestValue.answerIds.push(value);
            } else {
              surveyRequestValue.textValue = value;
            }

            surveyRequest.values.push(surveyRequestValue);
          }
        } else {
          const surveyRequestValue = new SurveyRequestValue();
          surveyRequestValue.answerIds = [];

          surveyRequestValue.parameterId = question.feedbackParameterId;

          question.answers.forEach((answer) => {
            if (answer.status === 'active') {
              const control = this.feedbackForm
                .get('multipleChoiceGroup_' + question.feedbackParameterId
                  + '.question_' + question.feedbackParameterId + '_' + answer.feedbackParameterAnswerId);
              if (control.value === true) {
                surveyRequestValue.answerIds.push(answer.feedbackParameterAnswerId);
              }
            }
          });
          surveyRequest.values.push(surveyRequestValue);
        }
      }
    });
    if (surveyRequest.values.length > 0) {
      this.apiService.updateSurvey(surveyRequest, this.ngUnsubscribe).subscribe(() => {
        this.surveyComplete = true;
        this.bookingHeaderTitleServiceService.setTitle('Thanks for letting us know');
      }, () => {
        this.submitting = false;
      }, () => {
        this.submitting = false;
      });
    } else {
      this.submitting = false;
    }
  }

  getLabelForScale(feedbackParameter: SportsFeedbackParameter, scaleVal: number): string {
    const keys = Object.keys(feedbackParameter.scaleLabels);
    let returnValue = '';
    if (keys.length > 0) {
      Object.keys(feedbackParameter.scaleLabels).forEach(key => {
        if (scaleVal === parseInt(key, 10)) {
          returnValue = feedbackParameter.scaleLabels[key];
        }
      });
    } else if (scaleVal === feedbackParameter.scaleFrom) {
      returnValue = 'Strongly disagree';
    } else if (scaleVal === feedbackParameter.scaleTo) {
      returnValue = 'Strongly agree';
    }
    return returnValue;
  }

  getAnswers(feedbackParameter: SportsFeedbackParameter): SportsFeedbackParameterAnswer[] {
    const answerList = [];
    feedbackParameter.answers.forEach(answer => {
      if (answer.status === 'active') {
        answerList.push(answer);
      }
    });

    return answerList;
  }

  rankingDrop(answers: SportsFeedbackParameterAnswer[], event: CdkDragDrop<string[]>): void {
    moveItemInArray(answers, event.previousIndex, event.currentIndex);
  }

  showError(question: SportsFeedbackParameter): boolean {
    if (question.type !== 'multiple_choice') {
      return this.feedbackForm.controls['question_' + question.feedbackParameterId].errors && this.submitted;
    } else {
      return this.feedbackForm.controls['multipleChoiceGroup_' + question.feedbackParameterId].errors && this.submitted;
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }
}
