<!-- if its a dashboard create a wrapper for dashboard -->

<div class="wgt-cart-toolbar" *ngIf="students.length > 0">
    <div class="container">
      <div class="row">
        <div class="span-basket">
          my basket ({{students.length}})
        </div>
        <div class="span-student">
          <ul class="student-basket" id="{{student.id}}" *ngFor="let student of students">
            <li class="name">{{student.student.studentName}}</li>
            <li>{{student.course.fullDayOfWeekNames}} @ {{student.course.startTimeDate | worldDate:'HH:mm'}}</li>
            <li>{{student.course.venueName}}</li>
            <li class="price"><span *ngIf="student.coursePrice.priceType === 'CREDIT_PACK'">from </span>{{getPrice(student.coursePrice) | currency:currencyCode:'symbol-narrow'}} per {{formatPriceTypeShort(student.coursePrice.priceType)}}</li>
          </ul>
        </div>
        <div class="span-button">
          <a [routerLink]="[]" queryParamsHandling="preserve" (click)="basketCheckout()" class="btn btn-ckeckout">
            view
            <span class="arrow"></span>
          </a>
        </div>
      </div>
    </div>
</div>
