import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PostcodeAddress} from '../../_model/postcode-address';
import {Address} from '../../_model/address';
import {PostcodeLookupService} from '../../_model/postcode-lookup.service';
import {PaymentCountry} from '../../_model/payment-country';
import {ApiService} from '../../api.service';
import {BasketService} from '../basket.service';
import {ApplicationSettingsService} from '../../application-settings.service';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, OnDestroy {

  @Input() submitted: boolean;
  @Input() addressForm: UntypedFormGroup;
  @Input() address: Address;
  @Input() addressLinesActive: boolean;

  postCodeLostFocus = false;

  postcodeAddresses: PostcodeAddress[];
  paymentCountries: PaymentCountry[];

  countryCode: string;
  postcodeLookup: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();
  settingsSubscription: Subscription;

  constructor(private apiService: ApiService,
              private basketService: BasketService,
              private postcodeLookupService: PostcodeLookupService,
              private applicationSettings: ApplicationSettingsService) {}

  ngOnInit(): void {
    this.apiService.getCountries(this.ngUnsubscribe).subscribe((countries) => {
      this.paymentCountries = countries;
    });
    this.countryCode = this.applicationSettings.countryCode;

    this.patchAddress();
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.settingsSubscription != null) {
      this.settingsSubscription.unsubscribe();
    }
  }

  patchAddress() {
    this.addressForm.patchValue({
      addressLine1Input: this.address.addressLine1,
      addressLine2Input: this.address.addressLine2,
      addressLine3Input: this.address.addressLine3,
      addressCityInput: this.address.locality,
      addressCountyInput: this.address.state,
      addressCountryInput: this.address.country,
      addressPostcodeInput: this.address.postCode
    });
  }

  postcodeFocusOut() {
    this.postcodeLookup = this.addressForm.get('postcodeLookupInput').value;
    if (this.postcodeLookup != null && this.postcodeLookup !== '') {
      this.postcodeLookup = this.postcodeLookup.toUpperCase();
      if (this.postcodeLookup.indexOf(' ') === -1) {
        this.postcodeLookup = this.postcodeLookup.replace(/^(.*)(\d)/, '$1 $2');
      }

      this.addressForm.patchValue({
        postcodeLookupInput: this.postcodeLookup
      });

      this.postCodeLostFocus = true;
    }
  }

  lookupAddress() {
    const addressNumber = this.addressForm.get('postcodeLookupHouseNumberInput').value;
    this.postcodeLookupService.lookupPostcode(this.postcodeLookup, this.countryCode).subscribe(response => {
      this.postcodeAddresses = response;

      this.postcodeAddresses.forEach(postcodeAddress => {
        if (postcodeAddress.number === addressNumber) {
          this.addressForm.patchValue({
            postcodeResultsSelect: postcodeAddress.id
          });
        }
      });

      this.addressListChange();
    });
  }

  addressListChange() {
    const selectedAddressId = this.addressForm.get('postcodeResultsSelect').value;
    this.postcodeAddresses.forEach(address => {
      if (selectedAddressId === address.id) {
        this.address = this.getAddress(address);
        this.patchAddress();
      }
    });

    this.addressUpdated();
  }

  getAddress(postCodeAddress: PostcodeAddress): Address {
    const address = new Address();

    address.addressLine1 = postCodeAddress.addressline1;
    address.addressLine2 = postCodeAddress.addressline2;
    address.addressLine3 = postCodeAddress.addressline3;
    address.locality = postCodeAddress.posttown;
    address.postCode = postCodeAddress.postcode;
    address.state = postCodeAddress.county;
    address.country = postCodeAddress.country;

    return address;
  }

  getAddressControl(componentName: string) {
    if (this.addressForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.addressForm.get(componentName);
  }

  addressFieldUpdated() {
    this.address.addressLine1 = this.addressForm.get('addressLine1Input').value;
    this.address.addressLine2 = this.addressForm.get('addressLine2Input').value;
    this.address.addressLine3 = this.addressForm.get('addressLine3Input').value;
    this.address.locality = this.addressForm.get('addressCityInput').value;
    this.address.state = this.addressForm.get('addressCountyInput').value;
    this.address.country = this.addressForm.get('addressCountryInput').value;
    this.address.postCode = this.addressForm.get('addressPostcodeInput').value;

    this.addressUpdated();
  }

  addressUpdated() {
    this.basketService.setAddress(this.address);
  }
}
