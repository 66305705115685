<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="7"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <app-dashboard-subscription-subscription *ngFor="let account of accounts" [account]="account"></app-dashboard-subscription-subscription>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
