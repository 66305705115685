<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info">
        <h2 class="title">
          				</h2>
        <div class="info">

          					</div>
      </div>


      <div class="span-back">
        <button type="button" class="btn btn-back" id="btn-prev" (click)="goBack()">
          <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
        </button>
      </div>

    </div>
  </div>
</div>
