import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../alert';
import {ApiService} from '../../api.service';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';

@Component({
  selector: 'app-direct-debit-mandate-redirect',
  templateUrl: './direct-debit-mandate-redirect.component.html',
  styleUrls: ['./direct-debit-mandate-redirect.component.css']
})
export class DirectDebitMandateRedirectComponent implements OnInit {

  status: string;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              private router: Router,
              private titleService: BookingHeaderTitleServiceService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Completing Direct Debit Setup');

    this.route.queryParams
      .subscribe(params => {
        const redirectFlowId = params.redirect_flow_id;
        const sessionId = params.session_id;
        const action = params.action;

        let redirectId = redirectFlowId;
        if (redirectId == null) {
          redirectId = sessionId;
        }

        if (action === 'checkout') {
          this.status = 'Redirecting you...';
        } else {
          this.status = 'Completing mandate setup...';
        }

        if (redirectId == null) {
          this.alertService.error('Missing parameter');
        } else {
          this.apiService.createMandate(redirectId).subscribe((mandateId) => {
            if (action === 'checkout') {
              const referrer = this.router.url;
              this.router.navigate(['/checkout'], {queryParams: {referrer}});
            } else if (action === 'edit-mandate') {
              const accountId = params.accountId;
              const paymentId = params.paymentId;

              this.router.navigate(['/dashboard_edit_direct_debit', accountId, 'direct_debit', paymentId], {queryParams: {mandateId}});
            } else {
              this.status = 'Your mandate has now been successfully completed. Thank you!';
            }
          });
        }
      });
  }

}
