import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Program} from './_model/program';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ProgramLevel} from './_model/program-level';
import {CourseSearchParameters} from './_model/course-search-parameters';
import {ProgramLevelQuestion} from './_model/program-level-question';
import {CourseSearchResult} from './_model/course-search-result';
import {CoursePriceList} from './_model/course-price-list';
import {Venue} from './_model/venue';
import {Address} from './_model/address';
import {CourseSearchUpdateRequest} from './_model/course-search-update-request';
import {CourseSearchUpdateResult} from './_model/course-search-update-result';
import {PolicyResult} from './_model/policy-result';
import {AccountCheckResponse} from './_model/account-check-response';
import {HowDidYouHearType} from './_model/how-did-you-hear-type';
import {PaymentCountry} from './_model/payment-country';
import {CreateAccountRequest} from './_model/create-account-request';
import {CreateAccountResult} from './_model/create-account-result';
import {Student} from './_model/student';
import {CreateStudentRequest} from './_model/create-student-request';
import {CreateStudentResponse} from './_model/create-student-response';
import {AbandonedCartRequest} from './_model/abandoned-cart-request';
import {Account} from './_model/account';
import {Card} from './_model/card';
import {PaymentGatewayResponse} from './_model/payment-gateway-response';
import {PaymentMethod} from './_model/payment-method';
import {NewEnrolmentResponse} from './_model/new-enrolment-response';
import {NewEnrolmentRequest} from './_model/new-enrolment-request';
import {EnrolmentResponse} from './_model/enrolment-response';
import {AccountSummary} from './_model/account-summary';
import {WaitingListResult} from './_model/waiting-list-result';
import {Term} from './_model/term';
import {WaitingListRequest} from './_model/waiting-list-request';
import {SportsCreditStudentResult} from './_model/sports-credit-student-result';
import {Booking} from './_model/booking';
import {StudentAchievement} from './_model/student-achievement';
import {LevelAchievement} from './_model/level-achievement';
import {AwardAchievement} from './_model/award-achievement';
import {ModuleAchievement} from './_model/module-achievement';
import {ChallengeAchievement} from './_model/challenge-achievement';
import {DirectDebitResponse} from './_model/direct-debit-response';
import {Person} from './_model/person';
import {AccountBillingItem} from './_model/account-billing-item';
import {DirectDebitCustomerDetails} from './_model/direct-debit-customer-details';
import {UpdatePasswordRequest} from './_model/update-password-request';
import {UpdatePersonRequest} from './_model/update-person-request';
import {WaitingListRequestSchedule} from './_model/waiting-list-request-schedule';
import {Course} from './_model/course';
import {Class} from './_model/class';
import {WithdrawalReason} from './_model/withdrawal-reason';
import {TransferResponse} from './_model/transfer-response';
import {CreditPackResult} from './_model/credit-pack-result';
import {CreditPackPurchaseRequest} from './_model/credit-pack-purchase-request';
import {CreditPackPurchaseResponse} from './_model/credit-pack-purchase-response';
import {FlexibleCourseEnrolmentRequest} from './_model/flexible-course-enrolment-request';
import {environment} from 'src/environments/environment';
import {RequestAccessResponse} from './_model/request-access-response';
import {MasterLicenseeSettings} from './_model/master-licensee-settings';
import {UpgradeToMultipleTermsRequest} from './_model/upgrade-to-multiple-terms-request';
import {UpgradeToMultipleTermsResponse} from './_model/upgrade-to-multiple-terms-response';
import {PreSaleUpgradeRequest} from './_model/pre-sale-upgrade-request';
import {PreSaleUpgradeResponse} from './_model/pre-sale-upgrade-response';
import {MembershipType} from './_model/membership-type';
import {Membership} from './_model/membership';
import {VoucherCodeValidationResponse} from './_model/voucher-code-validation-response';
import {CreditPackResponse} from './_model/credit-pack-response';
import {ConfirmationBlock} from './_model/confirmation-block';
import {OnlineBookingsPreferencesPaymentTypeDescription} from './_model/online-bookings-preferences-payment-type-description';
import {PendingPayment} from './_model/pending-payment';
import {PendingPaymentSettlementRequest} from './_model/pending-payment-settlement-request';
import {PendingPaymentSettlementResponse} from './_model/pending-payment-settlement-response';
import {AbandonedCartResponse} from './_model/abandoned-cart-response';
import {CourseReferral} from './_model/course-referral';
import {LoggedInUser} from './_model/logged-in-user';
import {AchievedAwardCertificateDescription} from './_model/achieved-award-certificate-description';
import {SubscriptionRequest} from './_model/subscription-request';
import {BookingSurveyResponse} from './_model/booking-survey-response';
import {SurveyRequest} from './_model/survey-request';
import {SurveyResponse} from './_model/survey-response';
import {APP_BASE_HREF} from '@angular/common';
import {VenueSearchDistance} from './_model/venue-search-distance';
import {PendingPaymentActionResponse} from './_model/pending-payment-action-response';
import {AutoEnrolmentResponse} from './_model/auto-enrolment-response';
import {UpdateAccountRequest} from './_model/update-account-request';
import {Mandate} from './_model/mandate';
import {SubscriptionCancellationResponse} from './_model/subscription-cancellation-response';
import {SubscriptionUpdateResponse} from './_model/subscription-update-response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(@Inject(APP_BASE_HREF) private href: string,
              private httpClient: HttpClient) { }

  public getPrograms(dateOfBirth: Date, studentId: string,
                     unsubscribeSubject?: Subject<void>): Observable<Array<Program>> {
    let dateString = '';

    if (dateOfBirth != null) {
      dateString = dateOfBirth.getDate() + '/' + (dateOfBirth.getMonth() + 1) + '/' + dateOfBirth.getFullYear();
    }

    if (studentId == null) {
      studentId = '';
    }

    return this.httpClient.get<any>(environment.apiUrl + 'organisation/programs?dateOfBirth='
                                        + dateString + '&studentId=' + studentId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.programs as any[];
          return array.map(data => new Program(data));
        }
        return new Array<Program>();
      })
    );
  }

  public getProgramLevels(programId: string,
                          unsubscribeSubject?: Subject<void>): Observable<Array<ProgramLevel>> {
    if (programId == null) {
      return new Observable<Array<ProgramLevel>>((observer) => {
        observer.next(new Array<ProgramLevel>());
      });
    }

    return this.httpClient.get<any>(environment.apiUrl + 'organisation/programs/'
      + programId + '/program_levels').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.program_levels as any[];
          return array.map(data => new ProgramLevel(data));
        }
        return new Array<ProgramLevel>();
      })
    );
  }

  public searchForCourses(courseSearchParameters: CourseSearchParameters,
                          unsubscribeSubject?: Subject<void>): Observable<Array<CourseSearchResult>> {
    return this.httpClient.post<any>(environment.apiUrl + '/organisation/course/search',
      courseSearchParameters).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
        if (response._embedded != null) {
          const array = response._embedded.courseSearchResultList as any[];
          return array.map(data => new CourseSearchResult(data));
        }
        return new Array<CourseSearchResult>();
      })
    );
  }

  public courseCreditSearch(studentId: string, creditId: string, courseSearchParameters: CourseSearchParameters,
                            unsubscribeSubject?: Subject<void>): Observable<Array<CourseSearchResult>> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/students/' + studentId + '/credits/' + creditId + '/search',
      courseSearchParameters).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.courseSearchResultList as any[];
          return array.map(data => new CourseSearchResult(data));
        }
        return new Array<CourseSearchResult>();
      })
    );
  }

  public programLevelFinder(programId: string, questionId: string,
                            unsubscribeSubject?: Subject<void>): Observable<ProgramLevelQuestion> {
    let urlString = environment.apiUrl + 'organisation/program/' + programId + '/level/finder';

    if (questionId != null && questionId !== '') {
      urlString += '/' + questionId;
    }

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new ProgramLevelQuestion(response);
      })
    );
  }

  public getCoursePrices(courseId: string, programLevelId: string, studentId: string, dateOfBirth?: Date,
                         unsubscribeSubject?: Subject<void>): Observable<CoursePriceList> {
    let urlString = environment.apiUrl + 'organisation/course/' + courseId + '/prices?';

    if (studentId != null) {
      urlString += '&studentId=' + studentId;
    }

    if (programLevelId != null) {
      urlString += '&programLevelId=' + programLevelId;
    }

    if (dateOfBirth != null) {
      urlString += '&dateOfBirth=' + dateOfBirth.toISOString();
    }

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new CoursePriceList(response);
      })
    );
  }

  public getEnrolmentPrices(enrolmentId: string,
                            unsubscribeSubject?: Subject<void>): Observable<CoursePriceList> {
    const urlString = environment.apiUrl + 'user/enrolment/' + enrolmentId + '/prices';

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new CoursePriceList(response);
      })
    );
  }

  public getProgramLevel(programLevelId: string,
                         unsubscribeSubject?: Subject<void>): Observable<ProgramLevel> {
    const urlString = environment.apiUrl + 'organisation/program_levels/' + programLevelId;

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new ProgramLevel(response);
      })
    );
  }

  public getProgram(programId: string,
                    unsubscribeSubject?: Subject<void>): Observable<Program> {
    if (programId == null) {
      return new Observable<Program>((observer) => {
        observer.next(null);
      });
    }

    const urlString = environment.apiUrl + 'organisation/programs/' + programId;

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Program(response);
      })
    );
  }

  public getVenue(venueId: string,
                  unsubscribeSubject?: Subject<void>): Observable<Venue> {
    const urlString = environment.apiUrl + 'organisation/venues/' + venueId;

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Venue(response);
      })
    );
  }

  public getAddress(venueId: string,
                    unsubscribeSubject?: Subject<void>): Observable<Address> {
    const urlString = environment.apiUrl + 'organisation/venues/' + venueId + '/address';

    return this.httpClient.get<any>(urlString).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Address(response);
      })
    );
  }

  public updateCourseSearchStudentEnrolment(courseSearchUpdateRequest: CourseSearchUpdateRequest,
                                            unsubscribeSubject?: Subject<void>): Observable<CourseSearchUpdateResult> {
    return this.httpClient.post<any>(environment.apiUrl + 'organisation/course/' + courseSearchUpdateRequest.courseId + '/search/refresh',
      courseSearchUpdateRequest).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
        return new CourseSearchUpdateResult(response);
      })
    );
  }

  public getTermsAndConditions(unsubscribeSubject?: Subject<void>): Observable<PolicyResult> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/terms_and_conditions')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new PolicyResult(response);
      })
    );
  }

  public getPrivacyPolicy(unsubscribeSubject?: Subject<void>): Observable<PolicyResult> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/privacy_policy').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new PolicyResult(response);
      })
    );
  }

  public checkIfPersonExists(email: string,
                             unsubscribeSubject?: Subject<void>): Observable<AccountCheckResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/person/exists?email=' + email)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new AccountCheckResponse(response);
      })
    );
  }

  public getHowDidYouHearAboutUsTypes(unsubscribeSubject?: Subject<void>): Observable<Array<HowDidYouHearType>> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/how_did_you_hear_types')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.how_did_you_hear_types as any[];
          return array.map(data => new HowDidYouHearType(data));
        }
        return new Array<HowDidYouHearType>();
      })
    );
  }

  public getCountries(unsubscribeSubject?: Subject<void>): Observable<Array<PaymentCountry>> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/countries').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.payment_countries as any[];
          return array.map(data => new PaymentCountry(data));
        }
        return new Array<PaymentCountry>();
      })
    );
  }

  public createAccount(createAccountRequest: CreateAccountRequest,
                       unsubscribeSubject?: Subject<void>): Observable<CreateAccountResult> {
    return this.httpClient.post<any>(environment.apiUrl + 'organisation/account',
      createAccountRequest).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new CreateAccountResult(response);
      })
    );
  }

  public getLoggedInUserStudents(unsubscribeSubject?: Subject<void>): Observable<Array<Student>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/logged_in_user/students')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.students as any[];
          return array.map(data => new Student(data));
        }
        return new Array<Student>();
      })
    );
  }

  public getLoggedInUserContacts(unsubscribeSubject?: Subject<void>): Observable<Array<Person>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/logged_in_user/contacts')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.persons as any[];
          return array.map(data => new Person(data));
        }
        return new Array<Person>();
      })
    );
  }

  public updateStudent(createStudentRequest: CreateStudentRequest[],
                       unsubscribeSubject?: Subject<void>): Observable<Array<CreateStudentResponse>> {
    return this.createStudent(createStudentRequest, unsubscribeSubject);
  }

  public createStudent(createStudentRequest: CreateStudentRequest[],
                       unsubscribeSubject?: Subject<void>): Observable<Array<CreateStudentResponse>> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/student',
      createStudentRequest).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.create_student_responses as any[];
          return array.map(data => new CreateStudentResponse(data));
        }
        return new Array<CreateStudentResponse>();
      })
    );
  }

  public createAbandonedCart(createAbandonedCartRequest: AbandonedCartRequest[],
                             unsubscribeSubject?: Subject<void>) {
    return this.httpClient.post<any>(environment.apiUrl + 'user/abandoned_cart',
      createAbandonedCartRequest).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public findExistingAccount(licenseeId: string,
                             unsubscribeSubject?: Subject<void>): Observable<Array<Account>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/accounts?licenseeId=' + licenseeId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null && response._embedded.accounts != null) {
          const array = response._embedded.accounts as any[];
          return array.map(data => new Account(data));
        }
        return new Array<Account>();
      })
    );
  }

  public findCardsForAccount(accountId: string, licenseeId: string,
                             unsubscribeSubject?: Subject<void>): Observable<Array<Card>> {
    let url = environment.apiUrl + 'user/cards?accountId=' + accountId;

    if (licenseeId != null) {
      url += '&licenseeId=' + licenseeId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.cards as any[];
          return array.map(data => new Card(data));
        }
        return new Array<Card>();
      })
    );
  }

  public findMandatesForAccount(accountId: string,  unsubscribeSubject?: Subject<void>): Observable<Array<Mandate>> {
    let url = environment.apiUrl + 'user/account/' + accountId + '/mandate';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.mandates as any[];
          return array.map(data => new Mandate(data));
        }
        return new Array<Mandate>();
      })
    );
  }

  public findAddresses(addressType: string,
                       unsubscribeSubject?: Subject<void>): Observable<Array<Address>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/addresses?addressType=' + addressType)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.addresses as any[];
          return array.map(data => new Address(data));
        }
        return new Array<Address>();
      })
    );
  }

  public getPaymentGateways(paymentGatewayTypes: string[],
                            licenseeId: string,
                            accountId: string,
                            unsubscribeSubject?: Subject<void>): Observable<Array<PaymentGatewayResponse>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/payment_gateways?paymentGatewayTypes='
      + paymentGatewayTypes + '&licenseeId=' + licenseeId + '&accountId=' + accountId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.payment_gateway_responses as any[];
          return array.map(data => new PaymentGatewayResponse(data));
        }
        return new Array<PaymentGatewayResponse>();
      })
    );
  }

  public addCardToAccount(paymentMethod: PaymentMethod,
                          unsubscribeSubject?: Subject<void>): Observable<Card> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/card', paymentMethod)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Card(response);
      })
    );
  }

  public previewEnrolment(enrolmentRequest: NewEnrolmentRequest,
                          unsubscribeSubject?: Subject<void>): Observable<NewEnrolmentResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/preview_enrol', enrolmentRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new NewEnrolmentResponse(response);
      })
    );
  }

  public enrol(enrolmentRequest: NewEnrolmentRequest,
               unsubscribeSubject?: Subject<void>): Observable<NewEnrolmentResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrol', enrolmentRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new NewEnrolmentResponse(response);
      })
    );
  }

  public previewProvisionalEnrolment(enrolmentRequest: NewEnrolmentRequest,
                                     unsubscribeSubject?: Subject<void>): Observable<NewEnrolmentResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/preview_provisional_enrol', enrolmentRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new NewEnrolmentResponse(response);
      })
    );
  }

  public provisionalEnrol(enrolmentRequest: NewEnrolmentRequest,
                          unsubscribeSubject?: Subject<void>): Observable<NewEnrolmentResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/provisional_enrol', enrolmentRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new NewEnrolmentResponse(response);
      })
    );
  }

  public setupMandate(licenseeId: string,
                      accountId: string,
                      action: string,
                      extraParams: string,
                      unsubscribeSubject?: Subject<void>): Observable<string> {
    if (extraParams == null) {
      extraParams = '';
    }

    let url = environment.apiUrl + 'user/setup_mandate?accountId=' + accountId + '&action=' + action + '&extraParams=' + encodeURIComponent(extraParams);

    if (licenseeId != null) {
      url += '&licenseeId=' + licenseeId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response.url;
      })
    );
  }

  public createMandate(redirectId: string,
                       unsubscribeSubject?: Subject<void>): Observable<string> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/create_mandate?' +
      'redirectId=' + redirectId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response.mandate_id;
      })
    );
  }

  public getEnrolments(enrolmentAfterDate: Date, enrolmentCompletedBeforeDate: Date, studentId: string, programId: string,
                       unsubscribeSubject?: Subject<void>): Observable<Array<EnrolmentResponse>> {
    let url = environment.apiUrl + 'user/enrolments?';

    if (enrolmentAfterDate != null) {
      url += 'enrolmentAfterDate=' + enrolmentAfterDate.toISOString();
    }

    if (enrolmentCompletedBeforeDate != null) {
      url += '&enrolmentCompletedBeforeDate=' + enrolmentCompletedBeforeDate.toISOString();
    }

    if (studentId != null) {
      url += '&studentId=' + studentId;
    }

    if (programId != null) {
      url += '&programId=' + programId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.enrolment_responses as any[];
          return array.map(data => new EnrolmentResponse(data));
        }
        return new Array<EnrolmentResponse>();
      })
    );
  }

  public getAccountSummary(accountId?: string,
                           unsubscribeSubject?: Subject<void>): Observable<AccountSummary> {
    let url = environment.apiUrl + 'user/account_summary';

    if (accountId != null) {
      url += '?accountId=' + accountId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new AccountSummary(response);
      })
    );
  }

  public getWaitingListEntries(unsubscribeSubject?: Subject<void>): Observable<Array<WaitingListResult>> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/waiting_lists').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.waiting_list_results as any[];
          return array.map(data => new WaitingListResult(data));
        }
        return new Array<WaitingListResult>();
      })
    );
  }

  public removeWaitingList(waitingListId: string,
                           unsubscribeSubject?: Subject<void>) {
    return this.httpClient.get<any>(environment.apiUrl + 'user/waiting_lists/' + waitingListId + '/delete')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public removeWaitingListAvailability(waitingListId: string, scheduleId: string, termId?: string,
                                       unsubscribeSubject?: Subject<void>) {
    let url = environment.apiUrl + 'user/waiting_lists/' + waitingListId + '/schedule/' + scheduleId + '/delete';
    if (termId != null) {
      url += '?termId=' + termId;
    }
    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public getLatestLevelForStudent(studentId: string,
                                  unsubscribeSubject?: Subject<void>): Observable<ProgramLevel> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/student/' + studentId + '/program_level')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new ProgramLevel(response);
      })
    );
  }

  public getVenues(unsubscribeSubject?: Subject<void>): Observable<Array<Venue>> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/venues/').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.venues as any[];
          return array.map(data => new Venue(data));
        }
        return new Array<Venue>();
      })
    );
  }

  public getVenueTerms(venueId: string,
                       unsubscribeSubject?: Subject<void>): Observable<Array<Term>> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/venues/' + venueId + '/terms')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.terms as any[];
          return array.map(data => new Term(data));
        }
        return new Array<Term>();
      })
    );
  }

  public addWaitingList(waitingListRequest: WaitingListRequest,
                        unsubscribeSubject?: Subject<void>): Observable<string> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/waiting_list', waitingListRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response.waiting_list_id;
      })
    );
  }

  public removeWaitingListExpiry(waitingListExpiryId: string,
                                 unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/waiting_list_expiries/' + waitingListExpiryId + '/remove')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(() => {
          return null;
        })
      );
  }

  public keepWaitingListExpiry(waitingListExpiryId: string,
                               unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/waiting_list_expiries/' + waitingListExpiryId + '/keep')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(() => {
          return null;
        })
      );
  }

  public getStudentCreditPacks(accountId: string,
                               studentId: string,
                               courseId: string,
                               programLevelId: string,
                               unsubscribeSubject?: Subject<void>): Observable<Array<CreditPackResponse>> {
    let url = environment.apiUrl + 'user/student_credit_packs?';

    if (accountId != null) {
      url += '&accountId=' + accountId;
    }

    if (studentId != null) {
      url += '&studentId=' + studentId;
    }

    if (courseId != null) {
      url += '&courseId=' + courseId;
    }

    if (programLevelId != null) {
      url += '&programLevelId=' + programLevelId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.student_credit_pack_responses as any[];
          return array.map(data => new CreditPackResponse(data));
        }
        return new Array<CreditPackResponse>();
      })
    );
  }

  public getStudentCredits(accountId: string, studentId: string, types: string[],
                           unsubscribeSubject?: Subject<void>): Observable<Array<SportsCreditStudentResult>> {
    let url = environment.apiUrl + 'user/student_credits?';

    if (accountId != null) {
      url += '&accountId=' + accountId;
    }

    if (studentId != null) {
      url += '&studentId=' + studentId;
    }

    if (types != null) {
      url += '&type=' + types.join(',');
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.credit_student_results as any[];
          return array.map(data => new SportsCreditStudentResult(data));
        }
        return new Array<SportsCreditStudentResult>();
      })
    );
  }

  public getStudentCreditBookings(sportsCreditId: string,
                                  unsubscribeSubject?: Subject<void>): Observable<Array<Booking>> {
    const url = environment.apiUrl + 'user/student_credit_packs/' + sportsCreditId + '/bookings';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.bookings as any[];
          return array.map(data => new Booking(data));
        }
        return new Array<Booking>();
      })
    );
  }

  public getStudentProgression(studentId: string, fromDate: Date, numberOfFutureModules: number,
                               unsubscribeSubject?: Subject<void>): Observable<Array<StudentAchievement>> {
    let url = environment.apiUrl + 'user/student/' + studentId + '/progression?';

    if (fromDate != null) {
      url += 'fromDate=' + fromDate.toISOString();
    }

    if (numberOfFutureModules != null) {
      url += '&numberOfFutureModules=' + numberOfFutureModules;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        const studentItems = [];
        if (response._embedded != null) {
            const levelArray = response._embedded.level_achievements as any[];
            if (levelArray != null) {
              levelArray.map(data => new LevelAchievement(data)).forEach(element => studentItems.push(element));
            }

            const awardArray = response._embedded.award_achievements as any[];
            if (awardArray != null) {
              awardArray.map(data => new AwardAchievement(data)).forEach(element => studentItems.push(element));
            }

            const moduleArray = response._embedded.module_achievements as any[];
            if (moduleArray != null) {
              moduleArray.map(data => new ModuleAchievement(data)).forEach(element => studentItems.push(element));
            }

            const challengeArray = response._embedded.challenge_achievements as any[];
            if (challengeArray != null) {
              challengeArray.map(data => new ChallengeAchievement(data)).forEach(element => studentItems.push(element));
            }
        }
        return studentItems.sort((t1, t2) => {
          if (t1.dateAchieved > t2.dateAchieved) {
            return 1;
          }
          if (t1.dateAchieved < t2.dateAchieved) {
            return -1;
          }
          return 0;
        });
      })
    );
  }

  public getAccounts(licenseeId?: string,
                     unsubscribeSubject?: Subject<void>): Observable<Array<Account>> {
    let url = environment.apiUrl + 'user/accounts';

    if (licenseeId != null && licenseeId !== '') {
      url += '?licenseeId=' + licenseeId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null && response._embedded.accounts != null) {
          const array = response._embedded.accounts as any[];
          return array.map(data => new Account(data));
        }
        return new Array<Account>();
      })
    );
  }

  public getRecurringBilling(accountId: string,
                             paymentId: string,
                             unsubscribeSubject?: Subject<void>): Observable<Array<DirectDebitResponse>> {

    let url = environment.apiUrl + 'user/accounts/' + accountId + '/recurring_billing';

    if (paymentId != null && paymentId !== '') {
      url += '?paymentId=' + paymentId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.recurring_billing_responses as any[];
          return array.map(data => new DirectDebitResponse(data));
        }
        return new Array<DirectDebitResponse>();
      })
    );
  }

  public getPerson(personId: string,
                   unsubscribeSubject?: Subject<void>): Observable<Person> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/persons/' + personId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Person(response);
      })
    );
  }

  public getBillingHistory(accountId: string, from: Date, to: Date,
                           unsubscribeSubject?: Subject<void>): Observable<Array<AccountBillingItem>> {
    let url: string;

    if (accountId == null) {
      url = environment.apiUrl + 'user/billing_history?';
    } else {
      url = environment.apiUrl + 'user/accounts/' + accountId + '/billing_history?';
    }

    if (from != null) {
      url += '&from=' + from.toISOString();
    }

    if (to != null) {
      url += '&to=' + to.toISOString();
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.account_billing_history as any[];
          return array.map(data => new AccountBillingItem(data));
        }
        return new Array<AccountBillingItem>();
      })
    );
  }

  public getInvoicePdf(accountId: string, invoiceId: string,
                       unsubscribeSubject?: Subject<void>): Observable<Blob> {
    const url = environment.apiUrl + 'user/accounts/' + accountId + '/invoice/' + invoiceId + '/pdf';

    return this.httpClient.get(url, { responseType: 'blob' })
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response;
      })
    );
  }

  public getReceiptPdf(accountId: string, receiptId: string,
                       unsubscribeSubject?: Subject<void>): Observable<Blob> {
    const url = environment.apiUrl + 'user/accounts/' + accountId + '/receipt/' + receiptId + '/pdf';

    return this.httpClient.get(url, { responseType: 'blob' }).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response;
      })
    );
  }

  public updateDirectDebit(accountId: string,
                           directDebitCustomerDetails: DirectDebitCustomerDetails,
                           unsubscribeSubject?: Subject<void>): Observable<DirectDebitCustomerDetails> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/accounts/'
      + accountId + '/direct_debit/update', directDebitCustomerDetails).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new DirectDebitCustomerDetails(response);
      })
    );
  }

  public getDirectDebit(accountId: string,
                        unsubscribeSubject?: Subject<void>): Observable<DirectDebitCustomerDetails> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/accounts/'
      + accountId + '/direct_debit').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new DirectDebitCustomerDetails(response);
      })
    );
  }

  public getCard(cardId: string,
                 unsubscribeSubject?: Subject<void>): Observable<Card> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/cards/'
      + cardId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Card(response);
      })
    );
  }

  public updateCard(card: Card,
                    unsubscribeSubject?: Subject<void>): Observable<Card> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/cards/'
      + card.sportsAccountPaymentGatewayCardId + '/update', card).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Card(response);
      })
    );
  }

  public updateMandate(mandate: Mandate,
                    unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.put<any>(environment.apiUrl + 'user/mandate/' + mandate.sportsAccountPaymentGatewayMandateId, mandate)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return null;
        })
      );
  }

  public deleteCard(card: Card,
                    unsubscribeSubject?: Subject<void>) {
    return this.httpClient.get<any>(environment.apiUrl + 'user/cards/' + card.sportsAccountPaymentGatewayCardId + '/remove')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public updatePassword(currentPassword: string, newPassword: string,
                        unsubscribeSubject?: Subject<void>) {
    const updateRequest = new UpdatePasswordRequest();
    updateRequest.currentPassword = currentPassword;
    updateRequest.newPassword = newPassword;

    return this.httpClient.post<any>(environment.apiUrl + 'user/update_password', updateRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public updatePerson(personId: string, updatePersonRequest: UpdatePersonRequest,
                      unsubscribeSubject?: Subject<void>): Observable<Person> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/persons/' + personId + '/update', updatePersonRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Person(response);
      })
    );
  }

  public updateAccount(accountId: string, updateAccountRequest: UpdateAccountRequest,
                      unsubscribeSubject?: Subject<void>): Observable<Account> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/account/' + accountId + '/update', updateAccountRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new Account(response);
        })
      );
  }

  public getStudent(studentId: string,
                    unsubscribeSubject?: Subject<void>): Observable<Student> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/students/'
      + studentId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Student(response);
      })
    );
  }

  public getEnrolment(enrolmentId: string,
                      unsubscribeSubject?: Subject<void>): Observable<EnrolmentResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/enrolments/'
      + enrolmentId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new EnrolmentResponse(response);
      })
    );
  }

  public setBookingNotAttending(bookingId: string,
                                unsubscribeSubject?: Subject<void>): Observable<Booking> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/bookings/'
      + bookingId + '/not_attending').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Booking(response);
      })
    );
  }

  public getWaitingList(waitingListId: string,
                        unsubscribeSubject?: Subject<void>): Observable<WaitingListResult> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/waiting_lists/'
      + waitingListId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new WaitingListResult(response);
      })
    );
  }

  public addWaitingListSchedule(waitingListId: string,
                                waitingListRequestSchedule: WaitingListRequestSchedule,
                                unsubscribeSubject?: Subject<void>) {
    return this.httpClient.post<any>(environment.apiUrl + 'user/waiting_lists/'
      + waitingListId + '/schedule', waitingListRequestSchedule).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public getCredit(creditId: string,
                   unsubscribeSubject?: Subject<void>): Observable<SportsCreditStudentResult> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/credits/'
      + creditId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new SportsCreditStudentResult(response);
      })
    );
  }

  public getCourse(courseId: string,
                   unsubscribeSubject?: Subject<void>): Observable<Course> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/courses/'
      + courseId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Course(response);
      })
    );
  }

  public getClass(classId: string,
                  unsubscribeSubject?: Subject<void>): Observable<Class> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/classes/'
      + classId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Class(response);
      })
    );
  }

  public enrolInMakeup(classId: string, creditId: string,
                       unsubscribeSubject?: Subject<void>): Observable<Booking> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/credits/' + creditId + '/enrol/'
      + classId).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Booking(response);
      })
    );
  }

  public getWithdrawalReasons(enrolmentId: string,
                              unsubscribeSubject?: Subject<void>): Observable<Array<WithdrawalReason>> {
    const url = environment.apiUrl + 'user/enrolments/' + enrolmentId + '/withdrawal_reasons';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.withdrawal_reasons as any[];
          return array.map(data => new WithdrawalReason(data));
        }
        return new Array<WithdrawalReason>();
      })
    );
  }

  public withdrawProvisional(provisionalId: string, withdrawalReasonId: string, withdrawalReasonOther: string,
                             unsubscribeSubject?: Subject<void>) {
    let url = environment.apiUrl + 'user/enrolments/' + provisionalId + '/withdraw?withdrawalReasonId=' + withdrawalReasonId;
    if (withdrawalReasonOther != null) {
      url += '&withdrawalReasonOther=' + encodeURIComponent(withdrawalReasonOther);
    }
    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public previewTransfer(enrolmentId: string, courseId: string,
                         unsubscribeSubject?: Subject<void>): Observable<TransferResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/enrolments/' + enrolmentId + '/transfer/'
      + courseId + '/preview').pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new TransferResponse(response);
      })
    );
  }

  public transfer(enrolmentId: string, courseId: string, newEnrolmentRequest: NewEnrolmentRequest,
                  unsubscribeSubject?: Subject<void>): Observable<TransferResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolments/' + enrolmentId + '/transfer/'
      + courseId, newEnrolmentRequest).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new TransferResponse(response);
      })
    );
  }

  public getAvailableCourseClasses(courseId: string, studentId?: string,
                                   unsubscribeSubject?: Subject<void>): Observable<Array<Class>> {
    let url = environment.apiUrl + 'organisation/courses/' + courseId + '/classes_available';

    if (studentId != null && studentId !== '') {
      url += '?studentId=' + studentId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.classes as any[];
          return array.map(data => new Class(data));
        }
        return new Array<Class>();
      })
    );
  }

  public getCourseCreditPacks(courseId: string, programLevelId?: string, studentId?: string,
                              unsubscribeSubject?: Subject<void>): Observable<Array<CreditPackResult>> {
    let url = environment.apiUrl + 'organisation/courses/' + courseId + '/credit_packs?';

    if (programLevelId != null && programLevelId !== '') {
      url += 'programLevelId=' + programLevelId;
    }

    if (studentId != null && studentId !== '') {
      url += '&studentId=' + studentId;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.credit_pack_results as any[];
          return array.map(data => new CreditPackResult(data));
        }
        return new Array<CreditPackResult>();
      })
    );
  }

  public purchaseCreditPack(creditPackPurchaseRequest: CreditPackPurchaseRequest,
                            unsubscribeSubject?: Subject<void>): Observable<CreditPackPurchaseResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/students/' + creditPackPurchaseRequest.studentId + '/credit_packs/'
      + creditPackPurchaseRequest.creditPackId + '/purchase', creditPackPurchaseRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new CreditPackPurchaseResponse(response);
      })
    );
  }

  public previewPurchaseCreditPack(creditPackPurchaseRequest: CreditPackPurchaseRequest,
                                   unsubscribeSubject?: Subject<void>): Observable<CreditPackPurchaseResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/students/' + creditPackPurchaseRequest.studentId + '/credit_packs/'
      + creditPackPurchaseRequest.creditPackId + '/preview', creditPackPurchaseRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new CreditPackPurchaseResponse(response);
      })
    );
  }

  public enrolStudentInFlexibleCourse(flexibleCourseEnrolmentRequest: FlexibleCourseEnrolmentRequest,
                                      unsubscribeSubject?: Subject<void>): Observable<EnrolmentResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/flexible_enrol', flexibleCourseEnrolmentRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new EnrolmentResponse(response);
      })
    );
  }

  public requestAccess(email: string, telephone: string,
                       unsubscribeSubject?: Subject<void>): Observable<RequestAccessResponse> {
    let url = environment.apiUrl + 'organisation/request_access?';

    if (email != null) {
      url += '&email=' + email;
    }

    if (telephone != null) {
      url += '&telephone=' + telephone;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new RequestAccessResponse(response);
      })
    );
  }

  public activateAccount(activationCode: string, email: string, password: string,
                         unsubscribeSubject?: Subject<void>): Observable<RequestAccessResponse> {
    let url = environment.apiUrl + 'organisation/activate_account/' + activationCode + '?password=' + password;

    if (email != null) {
      url += '&email=' + email;
    }

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new RequestAccessResponse(response);
      })
    );
  }

  public resetPassword(username: string,
                       unsubscribeSubject?: Subject<void>): Observable<RequestAccessResponse> {
    const url = environment.apiUrl + 'organisation/reset_password?username=' + username;

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new RequestAccessResponse(response);
      })
    );
  }

  public masterLicenseeSettings(unsubscribeSubject?: Subject<void>): Observable<MasterLicenseeSettings> {
    const url = environment.apiUrl + 'master_licensee_settings';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new MasterLicenseeSettings(response);
      }), catchError(this.handleError)
    );
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error.message);
  }
  public previewUpgradeToMultipleTerms(upgradeToMultipleTermsRequest: UpgradeToMultipleTermsRequest,
                                       unsubscribeSubject?: Subject<void>):
    Observable<UpgradeToMultipleTermsResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolments/' +
      upgradeToMultipleTermsRequest.enrolmentId + '/upgrade_to_multiple_terms/preview', upgradeToMultipleTermsRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new UpgradeToMultipleTermsResponse(response);
      })
    );
  }

  public upgradeToMultipleTerms(upgradeToMultipleTermsRequest: UpgradeToMultipleTermsRequest,
                                unsubscribeSubject?: Subject<void>):
    Observable<UpgradeToMultipleTermsResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolments/' +
      upgradeToMultipleTermsRequest.enrolmentId + '/upgrade_to_multiple_terms', upgradeToMultipleTermsRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new UpgradeToMultipleTermsResponse(response);
      })
    );
  }

  public previewPreSaleUpgrade(preSaleUpgradeRequest: PreSaleUpgradeRequest,
                               unsubscribeSubject?: Subject<void>):
    Observable<PreSaleUpgradeResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolments/' +
      preSaleUpgradeRequest.enrolmentId + '/pre_sale_upgrade/preview', preSaleUpgradeRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new PreSaleUpgradeResponse(response);
      })
    );
  }

  public preSaleUpgrade(preSaleUpgradeRequest: PreSaleUpgradeRequest,
                        unsubscribeSubject?: Subject<void>):
    Observable<PreSaleUpgradeResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolments/' +
      preSaleUpgradeRequest.enrolmentId + '/pre_sale_upgrade', preSaleUpgradeRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new PreSaleUpgradeResponse(response);
      })
    );
  }

  public addStudentMembership(studentId: string, membershipTypeId: string, membershipCode: string,
                              unsubscribeSubject?: Subject<void>): Observable<Membership> {
    const url = environment.apiUrl + 'user/students/' + studentId + '/memberships/' + membershipTypeId + '/add?membershipCode=' + membershipCode;

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new Membership(response);
      })
    );
  }

  public removeStudentMembership(studentId: string, membershipId: string,
                                 unsubscribeSubject?: Subject<void>) {
    const url = environment.apiUrl + 'user/students/' + studentId + '/memberships/' + membershipId + '/remove';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {})
    );
  }

  public getStudentMemberships(studentId: string,
                               unsubscribeSubject?: Subject<void>): Observable<Array<Membership>> {
    const url = environment.apiUrl + 'user/students/' + studentId + '/memberships';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.memberships as any[];
          return array.map(data => new Membership(data));
        }
        return new Array<Membership>();
      })
    );
  }

  public getVenueMembershipTypes(venueId: string,
                                 unsubscribeSubject?: Subject<void>): Observable<Array<MembershipType>> {
    const url = environment.apiUrl + 'organisation/venues/' + venueId + '/membership_types';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.membership_types as any[];
          return array.map(data => new MembershipType(data));
        }
        return new Array<MembershipType>();
      })
    );
  }

  public validateVoucherCode(licenseeId: string, voucherCode: string,
                             unsubscribeSubject?: Subject<void>): Observable<VoucherCodeValidationResponse> {
    const url = environment.apiUrl + 'user/licensees/' + licenseeId + '/vouchers/' + voucherCode + '/validate';
    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new VoucherCodeValidationResponse(response);
      })
    );
  }

  public getConfirmationBlocks(unsubscribeSubject?: Subject<void>): Observable<Array<ConfirmationBlock>> {
    const url = environment.apiUrl + 'organisation/confirmation_links';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.confirmation_blocks as any[];
          return array.map(data => new ConfirmationBlock(data));
        }
        return new Array<ConfirmationBlock>();
      })
    );
  }

  public getEnrolmentTypeDescription(paymentTypeCode: string,
                                     unsubscribeSubject?: Subject<void>): Observable<Array<OnlineBookingsPreferencesPaymentTypeDescription>> {
    const url = environment.apiUrl + 'organisation/payment_type/' + paymentTypeCode + '/description';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.sports_online_bookings_preferences_payment_type_descriptions as any[];
          return array.map(data => new OnlineBookingsPreferencesPaymentTypeDescription(data));
        }
        return new Array<OnlineBookingsPreferencesPaymentTypeDescription>();
      })
    );
  }

  public getPendingPayments(accountId: string,
                            unsubscribeSubject?: Subject<void>): Observable<Array<PendingPayment>> {
    const url = environment.apiUrl + 'user/accounts/' + accountId + '/pending_payments';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.pending_payments as any[];
          return array.map(data => new PendingPayment(data));
        }
        return new Array<PendingPayment>();
      })
    );
  }

  public autoGeneratePendingPayments(accountId: string,
                                     unsubscribeSubject?: Subject<void>): Observable<boolean> {
    const url = environment.apiUrl + 'user/accounts/' + accountId + '/generate_pending_payments';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return response.pending_payments_generated;
      })
    );
  }

  public getPendingPaymentsForLoggedInUser(unsubscribeSubject?: Subject<void>): Observable<Array<PendingPayment>> {
    const url = environment.apiUrl + 'user/pending_payments';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.pending_payments as any[];
          return array.map(data => new PendingPayment(data));
        }
        return new Array<PendingPayment>();
      })
    );
  }

  public getPendingPayment(pendingPaymentId: string,
                           unsubscribeSubject?: Subject<void>): Observable<PendingPayment> {
    const url = environment.apiUrl + 'user/pending_payments/' + pendingPaymentId;
    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        return new PendingPayment(response);
      })
    );
  }

  public deletePendingPayment(pendingPaymentId: string, withdrawalReasonId: string, withdrawalReasonOther: string,
                           unsubscribeSubject?: Subject<void>): Observable<null> {
    let url = environment.apiUrl + 'user/pending_payments/' + pendingPaymentId + '?withdrawalReasonId=' + withdrawalReasonId;

    if (withdrawalReasonOther != null) {
      url += '&withdrawalReasonOther=' + encodeURIComponent(withdrawalReasonOther);
    }

    return this.httpClient.delete<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {
        return null;
      })
    );
  }

  public previewSettlePendingPayment(pendingPaymentSettlementRequest: PendingPaymentSettlementRequest,
                                     unsubscribeSubject?: Subject<void>): Observable<PendingPaymentSettlementResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/pending_payments/'
      + pendingPaymentSettlementRequest.pendingPaymentId + '/settle/preview', pendingPaymentSettlementRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new PendingPaymentSettlementResponse(response);
        })
      );
  }

  public settlePendingPayment(pendingPaymentSettlementRequest: PendingPaymentSettlementRequest,
                              unsubscribeSubject?: Subject<void>): Observable<PendingPaymentSettlementResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/pending_payments/'
      + pendingPaymentSettlementRequest.pendingPaymentId + '/settle', pendingPaymentSettlementRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new PendingPaymentSettlementResponse(response);
        })
      );
  }

  public getAccount(sportsAccountId: string,
                    unsubscribeSubject?: Subject<void>): Observable<Account> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/accounts/' + sportsAccountId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new Account(response);
        })
      );
  }

  public getAbandonedCartsForLoggedInUser(statuses: string[],
                                          unsubscribeSubject?: Subject<void>): Observable<Array<AbandonedCartResponse>> {
    const url = environment.apiUrl + 'user/abandoned_carts?statuses=' + statuses.toString();

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.abandoned_cart_responses as any[];
          return array.map(data => new AbandonedCartResponse(data));
        }
        return new Array<AbandonedCartResponse>();
      })
    );
  }

  public removeAbandonedCart(abandonedCartId: string,
                             unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/abandoned_carts/' + abandonedCartId + '/cancel')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(() => {
          return null;
        })
      );
  }

  public getCourseReferral(referralCode: string,
                           unsubscribeSubject?: Subject<void>): Observable<CourseReferral> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/course_referrals/' + referralCode)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new CourseReferral(response);
        })
      );
  }

  public isCourseAvailableForDateOfBirth(courseId: string,
                                         dateOfBirth: Date,
                                         unsubscribeSubject?: Subject<void>): Observable<boolean> {
    return this.httpClient.get<any>(environment.apiUrl +
      'organisation/course/' + courseId + '/is_available_for_date_of_birth?dateOfBirth=' + dateOfBirth.toISOString())
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return response;
        })
      );
  }

  public getAchievedAwardCertificateDescription(achievedAwardId: string,
                                                unsubscribeSubject?: Subject<void>): Observable<AchievedAwardCertificateDescription> {
    return this.httpClient.get<any>(environment.apiUrl + 'images/achieved_award/' + achievedAwardId + '/description')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new AchievedAwardCertificateDescription(response);
        })
      );
  }

  public updateSubscriptionPreferences(personId: string, subscriptionRequest: SubscriptionRequest,
                                       unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.put<any>(environment.apiUrl + 'subscription/' + personId, subscriptionRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return null;
        })
      );
  }

  public getBookingSurvey(bookingSurveyId: string,
                          unsubscribeSubject?: Subject<void>): Observable<BookingSurveyResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'survey/booking/' + bookingSurveyId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new BookingSurveyResponse(response);
        })
      );
  }

  public getSurvey(surveyId: string,
                   unsubscribeSubject?: Subject<void>): Observable<SurveyResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'survey/' + surveyId)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new SurveyResponse(response);
        })
      );
  }

  public updateSurvey(surveyRequest: SurveyRequest,
                      unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.put<any>(environment.apiUrl + 'survey/' + surveyRequest.id, surveyRequest)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return null;
        })
      );
  }

  public getVenuePolicies(venueId: string,
                          unsubscribeSubject?: Subject<void>): Observable<Array<PolicyResult>> {
    const url = environment.apiUrl + 'organisation/venue/' + venueId + '/policies';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.policy_results as any[];
          return array.map(data => new PolicyResult(data));
        }
        return new Array<PolicyResult>();
      })
    );
  }

  public canTransferEnrolment(enrolmentId: string,
                              unsubscribeSubject?: Subject<void>): Observable<boolean> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/enrolment/' + enrolmentId + '/can_transfer')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return response;
        })
      );
  }

  public canCancelEnrolment(enrolmentId: string,
                              unsubscribeSubject?: Subject<void>): Observable<boolean> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/enrolment/' + enrolmentId + '/can_cancel')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return response;
        })
      );
  }

  public registerPageView(request: string): Observable<null> {
    const formData = new FormData();
    formData.set('requestUrl', request);
    formData.set('contextPath', this.href);
    return this.httpClient.post<any>(environment.apiUrl + 'user/request', formData)
      .pipe( map(() => {
          return null;
        })
      );
  }

  public convertProspectAccount(accountId: string, createAccountRequest: CreateAccountRequest): Observable<null> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/account/' + accountId + '/convert', createAccountRequest)
      .pipe( map(() => {
          return null;
        })
      );
  }

  public getVenueSearchDistances(unsubscribeSubject?: Subject<void>): Observable<Array<VenueSearchDistance>> {
    const url = environment.apiUrl + 'organisation/venue_search_distances';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.sports_venue_search_distances as any[];
          return array.map(data => new VenueSearchDistance(data));
        }
        return new Array<VenueSearchDistance>();
      })
    );
  }

  public getAbandonedCart(abandonedCartId: string, registerOpen: boolean,
                          unsubscribeSubject?: Subject<void>): Observable<AbandonedCartResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/abandoned_cart/' + abandonedCartId + '?registerOpen=' + registerOpen)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new AbandonedCartResponse(response);
        })
      );
  }

  setUnsubscribeIfNull(unSubRequest: Subject<void>): Subject<void> {
    if (unSubRequest == null) {
      return this.ngUnsubscribe;
    }

    return unSubRequest;
  }

  public updateEnrolmentSubscriptionPreferences(enrolmentRenewalId: string, subscribed: boolean, unsubscribeSubject?: Subject<void>): Observable<null> {
    return this.httpClient.put<any>(environment.apiUrl + 'organisation/renewal/' + enrolmentRenewalId + '/subscription?subscribed=' + subscribed, null)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)), map(() => {
          return null;
        })
      );
  }

  public getPendingPaymentRequiredAction(pendingPaymentId: string,
                              unsubscribeSubject?: Subject<void>): Observable<PendingPaymentActionResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'organisation/pending_payments/'
      + pendingPaymentId + '/confirm')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new PendingPaymentActionResponse(response);
        })
      );
  }

  public getAutoEnrolmentSettings(licenseeId: string,
                                         accountId: string,
                                         enrolmentTypes: string[],
                                         unsubscribeSubject?: Subject<void>): Observable<AutoEnrolmentResponse> {
    const accountIdUrl = [];
    if (accountId != null) {
      accountIdUrl.push('accountId=' + accountId);
    }
    if (enrolmentTypes != null) {
      enrolmentTypes.forEach(enrolmentType => {
        if (enrolmentType === 'MONTHLY_DIRECT_DEBIT') {
          accountIdUrl.push('enrolmentTypes=MONTHLY');
        } else {
          accountIdUrl.push('enrolmentTypes=' + enrolmentType);
        }
      });
    }
    return this.httpClient.get<any>(environment.apiUrl + 'user/licensee/'
      + licenseeId + '/auto_enrolment_preferences?' + accountIdUrl.join('&'))
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new AutoEnrolmentResponse(response);
        })
      );
  }

  public updateRecurringBillingPaymentMethod(recurringBillingId: string, paymentMethodType: string, paymentMethodId: string,
                                       unsubscribeSubject?: Subject<void>): Observable<SubscriptionUpdateResponse> {
    return this.httpClient.post<any>(environment.apiUrl + 'user/recurring_billing_service/' + recurringBillingId + '/payment_method/' + paymentMethodType + '/' + paymentMethodId, null)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new SubscriptionUpdateResponse(response);
        })
      );
  }

  public getMandates(accountId: string,
                                          unsubscribeSubject?: Subject<void>): Observable<Array<Mandate>> {
    const url = environment.apiUrl + 'user/account/' + accountId + '/mandate';

    return this.httpClient.get<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(response => {
        if (response._embedded != null) {
          const array = response._embedded.mandates as any[];
          return array.map(data => new Mandate(data));
        }
        return new Array<Mandate>();
      })
    );
  }

  public cancelMandate(mandateId: string, unsubscribeSubject?: Subject<void>): Observable<null> {
    let url = environment.apiUrl + 'user/mandate/' + mandateId;

    return this.httpClient.delete<any>(url).pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
      map(() => {
        return null;
      })
    );
  }

  public previewSubscriptionWithdrawal(enrolmentId: string,
                                  unsubscribeSubject?: Subject<void>): Observable<SubscriptionCancellationResponse> {
    return this.httpClient.get<any>(environment.apiUrl + 'user/enrolment/'
      + enrolmentId + '/withdraw/preview')
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new SubscriptionCancellationResponse(response);
        })
      );
  }

  public subscriptionWithdrawal(enrolmentId: string,
                                withdrawalReasonId: string,
                                withdrawalReasonOther: string,
                                periodEndDate: Date,
                                unsubscribeSubject?: Subject<void>): Observable<SubscriptionCancellationResponse> {
    const formData = new FormData();
    formData.set('withdrawalReasonId', withdrawalReasonId);
    formData.set('withdrawalReasonOther', withdrawalReasonOther);
    formData.set('periodEndDate', periodEndDate.toISOString());

    return this.httpClient.post<any>(environment.apiUrl + 'user/enrolment/'
      + enrolmentId + '/withdraw', formData)
      .pipe( takeUntil(this.setUnsubscribeIfNull(unsubscribeSubject)),
        map(response => {
          return new SubscriptionCancellationResponse(response);
        })
      );
  }

  public cancelAllRequests(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }
}
