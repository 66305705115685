import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {Student} from '../../_model/student';
import {WaitingListRequest} from '../../_model/waiting-list-request';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Program} from '../../_model/program';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {Venue} from '../../_model/venue';
import {Term} from '../../_model/term';
import {WaitingListRequestSchedule} from '../../_model/waiting-list-request-schedule';
import {DashboardService} from '../dashboard.service';
import {ProgramLevel} from '../../_model/program-level';
import {DashboardAddStudentService} from '../dashboard-add-student/dashboard-add-student.service';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {InteractiveSkillCheckService} from '../../course-search/interactive-skill-check/interactive-skill-check.service';
import {dateRangeOverlaps} from '../../_helpers/date_helper';
import {AlertService} from '../../alert';
import {AccountContact} from '../../_model/account-contact';
import {UserService} from '../../user-service';
import {Subject, Subscription} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-waiting-list-request',
  templateUrl: './dashboard-waiting-list-request.component.html',
  styleUrls: ['./dashboard-waiting-list-request.component.css']
})
export class DashboardWaitingListRequestComponent implements OnInit, OnDestroy {

  studentsForm: UntypedFormGroup;
  programsForm: UntypedFormGroup;
  enrolmentForm: UntypedFormGroup;
  schedulesForm: UntypedFormGroup;
  programLevelsForm: UntypedFormGroup;
  preferencesForm: UntypedFormGroup;
  submitted = false;
  submitting = false;

  accountContacts: AccountContact[];
  programs: Program[];
  programLevels: ProgramLevel[];
  enrolments: EnrolmentResponse[];

  stepNumber = 1;
  waitingListRequest = new WaitingListRequest();

  venues: Venue[];
  terms: Term[];
  timesFrom: string[];
  timesTo: string[];
  venueName: string;
  termName: string;
  dayName: string;

  student: Student;

  showLevelCheckerLink: boolean;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  newStudentIdSubscription: Subscription;
  programLevelSubscription: Subscription;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private userService: UserService,
              private router: Router,
              private dashboardAddStudentService: DashboardAddStudentService,
              private dashboardService: DashboardService,
              private interactiveSkillCheckService: InteractiveSkillCheckService,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService) {}

  setStepNumber(stepNumber: number) {
    this.stepNumber = stepNumber;
    this.removeBodyClasses();
    this.setBodyClasses();
  }

  setBodyClasses() {
    if (this.stepNumber <= 2) {
      this.renderer.addClass(document.body, 'dashboard-booking-request');
    } else {
      this.renderer.addClass(document.body, 'dashboard-booking-request-transfer');
    }
  }

  removeBodyClasses() {
    this.renderer.removeClass(document.body, 'dashboard-booking-request');
    this.renderer.removeClass(document.body, 'dashboard-booking-request-transfer');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Waiting List Request');

    this.waitingListRequest.schedules = [];
    this.waitingListRequest.dateRequired = new Date();

    this.studentsForm = this.formBuilder.group({
      waitingListStudent: ['', Validators.required]
    });

    this.programsForm = this.formBuilder.group({
      waitingListProgram: ['', Validators.required]
    });

    this.programLevelsForm = this.formBuilder.group( {
      waitingListProgramLevel: ['', Validators.required]
    });

    this.enrolmentForm = this.formBuilder.group( {
      currentCourseInput: ['', Validators.required]
    });

    this.preferencesForm = this.formBuilder.group( {
      dateSelect: ['', Validators.required],
      numberOfStudentsPerInstructorSelect: ['', Validators.required],
      classDurationInMinutesSelect: ['', Validators.required],
      instructorGenderSelect: ['', Validators.required]
    });

    this.schedulesForm = this.formBuilder.group({
      venueSelect: ['', Validators.required],
      termSelect: ['', Validators.required],
      daySelect: ['', Validators.required],
      fromTimeSelect: ['', Validators.required],
      toTimeSelect: ['', Validators.required]
    });

    this.loadStudents();

    const epochTime = moment().set({
      hour: 6,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    this.timesFrom = [];
    this.timesTo = [];
    while (this.timesFrom.length < 68) {
      this.timesFrom.push(epochTime.toISOString(true));
      epochTime.add(15, 'minute');
      this.timesTo.push(epochTime.toISOString(true));
    }

    this.newStudentIdSubscription = this.dashboardAddStudentService.currentNewStudentId.subscribe(studentId => {
      if (studentId != null) {
        this.waitingListRequest.studentId = studentId;
        this.apiService.getStudent(studentId, this.ngUnsubscribe).subscribe(student => {
          this.student = student;
          this.runStep2();
        }, () => {
          this.stepNumber = 1;
        });
      }
    });

    this.programLevelSubscription = this.interactiveSkillCheckService.currentProgramLevel.subscribe(programLevel => {
      if (programLevel != null) {
        this.waitingListRequest.levelId = programLevel.id;
        this.runStep4();
      }
    });

    this.preferencesForm.get('dateSelect').setValue(moment().add(1, 'd').format('YYYY-MM-DD'));

    this.renderer.addClass(document.body, 'dashboard-action');
    this.setBodyClasses();
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.newStudentIdSubscription != null) {
      this.newStudentIdSubscription.unsubscribe();
    }
    if (this.programLevelSubscription != null) {
      this.programLevelSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-action');
    this.removeBodyClasses();
  }

  loadStudents(): void {
    this.accountContacts = [];
    this.userService.getLoggedInStudentAccountContacts().subscribe(students => {
      this.accountContacts = students;

      this.accountContacts.forEach(student => {
        this.apiService.getLatestLevelForStudent(student.id, this.ngUnsubscribe).subscribe(level => {
          if (level != null) {
            student.programLevelId = level.id;
            student.hasLevelImage = level.hasLevelImage;
          }
        });
      });

      this.apiService.getLoggedInUserContacts(this.ngUnsubscribe).subscribe(contacts => {
        for (const contact of contacts) {
          this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
            contact.familyName, contact.gender, contact.dob, contact.personId, 'contact');
        }
      }, () => {}, () => {
        // Finally, add the logged in user
        this.authService.getLoggedInUser().subscribe(loggedInUser => {
          let type = 'self';

          if (loggedInUser.isStudent) {
            type = 'student';
          }

          if (!loggedInUser.isStudent) {
            this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(contact => {
              this.addContactIfNotFound(contact.personId, contact.title, contact.givenName,
                contact.familyName, contact.gender, contact.dob, contact.personId, type);
              this.sortContacts();
            });
          } else {
            this.sortContacts();
          }
        });
      });
    });
  }

  getLevelImageUrl(levelId: string): string {
    return environment.apiUrl + 'images/program_levels/' + levelId + '/image';
  }

  onStudentsSubmit() {
    this.submitted = true;
    if (this.studentsForm.invalid) {
      return;
    }
    this.submitted = false;

    this.waitingListRequest.studentId = this.studentsForm.get('waitingListStudent').value;

    if (this.waitingListRequest.studentId === 'new') {
      this.setStepNumber(7);
    } else {
      // Need to find the student to wrok out what to do next
      this.accountContacts.forEach(contact => {
        if (contact.id === this.waitingListRequest.studentId) {
          if (contact.type === 'student') {
            this.stepNumber = 99;
            this.apiService.getStudent(contact.id, this.ngUnsubscribe).subscribe(student => {
              this.student = student;
              this.runStep2();
            }, () => {
              this.stepNumber = 1;
            });
          } else {
            this.stepNumber = 7;
            this.dashboardAddStudentService.setAccountContact(contact);
            return;
          }
        }
      });
    }
  }

  runStep2() {
    this.setStepNumber(2);
    this.apiService.getPrograms(null, this.waitingListRequest.studentId, this.ngUnsubscribe).subscribe((data) => {
      this.programs = data;

      if (this.programs.length === 1) {
        this.programsForm.get('waitingListProgram').setValue(this.programs[0].id);
      }
    });
  }

  programSelected(program: Program) {
    this.showLevelCheckerLink = program.hasLevelFinder;
  }

  onProgramsSubmit() {
    this.submitted = true;

    if (this.programsForm.invalid) {
      return;
    }

    this.submitted = false;
    this.waitingListRequest.programId = this.programsForm.get('waitingListProgram').value;

    for (const studentLevel of this.student.levels) {
      if (studentLevel.program.id === this.waitingListRequest.programId && studentLevel.level != null) {
        // We have a level, skip this step
        this.runStep4();
        return;
      }
    }

    this.setStepNumber(3);
    this.apiService.getProgramLevels(this.waitingListRequest.programId, this.ngUnsubscribe)
      .subscribe(levels => {
        this.programLevels = levels;
        if (this.programLevels.length === 1) {
          this.programLevelsForm.get('waitingListProgramLevel').setValue(this.programLevels[0].id);
        }
      });
  }

  onProgramLevelsSubmit() {
    this.submitted = true;

    if (this.programLevelsForm.invalid) {
      return;
    }

    this.submitted = false;
    this.waitingListRequest.levelId = this.programLevelsForm.get('waitingListProgramLevel').value;

    this.runStep4();
  }

  runStep4() {
    const currentDate = new Date();
    this.setStepNumber(4);
    this.apiService.getEnrolments(currentDate,
      null,
      this.waitingListRequest.studentId,
      this.waitingListRequest.programId, this.ngUnsubscribe).subscribe(enrolments => {
      this.enrolments = enrolments;

      if (this.enrolments.length === 0) {
        this.setStepNumber(5);
      }
    });
  }

  onPreferencesSubmit() {
    this.submitted = true;

    if (this.preferencesForm.invalid) {
      return;
    }

    this.submitted = false;

    this.waitingListRequest.dateRequired = this.preferencesForm.get('dateSelect').value;
    this.waitingListRequest.numberOfStudentsPerInstructor = this.preferencesForm.get('numberOfStudentsPerInstructorSelect').value;
    this.waitingListRequest.classDurationInMinutes = this.preferencesForm.get('classDurationInMinutesSelect').value;
    this.waitingListRequest.instructorGender = this.preferencesForm.get('instructorGenderSelect').value;

    this.apiService.getVenues(this.ngUnsubscribe).subscribe(venues => {
      this.venues = venues;
    });

    if (typeof this.waitingListRequest.classDurationInMinutes === 'string') {
      this.waitingListRequest.classDurationInMinutes = parseInt(this.waitingListRequest.classDurationInMinutes, 10);
    }

    if (typeof this.waitingListRequest.numberOfStudentsPerInstructor === 'string') {
      this.waitingListRequest.numberOfStudentsPerInstructor = parseInt(this.waitingListRequest.numberOfStudentsPerInstructor, 10);
    }

    // Temporarily clear screen
    this.setStepNumber(60);
    this.apiService.getWaitingListEntries(this.ngUnsubscribe).subscribe(entries => {
      for (const entry of entries) {
        if (entry.waitingListEntry.classDurationInMinutes === this.waitingListRequest.classDurationInMinutes
          && entry.waitingListEntry.status === 'OPEN'
          && entry.waitingListEntry.instructorGender === this.waitingListRequest.instructorGender
          && entry.waitingListEntry.program.id === this.waitingListRequest.programId
          && entry.waitingListEntry.student.id === this.waitingListRequest.studentId
          && entry.waitingListEntry.numberOfStudentsPerInstructor === this.waitingListRequest.numberOfStudentsPerInstructor
          && ((entry.waitingListEntry.enrolment == null && this.waitingListRequest.existingEnrolmentId == null)
            || (entry.waitingListEntry.enrolment != null && this.waitingListRequest.existingEnrolmentId != null
            && this.waitingListRequest.existingEnrolmentId === entry.waitingListEntry.enrolment.enrolmentId))) {
          this.router.navigate(['/dashboard_waiting_list_amend', entry.waitingListEntry.id]);
          return;
        }
      }
    });
    this.setStepNumber(6);
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  transferEnrolmentSubmit(): void {
    this.venues = [];
    this.terms = [];

    this.waitingListRequest.existingEnrolmentId = this.enrolmentForm.get('currentCourseInput').value;

    this.setStepNumber(5);
  }

  venueChange(venueId: string, venueName: string) {
    this.terms = [];
    this.venueName = venueName;

    this.apiService.getVenueTerms(venueId, this.ngUnsubscribe).subscribe(terms => {
      this.terms = terms;
    });
  }

  termChange(termName: string) {
    this.termName = termName;
  }

  dayChange(dayName: string) {
    this.dayName = dayName;
  }

  removeWaitingListAvailability(schedule: WaitingListRequestSchedule) {
    this.waitingListRequest.schedules.splice(this.waitingListRequest.schedules.indexOf(schedule), 1);
  }

  onSchedulesSubmit() {
    if (this.schedulesForm.invalid) {
      return;
    }

    this.alertService.clear();

    const fromTime = new Date(this.schedulesForm.get('fromTimeSelect').value);
    const toTime = new Date(this.schedulesForm.get('toTimeSelect').value);

    const termId = this.schedulesForm.get('termSelect').value;
    const venueId = this.schedulesForm.get('venueSelect').value;
    const dayNumber = parseInt(this.schedulesForm.get('daySelect').value, 10);

    if (toTime <= fromTime) {
      return;
    }

    // Need to check whether it is overlapping with another schedule
    for (const wlSchedule of this.waitingListRequest.schedules) {
      if (wlSchedule.termId === termId
        && wlSchedule.venueId === venueId
        && wlSchedule.dayNumber === dayNumber) {
        if (dateRangeOverlaps(fromTime, toTime, wlSchedule.fromTime, wlSchedule.toTime)) {
          this.alertService.error('Availability overlaps with an existing schedule');
          return;
        }
      }
    }

    const schedule = new WaitingListRequestSchedule();
    schedule.venueId = venueId;
    schedule.venueName = this.venueName;
    schedule.termId = termId;
    schedule.termName = this.termName;
    schedule.fromTime = fromTime;
    schedule.toTime = toTime;
    schedule.dayNumber = dayNumber;
    schedule.dayName = this.dayName;

    this.waitingListRequest.schedules.push(schedule);

    this.schedulesForm.get('fromTimeSelect').setValue(null);
    this.schedulesForm.get('toTimeSelect').setValue(null);
    this.schedulesForm.get('termSelect').setValue(null);
    this.schedulesForm.get('venueSelect').setValue(null);
    this.schedulesForm.get('daySelect').setValue(null);

    this.venueName = null;
    this.termName = null;
    this.dayName = null;
  }

  submitWaitingList() {
    this.submitted = true;
    if (this.submitting || this.waitingListRequest.schedules.length === 0) {
      return;
    }

    this.submitted = false;

    this.submitting = true;
    this.apiService.addWaitingList(this.waitingListRequest).subscribe(() => {
      this.router.navigate(['/dashboard_waiting_list']);
      this.dashboardService.setNewConfirmationMessage('Waiting list entry successfully created');
      this.submitting = false;
    },
      () => {
        this.submitting = false;
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  onRunLevelChecker(): void {
    this.interactiveSkillCheckService.setProgramId(this.waitingListRequest.programId);
    this.stepNumber = 8;
  }

  addContactIfNotFound(id: string, title: string, givenName: string,
                       familyName: string, gender: string, dateOfBirth: Date, entityId: string, type: string) {
    // Check to see if they are already a student
    let found = false;
    for (const accountContact of this.accountContacts) {
      if (entityId === accountContact.entityId) {
        found = true;
        break;
      }
    }

    if (!found) {
      const accountContact = new AccountContact();
      accountContact.type = type;
      accountContact.id = id;
      accountContact.givenName = givenName;
      accountContact.familyName = familyName;
      accountContact.entityId = entityId;
      accountContact.title = title;
      accountContact.gender = gender;
      accountContact.dateOfBirth = dateOfBirth;

      this.accountContacts.push(accountContact);
    }
  }

  sortContacts() {
    this.accountContacts = this.accountContacts.sort((t1, t2) => {
      const t1Name = t1.givenName + ' ' + t1.familyName;
      const t2Name = t2.givenName + ' ' + t2.familyName;
      if (t1Name > t2Name) {
        return 1;
      }
      if (t1Name < t2Name) {
        return -1;
      }
      return 0;
    });
  }
}
