<app-header></app-header>
<app-booking-header></app-booking-header>

<!-- if its a dashboard create a wrapper for dashboard -->
<div class="wrap">
  <main class="main"><section class="wgt wgt-find-courses">
    <div class="container multi-step-form">

        <div class="span-intro">
          <div class="span-back">
            <button type="button" class="btn btn-back" id="btn-prev" [routerLink]="['/booking_start']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back
        </span>
            </button>
          </div>
        </div>

        <div class="multi-step-track">
          <div class="slide-track">

            <app-select-date-of-birth *ngIf="stepOne"></app-select-date-of-birth>
            <app-select-program *ngIf="stepTwo" [dateOfBirth]="dateOfBirth"></app-select-program>
            <app-select-program-level *ngIf="stepThree" [program]="program" [showLevelCheckerLink]="programShowLevelChecker"></app-select-program-level>
            <app-select-location *ngIf="stepFour"></app-select-location>

          </div>
        </div>

    </div>
  </section>		</main>
</div>

<app-footer></app-footer>
