<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="3"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main">

              <app-dashboard-confirmation></app-dashboard-confirmation>

              <div class="lead">
              <h1 class="title">Waiting List Requests</h1>

              <div class="row row-lead">
                <div class="span-subtitle">
                  <p>If you have placed a waiting list request with us, you will find the details about it below.</p>
                </div>
                <div class="span-button">
                  <a [routerLink]="['/dashboard_waiting_list_request']" class="btn btn-block btn-blue">make a new request</a>
                </div>
              </div>
            </div>

              <span *ngIf="waitingListsLoaded === false">
              <div class="ph-item">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                  </div>
                </div>
              </div>
              <div class="ph-item">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                  </div>
                </div>
              </div>
              </span>

              <div class="wgt wgt-dashboard wgt-waiting-lists" *ngIf="waitingListResults != null">
                <div class="card">

                  <div class="item-single" *ngFor="let entry of waitingListResults">
                    <div class="container">

                      <div class="row row-pending">
                        <div class="span-graphic">
                          <div class="badge-wrap red">
                            <div class="badge-text">{{getWaitingListStatus(entry.waitingListEntry)}}</div>
                          </div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_{{entry.waitingListEntry.id}}">{{entry.waitingListEntry.student.givenName}} {{entry.waitingListEntry.student.familyName}}</li>
                                <li>( Requested </li>
                                <li id="request_date_{{entry.waitingListEntry.id}}" class="regular">{{entry.waitingListEntry.dateRequested | worldDate: 'd MMM yyyy'}}</li>
                                <li> )</li>
                              </ul>
                            </li>
                            <li id="term_date_{{entry.waitingListEntry.id}}">{{getTermNames()}}</li>
                            <li>
                              <ul class="list-inline">
                                <li id="term_days_{{entry.waitingListEntry.id}}">{{entry.schedules.length}}</li>
                                <li> availabilities set</li>
                              </ul>
                            </li>
                            <li *ngIf="hasPlacement(entry)">
                              <ul class="list-inline">
                                <li class="waiting-list-placement-expires">
                                  <svg class="waiting-list-placement-expires-exclamation">
                                    <title>Expires</title>
                                    <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                                  </svg>
                                  <span class="span-expires-date">expires {{placementExpiry(entry) | worldDate: 'd MMM yyyy'}}</span><span *ngIf="!runningPlacementAction" class="span-expiry-action"><a [routerLink]="[]" queryParamsHandling="preserve" (click)="keepWaitingListPlacement(entry)">keep</a>&nbsp;&nbsp;<a [routerLink]="['/dashboard_waiting_list_cancel', entry.waitingListEntry.id]">cancel</a></span> </li>
                              </ul>
                            </li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button">
                          <button
                            class="btn btn-toggler"
                            data-toggle="collapsable"
                            [ngClass]="!entry.collapsed ? 'active' : ''"
                            (click)="entry.collapsed = !entry.collapsed"
                            type="button"
                          >
                            <span class="svg-icon caret"><svg class="svg-caret"><use xlink:href="./assets/sprite/spritemap.svg#sprite-caret"></use></svg></span>						</button>
                        </div>
                      </div>

                      <div class="waiting-list-extended toggler" id="waiting_list_info_{{entry.waitingListEntry.id}}" [ngClass]="!entry.collapsed ? 'active' : ''">
                        <div class="waiting-list-wrap">
                          <div class="waiting-list-info">
                            <p>The more details about your availability you can add below now, the more chances we have of placing you in one of our lessons. Please let us know as many feasible times and dates as possible so we can do our best to help.</p>
                          </div>
                          <div class="waiting-list-table">
                            <table class="table-responsive-advanced">
                              <thead>
                              <tr>
                              <th scope="col">term</th>
                              <th scope="col">day</th>
                              <th scope="col">from</th>
                              <th scope="col">until</th>
                              <th scope="col">venue</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let schedule of entry.schedules">
                                <td data-label="term">{{schedule.termDescription}}</td>
                                <td data-label="day">{{schedule.dayName}}</td>
                                <td data-label="from">{{schedule.start | worldDate: 'h:mma'}}</td>
                                <td data-label="until">{{schedule.end | worldDate: 'h:mma'}}</td>
                                <td data-label="venue">{{schedule.venueName}}</td>
                                <td><button type="button" class="btn btn-close" *ngIf="entry.schedules.length > 1" (click)="removeWaitingListAvailability(entry, schedule, $event)"><span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span></button></td>
                              </tr>
                              </tbody>
                              <tfoot>
                              <tr>
                                <td colspan="3">
                                  <a
                                    [routerLink]="['/dashboard_waiting_list_amend',entry.waitingListEntry.id]"
                                    class="btn btn-add"
                                  >
                                    <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>												add availability
                                  </a>
                                </td>
                                <td colspan="3">
                                  <button
                                    type="button"
                                    class="btn btn-close"
                                    [routerLink]="['/dashboard_waiting_list_cancel', entry.waitingListEntry.id]"
                                  >
                                    delete request
                                    <span class="svg-icon x-circle-full"><svg class="svg-x-circle-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle-full"></use></svg></span>											</button>
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div class="item-single" *ngIf="entry.waitingListEntry.enrolment != null">
                          <div class="container">

                            <div class="row row-transfer">
                              <div class="span-graphic" *ngIf="entry.waitingListEntry.enrolment.course.instructorHasPhoto">
                                <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(entry.waitingListEntry.enrolment.course.instructorId)}"></div>
                              </div>

                              <div class="span-content">
                                <ul class="list-content">
                                  <li>Transferring Enrolment from:</li>
                                  <li>
                                    <ul class="list-inline">
                                      <li id="term_date">{{entry.waitingListEntry.enrolment.course.fullDayOfWeekNames}}</li>
                                      <li> at </li>
                                      <li id="term_time">{{entry.waitingListEntry.enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
                                      <li id="term_season"> ( {{entry.waitingListEntry.enrolment.course.termDescription}} )</li>
                                    </ul>
                                  </li>
                                  <li>
                                    <ul class="list-inline">
                                      <li id="student_sklil" *ngIf="entry.waitingListEntry.enrolment.course.programLevel != null">{{entry.waitingListEntry.enrolment.course.programLevel.programLevelName}}</li>
                                      <li> with </li>
                                      <li id="student_teacher">{{entry.waitingListEntry.enrolment.course.instructorGivenName}}</li>
                                      <li> at </li>
                                      <li id="term_location">{{entry.waitingListEntry.enrolment.course.venueName}}</li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

