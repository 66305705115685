import {Invoice} from './invoice';
import {SportsPayment} from './sports-payment';
import {convertObjectToDate} from '../_helpers/date_helper';

export class UpgradeToMultipleTermsResponse {
  currencyCode: string;
  totalAmount: number;
  totalFee: number;
  totalDiscount: number;
  numberOfCredits: number;
  futureTerms: string[];
  creditNotes: Invoice[];
  numberOfClasses: number;
  sportsPayment: SportsPayment;
  result: string;
  requiresPaymentAction: boolean;
  error: string;
  paymentIntentClientSecret: string;
  lastDateOfLessons: Date;
  startDate: Date;
  totalDebitAmount: number;
  accountBalance: number;
  totalAmountWithBalance: number;
  voucherUsed: boolean;
  voucherCode: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.lastDateOfLessons = convertObjectToDate(this.lastDateOfLessons);
    this.startDate = convertObjectToDate(this.startDate);
  }
}
