<span [formGroup]="paymentForm" *ngIf="!loading">
    <div class="row row-card-info">
      <div class="span-input select required">
        <select
          id="creditCard"
          name="creditCard"
          class="input-select"
          formControlName="creditCardSelect"
          #cardPaymentMethodElement
          (change)="cardSelectChange(cardPaymentMethodElement.value)"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Card'"
        >
          <option *ngFor="let card of cards" value="{{card.sportsAccountPaymentGatewayCardId}}">{{getCreditCardName(card)}}</option>
          <option value="new">New Card...</option>
        </select>

        <label
          for="creditCard"
          class="floating-label">
          select card
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled"></ul>
      </div>
    </div>
    <span [style.display]="(cardPaymentMethod.cardId !== 'new') ? 'none' : '' ">
                  <div class="row row-card-info">

                    <div class="span-input span-text required">
                      <input
                        type="text"
                        id="creditCardName"
                        name="creditCardName"
                        class="input-text"
                        formControlName="creditCardNameInput"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="'Cardholder Name'"
                      />
                      <label
                        for="creditCardName"
                        class="floating-label">
                        card holder name
                      </label>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                      <ul class="parsley-errors-list filled"></ul>
                    </div><div class="span-input span-text required">
                    <span [ngClass]="[cardPaymentMethod.cardHasErrors ? '' : 'parsley-success', paymentForm.errors && paymentForm.errors.cardError ? '' : 'has-value']">
                    <div id="card_number" class="stripeCardNumber" #cardElement></div>
                    <label
                      for="card_number"
                      class="floating-label">
                      card number
                    </label>
                    <i class="asterisk" *ngIf="cardPaymentMethod.cardHasErrors">*</i>
                    <i class="checkmark"></i>
                    <ul *ngIf="submitted && cardPaymentMethod.cardHasErrors && paymentForm.errors != null" class="parsley-errors-list filled">
                      <li *ngIf="paymentForm.errors.cardError" class="parsley-required">{{cardPaymentMethod.cardErrors}}</li>
                    </ul>
                    </span>
                  </div>
                  </div>

                  <h3 class="title-label">
                    Card Holder Address
                  </h3>

                  <div class="row row-cha">

                    <div class="span-input span-checkbox" *ngFor="let address of paymentAddresses">
                      <label
                        for="paymentAddress{{address.addressId}}"
                        class="input-check checkbox">
                        <input

                          id="paymentAddress{{address.addressId}}"
                          name="paymentAddress"
                          type="radio"
                          class="input-checkbox"
                          [value]="address.addressId"
                          (change)="paymentAddressChange()"
                          formControlName="paymentAddress"
                          appParsleyValidation
                          [submitted]="submitted"
                          [elementName]="'Payment Address'"
                        />
                        <span class="preview-address">
			                    {{getSingleLineAddress(address)}}
		                   </span>
                      </label>
                    </div>

                    <div class="span-input span-checkbox">
                      <label
                        for="cha_other"
                        class="input-check checkbox">
                        <input
                          id="cha_other"
                          name="paymentAddress"
                          type="radio"
                          class="input-checkbox"
                          value="other"
                          (change)="paymentAddressChange()"
                          formControlName="paymentAddress"
                          appParsleyValidation
                          [submitted]="submitted"
                          [elementName]="'Payment Address'"
                        />
                        <span>
			Enter a different billing address
		</span>
                      </label>
                    </div>
                    <ul class="parsley-errors-list filled"></ul>
                  </div>

                <span [style.display]="(paymentAddressId !== 'other') ? 'none' : '' ">
                <h3 class="title-label">
                  Address
                </h3>

                  <app-address *ngIf="cardPaymentMethod != null && cardPaymentMethod.address != null"
                               [submitted]="submitted"
                               [addressForm]="paymentForm"
                               [addressLinesActive]="addressLinesActive"
                               [address]="cardPaymentMethod.address"></app-address>

                </span>
                  </span>
    </span>
