<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p style="color: white; text-align: center" > Please wait&#x2026;<br><br> We are processing your transaction, do not navigate away from this page. </p></ngx-spinner>
<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_upgrades_offers']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="/assets//sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info" *ngIf="enrolment != null">
            <h2 class="title">
              upgrade booking					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>
          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_upgrades_offers']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="/assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container multi-step-form">

      <div class="card">
        <div class="multi-step-track">
          <div class="slide-track">
            <form method="post" id="form_checkout_address" novalidate="novalidate" class="form-checkout" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
              <div class="form-section card-checkout current">
                <div class="card-inner">
                  <div class="table-summary table-checkout">

                    <div class="table-responsive" *ngIf="previewUpgradeResponse != null">
                      <table class="table table-multiple">
                        <tbody>
                        <tr>
                          <th>{{enrolment.student.givenName}} {{enrolment.student.familyName}} upgrade<br>
                            Total discount</th>
                          <th>
                            <span>{{previewUpgradeResponse.totalFee | currency:previewUpgradeResponse.currencyCode:'symbol-narrow'}}<br>
                              {{previewUpgradeResponse.totalDiscount * -1 | currency:previewUpgradeResponse.currencyCode:'symbol-narrow'}}<br>
                            </span>
                          </th>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr *ngIf="previewUpgradeResponse.accountBalance > 0">
                          <th colspan="2">{{previewUpgradeResponse.accountBalance | currency:previewUpgradeResponse.currencyCode:'symbol-narrow'}}</th>
                        </tr>
                        <tr *ngIf="previewUpgradeResponse.accountBalance > 0">
                          <td colspan="2">Account Balance</td>
                        </tr>
                        <tr>
                          <th colspan="2">{{previewUpgradeResponse.totalAmountWithBalance | currency:previewUpgradeResponse.currencyCode:'symbol-narrow'}}</th>
                        </tr>
                        <tr>
                          <td colspan="2">total</td>
                        </tr>
                        <tr>
                          <td>Start Date: {{previewUpgradeResponse.startDate | worldDate: 'EEEE d MMM yyyy'}}</td>
                        </tr>
                        <tr>
                          <td>End Date: {{previewUpgradeResponse.lastDateOfLessons | worldDate: 'EEEE d MMM yyyy'}}</td>
                        </tr>
                        <tr>
                          <td>Number of Lessons: {{previewUpgradeResponse.numberOfClasses}}</td>
                        </tr>
                        <tr>
                          <td>Number of Class Credits: {{previewUpgradeResponse.numberOfCredits}}</td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div></div>

                <div class="card-inner">

                  <div class="row row-payment-title" *ngIf="previewUpgradeResponse != null && previewUpgradeResponse.totalAmountWithBalance > 0">

                    <h3 class="span-heading title-label">
                      Payment method
                    </h3>
                    <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>		<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>

                  </div>

                  <app-checkout-payment-method-card *ngIf="enrolment != null && previewUpgradeResponse != null && previewUpgradeResponse.totalAmountWithBalance > 0"
                                                    [submitted]="submitted"
                                                    [paymentForm]="paymentForm"
                                                    [addressLinesActive]="addressLinesActive"
                                                    [accountId]="enrolment.account.id"
                                                    [licenseeId]="enrolment.account.licenseeId"
                                                    [updateInvoices]="true"></app-checkout-payment-method-card>


                  <div class="span-input span-checkbox required" *ngIf="termsAndConditions != null">
                    <label
                      for="enrolmentTerms"
                      class="input-check checkbox">
                      <input
                        id="enrolmentTerms"
                        name="enrolmentTerms"
                        type="checkbox"
                        required="required"
                        class="input-checkbox"
                        formControlName="enrolmentTermsCheckbox"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="'Terms and Conditions'"
                        [requireChecked]="true"
                      />
                      <span>
			I accept the <a target="_blank" [routerLink]="['/terms_and_conditions']">terms & conditions</a>.
		</span>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                    </label>
                    <ul class="parsley-errors-list filled"></ul>
                  </div>

                  <button type="submit" [ngClass]="previewUpgradeResponse == null || enrolling || paymentForm.invalid ? 'disabled' : ''" class="btn btn-block btn-red">
                    Complete booking
                  </button>

                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  </main>
</div>

<app-footer></app-footer>
