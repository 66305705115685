import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoginStatusService} from '../_model/login-status.service';
import {AuthService} from '../auth-service';
import {Router} from '@angular/router';
import {LoggedInUser} from '../_model/logged-in-user';
import {Subscription} from 'rxjs';
import {ApplicationSettingsService} from '../application-settings.service';
import {SessionService} from '../session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  loggedInUser: LoggedInUser;
  showMenu = false;

  companyTelephone: string;

  loggedInStatusSubscription: Subscription;

  constructor(private loginStatusService: LoginStatusService,
              private authService: AuthService,
              private applicationSettingsService: ApplicationSettingsService,
              private router: Router,
              private sessionService: SessionService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  processLoginEvent(status: boolean): void {
    if (status != null) {
      if (status) {
        this.setLoggedInUser();
      } else {
        this.loggedInUser = null;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  setLoggedInUser() {
    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.loggedInUser = loggedInUser;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.loggedInStatusSubscription = this.loginStatusService.currentLoggedInStatus.subscribe(status => {
      this.processLoginEvent(status);
    });

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.setLoggedInUser();
      }
    });

    this.companyTelephone = this.applicationSettingsService.companyTelephone;
  }

  ngOnDestroy() {
    if (this.loggedInStatusSubscription != null) {
      this.loggedInStatusSubscription.unsubscribe();
    }
  }

  doLogoff(): void {
    this.sessionService.removeBasket();
    this.authService.logout('booking_start');
  }

  doLogin(): void {
    this.authService.login();
  }

  toggleShowMenu(): void {
    this.showMenu = !this.showMenu;
    this.changeDetectorRef.detectChanges();
  }
}
