import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Mandate} from '../../../_model/mandate';
import {ApiService} from '../../../api.service';
import {CheckoutPaymentMethodCardService} from '../checkout-payment-method-card/checkout-payment-method-card.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-checkout-payment-method-mandate',
  templateUrl: './checkout-payment-method-mandate.component.html',
  styleUrls: ['./checkout-payment-method-mandate.component.css']
})
export class CheckoutPaymentMethodMandateComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() paymentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() selectedMandateId: string;
  @Input() accountId: string;
  @Input() action: string;
  @Input() extraParams: string;

  loading = true;
  settingUpMandate: boolean = false;
  ngUnsubscribe: Subject<void> = new Subject<void>();

  mandates: Mandate[];

  constructor(private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService) { }

  ngOnInit(): void {
    // Load mandates
    this.apiService.getMandates(this.accountId, this.ngUnsubscribe).subscribe(mandates => {
      this.mandates = mandates;

      if (this.mandates.length === 0) {
        this.selectedMandateId = 'new';
      } else if (this.selectedMandateId == null) {
        this.mandates.forEach(mandate => {
          if (mandate.isDefault) {
            this.selectedMandateId = mandate.sportsAccountPaymentGatewayMandateId;
          }
        });
      }
      this.paymentForm.get('mandateSelect').setValue(this.selectedMandateId);
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  mandateSelectChange(mandateId: string) {
    this.selectedMandateId = mandateId;
    this.checkoutPaymentMethodCardService.setPaymentMethodMandate(this.selectedMandateId);
    this.checkoutPaymentMethodCardService.setMandateFormUpdated();
  }

  getMandateDescription(mandate: Mandate): string {
    const accountDetails = [];
    if (mandate.bankName != null && mandate.bankName !== '') {
      accountDetails.push(mandate.bankName);
    }

    if (mandate.sortCode != null && mandate.sortCode !== '') {

      let sortCode;
      if (mandate.sortCode.length === 6) {
        sortCode = mandate.sortCode.substring(0, 2) + "-" + mandate.sortCode.substring(2, 4) + "-" + mandate.sortCode.substring(4, 6);
      } else {
        sortCode = mandate.sortCode;
      }

      accountDetails.push(sortCode);
    }

    if (mandate.trailingFourAccountDigits != null && mandate.trailingFourAccountDigits !== '') {
      accountDetails.push("****" + mandate.trailingFourAccountDigits);
    }

    if (mandate.customerName != null && mandate.customerName !== '') {
      accountDetails.push("(" + mandate.customerName + ")");
    }

    return accountDetails.join(" ");
  }

  setupDirectDebit() {
    if (this.settingUpMandate) {
      return;
    }

    this.settingUpMandate = true;
    this.apiService.setupMandate(null, this.accountId, this.action, this.extraParams).subscribe(url => {
      window.location.href = url;
    }, () => {this.settingUpMandate = false; });
  }
}
