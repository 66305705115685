export class CreditPackPurchaseRequest {
  creditPackId: string;
  accountId: string;
  studentId: string;
  cardId: string;
  licenseeId: string;
  programId: string;
  acceptedTermsAndConditions: boolean;
  termsAndConditionsPolicyId: string;
  paymentIntentId: string;
  quotedAmount: number;
  voucherCode: string;
}
