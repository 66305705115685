import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {getPriceTypeShort, getPriceTypeTitle} from '../_helpers/enrolment_helper';
import {BasketStudent} from '../_model/basket-student';
import {numberToWords} from 'number-to-words';
import {CoursePrice} from '../_model/course-price';
import {BasketService} from '../checkout/basket.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../title-generator';
import {AuthService} from '../auth-service';
import {Subscription} from 'rxjs';
import {SessionService} from '../session.service';
import {GoogleAnalyticsService} from '../google-analytics.service';
import {convertUrl} from '../_helpers/router_helper';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy {

  redirectUrl: string;
  students: BasketStudent[];
  currencyCode: string;

  basketService: Subscription;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private checkoutBasketService: BasketService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService) {
    this.students = sessionService.basket.students;
    this.currencyCode = sessionService.basket.currencyCode;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Cart');

    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params.referrer;
    });

    this.renderer.addClass(document.body, 'cart');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'basket');

    if (this.basketService != null) {
      this.basketService.unsubscribe();
    }
  }

  formatPriceTypeShort(enrolmentType: string): string {
    return getPriceTypeShort(enrolmentType);
  }

  formatPriceTypeTitle(enrolmentType: string): string {
    return getPriceTypeTitle(enrolmentType);
  }

  getTotalDueToday(basketStudent: BasketStudent): number {
    if (basketStudent.coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT'
      || basketStudent.coursePrice.priceType === 'MONTHLY_CREDIT_CARD') {
      return basketStudent.coursePrice.totalProRataPrice;
    }

    return basketStudent.coursePrice.totalPrice;
  }

  getNumberOfStudentsText(basketStudent: BasketStudent): string {
    return numberToWords.toWords(basketStudent.course.numberOfStudentsPerInstructor);
  }

  getAddress(basketStudent: BasketStudent): string {
    const address = basketStudent.venueAddress;

    let venueAddress = '';

    if (address != null) {
      venueAddress = this.appendAddress(address.addressLine1, venueAddress, true);
      venueAddress = this.appendAddress(address.addressLine2, venueAddress, true);
      venueAddress = this.appendAddress(address.addressLine3, venueAddress, true);
      venueAddress = this.appendAddress(address.locality, venueAddress, true);
      venueAddress = this.appendAddress(address.postCode, venueAddress, false);
    }

    return venueAddress;
  }

  appendAddress(addressLine: string, addressString: string, appendComma: boolean): string {
    if (addressLine != null && addressLine !== '') {
      addressString += addressLine;
      if (appendComma) {
        addressString += ', ';
      }
    }
    return addressString;
  }

  removeStudent(basketStudent: BasketStudent): void {
    let indexToRemove = null;
    let counter = 0;
    this.sessionService.basket.students.forEach(student => {
      if (student.id === basketStudent.id) {
        indexToRemove = counter;
        this.googleAnalytics.removeFromCart(student, 'New Enrolment');
      }
      counter++;
    });

    if (indexToRemove != null) {
      this.sessionService.basket.students.splice(indexToRemove, 1);
    }

    if (this.sessionService.basket.students.length === 0) {
      this.sessionService.basket.licenseeId = null;
    }

    // Update the basket in the session
    this.sessionService.saveBasket();
  }

  getPrice(coursePrice: CoursePrice): number {
    if (coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT'
      || coursePrice.priceType === 'MONTHLY_CREDIT_CARD'
      || coursePrice.priceType === 'CREDIT_PACK'
      && coursePrice.requiresMandateSetup === true) {
      return coursePrice.price;
    } else if (coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT'
      || coursePrice.priceType === 'MONTHLY_CREDIT_CARD'
      && coursePrice.requiresMandateSetup === false) {
      return coursePrice.totalProRataPrice;
    } else {
      return coursePrice.totalPrice;
    }
  }

  navigateToCheckout(): void {
    let redirectToBuy = false;
    for (const studentBasket of this.sessionService.basket.students) {
      if (studentBasket.course.courseType === 'FLEXIBLE') {
        // Check to see that the basket has the student in it
        // We assume there is only one student in it for flexible
        for (const basketStudent of this.sessionService.basket.students) {
          if (basketStudent.student.studentId != null) {
            // If single, send them to the payment details page
            redirectToBuy = true;
          }
        }
      }
    }
    // If single, send them to the payment details page
    if (redirectToBuy) {
      const referrer = 'cart';
      this.router.navigate(['/dashboard_booking_credits_buy'], {queryParams: {referrer}});
    } else {
      const referrer = this.router.url;
      this.router.navigate(['/checkout'], {queryParams: {referrer}});
    }
  }

  goBack() {
    if (this.redirectUrl == null) {
      this.redirectUrl = '/courses_list_single';
    }

    const url = convertUrl(this.redirectUrl, [{ key: 'runSearch', value: true }]);

    const queryParams = url.queryParams;
    this.router.navigate([url.page], {queryParams});
  }

  navigateToSearch() {
    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/dashboard_booking_start']);
      } else {
        this.router.navigate(['/booking_start']);
      }
    });
  }
}
