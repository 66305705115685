<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="8"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main"><app-dashboard-confirmation></app-dashboard-confirmation>

              <div class="lead">
                <h1 class="title">Billing History</h1>
              </div>

              <span *ngIf="!accountsLoaded">

                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
              </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
                <div class="ph-item">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
                </div>
              </span>

              <div class="wgt wgt-account-payment-history" *ngIf="pendingPayments != null && pendingPayments.length > 0">

                <p class="title">Overdue payments:</p>

                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-striped" role="presentation">
                      <tbody>
                      <tr *ngFor="let pendingPayment of pendingPayments">
                        <td id="pendingPayment_{{pendingPayment.pendingPaymentId}}" class="exclamation-mark-td"><svg class="exclamation-mark">
                          <title>Overdue</title>
                          <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                        </svg></td>
                        <td>{{pendingPayment.pendingPaymentCreationDate | worldDate: 'd MMM yyyy'}}</td>
                        <td>{{pendingPayment.amount | currency:pendingPayment.currencyCode:'symbol-narrow'}}</td>
                        <td>{{getPendingPaymentDescription(pendingPayment)}}</td>
                        <td><a [routerLink]="['/dashboard_invoice_payment', pendingPayment.pendingPaymentId]" *ngIf="pendingPayment.type === 'INVOICE' || pendingPayment.type === 'PAY_LATER'">pay</a></td>
                        <td><a [routerLink]="['/invoice/' + pendingPayment.pendingPaymentId + '/confirm']" *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && pendingPayment.directDebitStatus === 'requires_action'">confirm payment</a></td>
                        <td><a routerLink="" (click)="editDirectDebitDetails(pendingPayment)" *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && (pendingPayment.directDebitStatus === 'requires_payment_method' || pendingPayment.subscriptionStatus === 'incomplete_expired') && !pendingPayment.updatedPaymentMethod">update payment method</a></td>
                        <td><span *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && (pendingPayment.directDebitStatus === 'requires_payment_method' || pendingPayment.subscriptionStatus === 'incomplete_expired') && pendingPayment.updatedPaymentMethod">update payment method</span></td>
                        <td *ngIf="pendingPayment.canCancelAndWithdraw"><a [routerLink]="[]" (click)="cancelPendingPayment(pendingPayment.pendingPaymentId)">withdraw</a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div id="billingHistory" class="wgt wgt-account-payment-history" *ngIf="billingHistory != null">

                <p class="title">Payment history:</p>

                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-striped" role="presentation">
                      <tbody>
                      <tr *ngFor="let billingItem of billingHistory">
                        <td>{{billingItem.transactionDate | worldDate: 'd MMM yyyy'}}</td>
                        <td>{{getAmount(billingItem) | currency:billingItem.currencyCode:'symbol-narrow'}}{{getAmountText(billingItem)}}</td>
                        <td>{{billingItem.paymentStatus}}<span *ngIf="billingItem.paymentStatus === 'Pending' && billingItem.pendingPaymentDate != null"> ({{billingItem.pendingPaymentDate | worldDate: 'd MMM yyyy'}})</span></td>
                        <td>{{getItemType(billingItem)}}</td>
                        <td><span *ngIf="(billingItem.itemType === 'Receipt' && billingItem.paymentStatus === 'Paid') || billingItem.itemType === 'Invoice'"><a [routerLink]="[]" queryParamsHandling="preserve" (click)="invoicePdfClick(billingItem)" class="invoice"><span class="svg-icon dashboard-file"><svg class="svg-dashboard-file"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-file"></use></svg></span></a></span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>

<app-footer></app-footer>
