export class Account {
  id: string;
  accountName: string;
  accountNumber: string;
  accountType: string;
  balance: number;
  countryCode: string;
  currency: string;
  licenseeId: string;
  autoRenewal: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
