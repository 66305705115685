import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {Booking} from '../../_model/booking';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DashboardService} from '../dashboard.service';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-dashboard-booking-attending',
  templateUrl: './dashboard-booking-attending.component.html',
  styleUrls: ['./dashboard-booking-attending.component.css']
})
export class DashboardBookingAttendingComponent implements OnInit, OnDestroy {

  bookingForm: UntypedFormGroup;

  enrolment: EnrolmentResponse;
  bookings: Booking[];
  submitting = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.titleService.setTitle('Manage Booking Attendance');

    this.route.params.subscribe(params => {
      this.apiService.getEnrolment(params.id, this.ngUnsubscribe).subscribe(enrolment => {
        this.enrolment = enrolment;
        const now = new Date();
        this.bookings = [];
        enrolment.bookings.forEach(booking => {
          if (booking.bookedClass.startDate > now) {
            this.bookings.push(booking);
          }
        });
      });
    });

    this.bookingForm = this.formBuilder.group({
      notAttendingBooking: ['', Validators.required]
    });

    this.renderer.addClass(document.body, 'dashboard-booking-attending');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-attending');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  onBookingSelect(): void {
    if (this.bookingForm.invalid || this.submitting) {
      return;
    }

    const bookingNotAttending = this.bookingForm.get('notAttendingBooking').value;

    this.submitting = true;
    this.apiService.setBookingNotAttending(bookingNotAttending).subscribe(() => {
      this.router.navigate(['/dashboard_bookings']);
      this.dashboardService.setNewConfirmationMessage('Booking marked as not attending');
    }, () => {
      this.submitting = false;
    });
  }
}
