import {AbandonedCart} from './abandoned-cart';
import {convertObjectToDate} from '../_helpers/date_helper';

export class AbandonedCartResponse {
  abandonedCart: AbandonedCart;
  availableToBook: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.abandonedCart.dateAddedToCart = convertObjectToDate(this.abandonedCart.dateAddedToCart);
    this.abandonedCart.expiryDate = convertObjectToDate(this.abandonedCart.expiryDate);
    if (this.abandonedCart.course != null) {
      this.abandonedCart.course.startTimeDate = convertObjectToDate(this.abandonedCart.course.startTimeDate);
      this.abandonedCart.course.endTimeDate = convertObjectToDate(this.abandonedCart.course.endTimeDate);
    }
    if (this.abandonedCart.sportsStudent != null) {
      this.abandonedCart.sportsStudent.dateOfBirth = convertObjectToDate(this.abandonedCart.sportsStudent.dateOfBirth);
      if (this.abandonedCart.sportsStudent.levels != null) {
        this.abandonedCart.sportsStudent.levels.forEach(level => {
          level.verifiedDate = convertObjectToDate(level.verifiedDate);
        });
      }
    }
  }
}
