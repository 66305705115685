<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_account_settings']">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info">
            <h2 class="title">
              edit card					</h2>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_account_settings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-checkout">
      <div class="container" *ngIf="card != null">
        <form method="post" id="booking_edit_card_details" novalidate="novalidate" class="form-dashboard form-account" [formGroup]="editCardForm" (ngSubmit)="onCardFormSubmit()">

          <div class="card" *ngIf="!confirmDelete">
            <div class="row row-payment-title">
              <h3 class="span-heading title-label">
                Payment method
              </h3>

              <div class="span-cards">
                <span class="svg-icon card-visa"><svg class="svg-card-visa"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-visa"></use></svg></span>							<span class="svg-icon card-mastercard"><svg class="svg-card-mastercard"><use xlink:href="./assets/sprite/spritemap.svg#sprite-card-mastercard"></use></svg></span>						</div>
            </div>

            <h3 class="span-heading title-label">
              {{getCreditCardNumber()}}
            </h3>


            <div class="row row-ccn row-checkmark">
              <div class="span-input span-4 span-select required">
                <select
                  id="ce_month"
                  name="ce_month"
                  type="select"
                  required="required"
                  class="input-select"
                  formControlName="expiryMonthInput"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Expiry Month'"
                >
                  <option value="" disabled selected hidden>expiry month</option>
                  <option value="1">
                    1		</option>
                  <option value="2">
                    2		</option>
                  <option value="3">
                    3		</option>
                  <option value="4">
                    4		</option>
                  <option value="5">
                    5		</option>
                  <option value="6">
                    6		</option>
                  <option value="7">
                    7		</option>
                  <option value="8">
                    8		</option>
                  <option value="9">
                    9		</option>
                  <option value="10">
                    10		</option>
                  <option value="11">
                    11		</option>
                  <option value="12">
                    12		</option>
                </select>
                <i class="asterisk">*</i>
                <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
              </div><div class="span-input span-4 span-select required">
              <select
                id="ce_year"
                name="ce_year"
                type="select"
                required="required"
                class="input-select"
                formControlName="expiryYearInput"
                appParsleyValidation
                [submitted]="submitted"
                [elementName]="'Expiry Year'"
              >
                <option value="" disabled selected hidden>expiry year</option>
                <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
              </select>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
              <ul class="parsley-errors-list filled"></ul>
            </div>
              <div class="span-input span-checkbox">
              <label
                for="default_card"
                class="input-check checkbox">
                <input
                  id="default_card"
                  name="default_card"
                  type="checkbox"
                  class="input-checkbox"
                  value="default_card"
                  formControlName="isPrimaryCheckbox"
                  appParsleyValidation
                  [submitted]="submitted"
                  [elementName]="'Is Primary'"
                />
                <span>
									Use as my primary payment card
								</span>
              </label>
            </div>
            </div>


            <div class="row row-border">
              <h3 class="span-heading title-label">
                Card Holder's Name
              </h3>

              <div class="span-input span-text required">
              <input
                type="text"
                id="cardholder_name"
                name="cardholder_name"
                required="required"
                class="input-text"
                formControlName="cardholderNameInput"
                appParsleyValidation
                [submitted]="submitted"
                [elementName]="'Cardholder Name'"
              />
              <label for="cardholder_name" class="floating-label">
                cardholder name
              </label>
              <i class="asterisk">*</i>
              <i class="checkmark"></i>
                <ul class="parsley-errors-list filled"></ul>
            </div>
            </div>

            <div class="row row-border">
              <h3 class="span-heading title-label">
                Billing Address
              </h3>
              <app-address
                [submitted]="submitted"
                [addressForm]="editCardForm"
                [addressLinesActive]="true"
                [address]="card.cardAddress"></app-address>
            </div>

            <button type="submit" formaction="dashboard-account-settings.html" class="btn btn-block btn-red" [ngClass]="updating || editCardForm.invalid ? 'disabled' : ''">save changes</button>
          </div>

          <div *ngIf="!confirmDelete" class="card card-simple card-interactive">
            <h3 class="span-heading title-label">
              Remove this card from your profile?
            </h3>
            <button type="button" (click)="confirmDelete = true" class="btn btn-block btn-blue " >delete card</button>				</div>


          <div  *ngIf="confirmDelete" class="card card-simple card-interactive active">
            <h3 class="span-heading title-label">
              Are you sure you wish to delete this card??
            </h3>
            <div class="row row-buttons">
              <div class="span-button span-6">
                <button type="button" (click)="confirmDelete = false" class="btn btn-block btn-red-alt">
                  undo
                </button>
              </div>
              <div class="span-button span-6">
                <button type="button" (click)="deleteCard()" class="btn btn-block btn-yellow">
                  delete card
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </section>


  </main>
</div>

<app-footer></app-footer>
