import {CreateAccountAccountResult} from './create-account-account-result';

export class CreateAccountResult {
  personId: string;
  givenName: string;
  familyName: string;
  accountResults: CreateAccountAccountResult[];

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
