<app-header></app-header>
<app-checkout-header></app-checkout-header>

<div class="wrap">
  <main class="main"><div class="wgt wgt-checkout">
    <div class="container mandate-setup-text">
    {{status}}
    </div>
  </div>
  </main>
</div>
<app-footer></app-footer>
