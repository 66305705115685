import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../dashboard.service';
import {NewEnrolmentRequest} from '../../_model/new-enrolment-request';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {Address} from '../../_model/address';
import {PaymentMethod} from '../../_model/payment-method';
import {DirectDebitDetails} from '../../_model/direct-debit-details';
import {PolicyResult} from '../../_model/policy-result';
import {NewEnrolmentResponse} from '../../_model/new-enrolment-response';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {getPriceTypeTitle} from '../../_helpers/enrolment_helper';
import {AlertService} from '../../alert';
import {CheckoutPaymentMethodCardService} from '../../checkout/checkout-payment/checkout-payment-method-card/checkout-payment-method-card.service';
import {TitleGenerator} from '../../title-generator';
import {TermsAndConditionsValidator} from '../../_helpers/terms_and_conditions_validator';
import {BasketService} from '../../checkout/basket.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {AutoEnrolmentResponse} from '../../_model/auto-enrolment-response';
import {NewEnrolmentStudentResponse} from '../../_model/new-enrolment-student-response';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-booking-confirm-checkout',
  templateUrl: './dashboard-booking-confirm-checkout.component.html',
  styleUrls: ['./dashboard-booking-confirm-checkout.component.css']
})
export class DashboardBookingConfirmCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

  paymentForm: UntypedFormGroup;

  submitted = false;

  enrolmentId: string;
  enrolmentType: string;
  enrolment: EnrolmentResponse;
  previewEnrolment: NewEnrolmentResponse;

  cardPayment = false;
  directDebitPayment = false;
  directDebitPaymentWithCardProRata = false;
  requiresMandate = false;
  addressLinesActive = false;

  currentTransactionType: string;

  cardPaymentMethod: PaymentMethod;
  directDebitDetails: DirectDebitDetails;

  termsAndConditionsPolicyId: string;
  termsAndConditions: PolicyResult;
  venuePolicies: PolicyResult[];

  autoEnrolmentResponse: AutoEnrolmentResponse;

  enrolling = false;
  settingUpMandate = false;

  voucherCode: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  cardFormUpdateSubscription: Subscription;
  paymentMethodSubscription: Subscription;
  continueEnrolmentSubscription: Subscription;
  voucherCodeSubscription: Subscription;

  loading = false;
  requiresPaymentSelection = false;

  hasDirectDebitTransaction = false;
  hasCardTransaction = false;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private checkoutPaymentMethodCardService: CheckoutPaymentMethodCardService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private basketService: BasketService,
              private dashboardService: DashboardService,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2,
              private spinner: NgxSpinnerService,
              private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.loading = true;
    this.titleService.setTitle('Dashboard Booking Confirm');

    this.cardPaymentMethod = new PaymentMethod();
    this.cardPaymentMethod.address = new Address();

    this.route.params.subscribe(params => {
      this.enrolmentId = params.id;
      this.apiService.getEnrolment(this.enrolmentId, this.ngUnsubscribe).subscribe(enrolment => {
        this.enrolment = enrolment;
        this.previewProvisionalEnrolment();

        let paymentType: string;
        const paymentTypes = [];
        if ((this.enrolmentType === 'MONTHLY_DIRECT_DEBIT' || this.enrolmentType === 'MONTHLY_DIRECT_DEBIT') && this.requiresPaymentSelection) {
          this.requiresPaymentSelection = true;

          if (paymentTypes.indexOf('MONTHLY_DIRECT_DEBIT') < 0) {
            paymentTypes.push('MONTHLY_DIRECT_DEBIT');
          }

          if (paymentTypes.indexOf('CARD') < 0) {
            paymentTypes.push('CARD');
          }
        } else {
          if (!this.requiresPaymentSelection && this.enrolmentType === 'MONTHLY_DIRECT_DEBIT') {
            paymentType = this.enrolmentType;
            this.hasDirectDebitTransaction = true;
          } else if (!this.requiresPaymentSelection) {
            paymentType = 'CARD';
            this.hasCardTransaction = true;
          }

          if (paymentTypes.indexOf(paymentType) < 0) {
            paymentTypes.push(paymentType);
          }
        }

        this.apiService.getPaymentGateways(paymentTypes,
          this.enrolment.course.licenseeId, this.enrolment.account.id, this.ngUnsubscribe).subscribe(paymentGateways => {
          paymentGateways.forEach(paymentGateway => {
            if (paymentGateway.paymentGatewayType === 'CARD') {
              this.cardPayment = true;
            } else {
              this.directDebitPayment = true;
              this.requiresMandate = !paymentGateway.hasMandate;
              this.directDebitPaymentWithCardProRata = paymentGateway.monthlyWithCardProRata;

              if (!this.requiresMandate) {
                this.directDebitDetails = paymentGateway.directDebitDetails;
              }

              if (this.hasDirectDebitTransaction && this.directDebitPaymentWithCardProRata) {
                this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';
              }
            }
          });
        });

        this.venuePolicies = [];
        this.apiService.getVenuePolicies(enrolment.course.venueId, this.ngUnsubscribe).subscribe(policies => {
          const policyFormControlFields = [];
          policies.forEach(policy => {
            if (!this.venuePolicies.includes(policy)) {
              this.venuePolicies.push(policy);
              policyFormControlFields.push({ name: 'policyCheckbox_' + policy.id, control: new UntypedFormControl('', Validators.required) });
            }
          });
          policyFormControlFields.forEach(f => this.paymentForm.addControl(f.name, f.control));
        });

        const enrolmentTypes = [];
        enrolmentTypes.push(this.enrolmentType);
        this.apiService.getAutoEnrolmentSettings(this.enrolment.course.licenseeId, this.enrolment.account.id, enrolmentTypes, this.ngUnsubscribe)
          .subscribe(autoEnrolmentResponse => {
            this.autoEnrolmentResponse = autoEnrolmentResponse;
          });
      });
    });

    this.route.queryParams.subscribe(params => {
      this.enrolmentType = params.enrolmentType;
      this.requiresPaymentSelection = params.requiresPaymentSelection;

      if (this.enrolmentType === 'MONTHLY_DIRECT_DEBIT') {
        this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT';
      } else {
        this.currentTransactionType = 'CARD';
      }

      // If the enrolment type is not set, then we can't proceed
      if (this.enrolmentType == null) {
        this.router.navigate(['/dashboard_bookings']).then(() => {
          this.alertService.error('Enrolment type missing from url');
        });
      }
    });

    this.paymentForm = this.formBuilder.group({
      // There is no postcode validator, though if it was implemented it would be
      // Validators.pattern('^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$')
      creditCardSelect: [''],
      creditCardNameInput: [''],
      paymentAddress: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: [''],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: [''],
      addressCountyInput: [''],
      addressCountryInput: [''],
      addressPostcodeInput: [''],
      postcodeResultsSelect: [''],
      enrolmentTermsCheckbox: [''],
      paymentMethodInput: ['']
    });

    this.cardFormUpdateSubscription = this.checkoutPaymentMethodCardService.currentCardFormUpdated.subscribe(val => {
      if (val != null && !this.loading) {
        this.setPaymentValidators();
      }
    });

    this.paymentMethodSubscription = this.checkoutPaymentMethodCardService.currentPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod != null && !this.loading) {
        this.cardPaymentMethod = paymentMethod;
      }
    });

    this.continueEnrolmentSubscription = this.checkoutPaymentMethodCardService.currentContinueEnrolment.subscribe(hasErrors => {
      if (hasErrors != null && this.enrolment != null && !this.loading) {
        if (hasErrors) {
          this.enrolling = false;
          this.spinner.hide();
        } else {
          this.enrolStudent();
        }
      }
    });

    this.voucherCodeSubscription = this.basketService.currentVoucherCode.subscribe(voucherCode => {
      if (voucherCode != null) {
        if (voucherCode === 'NULL') {
          this.voucherCode = null;
        } else {
          this.voucherCode = voucherCode;
        }

        this.previewProvisionalEnrolment();
      }
    });

    this.renderer.addClass(document.body, 'checkout-multiple');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'type-multi');

    this.apiService.getTermsAndConditions(this.ngUnsubscribe).subscribe((termsAndConditions) => {
      this.termsAndConditions = termsAndConditions;

      if (this.termsAndConditions != null) {
        this.termsAndConditionsPolicyId = this.termsAndConditions.id;
      }
    });

    if (this.hasDirectDebitTransaction && this.directDebitPaymentWithCardProRata) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';
    } else if (this.hasDirectDebitTransaction) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT';
    } else {
      this.currentTransactionType = 'CARD';
    }

    this.setPaymentValidators();
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  previewProvisionalEnrolment() {
    this.previewEnrolment = null;
    this.apiService.previewProvisionalEnrolment(this.generateEnrolmentObject()).subscribe(response => {
      this.previewEnrolment = response;
      this.googleAnalytics.beginCheckoutForCourse(this.enrolment.course,
        this.enrolment.course.programLevel,
        null,
        response.totalAmountWithBalance,
        'Provisional Confirm',
        null);
    });
  }

  setPaymentValidators() {
    let paymentValidator = null;
    let addressValidator = null;
    let creditCardAddressValidator = null;

    if (this.previewEnrolment != null && this.previewEnrolment.totalAmountWithBalance > 0 && this.currentTransactionType === 'CARD') {
      paymentValidator = [Validators.required];

      if (this.cardPaymentMethod.cardId === 'new') {
        creditCardAddressValidator = [Validators.required];

        if (this.cardPaymentMethod.paymentAddressId === 'other') {
          addressValidator = [Validators.required];
        }
      } else {
        this.removeFormControlError('cardError');
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.termsAndConditions != null) {
      this.paymentForm.get('enrolmentTermsCheckbox').setValidators([TermsAndConditionsValidator()]);
    }

    this.paymentForm.get('creditCardSelect').setValidators(paymentValidator);
    this.paymentForm.get('creditCardNameInput').setValidators(creditCardAddressValidator);
    this.paymentForm.get('paymentAddress').setValidators(creditCardAddressValidator);
    this.paymentForm.get('addressLine1Input').setValidators(addressValidator);
    this.paymentForm.get('addressCityInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountyInput').setValidators(addressValidator);
    this.paymentForm.get('addressCountryInput').setValidators(addressValidator);
    this.paymentForm.get('addressPostcodeInput').setValidators(addressValidator);

    this.paymentForm.get('creditCardSelect').updateValueAndValidity();
    this.paymentForm.get('creditCardNameInput').updateValueAndValidity();
    this.paymentForm.get('paymentAddress').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressLine1Input').updateValueAndValidity();
    this.paymentForm.get('addressCityInput').updateValueAndValidity();
    this.paymentForm.get('addressCountyInput').updateValueAndValidity();
    this.paymentForm.get('addressCountryInput').updateValueAndValidity();
    this.paymentForm.get('addressPostcodeInput').updateValueAndValidity();
    this.paymentForm.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.cardFormUpdateSubscription != null) {
      this.cardFormUpdateSubscription.unsubscribe();
    }
    if (this.paymentMethodSubscription != null) {
      this.paymentMethodSubscription.unsubscribe();
    }
    if (this.continueEnrolmentSubscription != null) {
      this.continueEnrolmentSubscription.unsubscribe();
    }
    if (this.voucherCodeSubscription != null) {
      this.voucherCodeSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'checkout-multiple');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'type-multi');
  }

  generateEnrolmentObject(): NewEnrolmentRequest {
    const request = new NewEnrolmentRequest();

    request.accountId = this.enrolment.account.id;
    request.provisionalEnrolmentId = this.enrolmentId;
    request.provisionalEnrolmentType = this.enrolmentType;
    request.voucherCode = this.voucherCode;
    request.acceptedTermsAndConditions = this.paymentForm.get('enrolmentTermsCheckbox').value;
    request.acceptedPolicyIds = [];
    if (request.acceptedTermsAndConditions && this.termsAndConditionsPolicyId != null) {
      request.acceptedPolicyIds.push(this.termsAndConditionsPolicyId);
    }

    // You have to accept venue policies, so adding them all
    if (this.venuePolicies != null) {
      this.venuePolicies.forEach(policy => {
        request.acceptedPolicyIds.push(policy.id);
      });
    }

    if ((this.currentTransactionType === 'CARD' || this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && this.previewEnrolment != null && this.previewEnrolment.totalAmountWithBalance > 0) {
      request.cardId = this.cardPaymentMethod.cardId;
      request.paymentIntentId = this.cardPaymentMethod.paymentIntentId;
    }

    if (this.previewEnrolment != null) {
      request.quotedAmount = this.previewEnrolment.totalAmountWithBalance;
    }

    return request;
  }

  formatPriceTypeTitle(studentResponse: NewEnrolmentStudentResponse): string {
    return getPriceTypeTitle(studentResponse.enrolmentType);
  }

  getPaymentControl(componentName: string) {
    if (this.paymentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.paymentForm.get(componentName);
  }

  setupDirectDebit() {
    if (this.settingUpMandate) {
      return;
    }

    this.settingUpMandate = true;
    this.apiService.setupMandate(this.enrolment.course.licenseeId, this.enrolment.account.id, 'checkout', null).subscribe(url => {
      window.location.href = url;
    });
  }

  onPaymentSubmit(): void {
    if (this.enrolling) {
      return;
    }

    this.submitted = true;

    if (this.cardPaymentMethod.cardId === 'new' && this.cardPaymentMethod.cardHasErrors) {
      this.paymentForm.setErrors({cardError: true});
      this.changeDetectorRef.detectChanges();
    }

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      if (!this.addressLinesActive) {
        this.addressLinesActive = !!(this.submitted
          && (this.paymentForm.controls.addressLine1Input.errors
            || this.paymentForm.controls.addressCityInput.errors
            || this.paymentForm.controls.addressCountyInput.errors
            || this.paymentForm.controls.addressCountryInput.errors
            || this.paymentForm.controls.addressPostcodeInput.errors));
      }

      return;
    }

    this.enrolling = true;
    this.spinner.show();

    if ((this.currentTransactionType === 'CARD' || this.currentTransactionType === 'MONTHLY_DIRECT_DEBIT_WITH_CARD') && this.cardPaymentMethod.cardId === 'new') {
      this.checkoutPaymentMethodCardService.setAddCardToAccount();
    } else {
      this.enrolStudent();
    }
  }

  enrolStudent() {
    this.alertService.clear();

    if (this.enrolment == null) {
      return;
    }

    this.apiService.provisionalEnrol(this.generateEnrolmentObject()).subscribe(response => {
      this.enrolling = false;
      this.spinner.hide();
      let receiptId = null;
      if (response.result === 'success') {
        if (this.currentTransactionType === 'CARD') {
          if (response.sportsPayment != null
            && response.sportsPayment.payment != null
            && response.sportsPayment.payment.code !== '') {
            receiptId = response.sportsPayment.payment.code;
            this.alertService.success('Enrolment complete, receipt number: ' + response.sportsPayment.payment.code);
          } else {
            this.alertService.success('Enrolment complete');
          }
        } else {
          if (response.directDebitPendingPayment != null && response.directDebitPendingPayment.reference !== '') {
            receiptId = response.directDebitPendingPayment.reference;
            this.alertService.success('Enrolment complete, receipt number: ' + response.directDebitPendingPayment.reference);
          } else {
            this.alertService.success('Enrolment complete');
          }
        }

        this.googleAnalytics.paymentForCourse(this.enrolment.course,
          this.enrolment.course.programLevel,
          receiptId,
          response.totalAmountWithBalance,
          response.totalAmount,
          response.currency,
          'Provisional Confirm',
          null);

        this.navigateToReceipt(receiptId);
      } else if (response.result === 'requires_action') {
        if (response.requiresPaymentAction) {
          console.log('requires payment action');
          this.enrolling = true;
          this.spinner.show();
          this.checkoutPaymentMethodCardService.setHandleStripeAction(response.paymentIntentClientSecret);
        } else {
          console.error('Requires action, though action parameter not true');
        }
      } else if (response.result === 'payment_error') {
        this.alertService.error(response.error);
      } else if (response.result === 'error') {
        if (response.error != null) {
          this.alertService.error(response.error);
        } else {
          this.alertService.error('An unknown error has occurred');
        }
      } else {
        console.error('Unknown response: ' + response.result);
      }
    });
  }

  navigateToReceipt(receiptId) {
    this.router.navigate(['/booking_complete'], {queryParams: {receiptId}});
  }

  removeFormControlError(errorName: string) {
    if (this.paymentForm?.errors && this.paymentForm?.errors[errorName]) {
      delete this.paymentForm.errors[errorName];
      if (Object.keys(this.paymentForm.errors).length === 0) {
        this.paymentForm.setErrors(null);
      }
    }
  }

  paymentTypeSelected(paymentType: string): void {
    this.hasDirectDebitTransaction = paymentType === 'MONTHLY_DIRECT_DEBIT';
    if (this.hasDirectDebitTransaction && this.directDebitPaymentWithCardProRata) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT_WITH_CARD';
    } else if (this.hasDirectDebitTransaction) {
      this.currentTransactionType = 'MONTHLY_DIRECT_DEBIT';
    } else {
      this.currentTransactionType = 'CARD';
    }

    this.requiresPaymentSelection = false;
  }

  getMonthlyAmount(studentResponse: NewEnrolmentStudentResponse) {
    const discount = studentResponse.monthlyDiscount;

    if (discount == null) {
      return studentResponse.monthlyPrice.amount;
    }

    if (discount.type === 'PERCENTAGE') {
      return studentResponse.monthlyPrice.amount - (studentResponse.monthlyPrice.amount * (discount.amount / 100));
    } else {
      let discountAmount = studentResponse.monthlyPrice.amount - discount.amount;
      if (discountAmount < 0) {
        discountAmount = 0;
      }
      return discountAmount;
    }
  }

  getMonthlyEndDate(studentResponse: NewEnrolmentStudentResponse): Date {
    const nowDate = moment(studentResponse.monthlyContractStartDate);
    return nowDate.add(studentResponse.monthlyDiscount.subscriptionPeriodValue, 'month').subtract(1, 'day').toDate();
  }
}
