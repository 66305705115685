import {convertObjectToDate} from '../_helpers/date_helper';

export class RequestAccessResponse {
  status: string;
  error: string;
  username: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
