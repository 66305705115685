<app-header></app-header>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>

<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info" *ngIf="enrolment != null">
        <h2 class="title">
          {{enrolment.course.venueName}}				</h2>
        <div class="info">

          {{enrolment.course.fullDayOfWeekNames}}s @ {{enrolment.course.startTimeDate | worldDate:'HH:mm'}}					</div>
      </div>


      <div class="span-back">
        <button type="button" class="btn btn-back" [routerLink]="['/dashboard_bookings']">
          <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
        </button>
      </div>

    </div>
  </div>
</div>


<div class="wrap">
  <main class="main"><div class="wgt wgt-course-single">


    <div class="wgt wgt-course-pricing" style="background-image: url('./assets/images/location-hammersmith.jpg')">
      <div class="container">

        <div class="row row-pricing" *ngIf="coursePriceList != null">

          <div *ngFor="let priceList of coursePriceList.getActivePricesSortedBySequence()" class="span-4 row-element-centred" [ngClass]="(selectedEnrolmentType == null && priceList.sequence === 1) || (selectedEnrolmentType != null && selectedEnrolmentType === priceList.priceType) ? 'span-popular' : ''">
            <div class="card">
              <h3 class="title">
                {{formatPriceTypeTitle(priceList.priceType)}}
                <span class="tip" *ngIf="selectedEnrolmentType == null && priceList.sequence === 1">
							    (Most popular option)
						    </span>
                <span class="tip" *ngIf="selectedEnrolmentType != null && selectedEnrolmentType === priceList.priceType">
							    (Your renewal option)
						    </span>
              </h3>
              <div class="price">
                {{getPrice(priceList) | currency:coursePriceList.currencyCode:'symbol-narrow'}}<br />
                per {{formatPriceTypeShort(priceList.priceType)}}
              </div>
              <ul class="benefits">
                <li>One single payment.</li>
                <li>Regular lesson commitment often leads to better results.</li>
              </ul>

              <a (click)="addToBasket(priceList.priceType, priceList.requiresPaymentSelection)" class="btn btn-block btn-red">
                book now					</a>
            </div>
          </div>


        </div>

      </div>
    </div><div class="wgt wgt-course-info">
    <div class="container" *ngIf="enrolment != null">

      <div class="row row-info">

        <div class="span-3 span-programme">
          <span class="svg-icon badge-check"><svg class="svg-badge-check"><use xlink:href="./assets/sprite/spritemap.svg#sprite-badge-check"></use></svg></span>				<ul>
          <li class="title">programme</li>
          <li class="lead" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}</li>
          <li *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programLevelName}} (Level {{enrolment.course.programLevel.sequence}})</li>
          <li>{{getNumberOfStudentsText()}} Students per Class</li>
          <li>{{enrolment.course.classDurationInMinutes}} Minutes</li>
        </ul>
        </div>

        <div class="span-3 span-date" *ngIf="enrolment != null && coursePriceList != null">
          <span class="svg-icon calendar"><svg class="svg-calendar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-calendar"></use></svg></span>				<ul>
          <li class="title">Date & Time</li>
          <li class="lead">{{enrolment.course.termDescription}}</li>
          <li>{{enrolment.course.fullDayOfWeekNames}}s @ {{enrolment.course.startTimeDate | worldDate:'HH:mm'}}</li>
          <li>{{coursePriceList.numberOfWeeksToEnrol}} Lessons</li>
          <li>Start Date {{coursePriceList.startDate | worldDate: 'dd.MM.yy'}}</li>
        </ul>
        </div>

        <div class="span-3 span-location" *ngIf="venue != null && address != null">
          <span class="svg-icon map-marker"><svg class="svg-map-marker"><use xlink:href="./assets/sprite/spritemap.svg#sprite-map-marker"></use></svg></span>				<ul>
          <li class="title">location</li>
          <li class="lead">{{venue.venueName}}</li>
          <li *ngIf="address.addressLine1 != null">{{address.addressLine1}}</li>
          <li *ngIf="address.addressLine2 != null">{{address.addressLine2}}</li>
          <li *ngIf="address.addressLine3 != null">{{address.addressLine3}}</li>
          <li>{{address.locality}} {{address.postCode}}</li>
        </ul>
        </div>

        <div class="span-3 span-instructor" *ngIf="enrolment != null">
          <span class="svg-icon child"><svg class="svg-child"><use xlink:href="./assets/sprite/spritemap.svg#sprite-child"></use></svg></span>				<ul>
          <li class="title">instructor</li>
          <li class="lead">{{enrolment.course.instructorGivenName}}</li>
          <li class="image" *ngIf="enrolment.course.instructorHasPhoto">
            <img src="{{getInstructorImageUrl(enrolment.course.instructorId)}}" alt="{{enrolment.course.instructorGivenName}}">
          </li>
        </ul>
        </div>

      </div>

    </div>
  </div>
    <!--<div class="wgt wgt-gallery">
    <div class="container">

      <div class="row row-gallery">

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-1.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-2.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-3.jpg')"></span>
          </div>
        </div>

        <div class="span-4">
          <div class="thumb">
            <span style="background-image: url('./assets/images/gallery-hammersmit-4.jpg')"></span>
          </div>
        </div>

      </div>
    </div>
  </div>-->
    <div class="gmap" *ngIf="googleUrl != null">
      <iframe [src]="googleUrl" width="100%" height="374" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>

  </div>

  </main>
</div>

<app-footer></app-footer>
