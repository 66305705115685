import {AfterContentInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TitleGenerator} from '../../title-generator';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectLocationService} from '../select-location/select-location.service';
import {Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {convertUrl, UrlResponse} from '../../_helpers/router_helper';

@Component({
  selector: 'app-course-list-change-location',
  templateUrl: './course-list-change-location.component.html',
  styleUrls: ['./course-list-change-location.component.css']
})
export class CourseListChangeLocationComponent implements OnInit, OnDestroy, AfterContentInit {

  redirectPage: UrlResponse;
  redirecting = false;

  courseSearchParamsSubscription: Subscription;
  venueOptionsSubscription: Subscription;

  loading = true;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private router: Router,
              private selectLocationService: SelectLocationService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private sessionService: SessionService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Update Location');

    // TODO: this is wrong, don't navigate to results if there are no course search params
    if (this.sessionService.courseSearchParameters == null) {
      this.router.navigate(['/courses_list_single']);
    }

    this.route.queryParams.subscribe(params => {
      this.redirectPage = convertUrl(decodeURI(params.redirectPage), []);

      // Only do this after we have received the redirect page
      this.venueOptionsSubscription = this.selectLocationService.currentVenueOptions.subscribe(venueOptions => {
        if (venueOptions != null && !this.loading) {
          this.redirecting = true;

          this.sessionService.courseSearchParameters.venueOptions = venueOptions;
          this.sessionService.courseSearchParameters.venueOptions.venueId = null;

          this.sessionService.saveCourseSearchParameters();

          const requestData = {'address': this.sessionService.courseSearchParameters.venueOptions.address,
            'distance': this.sessionService.courseSearchParameters.venueOptions.distance,
            'longitude': this.sessionService.courseSearchParameters.venueOptions.longitude,
            'latitude': this.sessionService.courseSearchParameters.venueOptions.latitude};

          const base64Data = btoa(JSON.stringify(requestData));

          const redirectUrl = convertUrl(params.redirectPage, [
            { key: 'location', value: base64Data }]);

          const queryParams = redirectUrl.queryParams;

          this.router.navigate([redirectUrl.page], {queryParams});
        }
      });
    });

    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'type-single');
  }

  ngAfterContentInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.courseSearchParamsSubscription != null) {
      this.courseSearchParamsSubscription.unsubscribe();
    }
    if (this.venueOptionsSubscription != null) {
      this.venueOptionsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'find-course');
    this.renderer.removeClass(document.body, 'type-single');
  }

  redirectBack(): void {
    const queryParams = this.redirectPage.queryParams;

    this.router.navigate([this.redirectPage.page], {queryParams});
  }
}
