import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../auth-service';
import {Router} from '@angular/router';

@Directive({
  selector: '[appRedirectHome]'
})
export class RedirectHomeDirective implements OnInit {

  constructor(private el: ElementRef,
              private authService: AuthService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.el.nativeElement.setAttribute('href', '');
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/booking_start']);
      }
    });
  }
}
