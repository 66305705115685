import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './footer/footer.component';
import {FooterCondensedComponent} from './footer-condensed/footer-condensed.component';
import {BookingStartComponent} from './booking-start/booking-start.component';
import {HeaderComponent} from './header/header.component';
import {BookingHeaderComponent} from './booking-header/booking-header.component';
import {FindCourseSingleComponent} from './course-search/find-course-single/find-course-single.component';
import {FindCourseMultipleComponent} from './course-search/find-course-multiple/find-course-multiple.component';
import {CoursesListSingleComponent} from './course-search/courses-list-single/courses-list-single.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InteractiveSkillCheckComponent} from './course-search/interactive-skill-check/interactive-skill-check.component';
import {FindCourseMultipleStudentComponent} from './course-search/find-course-multiple-student/find-course-multiple-student.component';
import {FindCourseMultipleStepComponent} from './course-search/find-course-multiple-step/find-course-multiple-step.component';
import {SelectProgramComponent} from './course-search/select-program/select-program.component';
import {SelectProgramLevelComponent} from './course-search/select-program-level/select-program-level.component';
import {SelectLocationComponent} from './course-search/select-location/select-location.component';
import {SelectDateOfBirthComponent} from './course-search/select-date-of-birth/select-date-of-birth.component';
import {CourseListStudentFilterComponent} from './course-search/course-list-student-filter/course-list-student-filter.component';
import {CourseListFilterComponent} from './course-search/course-list-filter/course-list-filter.component';
import {
  CourseListStudentDetailsFilterComponent
} from './course-search/course-list-student-details-filter/course-list-student-details-filter.component';
import {CourseListCourseComponent} from './course-search/course-list-course/course-list-course.component';
import {InViewportModule} from 'ng-in-viewport';
import {CourseSingleComponent} from './course-search/course-single/course-single.component';
import {CourseListBasketComponent} from './course-search/course-list-basket/course-list-basket.component';
import {CartComponent} from './cart/cart.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {CheckoutHeaderComponent} from './checkout/checkout-header/checkout-header.component';
import {AuthService} from './auth-service';
import {AuthInterceptor} from './auth.interceptor';
import {AlertModule} from './alert';
import {rollbarFactory, RollbarService} from './rollbar';
import {CheckoutPersonalComponent} from './checkout/checkout-personal/checkout-personal.component';
import {CheckoutAddressComponent} from './checkout/checkout-address/checkout-address.component';
import {CheckoutStudentsComponent} from './checkout/checkout-students/checkout-students.component';
import {AppExceptionHandler} from './app-exception-handler';
import {CheckoutPaymentComponent} from './checkout/checkout-payment/checkout-payment.component';
import {AddressComponent} from './checkout/address/address.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {DirectDebitMandateRedirectComponent} from './checkout/direct-debit-mandate-redirect/direct-debit-mandate-redirect.component';
import {BookingCompleteComponent} from './booking-complete/booking-complete.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardHeaderComponent} from './dashboard/dashboard-header/dashboard-header.component';
import {DashboardSidebarComponent} from './dashboard/dashboard-sidebar/dashboard-sidebar.component';
import {OrdinalPipePipe} from './_pipes/ordinal-pipe.pipe';
import {DashboardBookingConfirmComponent} from './dashboard/dashboard-booking-confirm/dashboard-booking-confirm.component';
import {DashboardBookingCancelComponent} from './dashboard/dashboard-booking-cancel/dashboard-booking-cancel.component';
import {DashboardBookingsComponent} from './dashboard/dashboard-bookings/dashboard-bookings.component';
import {DashboardWaitingListComponent} from './dashboard/dashboard-waiting-list/dashboard-waiting-list.component';
import {DashboardWaitingListRequestComponent} from './dashboard/dashboard-waiting-list-request/dashboard-waiting-list-request.component';
import {DashboardConfirmationComponent} from './dashboard/dashboard-confirmation/dashboard-confirmation.component';
import {DashboardCreditsComponent} from './dashboard/dashboard-credits/dashboard-credits.component';
import {DashboardStudentsComponent} from './dashboard/dashboard-students/dashboard-students.component';
import {DashboardAddStudentComponent} from './dashboard/dashboard-add-student/dashboard-add-student.component';
import {DashboardUpgradesOffersComponent} from './dashboard/dashboard-upgrades-offers/dashboard-upgrades-offers.component';
import {DashboardProvisionalComponent} from './dashboard/dashboard-provisional/dashboard-provisional.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {DashboardAccountSettingsComponent} from './dashboard/dashboard-account-settings/dashboard-account-settings.component';
import {
  DashboardAccountSettingsAccountComponent
} from './dashboard/dashboard-account-settings/dashboard-account-settings-account/dashboard-account-settings-account.component';
import {
  DashboardEditDirectDebitComponent
} from './dashboard/dashboard-account-settings/dashboard-edit-direct-debit/dashboard-edit-direct-debit.component';
import {DashboardEditCardComponent} from './dashboard/dashboard-account-settings/dashboard-edit-card/dashboard-edit-card.component';
import {DashboardAddCardComponent} from './dashboard/dashboard-account-settings/dashboard-add-card/dashboard-add-card.component';
import {
  DashboardEditPersonalDetailsComponent
} from './dashboard/dashboard-account-settings/dashboard-edit-personal-details/dashboard-edit-personal-details.component';
import {DashboardBookingStartComponent} from './course-search/dashboard-booking-start/dashboard-booking-start.component';
import {DashboardBookingManageComponent} from './dashboard/dashboard-booking-manage/dashboard-booking-manage.component';
import {DashboardBookingAttendingComponent} from './dashboard/dashboard-booking-attending/dashboard-booking-attending.component';
import {DashboardWaitingListAmendComponent} from './dashboard/dashboard-waiting-list-amend/dashboard-waiting-list-amend.component';
import {DashboardBookingCreditsUseComponent} from './course-search/dashboard-booking-credits-use/dashboard-booking-credits-use.component';
import {
  DashboardBookingCreditsUseConfirmComponent
} from './course-search/dashboard-booking-credits-use-confirm/dashboard-booking-credits-use-confirm.component';
import {
  DashboardBookingConfirmCheckoutComponent
} from './dashboard/dashboard-booking-confirm-checkout/dashboard-booking-confirm-checkout.component';
import {
  CheckoutPaymentMethodCardComponent
} from './checkout/checkout-payment/checkout-payment-method-card/checkout-payment-method-card.component';
import {DashboardBookingTransferComponent} from './course-search/dashboard-booking-transfer/dashboard-booking-transfer.component';
import {
  DashboardBookingTransferConfirmComponent
} from './course-search/dashboard-booking-transfer-confirm/dashboard-booking-transfer-confirm.component';
import {
  DashboardBookingTransferPaymentComponent
} from './checkout/dashboard-booking-transfer-payment/dashboard-booking-transfer-payment.component';
import {DashboardBookingCreditsBuyComponent} from './checkout/dashboard-booking-credits-buy/dashboard-booking-credits-buy.component';
import {LostPasswordComponent} from './login/lost-password/lost-password.component';
import {RequestAccessComponent} from './login/request-access/request-access.component';
import {ActivateAccountComponent} from './login/activate-account/activate-account.component';
import {ApplicationSettingsService} from './application-settings.service';
import {TitleGenerator} from './title-generator';
import {DashboardBookingUpgradeComponent} from './checkout/dashboard-booking-upgrade/dashboard-booking-upgrade.component';
import {DashboardPreSaleUpgradeComponent} from './checkout/dashboard-pre-sale-upgrade/dashboard-pre-sale-upgrade.component';
import {CheckoutPaymentVoucherComponent} from './checkout/checkout-payment/checkout-payment-voucher/checkout-payment-voucher.component';
import {CourseListChangeLocationComponent} from './course-search/course-list-change-location/course-list-change-location.component';
import {GoogleAnalyticsService} from './google-analytics.service';
import {ParsleyValidationDirective} from './_directives/parsley-validation.directive';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RedirectHomeDirective} from './_directives/redirect-home.directive';
import {NgxSpinnerModule} from 'ngx-spinner';
import {WorldDatePipe} from './_pipes/world-date.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LogoDirective} from './_directives/logo.directive';
import {DashboardInvoicePaymentComponent} from './dashboard/dashboard-invoice-payment/dashboard-invoice-payment.component';
import {DashboardStudentProgressionComponent} from './dashboard/dashboard-student-progression/dashboard-student-progression.component';
import {DashboardWaitingListCancelComponent} from './dashboard/dashboard-waiting-list-cancel/dashboard-waiting-list-cancel.component';
import {DashboardWaitingListExpiryComponent} from './dashboard/dashboard-waiting-list-expiry/dashboard-waiting-list-expiry.component';
import {DashboardAbandonedCartsComponent} from './dashboard/dashboard-abandoned-carts/dashboard-abandoned-carts.component';
import {CourseListReferralComponent} from './course-search/course-list-referral/course-list-referral.component';
import {ErrorReportingComponent} from './error-reporting/error-reporting.component';
import {SwiperModule} from 'swiper/angular';
import {APP_BASE_HREF, DatePipe, PlatformLocation} from '@angular/common';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {DashboardBillingHistoryComponent} from './dashboard/dashboard-billing-history/dashboard-billing-history.component';
import {PasswordCheckerModule} from '@triangular/password-checker';
import {
  DashboardBookingIntensiveCreditUseComponent
} from './course-search/dashboard-booking-intensive-credit-use/dashboard-booking-intensive-credit-use.component';
import {CertificateComponent} from './certificate/certificate.component';
import {SubscriptionComponent} from './subscription/subscription.component';
import {SurveyComponent} from './survey/survey.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ResumeCartComponent} from './cart/resume-cart/resume-cart.component';
import {RenewalSubscriptionComponent} from './subscription/renewal-subscription/renewal-subscription.component';
import {InvoicingConfirmationComponent} from './invoicing/invoicing-confirmation/invoicing-confirmation.component';
import {InvoiceConfirmationCompleteComponent} from './invoicing/invoice-confirmation-complete/invoice-confirmation-complete.component';
import {DashboardInvoiceCancelComponent} from './dashboard/dashboard-invoice-cancel/dashboard-invoice-cancel.component';
import {CookieService} from 'ngx-cookie-service';
import { CheckoutPaymentMethodMandateComponent } from './checkout/checkout-payment/checkout-payment-method-mandate/checkout-payment-method-mandate.component';
import { DashboardSubscriptionCancelComponent } from './dashboard/dashboard-subscription-cancel/dashboard-subscription-cancel.component';
import { DashboardSubscriptionsComponent } from './dashboard/dashboard-subscriptions/dashboard-subscriptions.component';
import { DashboardSubscriptionSubscriptionComponent } from './dashboard/dashboard-subscriptions/dashboard-subscription-subscription/dashboard-subscription-subscription.component';

export function init_app(applicationSettingsService: ApplicationSettingsService) {
  return () => applicationSettingsService.load();
}

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          environment.keycloakSilentCheckSsoRedirectUri,
      },
      bearerExcludedUrls: ['https://api.pwnedpasswords.com', 'https://graph.facebook.com', 'https://api.ipify.org']
    }).catch(error => {
      // This needs to be set otherwise it will fail. The example code doesn't have a catch segment
      console.error('Failed to load keycloak', error);
    });
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    FooterCondensedComponent,
    BookingStartComponent,
    HeaderComponent,
    BookingHeaderComponent,
    FindCourseSingleComponent,
    FindCourseMultipleComponent,
    CoursesListSingleComponent,
    InteractiveSkillCheckComponent,
    FindCourseMultipleStudentComponent,
    FindCourseMultipleStepComponent,
    SelectProgramComponent,
    SelectProgramLevelComponent,
    SelectLocationComponent,
    SelectDateOfBirthComponent,
    CourseListStudentFilterComponent,
    CourseListFilterComponent,
    CourseListStudentDetailsFilterComponent,
    CourseListCourseComponent,
    CourseSingleComponent,
    CourseListBasketComponent,
    CartComponent,
    TermsAndConditionsComponent,
    CheckoutComponent,
    CheckoutHeaderComponent,
    CheckoutPersonalComponent,
    CheckoutAddressComponent,
    CheckoutStudentsComponent,
    CheckoutPaymentComponent,
    AddressComponent,
    PrivacyPolicyComponent,
    DirectDebitMandateRedirectComponent,
    BookingCompleteComponent,
    DashboardComponent,
    DashboardHeaderComponent,
    DashboardSidebarComponent,
    OrdinalPipePipe,
    DashboardBookingConfirmComponent,
    DashboardBookingCancelComponent,
    DashboardBookingsComponent,
    DashboardWaitingListComponent,
    DashboardWaitingListRequestComponent,
    DashboardConfirmationComponent,
    DashboardCreditsComponent,
    DashboardStudentsComponent,
    DashboardAddStudentComponent,
    DashboardUpgradesOffersComponent,
    DashboardProvisionalComponent,
    DashboardAccountSettingsComponent,
    DashboardAccountSettingsAccountComponent,
    DashboardEditDirectDebitComponent,
    DashboardEditCardComponent,
    DashboardAddCardComponent,
    DashboardEditPersonalDetailsComponent,
    DashboardBookingStartComponent,
    DashboardBookingManageComponent,
    DashboardBookingAttendingComponent,
    DashboardWaitingListAmendComponent,
    DashboardBookingCreditsUseComponent,
    DashboardBookingCreditsUseConfirmComponent,
    DashboardBookingConfirmCheckoutComponent,
    CheckoutPaymentMethodCardComponent,
    DashboardBookingTransferComponent,
    DashboardBookingTransferConfirmComponent,
    DashboardBookingTransferPaymentComponent,
    DashboardBookingCreditsBuyComponent,
    LostPasswordComponent,
    RequestAccessComponent,
    ActivateAccountComponent,
    DashboardBookingUpgradeComponent,
    DashboardPreSaleUpgradeComponent,
    CheckoutPaymentVoucherComponent,
    CourseListChangeLocationComponent,
    ParsleyValidationDirective,
    PageNotFoundComponent,
    RedirectHomeDirective,
    WorldDatePipe,
    LogoDirective,
    DashboardInvoicePaymentComponent,
    DashboardStudentProgressionComponent,
    DashboardWaitingListCancelComponent,
    DashboardWaitingListExpiryComponent,
    DashboardAbandonedCartsComponent,
    CourseListReferralComponent,
    ErrorReportingComponent,
    DashboardBillingHistoryComponent,
    DashboardBookingIntensiveCreditUseComponent,
    CertificateComponent,
    SubscriptionComponent,
    SurveyComponent,
    ResumeCartComponent,
    RenewalSubscriptionComponent,
    InvoicingConfirmationComponent,
    InvoiceConfirmationCompleteComponent,
    DashboardInvoiceCancelComponent,
    CheckoutPaymentMethodMandateComponent,
    DashboardSubscriptionCancelComponent,
    DashboardSubscriptionsComponent,
    DashboardSubscriptionSubscriptionComponent
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    InViewportModule,
    FormsModule,
    AlertModule,
    LoadingBarHttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    SwiperModule,
    KeycloakAngularModule,
    PasswordCheckerModule,
    DragDropModule
  ],
  providers: [ApplicationSettingsService, TitleGenerator, AuthService, GoogleAnalyticsService, DatePipe, CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {    provide: APP_BASE_HREF,    useFactory: getBaseHref,    deps: [PlatformLocation],  },
    { provide: ErrorHandler, useClass: AppExceptionHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [ApplicationSettingsService], multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
