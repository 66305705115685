import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DashboardService} from '../dashboard.service';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {AuthService} from '../../auth-service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {CoursePriceList} from '../../_model/course-price-list';
import {Venue} from '../../_model/venue';
import {Address} from '../../_model/address';
import {getPriceTypeShort, getPriceTypeTitle} from '../../_helpers/enrolment_helper';
import {numberToWords} from 'number-to-words';
import {CoursePrice} from '../../_model/course-price';
import {environment} from '../../../environments/environment';
import {TitleGenerator} from '../../title-generator';
import {Subject} from 'rxjs';
import {GoogleAnalyticsService} from '../../google-analytics.service';
import {AlertService} from '../../alert';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-booking-confirm',
  templateUrl: './dashboard-booking-confirm.component.html',
  styleUrls: ['./dashboard-booking-confirm.component.css']
})
export class DashboardBookingConfirmComponent implements OnInit, OnDestroy {

  enrolment: EnrolmentResponse;
  coursePriceList: CoursePriceList;
  venue: Venue;
  address: Address;
  googleUrl: SafeResourceUrl;
  selectedEnrolmentType: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private dashboardService: DashboardService,
              private alertService: AlertService,
              private sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService,
              private renderer: Renderer2,
              private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard Booking Confirm');
    this.spinner.show();

    this.route.params.subscribe(params => {
      this.apiService.getEnrolment(params.id, this.ngUnsubscribe).subscribe(enrolment => {
        this.enrolment = enrolment;
        this.apiService.getVenue(enrolment.course.venueId, this.ngUnsubscribe).subscribe(venue => {
          this.venue = venue;
          this.setGoogleUrl();
        });
        this.apiService.getAddress(enrolment.course.venueId, this.ngUnsubscribe).subscribe(venue => this.address = venue);

        this.loadPriceList();
        this.googleAnalytics.viewCourse(this.enrolment.course, this.enrolment.course.programLevel, null, 'Provisional Confirm');
      });
    });

    this.renderer.addClass(document.body, 'dashboard-booking-confirm');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-confirm');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  loadPriceList() {
    let programLevelId = null;
    if (this.enrolment.course.programLevel != null) {
      programLevelId = this.enrolment.course.programLevel.id;
    }

    this.apiService.getCoursePrices(this.enrolment.course.id, programLevelId, null, null, this.ngUnsubscribe).subscribe((data) => {
      this.coursePriceList = data;

      if (this.enrolment.previousEnrolmentType != null) {
        this.coursePriceList.prices.forEach(price => {
          if (this.enrolment.previousEnrolmentType === price.priceType) {
            this.selectedEnrolmentType = this.enrolment.previousEnrolmentType;
          }
        });
      }
        if (this.coursePriceList == null) {
          this.alertService.error('Failed to load prices');
        }
        this.loadCoursePriceDescriptions();
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
    });

  }

  loadCoursePriceDescriptions() {
    if (this.coursePriceList != null && this.coursePriceList.prices != null) {
      const priceListTypes = [];
      this.coursePriceList.prices.forEach(price => {
        if ((price.priceType === 'MONTHLY_DIRECT_DEBIT' && priceListTypes.includes('MONTHLY_CREDIT_CARD')) || (price.priceType === 'MONTHLY_CREDIT_CARD' && priceListTypes.includes('MONTHLY_DIRECT_DEBIT'))) {
          price.isHidden = true;
        } else {
          this.apiService.getEnrolmentTypeDescription(price.priceType, this.ngUnsubscribe).subscribe(points => {
            price.descriptionLines = points;

            price.descriptionLines.forEach(description => {
              if (description.content.indexOf('{makeup_lessons}') > -1) {
                description.content = description.content.replace('{makeup_lessons}', String(price.numberOfClassCredits));
              }
            });
          });
        }
        priceListTypes.push(price.priceType);
      });

      if (priceListTypes.includes('MONTHLY_DIRECT_DEBIT') && priceListTypes.includes('MONTHLY_CREDIT_CARD')) {
        this.coursePriceList.prices.forEach(price => {
          price.requiresPaymentSelection = ((price.priceType === 'MONTHLY_DIRECT_DEBIT' || price.priceType === 'MONTHLY_CREDIT_CARD') && !price.isHidden);
        });
      } else {
        this.coursePriceList.prices.forEach(price => {
          price.requiresPaymentSelection = false;
        });
      }
    }
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  setGoogleUrl() {
    if (this.venue.latitude != null && this.venue.longitude != null) {
      this.googleUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?' +
        'key=AIzaSyBn9W7K04WtxdwqzxP1zu8NRnjvCQ4Rtd4&' +
        'q=' + this.venue.latitude + ',' + this.venue.longitude);
    }
  }

  getNumberOfStudentsText(): string {
    return numberToWords.toWords(this.enrolment.course.numberOfStudentsPerInstructor);
  }

  formatPriceTypeTitle(enrolmentType: string): string {
    return getPriceTypeTitle(enrolmentType);
  }

  formatPriceTypeShort(enrolmentType: string): string {
    return getPriceTypeShort(enrolmentType);
  }

  getPrice(coursePrice: CoursePrice): number {
    if (coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT' || coursePrice.priceType === 'MONTHLY_CREDIT_CARD') {
      return coursePrice.price;
    } else {
      return coursePrice.totalPrice;
    }
  }
  addToBasket(enrolmentType: string, requiresPaymentSelection: boolean) {
    for(const price of this.coursePriceList.prices) {
      if (enrolmentType === price.priceType) {
        this.googleAnalytics.addToCartForCourse(this.enrolment.course,
          this.enrolment.course.programLevel,
          null,
          this.getPrice(price),
          this.coursePriceList.currencyCode,
          'Provisional Confirm',
          null);
      }
    }

    this.router.navigate(['/dashboard_booking_confirm_checkout', this.enrolment.enrolmentId], {queryParams: {enrolmentType, requiresPaymentSelection}});
  }

}
