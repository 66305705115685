<app-header></app-header>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
<app-booking-header></app-booking-header>

<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses booking-survey" *ngIf="!loading">
      <div class="container multi-step-form">
        <div *ngIf="surveyComplete">
          <div class="survey-complete" [innerHTML]="mergeText(survey.completionText)"></div>
        </div>
        <div *ngIf="surveyInvalid">
          <div class="survey-invalid">Survey has expired</div>
        </div>
        <div *ngIf="!surveyComplete && !surveyInvalid">
          <form method="post" id="form_survey" novalidate="novalidate" class="form-course-finder" [formGroup]="feedbackForm" (ngSubmit)="onFeedbackSubmit()">
          <div id="panel_find_location" class="panel panel-find-location" *ngIf="survey != null">

              <div class="survey-intro" [innerHTML]="mergeText(survey.introduction)"></div>

              <div *ngFor="let question of surveyQuestions">
                <label class="statement" [ngClass]="[showError(question) ? 'survey-error' : '']">{{getResourceText(question)}}</label>
                <ul class='survey-scale' *ngIf="question.type === 'nps'">
                  <li *ngFor="let scaleVal of npsList()" [ngStyle]="{'width': '10%'}">
                    <input type="radio" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}" value="{{scaleVal}}">
                    <label>{{scaleVal}}</label>
                    <div *ngIf="scaleVal === 1">Not at all likely</div>
                    <div *ngIf="scaleVal === 10">Extremely likely</div>
                  </li>
                </ul>
                <ul class='survey-scale' *ngIf="question.type === 'scale'">
                  <li *ngFor="let scaleVal of scaleList(question)" [ngStyle]="{'width': getScaleWidth(question)}">
                    <input type="radio" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}" value="{{scaleVal}}">
                    <label>{{scaleVal}}</label>
                    <div>{{getLabelForScale(question, scaleVal)}}</div>
                  </li>
                </ul>
                <textarea *ngIf="question.type === 'text'" class="survey-text" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}"></textarea>
                <ul class='survey-yes-no'  *ngIf="question.type === 'true_false'">
                  <li>
                    <input type="radio" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}" value="false">
                    <label>No</label>
                    <input type="radio" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}" value="true">
                    <label>Yes</label>
                  </li>
                </ul>
                <ul class='survey-yes-no' *ngIf="question.type === 'list'">
                  <li *ngFor="let answer of getAnswers(question)">
                    <input type="radio" name="question_{{question.feedbackParameterId}}" formControlName="question_{{question.feedbackParameterId}}" value="{{answer.feedbackParameterAnswerId}}">
                    <label>{{answer.answer}}</label>
                  </li>
                </ul>
                <ul class='survey-yes-no' *ngIf="question.type === 'multiple_choice'">
                  <div formGroupName="multipleChoiceGroup_{{question.feedbackParameterId}}">
                  <li *ngFor="let answer of getAnswers(question)">
                    <input type="checkbox" name="question_{{question.feedbackParameterId}}_{{answer.feedbackParameterAnswerId}}" formControlName="question_{{question.feedbackParameterId}}_{{answer.feedbackParameterAnswerId}}" value="{{answer.feedbackParameterAnswerId}}">
                    <label>{{answer.answer}}</label>
                  </li>
                  </div>
                </ul>
                <ul class='survey-yes-no' *ngIf="question.type === 'ranking'">
                  <div cdkDropList class="ranking-list" id="ranking-{{question.feedbackParameterId}}" (cdkDropListDropped)="rankingDrop(question.answers, $event)">
                    <div class="ranking-box" *ngFor="let answer of question.answers; let i = index" cdkDrag>{{i+1}}. {{answer.answer}}</div>
                  </div>
                </ul>
            </div>
          </div>
            <button type="submit" [ngClass]="this.feedbackForm.invalid || this.submitting ? 'disabled' : ''" class="btn btn-block btn-red">
              Submit Feedback
            </button>
          </form>
        </div>
      </div>
    </section>
  </main>
</div>


<app-footer></app-footer>
