import {StudentLevel} from './student-level';

export class AccountContact {
  type: string;
  id: string;
  givenName: string;
  familyName: string;
  programLevelId: string;
  entityId: string;
  title: string;
  dateOfBirth: Date;
  gender: string;
  hasLevelImage: boolean;
  levels: StudentLevel[]; // holder field

  AccountContact(type: string,
                 id: string,
                 title: string,
                 givenName: string,
                 familyName: string,
                 dateOfBirth: Date,
                 gender: string,
                 programLevelId: string,
                 hasLevelImage: boolean,
                 entityId: string) {
    this.type = type;
    this.id = id;
    this.givenName = givenName;
    this.familyName = familyName;
    this.programLevelId = programLevelId;
    this.hasLevelImage = hasLevelImage;
    this.entityId = entityId;
    this.title = title;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.levels = [];
  }
}
