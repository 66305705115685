<app-dashboard-header></app-dashboard-header>

<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="1"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main">
              <app-dashboard-confirmation></app-dashboard-confirmation>
              <div class="lead">
              <h1 class="title" *ngIf="loggedInUser != null">Welcome back, <span id="user_first_name">{{loggedInUser.givenName}}</span></h1>

              <div class="row row-lead">
                <div class="span-subtitle">
                </div>
                <div class="span-button">
                  <a [routerLink]="['/dashboard_bookings']" class="btn btn-block btn-blue">view all bookings</a>
                </div>
              </div>
            </div>
              <div class="wgt wgt-dashboard wgt-offers">
                <div class="card" *ngIf="abandonedCarts != null && abandonedCarts.length > 0">
              <app-dashboard-abandoned-carts *ngFor="let abandonedCart of abandonedCarts" [abandonedCartResponse]="abandonedCart"></app-dashboard-abandoned-carts>
                </div>
              </div>

              <div class="wgt wgt-account-payment-history" *ngIf="pendingPayments != null && pendingPayments.length > 0">

                <p class="title">Overdue payments:</p>

                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-striped" role="presentation">
                      <tbody>
                      <tr *ngFor="let pendingPayment of pendingPayments">
                        <td id="pendingPayment_{{pendingPayment.pendingPaymentId}}" class="exclamation-mark-td"><svg class="exclamation-mark">
                          <title>Overdue</title>
                          <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                        </svg></td>
                        <td>{{pendingPayment.pendingPaymentCreationDate | worldDate: 'd MMM yyyy'}}</td>
                        <td>{{pendingPayment.amount | currency:pendingPayment.currencyCode:'symbol-narrow'}}</td>
                        <td>{{getPendingPaymentDescription(pendingPayment)}}</td>
                        <td><a [routerLink]="['/dashboard_invoice_payment', pendingPayment.pendingPaymentId]" *ngIf="pendingPayment.type === 'INVOICE' || pendingPayment.type === 'PAY_LATER'">pay</a></td>
                        <td><a [routerLink]="['/invoice/' + pendingPayment.pendingPaymentId + '/confirm']" *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && pendingPayment.directDebitStatus === 'requires_action'">confirm payment</a></td>
                        <td><a routerLink="" (click)="editDirectDebitDetails(pendingPayment)" *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && (pendingPayment.directDebitStatus === 'requires_payment_method' || pendingPayment.subscriptionStatus === 'incomplete_expired') && !pendingPayment.updatedPaymentMethod">update payment method</a></td>
                        <td><span *ngIf="pendingPayment.type === 'DIRECT_DEBIT' && (pendingPayment.directDebitStatus === 'requires_payment_method' || pendingPayment.subscriptionStatus === 'incomplete_expired') && pendingPayment.updatedPaymentMethod">update payment method</span></td>
                        <td *ngIf="pendingPayment.canCancelAndWithdraw"><a [routerLink]="[]" (click)="cancelPendingPayment(pendingPayment.pendingPaymentId)">withdraw</a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <div class="wgt wgt-upcoming-lessons" *ngIf="futureEnrolments != null && futureEnrolments.length > 0">

                <p class="title">Your upcoming lessons:</p>

                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tbody>
                      <tr *ngFor="let futureClass of futureEnrolments">
                        <td>{{futureClass.nextClass.startDate | worldDate:'EEE'}} {{futureClass.nextClass.startDate | worldDate:'d' | ordinal}} {{futureClass.nextClass.startDate | worldDate:'MMM yyyy'}}</td>
                        <td>{{futureClass.nextClass.startDate | worldDate:'h:mma' : futureClass.nextClass.startDateOffset}}</td>
                        <td>{{futureClass.student.givenName}}</td>
                        <td><a [routerLink]="['/dashboard_booking_attending', futureClass.enrolmentId]" class="not-attending">Can’t attend?<span class="svg-icon dashboard-comment"><svg class="svg-dashboard-comment"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-comment"></use></svg></span></a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="ph-item" *ngIf="enrolmentsLoaded === false">
                <div class="ph-col-12">
                  <div class="ph-row">
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-2 empty"></div>
                  </div>
                </div>
              </div>
              <div class="wgt wgt-dashboard wgt-offers" *ngIf="provisionalEnrolments != null && provisionalEnrolments.length > 0">
                <div class="card"*ngFor="let provisional of provisionalEnrolments" >

                  <app-dashboard-provisional [provisional]="provisional" [provisionalEnrolments]="provisionalEnrolments"></app-dashboard-provisional>

                </div>
              </div><div class="wgt-credits-info" *ngIf="accountSummary != null">
                <div class="row">

                  <div class="span-card summary-link" (click)="navigateToAccountTransactions()">
                    <div class="card">
                      <div class="span-icon">
                        <span class="svg-icon dashboard-chart-bar"><svg class="svg-dashboard-chart-bar"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-chart-bar"></use></svg></span>				</div>
                      <ul class="span-content">
                        <li class="title">my account balance</li>
                        <li class="info" [ngClass]="account.balance < 0 ? 'balance-in-debt' : ''" *ngFor="let account of accountSummary.accounts">{{getAccountBalancePositive(account.balance) | currency:account.currency:'symbol-narrow'}} {{account.balance >= 0 ? 'Credit':'Debit'}} <span *ngIf="accountSummary.accounts.length > 1">({{account.accountNumber}})</span></li>
                      </ul>
                    </div>
                  </div>

                  <div class="span-card summary-link" (click)="navigateToCredits()">
                    <div class="card">
                      <div class="span-icon">
                        <span class="svg-icon dashboard-coins"><svg class="svg-dashboard-coins"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-coins"></use></svg></span>				</div>
                      <ul class="span-content">
                        <li class="title">credit packs</li>
                        <li class="info">{{numberOfCreditPacks()}} remaining</li>
                      </ul>
                    </div>
                  </div>

                  <div class="span-card summary-link" (click)="navigateToCredits()">
                    <div class="card">
                      <div class="span-icon">
                        <span class="svg-icon dashboard-database"><svg class="svg-dashboard-database"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-database"></use></svg></span>				</div>
                      <ul class="span-content">
                        <li class="title">class credit</li>
                        <li class="info">{{numberOfClassCredits()}} available</li>
                      </ul>
                    </div>
                  </div>

                  <div class="span-card summary-link" (click)="navigateToWaitingLists()">
                    <div class="card">
                      <div class="span-icon">
                        <span class="svg-icon dashboard-clock"><svg class="svg-dashboard-clock"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-clock"></use></svg></span>				</div>
                      <ul class="span-content">
                        <li class="title">waiting list entries</li>
                        <li class="info" *ngFor="let studentSummary of accountSummary.studentSummaries">{{studentSummary.numberOfWaitingListEntries}} x {{studentSummary.student.givenName}}</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              <span class="loading-account-summary" *ngIf="accountSummary == null">
                <div class="ph-item">
                  <div class="ph-col-12">
                    <div class="ph-picture"></div>
                    <div class="ph-row">
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12 empty"></div>
                      <div class="ph-col-12 empty"></div>
                    </div>
                  </div>
                </div>
                <div class="ph-item">
                  <div class="ph-col-12">
                    <div class="ph-picture"></div>
                    <div class="ph-row">
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12 empty"></div>
                      <div class="ph-col-12 empty"></div>
                    </div>
                  </div>
                </div>
                <div class="ph-item">
                  <div class="ph-col-12">
                    <div class="ph-picture"></div>
                    <div class="ph-row">
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12 empty"></div>
                      <div class="ph-col-12 empty"></div>
                    </div>
                  </div>
                </div>
                <div class="ph-item">
                  <div class="ph-col-12">
                    <div class="ph-picture"></div>
                    <div class="ph-row">
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12"></div>
                      <div class="ph-col-12 empty"></div>
                      <div class="ph-col-12 empty"></div>
                    </div>
                  </div>
                </div>
              </span>

            </main>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
