<app-header></app-header>

<div class="header">
  <div class="container">
    <div class="row">

      <div class="span-logo white-bg">
        <a appRedirectHome>
          <img appLogo/>
        </a>
      </div>

      <div class="span-info">
        <h2 class="title">
          Select Location</h2>
      </div>


      <div class="span-back">
        <button type="button" class="btn btn-back" (click)="redirectBack()">
          <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back to results        </span>
        </button>
      </div>

    </div>
  </div>
</div>


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
    <app-select-location></app-select-location>
    </section>
  </main>
</div>

<app-footer></app-footer>
