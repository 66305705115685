import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BookingHeaderTitleServiceService} from '../booking-header/booking-header-title-service.service';
import {TitleGenerator} from '../title-generator';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../session.service';
import {AuthService} from '../auth-service';

@Component({
  selector: 'app-booking-start',
  templateUrl: './booking-start.component.html',
  styleUrls: ['./booking-start.component.css']
})
export class BookingStartComponent implements OnInit, OnDestroy {

  venueId: string;
  instructorId: string;

  constructor(private titleService: TitleGenerator,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private renderer: Renderer2,
              private sessionService: SessionService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {
    this.titleService.setTitle('Find Course');

    this.bookingHeaderTitleServiceService.setTitle('find the perfect course');
    this.renderer.addClass(document.body, 'find-course');
    this.renderer.addClass(document.body, 'home');

    this.route.queryParams.subscribe(params => {
      this.venueId = params.venueId;
      this.instructorId = params.instructorId;

      if (params.termType != null) {
        if (params.termType !== 'WEEKLY' && params.termType !== 'FAST_TRACK') {
          console.log('Invalid term type: ' + params.termType);
        } else {
          this.sessionService.courseSearchTermType = params.termType;
        }
      }
    });

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'find-course');
    this.renderer.removeClass(document.body, 'home');
  }
}
