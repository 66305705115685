import {CourseSearchStudent} from './course-search-parameters';
import {Course} from './course';
import {CoursePrice} from './course-price';
import {Program} from './program';
import {Address} from './address';

export class BasketStudent {
  id: number;
  course: Course;
  coursePrice: CoursePrice;
  student: CourseSearchStudent;
  program: Program;
  venueAddress: Address;
  startDate: Date;
  numberOfWeeksToEnrol: number;
  whoIsSwimming: string;
  canEnrolInNextTerm: boolean;
  enrolInNextTerm: boolean;
  nextTermDescription: string;
  listPosition: number;
}
