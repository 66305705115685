import {convertObjectToDate} from '../_helpers/date_helper';
import {SportsFeedbackParameter} from './sports-feedback-parameter';

export class SurveyResponse {
  id: string;
  expired: boolean;
  verified: boolean;
  questions: SportsFeedbackParameter[];
  primaryContactGivenName: string;
  introduction: string;
  completionText: string;
  heading: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    if (this.questions != null) {
      this.questions.forEach(question => {
        question.validFrom = convertObjectToDate(question.validFrom);
        question.validTo = convertObjectToDate(question.validTo);
      });
    }
  }
}
