<form method="post" id="form_select_program" [formGroup]="programSelectForm">
  <div class="slide-track" [formGroup]="programSelectForm">

    <div id="panel_find_programme" class="panel panel-find-programme">

      <div class="span-title">
        <h2 class="entry-title">
          {{title}}		</h2>
      </div>

      <div class="row row-cards">

        <div class="span-card row-element-centred" *ngFor="let program of programs">
          <div class="card style-default">
            <div class="span-choice">
              <label
                for="{{program.id}}"
                class="input-check radio">
                <img src="./assets/images/programme-general.jpg" alt="{{program.programName}}">
                <input
                  id="{{program.id}}"
                  type="radio"
                  name="programSelect"
                  value="{{program.id}}"
                  data-next="3"
                  formControlName="programSelect"
                  [value]="program.id"
                  (click)="onProgramSelected(program.id)"
                />
                <span class="radio-content">
								<ul class="label-description">
									<li class="title">{{program.programName}}</li>
									<li class="lead">{{getProgramDescription(program)}}</li>
								</ul>
							</span>
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>					</div>
</form>
