export class AccountCheckResponse {
  sportsPersonId: string;
  personExists: boolean;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
