import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationSettingsService} from '../../application-settings.service';
import {ApiService} from '../../api.service';
import {Subject} from 'rxjs';
import {ConfirmationBlock} from '../../_model/confirmation-block';

@Component({
  selector: 'app-invoice-confirmation-complete',
  templateUrl: './invoice-confirmation-complete.component.html',
  styleUrls: ['./invoice-confirmation-complete.component.css']
})
export class InvoiceConfirmationCompleteComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private applicationSettingsService: ApplicationSettingsService,
              private apiService: ApiService) { }

  ngUnsubscribe: Subject<void> = new Subject<void>();

  confirmationBlocks: ConfirmationBlock[];

  ngOnInit(): void {
    this.apiService.getConfirmationBlocks(this.ngUnsubscribe).subscribe(blocks => {
      this.confirmationBlocks = blocks;

      let counter = 1;
      this.confirmationBlocks.forEach(block => {
        if (counter === 3) {
          counter = 1;
        }

        switch (counter) {
          case 1:
            block.class = 'span-blue';
            break;
          case 2:
            block.class = 'span-red';
            break;
        }

        counter++;
      });
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  getIconLocation(block: ConfirmationBlock): string {
    return './assets/sprite/spritemap.svg#' + block.icon;
  }

}
