import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CreateStudentRequest} from '../../_model/create-student-request';
import {ApiService} from '../../api.service';
import {DashboardAddStudentService} from './dashboard-add-student.service';
import {Venue} from '../../_model/venue';
import {MembershipType} from '../../_model/membership-type';
import {AlertService} from '../../alert';
import {Membership} from '../../_model/membership';
import {Subject, Subscription} from 'rxjs';
import {convertDateToServerTimezone} from '../../_helpers/date_helper';
import {ApplicationSettingsService} from '../../application-settings.service';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-add-student',
  templateUrl: './dashboard-add-student.component.html',
  styleUrls: ['./dashboard-add-student.component.css']
})
export class DashboardAddStudentComponent implements OnInit, OnDestroy {

  newStudentForm: UntypedFormGroup;
  createStudentRequest: CreateStudentRequest;
  submitted = false;
  submitting = false;
  dobYears: number[];
  bodyClassList: string[];
  dobDay: number;
  dobMonth: number;
  dobYear: number;
  buttonText = 'add student';

  showMembershipForm = false;
  membershipForm: UntypedFormGroup;
  membershipSubmitted = false;

  memberships: Membership[];
  venues: Venue[];
  membershipTypes: MembershipType[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  accountContactSubscription: Subscription;
  studentToUpdateSubscription: Subscription;

  constructor(private dashboardAddStudentService: DashboardAddStudentService,
              private apiService: ApiService,
              private alertService: AlertService,
              private renderer: Renderer2,
              private changeDetectorRef: ChangeDetectorRef,
              private applicationSettings: ApplicationSettingsService,
              private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createStudentRequest = new CreateStudentRequest();

    this.newStudentForm = this.formBuilder.group({
      studentTitleSelect: ['', Validators.required],
      studentFirstNameInput: ['', Validators.required],
      studentLastNameInput: ['', Validators.required],
      studentGenderSelect: ['', Validators.required],
      studentMedicalRadio: ['', Validators.required],
      studentMedicalConditionsTextArea: [''],
      studentDateOfBirthDaySelect: ['', Validators.required],
      studentDateOfBirthMonthSelect: ['', Validators.required],
      studentDateOfBirthYearSelect: ['', Validators.required]
    });

    this.membershipForm = this.formBuilder.group({
      membershipVenueSelect: ['', Validators.required],
      membershipTypeSelect: ['', Validators.required],
      membershipCodeInput: ['', Validators.required]
    });

    this.newStudentForm.get('studentMedicalRadio').valueChanges.subscribe(checked => {
      this.setControlMedicalValidators(checked);
    });

    this.accountContactSubscription = this.dashboardAddStudentService.currentAccountContact.subscribe(contact => {
      if (contact != null) {
        this.createStudentRequest.personId = contact.entityId;
        this.createStudentRequest.givenName = contact.givenName;
        this.createStudentRequest.familyName = contact.familyName;
        this.createStudentRequest.whoIsSwimming = contact.type;
        this.createStudentRequest.title = contact.title;
        this.createStudentRequest.gender = contact.gender;
        this.createStudentRequest.dateOfBirth = contact.dateOfBirth;

        if (contact.dateOfBirth != null) {
          this.dobDay = contact.dateOfBirth.getDate();
          this.dobMonth = contact.dateOfBirth.getMonth() + 1;
          this.dobYear = contact.dateOfBirth.getFullYear();
        }

        this.bindParameters();
      }
    });

    this.bodyClassList = [];
    document.body.classList.forEach( className => this.bodyClassList.push(className));

    this.bodyClassList.forEach(element => this.renderer.removeClass(document.body, element));

    this.renderer.addClass(document.body, 'dashboard-action');
    this.renderer.addClass(document.body, 'dashboard-booking-add-student');

    this.dobYears = new Array<number>();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= (currentYear - 100); i--) {
      this.dobYears.push(i);
    }

    this.studentToUpdateSubscription = this.dashboardAddStudentService.currentStudentToUpdate.subscribe(student => {
      if (student != null) {
        this.createStudentRequest.studentId = student.id;
        this.createStudentRequest.title = student.title;
        this.createStudentRequest.givenName = student.givenName;
        this.createStudentRequest.familyName = student.familyName;
        this.createStudentRequest.dateOfBirth = student.dateOfBirth;

        if (student.dateOfBirth != null) {
          this.dobDay = student.dateOfBirth.getDate();
          this.dobMonth = student.dateOfBirth.getMonth() + 1;
          this.dobYear = student.dateOfBirth.getFullYear();
        }

        this.createStudentRequest.gender = student.gender;

        if (student.medicalHistoryNotes != null && student.medicalHistoryNotes !== '') {
          this.createStudentRequest.hasMedicalConditions = true;
          this.createStudentRequest.medicalConditions = student.medicalHistoryNotes;
        } else {
          this.createStudentRequest.hasMedicalConditions = false;
        }

        this.bindParameters();

        this.buttonText = 'update student';
        this.setupAddMembership();

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  bindParameters() {
    this.newStudentForm.patchValue({
      studentTitleSelect: this.createStudentRequest.title,
      studentFirstNameInput: this.createStudentRequest.givenName,
      studentLastNameInput: this.createStudentRequest.familyName,
      studentGenderSelect: this.createStudentRequest.gender,
      studentMedicalRadio: this.createStudentRequest.hasMedicalConditions,
      studentMedicalConditionsTextArea: this.createStudentRequest.medicalConditions,
      studentDateOfBirthDaySelect: this.dobDay,
      studentDateOfBirthMonthSelect: this.dobMonth,
      studentDateOfBirthYearSelect: this.dobYear
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.accountContactSubscription != null) {
      this.accountContactSubscription.unsubscribe();
    }
    if (this.studentToUpdateSubscription != null) {
      this.studentToUpdateSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-action');
    this.renderer.removeClass(document.body, 'dashboard-booking-add-student');

    this.bodyClassList.forEach(element => this.renderer.addClass(document.body, element));
  }

  setControlMedicalValidators(required: boolean): void {
    let medicalRequired = null;
    if (required) {
      medicalRequired = [Validators.required];
    }

    this.newStudentForm.get('studentMedicalConditionsTextArea').setValidators(medicalRequired);
    this.newStudentForm.get('studentMedicalConditionsTextArea').updateValueAndValidity();
  }

  getStudentControl(componentName: string) {
    if (this.newStudentForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.newStudentForm.get(componentName);
  }

  getMembershipControl(componentName: string) {
    if (this.membershipForm.get(componentName) == null) {
      console.error('Failed to find component ' + componentName);
    }
    return this.membershipForm.get(componentName);
  }

  onNewStudentSubmit() {
    this.submitted = true;
    if (this.newStudentForm.invalid || this.submitting) {
      return;
    }

    this.submitted = false;
    this.submitting = true;

    const day = parseInt(this.newStudentForm.get('studentDateOfBirthDaySelect').value, 10);
    const month = parseInt(this.newStudentForm.get('studentDateOfBirthMonthSelect').value, 10) - 1;
    const year = parseInt(this.newStudentForm.get('studentDateOfBirthYearSelect').value, 10);

    const date = moment().set(
      {year,
        month,
        date: day
      });

    this.createStudentRequest.dateOfBirth = convertDateToServerTimezone(date.toDate(), this.applicationSettings.timezone);
    this.createStudentRequest.title = this.newStudentForm.get('studentTitleSelect').value;
    this.createStudentRequest.givenName = this.newStudentForm.get('studentFirstNameInput').value;
    this.createStudentRequest.familyName = this.newStudentForm.get('studentLastNameInput').value;
    this.createStudentRequest.gender = this.newStudentForm.get('studentGenderSelect').value;
    this.createStudentRequest.hasMedicalConditions = this.newStudentForm.get('studentMedicalRadio').value;
    this.createStudentRequest.medicalConditions = this.newStudentForm.get('studentMedicalConditionsTextArea').value;

    if (this.createStudentRequest.whoIsSwimming == null) {
      if (this.createStudentRequest.studentId == null) {
        this.createStudentRequest.whoIsSwimming = 'new';
      } else {
        this.createStudentRequest.whoIsSwimming = 'update';
      }
    }

    const studentRequests = [];
    studentRequests.push(this.createStudentRequest);

    this.apiService.createStudent(studentRequests).subscribe(response => {
      for (const createStudentResponse of response) {
        this.dashboardAddStudentService.setNewStudentId(createStudentResponse.studentId);
      }

      this.submitting = false;
    }, () => this.submitting = false);

  }

  setupAddMembership() {
    this.apiService.getVenues(this.ngUnsubscribe).subscribe(venues => this.venues = venues);

    this.showMembershipForm = true;

    this.loadMemberships();
  }

  loadMemberships() {
    this.apiService.getStudentMemberships(this.createStudentRequest.studentId, this.ngUnsubscribe)
      .subscribe(memberships => this.memberships = memberships);
  }

  onMembershipSubmit(): void {
    this.membershipSubmitted = true;
    if (this.membershipForm.invalid || this.submitting) {
      return;
    }

    this.submitting = true;

    const membershipTypeId = this.membershipForm.get('membershipTypeSelect').value;
    const membershipCode = this.membershipForm.get('membershipCodeInput').value;

    this.apiService.addStudentMembership(this.createStudentRequest.studentId,
      membershipTypeId,
      membershipCode).subscribe(response => {
      this.alertService.success('Membership for ' + response.membershipType.description + ' added');
      this.membershipForm.get('membershipTypeSelect').setValue(null);
      this.membershipForm.get('membershipCodeInput').setValue(null);
      this.membershipForm.get('membershipVenueSelect').setValue(null);

      this.submitting = false;
      this.membershipSubmitted = false;

      this.loadMemberships();
    }, () => this.submitting = false);
  }

  updateMemberships(): void {
    const membershipVenueId = this.membershipForm.get('membershipVenueSelect').value;
    if (membershipVenueId == null || membershipVenueId === '') {
      return;
    }

    this.membershipTypes = null;
    this.apiService.getVenueMembershipTypes(membershipVenueId, this.ngUnsubscribe)
      .subscribe(membershipTypes => this.membershipTypes = membershipTypes);
  }

  cancelMembership(membershipId: string) {
    this.apiService.removeStudentMembership(this.createStudentRequest.studentId, membershipId).subscribe(() => this.loadMemberships());
  }

  handleMedicalChange() {
    const hasMedical = this.newStudentForm.get('studentMedicalRadio').value;
    this.createStudentRequest.hasMedicalConditions = hasMedical;
  }
}
