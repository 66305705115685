import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SportsCreditStudentResult} from '../../_model/sports-credit-student-result';
import {AlertService} from '../../alert';
import {Instructor} from '../course-list-student-filter/instructor';
import {StartTime} from '../course-list-student-filter/start-time';
import {CourseSearchResult} from '../../_model/course-search-result';
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {BookingHeaderTitleServiceService} from '../../booking-header/booking-header-title-service.service';
import {TitleGenerator} from '../../title-generator';
import {isIterable} from '../../_helpers/general_helpers';
import {SelectLocationService} from '../select-location/select-location.service';
import {CourseListSingleServiceService} from '../courses-list-single/course-list-single-service.service';
import {Subject} from 'rxjs';
import {SessionService} from '../../session.service';
import {GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-dashboard-booking-credits-use',
  templateUrl: './dashboard-booking-credits-use.component.html',
  styleUrls: ['./dashboard-booking-credits-use.component.css']
})
export class DashboardBookingCreditsUseComponent implements OnInit, AfterViewInit, OnDestroy {

  ngUnsubscribe: Subject<void> = new Subject<void>();

  creditId: string;
  credit: SportsCreditStudentResult;
  courseResults: CourseSearchResult[];
  creditType: string;

  isSearchDisabled = false;
  runningCourseSearch = false;
  mondayExists = false;
  tuesdayExists = false;
  wednesdayExists = false;
  thursdayExists = false;
  fridayExists = false;
  saturdayExists = false;
  sundayExists = false;
  instructors: Instructor[];
  startTimes: StartTime[];

  address: string;
  distance: number;
  longitude: number;
  latitude: number;

  loading: boolean;

  showWeekly = true;

  mode: string;

  constructor(private alertService: AlertService,
              private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private bookingHeaderTitleServiceService: BookingHeaderTitleServiceService,
              private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private selectLocationService: SelectLocationService,
              private courseListSingleServiceService: CourseListSingleServiceService,
              private googleAnalytics: GoogleAnalyticsService,
              private sessionService: SessionService) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Credit Booking Search');

    this.sessionService.removeCourseSearchParameters();
    this.sessionService.courseSearchParameters.venueOptions.distance = 5;
    this.sessionService.courseSearchParameters.venueOptions.address = '';

    this.courseListStudentDetailsFilterService.studentDetailsFilterChangeUpdate.subscribe(hasValidUpdate => {
      if (hasValidUpdate != null && !this.loading) {
        this.courseResults = [];
        this.bookingHeaderTitleServiceService.setTitle('');
        if (hasValidUpdate) {
          this.setCourseSearch('full');
        }
      }
    });
    this.courseListStudentDetailsFilterService.filterChangeUpdate.subscribe(refreshRequested => {
      if (refreshRequested != null) {
        this.refreshCourses();
      }
    });

    this.route.params.subscribe(params => {
      this.creditId = params.id;
    });

    this.route.queryParams
      .subscribe(params => {
        if (params.location != null) {
          const decodedBase64 = atob(params.location);
          const addressDetails = JSON.parse(decodedBase64);

          this.address = addressDetails.address;
          this.distance = addressDetails.distance;
          this.longitude = addressDetails.longitude;
          this.latitude = addressDetails.latitude;
        }
      });

    this.renderer.addClass(document.body, 'courses-list-single');
    this.renderer.addClass(document.body, 'type-single');
  }

  ngAfterViewInit() {
    this.apiService.getCredit(this.creditId, this.ngUnsubscribe).subscribe(result => {
      this.credit = result;
      const creditResult = this.credit.creditResults[0];

      this.creditType =  creditResult.type;

      if (creditResult.status !== 'ACTIVE') {
        this.alertService.error('Credit is no longer active');
        return;
      }

      if (creditResult.expired) {
        this.alertService.error('Credit has expired');
        return;
      }

      if (this.address == null || this.address === '') {
        this.sessionService.courseSearchParameters.venueOptions.venueId = creditResult.venueId;
        this.selectLocationService.setVenueId(creditResult.venueId);
      } else {
        this.sessionService.courseSearchParameters.venueOptions.address = this.address;
        this.sessionService.courseSearchParameters.venueOptions.distance = this.distance;
        this.sessionService.courseSearchParameters.venueOptions.longitude = this.longitude;
        this.sessionService.courseSearchParameters.venueOptions.latitude = this.latitude;
        this.sessionService.courseSearchParameters.venueOptions.venueId = null;
      }

      if (this.creditType === 'IntensiveCourse') {
        this.showWeekly = false;
        this.sessionService.courseSearchParameters.termType = 'FAST_TRACK';
        this.mode = 'intensive-credit';
      } else {
        this.mode = 'credit';
      }

      this.selectLocationService.setVenueOptionsUpdated();

      // Run the search
      this.setCourseSearch('full');

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'courses-list-single');
    this.renderer.removeClass(document.body, 'type-single');
  }

  setCourseSearch(runType: string) {
    if (this.sessionService.courseSearchParameters == null) {
      console.error('Course search parameters not set for run');
      return;
    }

    console.log('Running search for ' + runType);

    this.renderer.addClass(document.body, 'index-courses');

    this.bookingHeaderTitleServiceService.setTitle('');

    this.isSearchDisabled = true;

    if (runType === 'full') {
      // Ensure that the student information is not set
      this.sessionService.courseSearchParameters.students = null;

      this.runningCourseSearch = true;
      this.apiService.courseCreditSearch(this.credit.student.id,
        this.credit.creditResults[0].credit.sportsCreditId,
        this.sessionService.courseSearchParameters, this.ngUnsubscribe)
        .subscribe((data) => {
          this.courseResults = data;
          this.runningCourseSearch = false;

          this.filterCourseSearchResults();
          this.isSearchDisabled = false;

          let programLevelName = null;
          if (this.credit.creditResults[0].course != null && this.credit.creditResults[0].course.programLevel != null) {
            programLevelName = this.credit.creditResults[0].course.programLevel.programLevelName;
          }

          if (this.creditType === 'IntensiveCourse') {
            this.googleAnalytics.search(programLevelName, 'Intensive Credit Search');
          } else {
            this.googleAnalytics.search(programLevelName, 'Make-up');
          }
        });
    } else if (runType === 'refresh') {
      this.filterCourseSearchResults();
      this.isSearchDisabled = false;
    }
  }

  filterCourseSearchResults(): void {
    this.instructors = [];
    this.startTimes = [];

    this.mondayExists = false;
    this.tuesdayExists = false;
    this.wednesdayExists = false;
    this.thursdayExists = false;
    this.fridayExists = false;
    this.saturdayExists = false;
    this.sundayExists = false;

    const courseSearchDays = [];

    let counter = 0;
    if (isIterable(this.courseResults)) {
      this.courseResults.forEach(course => {

        switch (course.course.fullDayOfWeekNames) {
          case 'Monday': {
            if (!courseSearchDays.includes(2)) {
              courseSearchDays.push(2);
            }
            this.mondayExists = true;
            break;
          }
          case 'Tuesday': {
            if (!courseSearchDays.includes(3)) {
              courseSearchDays.push(3);
            }
            this.tuesdayExists = true;
            break;
          }
          case 'Wednesday': {
            if (!courseSearchDays.includes(4)) {
              courseSearchDays.push(4);
            }
            this.wednesdayExists = true;
            break;
          }
          case 'Thursday': {
            if (!courseSearchDays.includes(5)) {
              courseSearchDays.push(5);
            }
            this.thursdayExists = true;
            break;
          }
          case 'Friday': {
            if (!courseSearchDays.includes(6)) {
              courseSearchDays.push(6);
            }
            this.fridayExists = true;
            break;
          }
          case 'Saturday': {
            if (!courseSearchDays.includes(7)) {
              courseSearchDays.push(7);
            }
            this.saturdayExists = true;
            break;
          }
          case 'Sunday': {
            if (!courseSearchDays.includes(1)) {
              courseSearchDays.push(1);
            }
            this.sundayExists = true;
            break;
          }

        }

        let foundInstructor = false;
        this.instructors.forEach(instructor => {
          if (course.course.instructorId === instructor.id) {
            foundInstructor = true;
          }
        });

        if (!foundInstructor) {
          const courseInstructor = new Instructor();
          courseInstructor.id = course.course.instructorId;
          courseInstructor.displayName = course.instructorDisplayName;
          courseInstructor.checked = true;

          this.instructors.push(courseInstructor);
        }

        let foundTime = false;
        const startTimeHour = course.course.startTimeHour;

        this.startTimes.forEach(startTime => {
          if (startTimeHour === startTime.hour) {
            foundTime = true;
          }
        });

        if (!foundTime) {
          const startTime = new StartTime();
          startTime.hour = startTimeHour;
          startTime.checked = true;

          const startDate = new Date();
          startDate.setHours(startTime.hour, 0, 0);
          startTime.fromTime = startDate;

          let toValue = startTime.hour + 1;
          if (toValue === 24) {
            toValue = 0;
          }

          const endDate = new Date();
          endDate.setHours(toValue, 0, 0);

          startTime.toTime = endDate;

          this.startTimes.push(startTime);
        }

        counter++;
      });
    }

    this.startTimes.sort((t1, t2) => {
      if (t1.hour > t2.hour) {
        return 1;
      }
      if (t1.hour < t2.hour) {
        return -1;
      }
      return 0;
    });

    this.instructors.sort((t1, t2) => {
      if (t1.displayName > t2.displayName) {
        return 1;
      }
      if (t1.displayName < t2.displayName) {
        return -1;
      }
      return 0;
    });

    this.sessionService.courseSearchParameters.days = courseSearchDays;
    this.bookingHeaderTitleServiceService.setTitle(counter + ' courses found');
  }

  refreshCourses() {
    if (this.courseResults != null) {
      let totalCourses = this.courseResults.length;
      if (isIterable(this.courseResults)) {
        for (const courseResult of this.courseResults) {
          courseResult.show = this.canShowCourse(courseResult);
          if (!courseResult.show) {
            totalCourses--;
          }
        }
      }
      this.bookingHeaderTitleServiceService.setTitle(totalCourses + ' courses found');
    }
  }

  canShowCourse(courseSearchResult: CourseSearchResult): boolean {
    let foundDay = false;

    if (this.creditType === 'IntensiveCourse') {
      const studentCredit = this.credit.creditResults[0];
      if (courseSearchResult.course.numberOfStudentsPerInstructor < studentCredit.minNumberOfStudentsPerInstructor
          || courseSearchResult.course.numberOfStudentsPerInstructor > studentCredit.maxNumberOfStudentsPerInstructor
          || courseSearchResult.course.classDurationInMinutes < studentCredit.minClassDurationInMinutes
          || courseSearchResult.course.classDurationInMinutes > studentCredit.maxClassDurationInMinutes) {
        return false;
      }
    }

    if (this.sessionService.courseSearchParameters.days == null) {
      return false;
    }

    for (const dayNumber of courseSearchResult.course.dayOfTheWeekNumbers) {
      if (this.sessionService.courseSearchParameters.days.includes(dayNumber)) {
        foundDay = true;
        break;
      }
    }

    if (!foundDay) {
      return false;
    }

    for (const instructor of this.instructors) {
      if (!instructor.checked
        && instructor.id === courseSearchResult.course.instructorId) {
        return false;
      }
    }

    for (const startTime of this.startTimes) {
      if (!startTime.checked
        && startTime.hour === courseSearchResult.course.startTimeHour) {
        return false;
      }
    }

    return true;
  }
}
