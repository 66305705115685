<app-dashboard-header></app-dashboard-header>

<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->
<div class="dashboard">

  <div class="container">
    <div class="row row-dashboard">

      <app-dashboard-sidebar [activeItem]="4"></app-dashboard-sidebar>

      <div class="span-main">
        <div class="container">


          <div class="wrap">
            <main class="main">
              <app-dashboard-confirmation></app-dashboard-confirmation>

              <div class="lead">
              <h1 class="title">Your Credits</h1>

              <div class="row row-lead">
                <div class="span-subtitle">
                  <p>All of your remaining credits can be found here. Whether they are make-up credits, or part of your credit pack, you can use them to book in future lessons.</p>
                </div>
              </div>
            </div>

              <span *ngIf="creditsLoaded === false">
              <div class="ph-item">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
              </div>
              </span>

              <!--
              ---	multiple sessions
              -->
              <span *ngIf="credits != null">
              <div class="wgt wgt-dashboard wgt-credits" *ngFor="let studentCredit of credits">
                <div class="card">

                  <div class="item-single">
                    <div class="container">
                      <div class="row row-credits">

                        <div class="span-graphic">
                          <div class="badge-wrap green">
                            <div class="badge-text">make-up sessions</div>
                          </div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_1">{{studentCredit.student.givenName}} {{studentCredit.student.familyName}}</li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssa_number_1">{{studentCredit.creditResults.length}} Sessions</li>
                                <li> Available</li>
                              </ul>
                            </li>
                            <li *ngFor="let credit of studentCredit.creditResults">
                              <ul class="list-inline">
                                <li>From </li>
                                <li id="ssa_start_1" *ngIf="credit.credit.relatedBooking != null">{{credit.credit.relatedBooking.bookedClass.startDate | worldDate: 'd MMM yyyy'}}</li>
                                <li id="ssa_start_1" *ngIf="credit.credit.relatedBooking == null">{{credit.creationDate | worldDate: 'd MMM yyyy'}}</li>
                                <li> at </li>
                                <li id="ss_location_1">{{credit.course.venueName}}</li>
                                <li> expires </li>
                                <li id="ssa_end_1">{{credit.expiryDate | worldDate: 'd MMM yyyy'}}</li>
                              </ul>
                            </li>
                          </ul>
                        </div>

                        <div class="span-buttons">
                          <a [routerLink]="[]" queryParamsHandling="preserve" (click)="useCredit(studentCredit)" class="btn btn-block btn-blue">use credit<span *ngIf="studentCredit.creditResults.length > 1">s</span></a>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
              </span>


              <span *ngIf="intensiveCredits != null">
              <div class="wgt wgt-dashboard wgt-credits" *ngFor="let studentCredit of intensiveCredits">
                <div class="card" *ngFor="let credit of studentCredit.creditResults">

                  <div class="item-single">
                    <div class="container">
                      <div class="row row-credits">

                        <div class="span-graphic">
                          <div class="badge-wrap green">
                            <div class="badge-text">fast track course</div>
                          </div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_1">{{studentCredit.student.givenName}} {{studentCredit.student.familyName}}</li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssa_number_1">Intensive Course</li>
                                <li> Available</li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssa_start_1">5</li>
                                <li> lessons with </li>
                                <li id="ssa_start_1">{{getStudentsPerInstructor(credit)}}</li>
                                <li> students and </li>
                                <li id="ssa_start_1">{{getClassDuration(credit)}} minute</li>
                                <li> duration, </li>
                                <li> expires </li>
                                <li id="ssa_end_1">{{credit.expiryDate | worldDate: 'd MMM yyyy'}}</li>
                              </ul>
                            </li>
                          </ul>
                        </div>

                        <div class="span-buttons">
                          <a [routerLink]="[]" (click)="useCredit(studentCredit)" class="btn btn-block btn-blue">book</a>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
              </span>

              <span *ngIf="creditPacksLoaded === false">
              <div class="ph-item">

                <div class="ph-col-2">
                  <div class="ph-avatar"></div>
                </div>

                <div class="loading-waiting-list-row">
                  <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-8"></div>
                    <div class="ph-col-4 empty"></div>
                  </div>
                </div>
              </div>
              </span>

              <!--
              ---	one credit + upcoming lessons + completed sessions + credit pack
              -->
              <div class="wgt wgt-dashboard wgt-credits" *ngIf="creditPacks != null">
                <div class="card" *ngFor="let studentCredit of creditPacks">

                  <div class="item-single">
                    <div class="container">

                      <div class="row row-credits">
                        <div class="span-graphic">
                          <div class="badge-wrap green">
                            <div class="badge-text">credit pack</div>
                          </div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_2">{{studentCredit.studentCreditPack.student.givenName}} {{studentCredit.studentCreditPack.student.familyName}}</li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssa_number_2">{{studentCredit.studentCreditPack.creditsRemaining}} Credits</li>
                                <li> Remaining</li>
                              </ul>
                            </li>
                            <li>(Expires {{studentCredit.studentCreditPack.expiryDate | worldDate: 'd MMM yyyy'}})</li>
                          </ul>
                        </div>

                        <div class="span-buttons">
                          <a [routerLink]="[]" queryParamsHandling="preserve" (click)="renewCredit(studentCredit)" class="btn btn-block btn-blue">{{getRenewText(studentCredit)}}</a>
                        </div>
                      </div>


                      <div class="row row-upcoming" *ngIf="studentCredit.studentCreditPack.nextBooking != null">
                        <div class="span-graphic">
                          <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(studentCredit.studentCreditPack.nextBooking.bookedClass.actualInstructor.id)}"></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>Upcoming Sessions</li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssu_date_full_1" *ngIf="studentCredit.studentCreditPack.nextBooking != null">{{studentCredit.studentCreditPack.nextBooking.bookedClass.startDate | worldDate: 'EEEE d MMM yyyy'}}</li>
                                <li *ngIf="studentCredit.studentCreditPack.nextBooking != null"> at </li>
                                <li id="ssu_time_1" *ngIf="studentCredit.studentCreditPack.nextBooking != null">{{studentCredit.studentCreditPack.nextBooking.bookedClass.startDate | worldDate: 'hh:mma'}}</li>
                                <li class="btn-more-wrap">
                                  <button
                                    type="button"
                                    class="btn btn-more btn-green"
                                    data-toggle="collapsable"
                                    data-target="other_upcoming_lessons"
                                    (click)="studentCredit.studentCreditPack.toggleFutureBookings = !studentCredit.studentCreditPack.toggleFutureBookings"
                                    *ngIf="studentCredit.studentCreditPack.bookings != null && getNumberOfMoreFutureBookings(studentCredit.studentCreditPack) > 0"
                                  >
                                    view <span id="csl_number_1">{{getNumberOfMoreFutureBookings(studentCredit.studentCreditPack)}}</span> more
                                  </button>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline" *ngIf="studentCredit.studentCreditPack.nextBooking != null">
                                <li id="sul_type_1" *ngIf="studentCredit.studentCreditPack.nextBooking.bookedClass.programLevel != null">{{studentCredit.studentCreditPack.nextBooking.bookedClass.programLevel.programLevelName}}</li>
                                <li> with </li>
                                <li id="student_teacher_1">{{studentCredit.studentCreditPack.nextBooking.bookedClass.actualInstructor.givenName}}</li>
                                <li> at </li>
                                <li id="sul_location_1">{{studentCredit.studentCreditPack.nextBooking.bookedClass.venueName}}</li>
                              </ul>
                              <div id="other_upcoming_lessons" class="hidden-info toggler" *ngIf="studentCredit.studentCreditPack.bookings != null" [ngClass]="studentCredit.studentCreditPack.toggleFutureBookings ? 'active' : ''">
                                <ul class="list-inline" *ngFor="let booking of getMoreFutureBookings(studentCredit.studentCreditPack)">
                                  <li id="sul_type_1">{{booking.bookedClass.startDate | worldDate: 'd MMM yyyy h:mma'}}</li>
                                  <li> for level </li>
                                  <li id="sul_type_1" *ngIf="booking.bookedClass.programLevel != null">>{{booking.bookedClass.programLevel.programLevelName}}</li>
                                  <li> with </li>
                                  <li id="student_teacher_1">{{booking.bookedClass.actualInstructor.givenName}}</li>
                                  <li> at </li>
                                  <li id="sul_location_1">{{booking.bookedClass.venueName}}</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button">
                          <button
                            type="button"
                            class="btn btn-list"
                            data-toggle="collapsable"
                            data-target="other_upcoming_lessons"
                          >
                            <span class="svg-icon dashboard-list"><svg class="svg-dashboard-list"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-list"></use></svg></span>						</button>
                        </div>
                      </div>


                      <div class="row row-completed" *ngIf="studentCredit.studentCreditPack.pastBooking != null">
                        <div class="span-graphic">
                          <div class="graphic-image"><span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>Completed Lessons</li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssu_date_full_1" *ngIf="studentCredit.studentCreditPack.pastBooking != null">{{studentCredit.studentCreditPack.pastBooking.bookedClass.startDate | worldDate: 'EEEE d MMM yyyy'}}</li>
                                <li *ngIf="studentCredit.studentCreditPack.pastBooking != null"> at </li>
                                <li id="ssu_time_1" *ngIf="studentCredit.studentCreditPack.pastBooking != null">{{studentCredit.studentCreditPack.pastBooking.bookedClass.startDate | worldDate: 'hh:mma'}}</li>
                                <li class="btn-more-wrap">
                                  <button
                                    type="button"
                                    class="btn btn-more btn-blue-dark"
                                    data-toggle="collapsable"
                                    data-target="other_completed_lessons"
                                    (click)="studentCredit.studentCreditPack.togglePastBookings = !studentCredit.studentCreditPack.togglePastBookings"
                                    *ngIf="studentCredit.studentCreditPack.bookings != null && getNumberOfMorePastBookings(studentCredit.studentCreditPack) > 0"
                                  >
                                    view <span id="csl_number">{{getNumberOfMorePastBookings(studentCredit.studentCreditPack)}}</span> more
                                  </button>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline" *ngIf="studentCredit.studentCreditPack.pastBooking != null">
                                <li id="sul_type_1" *ngIf="studentCredit.studentCreditPack.pastBooking.bookedClass.programLevel != null">{{studentCredit.studentCreditPack.pastBooking.bookedClass.programLevel.programLevelName}}</li>
                                <li> with </li>
                                <li id="student_teacher_1">{{studentCredit.studentCreditPack.pastBooking.bookedClass.actualInstructor.givenName}}</li>
                                <li> at </li>
                                <li id="sul_location_1">{{studentCredit.studentCreditPack.pastBooking.bookedClass.venueName}}</li>
                              </ul>
                              <div id="other_completed_lessons" class="hidden-info toggler" *ngIf="studentCredit.studentCreditPack.bookings != null" [ngClass]="studentCredit.studentCreditPack.togglePastBookings ? 'active' : ''">
                                <ul class="list-inline" *ngFor="let booking of getMorePastBookings(studentCredit.studentCreditPack)">
                                  <li id="sul_type_1">{{booking.bookedClass.startDate | worldDate: 'd MMM yyyy h:mma'}}</li>
                                  <li> for level </li>
                                  <li id="sul_type_1" *ngIf="booking.bookedClass.programLevel != null">{{booking.bookedClass.programLevel.programLevelName}}</li>
                                  <li> with </li>
                                  <li id="student_teacher_1">{{booking.bookedClass.actualInstructor.givenName}}</li>
                                  <li> at </li>
                                  <li id="sul_location_1">{{booking.bookedClass.venueName}}</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div class="credits-table">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>type</th>
                            <th>purchased</th>
                            <th>price</th>
                            <th>conditions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td id="type_1">{{studentCredit.studentCreditPack.creditPack.numberOfLessons}} x {{studentCredit.studentCreditPack.creditPack.name}}</td>
                            <td id="purchased_1">{{studentCredit.studentCreditPack.purchaseDate | worldDate: 'd MMM yyyy'}}</td>
                            <td id="price_1">{{studentCredit.studentCreditPack.lineItem.totalAmount | currency:studentCredit.studentCreditPack.lineItem.currency:'symbol-narrow'}}</td>
                            <td id="conditions_1">
                              {{getPrograms(studentCredit.studentCreditPack)}}, {{studentCredit.studentCreditPack.creditPack.numberOfStudentsPerInstructor}}:1 ratio, {{studentCredit.studentCreditPack.creditPack.classDurationInMinutes}} lesson minutes, {{studentCredit.studentCreditPack.creditPack.noticePeriodMinutes}} minutes mandtory non-attendance notice period.</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <!--
              ---	expired credits
              -->
              <div class="wgt wgt-dashboard wgt-credits" *ngIf="expiredCreditPacks != null">
                <div class="card" *ngFor="let studentCredit of expiredCreditPacks">

                  <div class="item-single">
                    <div class="container">

                      <div class="row row-expired">
                        <div class="span-graphic">
                          <div class="graphic-image"><span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>
                              <ul class="list-inline">
                                <li id="student_full_name_2">{{studentCredit.student.givenName}} {{studentCredit.student.familyName}}’s Expired Class Credits</li>
                              </ul>
                            </li>
                            <li>(Expired {{studentCredit.expiryDate | worldDate: 'd MMM yyyy'}})</li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button">
                          <button
                            type="button"
                            class="btn btn-toggler"
                            data-toggle="collapsable"
                            data-target="other_expired_lessons"
                            [ngClass]="studentCredit.toggleViewExpiredDetails ? 'active' : ''"
                            (click)="studentCredit.toggleViewExpiredDetails = !studentCredit.toggleViewExpiredDetails"
                          >
                            <span class="svg-icon caret"><svg class="svg-caret"><use xlink:href="./assets/sprite/spritemap.svg#sprite-caret"></use></svg></span>						</button>
                        </div>
                      </div>


                      <div class="row row-upcoming" *ngIf="studentCredit.nextBooking != null" >
                        <div class="span-graphic">
                          <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(studentCredit.nextBooking.bookedClass.actualInstructor.id)}"></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>Upcoming Sessions</li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssu_date_full_1" *ngIf="studentCredit.nextBooking != null">{{studentCredit.nextBooking.bookedClass.startDate | worldDate: 'EEEE d MMM yyyy'}}</li>
                                <li *ngIf="studentCredit.nextBooking != null"> at </li>
                                <li id="ssu_time_1" *ngIf="studentCredit.nextBooking != null">{{studentCredit.nextBooking.bookedClass.startDate | worldDate: 'hh:mma'}}</li>
                                <li class="btn-more-wrap">
                                  <button
                                    type="button"
                                    class="btn btn-more btn-green"
                                    data-toggle="collapsable"
                                    data-target="other_upcoming_lessons"
                                    (click)="studentCredit.toggleFutureBookings = !studentCredit.toggleFutureBookings"
                                    *ngIf="studentCredit.bookings != null && getNumberOfMoreFutureBookings(studentCredit) > 0"
                                  >
                                    view <span id="csl_number_1">{{getNumberOfMoreFutureBookings(studentCredit)}}</span> more
                                  </button>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline" *ngIf="studentCredit.nextBooking != null">
                                <li id="sul_type_1" *ngIf="studentCredit.nextBooking.bookedClass.programLevel != null">{{studentCredit.nextBooking.bookedClass.programLevel.programLevelName}}</li>
                                <li> with </li>
                                <li id="student_teacher_1">{{studentCredit.nextBooking.bookedClass.actualInstructor.givenName}}</li>
                                <li> at </li>
                                <li id="sul_location_1">{{studentCredit.nextBooking.bookedClass.venueName}}</li>
                              </ul>
                              <div id="other_upcoming_lessons" class="hidden-info toggler" *ngIf="studentCredit.bookings != null" [ngClass]="studentCredit.toggleFutureBookings ? 'active' : ''">
                                <ul class="list-inline" *ngFor="let booking of getMoreFutureBookings(studentCredit)">
                                  <li id="sul_type_1">{{booking.bookedClass.startDate | worldDate: 'd MMM yyyy h:mma'}}</li>
                                  <li> for level </li>
                                  <li id="sul_type_1" *ngIf="booking.bookedClass.programLevel != null">{{booking.bookedClass.programLevel.programLevelName}}</li>
                                  <li> with </li>
                                  <li id="student_teacher_1">{{booking.bookedClass.actualInstructor.givenName}}</li>
                                  <li> at </li>
                                  <li id="sul_location_1">{{booking.bookedClass.venueName}}</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="span-buttons icon-button">
                          <button
                            type="button"
                            class="btn btn-list"
                            data-toggle="collapsable"
                            data-target="other_upcoming_lessons"
                          >
                            <span class="svg-icon dashboard-list"><svg class="svg-dashboard-list"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-list"></use></svg></span>						</button>
                        </div>
                      </div>


                      <div class="row row-completed" *ngIf="studentCredit.pastBooking != null && studentCredit.toggleViewExpiredDetails">
                        <div class="span-graphic">
                          <div class="graphic-image"><span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span></div>
                        </div>

                        <div class="span-content">
                          <ul class="list-content">
                            <li>Completed Lessons</li>
                            <li>
                              <ul class="list-inline">
                                <li id="ssu_date_full_1" *ngIf="studentCredit.pastBooking != null">{{studentCredit.pastBooking.bookedClass.startDate | worldDate: 'EEEE d MMM yyyy'}}</li>
                                <li *ngIf="studentCredit.pastBooking != null"> at </li>
                                <li id="ssu_time_1" *ngIf="studentCredit.pastBooking != null">{{studentCredit.pastBooking.bookedClass.startDate | worldDate: 'hh:mma'}}</li>
                                <li class="btn-more-wrap">
                                  <button
                                    type="button"
                                    class="btn btn-more btn-blue-dark"
                                    data-toggle="collapsable"
                                    data-target="other_completed_lessons"
                                    (click)="studentCredit.togglePastBookings = !studentCredit.togglePastBookings"
                                    *ngIf="studentCredit.bookings != null && getNumberOfMorePastBookings(studentCredit) > 0"
                                  >
                                    view <span id="csl_number">{{getNumberOfMorePastBookings(studentCredit)}}</span> more
                                  </button>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <ul class="list-inline" *ngIf="studentCredit.pastBooking != null">
                                <li id="sul_type_1" *ngIf="studentCredit.pastBooking.bookedClass.programLevel != null">{{studentCredit.pastBooking.bookedClass.programLevel.programLevelName}}</li>
                                <li> with </li>
                                <li id="student_teacher_1">{{studentCredit.pastBooking.bookedClass.actualInstructor.givenName}}</li>
                                <li> at </li>
                                <li id="sul_location_1">{{studentCredit.pastBooking.bookedClass.venueName}}</li>
                              </ul>
                              <div id="other_completed_lessons" class="hidden-info toggler" *ngIf="studentCredit.bookings != null" [ngClass]="studentCredit.togglePastBookings ? 'active' : ''">
                                <ul class="list-inline" *ngFor="let booking of getMorePastBookings(studentCredit)">
                                  <li id="sul_type_1">{{booking.bookedClass.startDate | worldDate: 'd MMM yyyy h:mma'}}</li>
                                  <li> for level </li>
                                  <li id="sul_type_1" *ngIf="booking.bookedClass.programLevel != null">{{booking.bookedClass.programLevel.programLevelName}}</li>
                                  <li> with </li>
                                  <li id="student_teacher_1">{{booking.bookedClass.actualInstructor.givenName}}</li>
                                  <li> at </li>
                                  <li id="sul_location_1">{{booking.bookedClass.venueName}}</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div class="credits-table" *ngIf="studentCredit.toggleViewExpiredDetails">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>type</th>
                            <th>purchased</th>
                            <th>price</th>
                            <th>conditions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td id="type_1">{{studentCredit.creditPack.numberOfLessons}} x {{studentCredit.creditPack.name}}</td>
                            <td id="purchased_1">{{studentCredit.purchaseDate | worldDate: 'd MMM yyyy'}}</td>
                            <td id="price_1">{{studentCredit.lineItem.totalAmount | currency:studentCredit.lineItem.currency:'symbol-narrow'}}</td>
                            <td id="conditions_1">
                              {{getPrograms(studentCredit)}}, {{studentCredit.creditPack.numberOfStudentsPerInstructor}}:1 ratio, {{studentCredit.creditPack.classDurationInMinutes}} lesson minutes, {{studentCredit.creditPack.noticePeriodMinutes}} minutes mandtory non-attendance notice period.</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </main>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
