import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Account} from '../../../_model/account';
import {DirectDebitResponse} from '../../../_model/direct-debit-response';
import {ApiService} from '../../../api.service';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import moment from 'moment/moment';

@Component({
  selector: 'app-dashboard-subscription-subscription',
  templateUrl: './dashboard-subscription-subscription.component.html',
  styleUrls: ['./dashboard-subscription-subscription.component.css']
})
export class DashboardSubscriptionSubscriptionComponent implements OnInit, OnDestroy {

  @Input() account: Account;
  recurringBillingItems: DirectDebitResponse[];

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
              private applicationSettingsService: ApplicationSettingsService,
              private router: Router) { }

  ngOnInit(): void {
    this.apiService.getRecurringBilling(this.account.id, null, this.ngUnsubscribe).subscribe(recurringBillingItems => {
      this.recurringBillingItems = recurringBillingItems;
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  getMandateDescription(item: DirectDebitResponse): string {
    const accountDetails = [];
    if (item.mandate.bankName != null && item.mandate.bankName !== '') {
      accountDetails.push(item.mandate.bankName);
    }

    if (item.mandate.sortCode != null && item.mandate.sortCode !== '') {

      let sortCode;
      if (item.mandate.sortCode.length === 6) {
        sortCode = item.mandate.sortCode.substring(0, 2) + "-" + item.mandate.sortCode.substring(2, 4) + "-" + item.mandate.sortCode.substring(4, 6);
      } else {
        sortCode = item.mandate.sortCode;
      }

      accountDetails.push(sortCode);
    }

    if (item.mandate.trailingFourAccountDigits != null && item.mandate.trailingFourAccountDigits !== '') {
      accountDetails.push("****" + item.mandate.trailingFourAccountDigits);
    }

    if (item.mandate.customerName != null && item.mandate.customerName !== '') {
      accountDetails.push("(" + item.mandate.customerName + ")");
    }

    return accountDetails.join(" ");
  }

  getCreditCardName(item: DirectDebitResponse): string {
    let cardString = item.card.cardType + ': ';

    for (let i = 0; i < item.card.cardLength - item.card.trailingDigits.length; i++) {
      cardString += '*';
    }

    cardString += item.card.trailingDigits;

    cardString += ' exp: ' + moment(item.card.expiry).format('MM/YYYY');

    return cardString;
  }

  editDirectDebitDetails(recurringBillingItem: DirectDebitResponse): void {
    this.router.navigate(['/dashboard_edit_direct_debit', this.account.id, recurringBillingItem.paymentType, recurringBillingItem.paymentType === 'direct_debit' ? recurringBillingItem.mandate.sportsAccountPaymentGatewayMandateId : recurringBillingItem.card.sportsAccountPaymentGatewayCardId]);
  }
}
