<header>

  <header class="header-wrap">
    <div class="banner type-dashboard">
      <div class="container">

        <div class="row row-action">

          <div class="span-back" *ngIf="enrolment != null">
            <button type="button" class="btn btn-back" [routerLink]="['/dashboard_booking_transfer', enrolment.enrolmentId]">
              <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
            </button>
          </div>
          <div class="span-info" *ngIf="enrolment != null">
            <h2 class="title">
              transfer booking					</h2>
            <div class="info">
              <ul class="list-inline">
                <li id="student_lesson" *ngIf="enrolment.course.programLevel != null">{{enrolment.course.programLevel.programName}}’s {{enrolment.course.programLevel.programLevelName}}</li>
                <li> with </li>
                <li id="student_teacher">{{enrolment.course.instructorGivenName}}</li>
                <li> at </li>
                <li id="term_venue">{{enrolment.course.venueName}}</li>
                <li> on </li>
                <li id="term_day">{{enrolment.course.fullDayOfWeekNames}}</li>
                <li> at </li>
                <li id="term_time">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
              </ul>
            </div>
          </div>

          <div class="span-cancel">
            <button type="button" class="btn btn-cancel" [routerLink]="['/dashboard_bookings']">
        <span class="label">
        	cancel
        </span>
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>    </button>
          </div>
        </div>

      </div>
    </div>
  </header>

</header>
<app-alert></app-alert>
<!-- if its a dashboard create a wrapper for dashboard -->

<!-- if its NOT a booking action or managing users / card details create a markup for fluid layout -->


<div class="wrap">
  <main class="main">
    <section class="wgt wgt-find-courses">
      <div class="container">

        <form method="post" id="booking_confirm" novalidate="novalidate" class="form-dashboard" [formGroup]="transferForm" (ngSubmit)="onTransferSubmit()">
          <div class="panel panel-dashboard">

            <div class="span-title">
              <h2 class="entry-title">
                Are you ready to transfer?
              </h2>
            </div>

            <div class="row row-cards">

              <div class="span-card" *ngIf="enrolment != null">
                <div class="card style-checked transfer-from">
                  <div class="span-choice">
                    <label class="input-check">

                      <div class="span-graphic" *ngIf="enrolment.course.instructorHasPhoto">
                        <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(enrolment.course.instructorId)}"></div>
                      </div>

                      <span class="radio-content">
						<ul class="label-description">
							<li class="title">from</li>
							<li class="details">
								<ul class="list-inline">
									<li id="student_term_0">{{enrolment.course.termDescription}}</li>
									<li> with </li>
									<li id="student_teacher_0">{{enrolment.course.instructorGivenName}}</li>
								</ul>
							</li>
							<li class="details">
								<ul class="list-inline">
									<li id="term_time_0">{{enrolment.course.startTimeDate | worldDate: 'h:mma'}}</li>
									<li> on </li>
									<li id="term_day_0">{{enrolment.course.fullDayOfWeekNames}}</li>
									<li> at </li>
									<li id="term_venue_0">{{enrolment.course.venueName}}</li>
								</ul>
							</li>
						</ul>
					</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="span-arrow" *ngIf="enrolment != null">
                <span class="svg-icon dashboard-arrow"><svg class="svg-dashboard-arrow"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-arrow"></use></svg></span>	</div>

              <div class="span-card" *ngIf="course != null">
                <div class="card style-checked transfer-to">
                  <div class="span-choice">
                    <label class="input-check">

                      <div class="span-graphic" *ngIf="course.instructorHasPhoto">
                        <div class="graphic-image" [ngStyle]="{'background-image': getInstructorImageUrl(course.instructorId)}"></div>
                      </div>

                      <span class="radio-content">
                        <ul class="label-description">
                          <li class="title">to</li>
                          <li class="details">
                            <ul class="list-inline">
                              <li id="student_term_1">{{course.termDescription}}</li>
                              <li> with </li>
                              <li id="student_teacher_1">{{course.instructorGivenName}}</li>
                            </ul>
                          </li>
                          <li class="details">
                            <ul class="list-inline">
                              <li id="term_time_1">{{course.startTimeDate | worldDate: 'h:mma'}}</li>
                              <li> on </li>
                              <li id="term_day_1">{{course.fullDayOfWeekNames}}</li>
                              <li> at </li>
                              <li id="term_venue_1">{{course.venueName}}</li>
                            </ul>
                          </li>
                          <li class="title" *ngIf="transferPreview != null">start date</li>
                          <li class="details" id="transfer_start_date" *ngIf="transferPreview != null">{{transferPreview.startDate | worldDate: 'd MMM yyyy'}}</li>
                          <li class="title" *ngIf="transferPreview != null">pricing</li>
                          <li class="details" id="transfer_pricing" *ngIf="transferPreview != null">{{transferPreview.totalAmount | currency:transferPreview.currencyCode:'symbol-narrow'}}</li>
                          <li class="title" *ngIf="transferPreview != null && transferPreview.reasons.length > 0">reasons</li>
                          <span *ngIf="transferPreview != null">
                          <li class="details" id="transfer_reason" *ngFor="let reason of transferPreview.reasons">{{reason}}</li>
                          </span>
                          <li class="title restriction" *ngIf="course.activeRestrictions.length > 0">restrictions</li>
                          <span *ngIf="course.activeRestrictions.length > 0">
                          <li class="details restriction" id="restriction" *ngFor="let restriction of course.activeRestrictions">{{restriction.description}}</li>
                          </span>
                          <span *ngIf="venuePolicies != null">
                            <span *ngFor="let policy of venuePolicies">
                              <li class="title policy-title">{{policy.title}}</li>
                              <li class="details" id="policy-{{policy.id}}">
                                <div class="row row-policy-text" [innerHTML]="policy.policy">
                                </div>
                              </li>
                              <li class="details">
                                <div class="transfer-confirm">
                                  <div class="span-input span-checkbox required">
                                    <label
                                      for="policy_{{policy.id}}"
                                      class="input-check checkbox policy-checkbox">
                                      <input
                                        id="policy_{{policy.id}}"
                                        name="policy_{{policy.id}}"
                                        type="checkbox"
                                        required="required"
                                        style="display:none;"
                                        class="input-checkbox"
                                        formControlName="policyCheckbox_{{policy.id}}"
                                        appParsleyValidation
                                        [submitted]="submitted"
                                        [elementName]="policy.title"
                                        [requireChecked]="true"
                                      />
                                      <span>
                                    I accept the terms above.
                                  </span>
                                      <i class="asterisk">*</i>
                                      <i class="checkmark"></i>
                                    </label>
                                    <ul class="parsley-errors-list filled"></ul>
                                  </div>
                                </div>
                              </li>
                            </span>
                          </span>
                        </ul>
                      </span>
                    </label>
                  </div>

                  <div class="transfer-confirm">
                  <div class="span-input span-checkbox required" *ngIf="transferPreview != null && transferPreview.totalAmount === 0 && termsAndConditions != null">
                    <label
                      for="enrolmentTerms"
                      class="input-check checkbox">
                      <input
                        id="enrolmentTerms"
                        name="enrolmentTerms"
                        type="checkbox"
                        required="required"
                        class="input-checkbox"
                        formControlName="enrolmentTermsCheckbox"
                        style="display:none;"
                        appParsleyValidation
                        [submitted]="submitted"
                        [elementName]="'Terms and Conditions'"
                        [requireChecked]="true"
                      />
                      <span>
			I accept the <a target="_blank" [routerLink]="['/terms_and_conditions']">terms & conditions</a>.
		</span>
                      <i class="asterisk">*</i>
                      <i class="checkmark"></i>
                    </label>
                    <ul [ngClass]="submitted && transferForm.invalid ? 'transfer-error-padding' : ''" class="parsley-errors-list filled"></ul>
                  </div>

                  <button *ngIf="course != null && enrolment != null && transferPreview != null" [ngClass]="running || isError || transferForm.invalid ? 'disabled' : ''" type="submit" class="btn btn-block btn-green-alt" >
                    confirm transfer
                  </button>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </form>

      </div>
    </section>


  </main>
</div>

<app-footer></app-footer>
