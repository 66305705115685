import {Params} from '@angular/router';

export function convertUrl(fullUrl: string, additionalParams: {key: string, value: any}[]): UrlResponse {
  let redirectUrl = fullUrl;

  const keys: string[] = [];

  additionalParams.forEach(item => {
    keys.push(item.key);
  });

  const queryParams = [];
  if (fullUrl.includes('?')) {
    const questionSplit = fullUrl.split('?');
    redirectUrl = questionSplit[0];
    if (questionSplit.length === 2) {
      const params = questionSplit[1].split('&');
      if (params != null) {
        for (const param of params) {
          const values = param.split('=');
          if (values.length === 2 && !keys.includes(values[0])) {
            queryParams[values[0]] = values[1];
          }
        }
      }
    }
  }

  if (!redirectUrl.startsWith('/')) {
    redirectUrl = '/' + redirectUrl;
  }

  additionalParams.forEach(item => {
    queryParams[item.key] = item.value;
  });

  const response = new UrlResponse();
  response.page = redirectUrl;
  response.queryParams = queryParams;
  return response;
}

export class UrlResponse {
  page: string;
  queryParams: Params;
}
