import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable, throwError} from 'rxjs';
import {SocialLink} from './_model/social-link';
import {FooterLink} from './_model/footer-link';
import set = Reflect.set;

@Injectable()
export class ApplicationSettingsService {
  companyName: string;
  companyTelephone: string;
  countryCode: string;
  locale: string;
  localeLanguage: string;
  currency: string;
  googleAnalyticsTrackingCode: string;
  facebookPixelId: string;
  facebookToken: string;
  timezone: string;
  timezoneOffset: string;
  hasLogo: boolean;
  hasInverseLogo: boolean;
  socialLinks: SocialLink[];
  footerLinks: FooterLink[];
  websiteUrl: string;
  contactUrl: string;
  tagline: string;
  masterLicenseeId: string;
  autoGeneratePendingPayments: boolean;
  enforceHowDidYouHear: boolean;
  enforceLeadSource: boolean;
  distanceMeasurement: string;
  forceProgramSelection: boolean;
  autoEnrolmentEnabled: boolean;
  cookieYesIntegration: boolean;
  cookieYesUrl: string;

  public initialized = false;
  public loading = true;

  constructor(private apiService: ApiService) {
  }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.masterLicenseeSettings().subscribe(settings => {
        this.masterLicenseeId = settings.masterLicenseeId;
        this.companyName = settings.companyName;
        this.companyTelephone = settings.companyTelephone;
        this.countryCode = settings.countryCode;
        this.locale = settings.locale;
        this.localeLanguage = settings.localeLanguage;
        this.currency = settings.currency;
        this.googleAnalyticsTrackingCode = settings.googleAnalyticsTrackingCode;
        this.facebookPixelId = settings.facebookPixelId;
        this.facebookToken = settings.facebookToken;
        this.timezone = settings.timezone;
        this.timezoneOffset = settings.timezoneOffset;
        this.hasLogo = settings.hasLogo;
        this.hasInverseLogo = settings.hasInverseLogo;
        this.socialLinks = settings.socialLinks;
        this.footerLinks = settings.footerLinks;
        this.websiteUrl = settings.websiteUrl;
        this.contactUrl = settings.contactUrl;
        this.tagline = settings.tagline;
        this.autoGeneratePendingPayments = settings.autoGeneratePendingPayments;
        this.enforceHowDidYouHear = settings.enforceHowDidYouHear;
        this.enforceLeadSource = settings.enforceLeadSource;
        this.distanceMeasurement = settings.distanceMeasurement;
        this.forceProgramSelection = settings.forceProgramSelection;
        this.autoEnrolmentEnabled = settings.autoEnrolmentEnabled;
        this.cookieYesIntegration = settings.cookieYesIntegration;
        this.cookieYesUrl = settings.cookieYesUrl;

        this.loading = false;
        this.initialized = true;
        resolve(true);
      }, error => {
        this.loading = false;
        this.initialized = false;
        resolve(error);
        return throwError(error || 'Server error');
      });
    });
  }
}
