import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Basket} from '../_model/basket';
import {LoggedInUser} from '../_model/logged-in-user';
import {Address} from '../_model/address';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  currentShowConfirmationEmail: Observable<boolean>;
  private showConfirmationEmailSubject = new BehaviorSubject<boolean>(null);

  currentStep: Observable<number>;
  private stepSubject = new BehaviorSubject<number>(null);

  currentLoggedInPerson: Observable<LoggedInUser>;
  private loggedInPersonSubject = new BehaviorSubject<LoggedInUser>(null);

  currentAddress: Observable<Address>;
  private addressSubject = new BehaviorSubject<Address>(null);

  currentVoucherCode: Observable<string>;
  private voucherCodeSubject = new BehaviorSubject<string>(null);

  currentClearSessionBasket: Observable<boolean>;
  private clearSessionBasketSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentShowConfirmationEmail = this.showConfirmationEmailSubject.asObservable();
    this.currentStep = this.stepSubject.asObservable();
    this.currentLoggedInPerson = this.loggedInPersonSubject.asObservable();
    this.currentAddress = this.addressSubject.asObservable();
    this.currentVoucherCode = this.voucherCodeSubject.asObservable();
    this.currentClearSessionBasket = this.clearSessionBasketSubject.asObservable();
  }

  showConfirmationEmail(show: boolean) {
    this.showConfirmationEmailSubject.next(show);
  }

  setStepNumber(stepNumber: number) {
    this.stepSubject.next(stepNumber);
  }

  setLoggedInPerson(loggedInUser: LoggedInUser) {
    this.loggedInPersonSubject.next(loggedInUser);
  }

  setAddress(address: Address) {
    this.addressSubject.next(address);
  }

  setVoucherCode(voucherCode: string) {
    this.voucherCodeSubject.next(voucherCode);
  }

  clearSessionBasket() {
    this.clearSessionBasketSubject.next(true);
  }
}
