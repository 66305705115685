<div class="lead">
  <h1 class="title">Account Settings - {{account.accountNumber}}</h1>
</div>

<div class="wgt wgt-dashboard wgt-account-card-details" *ngIf="cards != null">
  <div class="card">

    <div class="item-single item-primary" *ngFor="let card of cards">
      <div class="container">
        <div class="row">

          <div class="span-graphic" *ngIf="card.isPrimary">
            <div class="badge-wrap green">
              <div class="badge-text">primary card</div>
            </div>
          </div>

          <div class="span-graphic" *ngIf="!card.isPrimary">
            <div class="badge-wrap red">
              <div class="badge-text">saved cards</div>
            </div>
          </div>

          <div class="span-content">
            <div class="row row-table">
              <div class="span-details">
                <ul class="list-content">
                  <li>Card Details</li>
                  <li>
                    <ul class="list-inline">
                      <li>{{getCreditCardStars(card)}}</li>
                      <li id="ccn_last_1">{{card.trailingDigits}}</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="span-expires">
                <ul class="list-content">
                  <li>Expires</li>
                  <li id="cce_date_1">{{card.expiry | worldDate: 'MM/yy'}}</li>
                </ul>
              </div>
            </div>
            <ul class="list-content">
              <li>Billing Details</li>
              <li id="user_postcode_1">{{card.cardAddress.postCode}}</li>
            </ul>
          </div>

          <div class="span-buttons icon-button">
            <button type="button" class="btn btn-star-full" (click)="setCardAsPrimary(card)">
              <span class="svg-icon dashboard-star-full" *ngIf="card.isPrimary"><svg class="svg-dashboard-star-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-star-full"></use></svg></span>
              <span class="svg-icon dashboard-star" *ngIf="!card.isPrimary"><svg class="svg-dashboard-star"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-star"></use></svg></span>
            </button>
            <a [routerLink]="[]" queryParamsHandling="preserve" (click)="editCardDetails(card)" class="btn btn-pencil">
              <span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-pencil"></use></svg></span>						</a>
            <button type="button" (click)="removeCardDetails(card)" class="btn btn-x-circle">
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>						</button>
          </div>

        </div>
      </div>
    </div>

    <div class="item-single item-other" *ngIf="account.licenseeId != null">
      <div class="container">

        <div class="span-add">
          <a [routerLink]="['/dashboard_add_card',account.id]" class="btn btn-add">
            <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>						add a new card
          </a>
        </div>

      </div>
    </div>

  </div>
</div>




<div class="wgt wgt-dashboard wgt-account-card-details" *ngIf="mandates != null">
  <div class="card">

    <div class="item-single item-primary" *ngFor="let mandate of mandates">
      <div class="container">
        <div class="row">

          <div class="span-graphic" *ngIf="mandate.isDefault">
            <div class="badge-wrap green">
              <div class="badge-text">primary direct debit</div>
            </div>
          </div>

          <div class="span-graphic" *ngIf="!mandate.isDefault">
            <div class="badge-wrap red">
              <div class="badge-text">direct debit</div>
            </div>
          </div>

          <div class="span-content">
            <div class="row row-table">
              <div class="span-details">
                <ul class="list-content">
                  <li>Account Number</li>
                  <li>
                    <ul class="list-inline">
                      <li>****</li>
                      <li id="ccn_last_1">{{mandate.trailingFourAccountDigits}}</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="span-expires">
                <ul class="list-content">
                  <li>Sort Code / Bank</li>
                  <li id="cce_date_1">{{mandate.sortCode != null ? mandate.sortCode : mandate.bankName}}</li>
                </ul>
              </div>
            </div>
            <ul class="list-content">
              <li>Account Name</li>
              <li id="user_postcode_1">{{mandate.customerName}}</li>
            </ul>
          </div>

          <div class="span-buttons icon-button">
            <button type="button" class="btn btn-star-full" (click)="setMandateAsPrimary(mandate)">
              <span class="svg-icon dashboard-star-full" *ngIf="mandate.isDefault"><svg class="svg-dashboard-star-full"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-star-full"></use></svg></span>
              <span class="svg-icon dashboard-star" *ngIf="!mandate.isDefault"><svg class="svg-dashboard-star"><use xlink:href="./assets/sprite/spritemap.svg#sprite-dashboard-star"></use></svg></span>
            </button>
            <a [routerLink]="[]" *ngIf="mandate.mandateUrl != null && mandate.mandateUrl !== ''" queryParamsHandling="preserve" (click)="viewDirectDebitGuarantee(mandate)" class="btn btn-pencil">
              <span class="svg-icon dashboard-pencil"><svg class="svg-dashboard-pencil"><use xlink:href="./assets/sprite/spritemap.svg#direct-debit-icon"></use></svg></span>						</a>
            <button type="button" (click)="removeMandate(mandate)" class="btn btn-x-circle">
              <span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>						</button>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>





<div class="wgt wgt-account-marketing" *ngIf="autoEnrolEnabled">

  <div class="card">
    <div class="table-responsive">
      <table class="table table-striped">
        <tbody>
        <tr>
          <td>
            <label
              for="auto_renewal"
              class="input-check checkbox">
              <input
                id="auto_renewal"
                name="auto_renewal"
                type="checkbox"
                style="display: none"
                [(ngModel)]="account.autoRenewal"
                #autoRenew
                (click)="updateAutoRenewal(autoRenew.checked)"
              />
              <span>
									<span class="svg-icon check-circle"><svg class="svg-check-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-check-circle"></use></svg></span>									<span class="svg-icon x-circle"><svg class="svg-x-circle"><use xlink:href="./assets/sprite/spritemap.svg#sprite-x-circle"></use></svg></span>									Auto-renew students in account {{account.accountNumber}}
								</span>
            </label>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
