import {NewEnrolmentStudentResponse} from './new-enrolment-student-response';
import {PendingPayment} from './pending-payment';
import {SportsPayment} from './sports-payment';

export class NewEnrolmentResponse {

  totalAmount: number;
  sportsPayment: SportsPayment;
  studentEnrolments: NewEnrolmentStudentResponse[];
  currency: string;
  result: string;
  requiresPaymentAction: boolean;
  error: string;
  paymentIntentClientSecret: string;
  directDebitPendingPayment: PendingPayment;
  accountBalance: number;
  totalAmountWithBalance: number;
  voucherUsed: boolean;
  voucherCode: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.sportsPayment != null
        && this.sportsPayment.payment != null
        && this.sportsPayment.payment.paymentDate != null
        && (typeof this.sportsPayment.payment.paymentDate === 'string'
            || typeof this.sportsPayment.payment.paymentDate === 'number')) {
        this.sportsPayment.payment.paymentDate = new Date(this.sportsPayment.payment.paymentDate);
      }
    }
  }
}
