  <div class="card" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onInView($event)">
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="courseSelected(null)">
      <div class="thumb" *ngIf="courseResult.course.venueImageExists">
        <span [ngStyle]="{'background-image': getVenueImageUrl()}"></span>
      </div>
      <div class="content" [ngClass]="!courseResult.course.venueImageExists ? 'course-content-no-banner' : ''">
        <h3 class="location-title">
          {{courseResult.course.venueName}}
        </h3>

        <ul class="location-info" *ngIf="(mode === 'new' || mode === 'referral_student') && coursePriceList != null">
          <li>{{courseResult.course.fullDayOfWeekNames}}<span *ngIf="courseResult.course.termType === 'WEEKLY'">s</span> @ {{courseResult.course.startTimeDate | worldDate:'HH:mm'}}</li>
          <li>{{courseResult.course.numberOfStudentsPerInstructor}}:1 - {{courseResult.course.classDurationInMinutes}}mins</li>
          <li *ngIf="priceType !== 'MONTHLY_DIRECT_DEBIT' && priceType !== 'MONTHLY_CREDIT_CARD'">From {{coursePriceList.startDate | worldDate:'d MMM yy'}} ({{coursePriceList.numberOfWeeksToEnrol}} Lessons<span *ngIf="courseResult.course.courseType === 'FLEXIBLE'"> Available</span>)</li>
          <li *ngIf="priceType === 'MONTHLY_DIRECT_DEBIT' || priceType === 'MONTHLY_CREDIT_CARD'">From {{coursePriceList.startDate | worldDate:'d MMM yy'}}</li>
          <li class="price" *ngIf="courseResult.availableToBook">From {{coursePriceAmount | currency:coursePriceList.currencyCode:'symbol-narrow'}} a {{coursePriceFrequency}}</li>
          <span *ngFor="let restriction of courseResult.course.activeRestrictions; let i = index" class="restriction-span">
            <li class="restriction">
              <div class="restriction-alert">
              <svg class="exclamation-mark">
                          <title>Course Restriction</title>
                          <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                        </svg>
              </div>
              <div class="restriction-name">
              {{restriction.onlineSummary}}
                </div>
            </li>
          </span>
        </ul>
        <ul class="location-info" *ngIf="mode === 'credit' || mode === 'intensive-credit' || mode === 'transfer'">
          <li>{{courseResult.course.fullDayOfWeekNames}} @ {{courseResult.course.startTimeDate | worldDate:'HH:mm'}}</li>
          <li>{{courseResult.course.numberOfStudentsPerInstructor}}:1 - {{courseResult.course.classDurationInMinutes}}mins</li>
          <li *ngIf="mode === 'credit' || mode === 'transfer'">{{courseResult.classDate | worldDate:'d MMM yy'}}</li>
          <li *ngIf="mode === 'intensive-credit'">Starting {{courseResult.classDate | worldDate:'d MMM yy'}}</li>
          <span *ngFor="let restriction of courseResult.course.activeRestrictions; let i = index" class="restriction-span">
            <li class="restriction">
              <div class="restriction-alert">
              <svg class="exclamation-mark">
                          <title>Course Restriction</title>
                          <use xlink:href="./assets/sprite/spritemap.svg#sprite-exclamation-mark"></use>
                        </svg>
              </div>
              <div class="restriction-name">
              {{restriction.onlineSummary}}
                </div>
            </li>
          </span>
        </ul>
        <div class="course-loader" *ngIf="(mode === 'new' || mode === 'referral_student') && coursePriceList == null"></div>
      </div>
    </a>
    <span *ngIf="multipleMode">
    <button (click)="courseSelected(studentResult.id)" *ngFor="let studentResult of courseResult.courseSearchStudents; let i = index" type="button" class="selected" [ngClass]="{ 'selected-bottom': i===(courseResult.courseSearchStudents.length-1), 'selected-top': i===0, 'course-unavailable': !studentResult.canEnrol, 'in-basket': isStudentInBasket(studentResult, courseResult.course.id) }">
      <svg *ngIf="isStudentInBasket(studentResult, courseResult.course.id)" class="sprite-tick student-in-basket-tick"><use xlink:href="./assets/sprite/spritemap.svg#sprite-tick"></use></svg>
      {{getStudentName(studentResult.id)}}
    </button>
    </span>
    <div class="result-disabled" *ngIf="!courseResult.availableToBook"></div>
  </div>

  <div class="modal" *ngIf="multipleMode && showMultipleSelection">

    <div class="wgt wgt-add-student">
      <div class="container">


        <div class="span-back">
          <button type="button" class="btn btn-back" (click)="showMultipleSelection = false">
            <span class="svg-icon arrow-circle"><svg class="svg-arrow-circle"><use xlink:href="/assets/sprite/spritemap.svg#sprite-arrow-circle"></use></svg></span>        <span class="label">
        	back        </span>
          </button>
        </div>

        <div class="span-title">
          <h2 class="entry-title">
            Which student(s) you would like to add to this course?
          </h2>
          <div class="entry-content" *ngIf="coursePriceList.numberOfPlacesAvailable === 1">
            <p class="available">
              ( Only 1 place is available )
            </p>
          </div>
        </div>

        <div class="row row-cards">

          <div class="span-card row-element-centred" *ngFor="let studentResult of courseResult.courseSearchStudents">
            <div class="card" *ngIf="canShowStudent(studentResult, courseResult.course.id)">

              <div class="span-choice">
                <label
                  for="student_pick_{{studentResult.id}}"
                  class="input-check radio">
                  <input
                    style="display: none"
                    id="student_pick_{{studentResult.id}}"
                    type="checkbox"
                    name="student_pick"
                    value="{{studentResult.id}}"
                    [attr.disabled]="!studentResult.canEnrol ? true : null"
                    (click)="courseClicked(studentResult.id)"
                  />
                  <span class="radio-content student-name">
									{{getStudentName(studentResult.id)}}
								</span>
                </label>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
