import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {Router} from '@angular/router';
import {Student} from '../../_model/student';
import {ModuleAchievement} from '../../_model/module-achievement';
import {DashboardAddStudentService} from '../dashboard-add-student/dashboard-add-student.service';
import {TitleGenerator} from '../../title-generator';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard-students',
  templateUrl: './dashboard-students.component.html',
  styleUrls: ['./dashboard-students.component.scss']
})
export class DashboardStudentsComponent implements OnInit, OnDestroy {

  students: Student[];

  addStudent = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  newStudentIdSubscription: Subscription;
  showAddingSubscription: Subscription;

  studentsLoaded: boolean;

  constructor(private titleService: TitleGenerator,
              private renderer: Renderer2,
              private authService: AuthService,
              private apiService: ApiService,
              private dashboardAddStudentService: DashboardAddStudentService,
              private router: Router) {
    this.studentsLoaded = false;
  }

  loadStudents(): void {
    this.students = [];
    this.apiService.getLoggedInUserStudents(this.ngUnsubscribe).subscribe(students => {
      this.students = students;
      this.studentsLoaded = true;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Student Progression');

    this.loadStudents();

    this.newStudentIdSubscription = this.dashboardAddStudentService.currentNewStudentId.subscribe(studentId => {
      if (studentId != null) {
        this.addStudent = false;
        this.loadStudents();
      }
    });

    this.showAddingSubscription = this.dashboardAddStudentService.currentShowAddingStudent.subscribe(showAdding => {
      if (showAdding != null) {
        this.addStudent = showAdding;
      }
    });

    this.renderer.addClass(document.body, 'dashboard-students');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.newStudentIdSubscription != null) {
      this.newStudentIdSubscription.unsubscribe();
    }

    if (this.showAddingSubscription != null) {
      this.showAddingSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-students');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  showAddStudent() {
    this.addStudent = true;
  }
}
