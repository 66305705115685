import {Injectable, isDevMode} from '@angular/core';
import {BasketStudent} from './_model/basket-student';
import {Basket} from './_model/basket';
import {Course} from './_model/course';
import {ProgramLevel} from './_model/program-level';
import moment from 'moment';
import {ApplicationSettingsService} from './application-settings.service';
import {FacebookConversionService} from './facebook-conversion.service';

declare let gtag: any;
declare let fbq: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private applicationSettings: ApplicationSettingsService,
              private facebookConversionService: FacebookConversionService) { }

  public login() {
    try {
      this.eventEmitter('login', null, null, null, null);
    } catch (err) {
      console.error(err);
    }
  }

  public promotionView(promotionId: string, promotionName) {
    try {
      if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
        const eventValues = {
          promotions: [
            {
              id: promotionId,
              name: promotionName
            }
          ]
        };
        if (!isDevMode()) {
          gtag('event', 'select_content', eventValues);
        } else {
          console.log('Google event track [select_content]: ', eventValues);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  public search(programLevelName: string, searchType: string) {
    try {
      this.eventEmitter('search', null, null, searchType, null, programLevelName);
    } catch (err) {
      console.error(err);
    }

    if (this.applicationSettings.facebookPixelId != null && this.applicationSettings.facebookPixelId !== '') {
      if (!isDevMode()) {
        fbq('track', 'Search');
      } else {
        console.log('Facebook track [Search]');
      }
    }

    try {
      this.facebookConversionService.logFindLocation('New Enrolment');
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public accountCreated() {
    try {
          this.facebookConversionService.logLead('New Enrolment');
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public addToCart(basketStudent: BasketStudent, currency: string, listName: string) {
    try {
      this.setGoogleTagSingleItem('add_to_cart', basketStudent, listName);
    } catch (err) {
      console.error(err);
    }

    if (this.applicationSettings.facebookPixelId != null && this.applicationSettings.facebookPixelId !== '') {
      const params = {
        value: basketStudent.coursePrice.price,
        currency,
        content_category: this.getCourseItemName(basketStudent.course)
      };
      if (!isDevMode()) {
        fbq('track', 'AddToCart', params);
      } else {
        console.log('Facebook track [AddToCart]: ', params);
      }
    }

    try {
      this.facebookConversionService.logAddToCart(basketStudent, listName);
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public addToCartForCourse(course: Course,
                            programLevel: ProgramLevel,
                            numberOfWeeksToEnrol: number,
                            price: number,
                            currency: string,
                            listName: string,
                            listPosition: number) {
    try {
      this.setGoogleTagSingleItemForCourse('add_to_cart',
        course,
        programLevel,
        listName,
        listPosition,
        numberOfWeeksToEnrol,
        price);
    } catch (err) {
      console.error(err);
    }

    if (this.applicationSettings.facebookPixelId != null && this.applicationSettings.facebookPixelId !== '') {
      const params = {
        value: price,
        currency,
        content_category: this.getCourseItemName(course)
      };
      if (!isDevMode()) {
        fbq('track', 'AddToCart', params);
      } else {
        console.log('Facebook track [AddToCart]: ', params);
      }
    }
  }

  public removeFromCart(basketStudent: BasketStudent, listName: string) {
    try {
      this.setGoogleTagSingleItem('remove_from_cart', basketStudent, listName);
    } catch (err) {
      console.error(err);
    }
  }

  public viewCourse(course: Course, programLevel: ProgramLevel, listPosition: number, listName: string) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      try {
        const viewItem = {
          items: [
            {
              id: course.id,
              name: this.getCourseItemName(course),
              list_name: listName,
              brand: programLevel == null ? '' : programLevel.programName,
              variant: programLevel == null ? '' : programLevel.programLevelName,
              list_position: listPosition,
            }
          ]
        };

        if (!isDevMode()) {
          gtag('event', 'view_item', viewItem);
        } else {
          console.log('Google event track [view_item]: ', viewItem);
        }
      } catch (err) {
        console.error(err);
      }
    }

    try {
      this.facebookConversionService.logViewContent(listName);
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public beginCheckout(basket: Basket, listName: string) {
    try {
      this.sendGoogleBasketEvent('begin_checkout', basket, listName);
    } catch (err) {
      console.error(err);
    }

    try {
      this.facebookConversionService.logStartCheckout(basket, listName);
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public checkoutProgress(basket: Basket, listName: string) {
    try {
      this.sendGoogleBasketEvent('checkout_progress', basket, listName);
    } catch (err) {
      console.error(err);
    }
  }

  public beginCheckoutForCourse(course: Course,
                                programLevel: ProgramLevel,
                                numberOfWeeksToEnrol: number,
                                price: number,
                                listName: string,
                                listPosition: number) {
    try {
      this.sendGoogleCourseEvent('begin_checkout',
        course,
        programLevel,
        numberOfWeeksToEnrol,
        price,
        listName,
        listPosition);
    } catch (err) {
      console.error(err);
    }
  }

  public payment(basket: Basket, receiptId: string, amount: number, listName: string) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      try {
        const itemsList = this.getBasketItemsList(basket, listName);
        const purchase = {
          transaction_id: receiptId,
          affiliation: 'FLOW Enrol',
          value: amount,
          currency: basket.currencyCode,
          tax: 0,
          shipping: 0,
          items: itemsList
        };

        if (!isDevMode()) {
          gtag('event', 'purchase', purchase);
        } else {
          console.log('Google event track [purchase]: ', purchase);
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (this.applicationSettings.facebookPixelId != null && this.applicationSettings.facebookPixelId !== '') {
      for (const basketStudent of basket.students) {
        const params = {
          content_name: this.getCourseItemName(basketStudent.course),
          content_type: listName,
          currency: basket.currencyCode,
          num_items: 1,
          value: basketStudent.coursePrice.price
        };
        if (!isDevMode()) {
          fbq('track', 'AddToCart', params);
        } else {
          console.log('Facebook track [AddToCart]: ', params);
        }
      }
    }

    try {
        this.facebookConversionService.logPurchaseConversion(basket, receiptId, amount, listName);
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  public paymentForCourse(course: Course,
                          programLevel: ProgramLevel,
                          receiptId: string,
                          amount: number,
                          price: number,
                          currencyCode: string,
                          listName: string,
                          listPosition: number) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      try {
        const itemsList = [{
            id: course.id,
            name: this.getCourseItemName(course),
            list_name: listName,
            brand: programLevel.programName,
            variant: programLevel.programLevelName,
            list_position: listPosition,
            price
        }];

        const purchase = {
          transaction_id: receiptId,
          affiliation: 'FLOW Enrol',
          value: amount,
          currency: currencyCode,
          tax: 0,
          shipping: 0,
          items: itemsList
        };

        if (!isDevMode()) {
          gtag('event', 'purchase', purchase);
        } else {
          console.log('Google event track [purchase]: ', purchase);
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (this.applicationSettings.facebookPixelId != null && this.applicationSettings.facebookPixelId !== '') {
      const params = {
        content_name: this.getCourseItemName(course),
        content_type: listName,
        currency: currencyCode,
        num_items: 1,
        value: price
      };
      if (!isDevMode()) {
        fbq('track', 'AddToCart', params);
      } else {
        console.log('Facebook track [AddToCart]: ', params);
      }
    }
  }

  public addPaymentMethod(basket: Basket, amount: number, listName: string) {
    try {
        this.facebookConversionService.logAddPaymentInfo(basket, amount, listName);
    } catch (err) {
      console.error('Failed to log Facebook event', err)
    }
  }

  private sendGoogleCourseEvent(eventName: string,
                                course: Course,
                                programLevel: ProgramLevel,
                                numberOfWeeksToEnrol: number,
                                price: number,
                                listName: string,
                                listPosition: number) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      const itemsList = [{
          id: course.id,
          name: this.getCourseItemName(course),
          list_name: listName,
          brand: programLevel.programName,
          variant: programLevel.programLevelName,
          list_position: listPosition,
          quantity: numberOfWeeksToEnrol,
          price
      }];

      const basketEvent = {
        items: itemsList
      };

      if (!isDevMode()) {
        gtag('event', eventName, basketEvent);
      } else {
        console.log('Google event track [' + eventName + ']: ', basketEvent);
      }
    }
  }

  private sendGoogleBasketEvent(eventName: string, basket: Basket, listName: string) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      const itemsList = this.getBasketItemsList(basket, listName);
      const basketEvent = {
        items: itemsList,
        coupon: basket.voucherCode
      };

      if (!isDevMode()) {
        gtag('event', eventName, basketEvent);
      } else {
        console.log('Google event track [' + eventName + ']: ', basketEvent);
      }
    }
  }

  private getCourseItemName(course: Course): string {
    const time = moment(course.endTimeDate).format('HH:mm');
    return time + ' on ' + course.fullDayOfWeekNames + ' @ ' + course.venueName + ' with ' + course.instructorGivenName;
  }

  private getBasketItemsList(basket: Basket, listName: string) {
    const itemsList = [];
    for (const basketStudent of basket.students) {
      itemsList.push({
        id: basketStudent.course.id,
        name: this.getCourseItemName(basketStudent.course),
        list_name: listName,
        brand: basketStudent.program.programName,
        variant: basketStudent.student.programLevelName,
        list_position: basketStudent.listPosition,
        quantity: basketStudent.numberOfWeeksToEnrol,
        price: basketStudent.coursePrice.price
      });
    }

    return itemsList;
  }

  private setGoogleTagSingleItem(eventName: string, basketStudent: BasketStudent, listName: string) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      const eventItems = [
        {
          id: basketStudent.course.id,
          name: this.getCourseItemName(basketStudent.course),
          list_name: listName,
          brand: basketStudent.program.programName,
          variant: basketStudent.student.programLevelName,
          list_position: basketStudent.listPosition,
          quantity: basketStudent.numberOfWeeksToEnrol,
          price: basketStudent.coursePrice.price
        }
      ];

      if (!isDevMode()) {
        gtag('event', eventName, {
          items: eventItems
        });
      } else {
        console.log('Google event track [' + eventName + ']: ', eventItems);
      }
    }
  }

  private setGoogleTagSingleItemForCourse(eventName: string,
                                          course: Course,
                                          programLevel: ProgramLevel,
                                          listName: string,
                                          listPosition: number,
                                          numberOfWeeksToEnrol: number,
                                          price: number) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      const eventItems = [
        {
          id: course.id,
          name: this.getCourseItemName(course),
          list_name: listName,
          brand: programLevel.programName,
          variant: programLevel.programLevelName,
          list_position: listPosition,
          quantity: numberOfWeeksToEnrol,
          price
        }
      ];

      if (!isDevMode()) {
        gtag('event', eventName, {
          items: eventItems
        });
      } else {
        console.log('Google event track [' + eventName + ']: ', eventItems);
      }
    }
  }

  private eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
    searchTerm: string = null) {
    if (this.applicationSettings.googleAnalyticsTrackingCode != null) {
      const eventValues = {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue,
        searchTerm
      };
      if (!isDevMode()) {
        gtag('event', eventName, eventValues);
      } else {
        console.log('Google event track [' + eventName + ']: ', eventValues);
      }
    }
  }
}
