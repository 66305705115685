import {PendingPaymentInvoice} from './pending-payment-invoice';
import {Person} from './person';
import {SportsPayment} from './sports-payment';
import {Refund} from './refund';
import {RefundReason} from './refund-reason';
import {convertObjectToDate} from '../_helpers/date_helper';
import {Enrolment} from './enrolment';

export class PendingPayment {
  pendingPaymentId: string;
  reference: string;
  isPaid: boolean;
  pendingPaymentInvoices: PendingPaymentInvoice[];
  status: string;
  expiry: Date;
  expiryAction: string;
  type: string;
  amount: number;
  receiptName: string;
  receiptDescription: string;
  processedBy: Person;
  directDebitPaymentId: string;
  directDebitStatus: string;
  direction: string;
  directDebitPaymentDate: Date;
  directDebitRefundPaymentId: string;
  payments: SportsPayment[];
  refunds: Refund[];
  refundReason: RefundReason;
  reason: string;
  cause: string;
  retries: number;
  currencyCode: string;
  pendingPaymentCreationDate: Date;
  sportsAccountId: string;
  canCancelAndWithdraw: boolean;
  enrolment: Enrolment;
  recurringBillingType: string;
  recurringBillingPaymentId: string;
  updatedPaymentMethod: boolean;
  subscriptionStatus: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      this.expiry = convertObjectToDate(this.expiry);
      this.pendingPaymentCreationDate = convertObjectToDate(this.pendingPaymentCreationDate);
      this.directDebitPaymentDate = convertObjectToDate(this.directDebitPaymentDate);
      if (this.pendingPaymentInvoices != null) {
        this.pendingPaymentInvoices.forEach(pendingPaymentInvoice => {
          pendingPaymentInvoice.enrolmentExpiryStartDate = convertObjectToDate(pendingPaymentInvoice.enrolmentExpiryStartDate);
        });
      }
    }
  }
}
