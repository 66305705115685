import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {WithdrawalReason} from '../../_model/withdrawal-reason';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../../title-generator';
import {DashboardService} from '../dashboard.service';
import {environment} from '../../../environments/environment';
import {SubscriptionCancellationResponse} from '../../_model/subscription-cancellation-response';

@Component({
  selector: 'app-dashboard-subscription-cancel',
  templateUrl: './dashboard-subscription-cancel.component.html',
  styleUrls: ['./dashboard-subscription-cancel.component.css']
})
export class DashboardSubscriptionCancelComponent implements OnInit, OnDestroy {

  withdrawalForm: UntypedFormGroup;

  enrolmentId: string;
  enrolment: EnrolmentResponse;
  withdrawalPreview: SubscriptionCancellationResponse;
  withdrawalReasons: WithdrawalReason[];
  selectedWithdrawalText: string;
  running = false;
  submitted = false;
  referrer: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private dashboardService: DashboardService,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2) {}

  setPaymentValidators() {
    let withdrawalReasonValidator = null;

    if (this.selectedWithdrawalText === 'Other') {
      withdrawalReasonValidator = [Validators.required];
    }

    this.withdrawalForm.get('withdrawalReasonOtherInput').setValidators(withdrawalReasonValidator);
    this.withdrawalForm.get('withdrawalReasonOtherInput').updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard Booking Cancel');

    this.route.params.subscribe(params => {
      this.enrolmentId = params.id;

      this.apiService.getWithdrawalReasons(this.enrolmentId, this.ngUnsubscribe).subscribe(reasons => this.withdrawalReasons = reasons);
    });

    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });

    this.withdrawalForm = this.formBuilder.group({
      withdrawalReasonSelect: ['', Validators.required],
      withdrawalReasonOtherInput: ['']
    });

    this.renderer.addClass(document.body, 'dashboard-booking-cancel');
    this.renderer.addClass(document.body, 'dashboard-action');

    this.apiService.getEnrolment(this.enrolmentId, this.ngUnsubscribe).subscribe(enrolment => {
      this.enrolment = enrolment;

      this.checkValidProvisional();
    });

    this.apiService.previewSubscriptionWithdrawal(this.enrolmentId, this.ngUnsubscribe).subscribe(withdrawalPreview => {
      console.log(withdrawalPreview);
      this.withdrawalPreview = withdrawalPreview;
    });
  }

  checkValidProvisional() {
    if (this.enrolment.enrolmentType !== 'MONTHLY') {
      // We have reloaded the page under a different login, or the request no longer exists
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-booking-cancel');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  updateWithdrawalReasonText(value: string) {
    this.selectedWithdrawalText = value;
    this.setPaymentValidators();
  }

  getWithdrawalControl(componentName: string) {
    return this.withdrawalForm.get(componentName);
  }

  onWithdrawalFormSubmit(): void {
    this.submitted = true;

    if (this.running || this.withdrawalForm.invalid) {
      return;
    }

    this.running = true;

    const selectedWithdrawalId = this.withdrawalForm.get('withdrawalReasonSelect').value;
    const selectedWithdrawalReasonOther = this.withdrawalForm.get('withdrawalReasonOtherInput').value;

    this.apiService.subscriptionWithdrawal(this.enrolmentId, selectedWithdrawalId, selectedWithdrawalReasonOther, this.withdrawalPreview.cancellationEndDate, this.ngUnsubscribe).subscribe(() => {
      this.router.navigate(['/dashboard_bookings']);
      this.dashboardService.setNewConfirmationMessage('Subscription cancelled');
    }, () => {
      this.running = false;
    });
  }

  goBack(): void {
    if (this.referrer == null) {
      this.referrer = '/dashboard_bookings';
    }

    this.router.navigate([this.referrer]);
  }
}
