import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../../auth-service';
import {ApiService} from '../../../api.service';
import {DashboardService} from '../../dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Card} from '../../../_model/card';
import {TitleGenerator} from '../../../title-generator';
import {Subject} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-dashboard-edit-card',
  templateUrl: './dashboard-edit-card.component.html',
  styleUrls: ['./dashboard-edit-card.component.css']
})
export class DashboardEditCardComponent implements OnInit, OnDestroy {

  cardId: string;
  updating = false;
  card: Card;

  years: number[];

  editCardForm: UntypedFormGroup;
  submitted = false;

  confirmDelete = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private dashboardService: DashboardService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private titleService: TitleGenerator,
              private renderer: Renderer2) {}

  ngOnInit(): void {
    this.titleService.setTitle('Edit Card');

    this.editCardForm = this.formBuilder.group({
      expiryMonthInput: ['', Validators.required],
      expiryYearInput: ['', Validators.required],
      cardholderNameInput: ['', Validators.required],
      isPrimaryCheckbox: [''],
      postcodeLookupInput: [''],
      postcodeLookupHouseNumberInput: [''],
      addressLine1Input: ['', Validators.required],
      addressLine2Input: [''],
      addressLine3Input: [''],
      addressCityInput: ['', Validators.required],
      addressCountyInput: ['', Validators.required],
      addressCountryInput: ['', Validators.required],
      addressPostcodeInput: ['', Validators.required],
      postcodeResultsSelect: ['']
    });

    this.route.params.subscribe(params => {
      this.cardId = params.id;
    });

    this.route.queryParams
      .subscribe(params => {
        if (params.action != null && params.action === 'remove') {
          this.confirmDelete = true;
        }
      });

    this.years = [];

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 100; i++) {
      this.years.push(i);
    }

    this.renderer.addClass(document.body, 'dashboard-edit-payment-card-details');
    this.renderer.addClass(document.body, 'dashboard-account');
    this.renderer.addClass(document.body, 'dashboard-action');

    if (this.card == null) {
      this.apiService.getCard(this.cardId, this.ngUnsubscribe).subscribe(result => {
        this.setFormData(result);
        this.card = result;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  setFormData(card: Card) {
    this.editCardForm.patchValue({
      expiryMonthInput: card.expiry.getMonth() + 1,
      expiryYearInput: card.expiry.getFullYear(),
      isPrimaryCheckbox: card.isPrimary,
      cardholderNameInput: card.cardholderName
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.renderer.removeClass(document.body, 'dashboard-edit-payment-card-details');
    this.renderer.removeClass(document.body, 'dashboard-account');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getCreditCardNumber(): string {
    if (this.card.cardLength === 16) {
      return '**** **** **** ' + this.card.trailingDigits;
    } else if (this.card.cardLength === 15) {
      return '**** ****** *' + this.card.trailingDigits;
    }

    let returnString = '';
    for (let i = 0; i < this.card.cardLength; i++) {
      returnString += '*';
    }

    return returnString + this.card.trailingDigits;
  }

  onCardFormSubmit(): void {
    this.submitted = true;

    if (this.updating || this.editCardForm.invalid) {
      return;
    }

    this.updating = true;

    const expiryMonth = this.editCardForm.get('expiryMonthInput').value - 1;
    const expiryYear = this.editCardForm.get('expiryYearInput').value;

    const date = moment().set(
      {year: expiryYear,
        month: expiryMonth,
        date: 1
      });

    this.card.expiry = date.toDate();
    this.card.cardholderName = this.editCardForm.get('cardholderNameInput').value;
    this.card.cardAddress.addressLine1 = this.editCardForm.get('addressLine1Input').value;
    this.card.cardAddress.addressLine2 = this.editCardForm.get('addressLine2Input').value;
    this.card.cardAddress.addressLine3 = this.editCardForm.get('addressLine3Input').value;
    this.card.cardAddress.locality = this.editCardForm.get('addressCityInput').value;
    this.card.cardAddress.state = this.editCardForm.get('addressCountyInput').value;
    this.card.cardAddress.postCode = this.editCardForm.get('addressPostcodeInput').value;
    this.card.cardAddress.country = this.editCardForm.get('addressCountryInput').value;
    this.card.isPrimary = this.editCardForm.get('isPrimaryCheckbox').value;

    this.apiService.updateCard(this.card).subscribe(() => {
      this.router.navigate(['/dashboard_account_settings']);
      this.dashboardService.setNewConfirmationMessage('Card updated');
    }, error => {
      this.updating = false;
    });
  }

  deleteCard(): void {
    this.apiService.deleteCard(this.card).subscribe(() => {
      this.router.navigate(['/dashboard_account_settings']);
      this.dashboardService.setNewConfirmationMessage('Card removed');
    });
  }
}
