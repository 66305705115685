import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Account} from '../../../_model/account';
import {ApiService} from '../../../api.service';
import {Card} from '../../../_model/card';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {UpdateAccountRequest} from '../../../_model/update-account-request';
import {ApplicationSettingsService} from '../../../application-settings.service';
import {Mandate} from '../../../_model/mandate';

@Component({
  selector: 'app-dashboard-account-settings-account',
  templateUrl: './dashboard-account-settings-account.component.html',
  styleUrls: ['./dashboard-account-settings-account.component.css']
})
export class DashboardAccountSettingsAccountComponent implements OnInit, OnDestroy {

  @Input() account: Account;
  cards: Card[];
  mandates: Mandate[];
  autoEnrolEnabled = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService,
              private applicationSettingsService: ApplicationSettingsService,
              private router: Router) { }

  ngOnInit(): void {
    this.loadCards();
    this.loadMandates();

    this.autoEnrolEnabled = this.applicationSettingsService.autoEnrolmentEnabled;
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  loadCards() {
    this.apiService.findCardsForAccount(this.account.id, null, this.ngUnsubscribe).subscribe(result => {
      this.cards = result;
    });
  }

  loadMandates() {
    this.apiService.findMandatesForAccount(this.account.id, this.ngUnsubscribe).subscribe(result => {
      this.mandates = result;
    });
  }

  getCreditCardStars(card: Card): string {
    if (card.cardLength === 16) {
      return '**** **** **** ';
    } else if (card.cardLength === 15) {
      return '**** ****** *';
    }

    let returnString = '';
    for (let i = 0; i < card.cardLength; i++) {
      returnString += '*';
    }

    return returnString;
  }

  editCardDetails(card: Card): void {
    this.router.navigate(['/dashboard_edit_card', card.sportsAccountPaymentGatewayCardId]);
  }

  removeCardDetails(card: Card): void {
    const action = 'remove';
    this.router.navigate(['/dashboard_edit_card', card.sportsAccountPaymentGatewayCardId], {queryParams: {action}});
  }

  setCardAsPrimary(card: Card) {
    if (card.isPrimary) {
      return;
    }

    card.isPrimary = true;
    this.apiService.updateCard(card).subscribe(() => {
      this.loadCards();
    });
  }

  updateAutoRenewal(value: boolean) {
    const updateAccountRequest = new UpdateAccountRequest();
    updateAccountRequest.autoRenew = value;

    this.apiService.updateAccount(this.account.id, updateAccountRequest).subscribe(account => {
      this.account = account;
    });
  }

  setMandateAsPrimary(mandate: Mandate): void {
    if (mandate.isDefault) {
      return;
    }

    mandate.isDefault = true;
    this.apiService.updateMandate(mandate).subscribe(() => {
      this.loadMandates();
    });
  }

  viewDirectDebitGuarantee(mandate: Mandate): void {
    window.open(mandate.mandateUrl, "_blank");
  }

  removeMandate(mandate: Mandate): void {
    this.apiService.cancelMandate(mandate.sportsAccountPaymentGatewayMandateId).subscribe(() => {
      this.loadMandates();
    });
  }
}
