import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseSearchResult} from '../../_model/course-search-result';
import {ApiService} from '../../api.service';
import {CoursePriceList} from '../../_model/course-price-list';
import {CourseSearchStudent} from '../../_model/course-search-parameters';
import {CourseListStudentDetailsFilterService} from '../course-list-student-details-filter/course-list-student-details-filter-service.service';
import {Router} from '@angular/router';
import {CourseSingleService} from '../course-single/course-single.service';
import {CourseListBasketService} from '../course-list-basket/course-list-basket.service';
import {SportsCredit} from '../../_model/sports-credit';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {SessionService} from '../../session.service';
import {CourseSearchStudentResult} from '../../_model/course-search-student-result';
import {AlertService} from '../../alert';

@Component({
  selector: 'app-course-list-course',
  templateUrl: './course-list-course.component.html',
  styleUrls: ['./course-list-course.component.css']
})
export class CourseListCourseComponent implements OnInit, OnDestroy {

  @Input() courseResult: CourseSearchResult;
  @Input() multipleMode: boolean;
  @Input() courseResults: CourseSearchResult[];
  @Input() mode: string;
  @Input() enrolment: EnrolmentResponse;
  @Input() credit: SportsCredit;

  coursePriceList: CoursePriceList;
  coursePriceAmount: number;
  coursePriceFrequency: string;
  priceType: string;

  loadedContent = false;
  loadingContent = false;
  showMultipleSelection = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private courseListStudentDetailsFilterService: CourseListStudentDetailsFilterService,
              private courseSingleService: CourseSingleService,
              private courseListBasketService: CourseListBasketService,
              private apiService: ApiService,
              private alertService: AlertService,
              private router: Router,
              private sessionService: SessionService) {}

  ngOnInit(): void {
    if (this.mode === 'transfer' && this.enrolment.course.id === this.courseResult.course.id) {
      this.courseResult.availableToBook = false;
    }
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  onInView({ target, visible }: { target: Element; visible: boolean }): void {
    if ((this.mode === 'new' || this.mode === 'referral_student') && visible && !this.loadedContent && !this.loadingContent) {
      this.loadCoursePrice();
    } else if ((this.mode === 'new' || this.mode === 'referral_student') && !visible && !this.loadedContent && this.loadingContent) {
      this.loadingContent = false;
      this.ngUnsubscribe.next();
    }
  }

  loadCoursePrice(): void {
    // The pricing model uses levels to work out the price, which means that we would have
    // to do the search "per student"
    this.loadingContent = true;
    this.courseResult.courseSearchStudents.forEach(value => {
      this.sessionService.courseSearchParameters.students.forEach(student => {
        if (student.id === value.id) {
          this.loadPrices(this.courseResult.course.id, student.programLevelId, student.dateOfBirth);
        }
      });
    });
  }

  loadPrices(courseId: string, programLevelId: string, dateOfBirth: Date) {
    // Never set the student Id for search results, otherwise it will check for monthly start dates
    this.apiService.getCoursePrices(courseId, programLevelId, null, dateOfBirth, this.ngUnsubscribe).subscribe((data) => {
      this.coursePriceList = data;
      this.loadingContent = false;
      this.loadedContent = true;

      if ((this.mode === 'new' || this.mode === 'transfer' || this.mode === 'referral_student')
        && (data.prices == null || data.prices.length === 0 || data.full)) {
        this.courseResult.availableToBook = false;
      } else {
        this.courseResult.availableToBook = true;

        const price = data.getFirstPrice();
        this.coursePriceAmount = price.price;
        this.priceType = price.priceType;

        if (price.priceType === 'SINGLE_TERM' || price.priceType === 'MULTIPLE_TERMS' || price.priceType === 'CREDIT_PACK') {
          this.coursePriceFrequency = 'lesson';
        } else if (price.priceType === 'MONTHLY_DIRECT_DEBIT' || price.priceType === 'MONTHLY_CREDIT_CARD') {
          this.coursePriceFrequency = price.recurringType.toLowerCase();
        }
      }
    });
  }

  getStudentName(studentId: number): string {
    for (const courseSearchStudent of this.sessionService.courseSearchParameters.students) {
      if (studentId === courseSearchStudent.id) {
        return courseSearchStudent.studentName;
      }
    }

    return null;
  }

  courseSelected(studentId: number) {
    const referrer = this.router.url;
    if (this.mode === 'new' || this.mode === 'referral_student') {
      if (this.coursePriceList == null) {
        // they are clicking before the price has loaded
        return;
      }

      // Need to work out how many students can enrol
      let numberOfStudents = 0;
      this.courseResult.courseSearchStudents.forEach(student => {
        if (studentId != null
          && studentId === student.id
          && (this.sessionService.basket != null
            && this.sessionService.basket.containsBasketItem(this.courseResult.course.id, student.id))) {
          // The student they clicked on is in the basket
          this.courseListBasketService.scrollToBasketId(this.sessionService.basket.getBasketId(this.courseResult.course.id, student.id));
          return;
        }

        if (student.canEnrol && (this.sessionService.basket == null
          || !this.sessionService.basket.containsBasketItem(this.courseResult.course.id, student.id))) {
          numberOfStudents++;
        }
      });

      if (numberOfStudents === 0) {
        return;
      } else if (numberOfStudents > 1) {
        // They need to choose
        this.showMultipleSelection = true;
      } else {
        if (this.courseResult.courseSearchStudents[0].canEnrol) {
          this.navigateToCoursePage(this.courseResult.courseSearchStudents[0].id);
        }
      }
    } else if (this.mode === 'credit') {
      const listPosition = this.courseResult.listPosition;
      this.router.navigate(['/dashboard_booking_credits_use_confirm',
        this.credit.sportsCreditId, this.courseResult.classId], {queryParams: {referrer, listPosition}});
    } else if (this.mode === 'intensive-credit') {
      const listPosition = this.courseResult.listPosition;
      this.router.navigate(['/dashboard_booking_intensive_credits_use_confirm',
        this.credit.sportsCreditId, this.courseResult.course.id], {queryParams: {referrer, listPosition}});
    } else if (this.mode === 'transfer') {
      const listPosition = this.courseResult.listPosition;
      this.router.navigate(['/dashboard_booking_transfer_confirm',
        this.enrolment.enrolmentId, this.courseResult.course.id], {queryParams: {referrer, listPosition}});
    }
  }

  courseClicked(studentId: number) {
    // We can only do one at a time
    this.navigateToCoursePage(studentId);
  }

  navigateToCoursePage(selectedStudentId: number) {
    const student = this.getCourseSearchStudent(selectedStudentId);
    const programId = student.programId;
    const programLevelId = student.programLevelId;
    const studentId = student.studentId;
    const referrer = this.router.url;
    const listPosition = this.courseResult.listPosition;

    if (programId == null) {
      this.alertService.error('Program is not set');
      console.error('Stopped course page redirect with no program id');
      return;
    } else if (programLevelId == null) {
      this.alertService.error('Program level is not set');
      console.error('Stopped course page redirect with no program level id');
      return;
    }

    this.router.navigate(['/course_single', this.courseResult.course.id],
      {queryParams: {selectedStudentId, studentId, programId, programLevelId, referrer, listPosition}});

    this.courseSingleService.setCourseSearchResult(this.courseResult);
    this.courseSingleService.setCoursePriceList(this.coursePriceList);
    this.courseSingleService.setCourseSearchResults(this.courseResults);
  }

  getCourseSearchStudent(id: number): CourseSearchStudent {
    if (typeof id === 'string') {
      id = parseInt(id, 10);
    }

    let foundStudent = null;
    for (const student of this.sessionService.courseSearchParameters.students) {
      if (id === student.id) {
        foundStudent = student;
      }
    }

    return foundStudent;
  }

  getVenueImageUrl(): string {
    return 'url(\'' + environment.apiUrl + 'images/venues/' + this.courseResult.course.venueId + '/image\')';
  }

  canShowStudent(studentResult: CourseSearchStudentResult, courseId: string) {
    return studentResult.canEnrol && (this.sessionService.basket == null
      || !this.sessionService.basket.containsBasketItem(courseId, studentResult.id));
  }

  isStudentInBasket(studentResult: CourseSearchStudentResult, courseId: string): boolean {
    return this.sessionService.basket != null && this.sessionService.basket.containsBasketItem(courseId, studentResult.id);
  }
}
