import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../../title-generator';
import {AlertService} from '../../alert';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-dashboard-waiting-list-expiry',
  templateUrl: './dashboard-waiting-list-expiry.component.html',
  styleUrls: ['./dashboard-waiting-list-expiry.component.css']
})
export class DashboardWaitingListExpiryComponent implements OnInit, OnDestroy, AfterViewInit {

  action: string;
  waitingListPlacementId: string;
  running = true;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private titleService: TitleGenerator,
              private alertService: AlertService,
              private authService: AuthService,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Waiting List Entry Preferences');

    this.route.params.subscribe(params => {
      this.action = params.action;
      this.waitingListPlacementId = params.waitingListPlacementId;
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    if (this.waitingListPlacementId == null) {
      this.alertService.error('Waiting list expiry not found');
    } else if (this.action == null) {
      this.alertService.error('Waiting list expiry action not found');
    } else if (this.action !== 'remove' && this.action !== 'keep') {
      this.alertService.error('Invalid action');
    } else {
      if (this.action === 'remove') {
        this.apiService.removeWaitingListExpiry(this.waitingListPlacementId, this.ngUnsubscribe).subscribe(() => {
          this.running = false;
        });
      } else {
        this.apiService.keepWaitingListExpiry(this.waitingListPlacementId, this.ngUnsubscribe).subscribe(() => {
          this.running = false;
        });
      }
    }
  }
}
