<a [routerLink]="[]" queryParamsHandling="preserve" *ngIf="showLink" (click)="doShowModal()">
<svg [ngClass]="largeIcon ? 'error-exclamation-mark-large' : 'error-exclamation-mark'">
  <title>Log error</title>
  <use xlink:href="./assets/sprite/spritemap.svg#sprite-error-exclamation"></use>
</svg>
</a>
<div *ngIf="showModal">
<div class="error-overlay"></div>
<div class="error-modal">
  <div class="modal-content" [formGroup]="errorForm">
  Issue:
    <textarea
      class="error-input"
      formControlName="errorDetails"></textarea>
    <div class="span-cancel">
      <a [routerLink]="[]" queryParamsHandling="preserve" (click)="showModal=false" class="btn btn-block btn-cancel error-btn">cancel</a>
    </div>
    <div class="span-button">
      <a [routerLink]="[]" queryParamsHandling="preserve" (click)="submitError()" class="btn btn-block btn-red error-btn">submit</a>
    </div>
  </div>
</div>
</div>
