import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, from, Observable, Subject, throwError} from 'rxjs';
import {catchError, filter, finalize, retry, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AlertService} from './alert';
import {getErrorMessage, logError} from './_helpers/http_log_helper';
import {AuthService} from './auth-service';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {FacebookConversionService} from './facebook-conversion.service';
import {environment} from '../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private alertService: AlertService,
              private authService: AuthService,
              private router: Router,
              private facebookConversionService: FacebookConversionService,
              private readonly keycloakService: KeycloakService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let err: HttpErrorResponse;

    if (req.url.startsWith('https://api.pwnedpasswords.com')) {
      return next.handle(req);
    }

    if (req.url.startsWith(this.facebookConversionService.FACEBOOK_URL)) {
      return next.handle(req);
    }

    if (req.url.startsWith("https://api.ipify.org")) {
      return next.handle(req);
    }

    // All requests should have the Enrol API key attached for public data
    return this.appendHeaders(req, next).pipe(
      retry(0),
      tap((response: HttpEvent<any>) => {
        lastResponse = response;
      }),
      catchError((error: HttpErrorResponse) => {
        err = error;

        if (!window.navigator.onLine) {
          console.log('User is offline');
        }

        if (error.status === 0) {
          console.log('Aborted request or connectivity issues [' + getErrorMessage(error) + ']');
          return throwError('Failed to receive response from server');
        } else {
          // If it is Json we know it is from FLOW
          const errorMsg = getErrorMessage(error);
          this.alertService.error(errorMsg);
          console.error(errorMsg, error);
          return throwError(errorMsg);
        }

      }), finalize(() => {
      if (lastResponse != null && lastResponse.type === HttpEventType.Sent && !err) {
        // last response type was 0, and we haven't received an error
        console.log('aborted request');
      }
    }));
  }

  handle401Error(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Received 401 error');

    return this.authService.isLoggedIn().pipe(
      switchMap( isLoggedIn => {
        // Lets see if they are logged in
        if (isLoggedIn) {
          if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Need to see whether the session should be extended
            console.log('Attempting to refresh token');
            return from(this.keycloakService.updateToken()).pipe(
              switchMap(() => {
                console.log('Refresh token complete, retrying query');
                this.tokenSubject.next(localStorage.getItem('id_token'));

                return this.appendHeaders(req, next).pipe(
                  retry(0),
                  catchError((errorMessage: HttpErrorResponse) => {
                    logError(errorMessage);
                    const errorMsg = getErrorMessage(error);
                    this.alertService.error(errorMsg);

                    return throwError(errorMsg);
                  })
                );
              }),
              catchError((e) => {
                // Note that most failed attempts to refresh session will come back as errors and not the boolean above
                console.log('Failed to refresh, setting the user as logged out', e);
                this.tokenSubject.next('error');
                return this.redirectToLogin();
              }), finalize(() => {
                this.isRefreshingToken = false;
              }));
          } else {
            console.log('Already refreshing token, waiting for response: ' + req.urlWithParams);
            return this.tokenSubject.pipe(
              filter(token => token != null),
              take(1),
              takeUntil(this.ngUnsubscribe),
              switchMap(token => {
                if (token === 'error') {
                  // Failed to refresh token, which means that the page is already redirecting to login
                  console.log('Finished waiting, token refresh failed to returning blank response: ' + req.urlWithParams);
                  return new Observable<HttpEvent<any>>((observer) => {
                    observer.next(null);
                  });
                } else {
                  console.log('Finished waiting, received new token, updating request: ' + req.urlWithParams);
                  return this.appendHeaders(req, next);
                }
              })
            );
          }
        } else {
          console.log('User not logged in so navigating to login page');
          // Send them to the login page
          return this.redirectToLogin();
        }
      })
    );
  }

  redirectToLogin(): Observable<HttpEvent<any>> {
    // Let's cancel all other requests
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    this.authService.login();

    // Send back null as we don't need to handle the error
    return new Observable<HttpEvent<any>>((observer) => {
      observer.next(null);
    });
  }

  appendHeaders(req: HttpRequest<any>, next: HttpHandler) {
    let headers: HttpHeaders = new HttpHeaders({
      AUTH_API_KEY: environment.apiKey,
      realm: 'flow-enrol'
    });

    return from(this.keycloakService.isLoggedIn()).pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return from(this.keycloakService.getToken()).pipe(
            switchMap(bearerToken => {
              if (bearerToken != null) {
                headers = new HttpHeaders({
                  AUTH_API_KEY: environment.apiKey, Authorization: 'Bearer ' + bearerToken
                });
              }

              return next.handle(req.clone({headers}));
            }),
            catchError(err => {
              console.error('Failed to obtain token', err);
              return next.handle(req.clone({headers}));
            })
          );
        } else {
          return next.handle(req.clone({headers}));
        }
      })
    );
  }
}
