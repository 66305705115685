import {ProgramLevelAnswer} from './program-level-answer';

export class ProgramLevelQuestion {
  sequence: number;
  programLevelFinderQuestionId: string;
  previousQuestionId: string;
  summary: string;
  heading: string;
  answers: ProgramLevelAnswer[];
  question: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
