import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {Subject} from 'rxjs';
import {AbandonedCartResponse} from '../../_model/abandoned-cart-response';
import {environment} from '../../../environments/environment';
import {CourseSearchParameters, CourseSearchStudent} from '../../_model/course-search-parameters';
import {SessionService} from '../../session.service';
import {VenueOptions} from '../../course-search/select-location/select-location.component';
import {Router} from '@angular/router';
import {DashboardService} from '../dashboard.service';

@Component({
  selector: 'app-dashboard-abandoned-carts',
  templateUrl: './dashboard-abandoned-carts.component.html',
  styleUrls: ['./dashboard-abandoned-carts.component.css']
})
export class DashboardAbandonedCartsComponent implements OnInit, OnDestroy {

  @Input() abandonedCartResponse: AbandonedCartResponse;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  running = false;

  constructor(private apiService: ApiService,
              private sessionService: SessionService,
              private dashboardService: DashboardService,
              private router: Router) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  cancelAbandonedCart() {
    if (this.running) {
      return;
    }

    this.running = true;

    this.apiService.removeAbandonedCart(this.abandonedCartResponse.abandonedCart.abandonedCartId, this.ngUnsubscribe)
      .subscribe(() => {
        this.dashboardService.setReloadAbandonedCarts();
      }, () => {
      this.running = false;
    });
  }

  runSearch(canBook: boolean) {
    const parameters = new CourseSearchParameters();
    const studentParameters = new CourseSearchStudent();

    studentParameters.id = 1;
    studentParameters.studentId = this.abandonedCartResponse.abandonedCart.sportsStudent.id;
    studentParameters.programId = this.abandonedCartResponse.abandonedCart.programLevel.programId;
    studentParameters.programLevelId = this.abandonedCartResponse.abandonedCart.programLevel.id;
    studentParameters.programLevelName = this.abandonedCartResponse.abandonedCart.programLevel.programLevelName;
    studentParameters.studentName = this.abandonedCartResponse.abandonedCart.sportsStudent.givenName
      + ' ' + this.abandonedCartResponse.abandonedCart.sportsStudent.familyName;
    // studentParameters.dateOfBirth = moment(this.student.dateOfBirth).tz(this.applicationSettings.timezone).toDate();
    studentParameters.dateOfBirth = this.abandonedCartResponse.abandonedCart.sportsStudent.dateOfBirth;

    const students = new Array<CourseSearchStudent>();
    students.push(studentParameters);

    const venueOptions = new VenueOptions();
    venueOptions.distance = 5;
    venueOptions.address = '';
    venueOptions.venueId = this.abandonedCartResponse.abandonedCart.course.venueId;

    parameters.students = students;
    parameters.venueOptions = venueOptions;

    this.sessionService.courseSearchParameters = parameters;
    this.sessionService.saveCourseSearchParameters();

    if (!canBook) {
      const runSearch = true;
      this.router.navigate(['courses_list_single'], {queryParams: {runSearch}});
    } else {
      const selectedStudentId = 1;
      const programId = studentParameters.programId;
      const programLevelId = studentParameters.programLevelId;
      const studentId = studentParameters.studentId;
      const referrer = '/courses_list_single';

      this.router.navigate(['/course_single',
        this.abandonedCartResponse.abandonedCart.course.id],
        {queryParams: {selectedStudentId, studentId, programId, programLevelId, referrer}});
    }
  }
}
