import {Instructor} from '../course-search/course-list-student-filter/instructor';
import {ProgramLevel} from './program-level';
import {convertObjectToDate} from '../_helpers/date_helper';

export class Class {
  actualInstructor: Instructor;
  startDate: Date;
  endDate: Date;
  id: string;
  name: string;
  programLevel: ProgramLevel;
  status: string;
  courseId: string;
  venueName: string;
  startDateOffset: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
    this.startDate = convertObjectToDate(this.startDate);
    this.endDate = convertObjectToDate(this.endDate);
  }
}
