import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Basket} from '../../_model/basket';
import {Student} from '../../_model/student';
import {AccountContact} from '../../_model/account-contact';

@Injectable({
  providedIn: 'root'
})
export class DashboardAddStudentService {

  currentNewStudentId: Observable<string>;
  private newStudentIdSubject = new BehaviorSubject<string>(null);

  currentStudentToUpdate: Observable<Student>;
  private studentToUpdateSubject = new BehaviorSubject<Student>(null);

  currentAccountContact: Observable<AccountContact>;
  private accountContactSubject = new BehaviorSubject<AccountContact>(null);

  currentShowAddingStudent: Observable<boolean>;
  private showAddingStudentSubject = new BehaviorSubject<boolean>(null);

  constructor() {
    this.currentNewStudentId = this.newStudentIdSubject.asObservable();
    this.currentStudentToUpdate = this.studentToUpdateSubject.asObservable();
    this.currentAccountContact = this.accountContactSubject.asObservable();
    this.currentShowAddingStudent = this.showAddingStudentSubject.asObservable();
  }

  setNewStudentId(studentId: string) {
    this.newStudentIdSubject.next(studentId);
  }

  setStudentToUpdate(student: Student) {
    this.studentToUpdateSubject.next(student);
  }

  setAccountContact(accountContact: AccountContact) {
    this.accountContactSubject.next(accountContact);
  }

  setShowAddingStudent(addingStudent: boolean) {
    this.showAddingStudentSubject.next(addingStudent);
  }
}
