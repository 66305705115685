import {AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BasketService} from './basket.service';
import {LoggedInUser} from '../_model/logged-in-user';
import {AuthService} from '../auth-service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {TitleGenerator} from '../title-generator';
import {Subject, Subscription} from 'rxjs';
import {SessionService} from '../session.service';
import {GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterContentInit {

  currentStepNumber: number;
  loggedInUser: LoggedInUser;
  isFlexible = false;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  basketSubscription: Subscription;
  loggedInPersonSubscription: Subscription;
  currentStepSubscription: Subscription;

  basketEmpty: boolean;
  loading = true;

  constructor(private titleService: TitleGenerator,
              private authService: AuthService,
              private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2,
              private router: Router,
              private basketService: BasketService,
              private sessionService: SessionService,
              private googleAnalytics: GoogleAnalyticsService) {
    this.basketEmpty = sessionService.basket == null
      || sessionService.basket.students == null
      || sessionService.basket.students.length === 0;
  }

  ngOnInit(): void {
    this.loading = true;

    this.titleService.setTitle('Checkout');

    this.currentStepNumber = 1;

    if (this.sessionService.basket.createAccountRequest != null
      && this.sessionService.basket.createAccountRequest.email != null
      && this.sessionService.basket.createAccountRequest.email !== '') {
      this.basketService.showConfirmationEmail(false);
    }

    // Show the address lines if completed
    if (this.sessionService.basket.createAccountRequest != null &&
      this.sessionService.basket.createAccountRequest.address != null
      && this.sessionService.basket.createAccountRequest.address.addressLine1 != null
      && this.sessionService.basket.createAccountRequest.address.addressLine1 !== '') {
      // this.addressLinesActive = true;
    }

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (this.sessionService.basket.accountLinked && !isLoggedIn) {
        this.currentStepNumber = 5;
      } else if (this.sessionService.basket.accountLinked) {
        this.loadLoggedInUserAndNavigate();
      } else if (isLoggedIn) {
        // We still need to know if the person has an account already
        this.checkIfAccountExists();
      }
    });

    this.loadIsFlexible();

    this.loggedInPersonSubscription = this.basketService.currentLoggedInPerson.subscribe(loggedInUser => {
      if (loggedInUser != null) {
        this.loggedInUser = loggedInUser;
      }
    });

    this.googleAnalytics.beginCheckout(this.sessionService.basket, 'New Enrolment');

    this.renderer.addClass(document.body, 'checkout-multiple');
    this.renderer.addClass(document.body, 'checkout');
    this.renderer.addClass(document.body, 'type-multi');

    this.currentStepSubscription = this.basketService.currentStep.subscribe(stepNumber => {
      if (stepNumber != null && !this.loading) {
        this.saveBasket();
        this.currentStepNumber = -1;

        if (stepNumber === 2) {
          this.apiService.checkIfPersonExists(this.sessionService.basket.createAccountRequest.email).subscribe((personResult) => {
            if (personResult.personExists) {
              // Redirect to login
              this.currentStepNumber = 5;
            } else {
              this.currentStepNumber = 2;
            }
          });
        } else if (stepNumber === 4) {
          this.navigateToStep4();
        } else {
          this.currentStepNumber = stepNumber;
        }

        this.googleAnalytics.checkoutProgress(this.sessionService.basket, 'New Enrolment');

        this.changeDetectorRef.detectChanges();
        this.scrollToTop();
      }
    });
  }

  ngAfterContentInit() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.basketSubscription != null) {
      this.basketSubscription.unsubscribe();
    }
    if (this.loggedInPersonSubscription != null) {
      this.loggedInPersonSubscription.unsubscribe();
    }
    if (this.currentStepSubscription != null) {
      this.currentStepSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'checkout-multiple');
    this.renderer.removeClass(document.body, 'checkout');
    this.renderer.removeClass(document.body, 'type-multi');
  }

  loadLoggedInUserAndNavigate() {
    this.currentStepNumber = -1;
    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.loggedInUser = loggedInUser;
      if (this.sessionService.basket.isProspect && !this.sessionService.basket.accountCreated) {
        // Need to confirm address details
        // First thing is to load what details we have
        this.apiService.getPerson(loggedInUser.personId, this.ngUnsubscribe).subscribe(contact => {
          this.sessionService.basket.createAccountRequest.telephoneHome = contact.telephoneHome;
          this.sessionService.basket.createAccountRequest.telephoneMobile = contact.telephoneMobile;
          this.apiService.findAddresses('postal', this.ngUnsubscribe).subscribe(results => {
            if (results.length > 0) {
              this.sessionService.basket.createAccountRequest.address = results[0];
            }
          });
          this.sessionService.saveBasket();
          this.currentStepNumber = 2;
        });
      }
      else if (this.sessionService.basket.studentsCreated) {
        this.navigateToStep4();
      } else {
        this.currentStepNumber = 3;
        this.googleAnalytics.checkoutProgress(this.sessionService.basket, 'New Enrolment');
      }
    });
  }

  checkIfAccountExists() {
    this.currentStepNumber = -1;
    this.apiService.findExistingAccount(this.sessionService.basket.licenseeId, this.ngUnsubscribe).subscribe(response => {
      if (response.length > 0) {
        // Need to find if there is a personal account, otherwise use a prospect
        let foundAccount = response[0];
        for (const account of response) {
          if (account.accountType === 'personal') {
            foundAccount = account;
            break;
          }
        }

        this.sessionService.basket.accountId = foundAccount.id;
        this.sessionService.basket.isProspect = foundAccount.accountType === 'prospect';
        this.sessionService.basket.accountLinked = true;

        this.saveBasket();

        this.loadLoggedInUserAndNavigate();
      } else {
        this.currentStepNumber = 1;
      }
    });
  }

  saveBasket() {
    // We don't want to save the password or terms agreement
    let currentPassword = null;
    let acceptedTerms = null;
    if (this.sessionService.basket.createAccountRequest != null) {
      currentPassword = this.sessionService.basket.createAccountRequest.password;
      acceptedTerms = this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy;

      this.sessionService.basket.createAccountRequest.password = null;
      this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy = false;
    }

    // Update the basket in the session
    this.sessionService.saveBasket();

    if (this.sessionService.basket.createAccountRequest != null) {
      this.sessionService.basket.createAccountRequest.password = currentPassword;
      this.sessionService.basket.createAccountRequest.acceptedPrivacyPolicy = acceptedTerms;
    }
  }

  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  navigateToStep4() {
    if (this.isFlexible) {
      this.router.navigate(['/dashboard_booking_credits_buy']);
    } else {
      this.currentStepNumber = 4;
      this.googleAnalytics.checkoutProgress(this.sessionService.basket, 'New Enrolment');
    }
  }

  doLogin() {
    this.authService.login();
  }

  cancelLogin() {
    this.currentStepNumber = 1;
  }

  loadIsFlexible() {
    this.sessionService.basket.students.forEach(basketStudent => {
      if (basketStudent.course.courseType === 'FLEXIBLE') {
        this.isFlexible = true;
      }
    });
  }
}
