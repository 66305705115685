import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap} from 'rxjs/operators';
import {LoginStatusService} from './_model/login-status.service';
import {from, Observable} from 'rxjs';
import {LoggedInUser} from './_model/logged-in-user';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {BasketService} from './checkout/basket.service';
import {KeycloakService} from 'keycloak-angular';
import {LocationStrategy} from '@angular/common';
import {ApplicationSettingsService} from './application-settings.service';

@Injectable()
export class AuthService {

  constructor(private loginStatusService: LoginStatusService,
              private router: Router,
              private http: HttpClient,
              private basketService: BasketService,
              private readonly keycloakService: KeycloakService,
              private readonly locationStrategy: LocationStrategy,
              private applicationSettings: ApplicationSettingsService) {
  }

  loginAndRedirectToDashboard() {
    const url = this.getBaseUri() + 'dashboard';
    this.login(url);
  }

  login(redirectUri?: string) {
    if (redirectUri == null) {
      redirectUri = window.location.href;
    }

    if (redirectUri.indexOf('?') === -1) {
      redirectUri += '?';
    } else {
      redirectUri += '&';
    }

    redirectUri += 'master_licensee_id=' + this.applicationSettings.masterLicenseeId;

    this.keycloakService.login({redirectUri}).catch(err => {
      console.error('Failed to login: ' + err);
    });
  }

  logout(redirectPage: string) {
    let redirectUri = this.getBaseUri();

    redirectUri += redirectPage;
    this.keycloakService.logout(redirectUri);
  }

  getBaseUri(): string {
    let redirectUri = window.location.origin;

    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref != null && baseHref !== '') {
      redirectUri += baseHref;

      if (!redirectUri.endsWith('/')) {
        redirectUri += '/';
      }
    }

    return redirectUri;
  }

  public getLoggedInUser(): Observable<LoggedInUser> {
    return this.isLoggedIn().pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return new Observable<LoggedInUser>((observer) => {
            observer.next(null);
          });
        }

        return this.http.get<any>(environment.apiUrl + 'user/logged_in_user').pipe(
            map(response => {
              return new LoggedInUser(response);
            })
          );
      }),
      catchError(err => {
        console.error('Failed to check if logged in', err);
        return new Observable<LoggedInUser>((observer) => {
          observer.next(null);
        });
      })
    );
  }

  isLoggedIn(): Observable<boolean> {
    return from(this.keycloakService.isLoggedIn());
  }
}
