<footer class="footer type-default">
  <div class="container">

    <div class="row row-nav">
      <div class="span-logo">
        <a appRedirectHome><img appLogo [inverse]="true" /></a>
        <em>{{tagline}}</em>
      </div>
      <div class="span-nav-1">
        <ul class="nav nav-footer">
          <li [ngClass]="link.highlighted ? 'label' : ''" *ngFor="let link of footerLinksLeft">
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Link' && link.url != null" href="{{link.url}}" [innerHTML]="link.label"></a>
            <span [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Link' && link.url == null" class="footer-link-no-link" [innerHTML]="link.label"></span>
            <span [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Line Break'">&nbsp;</span>
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Terms and Conditions'" [routerLink]="['/terms_and_conditions']" [innerHTML]="link.label"></a>
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Privacy'" [routerLink]="['/privacy_policy']" [innerHTML]="link.label"></a>
          </li>
        </ul>
      </div>
      <div class="span-nav-2">
        <ul class="nav nav-footer">
          <li [ngClass]="link.highlighted ? 'label' : ''" *ngFor="let link of footerLinksRight">
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Link' && link.url != null" href="{{link.url}}" [innerHTML]="link.label"></a>
            <span [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Link' && link.url == null" class="footer-link-no-link" [innerHTML]="link.label"></span>
            <span [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Line Break'">&nbsp;</span>
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Terms and Conditions'" [routerLink]="['/terms_and_conditions']" [innerHTML]="link.label"></a>
            <a [ngClass]="link.highlighted ? 'label' : ''" *ngIf="link.type === 'Privacy'" [routerLink]="['/privacy_policy']" [innerHTML]="link.label"></a>
          </li>
        </ul>
      </div>
      <div class="span-social">
        <a href="{{facebookUrl}}" *ngIf="facebookUrl != null">
          <span class="svg-icon facebook"><svg class="svg-facebook"><use xlink:href="./assets/sprite/spritemap.svg#sprite-facebook"></use></svg></span>					</a>
        <a href="{{twitterUrl}}" *ngIf="twitterUrl != null">
          <span class="svg-icon twitter"><svg class="svg-twitter"><use xlink:href="./assets//sprite/spritemap.svg#sprite-twitter"></use></svg></span>					</a>
        <a href="{{linkedInUrl}}" *ngIf="linkedInUrl != null">
          <span class="svg-icon linkedin"><svg class="svg-linkedin"><use xlink:href="./assets//sprite/spritemap.svg#sprite-linkedin"></use></svg></span>					</a>
        <a href="{{instagramUrl}}" *ngIf="instagramUrl != null">
          <span class="svg-icon instagram"><svg class="svg-instagram"><use xlink:href="./assets/sprite/spritemap.svg#sprite-instagram"></use></svg></span>					</a>
      </div>
    </div>

    <div class="row row-copyright">
      <div class="span-copyright">
        &copy; Copyright {{currentDate | date:'yyyy'}} Sporting Technology Ltd | By using this site you agree we can set and use cookies.
        <a [routerLink]="['/privacy_policy']">View Policy</a>
      </div>
      <div class="span-credits">
        Powered by
        <a href="http://www.sportingtechnology.com/">Sporting Technology Ltd.</a>
      </div>
    </div>

  </div>
</footer>
