import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../auth-service';
import {NavigationEnd, Router} from '@angular/router';
import {TitleGenerator} from '../title-generator';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  @Input() cannotConnectToServer: boolean;

  previousUrl: string;

  constructor(private titleService: TitleGenerator,
              private authService: AuthService,
              private router: Router) {}

  ngOnInit(): void {
    if (this.cannotConnectToServer == null) {
      this.cannotConnectToServer = false;
    }

    if (!this.cannotConnectToServer) {
      this.titleService.setTitle('Page Not Found');
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.previousUrl = event.url;
        });

      if (this.previousUrl !== null) {
        console.error('Page not found: ' + this.router.url + ' from ' + this.previousUrl);
      }
    } else {
      this.titleService.setTitle('Server Error');
    }
  }

  returnHome(): void {
    if (this.cannotConnectToServer == null || !this.cannotConnectToServer) {
      this.authService.isLoggedIn().subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/booking_start']);
        }
      });
    } else {
      window.location.reload();
    }
  }
}
