import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {LoggedInUser} from '../../_model/logged-in-user';
import {AuthService} from '../../auth-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {

  loggedInUser: LoggedInUser;
  showMenu = false;

  constructor(private authService: AuthService,
              private router: Router,
              private zone: NgZone) {
  }

  ngOnInit(): void {
    this.setLoggedInUser();
  }

  ngOnDestroy() {
  }

  setLoggedInUser() {
    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.loggedInUser = loggedInUser;
    });
  }

  logOff() {
    this.authService.logout('/booking_start');
  }
}
