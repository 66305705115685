<form method="post" id="studentDetailsFilter" novalidate="novalidate" class="form-course-finder" [formGroup]="studentDetailsFilterForm">
<div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleStudent()" class="accordion-header" [ngClass]="showStudent ? 'active' : ''">
      <span *ngIf="student.studentName != null">{{student.studentName}}'s Details</span>
      <span *ngIf="student.studentName == null">Student Details</span>
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-10-wgt-df1" class="toggler" *ngIf="showStudent" [ngClass]="showStudent ? 'active' : ''">
    <div class="row row-details">
      <div class="span-input span-text">
        <input
          type="date"
          id="Student_dob"
          name="Student_dob"
          class="input-text"
          max="{{getMaxDate()}}"
          min="{{getMinDate()}}"
          #StudentDOBInput
          (ngModelChange)="onDateOfBirthChange(StudentDOBInput.value, false)"
          [attr.disabled]="isSearchDisabled || student.studentId != null ? true : null"
          [ngClass]="StudentDOBInput.value == null || StudentDOBInput.value === ''  ? 'filter-input-error' : ''"
          formControlName="studentDobInput"
        />
        <label for="Student_dob" class="floating-label" [ngClass]="StudentDOBInput.value == null || StudentDOBInput.value === ''  ? 'filter-label-error' : ''">
          Date of birth
        </label>
      </div>

      <div class="span-input span-text">
        <select
          id="Student_programme"
          name="Student_programme"
          type="select"
          required="required"
          class="input-select"
          #StudentProgramSelect
          (change)="onProgramChange(StudentProgramSelect.value, false)"
          [attr.disabled]="isSearchDisabled ? true : null"
          [ngClass]="StudentProgramSelect.value == null || StudentProgramSelect.value === ''  ? 'filter-input-error' : ''"
          formControlName="studentProgramInput"
        >

          <option value="" disabled selected hidden>select program</option>
          <option *ngFor="let program of programs"
            value="{{program.id}}">
            {{program.programName}}</option>

        </select>
        <label for="Student_programme" class="floating-label" [ngClass]="StudentProgramSelect.value == null || StudentProgramSelect.value === ''  ? 'filter-label-error' : ''">
          Programme
        </label>
      </div>



      <div class="span-input span-select">
        <select
          id="Student_skill_level"
          name="Student_skill_level"
          type="select"
          required="required"
          class="input-select"
          #StudentProgramLevelSelect
          (change)="onProgramLevelChange(StudentProgramLevelSelect.value, false)"
          [attr.disabled]="isSearchDisabled || hasVerifiedLevelForProgram ? true : null"
          [ngClass]="StudentProgramLevelSelect.value == null || StudentProgramLevelSelect.value === '' ? 'filter-input-error' : ''"
          formControlName="studentLevelInput"
        >
          <option value="" disabled selected hidden>select level</option>
          <option *ngFor="let programLevel of programLevels"
            value="{{programLevel.id}}">
            {{programLevel.programLevelName}} (level {{programLevel.sequence}})			</option>
        </select>
        <label for="Student_skill_level" class="floating-label" [ngClass]="StudentProgramLevelSelect.value == null || StudentProgramLevelSelect.value === ''  ? 'filter-label-error' : ''">
          Level
          <span class="level-verified-tick-wrapper">
          <svg *ngIf="hasVerifiedLevelForProgram" class="sprite-tick level-verified-tick">
            <title>Your level has been verified by an instructor</title>
            <use xlink:href="./assets/sprite/spritemap.svg#sprite-tick"></use>
          </svg>
        </span>
        </label>

        <i class="asterisk">*</i><i class="checkmark"></i>
      </div>
    </div>
  </div>
</div>
</form>
