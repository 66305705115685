import {PendingPaymentSettlementResponse} from './pending-payment-settlement-response';
import {PendingPaymentInvoice} from './pending-payment-invoice';

export class PendingPaymentActionResponse extends PendingPaymentSettlementResponse {
  pendingPaymentInvoices: PendingPaymentInvoice[];
  apiKey: string;

  constructor(data?: any) {
    super(data);
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
