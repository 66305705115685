<div class="wrap">
  <main class="main"><section class="auth">

    <div class="container">
      <div class="row">

        <div class="span-bg" style="background-image: url('./assets/images/login-bg.jpg')"></div>

        <div class="span-form">
          <div class="login-wrap">

            <div class="logo">
              <a appRedirectHome>
                <img appLogo/>
              </a>
            </div><div class="login-header">
            <h3 class="login-title">
              Request a log in
            </h3>
            <p class="login-subtitle">
              Please enter your details below
            </p>
          </div>

            <div class="form">
              <form method="post" id="activate_account" action="" novalidate="novalidate" class="auth-form" [formGroup]="activateAccountForm" (ngSubmit)="onActivateAccountSubmit()">

                <div class="span-input span-text required" *ngIf="showActivationCode">
                  <input
                    type="text"
                    id="activation_code"
                    name="activation_code"
                    class="input-text"
                    formControlName="activationCodeInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Activation Code'"
                  />
                  <label
                    for="activation_code"
                    class="floating-label">
                    activation code
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul class="parsley-errors-list filled"></ul>
                </div>

                <div class="span-input span-text required" *ngIf="activationType === 'sms'">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    class="input-text"
                    formControlName="emailInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Email'"
                    (input)="activateAccountForm.controls.emailInput.setValue(activateAccountForm.controls.emailInput.value.toLowerCase())"
                  />
                  <label for="email" class="floating-label">
                    email
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul class="parsley-errors-list filled"></ul>
                </div>

                <div class="span-input span-text required">
                  <input
                    pwnedPasswordValidator
                    pwnedPasswordMinimumOccurrenceForError="1"
                    pwnedPasswordApi="https://api.pwnedpasswords.com/range/"
                    pwnedPasswordApiCallDebounceTime="400"
                    [type]="fieldTextType ? 'text' : 'password'"
                    id="password"
                    name="password"
                    class="input-text"
                    formControlName="passwordInput"
                    #passwordInput
                    (keyup)=passwordEntered(passwordInput.value)
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Password'"
                  />
                  <label
                    for="password"
                    class="floating-label">
                    password
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <button *ngIf="!fieldTextType" type="button" id="show_password" class="show-password" (click)="fieldTextType=true">Show</button>
                  <button *ngIf="fieldTextType" type="button" id="hide_password" class="show-password" (click)="fieldTextType=false">Hide</button>

                  <meter max="4" id="password-strength-meter" value="{{passwordStrength}}"></meter>

                  <ul class="parsley-errors-list filled"></ul>
                  <ul *ngIf="activateAccountForm.get('passwordInput').errors != null && activateAccountForm.get('passwordInput').errors.pwnedPasswordOccurrence" class="parsley-errors-list filled">
                    This password has previously appeared in a data breach and should never be used.
                  </ul>
                </div>

                <div class="span-input span-text required">
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    class="input-text"
                    formControlName="confirmPasswordInput"
                    appParsleyValidation
                    [submitted]="submitted"
                    [elementName]="'Password Confirmation'"
                  />
                  <label
                    for="password"
                    class="floating-label">
                    confirm password
                  </label>
                  <i class="asterisk">*</i>
                  <i class="checkmark"></i>
                  <ul class="parsley-errors-list filled"></ul>
                </div>

                <button type="submit" class="btn btn-block btn-red" [ngClass]="activateAccountForm.invalid || runningActivateAccount ? 'disabled' : ''">
                  Activate
                </button>

                <div *ngIf="error != null" class="invalid-feedback">
                  {{error}}
                </div>

                <div class="alternative">
                  <p>Having trouble? <a href="{{contactUsUrl}}">Contact us</a></p>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>		</main>
</div>
