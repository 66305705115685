import {convertObjectToDate} from '../_helpers/date_helper';

export class DirectDebitCustomerDetails {
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  region: string;
  postalCode: string;
  countryCode: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
