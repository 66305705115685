export class SubscriptionUpdateResponse {
  paymentStatus: string;
  paymentError: string;
  paymentErrorCode: string;
  pendingPaymentId: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
