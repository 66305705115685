import {Component, OnDestroy, OnInit} from '@angular/core';
import {getPriceTypeShort} from '../../_helpers/enrolment_helper';
import {Router} from '@angular/router';
import {CourseListBasketService} from './course-list-basket.service';
import {BasketService} from '../../checkout/basket.service';
import {CoursePrice} from '../../_model/course-price';
import {Subscription} from 'rxjs';
import {SessionService} from '../../session.service';
import {BasketStudent} from '../../_model/basket-student';

@Component({
  selector: 'app-course-list-basket',
  templateUrl: './course-list-basket.component.html',
  styleUrls: ['./course-list-basket.component.css']
})
export class CourseListBasketComponent implements OnInit, OnDestroy {

  students: BasketStudent[];
  currencyCode: string;
  basketScrollSubscription: Subscription;

  constructor(private courseListBasketService: CourseListBasketService,
              private checkoutBasketService: BasketService,
              private router: Router,
              private sessionService: SessionService) {
    this.students = sessionService.basket.students;
    this.currencyCode = sessionService.basket.currencyCode;
  }

  ngOnInit(): void {
    this.basketScrollSubscription = this.courseListBasketService.currentScrollToBasketId.subscribe(basketId => {
      if (basketId != null) {
        const element = document.getElementById(basketId.toString(10));
        if (element != null) {
          element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.basketScrollSubscription != null) {
      this.basketScrollSubscription.unsubscribe();
    }
  }

  formatPriceTypeShort(enrolmentType: string): string {
    return getPriceTypeShort(enrolmentType);
  }

  basketCheckout() {
    const referrer = this.router.url;
    this.router.navigate(['/cart'], {queryParams: {referrer}});
  }

  getPrice(coursePrice: CoursePrice): number {
    if (coursePrice.priceType === 'MONTHLY_DIRECT_DEBIT'
      || coursePrice.priceType === 'MONTHLY_CREDIT_CARD'
      || coursePrice.priceType === 'CREDIT_PACK') {
      return coursePrice.price;
    } else {
      return coursePrice.totalPrice;
    }
  }
}
