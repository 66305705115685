import {ChangeDetectorRef, Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EnrolmentResponse} from '../../_model/enrolment-response';
import {WithdrawalReason} from '../../_model/withdrawal-reason';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../../auth-service';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleGenerator} from '../../title-generator';
import {DashboardService} from '../dashboard.service';
import {environment} from '../../../environments/environment';
import {PendingPayment} from '../../_model/pending-payment';

@Component({
  selector: 'app-dashboard-invoice-cancel',
  templateUrl: './dashboard-invoice-cancel.component.html',
  styleUrls: ['./dashboard-invoice-cancel.component.css']
})
export class DashboardInvoiceCancelComponent implements OnInit {

  withdrawalForm: UntypedFormGroup;

  pendingPayment: PendingPayment;
  provisional: EnrolmentResponse;
  withdrawalReasons: WithdrawalReason[];
  selectedWithdrawalText: string;
  running = false;
  submitted = false;
  referrer: string;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  provisionalEnrolmentsSubscription: Subscription;

  constructor(private authService: AuthService,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleGenerator,
              private dashboardService: DashboardService,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private renderer: Renderer2) {}

  setPaymentValidators() {
    let withdrawalReasonValidator = null;

    if (this.selectedWithdrawalText === 'Other') {
      withdrawalReasonValidator = [Validators.required];
    }

    this.withdrawalForm.get('withdrawalReasonOtherInput').setValidators(withdrawalReasonValidator);
    this.withdrawalForm.get('withdrawalReasonOtherInput').updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Invoice Cancel');

    this.route.params.subscribe(params => {
      const pendingPaymentId = params.pendingPaymentId;

      this.apiService.getPendingPayment(pendingPaymentId, this.ngUnsubscribe).subscribe(pendingPayment => {
        this.pendingPayment = pendingPayment;
        const enrolmentId = pendingPayment.enrolment.enrolmentId;

        this.apiService.getEnrolment(enrolmentId, this.ngUnsubscribe).subscribe(enrolment => {
          this.provisional = enrolment;
          this.checkValidInvoice();
        });

        this.apiService.getWithdrawalReasons(enrolmentId, this.ngUnsubscribe).subscribe(reasons => this.withdrawalReasons = reasons);
      })


    });

    this.route.queryParams.subscribe(params => {
      this.referrer = params.referrer;
    });

    this.withdrawalForm = this.formBuilder.group({
      withdrawalReasonSelect: ['', Validators.required],
      withdrawalReasonOtherInput: ['']
    });

    this.renderer.addClass(document.body, 'dashboard-booking-cancel');
    this.renderer.addClass(document.body, 'dashboard-action');
  }

  checkValidInvoice() {
    if (!this.pendingPayment.canCancelAndWithdraw) {
      // We have reloaded the page under a different login, or the request no longer exists
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();

    if (this.provisionalEnrolmentsSubscription != null) {
      this.provisionalEnrolmentsSubscription.unsubscribe();
    }

    this.renderer.removeClass(document.body, 'dashboard-booking-cancel');
    this.renderer.removeClass(document.body, 'dashboard-action');
  }

  getInstructorImageUrl(instructorId: string): string {
    return 'url(\'' + environment.apiUrl + 'images/instructors/' + instructorId + '/image\')';
  }

  updateWithdrawalReasonText(value: string) {
    this.selectedWithdrawalText = value;
    this.setPaymentValidators();
  }

  onWithdrawalFormSubmit(): void {
    this.submitted = true;

    if (this.running || this.withdrawalForm.invalid) {
      return;
    }

    this.running = true;

    const selectedWithdrawalId = this.withdrawalForm.get('withdrawalReasonSelect').value;
    const selectedWithdrawalReasonOther = this.withdrawalForm.get('withdrawalReasonOtherInput').value;

    this.apiService.deletePendingPayment(this.pendingPayment.pendingPaymentId, selectedWithdrawalId, selectedWithdrawalReasonOther, this.ngUnsubscribe).subscribe(() => {
      this.router.navigate(['/dashboard']);
      this.dashboardService.setNewConfirmationMessage('Enrolment withdrawn');
    }, () => {}, () => { this.running = false; });

  }

  goBack(): void {
    if (this.referrer == null) {
      this.referrer = '/dashboard';
    }

    this.router.navigate([this.referrer]);
  }

}
