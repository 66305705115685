<form *ngIf="!showLevelChecker" method="post" id="form_select_program_level" [formGroup]="selectProgramLevelForm">
<div id="panel_find_skill_level" class="panel panel-find-skill-level">

  <div class="span-title">
    <h2 class="entry-title">
      {{title}}		</h2>
    <h2 class="program-title">
      {{program.programName}}		</h2>
    <div class="entry-content" *ngIf="showLevelCheckerLink">
      <a (click)="onRunLevelChecker()" [routerLink]="[]" queryParamsHandling="preserve" class="btn-check">Not sure? Use our interactive checker</a>
    </div>
  </div>

  <div class="row row-cards">

    <div class="span-card row-element-centred"*ngFor="let programLevel of programLevels">
      <div class="card style-default">
        <div class="span-choice">
          <label
            for="{{programLevel.id}}"
            class="input-check radio">
            <img src="{{getLevelImageUrlSrc(programLevel.id)}}" alt="{{programLevel.programLevelName}}">
            <input
              id="{{programLevel.id}}"
              type="radio"
              name="programLevelSelect"
              value="{{programLevel.id}}"
              data-next="4"
              formControlName="programLevelSelect"
              [value]="programLevel.id"
              (click)="onProgramLevelSelected(programLevel.id)"
            />
            <span class="radio-content">
								<ul class="label-description">
									<li class="title">{{programLevel.programLevelName}}</li>
									<li class="lead">(Level {{programLevel.sequence}})</li>
									<li class="description" [innerHTML]="getProgramLevelText(programLevel)"></li>
                  <div class="tooltip" [innerHTML]="getProgramLevelText(programLevel)"></div>
								</ul>
							</span>
          </label>
        </div>
      </div>
    </div>

  </div>

</div>
</form>

<app-interactive-skill-check *ngIf="showLevelChecker"></app-interactive-skill-check>
