import {Address} from './address';

export class Card {
  sportsAccountPaymentGatewayCardId: string;
  cardType: string;
  trailingDigits: string;
  expiry: Date;
  isPrimary: boolean;
  cardAddress: Address;
  cardLength: number;
  cardholderName: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
      if (this.expiry != null && (typeof this.expiry === 'string' || typeof this.expiry === 'number')) {
        if (this.expiry !== '') {
          this.expiry = new Date(this.expiry);
        }
      }
    }
  }
}
