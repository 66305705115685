<div class="item-single">
  <div class="container">
    <div class="row">

      <div class="span-graphic type-double">
        <div class="graphic-image" *ngIf="abandonedCartResponse.abandonedCart.course.instructorHasPhoto" [ngStyle]="{'background-image': getInstructorImageUrl(abandonedCartResponse.abandonedCart.course.instructorId)}"></div>
      </div>

      <div class="span-content">
        <ul class="list-content">
          <li>
            <ul class="list-inline">
              <li>Continue your course search for </li>
              <li id="student_first_name_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.sportsStudent.givenName}}</li>
            </ul>
          </li>
          <li>
            <ul>
              <li id="term_date_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.course.termDescription}}</li>
              <li *ngIf="abandonedCartResponse.availableToBook" id="student_full_name_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.course.instructorGivenName}}</li>
            </ul>
          </li>
          <li>
            <ul class="list-inline">
              <li id="term_days_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.course.fullDayOfWeekNames}}</li>
              <li> between </li>
              <li id="term_time_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.course.startTimeDate | worldDate: 'h:mma'}} & {{abandonedCartResponse.abandonedCart.course.endTimeDate | worldDate: 'h:mma'}}</li>
              <li> at </li>
              <li id="term_location_{{abandonedCartResponse.abandonedCart.course.id}}">{{abandonedCartResponse.abandonedCart.course.venueName}}</li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="span-buttons">
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="runSearch(true)" *ngIf="abandonedCartResponse.availableToBook" class="btn btn-block btn-yellow">view course</a>
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="runSearch(false)" *ngIf="!abandonedCartResponse.availableToBook" class="btn btn-block btn-yellow">find courses</a>
        <a [routerLink]="[]" queryParamsHandling="preserve" (click)="cancelAbandonedCart()" class="btn btn-block btn-underlined">remove</a>
      </div>

    </div>
  </div>
</div>
