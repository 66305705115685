<span [formGroup]="paymentForm" *ngIf="!loading">
    <div class="row row-card-info">
      <div class="span-input select required">
        <select
          id="mandate"
          name="mandate"
          class="input-select"
          formControlName="mandateSelect"
          #mandatePaymentMethodElement
          (change)="mandateSelectChange(mandatePaymentMethodElement.value)"
          appParsleyValidation
          [submitted]="submitted"
          [elementName]="'Mandate'"
        >
          <option *ngFor="let mandate of mandates" value="{{mandate.sportsAccountPaymentGatewayMandateId}}">{{getMandateDescription(mandate)}}</option>
          <option value="new">New Bank Account...</option>
        </select>

        <label
          for="mandate"
          class="floating-label">
          select bank account
        </label>
        <i class="asterisk">*</i>
        <i class="checkmark"></i>
        <ul class="parsley-errors-list filled"></ul>
      </div>
    </div>
    <span [style.display]="(selectedMandateId !== 'new') ? 'none' : '' ">
      <button type="button" (click)="setupDirectDebit()" [ngClass]="settingUpMandate ? 'disabled' : ''" class="btn btn-block btn-red">
        Setup Direct Debit
      </button>
    </span>
</span>
