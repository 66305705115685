import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy} from '@angular/core';
import {Student} from '../../_model/student';
import {DashboardAddStudentService} from '../dashboard-add-student/dashboard-add-student.service';
import {StudentAchievement} from '../../_model/student-achievement';
import {AwardAchievement} from '../../_model/award-achievement';
import {ModuleAchievement} from '../../_model/module-achievement';
import {LevelAchievement} from '../../_model/level-achievement';
import {ChallengeAchievement} from '../../_model/challenge-achievement';
import {ApiService} from '../../api.service';
import {Subject} from 'rxjs';
import Swiper, {SwiperOptions} from 'swiper';
import {NavigationMethods} from 'swiper/types/components/navigation';
import {KeyboardMethods, PaginationMethods, ScrollbarMethods} from 'swiper/types';
import SwiperCore, {Navigation, Pagination, Scrollbar} from 'swiper/core';
import {monthsBetweenDates} from '../../_helpers/date_helper';
import {StudentLevel} from '../../_model/student-level';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
  selector: 'app-dashboard-student-progression',
  templateUrl: './dashboard-student-progression.component.html',
  styleUrls: ['./dashboard-student-progression.component.css']
})
export class DashboardStudentProgressionComponent implements AfterViewInit, OnDestroy {

  @Input() student: Student;

  public swiper: Swiper;

  ngUnsubscribe: Subject<void> = new Subject<void>();

  levels: StudentLevel[] = [];
  hiddenLevels: StudentLevel[] = [];
  levelsHidden = true;

  constructor(private el: ElementRef,
              private dashboardAddStudentService: DashboardAddStudentService,
              private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {

    this.student.levels.forEach(studentLevel => {
      if (studentLevel.level != null) {
        const program = studentLevel.program;

        if (program.maximumAgeInMonths != null && program.minimumAgeInMonths != null && this.student.dateOfBirth != null) {
          const studentMonths = monthsBetweenDates(new Date(), this.student.dateOfBirth);

          if (studentMonths >= program.minimumAgeInMonths && studentMonths <= program.maximumAgeInMonths) {
            this.levels.push(studentLevel);
          } else {
            this.hiddenLevels.push(studentLevel);
          }
        } else {
          this.levels.push(studentLevel);
        }
      }
    });

    this.changeDetectorRef.detectChanges();

    this.student.progression = [];
    this.apiService.getStudentProgression(this.student.id, null, 3, this.ngUnsubscribe).subscribe(progression => {
      this.student.progression = [];
      this.student.futureProgression = [];

      for (const progressionItem of progression) {
        if (progressionItem.achieved) {
          this.student.progression.push(progressionItem);
        } else {
          this.student.futureProgression.push(progressionItem as ModuleAchievement);
        }
      }
      let index = this.student.progression.length - 1;

      if (index < 0) {
        index = 0;
      }

      this.changeDetectorRef.detectChanges();
      this.loadSwiper(index);
    });
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properly.
    this.ngUnsubscribe.complete();
  }

  loadSwiper(index: number): void {
    const prev = this.el.nativeElement.querySelector('.swiper-button-prev-' + this.student.id);
    const next = this.el.nativeElement.querySelector('.swiper-button-next-' + this.student.id);

    const navigation: NavigationMethods = {
      nextEl: next,
      prevEl: prev,
      update() {
        console.log('update');
      },
      init() {
        console.log('init');
      },
      destroy() {
        console.log('destroy');
      }
    };

    const keyboard: KeyboardMethods = {
      enable() {
      },
      enabled: true,
      disable() {
      }
    };

    const scrollbar: ScrollbarMethods = {
      el: null,
      dragEl: null,
      updateSize() {
      },
      init() {
      },
      setTranslate() {
      },
      destroy() {
      }
    };

    const pagination: PaginationMethods = {
      el: null,
      bullets: null,
      render() {
      },
      update() {
      },
      init() {
      },
      destroy() {
      }
    };

    const options: SwiperOptions = {
      direction: 'horizontal',
      spaceBetween: 20,
      keyboard,
      scrollbar,
      pagination,
      navigation,
      slidesPerView: 'auto'
    };

    this.swiper = new Swiper('.swiper-container-' + this.student.id, options);

    this.changeDetectorRef.detectChanges();

    if (this.student.progression.length > 0) {
      this.swiper.slideTo(this.student.progression.length - 1);
    }
  }

  showEditStudent(student: Student) {
    this.dashboardAddStudentService.setShowAddingStudent(true);
    this.dashboardAddStudentService.setStudentToUpdate(student);
  }

  getStudentGenderName(gender: string): string {
    if (gender === 'M') {
      return 'Male';
    } else {
      return 'Female';
    }
  }

  getAwardAchievement(progression: StudentAchievement): AwardAchievement {
    return progression as AwardAchievement;
  }

  getModuleAchievement(progression: StudentAchievement): ModuleAchievement {
    return progression as ModuleAchievement;
  }

  getLevelAchievement(progression: StudentAchievement): LevelAchievement {
    return progression as LevelAchievement;
  }

  getChallengeAchievement(progression: StudentAchievement): ChallengeAchievement {
    return progression as ChallengeAchievement;
  }

  msToTime(duration: number): string {
      const milliseconds = duration % 1000;
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let formattedTime = '';

      if (hours > 0) {
        formattedTime = this.appendStringWithComma(formattedTime, hours + ' hours');
      }

      if (minutes > 0 || hours > 0) {
        formattedTime = this.appendStringWithComma(formattedTime, minutes + ' minutes');
      }

      if (seconds > 0 || minutes > 0 || hours > 0) {
        formattedTime = this.appendStringWithComma(formattedTime, seconds + ' seconds');
      }

      if (milliseconds > 0) {
        formattedTime = this.appendStringWithComma(formattedTime, milliseconds + ' ms');
      }

      return formattedTime;
  }

  appendStringWithComma(formattedTime: string, value: string): string {
    if (formattedTime !== '') {
      formattedTime += ', ';
    }

    formattedTime += value;

    return formattedTime;
  }

  showHiddenLevels(): void {
    this.levelsHidden = false;
    this.changeDetectorRef.detectChanges();
  }
}
