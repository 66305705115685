
<div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleLocation()" class="accordion-header" [ngClass]="showLocation ? 'active' : ''">
      Location
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-0-wgt-f1" class="toggler"  *ngIf="showLocation && courseSearchParameters.venueOptions.venueId == null" [ngClass]="showLocation ? 'active' : ''">
    <div class="row row-csize checkbox-group">

      <div class="span-input span-select location-div">
        <select
          id="Student_location"
          name="Student_location"
          type="select"
          required="required"
          class="input-select"
          [(ngModel)]="courseSearchParameters.venueOptions.distance"
          (change)="reRunSearch()"
          [attr.disabled]="isSearchDisabled ? true : null"
        >
          <option value="{{venueSearchDistance.distance}}" *ngFor="let venueSearchDistance of venueSearchDistances" [selected]="venueSearchDistance.isDefault">
            {{courseSearchParameters.venueOptions.address}} + {{venueSearchDistance.distance}} {{distanceType}}
          </option>

        </select>
        <label for="Student_location" class="floating-label">
          Location
        </label>
        <div class="filter-change-location"><a [routerLink]="[]" queryParamsHandling="preserve" (click)="changeLocation()">change location</a></div>
      </div>

    </div>
  </div>
  <div id="tab-0-wgt-f1" class="toggler"  *ngIf="showLocation && courseSearchParameters.venueOptions.venueId != null" [ngClass]="showLocation ? 'active' : ''">
    <div class="row row-csize checkbox-group">

      <div class="span-input span-select">
        <select
          id="Student_venue"
          name="Student_venue"
          type="select"
          required="required"
          class="input-select"
          [attr.disabled]="isSearchDisabled ? true : null"
        >
          <option value="{{courseSearchParameters.venueOptions.venueId}}">
            {{venueName}}
          </option>
        </select>
        <label for="Student_location" class="floating-label">
          Venue
        </label>
        <div class="filter-change-location"><a [routerLink]="[]" queryParamsHandling="preserve" (click)="changeLocation()">change location</a></div>
      </div>

    </div>
  </div>
</div>
<div class="accordion-tab" *ngIf="courseRatios != null">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleClassSize()" class="accordion-header" [ngClass]="showClassSize ? 'active' : ''">
      Class Size
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-1-wgt-f1" class="toggler" *ngIf="showClassSize"  [ngClass]="showClassSize ? 'active' : ''">
    <div class="row row-csize checkbox-group">

      <div *ngFor="let courseRatio of courseRatios" class="span-input span-checkbox">
        <label
          for="{{courseRatio.id}}"
          class="input-check checkbox">
          <input
            id="{{courseRatio.id}}"
            name="class_size"
            type="checkbox"
            class="input-checkbox"
            value="{{courseRatio.id}}"
            [(ngModel)]="courseRatio.checked"
            (change)="refreshFilter()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>{{courseRatio.numberOfStudentsPerInstructorText | titlecase}} ({{courseRatio.classDuration}} mins)</span>
        </label>
      </div>

    </div>
  </div>
</div><div class="accordion-tab">
  <h4 *ngIf="!excludeTermType">
    <a [routerLink]="[]"  queryParamsHandling="preserve"(click)="toggleCourseType()" class="accordion-header"  [ngClass]="showCourseType ? 'active' : ''">
      Course Type
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-2-wgt-f1" class="toggler" *ngIf="showCourseType && !excludeTermType"  [ngClass]="showCourseType ? 'active' : ''">
    <div class="row row-ctype">
      <div class="span-input span-checkbox"  *ngIf="showWeekly">
        <label
          for="courseType_Weekly"
          class="input-check checkbox">
          <input
            id="courseType_Weekly"
            name="courseType"
            type="radio"
            class="input-checkbox"
            [value]="'WEEKLY'"
            [(ngModel)]="courseSearchParameters.termType"
            (change)="reRunSearch()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Weekly</span>
        </label></div>
        <div class="span-input span-checkbox" *ngIf="showIntensive">
          <div class="span-input span-checkbox">
            <label
              for="courseType_FT"
              class="input-check checkbox">
              <input
                id="courseType_FT"
                type="radio"
                class="input-checkbox"
                [value]="'FAST_TRACK'"
                [(ngModel)]="courseSearchParameters.termType"
                (change)="reRunSearch()"
                [attr.disabled]="isSearchDisabled ? true : null"
              >
              <span>Intensive</span>
            </label>
          </div>
        </div>
  </div>
</div>
<div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleDays()" class="accordion-header" [ngClass]="showDays ? 'active' : ''">
      Days
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-3-wgt-f1" class="toggler" *ngIf="showDays" [ngClass]="showDays ? 'active' : ''">
    <div class="row row-days">

      <div class="span-input span-checkbox" *ngIf="mondayExists">
        <label
          for="Monday"
          class="input-check checkbox">
          <input
            id="Monday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="2"
            [(ngModel)]="isMondayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Monday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="tuesdayExists">
        <label
          for="Tuesday"
          class="input-check checkbox">
          <input
            id="Tuesday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="3"
            [(ngModel)]="isTuesdayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Tuesday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="wednesdayExists">
        <label
          for="Wednesday"
          class="input-check checkbox">
          <input
            id="Wednesday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="4"
            [(ngModel)]="isWednesdayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Wednesday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="thursdayExists">
        <label
          for="Thursday"
          class="input-check checkbox">
          <input
            id="Thursday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="5"
            [(ngModel)]="isThursdayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Thursday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="fridayExists">
        <label
          for="Friday"
          class="input-check checkbox">
          <input
            id="Friday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="6"
            [(ngModel)]="isFridayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Friday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="saturdayExists">
        <label
          for="Saturday"
          class="input-check checkbox">
          <input
            id="Saturday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="7"
            [(ngModel)]="isSaturdayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Saturday</span>
        </label>
      </div>
      <div class="span-input span-checkbox" *ngIf="sundayExists">
        <label
          for="Sunday"
          class="input-check checkbox">
          <input
            id="Sunday"
            name="days"
            type="checkbox"
            class="input-checkbox"
            value="1"
            [(ngModel)]="isSundayChecked"
            (change)="daySelected()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>Sunday</span>
        </label>
      </div>

    </div>
  </div>
</div><div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleTimes()" class="accordion-header"  [ngClass]="showTimes ? 'active' : ''">
      Time
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-4-wgt-f1" class="toggler" *ngIf="showTimes"  [ngClass]="showTimes ? 'active' : ''">
    <div class="row row-time checkbox-group">
      <div *ngFor="let startTime of startTimes" class="span-input span-checkbox">
        <label
          for="{{startTime.hour}}"
          class="input-check checkbox">
          <input
            id="{{startTime.hour}}"
            name="instructor"
            type="checkbox"
            class="input-checkbox"
            value="{{startTime.hour}}"
            [(ngModel)]="startTime.checked"
            (change)="refreshFilter()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>{{startTime.fromTime | date:'HH:mm'}} - {{startTime.toTime | date:'HH:mm'}}</span>
        </label>
      </div>
    </div>
  </div>
</div><div class="accordion-tab">
  <h4>
    <a [routerLink]="[]" queryParamsHandling="preserve" (click)="toggleInstructor()" class="accordion-header" [ngClass]="showInstructor ? 'active' : ''">
      Instructor
      <span class="plus-circle"></span>
    </a>
  </h4>
  <div id="tab-5-wgt-f1" class="toggler" *ngIf="showInstructor" [ngClass]="showInstructor ? 'active' : ''">
    <div class="row row-instructor checkbox-group">
      <div *ngFor="let instructor of instructors" class="span-input span-checkbox">
        <label
          for="{{instructor.id}}"
          class="input-check checkbox">
          <input
            id="{{instructor.id}}"
            name="instructor"
            type="checkbox"
            class="input-checkbox"
            value="{{instructor.id}}"
            [(ngModel)]="instructor.checked"
            (change)="refreshFilter()"
            [attr.disabled]="isSearchDisabled ? true : null"
          >
          <span>{{instructor.displayName}}</span>
        </label>
      </div>

    </div>
  </div>
</div>
</div>
