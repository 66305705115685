import {SocialLink} from './social-link';
import {FooterLink} from './footer-link';

export class MasterLicenseeSettings {
  companyName: string;
  companyTelephone: string;
  countryCode: string;
  language: string;
  locale: string;
  localeLanguage: string;
  currency: string;
  googleAnalyticsTrackingCode: string;
  facebookPixelId: string;
  facebookToken: string;
  timezone: string;
  timezoneOffset: string;
  hasLogo: boolean;
  hasInverseLogo: boolean;
  socialLinks: SocialLink[];
  footerLinks: FooterLink[];
  websiteUrl: string;
  contactUrl: string;
  tagline: string;
  masterLicenseeId: string;
  autoGeneratePendingPayments: boolean;
  loginStyle: string;
  enforceHowDidYouHear: boolean;
  enforceLeadSource: boolean;
  distanceMeasurement: string;
  forceProgramSelection: boolean;
  autoEnrolmentEnabled: boolean;
  cookieYesIntegration: boolean;
  cookieYesUrl: string;

  constructor(data?: any) {
    if (data != null) {
      Object.assign(this, data);
    }
  }
}
